<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <!-- 详情页面 -->
    <template v-if="BindStore">
      <!-- 搜索 -->
      <div class="check_type_store">
        <!--  <div class="Aliexpress_Product" @click="AliexpressProduct">
          <span>{{ $t('URL.AliexpressLink') }}</span>
          <span>》</span>
        </div> -->
        <div class="Import_List" @click="ImportList">
          <span>{{ $t('URL.Import') }}&nbsp;{{ $t('URL.List') }}</span>
          <span>》</span>
        </div>
        <div class="My_Product" @click="MyProduct">
          <span>{{ $t('URL.PublishedProducts') }}</span>
          <span>》</span>
        </div>
      </div>
      <div class="comSerInput">
        <div class="Filter">
          <el-input
            :placeholder="$t('MyProduct.FindProducts')"
            v-model="searchText"
            class="input-with-select"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search comBtn btn-bg0" @click="search">
            {{ $t('MyProduct.search') }}
          </button>
        </div>
      </div>

      <!-- 内容 -->
      <div class="activeName">
        <div class="ago">
          <div class="bigbox">
            <div class="box" v-for="item in myProductList" :key="item.index">
              <div class="images"><img :src="item.MainPic" alt="" /></div>
              <div class="simple">{{ item.ProductName }}</div>
              <div class="btn">
                <button class="btn-bg0" @click="handel(item.ProductId)">
                  {{ $t('pending.view') }}
                </button>
              </div>
              <div class="remove" @click="remove(item.ProductId)">{{ $t('pending.remove') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagebox">
        <PagePagination
          :total="options.total"
          :page-size="options.pageSize"
          :current-page="options.page"
          @current-change="pageChange"
        />
      </div>
    </template>
    <template v-else>
      <!-- 搜索 -->
      <div class="check_type">
       <!--  <div class="Aliexpress_Product" @click="AliexpressProduct">
          <span>{{ $t('URL.AliexpressLink') }}</span>
          <span>》</span>
        </div> -->
        <div class="Import_List" @click="ImportList">
          <span>{{ $t('URL.Import') }}&nbsp;{{ $t('URL.List') }}</span>
          <span>》</span>
        </div>
        <div class="My_Product" @click="MyProduct">
          <span>{{ $t('URL.PublishedProducts') }}</span>
          <span>》</span>
        </div>
      </div>
      <div class="comSerInput">
        <div class="Filter">
          <el-input
            :placeholder="$t('MyProduct.FindProducts')"
            v-model="searchText"
            class="input-with-select"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search comBtn btn-bg0" @click="search">
            {{ $t('MyProduct.search') }}
          </button>
        </div>
      </div>

      <!-- 内容 -->
      <div class="activeName">
        <div class="ago">
          <div class="bigbox">
            <div class="box" v-for="item in myProductList" :key="item.index">
              <div class="images"><img :src="item.MainPic" alt="" /></div>
              <div class="simple">{{ item.ProductName }}</div>
              <div class="btn">
                <button class="btn-bg0" @click="handel(item.ProductId)">
                  {{ $t('pending.view') }}
                </button>
              </div>
              <div class="remove" @click="remove(item.ProductId)">{{ $t('pending.remove') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagebox">
        <PagePagination
          :total="options.total"
          :page-size="options.pageSize"
          :current-page="options.page"
          @current-change="pageChange"
        />
      </div>
    </template>
  </div>
</template>
<script>
import PagePagination from '@/components/PagePagination.vue'
import { mapState } from 'vuex'
export default {
  data: () => {
    return {
      dispatch: null,
      page: 'MyProduct',
      banner: '',
      itemname: '',
      currentPage: 1,
      searchText: '', //关键字搜索
      myProductList: [], // 我的产品列表
      myProductreMove: '', // 删除当前产品的id
      options:
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        },
      BindStore: false, //是否绑定店铺
      BindStoreList: [] // 是否绑定店铺
    }
  },
  components: {
    PagePagination
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    setTimeout(() => {
      this.CheckGuide() // 是否绑定店铺
    }, 10)
    this.banner = this.$t('pending.bannername')
    this.itemname = this.$t('pending.itemname')
    this.getData()
    const _self = this
    document.onkeydown = function (e) {
      let key = window.event.keyCode
      if (key === 13) {
        _self.search()
        /* console.log(e, '123') */
      }
    }
  },
  computed: mapState(['userInfo', 'theme']),
  methods: {
    /* 是否绑定店铺 */
    CheckGuide() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result
          // 价格重置
          //console.log(res.Result, '123');
          if (res.Result.IsStoreComplete == true) {
            this.BindStoreList = res.Result
            this.BindStore = false
          } else {
            this.BindStore = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
   /*  AliexpressProduct() {
      this.$router.push('/AliexpressProducts')
    }, */
    ImportList() {
      this.$router.push('/ImportList')
    },
    MyProduct() {
      this.$router.push('/MyProduct')
    },
    pageChange(page) {
      //分页器页数改变
      this.options.page = page
      this.getData()
    },
    getPageList() {
      //分页器跳转页数
      this.options.page = this.options.jumpPage > 0 ? Number(this.options.jumpPage) : 1
      this.getData()
    },
    handel(item) {
      // 我的产品详情
      let routeData = this.$router.resolve({
        path: '/ProductInfo',
        query: {
          id: item,
          type: 2 //2 我的产品
        }
      })
      window.open(routeData.href, '_blank')
    },
    search() {
      this.getData()
      this.pageChange()
      this.getPageList()
    },
    getData() {
      // 获取爆款列表
      let that = this
      that
        .dispatch('myProduct/MemberProductListMp', {
          ProductName: this.searchText,
          CurrentPageIndex: that.options.page,
          PageSize: that.options.pageSize,
          RecordCount: that.options.list.length,
          IsPublished: true
        })
        .then((res) => {
          this.myProductList = res.Items
          this.options.total = res.TotalItems
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 删除按钮
    remove(id) {
      this.deleteData(id)
    },
    deleteData(id) {
      let that = this
      that
        .dispatch('myProduct/MemberProductRemoveMp', {
          id: id
        })
        .then((res) => {
          if (res.Success == true) {
            this.$message.success(that.$t('MyProduct.ProductRemoved'))
            this.getData()
          }
        })
        .catch((err) => {
          this.$message.error(that.$t('pending.fail'))
        })
    }
  }
}
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
    background-image: url('../../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 1320px;
  padding-bottom: 32px;
}

.activeName {
  height: auto;
  width: 1320px;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.check_type {
  display: flex;
  width: 1320px;
  height: 60px;
  /*margin-top: 25px;*/
}
.check_type_store {
  width: 100%;
  height: 0.3125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-weight: 600;
  font-size: 0.07292rem;
  padding-left: 0.05208rem;
}
.Aliexpress_Product {
  width: 200px;
  height: 40px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.Aliexpress_Product a {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Aliexpress_Product:hover {
  transform: scale(1.1);
}
.Aliexpress_Product span:nth-child(1) {
  line-height: 40px;
  margin-left: 40px;
  color: #15104b;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
.Aliexpress_Product span:nth-child(2) {
  margin-left: 20px;
  color: #15104b;
  line-height: 40px;
}
.Import_List {
  width: 200px;
  height: 40px;
  background: #fff;
  margin-left: 15px;
  border-radius: 50px;
  cursor: pointer;
}
.Import_List a {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Import_List:hover {
  transform: scale(1.1);
}
.Import_List span:nth-child(1) {
  line-height: 40px;
  margin-left: 50px;
  color: #15104b;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
.Import_List span:nth-child(2) {
  margin-left: 28px;
  color: #15104b;
}
.My_Product {
  width: 200px;
  height: 40px;
  background: #15104b;
  margin-left: 35px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.My_Product a {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.My_Product:hover {
  transform: scale(1.1);
}
.My_Product span:nth-child(1) {
  line-height: 40px;
  margin-left: 15px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
.My_Product span:nth-child(2) {
  margin-left: 5px;
  color: #fff;
}
/* 搜索 */
.Filter {
  width: 1220px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  /* margin-top: 30px; */
  margin-left: 15px;
}

::v-deep .Filter .input-with-select {
  width: 500px;
  height: 40px;
  background: #f5f7fa !important;
  border-radius: 50px;
  margin-left: 30px;
}

::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 46px;
}

::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 46px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .Filter [class*=' el-icon-'],
[class^='el-icon-'] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Filter .search {
  width: 145px;
  height: 46px;
  margin-left: 30px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Filter .Advanced {
  display: inline-block;
  width: 145px;
  height: 46px;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* ago */

.bigbox {
  /*width: 1320px;*/
  display: flex;
  flex-wrap: wrap;
  /* padding-bottom: 23px; */
  /* margin-bottom: 30px; */
}

.box {
  margin-top: 22px;
  margin-left: 10px;
  width: 300px;
  height: 385px;
  background: #f6f6fa;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.box .images {
  width: 290px;
  height: 233px;
  text-align: center;
}

.images img {
  width: 200px;
  height: 200px;
  margin-top: 32px;
}

.box .simple {
  margin-top: 30px;
  width: 290px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: rgb(25, 25, 25, 0.8);
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box .btn {
  margin-top: 20px;
  width: 290px;
  text-align: center;
}

.box .btn button {
  width: 180px;
  height: 30px;
  background: #474882;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
}

.box .btn button:hover {
  background: #3c3772 !important;
  color: #fff;
}

.box .remove {
  margin-top: 16px;
  font-size: 16px;
  width: 290px;
  line-height: 1;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #d42727;
  cursor: pointer;
}
.pagebox {
  margin-top: 0.05208rem;
  width: 1250px;
}
</style>
