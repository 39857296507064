import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import login from './modules/login'
import center from './modules/center'
import guide from './modules/guide'
import home from './modules/home'
import member from './modules/member'
import notification from './modules/notification'
import order from './modules/order'
import signIn from './modules/signIn'
import store from './modules/store'
import surveyFile from './modules/surveyFile'
import takeNotes from './modules/takeNotes'
import synchronize from './modules/synchronize'
import quoted from './modules/quoted'
import alliance from './modules/alliance'
import card from './modules/card'
import help from './modules/help'
import dashboard from './modules/dashboard'
import memberRights from './modules/memberRights'
import myProduct from './modules/myProduct'
import password from './modules/password'
import pending from './modules/pending'
import quotedInfo from './modules/quotedInfo'
import recharge from './modules/recharge'
import video from './modules/video'
import wallet from './modules/wallet'
import winner from './modules/winner'
import welcomed from './modules/welcomed'
import souring from './modules/souring'
import request from '@/utils/request'
// import products from './modules/products'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
// console.log('32323', synchronize);

export default new Vuex.Store({
  state: {
    userInfo: null, //全局更新个人信息
    theme: 'common',
    authInfo: null, //注册时授权信息
    account: null, //账户信息
    storeKey: '', //店铺授权token
    switchTheme: false, // 主题开关
    tabDate: 0, // winners页面tab跳转
    avater: null,
    showMemuLock: false,
    firstAmount: null
  },
  mutations: {
    changeTheme(state) {
      if (state.theme === 'common') {
        state.theme = 'dark'
      } else {
        state.theme = 'common'
      }
    },
    setUer(state, user) {
      state.userInfo = user
    },
    isshow(state, value1) {
      state.switchTheme = value1
    },
    setAccount(state, account) {
      state.account = account
    },
    setAuthInfo(state, authInfo) {
      // 设置授权回调信息
      state.authInfo = authInfo
    },
    setStoreKey(state, storeKey) {
      // 设置授权回调信息
      state.storeKey = storeKey
    },
    setAvater(state, avater) {
      state.avater = avater
    },
    setMenuLock(state, menuLock) {
      state.showMemuLock = menuLock
    },
    SET_FIRST_AMOUNT(state, amount) {
      state.firstAmount = amount
    }
  },
  actions: {
    requestAvater(context) {
      request({
        method: 'post',
        url: '/api/Member/GetMemberVo'
      })
        .then((res) => {
          context.commit('setAvater', res.Result.Avatar)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    requestMenuLock(context) {
      request({
        method: 'get',
        url: '/api/Member/CheckGuide'
      }).then((res) => {
        // 价格重置
        if (res.Result.More7daysNoStoreOrNoGrade) {
          context.commit('setMenuLock', true)
        }
      })
    },
    setFirstAmount({ commit }, amount) {
      commit('SET_FIRST_AMOUNT', amount)
    }
  },
  getters: {
    getFirstAmount: (state) => state.firstAmount
  },
  modules: {
    login,
    center,
    guide,
    home,
    member,
    notification,
    order,
    signIn,
    store,
    surveyFile,
    takeNotes,
    synchronize,
    quoted,
    alliance,
    card,
    help,
    dashboard,
    memberRights,
    myProduct,
    password,
    pending,
    quotedInfo,
    recharge,
    video,
    wallet,
    winner,
    welcomed,
    souring
  },
  strict: debug,
  plugins: debug
    ? [Vuex.createLogger(), persistedState({ storage: window.sessionStorage })]
    : [persistedState({ storage: window.sessionStorage })]
})
