<template>
  <div class="scroll" :class="{ show: isActive }">
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="record_page" :class="[theme == 'common' ? '' : 'dark']">
        <div class="Filter">
          <div class="paymentOrder_search">
            <div class="store_style">
              <div class="store_word">
                <p>{{ $t('PaymentOrder.StoreName') }}</p>
              </div>
              <div class="store_select">
                <el-select v-model="selectStore" clearable>
                  <el-option
                    v-for="item in stores"
                    :key="item.index"
                    :label="item.StoreName"
                    :value="item.UserId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="orderNo_style">
              <div class="orderNo_word">
                <p>{{ $t('PaymentOrder.OrderNumber') }}</p>
              </div>
              <div class="orderNo_input">
                <el-input v-model="OrderNumber" clearable></el-input>
              </div>
            </div>
            <div class="time_style">
              <div class="time_word">
                <p>{{ $t('PaymentOrder.Date') }}</p>
              </div>
              <div class="time_selectTime">
                <el-date-picker
                  v-model="selectTime"
                  class=""
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('orders.to')"
                  @change="timeChange">
                </el-date-picker>
              </div>
            </div>
            <div class="store_style">
              <div class="store_word">
                <p>Refund Type</p>
              </div>
              <div class="store_select">
                <el-select v-model="selectType" clearable>
                  <el-option
                    v-for="item in optionsType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <button @click="Search" class="search_btn comBtn btn-bg0 min-wid200">
              {{ $t('MyProduct.search') }}
            </button>
          </div>
        </div>
        <div class="records">
          <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('DLZ.OrderRefunds')" name="recharge" class="recharge">
              <template v-if="rechargeList && rechargeList.length > 0">
                <el-table :data="rechargeList" style="width: 100%">
                  <el-table-column
                    prop="RefundDateDesc"
                    align="center"
                    :label="$t('PaymentOrder.RefundTime')">
                  </el-table-column>
                  <el-table-column prop="Store" align="center" :label="$t('PaymentOrder.Store')">
                  </el-table-column>
                  <el-table-column
                    prop="OrderNo"
                    align="center"
                    :label="$t('PaymentOrder.Ordernumber')">
                  </el-table-column>
                  <el-table-column align="center" :label="$t('PaymentOrder.RefundAmountCurrency')">
                    <template slot-scope="scope">
                      <p>{{ scope.row.RefundAmount }}</p>
                      <p>{{ scope.row.RefundCurrency }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="RefundTypeDesc"
                    align="center"
                    :label="$t('PaymentOrder.RefundType')">
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="options[0].total"
                    :page-size="pageSize"
                    :current-page="options[0].page"
                    :page-sizes="[]"
                    @size-change="handleSizeChange"
                    @current-change="pageChange">
                    <span class="pageSizeInput">
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="options[0].jumpPage" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="DefaultData">
                  <el-table style="width: 100%">
                    <el-table-column align="center" :label="$t('PaymentOrder.RefundTime')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('PaymentOrder.Store')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('PaymentOrder.Ordernumber')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('PaymentOrder.RefundAmountCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('PaymentOrder.RefundType')">
                    </el-table-column>
                    <template slot="empty">
                      <div class="NodataImg">
                        <img
                          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927907151_Nodata.png"
                          alt=""/>
                        <div>No data</div>
                      </div>
                    </template>
                  </el-table>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
          <el-button class="PaymentOrderExport comBtn border-col5 col-col5" @click="ExportBtn"
            >{{ $t('PaymentOrder.Export') }}
          </el-button>
          <div class="questionMark_icon" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715825363377_help-circle(1).png"
              alt=""
            />
          </div>
        </div>
        <div class="Comment_pop_up" v-if="ShowPopUp">
          <div class="dispute_charge">
            {{ $t('PaymentOrder.DisputeCharge') }}
          </div>
          <div class="after_sale">
            {{ $t('PaymentOrder.AfterSale') }}
          </div>
          <div class="other">{{ $t('PaymentOrder.OtherIncluding') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { baseApiHost } from '../../utils/env'
export default {
  data: () => {
    return {
      dispatch: null,
      currentPage: 1,
      page: 'recharge',
      activeName: 'recharge', //标签当前选中
      rechargeList: [], // 我的充值列表
      pageSize: 10,
      OrderNumber: '', // 查询
      stores: '', //高级搜索 店铺
      selectStore: '', //选中店铺
      isActive: false,
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      selectTime: [],
      dialogForm: {
        BeginDate: '',
        EndDate: ''
      },
      optionsSize: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ],
      optionsType: [
        {
          value: 1,
          label: 'After Sale'
        },
        {
          value: 2,
          label: 'DisputeChargeBack'
        }
      ],
      ShowPopUp: false,
      selectType: ''
    }
  },
  components: {},
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getRechageList()
    if (!this.stores) {
      this.getStores()
    }
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = []
        }
      },
      deep: true
    }
  },
  methods: {
    onMouseOver() {
      this.ShowPopUp = true
    },
    onMouseOut() {
      this.ShowPopUp = false
    },
    Search() {
      this.pageChange()
      this.options[0].page = 1
      this.getRechageList()
    },
    timeChange() {  // 取值
      if (this.selectTime.length) {
        this.dialogForm.BeginDate = this.selectTime[0]
        this.dialogForm.EndDate = this.selectTime[1]
      } else {
        this.dialogForm.BeginDate = ''
        this.dialogForm.EndDate = ''
      }
    },
    pageChange(page) { // 分页器页数改变
      this.options[0].page = page
      this.getRechageList()
    },
    handleSizeChange(pageSize) {
      this.options[0].pageSize = pageSize
      this.getRechageList()
    },
    getPageList() { // 分页器跳转页数
      this.options[0].page = this.options[0].jumpPage > 0 ? Number(this.options[0].jumpPage) : 1
      this.getRechageList()
    },
    handlePageSize() {
      if (!this.quotedList[0].pageSize) {
        this.quotedList[0].pageSize = 0
      }
    },
    selectChanged(pageSize) {
      this.options[0].pageSize = pageSize
      this.getRechageList()
    },
    getRechageList() { // 获取我的充值列表
      this.dispatch('recharge/NYRefundRegisterListRC', {
        CurrentPageIndex: this.options[0].page,
        PageSize: this.options[0].pageSize,
        RecordCount: this.options[0].list.length,
        OrderNo: this.OrderNumber,
        NewUserId: this.selectStore,
        BeginDate: this.selectTime ? this.selectTime[0] : '',
        EndDate: this.selectTime ? this.selectTime[1] : '',
        RefundType: this.selectType
      })
        .then((res) => {
          if (res.Success) {
            this.rechargeList = res.Items
            this.options[0].total = res.TotalItems
          } else {
            this.$message(res.Msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ExportBtn() {
      this.dispatch('recharge/ExportRefundNyExportListRC', {
        CurrentPageIndex: this.options[0].page,
        PageSize: this.options[0].pageSize,
        RecordCount: this.options[0].list.length,
        OrderNo: this.OrderNumber,
        NewUserId: this.selectStore,
        BeginDate: this.selectTime ? this.selectTime[0] : '',
        EndDate: this.selectTime ? this.selectTime[1] : '',
        RefundType: this.selectType
      })
        .then((res) => {
          if (res.Success) {
            window.open(baseApiHost() + res.Result)
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getStores() { // 获取高级搜索 店铺搜索
      this.dispatch('store/GetListSt')
        .then((res) => {
          this.stores = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
.Filter {
  width: 1320px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  font-family: 'Regular';
  .paymentOrder_search {
    display: flex;
    .store_style {
      display: flex;
      .store_word p {
        font-size: 14px;
        margin-left: 10px;
      }
      .store_select {
        margin-left: 5px;
        ::v-deep .el-input__inner {
          width: 180px;
          border-radius: 10px;
        }
      }
    }
    .orderNo_style {
      display: flex;
      .orderNo_word p {
        font-size: 14px;
        margin-left: 30px;
      }
      .orderNo_input {
        margin-left: 15px;
        ::v-deep .el-input__inner {
          width: 150px;
          border-radius: 10px;
        }
      }
    }
    .time_style {
      display: flex;
      .time_selectTime {
        margin-left: 15px;
      }
      ::v-deep .el-range-editor.el-input__inner {
        width: 280px;
        border-radius: 10px;
      }
      ::v-deep .el-date-editor .el-range-separator {
        width: 15%;
      }
      .time_word p {
        font-size: 14px;
        margin-left: 30px;
      }
    }
  }
  .search_btn {
    margin-left: 50px;
    margin-top: 15px;
  }
}
.Comment_pop_up {
  position: absolute;
  top: 50px;
  left: 230px;
  background: #eff1f3;
  border-radius: 10px;
  width: 350px;
  padding: 20px;
  z-index: 99;
  font-family: 'Regular';
  .dispute_charge {
    font-size: 14px;
  }
  .after_sale {
    font-size: 14px;
    margin-top: 8px;
  }
  .other {
    font-size: 14px;
    margin-top: 8px;
  }
}
::v-deep .pageSizeInput .el-input__inner {
  width: 100px !important;
  height: 35px;
  margin-top: -30px;
  border-radius: 20px;
}
.record_page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.records {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 32px;
  margin-top: 30px;
  position: relative;
}
::v-deep .records .el-tabs__content {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recharge {
  background-color: #ffffff !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
::v-deep .el-tabs__header {
  margin: 0;
  background-color: #fff;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: 'Regular';
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: 'Regular';
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 3px;
}
/* 标签页头部高度 */
::v-deep .records .recharge .el-table th,
::v-deep .el-table tr {
  height: 70px;
  font-family: 'Regular';
}
::v-deep .records .el-tabs__content {
  border-radius: 0 0 10px !important;
}
/* 分页切换 */
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
  font-family: 'Regular';
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: 'Regular';
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
}
.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
}
.PaymentOrderExport {
  position: absolute;
  top: 20px;
  right: 50px;
  border-radius: 50px;
  width: 180px;
  font-size: 16px;
  border: 2px solid #3f6aff;
}
.PaymentOrderExport:hover {
  background: #3f6aff !important;
  color: #fff !important;
}
.questionMark_icon {
  position: absolute;
  top: 28px;
  left: 170px;
}
.questionMark_icon img {
  width: 25px;
  height: 25px;
}
.questionMark_icon img:hover {
  cursor: pointer;
}
.NodataImg {
  padding-bottom: 60px;
}
.NodataImg img {
  width: 180px;
  height: 180px;
  margin-top: 100px;
}
</style>
