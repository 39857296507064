<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box">
      <div class="activeName">
        <div class="FAQTitle"> <!-- 标题 -->
          <p>{{ $t("FAQ.FAQ") }}</p>
        </div>
        <div class="AgentBox">
          <div class="AgentMessage">
            <div class="AgentImgLeft">
              <img :src="baseApiHost()+CenterList.Avatar" alt="" />
            </div>
            <div class="AgentMessageRight">
              <p>
                {{ $t("SideBar.Hi") }}&nbsp;<span style="color: #53c248"
                  >{{ userInfo.RealName }},</span
                >&nbsp;{{ $t("SideBar.how_doing") }}
              </p>
              <p>
                {{ $t("SideBar.I_m") }}
                <span style="font-style: italic; text-decoration: underline; font-size: 18px">{{
                  CenterList.AgentName
                }}</span>&nbsp;,&nbsp;{{ $t("SideBar.YourAgent") }}
              </p>
              <p>{{ $t("SideBar.FeelMe") }}&nbsp;!</p>
            </div>
            <!-- 留言板提示语 -->
            <!--  <div class="MessageSlogan" v-if="ShowMessageSlogan">
              You can click here to leave your comments.
              We will gather and review valuable insights and
              interesting ideas for an upcoming response video.
              Your feedback will help USAdrop improve further!
            </div> -->
          </div>
          <div class="AgentMethods">
            <div>
              <a :href="'https://api.whatsapp.com/send?phone=+' + CenterList.WhatsApp + '&text=Hello'"
                target="_blank">
                <div class="member_Whatsapp">
                  <span>
                    <span><img src="../../../assets/imgs/UpgradeToPro/whatsapp-logo-hero.png" alt=""/></span>
                  </span>
                  <span>{{ $t("SideBar.WhatsApp") }}</span>
                </div>
              </a>
            </div>
            <div>
              <a :href="'skype:' + CenterList.Skype + '?chat'" target="_blank">
                <div class="member_Skype">
                  <span>
                    <span><img src="../../../assets/imgs/UpgradeToPro/Skype123.png" alt="" /></span>
                  </span>
                  <span>{{ $t("SideBar.Skype") }}</span>
                </div>
              </a>
            </div>
            <div>
              <a :href="'https://t.me/' + CenterList.Telegram" target="_blank">
                <div class="member_Telegram">
                  <span>
                    <span
                      ><img src="../../../assets/imgs/UpgradeToPro/telegram1.png" alt=""
                    /></span>
                  </span>
                  <span>{{ $t("SideBar.Telegram") }}</span>
                </div>
              </a>
            </div>
          <!--   <div class="CustomerReviews" @click="OpenShowMessageBoardBox" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
              <span><img src="../../../assets/imgs/SideBar/Frame.png" alt=""></span>
              <span>{{ $t("SideBar.CustomerReviews") }}</span>
            </div> -->
          </div>
        </div>
        <div class="FAQMessageBox">
          <div class="HowUSAdrop_words" @click="HowUSAdropBtn">
            <p>{{ $t("FAQ.HowUSAdrop") }}</p>
            <p><i class="el-icon-arrow-right"></i></p>
          </div>
          <div class="shippingTime_words" @click="shippingTimeBtn">
            <p>{{ $t("FAQ.shippingTime") }}</p>
            <p><i class="el-icon-arrow-right"></i></p>
          </div>
          <div class="shippingCost_words" @click="shippingCostBtn">
            <p>{{ $t("FAQ.shippingCost") }}</p>
            <p><i class="el-icon-arrow-right"></i></p>
          </div>
          <div class="productItem_words" @click="productItemBtn">
            <p>{{ $t("FAQ.productItem") }}</p>
            <p><i class="el-icon-arrow-right"></i></p>
          </div>
          <div class="lookingFor_words" @click="lookingForBtn">
            <p>{{ $t("FAQ.lookingFor") }}</p>
            <p><i class="el-icon-arrow-right"></i></p>
          </div>
        </div>
      </div>
      <div class="zalo_box" v-if="ShowZaLoBox"> <!-- zalo业务员二维码 -->
        <div class="zalo_box_bgImg">
          <div class="zalo_Icon" @click="CloaseZalo">
            <i class="el-icon-circle-close"></i>
          </div>
          <div class="Zalo_Img">
            <img :src="CenterList.ZaloQR" alt="">
          </div>
          <div class="CenterList_AgentName">
            <p>{{ CenterList.AgentName }}</p>
          </div>
          <div class="SideBar_ScanZalo">
            {{ $t("SideBar.ScanZalo") }}
          </div>
        </div>
      </div>
      <div class="MessageBoardBox" v-if="ShowMessageBoardBox"> <!-- 留言板 -->
        <div class="MessageBoardContent">
          <div class="header_title"> <!-- 头部 -->
            <div class="SideBarComment">
              <p> {{$t("SideBar.Comment") }}</p>
            </div>
            <div class="SideBarIconClose" @click="CloseMessageBoard">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="line"></div>
          <div class="Message_content"> <!-- 留言内容 -->
            <el-input
              type="textarea"
              :rows="2"
              v-model="keywords"
              resize="none">
            </el-input>
          </div>
          <div class="footerbox"> <!-- 提交按钮 -->
            <button class="Cancel btn_com comBtn col-col0 border-col0" @click="CloseMessageBoard">{{ $t("store.cancel") }}</button>
            <button class="Save btn_com comBtn btn-bg0 border-col0" @click="SubmitComment">
              {{ $t("SideBar.Submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { baseApiHost } from "../../../utils/env";
export default {
  props: ["page"],
  data: () => {
    return {
      baseApiHost: baseApiHost,
      CenterList: [], // 当前用户业务员
      CenterMessage: [],
      paymentList: [],
      ShowZaLoBox: false, // zalo二维码
      ShowMessageSlogan: false,
      textarea: '',
      ShowMessageBoardBox: false,
      keywords: ""
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  mounted() {
    this.dispatch = this.$store.dispatch;
    this.menterData();
    this.getData();
  },
  methods: {
    OpenShowMessageBoardBox () {
      this.keywords= ""
      this.ShowMessageBoardBox = true;
    },
    CloseMessageBoard() {
      this.ShowMessageBoardBox = false;
    },
    onMouseOver() {
      this.ShowMessageSlogan = true;
    },
    onMouseOut() {
      this.ShowMessageSlogan = false;
    },
    ZaLoBtn () {
      this.ShowZaLoBox = true;
      this.AgentData();
    },
    CloaseZalo () {
      this.ShowZaLoBox = false;
    },
    AgentData() {
      let that = this;
      this.dispatch('center/GetAgentByIdCr', {
          id: this.CenterMessage.AgentId,
      })
        .then((res) => {
          if (res.Success == false) {
          } else {
            that.CenterList = res.Result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SubmitComment() { // 根据条件获取订单列表
       this.dispatch("center/SubmitCommentCr", {
        content: this.keywords
      })
        .then((res) => {
          if (res.Success) {
            this.ShowMessageBoardBox = false;
            this.$message({
              message: res.ErrMsg,
              type: 'success',
              duration: 2000,
              customClass: "SuccessClass"
            });
          } else {
            this.$message({
              message: res.ErrMsg,
              type: 'error',
              duration: 2000,
              customClass: "SuccessClass"
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      let that = this;
      this.dispatch('center/messageCr')
        .then((res) => {
          that.CenterMessage = res.Result;
          that.rawData = { ...res.Result };
          this.AgentData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    HowUSAdropBtn() {
      this.$router.push("/HowToStart");
    },
    shippingTimeBtn() {
      this.$router.push("/ShippingTime");
    },
    shippingCostBtn() {
      this.$router.push("/ShippingCost");
    },
    productItemBtn() {
      this.$router.push("/ProductItem");
    },
    lookingForBtn() {
      this.$router.push("/ProductNotFound");
    },
  },
};
</script>
<style>
.SuccessClass {
  min-width: 300px !important;
  margin-left: 50px;
}
</style>
<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  position: relative;
}
.FAQTitle {
  text-align: center;
  margin-left: 140px;
}
.FAQTitle p {
  font-size: 30px;
  font-weight: 600;
  color: #21224e;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.AgentBox {
  width: 1350px;
  height: 280px;
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  margin-left: 50px;
  position: relative;
}
.AgentMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AgentImgLeft img {
  width: 140px;
  height: 140px;
  margin-top: 50px;
}
.AgentMessageRight {
  margin-left: 30px;
  margin-top: 45px;
}
.AgentMessageRight p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
}
.AgentMessageRight p:nth-child(2) {
  font-size: 16px;
  font-family: "Regular";
  margin-top: 8px;
}
.AgentMessageRight p:nth-child(3) {
  font-size: 16px;
  font-family: "Regular";
  margin-top: 8px;
}
.MessageSlogan {
  background: #EFF1F3;
  width: 300px;
  height: 135px;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  right: 30px;
  top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 20px;
  padding-top: 10px;
  color: #333333;
  font-family: "Regular";
}
.MessageSlogan p {
  font-size: 14px;
  font-family: "Regular";
}
.AgentMethods {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 15px;
}
.member_Whatsapp {
  width: 200px;
  height: 50px;
  line-height: 35px;
  border-radius: 50px;
  background: #2bb51b;
}
.member_Whatsapp img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 35px;
}
.member_Whatsapp span:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  width: 200px;
  font-size: 18px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
}
.member_Skype {
  width: 200px;
  height: 50px;
  line-height: 45px;
  border-radius: 50px;
  background: #15ace5;
}
.member_Skype img {
  width: 33px;
  height: 33px;
  margin-top: 10px;
  margin-left: 35px;
}
.member_Skype span:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 50px;
  width: 200px;
  word-wrap: break-word;
  font-size: 18px;
  margin-left: 15px;
  position: absolute;
  top: 3px;
}
.member_Telegram {
  width: 200px;
  height: 50px;
  line-height: 45px;
  border-radius: 50px;
  background: #15ace5;
}
.member_Telegram img {
  width: 31px;
  height: 31px;
  margin-top: 10px;
  margin-left: 35px;
}
.member_Telegram span:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 50px;
  width: 200px;
  word-wrap: break-word;
  font-size: 18px;
  margin-left: 15px;
  position: absolute;
  top: 3px;
}
.member_Zalo {
  width: 200px;
  height: 50px;
  line-height: 45px;
  border-radius: 50px;
  background: #63a3ff;
  cursor: pointer;
}
.member_Zalo img {
  width: 31px;
  height: 31px;
  margin-top: 10px;
  margin-left: 35px;
}
.member_Zalo span:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 50px;
  width: 200px;
  word-wrap: break-word;
  font-size: 18px;
  margin-left: 10px;
  position: absolute;
  top: 5px;
}
.CustomerReviews {
  background: #3F6AFF;
  width: 200px;
  height: 50px;
  line-height: 45px;
  border-radius: 50px;
  cursor: pointer;
}
.CustomerReviews img {
  width: 31px;
  height: 31px;
  margin-top: 10px;
  margin-left: 35px;
}
.CustomerReviews span:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 50px;
  width: 200px;
  word-wrap: break-word;
  font-size: 18px;
  margin-left: 10px;
  position: absolute;
  top: 3px;
}
.FAQMessageBox {
  width: 1350px;
  height: 300px;
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}
.HowUSAdrop_words {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-left: 25px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.HowUSAdrop_words p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 30px;
  margin-top: 20px;
  line-height: 35px;
  color: #21224e;
}
.HowUSAdrop_words p:nth-child(2) {
  font-size: 16px;
  margin-top: 22px;
  margin-right: 0px;
}
.shippingTime_words {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-left: 25px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.shippingTime_words p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 30px;
  margin-top: 20px;
  line-height: 35px;
  color: #21224e;
}
.shippingTime_words p:nth-child(2) {
  font-size: 16px;
  margin-top: 22px;
  margin-right: 0px;
}
.shippingCost_words {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-left: 25px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.shippingCost_words p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  margin-left: 30px;
  margin-top: 20px;
  line-height: 35px;
  color: #21224e;
}
.shippingCost_words p:nth-child(2) {
  font-size: 16px;
  margin-top: 22px;
  margin-right: 0px;
}
.productItem_words {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-left: 25px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.productItem_words p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  margin-left: 30px;
  margin-top: 20px;
  line-height: 35px;
  color: #21224e;
}
.productItem_words p:nth-child(2) {
  font-size: 16px;
  margin-top: 22px;
  margin-right: 0px;
}
.lookingFor_words {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-left: 25px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.lookingFor_words p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  margin-left: 30px;
  margin-top: 20px;
  line-height: 35px;
  color: #21224e;
}
.lookingFor_words p:nth-child(2) {
  font-size: 16px;
  margin-top: 22px;
  margin-right: 0px;
}
.zalo_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
}
.zalo_box_bgImg {
  width: 400px;
  height: 600px;
  background-image: url("../../../assets/imgs/UpgradeToPro/Slice348zaloAgent.png");
  background-repeat: no-repeat;
  background-size: cover;
  left: calc(60% - 400px);
  top: calc(50% - 350px);
  position: relative;
  border-radius: 15px;
}
.Zalo_Img img {
  width: 300px;
  height: 300px;
  margin-left: 55px;
  margin-top: 50px;
}
.CenterList_AgentName {
  text-align: center;
  color: #fff !important;
  font-family: "Regular";
  font-size: 24px;
  margin-top: 10px;
}
.SideBar_ScanZalo {
  text-align: center;
  color: #fff !important;
  font-family: "Regular";
  font-size: 18px;
  margin-top: 10px;
}
.zalo_Icon {
  position: absolute;
  top: -15px;
  right: -10px;
  cursor: pointer;
}
.zalo_Icon >>> .el-icon-circle-close {
  color: #fff;
  font-size: 30px;
}
.MessageBoardBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
}
.MessageBoardContent {
  width: 800px;
  height: 500px;
  background: #FFFFFF;
  left: calc(56% - 500px);
  top: calc(50% - 270px);
  position: relative;
  border-radius: 15px;
}
.header_title {
  display: flex;
  justify-content: space-between;
}
.SideBarComment {
  margin-left: 50px;
  margin-top: 20px;
}
.SideBarComment p{
  font-family: "Regular";
  font-size: 18px;
}
.SideBarIconClose {
  margin-top: 25px;
  margin-right: 30px;
  cursor: pointer;
}
.SideBarIconClose >>> .el-icon-close {
  font-size: 22px;
}
.line {
  height: 1.1px;
  width: 100%;
  background: #E5E5E5;
  margin-top: 10px;
}
.Message_content {
  margin-top: 30px;
  width: 95%;
  margin-left: 20px;
  height: 300px;
}
.Message_content >>> .el-textarea__inner {
  height: 300px;
}
.footerbox {
  text-align: center;
}
.Cancel {
  width: 200px;
  height: 50px;
  border: 2px solid #15104B;
  font-size: 18px;
  margin-right: 60px;
}
.Save {
  width: 200px;
  height: 50px;
  border-style: solid;
  margin-left: 20px;
  margin-top: 30px;
}
</style>
