var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _vm._m(0),
    _c("div", { staticClass: "activeName" }, [
      _vm._m(1),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "Confirm" }, [
          _c("button", { on: { click: _vm.forgetTap } }, [
            _vm._v(_vm._s(_vm.$t("wallet.Confirm"))),
          ]),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.$t("guide.platform")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Bgrd" }, [
      _c("p", [
        _c("img", {
          attrs: { src: require("../../assets/imgs/guide/only.png"), alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ShopyLogo" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/guide/shopylogo.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }