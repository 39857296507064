import {
    StartSyncTask,
    GetSynTasks
  } from '@/api/synchronize'

  export default {
    namespaced: true,
    state: {
      token: '',
    },
    getters: {},
    mutations: {
      setAuthBack(state, status) {
  
      },
      setToken(state, status) {
        state.token = status;
      }
    },
    actions:{
        StartSyncTaskSy({state, commit}, params) {return StartSyncTask(params).then(res => res)},
        GetSynTasksSy({state, commit}, params) {return GetSynTasks(params).then(res => res)}
    }
   }