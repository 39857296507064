<template>
  <div class="particulars">
    <div class="activeName">
      <div class="title_title">
        <h1>Affiliate World Europe 2024</h1>
        <div class="insights_title">Insights and Opportunities for Direct Sellers</div>
      </div>
      <div class="exhibition_img">
        <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725698073785_meta-img.jpg" alt="">
      </div>
      <div class="left_image_right_text">
        <div class="left_img_box">
          <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700367204_img8-2.jpg" alt="">
        </div>
        <div class="right_text_title">
          <p>As Affiliate World Europe 2024 comes to a close, we're excited to share the highlights from this fantastic event held in Budapest from September 5th to 6th. This year's conference offered a wealth of knowledge and networking opportunities tailored for digital marketers, eCommerce professionals, and direct sellers like you.</p>
        </div>
      </div>
      <div class="keyinsights_box">
        <div class="key_insights_box">
          Key Insights from the Speaker Sessions
        </div>
        <div class="keyinsights_title">
          <p>
            1. Meta Ads Track: Advanced Creative Strategies
          </p>
          <p>
            Discover how leveraging A/B and multivariate testing can refine your ad creatives for better results. Speakers emphasized the importance of localizing ad techniques to resonate with diverse audiences and enhance engagement.
          </p>
        </div>
        <div class="google_ads_track">
          <div class="google_ads_title">2. Google Ads Track: Dynamic Creative Optimization </div>
          <div class="left_image_right_text_two">
            <div class="google_ads_img_box">
              <div class="google_ads_img">
                <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725690667021_image.png" alt="">
              </div>
              <div class="google_ads_words_one">
                <p>Sasha Vitez</p>
                <p>Senior Growth Consultant,</p>
                <p>Google</p>
              </div>
            </div>
            <div class="explorepo_tential_title">
              Explore the potential of Dynamic Creative Optimization (DCO) in display advertising. By utilizing AI-driven automation and optimizing creative elements, you can significantly improve ad performance and conversion rates. The track also delved into effective bidding strategies and data-driven decision-making in Google Ads.
            </div>
          </div>

        </div>
        <div class="native_advertising_track">
          <div class="native_advertising_title">3. Native Advertising Track: Crafting Effective Headlines</div>
          <div class="native_advertising_words">Master the art of creating compelling native ad headlines to capture attention and drive clicks. The session covered navigating the native ad bidding and auction systems and analyzing ad performance metrics to maximize ROI.	</div>
        </div>
        <div class="google_ads_track_two">
          <!-- <div class="google_ads_title_two">4. TikTok Track: Harnessing Influencer Partnerships</div> -->
          <div class="left_image_right_text_two">
            <div class="explorepo_tential_title_two">
              <p>
                4. TikTok Track: Harnessing Influencer Partnerships
              </p>
              <p>
                Learn how to collaborate with influencers on TikTok to boost brand visibility and engagement. Tips included crafting high-performing ad copy and leveraging TikTok’s algorithm updates for better targeting and content strategies.
              </p>

            </div>
            <div>
              <div class="google_ads_img_two">
                <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725691812869_image.png" alt="">
              </div>
              <div class="google_ads_words">
                <p>Niv Shaked</p>
                <p>Head of Indirect Sales, TikTok</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="key_takeaways_sellers_box">
        <div class="key_takeaway_big_title">Key Takeaways for Direct Sellers</div>
        <div class="key_takeaway_img">
          <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725692348912_Affiliate-World-Dubai-2024-Panel-1024x683.jpeg" alt="">
        </div>
        <div class="key_takeaways_sellers">
          <div class="key_takeaways_title">1. Diversify Traffic Sources</div>
          <div class="key_takeaways_words">
            A major theme at this year's Affiliate World was the shift towards diversifying traffic sources. While platforms like Facebook remain crucial, there's a growing emphasis on exploring new channels such as TikTok, native ads, and Google. This diversification is essential for optimizing your marketing strategy and reaching a broader audience.
          </div>
        </div>
        <div class="key_takeaways_sellers">
          <div class="key_takeaways_title">2. Harness the Power of AI</div>
          <div class="key_takeaways_words">
            Artificial intelligence and machine learning are transforming ad management. Sessions on AI-driven ad optimization, advanced scaling strategies, and creative testing highlighted how these technologies can enhance your campaigns. AI can streamline content creation and campaign management, leading to more effective and personalized advertising.
          </div>
        </div>
        <div class="key_takeaways_sellers">
          <div class="key_takeaways_title">3. Optimize Supply Chains and Product Sourcing</div>
          <div class="key_takeaways_words">
            The direct selling industry is evolving with a clear focus on supply chain optimization and exploring new markets. Streamlining product sourcing and offering innovative payment solutions were emphasized as key growth areas. This aligns perfectly with USAdrop’s commitment to providing comprehensive solutions beyond just fulfillment.
          </div>
        </div>
      </div>
      <div class="success_stories_box">
        <div class="case_study_box_one">
          <div class="case_study_title">
            Case Study 1: Boosting Customer Satisfaction with High-Quality Fulfillment
          </div>
          <div class="case_study_box">
            <div class="toma_img">
              <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725699996588_img1.jpg" alt="">
            </div>
            <div class="toma_content">
              A rising fashion direct seller saw a 35% increase in sales in August after integrating USAdrop’s product supply and brand customization. By leveraging our high-quality products and trending styles, they effectively met customer needs and maximized profit margins.
            </div>
          </div>
        </div>
        <div class="case_study_box_one">
          <div class="case_study_title">
            Case Study 2: Streamlining Global Expansion Fulfillment
          </div>
          <div class="case_study_box">
            <div class="toma_content_two">
              Another success story comes from a children’s toy retailer who expanded their international market presence. By using USAdrop’s global fulfillment options and local warehouses, they reduced shipping times by 40%, improved customer satisfaction, and significantly increased repeat orders.
            </div>
            <div class="toma_img_two">
              <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700059360_img2-6.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="case_study_box_two">
          <div class="case_study_img">
            <img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284572_img6.jpg" alt="">
          </div>
          <div class="case_study_content">
            <p>
              In addition to fulfillment services, we also offer:
            </p>
            <ul class="service-list">
              <li>Ad agency services</li>
              <li>Streamlined payment solutions</li>
              <li>30% Profit Co-founder</li>
              <li>Local warehouses for faster global delivery</li>
              <li>......</li>
            </ul>
          </div>
        </div>
        <div class="fulfillment_rate_content">
          If you haven’t explored these services yet, now is the perfect time to integrate them into your business and enhance your order fulfillment rates.
        </div>
      </div>
      <div class="visual_insights_box">
        <div class="visual_insights_big_title">Visual Insights</div>
        <div class="customer_testimonial_title">Customer Testimonial:</div>
        <div class="fulfillment_services">
          “USAdrop’s fulfillment services have transformed our order timing issues. The results speak for themselves, with noticeable improvements in customer feedback and profit margins!”
        </div>
        <div class="fashion_direct">
          — [Khaled], Fashion Direct Seller.
        </div>
      </div>
      <div class="whats_next_box">
        <div class="whats_next_content_one">
          Looking ahead, the insights gained from Affiliate World Europe are invaluable. We’re excited to apply these experiences to our products and continue supporting your direct selling success. We look forward to seeing you at the next Affiliate World event!
        </div>
        <div class="whats_next_content_one">
          Thank you to everyone who joined us and made this event a success. Stay tuned for more updates and tips to help you expand your direct selling business with USAdrop.
        </div>
      </div>
      <div class="img_box">
        <p><img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284738_img3-4.jpg" alt=""></p>
        <p><img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284692_img4-9.jpg" alt=""></p>
        <p><img src="https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700435239_74.jpg" alt=""></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ExhibitionTwo",
  data: () => {
    return {
    };
  },
  components: {
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  padding-bottom: 32px;
}
.title_title {
  text-align: center;
  padding-top: 50px;
  h1 {
    font-size: 40px;
    color: #3b3663;
  }
  .insights_title {
    font-size: 40px;
    color: #3b3663;
    margin-top: 50px;
  }
}
.exhibition_img {
  text-align: center;
  margin-top: 125px;
  img {
    width: 1100px;
    height: 400px;
    border-radius:  8px;
    object-fit: cover;
  }
}
.left_image_right_text {
  display: flex;
  margin-top: 125px;
  .left_img_box {
    margin-left: 160px;
    img {
      width: 250px;
      height: 250px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .right_text_title {
    width: 700px;
    margin-top: 0px;
    p {
      font-size: 16px;
      line-height: 50px;
      margin-left: 60px;
      color: #666666;
    }
  }
}
.keyinsights_box {
  text-align: left;
  margin-left: 160px;
  margin-top: 30px;
  .key_insights_box {
    font-size: 22px;
    font-weight: 600;
  }
  .keyinsights_title {
    p:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }
    p:nth-child(2) {
      font-size: 16px;
      color: #666666;
      width: 1000px;
      margin-top: 15px;
      line-height: 30px;
    }
  }
  .google_ads_track {
    margin-top: 15px;
    .google_ads_title {
      font-size: 16px;
      font-weight: 600;
    }
    .left_image_right_text_two {
      display: flex;
      margin-top: 20px;
      .google_ads_img img{
        width: 150px;
        height: 150px;
      }
      .google_ads_words_one {
        p:nth-child(1) {
          font-size: 12px;
          line-height: 40px;
          margin-left: 40px;
        }
        p:nth-child(2) {
          font-size: 12px;
        }
        p:nth-child(3) {
          font-size: 12px;
          line-height: 40px;
          margin-left: 50px;
        }
      }
    }
    .explorepo_tential_title {
      font-size: 16px;
      color: #666666;
      margin-top: 10px;
      width: 700px;
      line-height: 35px;
      margin-left: 80px;
    }
  }
  .google_ads_track_two {
    margin-top: 10px;
    .google_ads_title {
      font-size: 16px;
      font-weight: 600;
    }
    .google_ads_title_two {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }
    .left_image_right_text_two {
      display: flex;
      margin-top: 20px;
      .google_ads_img_two img{
        width: 150px;
        height: 150px;
        margin-left: 130px;
      }
      .google_ads_words {
        p:nth-child(1) {
          font-size: 12px;
          line-height: 40px;
          margin-left: 170px;
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 40px;
          margin-left: 120px;
        }
      }
    }
    .explorepo_tential_title_two {
      p:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
      }
      p:nth-child(2) {
        font-size: 16px;
        color: #666666;
        margin-top: 10px;
        width: 700px;
        line-height: 40px;
      }
    }
  }
  .native_advertising_track {
    margin-top: 10px;
    .native_advertising_title {
      font-size: 16px;
      font-weight: 600;
    }
    .native_advertising_words {
      font-size: 16px;
      color: #666666;
      margin-top: 15px;
      width: 1000px;
      line-height: 30px;
    }
  }
}
.key_takeaways_sellers_box {
  text-align: left;
  margin-left: 160px;
  .key_takeaway_big_title {
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
  }
  .key_takeaway_img img {
    width: 1024px;
    height: 682px;
    margin-top: 30px;
    border-radius: 8px;
  }
  .key_takeaways_sellers {
    .key_takeaways_title {
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
    }
    .key_takeaways_words {
      font-size: 16px;
      color: #666666;
      margin-top: 10px;
      width: 1000px;
      line-height: 30px;
    }
  }
}
.success_stories_box {
  text-align: left;
  margin-left: 160px;
  .case_study_box_one {
    margin-top: 40px;
    .case_study_title {
      font-size: 18px;
      font-weight: 600;
    }
    .case_study_box {
      margin-top: 20px;
      display: flex;
      .toma_img img {
        width: 250px;
        height: 250px;
        border-radius: 8px;
        object-fit: cover;
      }
      .toma_content {
        font-size: 16px;
        color: #666666;
        margin-left: 40px;
        width: 700px;
        line-height: 50px;
        margin-top: 25px;
      }
      .toma_content_two {
        font-size: 16px;
        color: #666666;
        width: 700px;
        line-height: 50px;
        margin-top: 25px;
      }
      .toma_img_two img {
        width: 250px;
        height: 250px;
        border-radius: 8px;
        object-fit: cover;
        margin-left: 50px;
      }
    }
  }
  .case_study_box_two {
    display: flex;
    margin-top: 30px;
    .case_study_img img{
      width: 250px;
      height: 250px;
      border-radius: 8px;
      object-fit: cover;
    }
    .case_study_content {
      font-size: 16px;
      color: #666666;
      margin-left: 50px;
      margin-top: 30px;
      .service-list {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .service-list li {
        position: relative;
        padding-left: 30px;
        margin-top: 15px;
      }
      .service-list li::before {
        content: "●";
        position: absolute;
        left: 0;
        color: #666666;
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }
  .fulfillment_rate_content {
    font-size: 16px;
    color: #666666;
    line-height: 30px;
    width: 1000px;
    margin-top: 20px;
  }
}
.visual_insights_box {
  text-align: left;
  margin-left: 160px;
  margin-top: 30px;
  .visual_insights_big_title {
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
  }
  .customer_testimonial_title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
  }
  .fulfillment_services {
    font-size: 16px;
    color: #666666;
    width: 1000px;
    line-height: 30px;
    margin-top: 20px;
  }
  .fashion_direct {
    font-size: 14px;
    color: #666666;
    margin-top: 10px;
    text-align: right;
    margin-right: 185px;
  }
}
.whats_next_box {
  text-align: left;
  margin-left: 160px;
  .whats_next_content_one {
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
    width: 1000px;
    line-height: 30px;
  }
}
.img_box {
  text-align: left;
  margin-left: 100px;
  display: flex;
  margin-top: 30px;
  p img {
    width: 300px;
    height: 400px;
    margin-left: 50px;
    object-fit: cover;
    border-radius: 8px;
  }
}
</style>
