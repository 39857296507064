<script>
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import { baseApiHost } from '@/utils/env'

//Sent the wrong product 发错产品
export default {
  name: 'FeedTemplate5',
  props: {
    dataSource: Object,
    imgUploadPath: String,
    afterSaleDetail: Object,
    readonly: Boolean
  },
  components: {
    FeedImageUpload
  },
  data() {
    return {
      formModel: {
        TempPath: null,
        IsPic: false,
        fileList: [],
        VideoLink: null
      }
    }
  },
  watch: {
    afterSaleDetail: {
      handler: function (newVal) {
        if (newVal) {
          this.formModel.fileList = newVal.FileList || []
          const imageList = []
          this.formModel.fileList.forEach((item) => {
            let url = ''
            if (item.Url.startsWith('/')) {
              url = baseApiHost() + item.Url
            } else {
              url = baseApiHost() + '/' + item.Url
            }
            imageList.push({
              name: item.FileName,
              url: url
            })
          })
          this.defaultFileList = imageList
          this.formModel.VideoLink = newVal.ErrorAttachedData?.VideoLink || ''
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getTemplateData() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            let data = {
              ...this.formModel,
              TempPath: this.imgUploadPath,
              IsPic: this.formModel.fileList.length > 0
            }
            resolve(data)
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <el-form ref="formRef" label-position="top" :model="formModel" :disabled="readonly">
    <el-form-item :label="$t('SupportTicket.VideoLink')">
      <el-input v-model="formModel.VideoLink"></el-input>
    </el-form-item>
    <el-form-item>
      <FeedImageUpload
        v-model="formModel.fileList"
        :img-upload-path="imgUploadPath"
        :show-example="true"
        :readonly="readonly"
      />
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped></style>
