<template>
  <iframe :src="iframeSrc" ref="myIframe" id="myIframe" frameborder="0"></iframe>
</template>

<script>
export default {
  name: 'ProductDetail',
  //import引入的组件
  components: {},
  data() {
    return {
      iframe: null
    }
  },

  mounted() {
    // 接收子iframe404页面传过来的back消息
    window.addEventListener('message', this.handleBack, false)
  },

  destroyed() {
    window.removeEventListener('message', this.handleBack, false)
  },

  computed: {
    iframeSrc() {
      const tk = sessionStorage.getItem('Authorization') || ''
      return `${process.env.VUE_APP_UD_SHOP_HOST}/productDetail/v2/${this.$route.params.goodsCode}?from=usadropIframe&tk=${tk}`
    }
  },
  //方法集合
  methods: {
    handleBack(e) {
      console.log('message来自==========>', e.origin)
      if (e.origin === process.env.VUE_APP_UD_SHOP_HOST) {
        console.log(e.origin) //子页面URL，这里是http://b.index.com
        // console.log(e.source) // 子页面window对象，全等于iframe.contentWindow
        // console.log(e.data) //子页面发送的消息
        if (e.data === 'back') {
          window.history.back()
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
#myIframe {
  width: 100%;
  height: 99%;
  position: relative;
  left: -10%;
}
</style>
