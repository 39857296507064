<template>
  <div class="scroll" :class="{ show: isActive }">
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="record_page" :class="[theme == 'common' ? '' : 'dark']">
        <div class="Filter">
          <el-input
            :placeholder="$t('DLZ.PleaseOrderNo')"
            v-model="OrderNumber"
            class="input-with-select"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search" @click="SearchOrderNumber">{{ $t('MyProduct.search') }}</button>
          <button class="searchFilter" @click="searchFilter">{{ $t('DLZ.Filter') }}</button>
        </div>
        <div>
          <!-- 高级搜索弹框 -->
          <el-dialog :visible.sync="isMore" custom-class="mydialog" :center="true">
            <el-form>
              <div class="DLZ_OrderNo_Box">
                <div class="DLZ_OrderNo">{{ $t('DLZ.OrderNo') }}</div>
                <div class="DLZ_Contact_input">
                  <el-form-item>
                    <div class="Contact_input">
                      <el-input v-model="OrderNumber" :placeholder="$t('')"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="DLZ_OrderNo_Box">
                <div class="DLZ_OrderNo">{{ $t('DLZ.RefundTime2') }}</div>
                <div class="DLZ_Refund_Time">
                  <el-form-item>
                    <div class="Contact_Time">
                      <el-date-picker
                        v-model="selectTime"
                        class="myselect"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        :range-separator="$t('orders.to')"
                        :start-placeholder="$t('')"
                        :end-placeholder="$t('')"
                        @change="timeChange"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <button @click="moreSearch" class="comBtn btn-bg0 min-wid200">
                {{ $t('orders.Confirm') }}
              </button>
            </div>
          </el-dialog>
        </div>
        <div class="records">
          <el-tabs v-model="activeName">
            <!-- Order Refunds -->
            <el-tab-pane :label="$t('DLZ.OrderRefunds')" name="recharge" class="recharge">
              <template v-if="rechargeList && rechargeList.length > 0">
                <el-table :data="rechargeList" style="width: 100%">
                  <el-table-column prop="RefundTime" align="center" :label="$t('DLZ.RefundTime2')">
                  </el-table-column>
                  <el-table-column prop="ShopName" align="center" :label="$t('DLZ.Store')">
                  </el-table-column>
                  <el-table-column prop="OrderNo" align="center" :label="$t('DLZ.OrderNo')">
                  </el-table-column>
                  <el-table-column align="center" :label="$t('DLZ.RefundAmountCurrency')">
                    <template slot-scope="scope">
                      <p>{{ scope.row.RefundAmount }}</p>
                      <p>{{ scope.row.OrderCurrency }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="RefundType" align="center" :label="$t('DLZ.RefundType')">
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="options[0].total"
                    :page-size="pageSize"
                    :current-page="options[0].page"
                    :page-sizes="[]"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                  >
                    <span class="pageSizeInput">
                      <!-- <el-input class="pageSizeBorder" v-model.number="pageSize" @input="handlePageSize"></el-input> -->
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="options[0].jumpPage" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="DefaultData">
                  <el-table style="width: 100%">
                    <el-table-column align="center" :label="$t('DLZ.RefundTime2')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.Store')"> </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.OrderNo')"> </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.RefundAmountCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.RefundType')">
                    </el-table-column>
                    <template slot="empty">
                      <div class="NodataImg">
                        <img
                          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927907151_Nodata.png"
                          alt=""
                        />
                        <div>No data</div>
                      </div>
                    </template>
                  </el-table>
                </div>
              </template>
            </el-tab-pane>
            <!-- Other Refunds -->
            <el-tab-pane :label="$t('DLZ.OtherRefunds')" name="exoenditure">
              <template v-if="OtherRefundsListList && OtherRefundsListList.length > 0">
                <el-table :data="OtherRefundsListList" style="width: 100%">
                  <el-table-column prop="RefundTime" align="center" :label="$t('DLZ.RefundTime2')">
                  </el-table-column>
                  <el-table-column prop="ShopName" align="center" :label="$t('DLZ.Store')">
                  </el-table-column>
                  <el-table-column prop="OrderNo" align="center" :label="$t('DLZ.OrderNo')">
                  </el-table-column>
                  <el-table-column align="center" :label="$t('DLZ.RefundAmountCurrency')">
                    <template slot-scope="scope">
                      <p>{{ scope.row.RefundAmount }}</p>
                      <p>{{ scope.row.OrderCurrency }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="RefundType" align="center" :label="$t('DLZ.RefundType')">
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="OtherRefundsOptions[0].total"
                    :page-size="pageSize"
                    :page-sizes="[]"
                    :current-page="OtherRefundsOptions[0].page"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                  >
                    <span class="pageSizeInput">
                      <!-- <el-input class="pageSizeBorder" v-model.number="pageSize" @input="handlePageSize"></el-input> -->
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="OtherRefundsOptions[0].jumpPage" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="DefaultData">
                  <el-table style="width: 100%">
                    <el-table-column align="center" :label="$t('DLZ.RefundTime2')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.Store')"> </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.OrderNo')"> </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.RefundAmountCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.RefundType')">
                    </el-table-column>
                    <template slot="empty">
                      <div class="NodataImg">
                        <img
                          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927907151_Nodata.png"
                          alt=""
                        />
                        <div>No data</div>
                      </div>
                    </template>
                  </el-table>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
          <el-button class="PaymentOrderExport comBtn border-col5 col-col5" @click="ExportBtn"
            >{{ $t('PaymentOrder.Export') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { baseApiHost } from '../../utils/env'
export default {
  data: () => {
    return {
      dispatch: null,
      currentPage: 1,
      page: 'recharge',
      activeName: 'recharge', //标签当前选中
      rechargeList: [], // 我的充值列表
      consumeList: [], //消费记录
      pageSize: 10,
      isActive: false,
      isMore: false,
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      OrderNumber: '', // 查询
      optionsSize: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ],
      selectTime: [],
      dialogForm: {
        RefundStartDate: '',
        RefundEndDate: ''
      },
      OtherRefundsOptions: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      OtherRefundsListList: [] // 授信列表
    }
  },
  components: {},
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.banner = this.$t('recharge.bannername')
    this.itemname = this.$t('recharge.itemname')
    this.getRechageList()
    this.OtherRefundsList()
    if (this.$route.params.type && this.$route.params.type == 'exoenditure') {
      this.activeName = this.$route.params.type
    } else {
      this.activeName = 'recharge'
    }
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = []
        }
      },
      deep: true
    }
  },
  methods: {
    SearchOrderNumber() {
      this.pageChange()
      this.OtherRefundsList()
    },
    searchFilter() {
      // 切换高级搜索
      let that = this
      this.OrderNumber = ''
      this.selectTime = ''
      that.isMore = !that.isMore
    },
    timeChange() {
      if (this.selectTime.length) {
        // 取值
        this.dialogForm.RefundStartDate = this.selectTime[0]
        this.dialogForm.RefundEndDate = this.selectTime[1]
      } else {
        this.dialogForm.RefundStartDate = ''
        this.dialogForm.RefundEndDate = ''
      }
    },
    pageChange(page) {
      //分页器页数改变
      this.options[0].page = page
      this.OtherRefundsOptions[0].page = page
      this.getRechageList()
      this.OtherRefundsList()
    },
    handleSizeChange(pageSize) {
      this.options[0].pageSize = pageSize
      this.OtherRefundsOptions[0].pageSize = pageSize
      this.getRechageList()
      this.OtherRefundsList()
    },
    getPageList() {
      //分页器跳转页数
      this.options[0].page = this.options[0].jumpPage > 0 ? Number(this.options[0].jumpPage) : 1
      this.OtherRefundsOptions[0].page =
        this.OtherRefundsOptions[0].jumpPage > 0 ? Number(this.OtherRefundsOptions[0].jumpPage) : 1
      this.getRechageList()
      this.OtherRefundsList()
    },
    handlePageSize() {
      if (!this.quotedList[0].pageSize) {
        this.quotedList[0].pageSize = 0
      }
    },
    selectChanged(pageSize) {
      this.options[0].pageSize = pageSize
      this.OtherRefundsOptions[0].pageSize = pageSize
      this.getRechageList()
      this.OtherRefundsList()
    },
    moreSearch() {
      // 多条件搜索
      this.isMore = false
      this.getRechageList()
      this.OtherRefundsList()
    },
    // 获取我的充值列表
    getRechageList() {
      // 获取充值记录
      let that = this
      that
        .dispatch('recharge/GetRefundRecordListRc', {
          OrderNo: that.OrderNumber,
          CurrentPageIndex: that.options[0].page,
          PageSize: that.options[0].pageSize,
          RecordCount: that.options[0].list.length,
          RefundStartDate: this.selectTime ? this.selectTime[0] : '',
          RefundEndDate: this.selectTime ? this.selectTime[1] : '',
          RefundType: 1
        })
        .then((res) => {
          if (res.Success) {
            that.rechargeList = res.Items
            that.options[0].total = res.TotalItems
          } else {
            that.$message(res.Msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取我的充值列表
    OtherRefundsList() {
      // 获取充值记录
      let that = this
      that
        .dispatch('recharge/GetRefundRecordListRc', {
          OrderNo: that.OrderNumber,
          CurrentPageIndex: that.OtherRefundsOptions[0].page,
          PageSize: that.OtherRefundsOptions[0].pageSize,
          RecordCount: that.OtherRefundsOptions[0].list.length,
          RefundStartDate: this.selectTime ? this.selectTime[0] : '',
          RefundEndDate: this.selectTime ? this.selectTime[1] : '',
          RefundType: 0
        })
        .then((res) => {
          if (res.Success) {
            that.OtherRefundsListList = res.Items
            that.options[0].total = res.TotalItems
          } else {
            that.$message(res.Msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ExportBtn() {
      if (this.activeName == 'recharge') {
        let that = this
        // 根据条件获取订单列表
        this.dispatch('recharge/ExportRefundRecordListRc', {
          CurrentPageIndex: that.options[0].page,
          PageSize: that.options[0].pageSize,
          RecordCount: that.options[0].list.length,
          RefundStartDate: this.selectTime ? this.selectTime[0] : '',
          RefundEndDate: this.selectTime ? this.selectTime[1] : '',
          OrderNo: that.OrderNumber,
          RefundType: 1
        })
          .then((res) => {
            if (res.Success) {
              window.open(baseApiHost() + res.Result)
            } else {
              this.$message.error(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (this.activeName == 'exoenditure') {
        let that = this
        // 根据条件获取订单列表
        this.dispatch('recharge/ExportRefundRecordListRc', {
          CurrentPageIndex: that.OtherRefundsOptions[0].page,
          PageSize: that.OtherRefundsOptions[0].pageSize,
          RecordCount: that.OtherRefundsOptions[0].list.length,
          RefundStartDate: this.selectTime ? this.selectTime[0] : '',
          RefundEndDate: this.selectTime ? this.selectTime[1] : '',
          OrderNo: that.OrderNumber,
          RefundType: 0
        })
          .then((res) => {
            if (res.Success) {
              window.open(baseApiHost() + res.Result)
            } else {
              this.$message.error(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
}
</script>

<style scoped>
.NodataImg {
  padding-bottom: 30px;
}
.NodataImg img {
  width: 170px;
  height: 170px;
  margin-top: 80px;
}
::v-deep .mydialog {
  border-radius: 20px !important;
  width: 750px;
}
.DLZ_OrderNo_Box {
  display: flex;
}
.DLZ_OrderNo {
  font-size: 16px;
  margin-top: 30px;
  margin-left: 15px;
}
.DLZ_Refund_Time {
  margin-top: 20px;
  margin-left: 30px;
}
.DLZ_Contact_input {
  margin-top: 20px;
  margin-left: 50px;
}
::v-deep .el-range-editor.el-input__inner {
  width: 550px;
  border-radius: 10px;
}
.pageSizeInput >>> .el-input__inner {
  width: 100px !important;
  height: 35px;
  margin-top: -30px;
  border-radius: 20px;
}
.Contact_input >>> .el-input__inner {
  width: 550px;
  border-radius: 10px;
}
.record_page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.records {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  /*margin: auto;*/
  padding-bottom: 32px;
  margin-top: 30px;
  position: relative;
}
::v-deep .records .el-tabs__content {
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recordsClass {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  margin: auto;
  height: 50px;
}
.InputRecords {
  height: 50px;
}
.InputRecords >>> .el-input__inner {
  height: 50px;
}
.recharge {
  background-color: #ffffff !important;
  /*border-radius: 10px !important;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.banner {
  margin-left: 20px;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
  color: #000000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__header {
  margin: 0;
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 3px;
}
::v-deep .el-tabs .el-tabs--top .el-tabs__item:hover {
  background: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页头部高度 */
::v-deep .records .recharge .el-table th,
::v-deep .el-table tr {
  height: 70px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* 内容区的高度 */
::v-deep .records .recharge .el-table__row {
  height: 80px !important;
}

.records .recharge .paging {
  margin-top: 10px;
  padding-right: 35px;
  padding-left: 32px;
}

::v-deep .records .el-tabs__content {
  border-radius: 0 0 10px !important;
}

.records .recharge .Get1 {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 110px;
  width: 35px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #000000;
  opacity: 0.3;
  border-radius: 5px;
  line-height: 28px;
  text-align: center;
  margin-right: 21px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.records .recharge .Get2 {
  position: absolute;
  bottom: 15px;
  right: 60px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  color: #000000;
  opacity: 0.6;
}
/* 分页切换 */
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* Winners搜索*/
.Filter {
  width: 1320px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  /*margin: auto;
    margin-top: 80px;*/
}
.Filter .searchFilter {
  display: inline-block;
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .searchFilter:hover {
  background: #3c3772;
}
::v-deep .Filter .input-with-select {
  width: 600px;
  height: 30px;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 30px;
}
::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 40px;
}
::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 40px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .Filter [class*=' el-icon-'],
[class^='el-icon-'] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search {
  display: inline-block;
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .search:hover {
  background: #3c3772;
}
.BackTop {
  position: fixed;
  bottom: 0px;
  right: 20px;
  cursor: pointer;
}

.BackTop img {
  width: 50px;
  height: 50px;
}
.PaymentOrderExport {
  position: absolute;
  top: 20px;
  right: 50px;
  border-radius: 50px;
  width: 180px;
  font-size: 16px;
  border: 2px solid #3f6aff;
}
.PaymentOrderExport:hover {
  background: #3f6aff !important;
  color: #fff !important;
}
</style>
