import request from '@/utils/request';

export const GetWinnerList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetWinnerList',
    data
  });
  export const TheChartsList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetWinnerList',
    data
  });
//   export const GetWinnerList = (params) => request({
//     method: "post",
//     url: '/api/WinnerProduct/GetWinnerList',
//     params
//   });
  export const GetWinnerDetail = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetWinnerDetail',
    data
  });
  export const AddToMyProduct = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/AddToMyProduct',
    data
  });

  // list
export const importPublish = (data) => {
  return request({
    url: '/api/CrawlProduct/CrawlProductAsync1688',
    method: 'post',
    params: data
  })
}

  export const GetPodList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetPodList',
    data
  });
  export const GetUSAStoreList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetUSAStoreList',
    data
  });
  export const GetClearanceList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetClearanceList',
    data
  });
  export const HasNewWinnerProduct = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/HasNewWinnerProduct',
    data
  });
  export const ReadWinnerDetail = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/ReadWinnerDetail',
    data
  });
  export const GetCountByTimePeriod = (params) => request({
    method: "get",
    url: '/api/WinnerProduct/GetCountByTimePeriod',
    params
  });
  export const GetFirstProduct = (params) => request({
    method: "get",
    url: '/api/WinnerProduct/GetFirstProduct',
    params
  });
  export const GetProductExtByProductId = (params) => request({
    method: "get",
    url: '/api/LhProductExt/GetProductExtByProductId',
    params
  });
  export const WannaExpirePopup = (params) => request({
    method: "get",
    url: '/api/LhMemberExtension/WannaExpirePopup',
    params
  });
  export const PostExpirePopup = (data) => request({
    method: "post",
    url: '/api/LhMemberExtension/PostExpirePopup',
    data
  });
  export const GetWinnerCountryList = (params) => request({
    method: "get",
    url: '/api/Common/GetWinnerCountryList',
    params
  });
  export const GetRightsWithWinnerQuote = (params) => request({
    method: "get",
    url: '/api/MemberRights/GetRightsWithWinnerQuote',
    params
  });
  export const GetWinnerPrice = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetWinnerPrice',
    data
  });
  export const GetLogisticFee = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetLogisticFee',
    data
  });
  export const CrawlProductPost = (data) => request({
    method: "post",
    url: '/api/CrawlProduct/CrawlProductPost',
    data
  });
  export const GetElectronicsList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetElectronicsList',
    data
  });
  export const GetOutdoorSportsList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetOutdoorSportsList',
    data
  });
  /* 汽车配件列表 */
  export const GetCarAccessoriesList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetCarAccessoriesList',
    data
  });
  export const GetMothersDayList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetMothersDayList',
    data
  });
  export const GetMotherBabyList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetMotherBabyList',
    data
  });
  export const GetPetsList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetPetsList',
    data
  });
  /* 展会列表 */
  export const GetExhibitionList = (data) => request({
    method: "post",
    url: '/api/WinnerProduct/GetExhibitionList',
    data
  });


