var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "searchbox" }, [
        _c("div", { staticClass: "search_input" }, [
          _c("div", { staticClass: "search_icon el-icon-search" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.OrderNo,
                expression: "OrderNo",
              },
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t("SupportTicket.PSPlease"),
            },
            domProps: { value: _vm.OrderNo },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.OrderNo = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "topbox" }, [
          _c(
            "div",
            { staticClass: "comBtn btn-bg0", on: { click: _vm.SearchOrderNo } },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Search")) + " ")]
          ),
          _c(
            "div",
            { staticClass: "comBtn btn-bg0", on: { click: _vm.BackBtn } },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Back")) + " ")]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "records" },
        [
          _c(
            "el-tabs",
            [
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.StoreName"),
                        prop: "UserName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.Order"),
                        prop: "OrderNo",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("p", [
                                  _vm._v(_vm._s(scope.row.SalesRecord)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.OrderDate"),
                        prop: "OrderTime",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("p", [_vm._v(_vm._s(scope.row.OrderTime))]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.ProductInformation"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.OrderDetailVos,
                              function (detail, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "productDetails" },
                                  [
                                    _c("div", { staticClass: "productImg" }, [
                                      _c("img", {
                                        attrs: {
                                          src: detail.ProductPictureUrl,
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "productAttribute" },
                                      [
                                        _c("p", [
                                          _vm._v(_vm._s(detail.ProductName)),
                                        ]),
                                        _c("p", [_vm._v("SKU:")]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(detail.ProductRealSku) +
                                              "  "
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(detail.Num))]
                                          ),
                                        ]),
                                        _c("p", [_vm._v("Attributes:")]),
                                        _c("p", [_vm._v(_vm._s(detail.Spec))]),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.TrackingNumber"),
                        prop: "TrackingNumber",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(scope.row.TrackingNumber || "——")
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: _vm.$t("SupportTicket.Processing"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ViewOrderDetailsBtn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewOrderDetails(
                                            scope.row.OrderId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "SupportTicket.ViewOrderDetails"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "FileQuestionBtn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileQuestion(
                                            scope.row.OrderId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("SupportTicket.FileQuestion")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.isMore,
                "custom-class": "mydialog",
                title: _vm.$t("SupportTicket.Prompt"),
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isMore = $event
                },
              },
            },
            [
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "OperationFailed" }, [
                _vm._v(
                  " Operation failed for the following reasons: This order has other issues in Processing! "
                ),
              ]),
              _c("div", { staticClass: "NoticeClick" }, [
                _c("span", [_c("i", { staticClass: "el-icon-question" })]),
                _c("span", [
                  _vm._v(
                    " Click the Yes button will jump to the issues processing page of this order "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "FunctionButton" },
                [
                  _c(
                    "el-button",
                    { attrs: { round: "" }, on: { click: _vm.CloseBtn } },
                    [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Close")) + " ")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { round: "" }, on: { click: _vm.navigateYes } },
                    [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Yes")) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }