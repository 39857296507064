<template>
  <div class="ud-video">
    <video
      v-bind="{ ...$attrs }"
      v-on="{ ...$listeners }"
      ref="udvideo"
      @seeking="handleSeeking"
      @timeupdate="handleTimeupdate"
      @seeked="handleSeeked"
    >
      <!-- 这里将事件传递给 video -->
      <source :src="src" :type="type" />
    </video>
  </div>
</template>

<script>
// import { throttle } from '@/utils/tools'

export default {
  props: {
    src: {
      type: String,
      default: '',
      required: true // 必须传递 src 属性
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    // 可以查看视频的时间，为0不限制，超出则暂停
    canSeeTime: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isSeeking: false
    }
  },
  methods: {
    // 当用户拖动进度条时
    handleSeeking() {
      const currentVideo = this.$refs.udvideo
      const videoTime = currentVideo.currentTime.toFixed(2)

      // 检查标志并处理
      if (this.isSeeking) return
      if (this.canSeeTime === 0) return

      if (videoTime > this.canSeeTime) {
        this.isSeeking = true // 设置标志
        currentVideo.currentTime = this.canSeeTime
        currentVideo.pause()
        this.$emit('canSeePause')
      }
    },
    // 当视频播放时更新进度
    handleTimeupdate(event) {
      const currentVideo = this.$refs.udvideo
      const videoTime = currentVideo.currentTime.toFixed(2)
      // 检查标志并处理
      if (this.isSeeking) {
        currentVideo.pause()
        this.$emit('canSeePause')
        event.preventDefault()
      }
      if (videoTime > this.canSeeTime) {
        this.isSeeking = true // 设置标志
        currentVideo.currentTime = this.canSeeTime
        currentVideo.pause()
      }
    },
    // 当用户完成拖动进度条时
    handleSeeked() {
      const currentVideo = this.$refs.udvideo
      if (this.isSeeking) {
        currentVideo.pause()
        currentVideo.currentTime = this.canSeeTime // 重新设置时间
        this.isSeeking = false // 重置标志
      }
      this.$emit('seekedSuccess')
    }
  }
}
</script>

<style scoped lang="scss">
.ud-video {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
}
</style>
