<template>
  <div class="particulars">
    <div class="main">
      <!-- 详情 -->
      <div>
        <!-- 标题 -->
        <div class="orderTitle">
          <span>{{ $t('SupportTicket.Order') }}</span>
          <span>{{ orderDetails.SalesRecord }}</span>
          <button class="comBtn btn-bg0" @click="BackBtn">
            {{ $t('SupportTicket.Back') }}
          </button>
        </div>
        <div class="RecipientInformationBox">
          <div class="RecipientInformation">
            <p>
              {{ $t('SupportTicket.RecipientInformation') }}
            </p>
          </div>
          <div class="TableContentBox">
            <!-- 左边内容 -->
            <div class="left-main">
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.CompanyName1') }}</div>
                <div class="The-content">{{ orderDetails.Contact }}</div>
              </div>
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.Country1') }}</div>
                <div class="The-content">{{ orderDetails.CountryNameEn }}</div>
              </div>
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.States1') }}</div>
                <div class="The-content">{{ orderDetails.Province }}</div>
              </div>
              <!-- <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.MobilePhone1') }}</div>
                <div class="The-content">{{ orderDetails.MobilePhone }}</div>
              </div> -->
            </div>
            <!-- 右边内容 -->
            <div class="left-main">
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.TelePhone1') }}</div>
                <div class="The-content">{{ orderDetails.TelePhone }}</div>
              </div>
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.City1') }}</div>
                <div class="The-content">{{ orderDetails.City }}</div>
              </div>
             <!--  <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.Province') }}</div>
                <div class="The-content">{{ orderDetails.Province }}</div>
              </div> -->
              <!-- <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.CompanyName1') }}</div>
                <div class="The-content">{{ orderDetails.Company }}</div>
              </div> -->
             <!--  <div class="left-content">
                <div class="The-recipient"></div>
                <div class="The-content"></div>
              </div> -->
              <div class="left-content">
                <div class="The-recipient">{{ $t('SupportTicket.PostCode1') }}</div>
                <div class="The-content">{{ orderDetails.ZipCode }}</div>
              </div>
            </div>
          </div>
          <div class="AddressMain">
            <div class="AddressOneContent">
              <div class="">{{ $t('SupportTicket.Address1') }}</div>
              <div style="margin-left: 10px;">{{ orderDetails.Address1 }}</div>
            </div>
            <div class="AddressTwoContent">
              <div class="">{{ $t('SupportTicket.Address2') }}</div>
              <div style="margin-left: 10px;">{{ orderDetails.Address2 }}</div>
            </div>
          </div>
          <!-- 产品明细 -->
          <div class="ProductDetailBox">
            <div class="ProductDetail">
              <span>{{ $t('SupportTicket.ProductDetail') }}</span>
              <span>({{ $t('SupportTicket.Total') }}</span>
              <span>{{ orderDetails.TotalNum }}</span>
              <span>)</span>
            </div>
            <div v-for="(detail, index) in orderDetails.OrderDetails" :key="index">
              <div class="ProductName">
                <div class="SupportTicketProductName">
                  {{ $t('SupportTicket.ProductName') }}
                </div>
                <div class="detailProductName">{{ detail.ProductName }}</div>
              </div>
              <div class="ProductSkuBox">
                <div class="ProductSku">
                  <div class="ProductSkuName">{{ $t('SupportTicket.ProductSku') }}</div>
                  <div>{{ detail.ProductRealSku }}</div>
                </div>
                <div class="Quantity">
                  <div>{{ $t('SupportTicket.Quantity') }}:</div>
                  <div style="margin-left: 10px">{{ detail.Num }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="LogisticTransitNo">
            <div class="SupportTicketLogisticTransitNoName">
              {{ $t('SupportTicket.LogisticTransitNo') }}
            </div>
            <div class="orderDetailsLogisticTransitNo">{{ orderDetails.LogisticTransitNo }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetOrderAndAddressAndDetail } from '@/api'

export default {
  data: () => {
    return {
      name: 'ViewOrderDetails',
      orderDetails: []
    }
  },

  created() {
    const orderId = this.$route.query.orderId
    if (orderId) {
      // 调用接口获取订单详情
      this.GetOrderAndAddressList(orderId)
    }
  },
  mounted() {},
  methods: {
    GetOrderAndAddressList(orderId) {
      GetOrderAndAddressAndDetail(orderId).then((res) => {
        this.orderDetails = res.data
      })
    },
    BackBtn() {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  .main {
    width: 1400px;
    padding: 0 0 32px;
  }
  .orderTitle {
    span:nth-child(1) {
      font-size: 20px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    span:nth-child(2) {
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
      color: #3f6aff;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    span:nth-child(3) {
      font-size: 20px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 10px;
    }
    span:nth-child(4) {
      font-size: 18px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 10px;
    }
    span:nth-child(5) {
      font-size: 18px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 10px;
      color: #ff0000;
    }
    button {
      margin-left: 15px;
    }
  }
  .RecipientInformationBox {
    width: 1000px;
    height: auto;
    margin-top: 30px;
    border: 1px solid #e5e5e5;
    .RecipientInformation {
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      border-bottom: 1px solid #e5e5e5;
      p {
        color: #3f6aff;
        font-size: 16px;
        line-height: 40px;
        margin-left: 20px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
    .AddressOne {
      width: 100%;
      height: 40px;
      background: #f4f7ff;
      span {
        font-size: 16px;
        line-height: 40px;
        margin-left: 20px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
    .AddressTwo {
      width: 100%;
      height: 40px;
      background: #F4F7FF;
      span {
        font-size: 16px;
        line-height: 40px;
        margin-left: 20px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
    .ProductName {
      width: 100%;
      height: auto;
      display: flex;
      background: #ffffff;
      .SupportTicketProductName {
        width: 150px;
        line-height: 40px;
        margin-left: 15px;
        font-weight: 600;
        font-size: 14px;
      }
      .detailProductName {
        margin-left: 15px;
        font-weight: 600;
        font-size: 14px;
        margin-top: 5px;
        line-height: 30px;
      }
    }
    .TableContentBox {
      width: 100%;
      height: auto;
      display: flex;
      .left-main {
        width: 500px;
        height: auto;
        border-right: 2px solid #e5e5e5;
        .left-content {
          display: flex;
        }
        .The-recipient {
          width: 250px;
          height: 40px;
          background: #F4F7FF;
          color: #000;
          font-size: 16px;
          line-height: 40px;
          padding-left: 15px;
          border-bottom: 1px solid #e5e5e5;
        }
        .The-content {
          width: 250px;
          height: 40px;
          background: #fff;
          color: #666666;
          font-size: 16px;
          line-height: 40px;
          padding-left: 15px;
          border-bottom: 1px solid #e5e5e5; 
        }
      }
    }
    .AddressMain {
      width: 100%;
      height: auto;
      .AddressOneContent {
        display: flex;
        background: #F4F7FF;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        font-size: 16px;
      }
      .AddressTwoContent {
        display: flex;
        background: #fff;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        font-size: 16px;
      }
    }
    .ProductDetailBox {
      width : 100%;
    }
    .ProductDetail {
      width: 100%;
      height: 40px;
      background: #f4f7ff;
      span:nth-child(1) {
        color: #3f6aff;
        font-size: 16px;
        line-height: 40px;
        margin-left: 15px;
      }
      span:nth-child(2) {
        color: #3f6aff;
        font-size: 16px;
        line-height: 40px;
        margin-left: 15px;
      }
      span:nth-child(3) {
        color: #ff0000;
        font-size: 16px;
        line-height: 40px;
        margin-left: 15px;
      }
      span:nth-child(4) {
        color: #3f6aff;
        font-size: 16px;
        line-height: 40px;
      }
    }
    .ProductSkuBox {
      width: 100%;
      height: auto;
      display: flex;
      background: #f4f7ff;
      border-bottom: 1px solid #ccc;
      .ProductSku {
        width: 500px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-left: 15px;
        display: flex;
      }
      .ProductSkuName {
        width: 150px;
      }
      .Quantity {
        width: 500px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-left: 15px;
        display: flex;
      }
    }
  }
  .LogisticTransitNo {
    display: flex;
    width: 100%;
    height: auto;
    background: #F4F7FF;
    .SupportTicketLogisticTransitNoName {
      height: 50px;
      line-height: 50px;
      padding-left: 10px;
      font-size: 16px;
    }
    .orderDetailsLogisticTransitNo {
      height: 50px;
      line-height: 50px;
      padding-left: 10px;
      font-size: 16px;
    }
  }
}
</style>
