import {
  GetRightsWithSourcing,
  GetRightsWithProcessOrder,
  GetRightsWithPublishProduct
} from '@/api/memberRights'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetRightsWithSourcingMr({state, commit}, params) {return GetRightsWithSourcing(params).then(res => res)},
    GetRightsWithProcessOrderMr({state, commit}, params) {return GetRightsWithProcessOrder(params).then(res => res)},
    GetRightsWithPublishProductMr({state, commit}, params) {return GetRightsWithPublishProduct(params).then(res => res)}
  }

}