<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <template>
      <!-- 内容页面 -->
      <div class="activeName_big_box_BindStore">
        <div class="activeName">
          <div class="check_type">
            <!--  <div class="Aliexpress_Product">
              <a href="/AliexpressProducts">
                <span>{{ $t('URL.AliexpressLink') }}</span>
                <span>》</span>
              </a>
            </div> -->
            <div class="Import_List">
              <a href="/ImportList">
                <span>{{ $t('URL.Import') }}&nbsp;&nbsp;{{ $t('URL.List') }}</span>
                <span>》</span>
              </a>
            </div>
            <div class="My_Product">
              <a href="/MyProduct">
                <span>{{ $t('URL.PublishedProducts') }}</span>
                <span>》</span>
              </a>
            </div>
          </div>
          <div class="itembox" v-for="(product, index) in productList" :key="product.ProductId">
            <el-tabs
              v-model="product.activeName"
              @tab-click="handleClick(product.ProductId, index)"
            >
              <el-tab-pane :label="$t('MyProduct.product')" class="first" :name="`first${index}`">
                <div class="details" v-if="productList && productList.length > 0">
                  <span class="img">
                    <img :src="product.MainPic" alt="" />
                  </span>
                  <span class="Burner">
                    <p>{{ $t('MyProduct.productName') }}</p>
                    <el-input
                      type="text"
                      v-model="product.ProductName"
                      maxlength="300"
                      show-word-limit
                    ></el-input>
                    <span class="remaining">{{ $t('MyProduct.remaining') }}</span>
                  </span>
                  <span class="URL">
                    <p>{{ $t('MyProduct.source') }}</p>
                    <el-input v-model="product.SourceUrl">
                      <button
                        class="appendBtn comBtn col-col5 nobg"
                        slot="append"
                        @click="getExamCode(product.SourceUrl)"
                      >
                        {{ $t('MyProduct.view') }}
                      </button>
                    </el-input>
                  </span>
                </div>
                <el-empty :description="$t('MyProduct.NoData')" v-else></el-empty>
              </el-tab-pane>
              <!-- second -->
              <el-tab-pane
                :label="$t('MyProduct.description')"
                :name="`second${index}`"
                class="second"
              >
                <wangEnduit
                  :boxId="`box${index}`"
                  :isClear="isClear"
                  v-model="product.details.Description"
                  @change="changeFormMarkdown"
                ></wangEnduit>
              </el-tab-pane>
              <!-- Variants页面 -->
              <el-tab-pane
                :label="$t('MyProduct.variants')"
                class="Variants"
                :name="`third${index}`"
              >
                <template v-if="product.details.SpecList && product.details.SpecList.length > 0">
                  <el-table
                    ref="multipleTable"
                    :data="product.details.SpecList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    @cell-mouse-enter="mouseEnter"
                    @cell-mouse-leave="mouseLeave"
                  >
                    <el-table-column type="selection" min-width="20%"> </el-table-column>
                    <el-table-column
                      prop="Pic"
                      align="center"
                      :label="$t('MyProduct.picture')"
                      min-width="20%"
                    >
                      <div slot-scope="scope">
                        <el-image
                          :src="scope.row.Pic"
                          :preview-src-list="srcList"
                          class="bakingpackaging"
                        >
                          <div slot="error" style="padding-top: 35px">
                            <img
                              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715925054578_feilimg.png"
                              alt=""
                            />
                          </div>
                        </el-image>
                        <el-upload
                          class="upload-demo"
                          :action="action"
                          :on-success="handleAvatarSuccess"
                          :show-file-list="false"
                          multiple
                          :headers="headersaa"
                          :file-list="fileList"
                          ref="upload"
                        >
                          <div
                            size="small"
                            type="primary"
                            class="headportrait"
                            v-show="alterbtn && rowId == scope.row.SpecId"
                            @click="revamp(scope.row)"
                          >
                            {{ $t('MyProduct.UploadPictures') }}
                            <span></span>
                          </div>
                        </el-upload>
                      </div>
                    </el-table-column>
                    <el-table-column
                      prop="SpecValue1"
                      align="center"
                      :label="product.details.Attributes[0].AttributesName"
                      min-width="20%"
                      v-if="product.HasSpec && product.Attributes.length >= 1"
                    >
                      <div slot-scope="scope" class="frame">
                        <el-input v-model="scope.row.SpecValue1" name=""></el-input>
                      </div>
                    </el-table-column>
                    <el-table-column
                      prop="SpecValue2"
                      align="center"
                      :label="product.details.Attributes[1].AttributesName"
                      show-overflow-tooltip
                      min-width="20%"
                      v-if="product.HasSpec && product.Attributes.length >= 2"
                    >
                      <div slot-scope="scope" class="frame">
                        <el-input v-model="scope.row.SpecValue2" name=""></el-input>
                      </div>
                    </el-table-column>
                    <el-table-column
                      prop="Sku"
                      align="center"
                      :label="$t('MyProduct.sku')"
                      show-overflow-tooltip
                      min-width="20%"
                    >
                      <div slot-scope="scope" class="frame">
                        <el-input name="" v-model="scope.row.Sku"></el-input>
                      </div>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      :label="$t('MyProduct.peice')"
                      :render-header="MyProductPriceBtn"
                      show-overflow-tooltip
                      min-width="20%"
                    >
                      <div slot-scope="scope" class="frame">
                        <el-input v-model="scope.row.Price" name=""></el-input>
                      </div>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      :label="$t('MyProduct.price')"
                      :render-header="renderPriceBtn"
                      show-overflow-tooltip
                      min-width="20%"
                    >
                      <div slot-scope="scope" class="frame">
                        <el-input v-model="scope.row.ComparedPrice" name=""></el-input>
                      </div>
                    </el-table-column>
                  </el-table>
                </template>
                <el-empty :description="$t('MyProduct.NoData')" v-else></el-empty>
              </el-tab-pane>
              <!--fourth  -->
              <el-tab-pane :label="$t('MyProduct.images')" :name="`fourth${index}`" class="fourth">
                <div class="upload">
                  <el-upload
                    :action="action"
                    list-type="picture-card"
                    :headers="headersaa"
                    :on-remove="handleRemove"
                    :on-success="
                      (res, file) => {
                        handleAvatarimage(index, res, file)
                      }
                    "
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="product.details.uploadimgs" alt="" />
                  </el-dialog>
                </div>
                <div class="select_demo" id="app">
                  <template
                    v-if="product.details.PhotoList && product.details.PhotoList.length > 0"
                  >
                    <transition-group>
                      <div
                        key="{{ index }}{{ item.Id }}"
                        style="display: flex; justify-content: flex-start; flex-wrap: wrap"
                      >
                        <div
                          class="select_li"
                          v-for="(pic, index) in product.details.PhotoList"
                          :key="pic.Id"
                          @click="selectFn(product.details, index)"
                        >
                          <img
                            :src="pic.Imageurl"
                            alt=""
                            referrerpolicy="origin-when-cross-origin"
                          />
                          <div class="select_box" v-if="pic.value">
                            <i class="el-icon-check"></i>
                          </div>
                          <div class="select_box_active" v-else>{{ pic.name }}</div>
                        </div>
                      </div>
                    </transition-group>
                  </template>
                  <el-empty
                    :description="$t('MyProduct.NoData')"
                    v-else-if="
                      (!product.details.PhotoList || product.details.PhotoList.length == 0) &&
                      (!product.details.uploadimgs || product.details.uploadimgs.length == 0)
                    "
                  ></el-empty>
                  <div v-else></div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="btn">
              <span>
                <button class="comBtn border-col5 nobg" @click="storage(index)">
                  {{ $t('MyProduct.save') }}
                </button>
              </span>
              <span>
                <button class="comBtn border-col5 nobg" @click="cancel(product.ProductId)">
                  {{ $t('MyProduct.remove') }}
                </button>
              </span>
              <span>
                <button class="comBtn btn-bg5" @click.stop="save(product.ProductId, index)">
                  {{ $t('MyProduct.store') }}
                </button>
              </span>
            </div>
            <div class="speed_progress" v-if="Fulfilled">
              <div class="progress_fulfilled">
                <span>{{ $t('MyProduct.ProductsPublished') }}</span>
                <span>{{ MembershipInterests.UsedTimes }}</span>
                <span>/</span>
                <span>{{ MembershipInterests.TotalTimes }}</span>
              </div>
              <el-progress
                :percentage="pmgressbar"
                style="width: 170px; margin-top: 8px"
                :show-text="false"
              ></el-progress>
              <div class="MonthlyRed" @click="MonthlyRed()">
                <span v-if="paymentList.GradeId == 1">
                  <img
                    src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715925998450_MemberJICHUSlice.png"
                    alt=""
                  />
                </span>
                <span v-if="paymentList.GradeId == 2">
                  <img
                    src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715925998599_MemberVIPSlice16.png"
                    alt=""
                  />
                </span>
                <span v-if="paymentList.GradeId == 3">
                  <img
                    src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715925998578_MemberSVIPSlice16.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <!-- 弹出框 -->
          <div class="paybox" v-if="Withdrawn">
            <div class="pay_content">
              <p class="pay_title"><span @click="hideDetail"></span></p>
              <div class="pay_items">
                <div class="updated">
                  <span style="margin-right: 20px">{{ $t('MyProduct.Store') }}</span>
                  <el-select v-model="shop" placeholder="Please select">
                    <el-option
                      v-for="item in StoreList"
                      :key="item.StoreId"
                      :label="item.StoreName"
                      :value="item.StoreId"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="according">
                  <button class="comBtn btn-bg0" @click="confirm()">
                    {{ $t('MyProduct.Confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="paging">
      <div class="pagebox">
        <PagePagination
          :total="options[0].total"
          :page-size="options[0].pageSize"
          :current-page="options[0].page"
          @current-change="pageChange"
        />
      </div>
    </div>
    <div class="pay_box" v-if="ModifyPrice">
      <div class="pay_price">
        <p class="pay_title_price"><span @click="ModifyPriceBtn"></span></p>
        <div class="pay_items">
          <div class="text_price">
            <el-input style="width: 70%" v-model="price"></el-input>
            <div class="price_btn">
              <button class="comBtn btn-bg0" @click="SubmitPrice()">
                {{ $t('MyProduct.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="compare_box" v-if="ComparePrice">
      <div class="compare_price">
        <p class="pay_title_compare_price">
          <span @click="ComparePriceBtn"></span>
        </p>
        <div class="pay_compare_items">
          <div class="text_comparePrice">
            <el-input style="width: 70%" v-model="PriceCompare"></el-input>
            <div class="price_compare_btn">
              <button class="comBtn btn-bg0" @click="SubmitPriceCompare()">
                {{ $t('MyProduct.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="member_box" v-if="memberPopupBox">
      <div class="member_set">
        <div class="member_content">
          <div class="member_top">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926321247_HuoJianAnYe.png"
              alt=""
            />
          </div>
          <div class="member_down">
            <p>{{ $t('SourcingUrl.YouHave') }}</p>
            <p>{{ $t('MyProduct.HaveReached') }}</p>
          </div>
          <div class="Bindstore">
            <p>
              <span style="color: red; font-size: 20px">*</span>
              {{ $t('MyProduct.BindMembership') }}
            </p>
          </div>
        </div>
        <div class="member_btn">
          <el-button class="" @click="ComparePriceBtn">{{ $t('MyProduct.ViewPlan') }}</el-button>
          <el-button class="" @click="AddStoreBtn">{{ $t('MyProduct.AddStore') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wangEnduit from '@/components/wangEnduit'
import { baseApiHost } from '@/utils/env'
import PagePagination from '@/components/PagePagination.vue'
import Clipboard from 'clipboard'
import { mapState } from 'vuex'
export default {
  data: () => {
    return {
      baseApiHost: baseApiHost,
      action: baseApiHost() + '/api/File/UploadImgage',
      dispatch: null,
      page: 'pending',
      value: '',
      input3: '',
      banner: '',
      itemname: '',
      text: '',
      text1: '',
      isClear: false,
      currentPage: 1,
      tableData: '',
      Withdrawn: false, //弹出框
      productList: [], // pending列表
      multipleSelection: [], // Variants选中列表
      selectPic: [], // 图片列表
      srcList: [], // 大图
      selectArr: [],
      presentId: '', //当前页的id
      presentIndex: '', //当前页的索引
      StoreList: [], // 店铺列表
      shop: '', // 已选店铺列表
      current: '', // 当前产品的索引
      fileList: [], // 上传的图片地址
      handlePreview: [], // 上传图片时当前店铺列表
      alterbtn: false, // 修改按钮显示与隐藏
      rowId: '', // 当前行id
      dialogImageUrl: [], //图片地址
      dialogVisible: false, //上传图片弹框
      dialogTableVisible: false,
      ModifyPrice: false,
      ComparePrice: false,
      memberPopupBox: false,
      price: '', // 修改的全部价格
      PriceCompare: '', // 修改全部的总金额
      pmgressbar: 0, // 进度条
      Fulfilled: true, // 进度条的显示与隐藏
      MembershipInterests: [],
      paymentList: [],
      headersaa: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      }, // token
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 3,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ]
    }
  },
  components: {
    wangEnduit,
    PagePagination
  },
  computed: {
    ...mapState(['theme', 'userInfo'])
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.banner = this.$t('MyProduct.bannername')
    this.itemname = this.$t('MyProduct.itemname')
    this.getList()
    this.globalClick(this.CloseBtn)
    this.Membership()
    this.menterData()
  },
  methods: {
    //头部三个按钮跳转地址
    /*  AliexpressProduct() {
      this.$router.push('/AliexpressProducts')
    }, */
    ImportList() {
      this.$router.push('/ImportList')
    },
    MyProduct() {
      this.$router.push('/MyProduct')
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 会员权益
    Membership() {
      let that = this
      this.dispatch('memberRights/GetRightsWithPublishProductMr')
        .then((res) => {
          this.MembershipInterests = res.Result
          if (this.MembershipInterests.TotalTimes == '-1') {
            this.Fulfilled = false
          }
          this.pmgressbar =
            (this.MembershipInterests.UsedTimes / this.MembershipInterests.TotalTimes) * 100
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CloseBtn() {
      // 点击空白页面关闭会员权益弹出框
      this.memberPopupBox = false
    },
    MonthlyRed() {
      this.$router.push('/Upgrade')
    },
    copy(item) {
      if (item == null) {
        return
      }
      var clipboard = new Clipboard('#copy_text')
      clipboard.on('success', (e) => {
        this.$message.success(this.$t('MyProduct.CopySucceeded'))
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', (e) => {
        Message({
          // 不支持复制
          message: this.$t('MyProduct.browserReplication'),
          type: 'warning'
        })
        clipboard.destroy() // 释放内存
      })
    },
    handleAvatarSuccess(res, file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      this.handlePreview.Pic = res.Result[0].FullFileName
    },
    getExamCode(item) {
      if (item) {
        window.open(item)
      }
    },
    revamp(item) {
      this.handlePreview = item
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    mouseEnter(row) {
      //鼠标移入单元格事件
      this.alterbtn = true
      this.rowId = row.SpecId //赋值行id，便于页面判断
    },
    mouseLeave(row) {
      //鼠标移出单元格事件
      this.alterbtn = false
      this.rowId = ''
    },
    pageChange(page) {
      //分页器页数改变
      this.options[0].page = page
      this.getList()
    },
    handleAvatarimage(index, res, file) {
      // image页面
      let that = this
      let list = that.productList
      let arr = that.current
      res.Result.map((item) => {
        let img = ''
        img = this.baseApiHost() + item.FullFileName
        list[index].details.uploadimgs = []
        list[index].details.uploadimgs.push(img)
      })
      that.productList = list
      that.$forceUpdate()
      let details = list[index].details // 设置商品图片列表
      let PhotoList = []
      if (details.uploadimgs.length > 0) {
        for (let i = 0; i < details.uploadimgs.length; i++) {
          //有上传的图片
          let photo = {}
          photo.Id = 0
          photo.Imageurl = details.uploadimgs[i]
          photo.IsMain = false
          photo.IsPublish = false
          photo.Sort = i
          PhotoList.push(photo)
        }
      }
      if (list[arr].details.PhotoList && list[arr].details.PhotoList.length > 0) {
        list[arr].details.PhotoList = list[arr].details.PhotoList.concat(PhotoList)
        list[arr].details.PhotoList.map((item, index) => {
          item.Sort = index
        })
        details.uploadimgs = []
      } else {
        list[arr].details.PhotoList = PhotoList
        details.uploadimgs = []
      }
    },
    handleRemove(file, fileList) {
      file.url = ''
      file.response.Result = []
    },
    selectFn(pic, index) {
      this.selectArr = pic.PhotoList
      if (this.selectArr[index].value == undefined) {
        this.selectArr[index].IsPublish = true
        this.selectArr.map((item) => {
          item.IsMain = false
        })
        this.selectArr[0].IsMain = true
        this.selectPic.push(this.selectArr[index])
        this.$set(this.selectArr[index], 'value', this.selectPic.length)
      } else {
        var i
        this.selectPic.map((item, indexs) => {
          if (item == this.selectArr[index]) {
            i = indexs
          }
        })
        this.selectArr.map((item) => {
          if (i + 1 < item.value) {
            item.value = item.value - 1
          }
        })
        this.$set(this.selectArr[index], 'value', undefined)
        this.selectPic.splice(i, 1)
      }
    },
    getList() {
      // 获取pending列表
      let that = this
      that
        .dispatch('myProduct/MemberProductListMp', {
          CurrentPageIndex: that.options[0].page,
          PageSize: that.options[0].pageSize,
          RecordCount: that.options[0].list.length,
          IsPublished: false
        })
        .then((res) => {
          let list = res.Items
          this.options[0].total = res.TotalItems
          let detail = {}
          detail.Description = ''
          detail.PicList = []
          detail.SpecList = []
          detail.PhotoList = []
          detail.isDefault = true
          list.map((item, index) => {
            item.activeName = 'first' + index
            item.details = detail
          })
          this.productList = list
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick(event, item) {
      // 获取商品详情
      let that = this
      let currentProduct = that.productList[item]
      that.current = item
      if (currentProduct.details.isDefault) {
        that
          .dispatch('pending/GetDetailPd', {
            Id: event
          })
          .then((res) => {
            if (res.Success == true && res.Result) {
              that.selectPic = []
              //   console.log(res.Result.ProductType);
              if (res.Result.ProductType == 'normal') {
                currentProduct.HasSpec = false
              } else {
                currentProduct.HasSpec = true
                if (res.Result.Attributes && res.Result.Attributes.length > 0) {
                  currentProduct.Attributes = res.Result.Attributes
                }
              }
              currentProduct.details = res.Result
              // console.log(currentProduct.details);
              this.selectArr = currentProduct.details.PhotoList
              this.selectArr.map((item) => {
                if (item.value == undefined) {
                  item.IsPublish = true
                  this.selectPic.push(item)
                  this.$set(item, 'value', this.selectPic.length)
                }
              })
              if (currentProduct.details.SpecList) {
                currentProduct.details.SpecList.forEach((item) => {
                  that.srcList.push(item.Pic)
                })
              }
              currentProduct.details.uploadimgs = []
              //that.productList = list;
              that.checked()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    checked() {
      let that = this
      let arr = that.current
      if (that.productList[arr].details.SpecList.length != null) {
        that.$nextTick(() => {
          for (let i = 0; i < that.productList[arr].details.SpecList.length; i++) {
            that.$refs.multipleTable.map((item) => {
              item.toggleRowSelection(that.productList[arr].details.SpecList[i], true)
            })
          }
        })
      }
    },
    cancel(id) {
      // 删除按钮
      let that = this
      that
        .dispatch('pending/MemberProductRemovePd', {
          Id: id
        })
        .then((res) => {
          if (res.Success == true) {
            let deleteAfterPage = Math.ceil((this.options.total - 1) / this.options.pageSize)
            let currentPage =
              this.options.page > deleteAfterPage ? deleteAfterPage : this.options.page
            this.options.page = currentPage < 1 ? 1 : currentPage
            that.getList()
            that.$message.success(that.$t('MyProduct.delete'))
          } else {
            that.$message.error(that.$t('MyProduct.Failure'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    storage(item) {
      // 保存按钮
      if (this.multipleSelection.length == 0) {
        this.$message.error(this.$t('MyProduct.checked'))
        return
      }
      if (this.selectPic.length == 0) {
        this.$message.error(this.$t('MyProduct.blank'))
        return
      }
      let arr = ''
      arr = item
      let that = this
      let list = that.productList
      that
        .dispatch('pending/MemberProductEditPd', {
          Description: that.tableData,
          SpecList: that.multipleSelection,
          PicList: [list[arr].details.PicList],
          PhotoList: that.selectPic,
          ProductId: list[arr].ProductId,
          ProductName: list[arr].ProductName,
          MainPic: list[arr].MainPic,
          SourceUrl: list[arr].SourceUrl,
          Price: list[arr].Price,
          ProductTags: list[arr].ProductTags,
          ProductType: list[arr].ProductType
        })
        .then((res) => {
          if (res.Success == true) {
            this.getList()
            this.$message.success(that.$t('MyProduct.successfully'))
          } else {
            this.$message.error(that.$t('MyProduct.VariantAmount'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 关闭弹出框
    hideDetail() {
      this.Withdrawn = false
      this.shop = ''
    },
    ModifyPriceBtn() {
      this.ModifyPrice = false
    },
    MemberBtn() {
      this.$router.push('/Upgrade')
    },
    ComparePriceBtn() {
      this.ComparePrice = false
    },
    dialogVisiblePrice() {
      this.ModifyPrice = true
    },
    dialogComparePrice() {
      this.ComparePrice = true
    },
    // 发布按钮
    confirm() {
      let arr = ''
      arr = this.current
      if (this.shop == '') {
        return this.$message.error(this.$t('MyProduct.empty'))
      }
      let that = this
      let list = that.productList
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      that
        .dispatch('pending/MemberProductEditPd', {
          ApiUserId: Number(that.shop),
          Description: this.tableData,
          SpecList: this.multipleSelection,
          PicList: [list[arr].details.PicList],
          PhotoList: this.selectPic,
          ProductId: list[arr].ProductId,
          ProductName: list[arr].ProductName,
          MainPic: list[arr].MainPic,
          SourceUrl: list[arr].SourceUrl,
          Price: list[arr].Price,
          ProductTags: list[arr].ProductTags,
          ProductType: list[arr].ProductType
        })
        .then((res) => {
          loading.close()
          if (res.Success == true) {
            this.getList()
            this.$router.push('/MyProduct')
            this.$message.success(that.$t('MyProduct.successfullyTwo'))
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.Withdrawn = false
      this.shop = ''
    },
    save(id, item) {
      this.Membership()
      if (this.MembershipInterests.Enabled == true) {
        this.current = item
        if (this.selectPic.length == 0) {
          this.$message.error(this.$t('MyProduct.confirmdescription'))
          return
        }
        if (this.multipleSelection.length != 0) {
          var hasZeroPrice = this.multipleSelection.some((item) => {
            return item.Price === 0
          })
          if (hasZeroPrice) {
            this.$message.error(this.$t('MyProduct.PriceZero'))
            this.Withdrawn = false
            return
          }
          var hasZeroComparedPrice = this.multipleSelection.some((item) => {
            return item.ComparedPrice === 0
          })
          if (hasZeroComparedPrice) {
            this.$message.error(this.$t('MyProduct.ComparedPriceZero'))
            this.Withdrawn = false
            return
          }
        }
        let that = this
        this.Withdrawn = true
        that
          .dispatch('store/GetListSt', {
            storeType: 'Shopify'
          })
          .then((res) => {
            that.StoreList = res.Result
          })
          .catch((err) => {
            console.log(err)
          })
        this.memberPopupBox = false
      } else {
        this.memberPopupBox = true
      }
    },
    changeFormMarkdown(val) {
      this.tableData = val
    },
    // 修改全部价格
    SubmitPrice() {
      if (this.price == '') {
        return this.$message(this.$t('MyProduct.PleasePrice'))
      }
      this.productList[this.current].details.SpecList.map((item) => {
        item.Price = this.price
      })
      this.$message.success(this.$t('MyProduct.ModifiedSuccessfully'))
      this.price = ''
      this.ModifyPrice = false
    },
    SubmitPriceCompare() {
      if (this.PriceCompare == '') {
        return this.$message(this.$t('MyProduct.ModifiedSuccessfully'))
      }
      this.productList[this.current].details.SpecList.map((item) => {
        item.ComparedPrice = this.PriceCompare
      })
      this.$message.success(this.$t('MyProduct.ModifiedSuccessfully'))
      this.PriceCompare = ''
      this.ComparePrice = false
    },
    //修改全部价格图标
    renderPriceBtn(h, { column, $index }) {
      return h('div', [
        h('span', column.label),
        h('button', {
          style: 'border: none;background: none;',
          on: {
            click: this.dialogComparePrice
          },
          domProps: {
            innerHTML: '<i class="el-icon-edit"></i>'
          }
        })
      ])
    },
    //修改全部价格图标
    MyProductPriceBtn(h, { column, $index }) {
      return h('div', [
        h('span', column.label),
        h('button', {
          style: 'border: none;background: none;',
          on: {
            click: this.dialogVisiblePrice
          },
          domProps: {
            innerHTML: '<i class="el-icon-edit"></i>'
          }
        })
      ])
    }
  }
}
</script>
<style></style>
<style lang="scss" scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  min-width: 1320px;
  background-color: #eff1f3 !important;
  font-family: 'Regular';
}
.activeName {
  padding-bottom: 10px;
  width: 1320px;
  position: relative;
  overflow: hidden;
}
.nobg {
  border-style: solid;
  border-width: 2px;
  color: #3f6aff !important;
}
.btn {
  position: absolute;
  top: 20px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nobg {
    @extend .nobg;
    margin-right: 20px;
  }
}
.btn .nobg:hover {
  background: #3f6aff;
  color: #fff !important;
}
.appendBtn {
  width: 90px;
  margin-left: -41px;
  height: 100%;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  padding: 0px;
}
.check_type {
  width: 100%;
  height: 60px;
  display: flex;
}
.Aliexpress_Product {
  width: 200px;
  height: 40px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #000;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 40px;
    color: #15104b;
    font-size: 16px;
  }
  span:nth-child(2) {
    margin-left: 20px;
    color: #15104b;
    font-weight: 600px;
    line-height: 40px;
  }
}
.Import_List {
  width: 200px;
  height: 40px;
  background: #15104b;
  color: #ffffff !important;
  margin-left: 15px;
  border-radius: 50px;
  cursor: pointer;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #000;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 50px;
    color: #ffffff;
    font-size: 14px;
  }
  span:nth-child(2) {
    margin-left: 28px;
    color: #ffffff;
  }
}
.My_Product {
  width: 200px;
  height: 40px;
  background: #ffffff;
  margin-left: 35px;
  border-radius: 50px;
  cursor: pointer;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #000;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 15px;
    color: #15104b;
    font-size: 14px;
  }
  span:nth-child(2) {
    margin-left: 5px;
    color: #15104b;
  }
}
.MonthlyRed {
  position: absolute;
  top: 0px;
  left: 220px;
  cursor: pointer;
  span img {
    width: 25px;
    height: 25px;
  }
  span img:hover {
    transform: scale(1.2);
  }
}
.member_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .member_set {
    width: 380px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    left: calc(60% - 400px);
    top: calc(50% - 450px);
    margin-top: 160px;
    background-color: #2a293e;
    height: 500px;
    border-radius: 10px;
    position: relative;
  }
  .member_top {
    width: 230px;
    height: 220px;
    margin-left: 50px;
    img {
      width: 100%;
      height: 100%;
      margin-top: 35px;
      margin-left: 20px;
    }
  }
  .member_down {
    width: 380px;
    margin-top: 0px;
    p:nth-child(1) {
      text-align: center;
      margin-top: 50px;
      font-size: 18px;
      color: #fff;
    }
    p:nth-child(2) {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      color: #fff;
    }
  }
  .Bindstore p:nth-child(1) {
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 40px;
    word-break: normal;
  }
  .member_btn {
    margin-left: 55px;
    width: 150px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    margin-top: 15px;
    display: flex;
    ::v-deep .el-button:nth-child(1) {
      margin-left: 5px;
      height: 45px;
      margin-bottom: -150px;
      border-radius: 50px !important;
      font-size: 16px;
      color: #fff;
      background: #93df89;
      border: 1px solid #93df89;
    }
    ::v-deep .el-button:nth-child(2) {
      margin-left: 30px;
      height: 45px;
      border-radius: 50px;
      font-size: 16px;
      color: #fff;
      background: #93df89;
      border: 1px solid #93df89;
    }
    ::v-deep .el-button:nth-child(1):hover {
      background: #bff4b8;
      color: #fff;
    }
    ::v-deep .el-button:nth-child(2):hover {
      background: #bff4b8;
      color: #fff;
    }
  }
}
.speed_progress {
  width: 215px;
  height: 60px;
  position: absolute;
  top: 22px;
  left: 515px;
  .progress_fulfilled {
    span:nth-child(1) {
      font-size: 14px;
    }
    span:nth-child(2) {
      font-size: 14px;
      margin-left: 30px;
    }
    span:nth-child(3) {
      font-size: 14px;
      margin-left: 5px;
    }
    span:nth-child(4) {
      font-size: 14px;
      margin-left: 6px;
    }
  }
}
::v-deep .el-progress__text {
  font-size: 14px;
  color: #ccc !important;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 1;
  background: #ccc !important;
}
.compare_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .compare_price {
    width: 300px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    position: absolute;
    left: calc(69% - 10px);
    top: calc(50% - 300px);
    margin-top: 160px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    ::v-deep .el-input__inner {
      width: 100%;
      height: 30px;
      margin-top: -30px;
    }
  }
  .pay_title_compare_price {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    padding-left: 42px;
    padding-right: 32px;
    background: #ffffff;
    span {
      width: 10px;
      height: 10px;
      line-height: 10px;
      display: block;
      background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png')
        no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      float: right;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .pay_compare_items {
    width: 459px;
    height: 170px;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    background-color: #fff;
    margin-top: 32px;
    margin-left: 31px;
  }
  .text_comparePrice {
    width: 245px;
    height: 30px;
    margin-top: -30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.price_compare_btn {
  text-align: center;
  float: right;
  button {
    width: 60px;
    height: 25px;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Regular';
    padding: 0 2px !important;
  }
}
.pay_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .pay_price {
    width: 300px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    position: absolute;
    left: calc(60% - 80px);
    top: calc(50% - 300px);
    margin-top: 160px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    ::v-deep .el-input__inner {
      width: 100%;
      height: 30px;
      margin-top: -30px;
    }
  }
  .pay_title_price {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    padding-left: 42px;
    padding-right: 13px;
    background: #ffffff;
    span {
      width: 10px;
      height: 10px;
      line-height: 10px;
      display: block;
      background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png')
        no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      float: right;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .price_btn {
    height: 30px;
    line-height: 30px;
    text-align: center;
    float: right;
    button {
      width: 60px;
      height: 25px;
      color: #fff;
      font-size: 14px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      padding: 0 2px !important;
    }
  }
  .text_price {
    width: 245px;
    height: 30px;
    margin-top: -30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.itembox {
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 15px;
  position: relative;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0 !important;
  padding-right: 60px !important;
}
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 10px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #eff1f3 !important;
}
.first {
  width: 1320px;
  background-color: #fff;
}
.details {
  height: 315px;
  position: relative;
}
.img {
  display: inline-block;
  width: 250px;
  height: 250px;
  padding-top: 30px;
  margin-left: 32px;
  img {
    width: 260px;
  }
}
.Burner {
  position: absolute;
  top: 50px;
  left: 380px;
  height: 100px;
  font-size: 14px;
  ::v-deep .el-input {
    width: 900px;
    margin-top: 16px;
  }
  ::v-deep .el-input__inner {
    height: 48px !important;
    border-radius: 12px !important;
  }
  ::v-deep .el-input .el-input__count {
    position: absolute;
    top: 35px;
    left: -80px;
  }
  .remaining {
    margin-top: 5px;
    margin-left: 680px;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #010101;
    opacity: 0.4;
  }
}
.URL {
  /* URL */
  position: absolute;
  top: 180px;
  left: 380px;
  height: 100px;
  font-size: 14px;
  ::v-deep .el-input {
    width: 895px;
    margin-top: 16px;
  }
  ::v-deep .el-input__inner {
    height: 48px !important;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  ::v-deep .el-input-group__append {
    padding: 0;
    border: 0;
    background-color: #ffffff;
  }
}
.pagebox {
  width: 100%;
  height: 88px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
}
.el-table::before {
  background: #dbdbe4;
}
::v-deep .w-e-text-container {
  border: 0 !important;
}
::v-deep .w-e-text {
  padding: 0 10px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
}
.Variants {
  position: relative;
  .paging {
    padding-top: 30px;
    padding-right: 35px;
    padding-left: 32px;
  }
  .headportrait {
    color: #6788ff;
  }
}
::v-deep .Variants .el-table td,
.el-table th.is-leaf {
  text-align: center !important;
}
::v-deep .Variants .el-table th > .cell {
  text-align: center !important;
}
::v-deep .el-tabs__content {
  background-color: #fff !important;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
::v-deep .el-select .el-input__inner {
  font-weight: 100;
}
.Variants .headportrait span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #6788ff;
}
::v-deep .Variants .frame .el-input__inner {
  text-align: center;
  width: 140px;
  border-radius: 25px;
}
::v-deep .Variants .el-table th,
.el-table tr {
  height: 64px;
}
::v-deep .el-table thead {
  color: rgb(0, 0, 0, 0.8);
}
::v-deep .el-table {
  color: rgb(0, 0, 0, 0.8);
}
/* 表单高度 */
::v-deep .el-table__row {
  height: 130px;
}
::v-deep .el-table__body-wrapper .is-scrolling-none {
  height: 100% !important;
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
}
::v-deep .el-tabs__active-bar {
  color: #6788ff;
}
/*fourth  */
.fourth {
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}
.upload {
  width: 250px !important;
  display: contents;
}
::v-deep .upload .el-upload--picture-card {
  width: 250px !important;
  height: 240px !important;
  line-height: 240px !important;
}
.select_demo {
  height: 32vw;
  overflow-y: auto;
  width: 1030px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 250px !important;
  height: 240px !important;
}
::v-deep .el-upload-list--picture-card {
  display: none;
}
.bakingpackaging {
  width: 90px;
  height: 90px;
}
.select_li {
  position: relative;
  float: left;
  width: 160px;
  height: 160px;
  cursor: pointer;
  background-position: center 0 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-bottom: 10px;
  margin-left: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.select_box,
.select_box_active {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 0;
  top: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select_box {
  background: #04bd00;
}
.el-icon-check {
  font-size: 20px !important;
  color: #ffffff;
}
.pagebox {
  /* 分页 */
  width: 1320px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
}
.paging {
  width: 100%;
  padding-bottom: 30px;
}
.paybox {
  /* 弹出框 */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .pay_content {
    width: 500px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    left: calc(58% - 420px);
    top: calc(50% - 380px);
    margin-top: 160px;
    background-color: #fff;
    height: 200px;
  }
  .pay_title {
    width: 100%;
    height: 33px;
    line-height: 80px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    padding-left: 42px;
    padding-right: 32px;
    background: #ffffff;
    span {
      width: 15px;
      height: 16px;
      display: block;
      background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png')
        no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      float: right;
      margin-top: 25px;
      cursor: pointer;
    }
  }
  .pay_items {
    width: 459px;
    height: 170px;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    background-color: #fff;
    margin-top: 32px;
    margin-left: 31px;
  }
  .updated {
    margin-bottom: 30px;
    text-align: center;
    ::v-deep .el-input__inner {
      border-radius: 20px;
    }
    span {
      font-size: 16px;
    }
    ::v-deep .el-select {
      text-align: center;
      font-weight: 900;
      width: 300px;
    }
  }
  .according {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    button {
      width: 125px;
      height: 38px;
    }
  }
}
.el-input .el-input__count .el-input__count-inner {
  padding: 0px;
}
</style>
