<template>
  <div class="content-left">
    <div class="login-info">
      <img :src="logoImg[formLink]" alt="" class="logo1" />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2" style="margin-top: 50px">Launch Your</div>
        <div class="ft-40 fw-800 color1 mb-24">7-Figure Dropshipping</div>
        <div class="ft-40 fw-800 color1 mb-24">Journey Today!</div>
        <div class="ft-32 fw-700 color2 mb-48">Master the essentials to build a profitable online store.</div>
        <div class="btnContent">
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Building Your Store</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Finding Winning Products</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Advertising Strategies</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Leveraging USAdrop</div>
          </div>
        </div>
        <div class="ft-28 color2 mb-48 fw-600">
          Unlock everything you need to succeed with USAdrop.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('~@/assets/imgs/loginBg.png') no-repeat;
  background-size: cover;
  //   text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-info {
    // width: 60%;
    margin-right: 80px;
    text-align: left;
    margin-top: -6%;
    .logo1 {
      width: 215px;
      height: 128px;
      margin-bottom: 40px;
    }
    .welcome-title {
    }
    .WelcomeTo {
      margin-top: 50px;
    }
  }
  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .logo2 {
      width: 90px;
      height: 64px;
      position: absolute;
      left: 40px;
      margin-bottom: 30px;
    }
    .login-head {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      margin-top: 104px;
      margin-bottom: 40px;
      text-align: center;
    }
    .forget {
      width: 100%;
      text-align: right;
      position: absolute;
      top: 44px;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        // box-shadow: none;
        border-radius: 50px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
      }
    }
    .login-bottom {
      margin-top: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .el-button {
        width: 226px;
        height: 44px;
        background: $color2;
        font-size: 16px;
        margin-bottom: 8px;
        color: #ffffff;
        border-radius: 30px;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
    }

    .demo-ruleForm {
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
.btnContent {
  display: flex;
  width: 800px;
  flex-wrap: wrap;
  justify-content: space-between;
  .btnInfo {
    width: 400px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    .content-icon {
      width: 40px;
      height: 40px;
      background: $color2;
      ::v-deep .el-icon-check{
        font-size: 26px;
        color: #fff;
        line-height: 40px;
        margin-left: 7px;
        font-weight: 800;
      }
    }
    .content-words {
      font-size: 24px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
</style>
