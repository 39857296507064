<template>
  <div></div>
</template>

<script>
import Cookies from "js-cookie";
export default {
    data:()=>{
        return {
            text: "Loading"
        }
    },
    created(){
      console.log('d=11111')
     /*  console.log(location.pathname, '111111111') */
        let that = this;
        let loading = that.$loading({
            lock: true,
            text: that.text,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
        });

        // 获取授权时获取的state code
        let search = decodeURIComponent(location.search);
        if (search) {
          console.log('d=11111'.search)
            let params = {};
            search = search.substring(1).split("&");
           // console.log(search);
            search.map(item => {
                let index = item.indexOf("=");
                let key = item.substring(0, index);
                let value = item.substring(index + 1).split("#")[0];
                params[key] = value;
            });
            if(params.d != undefined){
                localStorage.setItem('CofunderCode', params.d);
                Cookies.set("CofunderCode", params.d);
                if (localStorage.getItem("CofunderCode") != undefined && localStorage.getItem("CofunderCode") != "undefined") {
                    loading.close();
                    if (params.register != undefined) {
                      window.location.href = 'https://app.usadrop.com/register?from=pay'
                    }
                }
            }
        }
        that.$message("The link has expired")
        loading.text = "The link has expired"
    }
}
</script>
