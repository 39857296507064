<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <meta name="referrer" content="never"></meta>
    <!-- 详情页面 -->
    <!-- 搜索 -->
    <!--     <div class="Filter">
      <el-input :placeholder="$t('Quoted.orders')" v-model="input3" class="input-with-select">
        <el-button slot="prepend" icon="el-icon-search"></el-button>
      </el-input>
      <button class="search">{{ $t("Quoted.search") }}</button>
    </div> -->
    <!-- 内容 -->
    <div class="activeName">
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <!-- quoting -->
        <el-tab-pane :label="$t('Sourcing.quoting')" class="first" name="0">
          <template v-if="quotedList[0].list && quotedList[0].list.length > 0">
            <el-table :data="quotedList[0].list" style="width: 100%">
              <el-table-column align="center" :label="$t('Sourcing.sourcing')">
                <div slot-scope="scope" class="content">
                  <img :src="scope.row.MainPic" alt=""/>
                  <span class="bakingpackaging">{{ scope.row.ProductUrl }}</span>
                  <button
                    @click="copy"
                    :data-clipboard-text="scope.row.ProductUrl"
                    class="search_btn"
                    id="copy_text"
                  ></button>
                  <span class="Blue">{{ scope.row.CreateTime }}</span>
                  <!--  <span class="Cancel">{{ scope.row.Plantform }}</span> -->
                </div>
              </el-table-column>
              <el-table-column align="center" :label="$t('Sourcing.status')">
                <div slot-scope="scope">
                  <el-tooltip :content="$t('Sourcing.quoted')" placement="right">
                    <span v-if="scope.row.QuoteStatus == 1">
                      <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588467252_qouted.png" alt=""/>
                    </span>
                  </el-tooltip>
                  <el-tooltip :content="$t('Sourcing.NoResult')" placement="right">
                    <span v-if="scope.row.QuoteStatus == -1">
                      <!-- No sourcing -->
                      <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588467378_quoteding.png" alt=""/>
                    </span>
                  </el-tooltip>
                  <el-tooltip :content="$t('Sourcing.quoting')" placement="right">
                    <span v-if="scope.row.QuoteStatus == 0">
                      <!-- quotiing -->
                      <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588467412_Noquoted.png" alt=""/>
                    </span>
                  </el-tooltip>
                </div>
              </el-table-column>
              <el-table-column align="center">
                <div slot-scope="{}">
                  <!--slot-scope="scope"-->
                  <p style="font-family: Regular; -webkit-font-smoothing: antialiased">
                    {{ $t("Sourcing.SalesmanAgent") }}
                  </p>
                  <!-- <button class="btn" @click="btn(scope.row.Id)">{{ $t("Quoted.Import") }}</button> -->
                </div>
              </el-table-column>
            </el-table>
          </template>
          <el-empty :description="$t('Sourcing.NoData')" v-else></el-empty>
           <div class="pagebox">
            <PagePagination
                :total="quotedList[0].total"
                :page-size="quotedList[0].pageSize"
                :current-page="quotedList[0].page"
                @current-change="pageChange"
              />
          </div>
          <!-- <div class="pagebox">
            <el-pagination
              layout="prev, pager, next"
              :total="quotedList[0].total"
              :page-size="8"
              :current-page="quotedList[0].page"
              @current-change="pageChange"
            >
            </el-pagination>
            <div @keyup.enter="getPageList(1)" class="page_sign">
              <span>{{ $t("Quoted.get2") }}</span>
              <input type="text" v-model="quotedList[1].jumpPage"/>
            </div>
          </div> -->
        </el-tab-pane>
        <!-- quoted -->
        <el-tab-pane :label="$t('Sourcing.quoted')" class="second" name="1">
          <template v-if="quotedList[1].list && quotedList[1].list.length > 0">
            <el-table :data="quotedList[1].list" style="width: 100%">
              <el-table-column align="center" :label="$t('Sourcing.sourcing')">
                <div slot-scope="scope" class="content">
                  <img :src="scope.row.MainPic" alt=""/>
                  <span class="bakingpackaging">{{ scope.row.ProductUrl }}</span>
                  <button
                    @click="copy"
                    :data-clipboard-text="scope.row.ProductUrl"
                    class="search_btn"
                    id="copy_text"
                  ></button>
                  <span class="Blue">{{ scope.row.CreateTime }}</span>
                  <!--  <span class="Cancel">{{ scope.row.Plantform }}</span> -->
                </div>
              </el-table-column>
              <el-table-column align="center" :label="$t('Sourcing.status')">
                <div slot-scope="scope">
                  <!-- quoted -->
                  <span v-if="scope.row.QuoteStatus == -1">
                    <!-- No sourcing -->
                    <!-- <el-tooltip v-if="scope.row.IsDisplayQuote == true" content="No sourcing result" >
                      <img src="../../assets/imgs/Quoted/quoteding.png" alt="">
                    </el-tooltip> -->
                    <span v-if="scope.row.IsDisplayQuote == true">
                      <p style="color: red">{{ $t('Sourcing.NoResult') }}</p>
                      <p>{{ $t('Sourcing.SalesmanAgent') }}</p>
                    </span>
                    <el-tooltip v-else content="Quoted">
                      <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588467252_qouted.png" alt=""/>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <el-tooltip content="Quoted">
                      <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588467252_qouted.png" alt=""/>
                    </el-tooltip>
                  </span>
                </div>
              </el-table-column>
              <el-table-column align="center">
                <div slot-scope="scope" class="signBox">
                  <button
                    class="btn comBtn border-col5 col-col5"
                    @click="btn(scope.row.Id, scope.row.IsDisplayQuote, scope.row.QuoteStatus)"
                  >
                    {{ $t("Sourcing.views") }}
                  </button>
                  <div
                    class="signContent"
                    v-if="scope.row.IsViewDetail === false && scope.row.QuoteStatus === 1"
                  ></div>
                </div>
              </el-table-column>
            </el-table>
            <div class="pagebox">
              <div class="pagebox">
              <PagePagination
                  :total="quotedList[1].total"
                  :page-size="quotedList[1].pageSize"
                  :current-page="quotedList[1].page"
                  @current-change="pageChange"
                />
            </div>
              <!-- <el-pagination
                layout="prev, pager, next"
                :total="quotedList[1].total"
                :page-size="8"
                :current-page="quotedList[1].page"
                @current-change="pageChange"
              >
              </el-pagination>
              <div @keyup.enter="getPageList(1)" class="page_sign">
                <span>{{ $t("Quoted.get2") }}</span>
                <input type="text" v-model="quotedList[1].jumpPage"/>
              </div> -->
            </div>
          </template>
          <el-empty :description="$t('Sourcing.NoData')" v-else></el-empty>
        </el-tab-pane>
      </el-tabs>
      <!-- <el-button v-if="paymentList.IsAdmin == true" class="orders comBtn btn-bg5" @click.stop="SourcingBtn">{{ $t("Sourcing.SourcingAliexpressProducts") }}
      </el-button> -->
      <div class="speed-progress" v-if="Fulfilled">
        <div class="progress-fulfilled">
          <span>{{ $t("Sourcing.ProductsSourced") }}</span>
          <span>{{ MembershipInterests.UsedTimes }}</span>
          <span>/</span>
          <span>{{ MembershipInterests.TotalTimes }}</span>
        </div>
        <el-progress
          :percentage="pmgressbar"
          style="width: 170px; margin-top: 8px"
          :show-text="false"
        ></el-progress>
        <div class="MonthlyRed" @click="MonthlyRed()">
          <span v-if="paymentList.GradeId === 1">
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576583_MemberJICHUSlice.png" alt=""/>
          </span>
          <span v-if="paymentList.GradeId === 2">
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576645_MemberVIPSlice16.png" alt=""/>
          </span>
          <span v-if="paymentList.GradeId === 3">
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576615_MemberSVIPSlice16.png" alt=""/>
          </span>
        </div>
        <div
          v-if="MembershipInterests.DayLimit&& MembershipInterests.DayRemainTimes === 0"
          class="IconQuestion"
        >
          <i class="el-icon-question" @mouseenter="enter" @mouseleave="leave"></i>
        </div>
        <div class="DisplayInfo" v-if="displayUSAdropInfo">
          <p>{{ $t("Sourcing.HaveToday") }}</p>
          <p>{{ $t("Sourcing.SalesmanAgent") }}</p>
        </div>
      </div>
    </div>
    <!-- 会员费 -->
    <div class="member_box" v-if="memberPopupBox">
      <div class="member_set">
        <div class="member_content">
          <div class="member_top">
            <!-- <img src="../../assets/imgs/UpgradeToPro/HuoJianAnYe.png" alt=""> -->
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591322032_MemberHuoJian.png" alt=""/>
          </div>
          <div class="member_down">
            <p>{{ $t("SourcingUrl.YouHave") }}</p>
            <p>{{ $t("Sourcing.Upgrade") }}</p>
          </div>
          <div class="Bindstore">
            <p>
              <span style="color: red; font-size: 20px">*</span>
              {{ $t("Sourcing.BindMembership") }}
            </p>
          </div>
        </div>
        <div class="member_btn">
          <el-button @click="ComparePriceBtn">{{ $t("Sourcing.ViewPlan") }}</el-button>
          <el-button @click="AddStoreBtn">{{ $t("Sourcing.AddStore") }}</el-button>
        </div>
      </div>
    </div>
    <!-- 联系业务员 -->
    <div class="Agent_box" v-if="displayAgent">
      <div class="Agent_set">
        <div class="member_Close_btn" @click="CloseAgentBtn">
          <i class="el-icon-remove-outline"></i>
        </div>
        <div class="Agent_content">
          <div class="Agent_top">
            <p><img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591372997_ziyuan1.png" alt=""/></p>
          </div>
          <div class="Agent_down">
            <p>{{ $t("Sourcing.Reached") }}</p>
            <p>{{ $t("Sourcing.pleaseTomorrow") }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 未绑定店铺7天和会员费过期弹窗 -->
    <MemberBindBox ref="child"></MemberBindBox>
  </div>
</template>
<script>
import MemberBindBox from "@/components/MemberBindBox.vue";
import Clipboard from "clipboard";
import PagePagination from '@/components/PagePagination.vue';
import {mapState} from "vuex";

export default {
  name: 'Sourcinghistory',
  data: () => {
    return {
      dispatch: null,
      page: "Sourcing",
      input3: "",
      activeName: "0", //标签切换
      banner: "",
      itemname: "",
      ComparePrice: false,
      MembershipInterests: [],
      memberPopupBox: false, // 会员费
      displayAgent: false, // 业务员
      pmgressbar: 0, // 进度条
      paymentList: [],
      Fulfilled: true, // 进度条的显示与隐藏
      quotingList: [
        //引入中列表
      ],
      // 数据列表
      quotedList: [
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
        {
          type: 1,
          list: [],
          page: 1,
          pageSize: 8,
          total: 0,
          jumpPage: 1,
        },
        {
          type: -1,
          list: [],
          page: 1,
          pageSize: 8,
          total: 0,
          jumpPage: 1,
        },
      ],
      date: "",
      currentPage: 1,
      displayUSAdropInfo: false,
      BindStoreList: [], // 是否绑定店铺
    };
  },
  components: {
    MemberBindBox,
    PagePagination
  },
  computed: mapState(["userInfo", "theme"]),
  created() {
    this.dispatch = this.$store.dispatch;
    if (this.$route.params.type === "1") {
      this.activeName = this.$route.params.type;
    } else {
      this.activeName = "0";
    }
  },
  mounted() {
    //this.globalClick(this.CloseBtn);
    this.banner = this.$t("Quoted.bannername");
    this.itemname = this.$t("Quoted.itemname");
    this.getData();
    this.Membership();
    this.menterData();
    this.CheckGuide();
  },
  methods: {
    enter: function (e) {
      this.displayUSAdropInfo = true;
    },
    leave: function (e) {
      this.displayUSAdropInfo = false;
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          // 价格重置
          this.paymentList = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Membership() {
      let that = this;
      this.dispatch('memberRights/GetRightsWithSourcingMr')
        .then((res) => {
          that.MembershipInterests = res.Result;
          that.pmgressbar =
            (that.MembershipInterests.UsedTimes / that.MembershipInterests.TotalTimes) * 100;
          if (this.MembershipInterests.TotalTimes === "-1") {
            this.Fulfilled = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ComparePriceBtn() {
      this.$router.push("/Upgrade");
    },
    MonthlyRed() {
      this.$router.push("/Upgrade");
    },
    AddStoreBtn() {
      this.$router.push("/Store");
    },
    CloseAgentBtn() {
      this.displayAgent = false;
    },
    CloseBtn(event) {
      this.memberPopupBox = false;
    },
    pageChange(page) {
      //分页器页数改变
      this.quotedList[this.activeName].page = page;
      this.getData();
    },
    getPageList(type) {
      //分页器跳转页数
      this.quotedList[type].page =
        this.quotedList[type].jumpPage > 0 ? Number(this.quotedList[type].jumpPage) : 1;
      this.getData();
      console.log("226556");
    },
    changeTab(item) {
      // 标签栏切换
      let index = Number(item.index);
      if (this.quotedList[index].list.length === 0) {
        this.getData();
      }
    },
    btn(row, IsDisplayQuote, QuoteStatus) {
      // 商品详情
      if (IsDisplayQuote && QuoteStatus === 1) {
        let routeData = this.$router.resolve({
          path: "/ViewDetails",
          query: {
            id: Number(row),
            type: 1, // 1 Quoted详情
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$message.warning(this.$t("Sourcing.businessHours"));
      }
    },
    SourcingBtn() {
      if (this.BindStoreList.More7daysNoStoreOrNoGrade) {
        this.CheckGuide();
      } else {
        this.Membership();
        if (this.MembershipInterests.Enabled) {
          this.$router.push("SourcingURL");
        } else {
          if (this.MembershipInterests.RemainTimes === 0) {
            this.memberPopupBox = true;
          } else {
            this.displayAgent = true;
          }
        }
      }
    },
    getData() {
      let that = this;
      let type = Number(that.activeName);
      this.dispatch('quoted/LhSourcingQuoteListQt', {
        QuoteStatus: that.quotedList[type].type, //-1：无法报价 0：待报价 1 ：已报价
        CurrentPageIndex: that.quotedList[type].page,
        PageSize: that.quotedList[type].pageSize,
        RecordCount: that.quotedList[type].list.length
      })
        .then((res) => {
          let list = that.quotedList;
          list[type].list = res.Items;
          list[type].list.map((item) => {
            item.ProductUrl = item.ProductUrl.split("html")[0] + "html";
          });
          list[type].total = res.TotalItems;
          that.quotedList = list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 复制按钮
    copy() {
      var _this = this;
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        this.$message.success(this.$t('Sourcing.CopySucceeded'));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.warning($t('Sourcing.TheReplication'));
        // 不支持复制
       /*  Message({
          message: "The browser does not support automatic replication",
          type: "warning",
        }); */
        // 释放内存
        clipboard.destroy();
      });
    },
    /* 是否绑定店铺 */
    CheckGuide() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result;
          if (res.Result.More7daysNoStoreOrNoGrade) {
            this.$refs.child.handelMember();
          } else {
            /* id && this.goDetail(); */
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
::v-deep .el-progress-bar__outer {
  height: 6px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
</style>

<style scoped>
.IconQuestion {
  position: absolute;
  top: 2px;
  left: 260px;
}

::v-deep .IconQuestion .el-icon-question {
  font-size: 20px;
  color: #3F6AFF;
}

.DisplayInfo {
  width: 360px;
  height: 55px;
  background: #93df89;
  border-radius: 10px;
  /*margin-left: 140px;*/
  position: absolute;
  left: 285px;
  top: -15px;
  z-index: 9;
}

.DisplayInfo p:nth-child(1) {
  font-size: 14px;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
  padding-left: 10px;
}

.DisplayInfo p:nth-child(2) {
  font-size: 14px;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
}

.member_title {
  width: 12px;
  height: 12px;
  background: url("../../assets/imgs/close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0px;
  cursor: pointer;
  /* background: orange; */
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
}

.MonthlyRed {
  position: absolute;
  top: 0px;
  left: 225px;
  cursor: pointer;
}

.MonthlyRed span img {
  width: 26px;
  height: 27px;
}

.MonthlyRed span img:hover {
  transform: scale(1.05);
}

::v-deep .el-progress-bar__outer {
  height: 8px;
}

::v-deep .el-empty__description p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.member_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.member_set {
  width: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 400px);
  top: calc(50% - 450px);
  margin-top: 160px;
  /*background-color: #fff;*/
  background-color: #2a293e;
  height: 500px;
  border-radius: 10px;
  position: relative;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.member_top {
  width: 230px;
  height: 220px;
  /* background: orangered; */
  margin-left: 50px;
}

.member_top img {
  width: 100%;
  height: 100%;
  margin-top: 35px;
  margin-left: 20px;
}

.member_down {
  width: 380px;
  /*height: 200px;
  /* background: orange; */
  margin-top: 0px;
}

.member_down p:nth-child(1) {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  /*font-weight: 600;*/
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-break: normal;
}

.member_down p:nth-child(2) {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-break: normal;
}

.Bindstore p:nth-child(1) {
  color: #fff;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  word-break: normal;
}

.member_btn {
  margin-left: -25px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border-radius: 50px;
  margin-top: 20px;
  display: flex;
}

.member_btn >>> .el-button:nth-child(1) {
  margin-left: 85px;
  height: 45px;
  margin-bottom: -150px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}

.member_btn >>> .el-button:nth-child(2) {
  margin-left: 30px;
  height: 45px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}

.member_btn >>> .el-button:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}

.member_btn >>> .el-button:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}

.speed-progress {
  width: auto;
  height: 60px;
}

.progress-fulfilled span:nth-child(1) {
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(2) {
  font-size: 14px;
  margin-left: 30px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(3) {
  font-size: 14px;
  margin-left: 5px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(4) {
  font-size: 14px;
  margin-left: 6px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-progress__text {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 1;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  /* display: none; */
}

/* 搜索 */
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 1320px;
}

.Filter {
  width: 1320px;
  height: 94px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 94px;
  margin: auto;
}

::v-deep .Filter .input-with-select {
  width: 563px;
  height: 40px;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 30px;
}

::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 46px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 46px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .Filter [class*=" el-icon-"],
[class^="el-icon-"] {
  color: #000;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Filter .search {
  display: inline-block;
  width: 145px;
  height: 46px;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 50px;
  color: rgb(102, 135, 255);
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Filter .Advanced {
  display: inline-block;
  width: 145px;
  height: 46px;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* <!-- 内容 --> */
.activeName {
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  padding-bottom: 20px;
}

.breadCrumb {
  padding-top: 60px;
  padding-left: 20px;
}

::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}

::v-deep .el-tabs__header {
  margin: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-tabs__nav {
  margin-left: 32px;
}

/* 提示文案 */
.PromptStatement {
  position: absolute;
  top: 15px;
  left: 510px;
}

.PromptStatement p {
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 25px;
}

/* 订单按钮 */
.activeName .orders {
  position: absolute;
  top: 20px;
  right: 32px;
  font-family: "Regular";
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  height: 40px;
}

.activeName .speed-progress {
  position: absolute;
  top: 26px;
  left: 245px;
}

::v-deep .el-tabs__item {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 10px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页的标签头 */
::v-deep .activeName #tab-first {
  padding-left: 16px !important;
  padding-right: 0px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页内容区的总长度 */
::v-deep .activeName .el-tabs__content {
  background-color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

::v-deep .activeName #tab-second {
  padding-left: 88px !important;
  padding-right: 0px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #eff1f3 !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页头部高度 */
::v-deep .el-table th,
.el-table tr {
  height: 70px;
  font-weight: 500;
}

::v-deep .el-table thead {
  font-size: 14px;
  color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 内容区的高度 */
::v-deep .el-table__row {
  height: 142px !important;
}

/* content */
::v-deep .el-table_1_column_1 {
  padding-top: 24px !important;
}

.content img {
  position: absolute;
  top: 24px;
  left: 112px;
  width: 100px;
  height: 100px;
  border: none !important;
  border-color: none !important;
  background: none !important;
}

.bakingpackaging {
  position: absolute;
  top: 33px;
  left: 254px;
  font-size: 14px;
  color: #19a1e7;
  font-weight: 500;
  cursor: pointer;
  width: auto;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.search_btn {
  position: absolute;
  top: 35px;
  left: 450px;
  font-size: 14px;
  color: #19a1e7;
  font-weight: 500;
  cursor: pointer;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591496627_copy-picture.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  width: 12px;
  height: 12px;
  border: 0;
  padding: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  z-index: 999;
}

.Blue {
  position: absolute;
  top: 108px;
  left: 254px;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Cancel {
  position: absolute;
  top: 95px;
  left: 551px;
  font-weight: 400;
  color: rgb(63, 66, 84, 0.6);
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.btn {
  width: 145px;
  height: 38px;
  font-size: 14px;
  border-width: 2px;
  border-style: solid;
  word-wrap: break-word;
}
.btn:hover{
  background: #3f6aff;
  color: #fff !important;
}

::v-deep .el-table_2_column_4 {
  padding-top: 24px !important;
}

::v-deep .el-table__row .el-table_1_column_2 {
  font-size: 14px;
  font-weight: 400;
  color: #1d1d1d;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-table .el-table__cell {
  padding-top: 28px;
}

/* address */
::v-deep .el-table__row .el-table_1_column_3 {
  color: #f81b17 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 详情图 */

.Details-figure {
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
}

.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
}

.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
}

.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign span {
  display: inline-block;
  cursor: pointer;
  margin-right: 12px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 流览图 */

/* <!-- Wrapper for slides 轮播图片 --> */
.focus {
  padding-top: 50px;
  width: 1320px;
  height: 800px;
}

.focus {
  padding-top: 50px;
  width: 1320px;
  height: 800px;
}

.banner .img {
  width: 539px;
  height: 539px;
  margin-left: 64px;
  padding-top: 0;
}

.img {
  float: left;
}

.banner {
  overflow: hidden;
}

.trans {
  transition: all 0.5s;
}

.view {
  width: 480px;
  height: 302px;
  border: 8px solid orangered;
  overflow: hidden;
  position: relative;
}

.indicators {
  margin-left: 60px;
  margin-top: 22px;
}

.indicators img {
  width: 91px;
  height: 90px;
  margin-left: 11px;
}

.con {
  width: 10% !important;
  height: 10% !important;
}

.prev {
  position: absolute;
  top: 35%;
  left: 64px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}

.next {
  position: absolute;
  top: 35%;
  left: 577px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}

/* mobilemouse */
.mobilemouse {
  font-size: 16px;
  font-weight: 400;
  color: rgb(136, 136, 136, 0.6);
  margin-top: 20px;
  margin-left: 127px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* <!-- 简介 --> */
.synopsis {
  position: absolute;
  top: 50px;
  left: 693px;
  font-size: 20px;
  width: 562px;
}

.intro {
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  /*font-family: Regular;-webkit-font-smoothing: antialiased;*/
  font-weight: 300;
  color: #191919;
}

.price {
  margin-top: 36px;
}

.price span:nth-child(1) {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
  line-height: 25px;
}

.price span:nth-child(2) {
  font-size: 36px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
  line-height: 25px;
  margin-left: 58px;
}

.measure {
  margin-top: 24px;
}

.measure span {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}

::v-deep .measure .el-radio-button__inner {
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px !important;
  border-radius: 50px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.measure .s {
  margin-left: 68px;
}

.measure .m {
  margin-left: 24px;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 7px;
  padding: 0;
}

.measure .xl {
  margin-left: 24px;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 7px;
  padding: 0;
}

.picture {
  margin-top: 21px;
}

.picture span {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}

::v-deep .picture .el-radio-button__inner {
  width: 81px;
  height: 81px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-top-width: 0px;
  padding-left: 0px;
  /* background-color: #F9FAFB !important; */
  border: 0 !important;
}

.picture .image1 {
  margin-left: 51px;
}

.picture .image2 {
  margin-left: 8px;
}

.picture .image3 {
  margin-left: 8px;
}

.address {
  margin-top: 21px;
}

.address span {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}

::v-deep .address .el-radio-button__inner {
  width: 75px;
  height: 30px;
  border-radius: 50px !important;
  padding: 0 !important;
  margin-bottom: 0px !important;
  padding-top: 5px !important;
}

.address .US {
  margin-left: 31px;
}

.address .GB {
  margin-left: 14px;
}

.address .ES {
  margin-left: 14px;
}

/* storyline */
.storyline {
  font-size: 21px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #000000;
  margin-top: 41px;
  height: 85px;
  width: 545px;
  overflow: hidden;
}

.View {
  display: inline-block;
  margin-top: 76px;
  margin-left: 223px;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #6788ff;
  cursor: pointer;
}

.Product button {
  display: inline-block;
  margin-top: 70px;
  margin-left: 154px;
  width: 250px;
  height: 64px;
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}

.product-details {
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  margin-top: 32px;
  position: relative;
  background: #f9fafb;
}

.product-details img {
  width: 1184px;
  height: 2089px;
  margin: 68px;
}

.el-tabs__active-bar {
  top: 30px;
  border-radius: 20px !important;
  border: 1px solid blue;
}

.Agent_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Agent_set {
  width: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 400px);
  top: calc(50% - 300px);
  margin-top: 160px;
  /*background-color: #fff;*/
  background-color: #5c5a86;
  height: 250px;
  border-radius: 10px;
  position: relative;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Agent_top {
  display: flex;
}

.Agent_top p:nth-child(1) img {
  margin-top: 65px;
  width: 345px;
  margin-left: 15px;
}

.Agent_top p:nth-child(2) {
  margin-top: 65px;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Agent_down {
  width: 380px;
  height: 200px;
  /* background: orange; */
  margin-top: 30px;
}

.Agent_down p:nth-child(1) {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  /*font-weight: 600;*/
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
}

.Agent_down p:nth-child(2) {
  text-align: center;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
}

.member_Close_btn {
  position: absolute;
  top: 8px;
  right: 10px;
}

::v-deep .member_Close_btn .el-icon-remove-outline {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
</style>
