<template>
  <div class="particulars" id="add" :class="[theme == 'common' ? '' : 'dark']">
    <div class="main" v-show="details">
      <div class="searchbox">
        <div class="searchbox_span">
          <span style="font-family: Regular">{{ $t('synchronize.time') }}</span>
          <el-select v-model="value" :placeholder="$t('synchronize.select')" class="myselect">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="Button">
          <button class="comBtn btn-bg0 btn1" @click="refresh">
            {{ $t('synchronize.Refresh') }}
          </button>
          <button class="comBtn btn-bg7" :disabled="disabled" @click="OrderSyn">
            {{ $t('synchronize.StartSync') }}
          </button>
        </div>
      </div>
    </div>
    <div class="activeName" v-show="details"> <!-- 内容 -->
      <el-table
        ref="multipleTable"
        :data="quotingList"
        tooltip-effect="dark"
        style="width: 100%"
        class="first"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" :selectable="selectInit"> </el-table-column>
        <el-table-column :label="$t('synchronize.Shopify')">
          <template slot-scope="scope">{{ scope.row.StoreName }}</template>
        </el-table-column>
        <el-table-column label="">
          <div slot-scope="scope">
            <div v-if="scope.row.StatusCode == -1">
              <span
                ><font
                  style="
                    color: #67c23a !important;
                    font-family: Regular;
                    -webkit-font-smoothing: antialiased;">{{ scope.row.EndTime }}</font></span>,
              <span>{{ $t('synchronize.fail') }}</span>
              <span style="display: none">{{ $t('synchronize.additions') }}【{{ scope.row.AddCount }}】,</span>
              <span style="display: none">{{ $t('synchronize.update') }}【{{ scope.row.UpdateCount }}】,</span>
              <span style="display: none">{{ $t('synchronize.fail1') }}【{{ scope.row.FailedCount }}】</span>
              <span>,{{ scope.row.ErrorString }}</span>
            </div>
            <div v-if="scope.row.StatusCode == 0"> <!-- 待执行 -->
              <span
                style="font-weight: bold; font-family: Regular; -webkit-font-smoothing: antialiased"
                >{{ $t('synchronize.ToBeImplemented') }}</span>
            </div>
            <div v-else-if="scope.row.StatusCode == 1"> <!-- 正在执行 -->
              <span
                style="font-weight: bold; font-family: Regular; -webkit-font-smoothing: antialiased"
                >{{ $t('synchronize.Executing') }}<i class="el-icon-loading"></i>
              </span>
              <span style="display: none"
                >{{ $t('synchronize.additions') }}【{{ scope.row.AddCount }}】,</span
              >
              <span style="display: none">{{ $t('synchronize.update') }}【{{ scope.row.UpdateCount }}】,</span>
              <span style="display: none">{{ $t('synchronize.fail1') }}【{{ scope.row.FailedCount }}】</span>
            </div>
            <div v-else-if="scope.row.StatusCode == 2"> <!-- 完成 -->
              <span
                ><font
                  style="
                    color: #67c23a !important;
                    font-family: Regular;
                    -webkit-font-smoothing: antialiased;"
                  >{{ scope.row.EndTime }}</font></span>,
              <span style="font-weight: bold">{{ $t('synchronize.complete') }}</span>
              <span style="display: none">{{ $t('synchronize.additions') }}【{{ scope.row.AddCount }}】,</span>
              <span style="display: none">{{ $t('synchronize.update') }}【{{ scope.row.UpdateCount }}】,</span>
              <span style="display: none">{{ $t('synchronize.fail1') }}【{{ scope.row.FailedCount }}】</span>
              <span
                v-if="(scope.row.ErrorString || '') != ''"
                style="color: #bc0c34; font-family: Regular; -webkit-font-smoothing: antialiased">
                {{ $t('synchronize.error') }} {{ scope.row.ErrorString }}</span>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => {
    return {
      page: 'synchronize',
      banner: '',
      activeName: 'first',
      currentPage: 1,
      itemname: '',
      details: true,
      Withdrawn: false,
      SynList: [],
      quotingList: [], // 内容列表
      multipleSelection: [], // 已选内容列表
      options: [
        {
          id: 1,
          value: '7',
          label: '7 days'
        },
        {
          id: 2,
          value: '15',
          label: '15 days'
        },
        {
          id: 3,
          value: '30',
          label: '30 days'
        }
      ],
      value: '7',
      timer: '',
      dispatch: null,
      disabled: false // 按钮是否可用
    }
  },
  components: {},
  computed: {
    ...mapState(['theme', 'userInfo'])
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      if (document.getElementById('add') === null) {
        clearInterval(this.timer)
        return
      }
      this.getData()
    }, 10000)
  },
  methods: {
    selectInit(row, index) {
      if (row.StatusCode == 0 || row.StatusCode == 1) {
        return false
      } else {
        return true
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 获取页面数据
    getData() {
      this.dispatch('synchronize/GetSynTasksSy')
        .then((res) => {
          this.quotingList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 刷新页面
    refresh() {
      this.getData()
    },
    // 同步页面
    OrderSyn() {
      this.disabled = true
      let synctype = ''
      if (this.multipleSelection.length == this.quotingList.length) {
        synctype = 'all'
      } else {
        synctype = 'change'
      }
      if (this.multipleSelection.length == '') {
        return this.$message.warning(this.$t('synchronize.operation'))
      }
      if (this.value == '') {
        return this.$message.warning(this.$t('synchronize.performing'))
      }
      let UserList = []
      this.multipleSelection.forEach((item, index) => {
        if (item.StatusCode == 0) {
          console.log(item.StatusCode)
          return
        }
        UserList[index] = {
          UserId: item.UserId,
          Platform: 'Shopify',
          StatusCode: item.StatusCode
        }
        item.StatusCode = 0
        console.log(item.StatusCode, '1111')
      })
      if (UserList.length == 0) {
        return
      }
      let that = this
      this.dispatch('synchronize/StartSyncTaskSy', {
        SyncType: synctype,
        DayNum: that.value,
        UserList: UserList
      }).then((res) => {
        if (res.Success == true) {
          this.$message.success(this.$t('synchronize.BackgroundSuccessfully'))
          this.getData()
        }
      })
      setTimeout(() => {
        this.disabled = false
      }, 2000).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.el-select-dropdown__item.selected {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.el-button--primary {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.el-button--success {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.main {
  width: 1320px;
}
.activeName {
  margin-top: 32px;
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  padding-bottom: 30px;
}
::v-deep .el-table .cell {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  word-break: break-word !important;
}
::v-deep .el-table th > .cell {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-table th,
.el-table tr {
  height: 64px;
  font-weight: 500;
}
.el-table::before {
  background: #dbdbe4;
}
::v-deep .el-table__row {
  height: 86px !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.activeName .orders {
  position: absolute;
  top: 20px;
  right: 32px;
  width: 178px;
  height: 40px;
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.btn {
  display: inline-block;
  width: 135px;
  height: 38px;
  background: rgb(191, 204, 249, 0.15);
  border-radius: 10px;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  color: #6788ff;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
</style>
<style lang="scss" scoped>
.searchbox_span {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
@mixin flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchbox {
  @include flex;
  height: 96px;
  padding: 0 40px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  background: #ffffff;
  .myselect {
    margin-left: 20px;
    font-family: Regular;
    ::v-deep .el-input {
      input {
        border-radius: 25px !important;
      }
    }
  }
  .Button {
    @include flex;
    .btn1 {
      margin-right: 20px;
    }
  }
}
.paybox {
  .recharge {
  }
}
</style>
