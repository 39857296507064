<script>
import { useJSCore } from 'sm-js-core'

export default {
  name: 'FormItemInput',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number | String,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    value(newValue) {
      this.showDialog = newValue
    },
    showDialog(newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    dialogWidth() {
      return useJSCore().base.isNumber(Number(this.width)) && Number(this.width) > 0
        ? `${this.width}px`
        : '50%'
    }
  }
}
</script>
<template>
  <el-dialog :visible.sync="showDialog" :title="title" :width="dialogWidth">
    <span v-html="message"></span>
  </el-dialog>
</template>

<style scoped lang="scss"></style>
