import request from '@/utils/request';

export const GetWelcomed = (data) => request({
  method: "post",
  url: '/api/LhMemberExtension/GetWelcomed',
  data
});
export const GetWelcomedAdd = (data) => request({
  method: "post",
  url: '/api/LhMemberExtension/Add',
  data
});

