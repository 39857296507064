import {
  GetUser,
  homeIndex,
  OrderLineChart,
  GetBanners,
  OAuth2CallBack,
  GetVerifyCode,
  LhFirstStepRecordAdd,
  CheckMoblieExist

} from '@/api/home'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetUserHm({state, commit}, params) {return GetUser(params).then(res => res)},
    homeIndexHm({state, commit}, params) {return homeIndex(params).then(res => res)},
    OrderLineChartHm({state, commit}, params) {return OrderLineChart(params).then(res => res)},
    GetBannersHm({state, commit}, params) {return GetBanners(params).then(res => res)},
    OAuth2CallBackHm({state, commit}, params) {return OAuth2CallBack(params).then(res => res)},
    GetVerifyCodeHm({state, commit}, params) {return GetVerifyCode(params).then(res => res)},
    LhFirstStepRecordAddHm({state, commit}, params) {return LhFirstStepRecordAdd(params).then(res => res)},
    CheckMoblieExistHm({state, commit}, params) {return CheckMoblieExist(params).then(res => res)}
  }

}