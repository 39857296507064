<template>
  <div class="container">
    <img src="../assets/imgs/loading.gif" alt="" class="loading" />
    <p class="loading_text">shopify Store is connecting... {{ step }}/2</p>
  </div>
</template>
<script>
import { setToken } from "@/utils/token";
import apis from "@/api/index.js";
export default {
  data: () => {
    return {
      step: 1
    };
  },
  created() {
    let that = this;
    // 获取授权时获取的参数
    let search = decodeURIComponent(location.search);
    // let search =
    //   "?hmac=f83244430b9728b002ecc0154f7da72654514fef7ec2ef8fd066a27d35f92247&shop=usatest2021.myshopify.com&timestamp=1634104667";
    if (search) {
      let params = {};
      search = search.substring(1).split("&");
      search.map(item => {
        let index = item.indexOf("=");
        let key = item.substring(0, index);
        let value = item.substring(index + 1).split("#")[0];
        params[key] = value;
      });
     // console.log(params);

      if (params.code) {
        //alert('hello')
        that.getStep2(params);
      } else {
        //alert('hello2')
        params.oauthRedirectUrl = window.location.href.split("?")[0];
        that.getStep1(params);
      }
    }
  },

  mounted() {
    // 自动加载进度
    // this.setTime();
  },
  methods: {
    getStep1(params) {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      console.log(params);
      //alert('getStep1>>'+JSON.stringify(params))
      this.axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: params,
        transformRequest: [
          function(data) {
            console.log(1111);
            //   处理form data格式问题
            let ret = "";
            for (let it in data) {
              ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
            }
            ret = ret.substring(0, ret.lastIndexOf("&"));
            return ret;
          }
        ],
        url: '/api/Member/ShopifyConnect'
      })
        .then(res => {
          loading.close();
          //this.step = 2;
          // console.log(res);
          var that = this;
          if (res.data.Success) {
            //店铺绑定过
             if (res.data.Result.ResultType == 1) {
              setToken(res.data.Result.JwtToken);
               setTimeout(() => {
                 that.step = 2;
                 setTimeout(() => {
                  /*  this.$router.push("/Upgrade"); */
                 /* this.$router.push("dashboard"); */
                 window.location.href = `${window.location.origin}/Upgrade`
                 }, 850);
               }, 850);
             } else if (res.data.Result.ResultType == 0){
              //：店铺未绑定过
               setTimeout(() => {
                 window.location.href = res.data.Result.ShopOAuthUrl;
               }, 1200);
             } else if (res.data.Result.ResultType == 2){
              //店铺绑定过
               setToken(res.data.Result.JwtToken);
               setTimeout(() => {
                 window.location.href = res.data.Result.ShopOAuthUrl;
               }, 1200);
             }
          } else {
            //alert('sssss')
            this.$message(res.data.ErrMsg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getStep2(params) {
      this.step = 2;
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      //如果STEP1返回的ResultType=2，表示存在店铺，但店铺是取消授权状态
      if(window.location.href.indexOf('resultType=2')!=-1){
       this.axios({
        method: "post",
        data: params,
        url: '/api/Store/ReceiveAndRefreshShopifyToken'
      })
        .then(res => {
          loading.close();
          if (res.data.Success) {
            setTimeout(() => {
              /* this.$router.push("/Upgrade"); */
              window.location.href = `${window.location.origin}/Upgrade`
             /*  this.$router.push("dashboard");  */
            }, 1200);
          } else {
            this.$message(res.data.ErrMsg);
          }
        })
        .catch(err => {
          console.log(err);
        });

       return false;
      }
      //下面是去注册/登录并且绑定的操作
      this.axios({
        method: "post",
        data: params,
        url: '/api/Member/GetShopifyTokenKey'
      })
        .then(res => {
          loading.close();
          if (res.data.Success) {
            this.$store.commit("setStoreKey", res.data.ResData);
            setTimeout(() => {
              /* this.$router.push("/register"); */
              window.location.href = `${window.location.origin}/storeLogin`
              /* this.$router.push({
                path: '/register',
                query: {
                  type: 0
                }
              }) */
            }, 1200);
          } else {
            this.$message(res.data.ErrMsg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setTime() {
      let that = this;
      let step = that.step;
      if (step < 3) {
        setTimeout(() => {
          step++;
          that.step = step;
          that.setTime();
        }, 666);
      }
    }
  }
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 393px;
  height: 70px;
  margin-bottom: 40px;
}

.loading_text {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #aaaaaa;
  line-height: 34px;
}
</style>
