<template>
  <div>
    <div class="layout" v-if="!singlePage">
      <Slider></Slider>
      <div class="mainContent">
        <div class="customer">
          <Customer></Customer>
        </div>
        <Header></Header>
        <div class="container" id="usaContainer">
          <router-view> </router-view>
        </div>
        <Footer></Footer>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>
<script>
import Slider from '../Slider'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Customer from './Customer.vue'

export default {
  watch: {},
  components: { Slider, Header, Customer, Footer },
  data() {
    return {
      singlePage: false
    }
  },
  created() {
    const { singlePage } = this.$route.meta || {}
    this.singlePage = singlePage
    !singlePage && this.menterData()
  },
  methods: {
    menterData() {
      this.$store.dispatch('member/GetMemberVoMe')
    }
  }
}
</script>
<style lang="scss" scoped>
.layout {
  display: flex;

  .mainContent {
    display: flex;
    max-height: 100vh;
    width: calc(100% - 240px);
    flex-direction: column;

    .container {
      flex: 1;
      height: calc(100vh - 70px);
      background-color: #eff1f3;
      padding-top: 40px;
      padding-left: 60px;
      overflow-y: scroll;
    }
  }
}
</style>
