<script>
export default {
  name: 'FeedErrorProduct',
  props: {
    orderDetails: Array
  },
  data() {
    return {
      ErrorSkuList: []
    }
  },
  watch: {
    orderDetails: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.ErrorSkuList = newVal.map((item) => {
            return {
              OrderDetailId: item.OrderDetailId,
              OrderId: item.OrderId,
              Spec: item.Spec,
              Num: item.Num,
              ProductRealSku: item.ProductRealSku
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onHandleRemove(index) {
      this.ErrorSkuList.splice(index, 1)
    },
    getErrorSkuList() {
      return {
        ErrorSkuList: this.ErrorSkuList
      }
    },
    handleInput(value, item) {
      item.Num = value.replace(/[^\d]/g, '')
    }
  }
}
</script>

<template>
  <div>
    <div class="header">
      <span class="title">{{ $t('SupportTicket.ProductsDetail') }}</span>
    </div>
    <div class="list-view">
      <div class="list-item">
        <span class="left-product">{{ $t('SupportTicket.Item') }}</span>
        <span>{{ $t('SupportTicket.Quantity') }}</span>
      </div>
      <div class="list-item" v-for="(item, index) in ErrorSkuList" :key="item.OrderDetailId">
        <div class="left-product">
          <el-input style="width: 100%" v-model="item.ProductRealSku" disabled></el-input>
        </div>
        <el-input
          class="quantity-input"
          v-model="item.Num"
          type="number"
          @input="handleInput($event, item)"
        ></el-input>
        <el-button
          v-if="ErrorSkuList.length > 1"
          size="small"
          type="text"
          style="color: red"
          @click="onHandleRemove(index)"
        >
          {{ $t('SupportTicket.Remove') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 16px;

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #15104b;
  }
}

.list-view {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .left-product {
      min-width: 30%;
      padding-right: 16px;
    }

    .quantity-input {
      width: 100px;
      margin-right: 16px;
    }
  }
}
</style>
