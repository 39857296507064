var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName_big_box" }, [
        _c("div", { staticClass: "activeName comSerInput" }, [
          _vm._m(0),
          _vm._m(1),
          _c("div", { staticClass: "Winners_box" }, [
            _c(
              "div",
              { staticClass: "bigbox" },
              [
                _vm._l(_vm.cardList, function (item, index) {
                  return _c("div", { key: item.index, staticClass: "box" }, [
                    _c("div", { staticClass: "images" }, [
                      _c("img", {
                        attrs: { src: item.MainPic, alt: "" },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.enterProduct(index)
                          },
                          mouseleave: function ($event) {
                            return _vm.leaveProduct(index)
                          },
                        },
                      }),
                    ]),
                    _vm.currentIndexImg === index
                      ? _c(
                          "div",
                          {
                            staticClass: "Contents_Exhibition",
                            on: {
                              click: function ($event) {
                                return _vm.showBigImage(index)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ProductExhibition_Description" },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(item.ProductExhibition.Description)
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
                _vm.ShowFalse
                  ? _c("div", { staticClass: "LargeImagePreview" }, [
                      _c(
                        "div",
                        { staticClass: "LargeImagePreviewImgBox" },
                        _vm._l(_vm.cardList, function (item, index) {
                          return _c(
                            "div",
                            { key: item.index, staticClass: "LargePreviewImg" },
                            [
                              _vm.currentIndex === index
                                ? _c("img", {
                                    attrs: { src: item.MainPic, alt: "" },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "elIconCircleClose",
                          on: { click: _vm.elIconCircleCloseBtn },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "pagebox" },
              [
                _c("PagePagination", {
                  attrs: {
                    total: _vm.options1.total,
                    "page-size": _vm.options1.pageSize,
                    "current-page": _vm.options1.page,
                  },
                  on: { "current-change": _vm.pageChange },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ExhibitionTitle" }, [
      _c("p", [_vm._v("Exhibition")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "UniqueMoments" }, [
      _c("p", [
        _vm._v(
          "Unique Moments Through the Lens: A Retrospective of Exhibitions"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }