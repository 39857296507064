import request from '@/utils/request';

export const GetTop = (params) => request({
    method: "get",
    url: '/api/LhNews/GetTop',
    params
  });
  
export const GetUnReadNewsCount = (data) => request({
    method: "post",
    url: '/api/LhNews/GetUnReadNewsCount',
    data
  });
  export const LhNewsGet = (params) => request({
    method: "get",
    url: '/api/LhNews/Get',
    params
  });
  
export const LhNewsGetAll = (data) => request({
    method: "post",
    url: '/api/LhNews/GetAll',
    data
  });
  
export const GetLasted = (params) => request({
    method: "get",
    url: '/api/LhNews/GetLasted',
    params
  });