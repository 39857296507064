var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.BindStore
      ? _c("div", { staticClass: "SideBarBigBoxStore" }, [
          _c("div", { staticClass: "LinkMyStore" }, [
            _c("p", { on: { click: _vm.LinkMyStoreBtn } }, [
              _vm._v(_vm._s(_vm.$t("Customer.ConnectUSAdrop"))),
            ]),
            _c("p", { on: { click: _vm.helpBtn } }, [
              _vm._v(_vm._s(_vm.$t("Customer.NoOffline"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "Bind_store_box" }, [
      _vm.NavPopBigBoxPop
        ? _c("div", { staticClass: "NavPopBigBox" }, [
            _vm.CloseSourcingBtn
              ? _c(
                  "div",
                  { staticClass: "NavGuideSourcing" },
                  [
                    _c("p", [
                      _c("span", [_vm._v("(1/5)")]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Customer.SourceProduct")) + " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Customer.supplierPortals")) + " "
                      ),
                    ]),
                    _c("el-button", { on: { click: _vm.NextOrders } }, [
                      _vm._v(_vm._s(_vm.$t("Customer.Next"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.CloseOrdersBtn
              ? _c("div", { staticClass: "NavGuideOrders" }, [
                  _c("p", [
                    _c("span", [_vm._v("(2/5)")]),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.FulfillUSAdrop"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "NavGuideOrdersBtn" },
                    [
                      _c("el-button", { on: { click: _vm.BackSourcing } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Back"))),
                      ]),
                      _c("el-button", { on: { click: _vm.NextWinner } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Next"))),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.CloseWinnerBtn
              ? _c("div", { staticClass: "NavGuideWinner" }, [
                  _c("p", [
                    _c("span", [_vm._v("(3/5)")]),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.Handpicked"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "NavGuideWinnerBtn" },
                    [
                      _c("el-button", { on: { click: _vm.BackOrders } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Back"))),
                      ]),
                      _c("el-button", { on: { click: _vm.NextProduct } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Next"))),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.ClosePricingBtn
              ? _c("div", { staticClass: "NavGuidePricing" }, [
                  _c("p", [
                    _c("span", [_vm._v("(4/5)")]),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.SpecialMemberships"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "NavGuidePricingBtn" },
                    [
                      _c("el-button", { on: { click: _vm.BackWallet } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Back"))),
                      ]),
                      _c("el-button", { on: { click: _vm.NextWallet } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Next"))),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.CloseWalletBtn
              ? _c("div", { staticClass: "NavGuideWallet" }, [
                  _c("p", [
                    _c("span", [_vm._v("(5/5)")]),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.ManageBoost")) + " "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Customer.dropshippingBusiness")) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "NavGuideWalletBtn" },
                    [
                      _c("el-button", { on: { click: _vm.BackPricing } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Back"))),
                      ]),
                      _c("el-button", { on: { click: _vm.CloseOkWallet } }, [
                        _vm._v(_vm._s(_vm.$t("Customer.Ok"))),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _c("div", [
      _vm.paymentList.AgentId > 0
        ? _c("div", [
            _c("div", { staticClass: "AgentBox" }, [
              _c(
                "div",
                {
                  staticClass: "Agent",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.MyAgentBox.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/NewLogo/IMG_0172.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("div", {
                staticClass: "headPortraitImg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.MyAgentBox.apply(null, arguments)
                  },
                },
              }),
            ]),
            _vm.ContactHere
              ? _c("div", { staticClass: "Contact_agent" }, [
                  _c(
                    "div",
                    {
                      staticClass: "remove_outline",
                      on: { click: _vm.RemoveOutline },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.$t("Customer.HiContact")))]),
                ])
              : _vm._e(),
            _vm.ContentTwo
              ? _c("div", { staticClass: "Contact_agent" }, [
                  _c(
                    "div",
                    {
                      staticClass: "remove_outline",
                      on: { click: _vm.RemoveOutline },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("Customer.everything")) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.helpYou")) + " "),
                  ]),
                ])
              : _vm._e(),
            _vm.ContentThree
              ? _c("div", { staticClass: "Contact_agent_three" }, [
                  _c(
                    "div",
                    {
                      staticClass: "remove_outline",
                      on: { click: _vm.RemoveOutline },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("Customer.wellToday")) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.LetMe")) + " "),
                  ]),
                ])
              : _vm._e(),
            _vm.ContentFour
              ? _c("div", { staticClass: "Contact_agent_Pssp" }, [
                  _c(
                    "div",
                    {
                      staticClass: "remove_outline",
                      on: { click: _vm.RemoveOutline },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.$t("Customer.Pssp")))]),
                ])
              : _vm._e(),
            _vm.ContentFive
              ? _c("div", { staticClass: "Contact_agent" }, [
                  _c(
                    "div",
                    {
                      staticClass: "remove_outline",
                      on: { click: _vm.RemoveOutline },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.$t("Customer.HeyThere")))]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.memberPopupBox
        ? _c("div", { staticClass: "member_box" }, [
            _c("div", { staticClass: "member_set" }, [
              _c("div", { staticClass: "member_el-icon-close" }, [
                _c("i", {
                  staticClass: "el-icon-close",
                  on: { click: _vm.close },
                }),
              ]),
              _c("div", { staticClass: "headportrait" }, [
                _c("img", {
                  attrs: {
                    src: _vm.baseApiHost() + _vm.CenterList.Avatar,
                    alt: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "member_RealName" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("Customer.Hi")) + " "),
                  _c("span", { staticStyle: { color: "#53c248" } }, [
                    _vm._v(_vm._s(_vm.userInfo.RealName) + ","),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("Customer.how_doing")) + " "),
                ]),
              ]),
              _c("div", { staticClass: "member_AgentNames" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("Customer.I_m")) + " "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-style": "italic",
                        "text-decoration": "underline",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.CenterList.AgentName))]
                  ),
                  _vm._v(" , " + _vm._s(_vm.$t("Customer.YourAgent")) + " "),
                ]),
              ]),
              _c("div", { staticClass: "member_hello" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.FeelMe")) + " !")]),
              ]),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://api.whatsapp.com/send?phone=+" +
                      _vm.CenterList.WhatsApp +
                      "&text=Hello",
                    target: "_blank",
                  },
                },
                [
                  _c("div", { staticClass: "member_Whatsapp" }, [
                    _vm._m(0),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Customer.WhatsApp")))]),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "skype:" + _vm.CenterList.Skype + "?chat",
                    target: "_blank",
                  },
                },
                [
                  _c("div", { staticClass: "member_Skype" }, [
                    _vm._m(1),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Customer.Skype")))]),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://t.me/" + _vm.CenterList.Telegram,
                    target: "_blank",
                  },
                },
                [
                  _c("div", { staticClass: "member_Telegram" }, [
                    _vm._m(2),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Customer.Telegram")))]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.ShowSurveyImg
        ? _c("div", [
            _vm.paymentList.HasRegistTaskNoOver == true
              ? _c("div", { staticClass: "SurveyFileBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "SurveyFileContent",
                      on: { click: _vm.SurveyFileBtn },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/SurveyFile/Group2193.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.displayQuestionBox
        ? _c("div", { staticClass: "QuestionBox" }, [
            _c("div", { staticClass: "QuestionTopBox" }, [
              _c("div", { staticClass: "DontMissOut_Img" }, [
                _vm._m(3),
                _c("div", { staticClass: "DontMissOut_Word" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("Customer.DontMissOut")))]),
                ]),
                _c("div", { staticClass: "CloseRulesBtn_Pop" }, [
                  _c("p", { on: { click: _vm.CloseRulesBtn } }, [
                    _c("i", { staticClass: "el-icon-circle-close" }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "ProgressBarBox" }, [
                _c("div", { staticClass: "ProgressBar_Pop" }, [
                  _c(
                    "div",
                    { staticClass: "elProgressBox" },
                    [
                      _c("el-progress", {
                        attrs: {
                          percentage: _vm.pmgressbar,
                          "show-text": false,
                          color: _vm.customColor,
                          "stroke-width": 10,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "CompletedBox" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("Customer.Completed")))]),
                    _c("span", { staticStyle: { "margin-left": "8px" } }, [
                      _vm._v(_vm._s(_vm.SurveyAllFileNumber.Executed)),
                    ]),
                    _vm._v(" / "),
                    _c("span", [_vm._v(_vm._s(_vm.SurveyAllFileNumber.Total))]),
                  ]),
                ]),
                _vm._m(4),
              ]),
            ]),
            _c("div", { staticClass: "QuestionBottomBox" }, [
              _c("div", [
                _c("div", { staticClass: "SurpriseJackpot" }, [
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("Customer.Surprise")) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("Customer.Credits")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "RulesCompletedBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "RulesTitle",
                      on: { click: _vm.OpenRulesBtn },
                    },
                    [
                      _vm._m(5),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("Customer.RulesDescription"))),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "QuestionBottomBox" },
                _vm._l(_vm.SurveyAllFileList, function (item) {
                  return _c(
                    "div",
                    { key: item.Tag, staticClass: "FinishBox" },
                    [
                      item.ExecutionStatus == 4
                        ? _c(
                            "el-button",
                            {
                              staticClass: "FinishQuestionnaire",
                              attrs: { disabled: "" },
                            },
                            [_vm._v(_vm._s(item.Title))]
                          )
                        : item.ExecutionStatus != 2
                        ? _c(
                            "el-button",
                            {
                              staticClass: "FinishQuestionnaire",
                              on: {
                                click: function ($event) {
                                  return _vm.GetByClick(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.Title))]
                          )
                        : item.ExecutionStatus == 2
                        ? _c(
                            "el-button",
                            {
                              staticClass: "FinishQuestionnaire",
                              attrs: { disabled: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.GetByClick(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.Title))]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "FinishSwitch" },
                        [
                          item.ExecutionStatus == 2
                            ? _c("el-switch", {
                                attrs: {
                                  disabled: "",
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                },
                                model: {
                                  value: _vm.CompleteValue,
                                  callback: function ($$v) {
                                    _vm.CompleteValue = $$v
                                  },
                                  expression: "CompleteValue",
                                },
                              })
                            : item.ExecutionStatus != 2
                            ? _c("el-switch", {
                                attrs: {
                                  disabled: "",
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                },
                                model: {
                                  value: _vm.SurveyValue,
                                  callback: function ($$v) {
                                    _vm.SurveyValue = $$v
                                  },
                                  expression: "SurveyValue",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm.DisplayRulesDetails
        ? _c("div", { staticClass: "RulesPopUp_box" }, [
            _c("div", { staticClass: "RulesPopUp_Content_Box" }, [
              _c("div", { staticClass: "RulesDescriptionTitle" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.RulesDescription")))]),
                _c("p", { on: { click: _vm.CloseRulesIconBtn } }, [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                ]),
              ]),
              _c("div", { staticClass: "USAdropCampaignTitle" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.USAdropCampaign")))]),
              ]),
              _c("div", { staticClass: "Customer_WeAreDelighted" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.WeAreDelighted")))]),
              ]),
              _c("div", { staticClass: "Customer_newCustomer" }, [
                _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Customer.newCustomer")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Customer.Ucredits")))]),
                ]),
              ]),
              _c("div", { staticClass: "Customer_InAddition" }, [
                _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Customer.InAddition")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Customer.thank-you")))]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Customer.enhanceCustomers"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Customer_WeAreDelighted" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Customer.shoppingeExperience"))),
                ]),
              ]),
              _c("div", { staticClass: "Customer_TaskOne" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.TaskOne")))]),
              ]),
              _c("div", { staticClass: "Customer_TaskTwo" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.TaskTwo")))]),
              ]),
              _c("div", { staticClass: "Customer_OnceSuccessfully" }, [
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Customer.successfullyComplete"))),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Customer.threeHundredUcredits"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Customer_InAddition" }, [
                _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Customer.TheseUcreditsBusiness"))),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.SurveyDetailsHide
        ? _c("div", { staticClass: "SurveyFileDetailsBox" }, [
            _c("div", { staticClass: "SurveyDetailsContent" }, [
              _c("div", { staticClass: "SurveyFileTitle" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.Questionnaire")))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("Customer.Following")))]),
              ]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "SurveyDetailListBox" },
                  _vm._l(_vm.HaverRead, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "SurveyDetailListTitle" },
                      [
                        _c("p", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(index + 1) +
                              ". " +
                              _vm._s(item.Title) +
                              " "
                          ),
                        ]),
                        !item.IsCanInput
                          ? [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: item.answerStr,
                                    callback: function ($$v) {
                                      _vm.$set(item, "answerStr", $$v)
                                    },
                                    expression: "item.answerStr",
                                  },
                                },
                                [
                                  _vm._l(item.Items, function (answer, index) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          key: index,
                                          attrs: { label: answer.Value },
                                        },
                                        [_vm._v(_vm._s(answer.Value))]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: item.answerStr,
                                  callback: function ($$v) {
                                    _vm.$set(item, "answerStr", $$v)
                                  },
                                  expression: "item.answerStr",
                                },
                              }),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "CloseSubmitBtn" },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.CloseSurveyDetailsBtn } },
                      [_vm._v(_vm._s(_vm.$t("Customer.Close")))]
                    ),
                    _c("el-button", { on: { click: _vm.SubmitSurveyBtn } }, [
                      _vm._v(_vm._s(_vm.$t("Customer.Submit"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/UpgradeToPro/whatsapp-logo-hero.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/UpgradeToPro/Skype123.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/UpgradeToPro/telegram1.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Group_img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/SurveyFile/Group2184.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "GroupIcon" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/SurveyFile/Group2181(1).png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-question" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }