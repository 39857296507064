<template>
  <div class="agent_box" v-if="visible">
    <div class="agent_set">
      <div class="member_close_btn" @click="closeAgentBtn">
        <i class="el-icon-remove-outline"></i>
      </div>
      <div class="agent_content">
        <div class="agent_top">
          <p>
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591372997_ziyuan1.png"
              alt=""
            />
          </p>
        </div>
        <div class="agent_down">
          <p>{{ $t('Sourcing.Reached') }}</p>
          <p>{{ $t('Sourcing.pleaseTomorrow') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentPop',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {}
  },
  methods: {
    closeAgentBtn() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.agent_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  .agent_set {
    width: 380px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    left: calc(60% - 400px);
    top: calc(50% - 300px);
    margin-top: 160px;
    background-color: #5c5a86;
    height: 250px;
    border-radius: 10px;
    position: relative;
  }

  .agent_top {
    p:nth-child(1) img {
      margin-top: 65px;
      width: 345px;
      margin-left: 15px;
    }
  }

  .agent_down {
    width: 380px;
    height: 200px;
    margin-top: 30px;
    p:nth-child(1) {
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
      color: #fff;
    }

    p:nth-child(2) {
      text-align: center;
      font-size: 20px;
      color: #fff;
    }
  }

  .member_close_btn {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  .el-icon-remove-outline {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
  }
}
</style>
