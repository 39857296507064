import {
  LhHelpMessage,
  GetHelpCenterCatalogs,
  GetHelpInfoById,
  GetHelpInfos
} from '@/api/help'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    LhHelpMessageHp({state, commit}, params) {return LhHelpMessage(params).then(res => res)},
    GetHelpCenterCatalogsHp({state, commit}, params) {return GetHelpCenterCatalogs(params).then(res => res)},
    GetHelpInfoByIdHp({state, commit}, params) {return GetHelpInfoById(params).then(res => res)},
    GetHelpInfosHp({state, commit}, params) {return GetHelpInfos(params).then(res => res)}
  }

}