var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "particulars",
        class: [_vm.theme == "common" ? "" : "dark"],
      },
      [
        _c("div", { staticClass: "activeName" }, [
          _c("div", { staticClass: "FAQTitle" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("FAQ.FAQ")))]),
          ]),
          _c(
            "div",
            { staticClass: "FAQTitleDetails" },
            [
              _c(
                "el-collapse",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: _vm.$t("FAQ.lookingFor"), name: "1" } },
                    [
                      _c("div", { staticClass: "withUSAdrop_words" }, [
                        _vm._v(" " + _vm._s(_vm.$t("FAQ.automatic")) + " "),
                      ]),
                      _c("div", { staticClass: "withUSAdrop_words" }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }