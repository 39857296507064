<template>
  <div class="particulars">
    <div class="active_name">
      <H1 class="title">Welcome To USAdrop Academy</H1>
      <div class="banner">
        <el-image
          class="banner-img"
          src="https://file.securepago.com/ud/1731044475571_a7aa645d-193b-45f9-bbef-b540c764d6cd.jpg"
        ></el-image>
      </div>
      <div class="category">
        <div
          v-for="(item, index) in cateList"
          @click="changeTab(item)"
          :class="activeTab === item.Id ? 'active-tab' : ''"
          :key="item.Id"
          class="category-item"
        >
          {{ item.Name }}
        </div>
      </div>
      <div class="content">
        <div class="content-item" v-for="(item, index) in videoList" :key="index"  @click="videDetail(item)">
          <!-- video -->
          <div class="box">
            <div class="box_video">
              <img :src="item.CoverUrl" alt="">
            </div>
            <!-- <DpVideo
              class="box_video"
              :poster="item.CoverUrl"
              :src="item.VideoUrl"
              :canSeeTime="item.CanSeeTime"
              :seekTime="item.seekTime"
              :id="`dp-tutorial${index}`"
              @play="handlePlay(item)"
              @canSeePause="handleCanSeePause(index)"
            /> -->
          </div>
          <!-- info -->
          <div class="info">
            <div class="title">
              {{ item.Name }}
            </div>
            <div class="group" v-if="item.Process == 0">
              <p>Watch Now</p>
            </div>
            <div v-else>
              <el-progress :percentage="item.Process" :color="customColor" :stroke-width="20"></el-progress>
              <div class="progress-label">
                {{ item.Process }}%
                <span>Complete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员提示弹窗 -->
    <SubscribeNowDialog :visible.sync="upgradeVisible" title="Get Pro Plan for more features" />
  </div>
</template>

<script>
import UdVideo from '@/components/UdVideo/index.vue'
import DpVideo from '@/components/DpVideo/index.vue'
import SubscribeNowDialog from '@/components/SubscribeNowDialog.vue'
import { getVideoData, getRecommendList, AddPlayTime, UpdateVideoProcess } from '@/api/proConnect'
import { mapState } from 'vuex'

export default {
  components: { UdVideo, DpVideo, SubscribeNowDialog },
  data() {
    return {
      dispatch: null,
      upgradeVisible: false,
      cateList: [],
      activeTab: '',
      videoList: [],
      paymentList: [],
      customColor: '#15104B'
    }
  },
  mounted() {
    // console.clear()
    this.getCateData()
    this.menterData()
  },
  computed: {
    videoProgress() {
      return (this.currentVideoData.seekTime / this.currentVideoData.duration) * 100;
    }
  },
  watch: {
    activeTab: {
      handler: function (newVal, oldVal) {
        // 根据tab 获取列表数据
        this.videoList = []
        this.getRecommendData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getCateData() {
      getVideoData().then((res) => {
        this.cateList = [
          {
            Id: '',
            Name: 'All'
          },
          ...res
        ]
        this.activeTab = this.cateList[0].Id
        this.getRecommendData()
      })
    },
    getRecommendData() {
      const postData = {
        Catlog: this.activeTab,
        PageIndex: 1,
        PageSize: 20
      };
      getRecommendList(postData).then((res) => {
        if (res.Success) {
          this.videoList = res.Items.map((item) => {
            // 根据会员等级判断
            if (this.paymentList.GradeId == 2 || this.paymentList.GradeId == 3) { // 会员
              return {
                ...item,
                CanSeeTime: '', // 会员可以无限制观看
              };
            } else { // 非会员
              if (item.IsPayed==true) {
                // 非会员且已购买，可以观看
                return {
                  ...item,
                  CanSeeTime: '', // 允许观看
                };
              } else {
                // 非会员且未购买，设置观看时间限制
                return {
                  ...item,
                  CanSeeTime: item.ExpenseType === 2 ? 60 : '',
                };
              }
            }
          });
        }
      });
    },
    changeTab(itemData) {
      this.activeTab = itemData.Id
    },
    showControls(index) {
      this.videoList[index].controls = true
    },
    hideControls(index, idx) {
      this.videoList[index].controls = false
    },
    handleCanSeePause() {
      this.upgradeVisible = true
    },
    updateVideoListProgress() {
      this.videoList.forEach(video => {
        const lastWatchedTime = localStorage.getItem(`videoTime_${video.Id}`);
        if (lastWatchedTime) {
          const totalTime = video.Duration; // 假设视频对象中有总时长属性
          const percentage = (lastWatchedTime / totalTime) * 100;
          video.Process = percentage; // 更新进度
        }
      });
    },
    videDetail(item) {
      const postData = {
        VideoId: item.Id, // 记录 VideoId
        MemberId: this.paymentList.GradeId
      };
      AddPlayTime(postData).then((res) => {
        const currentIndex = this.videoList.findIndex(video => video.Id === item.Id);
        localStorage.setItem('videoList', JSON.stringify(this.videoList)); // 存储视频列表
        localStorage.setItem('currentIndex', currentIndex); // 存储当前视频索引
        localStorage.setItem('currentVideoData', JSON.stringify(item)); // 存储当前视频数据
        this.$router.push({
          name: 'DropshippingTutorialDetail',
          query: { videoId: item.Id }
        });
        this.updateVideoListProgress();
      })
    },
    menterData() {
      const {dispatch} = this.$store;
      dispatch('login/getPaymentList').then(res => {
        const {Result, Success} = res || {};
        if (Success && Result) {
          this.paymentList = res.Result;
        }
        this.getRecommendData()
      });
    },
  }
}
</script>
<style scoped>
video {
  width: 150px;
  height: 150px;
}
</style>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  font-family: 'Regular';
}
.active_name {
  width: 1320px;
  border-radius: 10px;
  position: relative;
  // background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 30px;
  > .title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
  }
  > .banner {
    width: 100%;
    height: 320px;
    border-radius: 10px;
    box-shadow: 2px 7px 8.8px 0px #00000040;

    .banner-img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
  > .category {
    height: 100px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .category-item {
      border-radius: 22px;
      background-color: #15104b;
      color: #fff;
      min-width: 200px;
      height: 34px;
      display: flex;
      padding: 0 16px;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.06);
      }
    }
    .active-tab {
      /*color: #3f6aff;
      text-decoration: underline;*/
      background: #3C3772;
      color: #fff;
    }
  }
  > .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 0 20px; /* 为左右两边添加20px的内边距 */
    .content-item {
      height: 400px;
      box-sizing: border-box;
      box-shadow: 2px 7px 8.8px 0px #00000040;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      overflow: hidden; /* 保持圆角效果 */
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.03);
      }
    }
    .box {
      width: 100%;
      height: 55%;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      box-sizing: border-box;
      overflow: hidden;
      .box_video {
        width: 420px;
        height: 220px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .info {
      flex: 1;
      min-height: 0;
      box-sizing: border-box;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
    }
    .title {
      flex: 1;
      min-height: 0;
      box-sizing: border-box;
      font-size: 20px;
      line-height: 29px;
      font-weight: 500;
      color: #000;
      align-content: center;
      display: -webkit-inline-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .group {
      display: flex;
      align-items: center;
      width: 380px;
      height: 50px;
      border: 2px solid #CFCFCF;
      cursor: pointer;
      color: #15104b;
      font-weight: bold;
      border-radius: 6px;
      p {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-left: 130px;
      }
    }
  }
}
::v-deep .el-progress__text {
  display: none;
}
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-label {
  margin-top: 6px;
  font-size: 20px;
}
</style>
