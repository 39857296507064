var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "usadrop-design-form" },
    [
      _c("div", { staticClass: "step-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.canPreviewDesign
                ? _vm.$t("PodProduct.step1")
                : _vm.$t("PodProduct.personalize")
            ) +
            " "
        ),
      ]),
      _c("SceneSelect", {
        attrs: { "senses-config": _vm.scenesConfig },
        model: {
          value: _vm.currentSceneId,
          callback: function ($$v) {
            _vm.currentSceneId = $$v
          },
          expression: "currentSceneId",
        },
      }),
      _vm.validConfig
        ? _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.formModel } },
            [
              _c("div", { staticClass: "pod-color-config" }, [
                _c(
                  "div",
                  { staticClass: "color-image-wrapper" },
                  _vm._l(_vm.colorfulStages, function (stage, key, index) {
                    return _c(
                      "div",
                      { key: stage.stageName, staticClass: "color-image-item" },
                      [
                        _c("p", { staticClass: "color-title" }, [
                          _vm._v(
                            "color" +
                              _vm._s(index + 1) +
                              "/" +
                              _vm._s(stage.stageName)
                          ),
                        ]),
                        stage?.colorConfig?.colorMode === "all"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: stage.color,
                                  expression: "stage.color",
                                },
                              ],
                              attrs: { type: "color" },
                              domProps: { value: stage.color },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(stage, "color", $event.target.value)
                                },
                              },
                            })
                          : _c(
                              "div",
                              _vm._l(
                                stage?.colorConfig?.colors || [],
                                function (color) {
                                  return _c("p", {
                                    key: color,
                                    staticClass: "color-rect",
                                    class:
                                      color === stage.color ? "active" : "",
                                    style: {
                                      background: color,
                                    },
                                    on: {
                                      click: function ($event) {
                                        stage.color = color
                                      },
                                    },
                                  })
                                }
                              ),
                              0
                            ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "div",
                { staticClass: "form-content" },
                _vm._l(_vm.formOptions, function (formItem) {
                  return _c(
                    "el-form-item",
                    { key: formItem.id, attrs: { label: "" } },
                    [
                      formItem.type.toLowerCase().includes("text")
                        ? _c("FormItemInput", {
                            attrs: { config: formItem },
                            model: {
                              value: formItem.value,
                              callback: function ($$v) {
                                _vm.$set(formItem, "value", $$v)
                              },
                              expression: "formItem.value",
                            },
                          })
                        : _vm._e(),
                      formItem.type.toLowerCase().includes("image")
                        ? _c("FormItemImage", {
                            attrs: {
                              config: formItem,
                              "image-real-size": _vm.getImageUploadRealSize(
                                formItem.id
                              ),
                            },
                            model: {
                              value: formItem.value,
                              callback: function ($$v) {
                                _vm.$set(formItem, "value", $$v)
                              },
                              expression: "formItem.value",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.canPreviewDesign
                ? _c(
                    "el-form-item",
                    [
                      _c("div", { staticClass: "step-title" }, [
                        _vm._v(_vm._s(_vm.$t("PodProduct.step2"))),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "preview-btn",
                          attrs: {
                            type: "primary",
                            size: "medium",
                            block: "",
                            round: "",
                            loading: _vm.buttonLoading,
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("PodProduct.preview")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("ImagePreviewDialog", {
        attrs: { "preview-src-list": _vm.previewImageList },
        model: {
          value: _vm.showImagePreviewDialog,
          callback: function ($$v) {
            _vm.showImagePreviewDialog = $$v
          },
          expression: "showImagePreviewDialog",
        },
      }),
      _c("div", { ref: "fabricWorkspace", staticStyle: { display: "none" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }