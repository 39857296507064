<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box">
      <div class="activeName comSerInput">
        <div class="ExhibitionTitle">
          <p>Exhibition</p>
        </div>
        <div class="UniqueMoments">
          <p>Unique Moments Through the Lens: A Retrospective of Exhibitions</p>
        </div>
        <div  class="Winners_box">
          <div class="bigbox">
            <div class="box" v-for="(item,index) in cardList" :key="item.index">
              <div class="images">
                <img :src="item.MainPic" alt="" @mouseenter="enterProduct(index)" @mouseleave="leaveProduct(index)"/>
                <!-- <el-image :src="item.MainPic" lazy></el-image> -->
               <!--  <el-image
                  @mouseenter="ShowProductExhibitionDescription = index"
                  @mouseleave="ShowProductExhibitionDescription == false"
                  :src="item.MainPic"
                 >
                </el-image>  -->
              </div>
              <div class="Contents_Exhibition" @click="showBigImage(index)" v-if="currentIndexImg === index">
                <div class="ProductExhibition_Description">
                  <p>{{ item.ProductExhibition.Description }}</p>
                </div>
              </div>
            </div>
            <div class="LargeImagePreview" v-if="ShowFalse">
              <div class="LargeImagePreviewImgBox">
                <div class="LargePreviewImg" v-for="(item,index) in cardList" :key="item.index">
                  <img :src="item.MainPic" v-if="currentIndex === index" alt="">
                </div>
              </div>
              <div class="elIconCircleClose" @click="elIconCircleCloseBtn">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="pagebox">
            <PagePagination
              :total="options1.total"
              :page-size="options1.pageSize"
              :current-page="options1.page"
              @current-change="pageChange"
            />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { cardListMixin } from '@/utils/mixin'
import PagePagination from '@/components/PagePagination.vue';
export default {
  mixins: [cardListMixin],
  data: () => {
    return {
      dispatch: null,
      page: "Superdeals",
      banner: "",
      type: "",
      options1:
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
      cardList:[],
      ShowProductExhibitionDescription: false,
      currentIndex: null,
      currentIndexImg: null,
      ShowFalse: false,
      imgViewDialog_imgSrc: ""
    };
  },
  components: {
    PagePagination
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.banner = this.$t("Winners.bannername");
    this.itemname = this.$t("Winners.itemname");
    this.getData();
  },
  computed: mapState(["userInfo", "theme"]),
  methods: {
    pageChange(page) {
      //分页器页数改变
      this.options1.page = page;
      this.getData();
    },
    getPageList() {
      //分页器跳转页数
      this.options1.page =
      this.options1.jumpPage > 0 ? Number(this.options1.jumpPage) : 1;
      this.getData();
    },
    getData() {
      let that = this;
      let type = Number(that.activeName);
      that.dispatch('winner/GetExhibitionListWi', {
            CurrentPageIndex: that.options1.page,
            PageSize: that.options1.pageSize,
            RecordCount: that.options1.list.length
        })
        .then((res) => {
          that.cardList = res.Items;
          this.options1.total = res.TotalItems
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showBigImage(index) {
      this.currentIndex = index;
      this.ShowFalse= true;
    },
    enterProduct: function (index) {
      this.currentIndexImg = index;
      this.ShowProductExhibitionDescription = true;
    },
    leaveProduct: function (index) {
      this.currentIndexImg = index;
      this.ShowProductExhibitionDescription = false;
    },
    elIconCircleCloseBtn() {
      this.ShowFalse = false;
    }

  },
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
}
.Winners_box {
  position: relative;
}
.bigbox {
  display: flex;
  flex-wrap: wrap;
}
.box {
  margin-top: 22px;
  margin-left: 32px;
 /* width: 290px;
  height: 380px;
  background: #f6f6fa;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);*/
  border-radius: 10px;
  position: relative;
}
.box:hover {
  transform: scale(1.02);
}
.box .images {
  width: 290px;
  height: 290px;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.images img {
  width: 290px;
  height: 290px;
  margin-top: 10px;
  border-radius: 8px;
  object-fit: cover;
	object-position: left top;
	transition: 1s;
}
.images >>> .el-image {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 8px;
}
.box .simple {
  margin-top: 10px;
  width: 196px;
  height: 46px;
  text-align: left;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 45px;
  word-break: normal;
}
/* 分页 */
.pagebox {
  width: 1320px;
  margin-top: 30px;
}
.Contents_Exhibition {
  position: absolute;
  width: 290px;
  height: 290px;
  background: #000;
  opacity: 0.6;
  top: 10px;
  left: 0px;
  border-radius: 8px;
}
.ProductExhibition_Description {
  z-index: 999;
  position: absolute;
  bottom: 10px;
  left: 5px;
}
.ProductExhibition_Description p {
  color: #fff;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.LargeImagePreview {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background:rgba(0,0,0,0.5);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  z-index: 999;
}
.LargeImagePreviewImgBox {
  width: 1320px;
  height: 750px;
  position: absolute;
  top: 50px;
  left: 500px;
}
/*.LargePreviewImg {
  width: 1000px;
  height: 800px;
}*/
.LargePreviewImg img {
  width: 1000px;
  height: 800px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.CloseImg {
  position: absolute;
  top: 0px;
  left: 0px;
}
.CloseImgBtn {
  position: absolute;
  top: 0px;
  left: 10px;
}
.ExhibitionTitle {
  text-align: center;
}
.ExhibitionTitle p {
  font-size: 30px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.UniqueMoments {
  text-align: center;
}
.UniqueMoments p {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.elIconCircleClose {
  position: absolute;
  top: 30px;
  right: 380px;
}
.elIconCircleClose >>> .el-icon-close {
  font-size: 30px;
  color: #fff !important;
  cursor: pointer;
}
</style>
