<template>
  <div class="sense-name-dialog">
    <el-dialog width="46%" :visible.sync="showDialogVisible" title="Sense Setting">
      <el-form ref="formRef" label-position="right" label-width="120px" :model="currentSenseForm">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="Sense Name settings" name="1">
            <el-form-item
              :label="`Sense${index + 1} Name`"
              v-for="(item, index) in currentSenseForm.options"
              :key="item.id"
              :prop="'options.' + index + '.name'"
              :rules="[
                {
                  required: true,
                  message: 'Please input Sense Name!',
                  trigger: 'blur'
                }
              ]"
            >
              <el-input class="formInput" v-model="item.name"></el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="Sense Option basic info settings" name="3">
            <el-form-item label="Sense Title" prop="name">
              <el-input class="formInput" v-model="currentSenseForm.title"></el-input>
            </el-form-item>
            <el-form-item label="Tooltip">
              <el-input class="formInput" v-model="currentSenseForm.toolTip"></el-input>
            </el-form-item>
            <el-form-item label="Help text">
              <el-input class="formInput" v-model="currentSenseForm.helpTxt"></el-input>
            </el-form-item>
            <el-form-item label="Popup tip">
              <el-checkbox v-model="currentSenseForm.showPopupTip"></el-checkbox>
            </el-form-item>
            <el-form-item label="Title">
              <el-input class="formInput" v-model="currentSenseForm.popTitle"></el-input>
            </el-form-item>
            <el-form-item label="Width">
              <el-input class="formInput" v-model="currentSenseForm.popWidth"></el-input>
            </el-form-item>
            <div class="fuwenben">
              <wangEnduit boxId="boxSense" v-model="currentSenseForm.richText"></wangEnduit>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button round @click="showDialogVisible = false">Cancel</el-button>
          <el-button round type="primary" @click="confirmSense">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import wangEnduit from '@/components/wangEnduit'
export default {
  components: {
    wangEnduit
  },
  name: 'SenseNameDialog',
  data() {
    return {
      activeNames: ['1', '3']
      //   visible: false
    }
  },
  computed: {
    showDialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Boolean
    },
    currentSenseForm: {
      type: Object,
      required: false
    }
  },
  methods: {
    confirmSense() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          this.$emit('confirmSense', this.currentSenseForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sense-name-dialog {
  width: 100%;
  // height: 100%;
  font-family: Open Sans;
  ::v-deep .el-dialog {
    overflow: auto;
    border-radius: 26px;
    .el-dialog__body {
      padding: 0;
    }
  }
  ::v-deep .el-collapse-item__header {
    background: #f4f7ff;
    padding: 0 12px 0 32px;
    font-weight: 600;
    font-size: 16px;
  }
  ::v-deep .el-collapse-item__content {
    padding: 20px 40px 20px;
  }
  .w-e-text-container {
    min-height: 130px !important;
  }
  .formInput {
    ::v-deep .el-input__inner {
      border-radius: 36px;
      border: 1px solid #e5e5e5;
    }
  }
}
</style>
