<template>
  <div class="usadrop-design-form">
    <div class="step-title">
      {{ canPreviewDesign ? $t('PodProduct.step1') : $t('PodProduct.personalize') }}
    </div>
    <SceneSelect v-model="currentSceneId" :senses-config="scenesConfig" />
    <el-form ref="form" :model="formModel" v-if="validConfig">
      <div class="pod-color-config">
        <!-- <h2 style="margin-bottom: 12px">Color Picker</h2> -->
        <div class="color-image-wrapper">
          <div
            class="color-image-item"
            v-for="(stage, key, index) in colorfulStages"
            :key="stage.stageName"
          >
            <!-- <el-image width="100%" :src="stage.screenshot" :previewSrcList="[stage.screenshot]" /> -->
            <p class="color-title">color{{ index + 1 }}/{{ stage.stageName }}</p>
            <!-- 允许选择所有颜色 -->
            <input
              v-if="stage?.colorConfig?.colorMode === 'all'"
              type="color"
              v-model="stage.color"
            />
            <div v-else>
              <p
                class="color-rect"
                :class="color === stage.color ? 'active' : ''"
                :style="{
                  background: color
                }"
                v-for="color in stage?.colorConfig?.colors || []"
                :key="color"
                @click="stage.color = color"
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-content">
        <el-form-item v-for="formItem in formOptions" label="" :key="formItem.id">
          <FormItemInput
            v-if="formItem.type.toLowerCase().includes('text')"
            v-model="formItem.value"
            :config="formItem"
          />
          <FormItemImage
            v-if="formItem.type.toLowerCase().includes('image')"
            v-model="formItem.value"
            :config="formItem"
            :image-real-size="getImageUploadRealSize(formItem.id)"
          />
        </el-form-item>
      </div>
      <el-form-item v-if="canPreviewDesign">
        <div class="step-title">{{ $t('PodProduct.step2') }}</div>
        <el-button
          class="preview-btn"
          type="primary"
          size="medium"
          block
          round
          :loading="buttonLoading"
          @click="onSubmit"
        >
          {{ $t('PodProduct.preview') }}
        </el-button>
      </el-form-item>
    </el-form>
    <ImagePreviewDialog v-model="showImagePreviewDialog" :preview-src-list="previewImageList" />
    <div ref="fabricWorkspace" style="display: none"></div>
  </div>
</template>
<script>
import FormItemInput from './widgets/FormItemInput.vue'
import FormItemImage from './widgets/FormItemImage.vue'
import ImagePreviewDialog from '@/components/ImagePreview'
import { designRender } from '@/views/PodPage/components/designRender'
import { useJSCore } from 'sm-js-core'
import SceneSelect from '@/views/PodPage/components/widgets/SceneSelect.vue'

export default {
  props: {
    designConfig: {
      type: Object,
      required: true
    },
    designData: {
      type: Object,
      required: true
    },
    activeScenesIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
    SceneSelect,
    FormItemInput,
    FormItemImage,
    ImagePreviewDialog
  },
  data() {
    return {
      formModel: {},
      buttonLoading: false,
      showImagePreviewDialog: false,
      previewImageList: [],
      colorfulStages: null,
      currentSceneId: null
    }
  },
  watch: {
    designConfig: {
      immediate: true,
      deep: true,
      handler(newVal, o) {
        if (!newVal) {
          return
        }
        const oldOptions = this.formModel?.options || []
        this.initFormModel(newVal, this.activeScenesIndex, oldOptions)
      }
    },
    activeScenesIndex: {
      immediate: true,
      handler(newVal, o) {
        if (newVal >= 0 && this.designData.scenes) {
          this.currentSceneId = this.designData.scenes[newVal].id
        }
      }
    },
    currentSceneId: {
      handler(newVal, o) {
        if (this.designData.scenes) {
          const index = this.designData.scenes.findIndex((item) => item.id === newVal)
          this.initFormModel(this.designConfig, index, [])
        }
      }
    }
  },
  computed: {
    validConfig() {
      return this.formOptions.length > 0
    },
    formOptions() {
      return this.formModel.options?.filter((item) => item.status) || []
    },
    canPreviewDesign() {
      return this.formModel.canPreview
    },
    currentSceneDesignData() {
      const index = this.designData.scenes.findIndex((item) => item.id === this.currentSceneId)
      if (index === -1) {
        return {}
      }
      return this.designData.scenes[index].designInfo
    },
    scenesConfig() {
      const scenesConfig = this.designConfig.scenesConfig || {}
      const options = []
      this.designConfig.scenes?.forEach((item) => {
        const openCount = item.config.options?.filter((item) => item.status)?.length || 0
        if (openCount > 0) {
          options.push({
            name: item.name,
            id: item.id
          })
        }
      })
      for (let i = 0; i < scenesConfig.options.length; i++) {
        const containSense =
          options.findIndex((option) => option.id === scenesConfig.options[i].id) !== -1
        if (!containSense) {
          scenesConfig.options.splice(i, 0)
        }
      }
      return scenesConfig
    }
  },
  methods: {
    initFormModel(designConfig, index, oldOptions) {
      if (!designConfig.scenes) {
        return
      }
      this.formModel = useJSCore().obj.deepClone(designConfig.scenes[index].config)
      this.formModel.options?.forEach((item) => {
        if (item.type === 'image') {
          const realSize = this.getImageUploadRealSize(item.id)
          item.value = {
            url: item.value,
            width: realSize?.width || 0,
            height: realSize?.height || 0
          }
        }
      })
      if (oldOptions.length > 0) {
        this.formModel.options?.forEach((item) => {
          const oldItem = oldOptions.find((old) => old.id === item.id)
          item.value = oldItem?.value
        })
      }
      this.calculateColorStages()
      this.currentSceneId = this.designData.scenes[index].id
      this.$forceUpdate()
    },
    getImageUploadRealSize(id) {
      for (const stage in this.currentSceneDesignData?.stages) {
        const object = this.currentSceneDesignData?.stages[stage].objects.find(
          (item) => item?.id === id
        )
        if (object) {
          return {
            width: Number(object.width.toFixed(0)),
            height: Number(object.height.toFixed(0))
          }
        }
      }
      return { width: 0, height: 0 }
    },
    //回填数据
    backFillingData() {
      const designDataStages = useJSCore().obj.deepClone(this.currentSceneDesignData.stages)
      // 支持换背景的图层改变颜色
      for (const stageKey in this.colorfulStages) {
        // 找出产品图层
        const productLayer = designDataStages[stageKey].objects.find(
          (obj) => obj?.type === 'image' && obj?.id === 'product' && obj?.backgroundColor
        )
        if (productLayer) {
          productLayer.backgroundColor = this.colorfulStages[stageKey].color
        }
      }
      this.formModel.options.forEach((item) => {
        const id = item.id
        for (const stage in designDataStages) {
          const object = designDataStages[stage].objects.find((obj) => obj?.id === id)
          if (object) {
            if (item.type.toLowerCase().includes('text')) {
              object.text = item.value
            } else if (item.type.toLowerCase().includes('image') && item.value?.url) {
              object.src = item.value.url
              const oldWidth = object.width
              const oldHeight = object.height
              const oldScaleX = object.scaleX
              const oldScaleY = object.scaleY
              object.width = item.value.width
              object.height = item.value.height
              object.scaleX = (oldWidth * oldScaleX) / object.width
              object.scaleY = (oldHeight * oldScaleY) / object.height
            }
            break
          }
        }
      })
      return designDataStages
    },

    /**
     * 预览
     */
    onSubmit() {
      const result = this.backFillingData()
      this.buttonLoading = true
      designRender(this.$refs.fabricWorkspace, result)
        .then((res) => {
          this.previewImageList = res.map((item) => item.image)
          this.showImagePreviewDialog = true
          this.buttonLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.buttonLoading = false
        })
    },
    calculateColorStages() {
      const cloneStages = useJSCore().obj.deepClone(this.currentSceneDesignData?.stages || {})
      // Object.keys(cloneStages).forEach((key) => {
      //   const stage = cloneStages[key]
      //   const objects = stage?.objects
      //   if (stage?.colorConfig?.colorMode !== 'nonsupport') {
      //     const defaultColor = objects.find((item) => item?.backgroundColor)?.backgroundColor
      //     stage.color = defaultColor
      //   }
      // })
      // this.colorfulStages = cloneStages
      Object.entries(cloneStages).forEach(([key, stage]) => {
        const objects = stage.objects
        for (const object of objects) {
          if (object.id === 'product') {
            // 此块板是否支持配置背景色
            if (stage?.colorConfig?.colorMode !== 'nonsupport') {
              stage.color = object.backgroundColor
              // 背景颜色位于哪一个图层
              stage.colorInObjectId = object.id
            } else {
              delete cloneStages[key]
            }
          }
        }
      })
      this.colorfulStages = cloneStages
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep(.el-form-item--small.el-form-item) {
  margin-bottom: 4px;
}

.usadrop-design-form {
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  flex: 1;

  .step-title {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin: 8px 0;
  }

  .form-content {
    // max-height: 80vh;
    // overflow-y: auto;
  }

  .preview-btn {
    width: 100%;
    background: #bc0c34;
    border: none;
    height: 40px;
    font-size: 16px;
  }

  .pod-color-config {
    border-radius: 6px;
    position: relative;
    flex: 1;
    .color-image-wrapper {
      // display: flex;
      .color-image-item {
        // display: flex;
        // align-items: center;
        margin-bottom: 16px;
        .color-title {
          font-size: 14px;
          color: #333;
          font-weight: 500;
          margin: 0 8px 8px 0;
        }
        .color-rect {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          display: inline-block;
          border: 1px solid #ccc;
          cursor: pointer;
          &.active {
            border: 2px solid #3f6aff;
          }
        }
        .color-select {
          width: 120px;
        }
      }
    }
  }
}
</style>
