<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="TabBox">
        <div class="contentBox1" @click="MyCard">
          <p>
            <img src="../../assets/imgs/ProfileSub/Slice199MyCard1.png" alt="" />
          </p>
          <p>
            {{ $t('center.MyCard') }}
          </p>
        </div>
        <div class="contentBox1" @click="MyVoucher">
          <p>
            <img src="../../assets/imgs/ProfileSub/Slice199Voucher1.png" alt="" />
          </p>
          <p>
            {{ $t('center.Voucher') }}
          </p>
        </div>
        <div class="">
          <a
            class="contentBox1"
            href="https://vn.usadrop.com/blog/"
            target="_blank"
            v-if="this.fromLink === 'vn.usadrop.com'"
          >
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Tips1.png" alt="" />
            </p>
            <p>
              {{ $t('center.Tips') }}
            </p>
          </a>
          <a class="contentBox1" href="https://usadrop.com/blog/" target="_blank" v-else>
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Tips1.png" alt="" />
            </p>
            <p>
              {{ $t('center.Tips') }}
            </p>
          </a>
        </div>
        <div class="">
          <a
            class="contentBox1"
            href="https://vn.usadrop.com/privacy-policy/"
            target="_blank"
            v-if="this.fromLink === 'vn.usadrop.com'"
          >
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Policy.png" alt="" />
            </p>
            <p>
              {{ $t('center.PrivacyPolicy') }}
            </p>
          </a>
          <a class="contentBox1" href="https://usadrop.com/privacy-policy/" target="_blank" v-else>
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Policy.png" alt="" />
            </p>
            <p>
              {{ $t('center.PrivacyPolicy') }}
            </p>
          </a>
        </div>
        <div class="">
          <a
            class="contentBox1"
            href="https://vn.usadrop.com/terms-and-conditions/"
            target="_blank"
            v-if="this.fromLink === 'vn.usadrop.com'"
          >
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Terms1.png" alt="" />
            </p>
            <p>
              {{ $t('center.TermsAndConditions') }}
            </p>
          </a>
          <a
            class="contentBox1"
            href="https://usadrop.com/terms-and-conditions/"
            target="_blank"
            v-else
          >
            <p>
              <img src="../../assets/imgs/ProfileSub/Slice199Terms1.png" alt="" />
            </p>
            <p>
              {{ $t('center.TermsAndConditions') }}
            </p>
          </a>
        </div>
      </div>
      <div class="CardFunctionBox"> <!-- 卡片功能 -->
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label" class="RegistrationInfoImg">
              <img src="../../assets/imgs/ProfileSub/RegistrationInfo.png" alt="" />
              {{ $t('center.RegistrationInfo') }}
            </span>
            <div> 
              <div class="Important_Notice_box" @click="ClickImportantNotice">  <!-- 公告 -->
                <div class="Important_Notice_main">
                  <span style="margin-left: 15px">Important Notice:&nbsp;</span>
                  <span>
                    <span class="box" v-for="(item, index) in NoticesList" :key="index">
                      <span class="updated">{{ item.Title }}</span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="Welcome_name"> <!-- 用户名和会员等级 -->
                <el-upload
                  class="avatar-uploader"
                  :action="action"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :headers="headersaa"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="baseApiHost() + imageUrl" class="avatar" />
                  <img
                    v-else
                    src="../../assets/imgs/Bank-Transfer/headportrait.png"
                    class="avatar"
                  />
                </el-upload>
                <div class="name">
                  <span>{{ $t('center.Welcome') }}</span>
                  <span style="margin-left: 5px">{{ CenterMessage.Fillname }}!</span>
                  <span class="Diamonds" style="margin-left: 5px">
                    <span v-if="paymentList.GradeId == 1">
                      <img
                        class="header_Basic"
                        src="../../assets/imgs/NewLogo/MemberJICHUSlice.png"
                        alt="" />
                    </span>
                    <el-tooltip
                      v-if="paymentList.GradeId == 2"
                      class="item"
                      effect="light"
                      content="Pro"
                      placement="top">
                      <span>
                        <img
                          class="header_Basic"
                          src="../../assets/imgs/NewLogo/MemberVIPSlice16.png"
                          alt=""
                        />
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      v-if="paymentList.GradeId == 3"
                      class="item"
                      effect="light"
                      content="Plus"
                      placement="top">
                      <span>
                        <img
                          class="header_Basic"
                          src="../../assets/imgs/NewLogo/MemberSVIPSlice16.png"
                          alt=""
                        />
                      </span>
                    </el-tooltip>
                  </span>
                </div>
              </div>
              <div class="Membership_Exhibition"> <!-- 会员权益展示 -->
                <div class="Membership_One">
                  <div class="one_content" v-if="paymentList.GradeId == 1">
                    <p><img src="../../assets/imgs/ProfileSub/ProductJCSlice.png" alt="" /></p>
                    <p>{{ $t('center.WinningProducts') }}</p>
                    <p style="color: #2c87fb">{{ $t('center.PreviousDays') }}</p>
                  </div>
                  <div class="one_content" v-if="paymentList.GradeId == 2">
                    <p><img src="../../assets/imgs/ProfileSub/ProductVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.WinningProducts') }}</p>
                    <p style="color: #d14139">{{ $t('center.WithinMonth') }}</p>
                  </div>
                  <div class="one_content" v-if="paymentList.GradeId == 3">
                    <p><img src="../../assets/imgs/ProfileSub/ProductSVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.WinningProducts') }}</p>
                    <p style="color: #fcb12c">{{ $t('center.WithinWeek') }}</p>
                  </div>
                </div>

                <div class="Membership_Two">
                  <div class="two_content" v-if="paymentList.GradeId == 1">
                    <p><img src="../../assets/imgs/ProfileSub/SourcingJCSlice.png" alt="" /></p>
                    <p>{{ $t('center.Sourcing') }}</p>
                    <p style="color: #2c87fb">{{ $t('center.Month') }}</p>
                  </div>
                  <div class="two_content" v-if="paymentList.GradeId == 2">
                    <p><img src="../../assets/imgs/ProfileSub/SourcingVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.Sourcing') }}</p>
                    <p style="color: #d14139">{{ $t('center.21Week') }}</p>
                  </div>
                  <div class="two_content" v-if="paymentList.GradeId == 3">
                    <p><img src="../../assets/imgs/ProfileSub/SourcingSVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.Sourcing') }}</p>
                    <p style="color: #fcb12c">{{ $t('center.63Week') }}</p>
                  </div>
                </div>

                <div class="Membership_Three">
                  <div class="three_content" v-if="paymentList.GradeId == 1">
                    <p><img src="../../assets/imgs/ProfileSub/VideoJCSlice.png" alt="" /></p>
                    <p>{{ $t('center.VideoShooting') }}</p>
                    <p style="color: #2c87fb">{{ $t('center.10Voucher') }}</p>
                  </div>
                  <div class="three_content" v-if="paymentList.GradeId == 2">
                    <p><img src="../../assets/imgs/ProfileSub/VideoVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.VideoShooting') }}</p>
                    <p style="color: #d14139">{{ $t('center.50Voucher') }}</p>
                  </div>
                  <div class="three_content" v-if="paymentList.GradeId == 3">
                    <p><img src="../../assets/imgs/ProfileSub/VideoSVIPSlice.png" alt="" /></p>
                    <p>{{ $t('center.VideoShooting') }}</p>
                    <p style="color: #fcb12c">{{ $t('center.100Voucher') }}</p>
                  </div>
                </div>

                <div class="Membership_Four">
                  <div @click="GradeBthList()" class="four_content" v-if="paymentList.GradeId == 1">
                    <p><img src="../../assets/imgs/ProfileSub/MoreJCSlice.png" alt="" /></p>
                    <p style="color: #2c87fb">{{ $t('center.ViewMore') }}</p>
                  </div>
                  <div @click="GradeBthList()" class="four_content" v-if="paymentList.GradeId == 2">
                    <p><img src="../../assets/imgs/ProfileSub/MoreVIPSlice.png" alt="" /></p>
                    <p style="color: #d14139">{{ $t('center.ViewMore') }}</p>
                  </div>
                  <div @click="GradeBthList()" class="four_content" v-if="paymentList.GradeId == 3">
                    <p><img src="../../assets/imgs/ProfileSub/MoreSVIPSlice.png" alt="" /></p>
                    <p style="color: #fcb12c">{{ $t('center.ViewMore') }}</p>
                  </div>
                </div>
              </div>
              <div class="personal_information_box"> <!-- 会员修改个人信息 -->
                <div class="personal_msg">
                  <div class="edit_personal">
                    <p>
                      <i class="el-icon-edit"></i>
                      <span>{{ $t('center.Edit') }}</span>
                    </p>
                  </div>
                  <div class="personal_msg_box">
                    <div class="msg_left">
                      <div class="email_box">
                        <p>{{ $t('center.email') }}</p>
                        <p>
                          <el-input
                            v-model="CenterMessage.Email"
                            :disabled="true"
                            name=""
                          ></el-input>
                        </p>
                      </div>
                      <div class="telephone_box">
                        <p>{{ $t('center.telephone') }}</p>
                        <p>
                          <el-input
                            v-model="CenterMessage.Telephone"
                            :disabled="true"
                            name=""
                          ></el-input>
                        </p>
                      </div>
                      <div class="IconInfo" @mouseenter="enter" @mouseleave="leave">
                        <i class="el-icon-info"></i>
                      </div>
                      <div class="DisplayInfo" v-if="displayUSAdropInfo">
                        <p>{{ $t('center.YourPrivacy') }}</p>
                        <p>{{ $t('center.Infomation') }}</p>
                      </div>
                      <div class="facebook_box">
                        <p>{{ $t('center.facebook') }}</p>
                        <p><el-input v-model="CenterMessage.Facebook" name=""></el-input></p>
                      </div>
                    </div>
                    <div class="msg_right">
                      <div class="skype_box">
                        <p>{{ $t('center.skype') }}</p>
                        <p><el-input v-model="CenterMessage.Skype" name=""></el-input></p>
                      </div>
                      <div class="whatsapp_box">
                        <p>{{ $t('center.whatsapp') }}</p>
                        <p><el-input v-model="CenterMessage.WhatsApp" name=""></el-input></p>
                      </div>
                      <div class="wechat_box">
                        <p>{{ $t('center.wechat') }}</p>
                        <p>
                          <el-input
                            v-model="CenterMessage.WeChat"
                            name=""
                            autocomplete="new-password"
                          ></el-input>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn">
                <button v-if="paymentList.IsAdmin == true" class="comBtn btn-bg0" @click="confirm">
                  {{ $t('center.save') }}
                </button>
                <button class="comBtn border-col0 col-col0 logoutbtn" @click="logout">
                  {{ $t('center.logout') }}
                </button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane> <!-- 修改密码 -->
            <span slot="label" class="AccountSecurityImg">
              <img src="../../assets/imgs/ProfileSub/AccountSecurity.png" alt="" />
              {{ $t('center.AccountSecurity') }}
            </span>
            <div>
              <div class="edit_password_main">
                <div class="Reset_Password_content">
                  <p>{{ $t('center.ResetPassword') }}</p>
                </div>
                <div class="PsdBtn">
                  <div class="New_Password_btn">
                    <p>{{ $t('center.NewPassword') }}</p>
                    <p>
                      <el-input
                        class="raduisInput"
                        v-model="password"
                        show-password
                        autocomplete="new-password"
                      ></el-input>
                    </p>
                  </div>
                  <div class="Repeat_New_Password">
                    <p>{{ $t('center.Repeat') }}</p>
                    <p>
                      <el-input
                        class="raduisInput"
                        v-model="NewPassword"
                        show-password
                        autocomplete="new-password"
                      ></el-input>
                    </p>
                  </div>
                </div>
              </div>
              <div class="confirm_btn">
                <button class="btn-bg0" @click="confirm">{{ $t('center.save') }}</button>
              </div>
            </div>
          </el-tab-pane>
          
          <el-tab-pane> <!-- 系数计算 -->
            <span slot="label" class="PublishSettingsImg">
              <img src="../../assets/imgs/ProfileSub/PublishSettings.png" alt="" />
              {{ $t('center.PublishSettings') }}
            </span>
            <div>
              <div class="publishSetting1" v-for="(item, index) in SettingList" :key="index">
                <div class="Price">{{ item.SettingTitle }}</div>
                <div class="cost">
                  {{ $t('center.cost') }}
                  <input v-model.number="item.SettingValue" name="" />
                </div>
                <div class="produce">{{ item.Remarks }}</div>
              </div>
              <div class="payment">
                <button class="btn-bg0" @click="payment">{{ $t('center.save') }}</button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { baseApiHost } from '@/utils/env'
import { mapState } from 'vuex'
import { getFrom } from '@/utils/tools'

export default {
  data: () => {
    return {
      baseApiHost: baseApiHost,
      action: baseApiHost() + '/api/File/UploadImgage',
      fromLink: getFrom(),
      dispatch: null,
      banner: '',
      itemname: '',
      page: 'ProfileSub',
      CenterMessage: [], //当期登录用户的信息
      CenterList: [], // 当前用户业务员
      rawData: '', // 获取表单时clone的原始数据
      diffData: '', // 差异
      SettingList: [], //当前用户的发布设置列表
      password: '', //密码
      NewPassword: '', // 新密码
      paymentList: [], //会员权益
      selected: '0',
      imageUrl: '',
      headersaa: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      }, // token
      NoticesList: [], // 获取最新公告标题
      displayUSAdropInfo: false
    }
  },
  components: {},
  computed: mapState(['userInfo', 'theme']),
  mounted() {
    this.banner = this.$t('center.bannername')
    this.itemname = this.$t('center.itemname')
    this.getData()
    this.menterData()
    this.getDataMsa()
    this.SettingData()
    setTimeout(() => {
      this.AgentData()
    }, 500)
  },
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
    if (this.$route.params.id == undefined) {
      this.selected = 0
    } else {
      this.selected = this.$route.params.id
    }
  },
  methods: {
    enter: function (e) {
      this.displayUSAdropInfo = true
    },
    leave: function (e) {
      this.displayUSAdropInfo = false
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          // 价格重置
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    MyCard() {
      this.$router.push('/myCard')
    },
    MyVoucher() {
      this.$router.push('/Voucher')
    },
    ClickImportantNotice() {
      this.$router.push('/ImportantNotices')
    },
    reverse() {
      this.SettingList.reverse()
    },
    toggle(item, key) {
      this.selected = key
      if (key == 1) {
        this.AgentData()
      }
      if (key == 2) {
        this.SettingData()
      }
    },
    getData() { // 获取数据
      this.dispatch('center/messageCr')
        .then((res) => {
          this.CenterMessage = res.Result
          this.rawData = { ...res.Result }
          this.imageUrl = this.CenterMessage.Avatar
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.Result[0].FullFileName
      if (!this.diffData) {
        this.diffData = {}
      }
      this.$store.commit('setAvater', this.imageUrl)
      this.diffData.Avatar = this.imageUrl
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error(this.$t('center.format'))
      }
      if (!isLt2M) {
        this.$message.error(this.$t('center.cannot'))
      }
      return isJPG && isLt2M
    },
    diffFormData() { // 比较差异的方法
      for (let k in this.rawData) {
        if (this.rawData[k] !== this.CenterMessage[k]) {
          if (!this.diffData) {
            this.diffData = {}
          }
          this.diffData[k] = this.CenterMessage[k]
        }
      }
    },
    modifyData() { // 修改数据
      if (this.NewPassword != this.password) {
        return this.$message.error(this.$t('center.inconsistent'))
      }
      this
        .dispatch('center/ModifyMemberCr', {
          MemberId: this.CenterMessage.MemberId,
          Email: this.CenterMessage.Email,
          Skype: this.CenterMessage.Skype,
          Facebook: this.CenterMessage.Facebook,
          WhatsApp: this.CenterMessage.WhatsApp,
          WeChat: this.CenterMessage.WeChat,
          Avatar: this.imageUrl,
          NewPassword: this.NewPassword
        })
        .then((res) => {
          console.log(res)
          if (res.Success == true) {
            this.$message.success(this.$t('center.submitted'))
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    confirm() {
      this.diffFormData()
      let SetTime = this
      this.modifyData()
      setTimeout(function () {
        SetTime.getData()
      }, 500)
    },
    logout() {
      window.sessionStorage.clear()
      window.location.href = `${window.location.origin}/`
    },
    AgentData() { // 获取代理人信息
      this
        .dispatch('center/GetAgentByIdCr', {
          id: this.CenterMessage.AgentId
        })
        .then((res) => {
          this.CenterList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    SettingData() { // 获取当前用户的发布设置列表
      this
        .dispatch('center/GetPublishSettingsCr')
        .then((res) => {
          this.SettingList = res.Result
          if (this.SettingList.length > 0) {
            this.SettingList.map((x) => {
              if (x.SettingKey == 'DefaultPublishComparedPrice') {
                x.SettingTitle = 'Default Publish Compared at Price'
              } else if (x.SettingKey == 'DefaultPublishPrice') {
                x.SettingTitle = 'Default Publish Price'
              }
            })
          }
          this.reverse()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    payment() {
      let list = this.SettingList
      this
        .dispatch('center/SavePublishSettingsCr', [
          {
            SettingGroup: list[0].SettingGroup,
            SettingKey: list[0].SettingKey,
            SettingValue: list[0].SettingValue
          },
          {
            SettingGroup: list[1].SettingGroup,
            SettingKey: list[1].SettingKey,
            SettingValue: list[1].SettingValue
          }
        ])
        .then((res) => {
          if (res.Success == true) {
            this.$message.success(this.$t('center.successfully'))
            this.SettingData()
          } else {
            this.$message.error(this.$t('center.current'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    GradeBthList() { /* 跳转会员等级列表 */
      this.$router.push('/Upgrade')
    },
    getDataMsa() { /* 获取最新公告的标题 */
      this
        .dispatch('notification/GetTopNo', {
          num: 1
        })
        .then((res) => {
          this.NoticesList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 130px;
  font-family: 'Regular';
}
.activeName {
  width: 1320px;
  height: 735px;
  border-radius: 10px !important;
}
.CardFunctionBox {
  margin-top: 40px;
  border-radius: 15px;
  margin-left: 15px;
  height: auto;
}
::v-deep .CardFunctionBox .el-tabs--border-card .el-tabs__content {
  border-radius: 15px;
  padding-bottom: 30px;
}
::v-deep .CardFunctionBox .el-tabs--border-card {
  border-radius: 10px !important;
}
::v-deep .CardFunctionBox .el-tabs__nav-scroll {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
::v-deep .CardFunctionBox .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
::v-deep .CardFunctionBox .el-tabs__nav-wrap {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.RegistrationInfoImg img {
  width: 16px;
  height: 16px;
}
.AccountSecurityImg img {
  width: 16px;
  height: 16px;
}
.PublishSettingsImg img {
  width: 16px;
  height: 16px;
}
.DisplayInfo {
  width: auto;
  height: 56px;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  left: 215px;
  top: 190px;
  padding-right: 8px;
  p:nth-child(1) {
    font-size: 14px;
    color: #fff;
    padding-top: 5px;
    padding-left: 10px;
  }
  p:nth-child(2) {
    font-size: 14px;
    color: #fff;
    padding-left: 10px;
  }
}
/*自动继承父元素的背景*/
.DisplayInfo::before {
  content: '';
  position: absolute;
  bottom: 85%;
  left: 350px;
  width: 15px;
  height: 15px;
  margin-top: 15px;
  background: inherit;
  transform: rotate(45deg);
}
.Important_Notice_main {
  width: 1200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin-left: 40px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;
  span:nth-child(2) {
    line-height: 40px;
    margin-left: 10px;
    color: #4c8bf5;
  }
}
.edit_password_main {
  width: 800px;
  height: 300px;
  margin-top: 20px;
  margin-left: 150px;
  border-radius: 10px;
  position: relative;
}
.Reset_Password_content {
  margin-left: 50px;
  margin-top: 30px;
  font-size: 14px;
}
.New_Password_btn {
  margin-top: 35px;
  margin-left: 50px;
  font-size: 14px;
  p:nth-child(2) {
    width: 600px;
  }
}
.Repeat_New_Password {
  margin-top: 35px;
  margin-left: 50px;
  font-size: 14px;
  p:nth-child(2) {
    width: 600px;
  }
}
.confirm_btn {
  margin-top: 60px;
  margin-right: 130px;
  position: relative;
  button {
    display: block;
    width: 200px;
    height: 42px;
    background: #474882;
    border-radius: 21px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    bottom: 30px;
    left: 400px;
  }
  button:hover {
    background: #3c3772 !important;
    color: #fff;
  }
}
.header_Basic {
  width: 25px;
  height: 25px;
}
.Welcome_name {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding-left: 39px;
  margin-top: 19px;
  margin-bottom: 20px;
  .name {
    margin-top: 7px;
    margin-left: 5px;
  }
}
.Membership_Exhibition {
  width: 100%;
  height: 130px;
  margin-top: 0px;
  display: flex;
  margin-left: 45px;
  margin-bottom: 10px;
  .Membership_One {
    width: 240px;
    height: 110px;
    border: 1px solid #ccc;
    border-radius: 10px;
    .one_content{
      p:nth-child(1) {
        text-align: center;
        margin-top: 5px;
      }
      p:nth-child(2) {
        text-align: center;
        font-size: 14px;
      }
      p:nth-child(3) {
        text-align: center;
        font-size: 14px;
      }
    }
  }
  .Membership_Two {
    width: 240px;
    height: 110px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: 73px;
    .two_content p:nth-child(1) {
      text-align: center;
      margin-top: 10px;
    }
    .two_content p:nth-child(1) img {
      width: 35px;
      height: 32px;
      margin-top: 3px;
    }
    .two_content p:nth-child(2) {
      text-align: center;
      font-size: 14px;
    }
    .two_content p:nth-child(3) {
      text-align: center;
      font-size: 14px;
    }
  }
  .Membership_Three {
    width: 240px;
    height: 110px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: 73px;
    .three_content p:nth-child(1) img {
      width: 26px;
      height: 26px;
      margin-top: 10px;
    }
    .three_content p:nth-child(1) {
      text-align: center;
      margin-top: 7px;
    }
    .three_content p:nth-child(2) {
      text-align: center;
      font-size: 14px;
    }
    .three_content p:nth-child(3) {
      text-align: center;
      font-size: 14px;
    }
  }
  .Membership_Four {
    width: 240px;
    height: 110px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: 73px;
    .four_content p:nth-child(1) {
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
    }
    .four_content p:nth-child(1) img {
      width: 40px;
      height: 40px;
    }
    .four_content p:nth-child(2) {
      text-align: center;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
.personal_information_box {
  width: 100%;
  height: 290px;
  .personal_msg {
    width: 1200px;
    height: 280px;
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
    margin-left: 40px;
  }
  .personal_msg_box {
    display: flex;
  }
  .edit_personal {
    width: 100%;
    height: 40px;
    p {
      position: absolute;
      top: 13px;
      right: 20px;
      color: #808080;
    }
  }
  .msg_left {
    width: 47%;
    height: 320px;
  }
  ::v-deep .msg_left .el-input__inner {
    border-radius: 50px;
  }
  .email_box {
    margin-left: 20px;
    p:nth-child(1) {
      font-size: 14px;
    }
    p:nth-child(2) {
      margin-top: 10px;
    }
  }
}
.telephone_box {
  margin-left: 20px;
  p:nth-child(1) {
    font-size: 14px;
  }
  p:nth-child(2) {
    margin-top: 10px;
  }
}
.IconInfo {
  position: absolute;
  top: 152px;
  left: 570px;
  cursor: pointer;
}
::v-deep .IconInfo .el-icon-info {
  font-size: 20px;
}
.facebook_box {
  margin-left: 20px;
  p:nth-child(1) {
    font-size: 14px;
  }
  p:nth-child(2) {
    margin-top: 10px;
  }
}
.msg_right {
  width: 47%;
  height: 320px;
  margin-left: 30px;
}
::v-deep .msg_right .el-input__inner {
  border-radius: 50px;
}
.skype_box {
  margin-left: 20px;
  p:nth-child(1) {
    font-size: 14px;
  }
  p:nth-child(2) {
    margin-top: 10px;
  }
}
.whatsapp_box {
  margin-left: 20px;
  p:nth-child(1) {
    font-size: 14px;
  }
  p:nth-child(2) {
    margin-top: 10px;
  }
}
.wechat_box {
  margin-left: 20px;
  p:nth-child(1) {
    font-size: 14px;
  }
  p:nth-child(2) {
    margin-top: 10px;
  }
}
.publishSetting1 {
  width: 990px;
  height: 181px;
  padding-left: 51px;
}
.Price {
  padding-top: 52px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.6);
}
.cost {
  margin-top: 21px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.8);
  input {
    padding-left: 20px;
    margin-left: 24px;
    display: inline-block;
    width: 200px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgb(0, 0, 0, 0.4);
    border-radius: 50px;
  }
}
.produce {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
}
.payment {
  text-align: center;
  button {
    width: 200px;
    height: 42px;
    line-height: 42px;
    background: #474882;
    border-radius: 21px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 30px;
  }
  button:hover {
    background: #3c3772 !important;
    color: #fff;
  }
}
/* 头像选择框 */
.avatar-uploader {
  display: inline;
  ::v-deep .el-upload {
    border-radius: 50%;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;
    img {
      border-radius: 50%;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #3f6aff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 44px;
  height: 44px;
  display: block;
}
.TabBox {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  .contentBox1 {
    display: flex;
    width: 240px;
    height: 120px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 20px 0;
    font-size: 16px;
    font-weight: 400;
    border-radius: 14px;
    color: #15104b;
    img {
      width: 40px;
      height: 40px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.logoutbtn {
  border-style: solid;
  border-width: 2px;
}
.btn {
  margin-top: 30px;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 200px;
    height: 42px;
    &:first-child {
      margin-right: 40px;
    }
    &:nth-child(2) {
      margin-left: 8px;
    }
    &:nth-child(2):hover {
      background: #15104b;
      color: #ffffff !important;
    }
  }
}
</style>