import $store from '@/store'

export function updateIntercomInfo() {
  if (window.Intercom) {
    const memberInfo = $store.state.member.memberInfo
    window.Intercom('update', {
      user_id: memberInfo.MemberId,
      name: memberInfo.Fillname, // Full name
      email: memberInfo.Email,
      created_at: new Date().getTime()
    })
  }
}

export function logoutIntercom() {
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
}
