<template>
  <div>
    <div class="main-card-box">
      <el-tabs
        v-model="tabName"
        v-loading="loading"
        :before-leave="beforeSwitch"
        @tab-click="tabClick"
      >
        <el-tab-pane label="All" name="all">
          <card :cardList="productList" :productType="productType" />
        </el-tab-pane>
        <el-tab-pane v-for="(value, key) in productPlatforms" :key="key" :label="key" :name="key">
          <card :cardList="productList" :productType="productType" />
        </el-tab-pane>
      </el-tabs>
      <!-- 会员提示弹窗 -->
      <UpgradeDialog :visible.sync="upgradeVisible" title="Get Pro Plan for more features" />
    </div>
    <div class="main-footer">
      <PagePagination
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { getUDProductData, findProductListHot } from '@/api/proConnect'
import PagePagination from '@/components/PagePagination.vue'
import UpgradeDialog from '@/components/UpgradeDialog.vue'
import card from '@/components/ProductItemList/card.vue'
import { productPlatforms, productTypeMap } from '../constants'
import { mapState } from 'vuex'

export default {
  components: {
    PagePagination,
    card,
    UpgradeDialog
    // ProductItemList
  },

  computed: {
    ...mapState({
      memberInfo: (state) => state.member.memberInfo
    })
  },
  data() {
    return {
      total: 0,
      pageSize: 8,
      currentPage: 1,
      productList: [],
      loading: false,
      tabName: 'all',
      upgradeVisible: false,
      productPlatforms,
      productType: productTypeMap['Find Products']
    }
  },

  mounted() {
    this.getProducts()
  },

  // watch: {
  //   'memberInfo.GradeId': {
  //     handler(val) {
  //       this.getProducts()
  //     },
  //     deep: true
  //   }
  // },

  methods: {
    async getAllProducts() {
      if (!this.memberInfo?.GradeId) {
        return
      }
      this.loading = true
      try {
        const res = await findProductListHot({
          pageNum: this.currentPage,
          size: this.pageSize,
          // 0 find  1 winning 2 top
          hotType: productTypeMap['Find Products'],
          isVip: this.memberInfo?.GradeId,
          vipLevel: this.memberInfo?.GradeId
        })
        if (res.success) {
          this.total = res.data.total
          this.productList = res.data.records
          this.loading = false
        } else {
          this.$message.error(res.ErrMsg || 'error')
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    /**
     * 获取亚马逊，速卖通，Tik Tok平台商品
     */
    async getPlatformProducts(tabName) {
      if (!this.memberInfo?.GradeId) {
        return
      }
      this.loading = true
      const { data } = await getUDProductData({
        pageNum: this.currentPage,
        size: this.pageSize,
        // 0 find  1 winning 2 top
        hotType: productTypeMap['Find Products'],
        productCollection: productPlatforms[tabName]?.searchWord
      })
      this.total = data.total
      this.productList = data.records
      this.loading = false
    },

    getProducts() {
      if (productPlatforms[this.tabName]) {
        this.getPlatformProducts(this.tabName)
      } else {
        this.getAllProducts()
      }
    },

    pageChange(val) {
      this.currentPage = val
      this.getProducts()
    },

    tabClick(tab) {
      this.tabName = tab.name
      this.currentPage = 1
      this.getProducts()
    },

    beforeSwitch(activeName) {
      if (this.memberInfo.GradeId === 1) {
        // 普通用户需要升级才能查看
        this.upgradeVisible = true
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-card-box {
  width: 1310px;
  margin-top: 30px;
}
.main-footer {
  margin-top: 30px;
  width: 1310px;
  height: 70px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
</style>
