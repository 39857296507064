<template>
  <div id="app">
    <div id="main" class="echartsBox"></div>
  </div>
</template>
<script>
var myChart;
export default {
  name: "echart",
  data: () => {
    return {
      daylist: [],
      numlist: [],
      day:[0,0,0,0],
      num:[0,0,0]
    };
  },
  created(){
    this.dispatch = this.$store.dispatch
  },
  props: ["dayType"],
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();
      }
      myChart = window.echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.daylist,
          axisLabel: {
            textStyle: {
              fontFamily: "Regular"
            }
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontFamily: "Regular"
            }
          }
        },
        series: [
          {
            type: "line",
            data: this.numlist,
            smooth: true
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      option && myChart.setOption(option);
    },
    getDayType() {
      //根据所选日期渲染图表
      this.dispatch('home/OrderLineChartHm',  {
          EnumDayDiff: this.dayType
        })
        .then(res => {
            if (res.Result.length == 0) {
              this.daylist.push(this.day)
              this.numlist.push(this.num)
            } else {
              res.Result.forEach(item => {
               this.daylist.push(item.CurrentDate)
               this.numlist.push(item.OrderCountByDay)
              })
            }
          // 数据获取结束，开始渲染表格
          this.drawChart();
          this.daylist = []
          this.numlist = []
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getDayType();
  },
  watch: {
    dayType() {
      //监听日期类型变化重置表格
      this.getDayType();
    }
  },
  beforeDestroy() {
    if (myChart) {
      myChart.dispose();
    }
  }
};
</script>

<style scoped>
.echartsBox {
width: 1000px;
height: 380px;
position: absolute;
left: 0px;
top: 0px;
}
</style>
