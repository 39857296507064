<template>
  <div class="bind_store_box" v-if="LinkYouStoreBox">
    <div class="bind_store_content_box">
      <!-- 关闭按钮 -->
      <div class="close_btn_icon">
        <i class="el-icon-circle-close" @click="CloseMyStore"></i>
      </div>
      <!-- 头部文案 -->
      <div class="header_Copywriting">
        <p>{{ $t("MemberBindBox.LinkStore") }}</p>
      </div>
      <!-- 虚线 -->
      <div class="Dashed_line"></div>
      <!-- 中间内容 -->
      <div class="Cneter_content_box">
        <div class="left_img_box">
          <img src="../assets/imgs/SideBar/computer@2x.png" alt="" />
        </div>
        <div class="center_img_box">
          <img src="../assets/imgs/SideBar/convert206@2x.png" alt="" />
        </div>
        <div class="right_img_box">
          <img src="../assets/imgs/SideBar/UDLogo208@2x.png" alt="" />
        </div>
      </div>
      <!-- 底部按钮和文案 -->
      <div class="bottom_btn_copywriting">
        <!-- 按钮 -->
        <div class="link_my_store_btn" @click="LinkMyStoreBtn">
          <p>
            <img src="../assets/imgs/SideBar/btnconvert@2x.png" alt="" />
          </p>
          <p>{{ $t("MemberBindBox.LinkMyStore") }}</p>
        </div>
        <div class="add_shopify">
          <a href="https://shopify.pxf.io/k07Y73" target="_blank">
            <el-tooltip
              class="item"
              effect="dark"
              content="If you don't have a Shopify store, click this button to create one quickly."
              placement="top-start">
              <p>
                + Add Shopify
              </p>
            </el-tooltip>
          </a>
        </div>
      </div>
      <!-- 文案 -->
      <div class="bottom_Copywriting">
        <p>{{ $t("MemberBindBox.BindStore") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件
  components: {},
  data() {
    return {
      dispatch: null,
      LinkYouStoreBox: false,
    };
  },
  created(){
    this.dispatch = this.$store.dispatch
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.handelMember();
  },
  //方法集合
  methods: {
    /* 关闭弹窗 */
    CloseMyStore() {
      this.LinkYouStoreBox = false;
    },
    /* 跳转 */
    LinkMyStoreBtn() {
      this.$router.push("Store");
    },
    /* 是否绑定店铺和充值 */
    handelMember() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          if (res.Result.More7daysNoStoreOrNoGrade == true) {
            this.LinkYouStoreBox = true;
          } else {
            this.LinkYouStoreBox = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.bind_store_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.bind_store_content_box {
  width: 1000px;
  height: 700px;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.close_btn_icon {
  text-align: right;
  margin-right: 15px;
  margin-top: 25px;
  width: 95%;
  height: 0px;
}
.close_btn_icon >>> .el-icon-circle-close {
  font-size: 26px;
  color: #333333;
  cursor: pointer;
}
.header_Copywriting {
  width: 100%;
  height: 60px;
  margin-top: 30px;
}
.header_Copywriting p {
  font-size: 30px;
  color: #333333;
  text-align: center;
  font-family: "Bold";
  -webkit-font-smoothing: antialiased;
}
.Dashed_line {
  width: 920px;
  height: 2px;
  opacity: 1;
  border: 1px dashed #333333;
  text-align: center;
  margin-left: 40px;
}
.Cneter_content_box {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.left_img_box img {
  width: 210px;
  height: 210px;
  margin-top: 45px;
}
.center_img_box img {
  width: 65px;
  height: 65px;
  margin-left: 80px;
  margin-top: 115px;
}
.right_img_box img {
  width: 240px;
  height: 280px;
  margin-left: 80px;
}
.bottom_btn_copywriting {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  .link_my_store_btn {
    width: 200px;
    height: 50px;
    background: #55d494;
    border-radius: 6px;
    display: flex;
    align-self: center;
    cursor: pointer;
    p:nth-child(1) img {
      width: 25px;
      height: 25px;
      margin-left: 15px;
      margin-top: 13px;
    }
    p:nth-child(2) {
      color: #ffffff;
      font-size: 18px;
      margin-top: 13px;
      margin-left: 10px;
    }
  }
  .add_shopify {
    margin-left: 50px;
    width: 200px;
    height: 50px;
    background: #93df89;
    border-radius: 6px;
    p {
      font-size: 18px;
      color: #ffffff;
      margin-top: 10px;
      text-align: center;
    }
  }
}
.bottom_Copywriting {
  text-align: center;
  margin-top: 15px;
}
.bottom_Copywriting p {
  font-size: 16px;
  color: #64748e;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
</style>
