var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "USAdrop_box" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("Help.submitted")))]),
      ]),
      _c("div", { staticClass: "Confirm_btn" }, [
        _c("button", { on: { click: _vm.ConfirmBtn } }, [_vm._v("Confirm")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_text" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/UpgradeToPro/successful.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }