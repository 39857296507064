import request from '@/utils/request';

export const GetDetail = (data) => request({
  method: "post",
  url: '/api/MemberProduct/GetDetail',
  data
});
export const MemberProductRemove = (data) => request({
    method: "post",
    url: '/api/MemberProduct/Remove',
    data
  });
  export const MemberProductEdit = (data) => request({
    method: "post",
    url: '/api/MemberProduct/Edit',
    data
  });

