<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="help_title">
        {{ $t("Help.Help") }}
      </div>
      <div class="content_box">
        <div class="Help_something">
          <p>{{ $t("Help.something") }}</p>
        </div>
        <div class="Help_ForGeneral">
          <p>{{ $t("Help.ForGeneral") }}</p>
        </div>
        <div class="Help_WithinHours">
          <p>{{ $t("Help.WithinHours") }}</p>
        </div>
        <div class="message_box">
          <div class="message_Name">
            <p>{{ $t("Help.Name") }}</p>
            <p><el-input v-model="CenterMessage.Fillname" placeholder=""></el-input></p>
          </div>
          <div class="message_Email">
            <p>{{ $t("Help.Email") }}</p>
            <p><el-input v-model="CenterMessage.Email" placeholder=""></el-input></p>
          </div>
        </div>
        <div class="InputContent">
          <p>*</p>
          <p>{{ $t("Help.Message") }}</p>
          <p>
            <el-input
              type="textarea"
              :rows="2"
              placeholder=""
              v-model="CenterMessage.Message">
            </el-input>
          </p>
          <!-- <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
            <el-form-item></el-form-item>
          </el-form> -->
        </div>
        <div class="btn">
          <button @click="SendMessage">{{ $t("center.Submit") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dispatch: null,
      textarea: '',
      CenterMessage: [], //当期登录用户的信息
      rawData: '',	// 获取表单时clone的原始数据
      diffData: '',	// 差异
      Message: ''
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData();
    /*this.AgentData(); */
  },
  methods: {
     // 获取数据
    getData() {
      let that = this;
      that.dispatch('center/messageCr')
        .then(res => {
          that.CenterMessage = res.Result;
         // console.log(res.Result.Avatar);
          that.rawData = { ...res.Result }
        //  console.log(that.CenterMessage, 222222222);
        })
        .catch(err => {
          console.log(err);
        });
    },
    diffFormData () {
      for (let k in this.rawData) {
        if (this.rawData[k] !== this.CenterMessage[k]) {
          if (!this.diffData) {
            this.diffData = {}
          }
          this.diffData[k] = this.CenterMessage[k]
        }
      }
    },
    /* 修改数据 */
    helpData() {
      let that = this;
      that.dispatch('help/LhHelpMessageHp', {
          MemberId: that.CenterMessage.MemberId,
          Email: that.CenterMessage.Email,
          Name: that.CenterMessage.Fillname,
          Message: that.CenterMessage.Message
        })
        .then(res => {
          if (res.Success == true) {
            if(!that.CenterMessage.Message || that.CenterMessage.Message.length == 0) {
              return that.$message.error(that.$t('tips.FieldSubmitted'))
            } else {
              that.$router.push('/HelpSuccess')
            }
          }else {
            that.$message.error(res.ErrMsg)
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    SendMessage() {
      this.helpData();
    }
  }
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  margin: auto;
  width: 1320px;
  position: relative;
}
.help_title {
  text-align: center;
  margin-top: 30px;
  font-size: 40px;
  font-weight: 600;
  color: #474882;
  /*color: #fff;*/
}
.content_box {
  width: 100%;
  height: 580px;
  background: #fff;
  margin-top: 35px;
  border-radius: 15px;
}
.Help_something p{
  margin-top: 10px;
  margin-left: 200px;
  padding-top: 30px;
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Help_ForGeneral p{
  font-size: 18px;
  margin-top: 15px;
  margin-left: 200px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Help_WithinHours p{
  font-size: 18px;
  margin-top: 15px;
  margin-left: 200px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.message_box {
  display: flex;
  margin-left: 350px;
  margin-top: 20px;
}
.message_Name p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .message_Name .el-input__inner {
  width: 300px;
  margin-top: 15px;
}
.message_Email {
  margin-left: 55px;
}
.message_Email p:nth-child(1) {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .message_Email .el-input__inner {
  width: 300px;
  margin-top: 15px;
}
.InputContent {
  margin-left: 350px;
  margin-top: 15px;
  width: 660px;
  height: 150px;
  position: relative;
}
.InputContent p:nth-child(1) {
  font-size: 16px;
  color: red;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: -3px;
  left: -12px;
}
.InputContent p:nth-child(2) {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .InputContent .el-textarea__inner {
  width: 660px;
  height: 150px;
  margin-top: 15px;
}
.btn {
  margin-top: 50px;
  margin-right: 130px;
  position: relative;
}
.btn button {
  display: block;
  width: 200px;
  height: 35px;
  background: #474882;
  border-radius: 21px;
  border: 0;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: 50px;
  left: 600px;
}
/* 暗夜 */
body .dark {
  background: #1f1e2e;
}
</style>
