<template>
  <p class="bread" :class="[theme == 'common' ? '' : 'dark']">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="item.path"
        v-for="(item, index) of breadListLast"
        :key="item.index"
        :class="index == breadListLast.length - 1 && breadListLast.length > 1 ? 'last-bread' : ''"
        >{{ item.title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </p>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['banner', 'itemname'],

  data() {
    return {
      //手动配置项：breadListIm为路由与面包屑名称对应关系,breadLoadName为面包屑组件路由
      breadListLast: []
    }
  },
  methods: {
    loadChange() {
      let matched = []
      this.$route.matched.filter((item) => {
        !item.meta.hideBread &&
          matched.push({
            path: item.path,
            name: item.name,
            title: item.meta.title,
            breadNumber: item.meta.breadNumber
          })
      }) //获取路由信息，并过滤保留路由名称信息存入数组
      console.log('routewr', matched)
      var adc = []
      adc = matched
      adc.map((item) => {
        if (!item.title) {
          item.title = item.name
        }
        if (item.title == 'Upgrade') {
          item.path = '/Upgrade'
        }
        if (!item.breadNumber) {
          item.breadNumber = 1
        }
      })
      this.breadListLast = adc
    }
  },
  watch: {
    $route(to, from) {
      this.loadChange()
    }
  },
  //页面挂载之后,解析路由，给出面包屑，路由里面一定要含有breadCom组件的path
  mounted: function () {
    this.loadChange()
  },
  computed: mapState(['userInfo', 'theme'])
}
</script>
<style scoped lang="scss">
.bread {
  line-height: 60px;
  font-size: 12px;
  color: #858585;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.bread .el-breadcrumb {
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  margin-right: 30px;
  height: 100%;
  font-family: 'Regular';
  font-size: 22px;
  .last-bread {
    font-size: 18px;
    ::v-deep .el-breadcrumb__inner {
      font-weight: 500 !important;
    }
  }
  ::v-deep .el-breadcrumb__inner {
    color: #15104b;
    font-weight: 600 !important;
  }
}
</style>
