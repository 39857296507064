import { render, staticRenderFns } from "./RefundRegistration.vue?vue&type=template&id=17fee22f&scoped=true"
import script from "./RefundRegistration.vue?vue&type=script&lang=js"
export * from "./RefundRegistration.vue?vue&type=script&lang=js"
import style0 from "./RefundRegistration.vue?vue&type=style&index=0&id=17fee22f&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fee22f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/ud-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17fee22f')) {
      api.createRecord('17fee22f', component.options)
    } else {
      api.reload('17fee22f', component.options)
    }
    module.hot.accept("./RefundRegistration.vue?vue&type=template&id=17fee22f&scoped=true", function () {
      api.rerender('17fee22f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PaymentOrders/RefundRegistration.vue"
export default component.exports