import {
    ExportOrder,
    GetRightsWithProcessOrder,
    GetOrderListTwo,
    GetOrderPaymentsAmount,
    GetOrderVoByOrderId,
    ExchangeItemStatus,
    GetAddress,
    GetOrderPaymentsAmountBySearch,
    SaveOrderPayments,
    UpdateAddress,
    GetCountryList,
    AddOfflineOrder
  } from '@/api/order'
  export default {
    namespaced: true,
    state: {
      token: '',
    },
    getters: {},
    mutations: {
      setAuthBack(state, status) {
  
      },
      setToken(state, status) {
        state.token = status;
      }
    },
    actions:{
        ExportOrderOd({state, commit}, params) {return ExportOrder(params).then(res => res)},
        GetRightsWithProcessOrderOd({state, commit}, params) {return GetRightsWithProcessOrder(params).then(res => res)},
        GetOrderListTwoOd({state, commit}, params) {return GetOrderListTwo(params).then(res => res)},
        GetOrderPaymentsAmountOd({state, commit}, params) {return GetOrderPaymentsAmount(params).then(res => res)},
        GetOrderVoByOrderIdOd({state, commit}, params) {return GetOrderVoByOrderId(params).then(res => res)},
        ExchangeItemStatusOd({state, commit}, params) {return ExchangeItemStatus(params).then(res => res)},
        GetAddressOd({state, commit}, params) {return GetAddress(params).then(res => res)},
        GetOrderPaymentsAmountBySearchOd({state, commit}, params) {return GetOrderPaymentsAmountBySearch(params).then(res => res)},
        SaveOrderPaymentsOd({state, commit}, params) {return SaveOrderPayments(params).then(res => res)},
        UpdateAddressOd({state, commit}, params) {return UpdateAddress(params).then(res => res)},
        GetCountryListOd({state, commit}, params) {return GetCountryList(params).then(res => res)},
        AddOfflineOrderOd({state, commit}, params) {return AddOfflineOrder(params).then(res => res)}

    }
  
  }