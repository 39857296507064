<template>
  <div class="main">
    <div class="main-member">
      <div
        class="main-member-item"
        v-for="item in membersBlock"
        :key="item.id"
        :style="{ backgroundImage: `url(${item.img})` }"
        @click="timePeriodChange(item.pathName)"
      >
        <!-- :class="$route.name === item.pathName ? 'active' : ''" -->

        <div class="main-member-item-inner">
          <div class="main-member-item-title">
            {{ item.PayTitle }}
          </div>
          <div class="main-member-item-sub-title">{{ item.PayName }}</div>
        </div>
      </div>
    </div>
    <div class="main-search">
      <div class="main-search-content">
        <div class="main-search-input">
          <el-input
            placeholder="Find Products"
            class="s-item search-input2"
            v-model="keyword"
            clearable
            @clear="clearSearch"
            @keyup.enter.native="onSearch"
          >
            <template slot="prefix">
              <i class="el-icon-search el-input__icon"></i>
            </template>
            <template slot="suffix">
              <el-upload
                ref="uploadImageRef"
                :action="actionss"
                accept="image/*"
                :show-file-list="false"
                :http-request="uploadImg"
                v-if="!searchImage"
              >
                <el-button
                  circle
                  size="small"
                  style="background: transparent; border: none"
                  :loading="imgLoading"
                >
                  <img
                    v-if="!imgLoading"
                    class="search-img"
                    src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924727110_icon_upload.svg"
                    alt=""
                  />
                </el-button>
              </el-upload>
              <div style="display: flex; align-items: center" v-else>
                <i
                  class="el-icon-circle-close el-input__icon"
                  @click="clearSearch"
                  style="margin-right: 4px; cursor: pointer"
                ></i>
                <el-image class="search-img" :src="searchImage" />
              </div>
            </template>
          </el-input>
        </div>
        <div class="main-search-btn comBtn" @click="onSearch">
          {{ $t('Winners.Search') }}
        </div>
      </div>
    </div>
    <keep-alive>
      <SearchResult v-if="searched" ref="searchResult" :searchImage="!!searchImage" />
      <router-view v-else></router-view>
    </keep-alive>
  </div>
</template>

<script>
import SearchResult from './components/SearchResult.vue'
import { members } from './constants'
import { uploadFileOss } from '@/api/proConnect'
export default {
  data() {
    return {
      membersBlock: members,
      keyword: '',
      imgLoading: false,
      searchImage: '',
      action: '/goodsapi/oss/uploadFile',
      actionss: '/goodsapi/oss/sign',
      searched: false
    }
  },
  components: {
    SearchResult
  },

  methods: {
    async uploadImg(options) {
      this.imgLoading = true
      const now = new Date()
      const timestamp = now.getTime()
      const filename = `file_${timestamp}.jpg`

      const newFile = new File([options.file], filename, {
        type: 'image/jpeg'
      })

      try {
        const ossSignature = await this.getOSSSignature()
        const formData = new FormData()
        formData.append('name', filename)
        formData.append('OSSAccessKeyId', ossSignature.ossAccessKeyId)
        formData.append('policy', ossSignature.policy)
        formData.append('signature', ossSignature.signature)
        formData.append('success_action_status', '200')
        formData.append('key', ossSignature.dir + filename)
        formData.append('file', newFile)
        const response = await fetch(ossSignature.host, {
          method: 'POST',
          body: formData
        })
        if (response.status === 200) {
          this.imgLoading = false
          const imageId = ossSignature.host + ossSignature.dir + filename
          this.searchImage = imageId // Display the uploaded image
          this.searchImg(imageId)
        } else {
          throw new Error('Upload failed.')
        }
      } catch (error) {
        console.error(error)
        this.$message.error(this.$t('searchdetail.uploadFail'))
        this.imgLoading = false
      }
    },
    async getOSSSignature() {
      try {
        const { data } = await uploadFileOss()
        this.ossSignature = {
          ossAccessKeyId: data.ossAccessKeyId,
          policy: data.policy,
          signature: data.signature,
          dir: data.dir,
          host: 'https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/'
        }
        return this.ossSignature
      } catch (error) {
        console.error('Failed to fetch OSS Access Key ID', error)
      }
    },

    /**
     * 切换商品类型
     * @param id
     */
    timePeriodChange(pathName) {
      this.$router.push({
        name: pathName
      })
    },
    onSearch() {
      if (!this.keyword && !this.searchImage) {
        this.searched = false
        return this.$message.warning(this.$t('please input keyword'))
      }
      if (this.searchImage) {
        this.keyword = ''
        this.searchImg(this.searchImage)
        return
      }
      this.searched = true
      this.$nextTick(() => {
        this.$refs.searchResult.searchProducts(this.keyword)
      })
    },

    searchImg(imageUrl) {
      this.searched = true
      this.$nextTick(() => {
        this.$refs.searchResult.searchByImage(imageUrl)
      })
    },

    clearSearch() {
      this.searched = false
      this.searchImage = ''
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  padding-bottom: 40px;
  position: relative;
  .main-member {
    display: flex;
    justify-content: space-between;
    width: 1310px;
    .main-member-item {
      width: 340px;
      height: 118px;
      border-radius: 16px;
      background: #d9d9d9;
      cursor: pointer;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 130px;
      position: relative;
      &.active {
        box-shadow: 4px 4px 10px 2px rgba(73, 73, 73, 0.521);
        position: relative;
        top: -12px;
      }
      &:hover {
        transform: scale(1.1);
      }
      .main-member-item-inner {
        color: #fff;
        font-family: 'Regular';
        .main-member-item-title {
          font-size: 36px;
        }
        .main-member-item-sub-title {
          font-size: 20px;
        }
      }
    }
  }
  .main-search {
    background-color: #fff;
    width: 1310px;
    height: 70px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 40px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    .main-search-content {
      display: flex;
      width: 100%;
    }
    .main-search-input {
      flex: 1;
    }
    ::v-deep .el-input {
      // width: 100%;
      height: 40px;
      .el-input__inner {
        // width: 1000px;
        height: 40px;
        text-align: center;
        background: #f1f1f2;
        border: none;
        border-radius: 10px;
      }
      .el-input__icon.el-icon-circle-close.el-input__clear {
        position: absolute;
        right: 36px;
        top: 2px;
      }

      .el-input__suffix-inner {
        margin: auto 0;
      }
    }
    .main-search-btn {
      width: 145px;
      height: 40px;
      line-height: 40px;
      margin-left: 24px;
      background: #eaeffb;
      color: #6e8fff;
      border-radius: 10px;
    }
  }
}
:deep(.search-input) {
  width: 100%;
  .el-input__inner {
    border-radius: 32px;
    background-color: #eff1f3 !important;
    border-color: #eff1f3;
  }
}

:deep(.search-input2) {
  width: 100%;
  .el-input__inner {
    border-radius: 32px;
    background-color: #eff1f3 !important;
    border-color: #eff1f3;
    padding-left: 44px !important;
  }

  .search-img {
    width: 24px;
    height: 24px;
  }
}
</style>
