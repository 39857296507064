<template>
  <div class="particulars">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      page: "Stockups",
    };
  },
  components: {
  },
  mounted() {
  },
  methods: {},
};
</script>

<style scoped>
.particulars {
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
</style>
