var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "TabBox" }, [
          _c("div", { staticClass: "contentBox1", on: { click: _vm.MyCard } }, [
            _vm._m(0),
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("center.MyCard")) + " ")]),
          ]),
          _c(
            "div",
            { staticClass: "contentBox1", on: { click: _vm.MyVoucher } },
            [
              _vm._m(1),
              _c("p", [_vm._v(" " + _vm._s(_vm.$t("center.Voucher")) + " ")]),
            ]
          ),
          _c("div", {}, [
            this.fromLink === "vn.usadrop.com"
              ? _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://vn.usadrop.com/blog/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(2),
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("center.Tips")) + " "),
                    ]),
                  ]
                )
              : _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://usadrop.com/blog/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(3),
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("center.Tips")) + " "),
                    ]),
                  ]
                ),
          ]),
          _c("div", {}, [
            this.fromLink === "vn.usadrop.com"
              ? _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://vn.usadrop.com/privacy-policy/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(4),
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.PrivacyPolicy")) + " "
                      ),
                    ]),
                  ]
                )
              : _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://usadrop.com/privacy-policy/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(5),
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.PrivacyPolicy")) + " "
                      ),
                    ]),
                  ]
                ),
          ]),
          _c("div", {}, [
            this.fromLink === "vn.usadrop.com"
              ? _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://vn.usadrop.com/terms-and-conditions/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(6),
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.TermsAndConditions")) + " "
                      ),
                    ]),
                  ]
                )
              : _c(
                  "a",
                  {
                    staticClass: "contentBox1",
                    attrs: {
                      href: "https://usadrop.com/terms-and-conditions/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(7),
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.TermsAndConditions")) + " "
                      ),
                    ]),
                  ]
                ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "CardFunctionBox" },
          [
            _c(
              "el-tabs",
              { attrs: { type: "border-card" } },
              [
                _c("el-tab-pane", [
                  _c(
                    "span",
                    {
                      staticClass: "RegistrationInfoImg",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/imgs/ProfileSub/RegistrationInfo.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.RegistrationInfo")) + " "
                      ),
                    ]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "Important_Notice_box",
                        on: { click: _vm.ClickImportantNotice },
                      },
                      [
                        _c("div", { staticClass: "Important_Notice_main" }, [
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "15px" } },
                            [_vm._v("Important Notice: ")]
                          ),
                          _c(
                            "span",
                            _vm._l(_vm.NoticesList, function (item, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "box" },
                                [
                                  _c("span", { staticClass: "updated" }, [
                                    _vm._v(_vm._s(item.Title)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "Welcome_name" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: _vm.action,
                              "show-file-list": false,
                              "on-success": _vm.handleAvatarSuccess,
                              headers: _vm.headersaa,
                              "before-upload": _vm.beforeAvatarUpload,
                            },
                          },
                          [
                            _vm.imageUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: {
                                    src: _vm.baseApiHost() + _vm.imageUrl,
                                  },
                                })
                              : _c("img", {
                                  staticClass: "avatar",
                                  attrs: {
                                    src: require("../../assets/imgs/Bank-Transfer/headportrait.png"),
                                  },
                                }),
                          ]
                        ),
                        _c("div", { staticClass: "name" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("center.Welcome"))),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "5px" } },
                            [_vm._v(_vm._s(_vm.CenterMessage.Fillname) + "!")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "Diamonds",
                              staticStyle: { "margin-left": "5px" },
                            },
                            [
                              _vm.paymentList.GradeId == 1
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "header_Basic",
                                      attrs: {
                                        src: require("../../assets/imgs/NewLogo/MemberJICHUSlice.png"),
                                        alt: "",
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _vm.paymentList.GradeId == 2
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content: "Pro",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _c("img", {
                                          staticClass: "header_Basic",
                                          attrs: {
                                            src: require("../../assets/imgs/NewLogo/MemberVIPSlice16.png"),
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.paymentList.GradeId == 3
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content: "Plus",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _c("img", {
                                          staticClass: "header_Basic",
                                          attrs: {
                                            src: require("../../assets/imgs/NewLogo/MemberSVIPSlice16.png"),
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "Membership_Exhibition" }, [
                      _c("div", { staticClass: "Membership_One" }, [
                        _vm.paymentList.GradeId == 1
                          ? _c("div", { staticClass: "one_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/ProductJCSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("center.WinningProducts"))
                                ),
                              ]),
                              _c("p", { staticStyle: { color: "#2c87fb" } }, [
                                _vm._v(_vm._s(_vm.$t("center.PreviousDays"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 2
                          ? _c("div", { staticClass: "one_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/ProductVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("center.WinningProducts"))
                                ),
                              ]),
                              _c("p", { staticStyle: { color: "#d14139" } }, [
                                _vm._v(_vm._s(_vm.$t("center.WithinMonth"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 3
                          ? _c("div", { staticClass: "one_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/ProductSVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("center.WinningProducts"))
                                ),
                              ]),
                              _c("p", { staticStyle: { color: "#fcb12c" } }, [
                                _vm._v(_vm._s(_vm.$t("center.WithinWeek"))),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "Membership_Two" }, [
                        _vm.paymentList.GradeId == 1
                          ? _c("div", { staticClass: "two_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/SourcingJCSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.Sourcing"))),
                              ]),
                              _c("p", { staticStyle: { color: "#2c87fb" } }, [
                                _vm._v(_vm._s(_vm.$t("center.Month"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 2
                          ? _c("div", { staticClass: "two_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/SourcingVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.Sourcing"))),
                              ]),
                              _c("p", { staticStyle: { color: "#d14139" } }, [
                                _vm._v(_vm._s(_vm.$t("center.21Week"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 3
                          ? _c("div", { staticClass: "two_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/SourcingSVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.Sourcing"))),
                              ]),
                              _c("p", { staticStyle: { color: "#fcb12c" } }, [
                                _vm._v(_vm._s(_vm.$t("center.63Week"))),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "Membership_Three" }, [
                        _vm.paymentList.GradeId == 1
                          ? _c("div", { staticClass: "three_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/VideoJCSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.VideoShooting"))),
                              ]),
                              _c("p", { staticStyle: { color: "#2c87fb" } }, [
                                _vm._v(_vm._s(_vm.$t("center.10Voucher"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 2
                          ? _c("div", { staticClass: "three_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/VideoVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.VideoShooting"))),
                              ]),
                              _c("p", { staticStyle: { color: "#d14139" } }, [
                                _vm._v(_vm._s(_vm.$t("center.50Voucher"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.paymentList.GradeId == 3
                          ? _c("div", { staticClass: "three_content" }, [
                              _c("p", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/imgs/ProfileSub/VideoSVIPSlice.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.VideoShooting"))),
                              ]),
                              _c("p", { staticStyle: { color: "#fcb12c" } }, [
                                _vm._v(_vm._s(_vm.$t("center.100Voucher"))),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "Membership_Four" }, [
                        _vm.paymentList.GradeId == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "four_content",
                                on: {
                                  click: function ($event) {
                                    return _vm.GradeBthList()
                                  },
                                },
                              },
                              [
                                _c("p", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/imgs/ProfileSub/MoreJCSlice.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("p", { staticStyle: { color: "#2c87fb" } }, [
                                  _vm._v(_vm._s(_vm.$t("center.ViewMore"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.paymentList.GradeId == 2
                          ? _c(
                              "div",
                              {
                                staticClass: "four_content",
                                on: {
                                  click: function ($event) {
                                    return _vm.GradeBthList()
                                  },
                                },
                              },
                              [
                                _c("p", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/imgs/ProfileSub/MoreVIPSlice.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("p", { staticStyle: { color: "#d14139" } }, [
                                  _vm._v(_vm._s(_vm.$t("center.ViewMore"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.paymentList.GradeId == 3
                          ? _c(
                              "div",
                              {
                                staticClass: "four_content",
                                on: {
                                  click: function ($event) {
                                    return _vm.GradeBthList()
                                  },
                                },
                              },
                              [
                                _c("p", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/imgs/ProfileSub/MoreSVIPSlice.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("p", { staticStyle: { color: "#fcb12c" } }, [
                                  _vm._v(_vm._s(_vm.$t("center.ViewMore"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "personal_information_box" }, [
                      _c("div", { staticClass: "personal_msg" }, [
                        _c("div", { staticClass: "edit_personal" }, [
                          _c("p", [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _c("span", [_vm._v(_vm._s(_vm.$t("center.Edit")))]),
                          ]),
                        ]),
                        _c("div", { staticClass: "personal_msg_box" }, [
                          _c("div", { staticClass: "msg_left" }, [
                            _c("div", { staticClass: "email_box" }, [
                              _c("p", [_vm._v(_vm._s(_vm.$t("center.email")))]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true, name: "" },
                                    model: {
                                      value: _vm.CenterMessage.Email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "Email",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.Email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "telephone_box" }, [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.telephone"))),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true, name: "" },
                                    model: {
                                      value: _vm.CenterMessage.Telephone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "Telephone",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.Telephone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "IconInfo",
                                on: {
                                  mouseenter: _vm.enter,
                                  mouseleave: _vm.leave,
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                            _vm.displayUSAdropInfo
                              ? _c("div", { staticClass: "DisplayInfo" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("center.YourPrivacy"))
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("center.Infomation"))),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "facebook_box" }, [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.facebook"))),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: { name: "" },
                                    model: {
                                      value: _vm.CenterMessage.Facebook,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "Facebook",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.Facebook",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "msg_right" }, [
                            _c("div", { staticClass: "skype_box" }, [
                              _c("p", [_vm._v(_vm._s(_vm.$t("center.skype")))]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: { name: "" },
                                    model: {
                                      value: _vm.CenterMessage.Skype,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "Skype",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.Skype",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "whatsapp_box" }, [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.whatsapp"))),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: { name: "" },
                                    model: {
                                      value: _vm.CenterMessage.WhatsApp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "WhatsApp",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.WhatsApp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "wechat_box" }, [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("center.wechat"))),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      name: "",
                                      autocomplete: "new-password",
                                    },
                                    model: {
                                      value: _vm.CenterMessage.WeChat,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CenterMessage,
                                          "WeChat",
                                          $$v
                                        )
                                      },
                                      expression: "CenterMessage.WeChat",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "btn" }, [
                      _vm.paymentList.IsAdmin == true
                        ? _c(
                            "button",
                            {
                              staticClass: "comBtn btn-bg0",
                              on: { click: _vm.confirm },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("center.save")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "comBtn border-col0 col-col0 logoutbtn",
                          on: { click: _vm.logout },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("center.logout")) + " ")]
                      ),
                    ]),
                  ]),
                ]),
                _c("el-tab-pane", [
                  _c(
                    "span",
                    {
                      staticClass: "AccountSecurityImg",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/imgs/ProfileSub/AccountSecurity.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.AccountSecurity")) + " "
                      ),
                    ]
                  ),
                  _c("div", [
                    _c("div", { staticClass: "edit_password_main" }, [
                      _c("div", { staticClass: "Reset_Password_content" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("center.ResetPassword"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "PsdBtn" }, [
                        _c("div", { staticClass: "New_Password_btn" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("center.NewPassword"))),
                          ]),
                          _c(
                            "p",
                            [
                              _c("el-input", {
                                staticClass: "raduisInput",
                                attrs: {
                                  "show-password": "",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "Repeat_New_Password" }, [
                          _c("p", [_vm._v(_vm._s(_vm.$t("center.Repeat")))]),
                          _c(
                            "p",
                            [
                              _c("el-input", {
                                staticClass: "raduisInput",
                                attrs: {
                                  "show-password": "",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.NewPassword,
                                  callback: function ($$v) {
                                    _vm.NewPassword = $$v
                                  },
                                  expression: "NewPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "confirm_btn" }, [
                      _c(
                        "button",
                        { staticClass: "btn-bg0", on: { click: _vm.confirm } },
                        [_vm._v(_vm._s(_vm.$t("center.save")))]
                      ),
                    ]),
                  ]),
                ]),
                _c("el-tab-pane", [
                  _c(
                    "span",
                    {
                      staticClass: "PublishSettingsImg",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/imgs/ProfileSub/PublishSettings.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("center.PublishSettings")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _vm._l(_vm.SettingList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "publishSetting1" },
                          [
                            _c("div", { staticClass: "Price" }, [
                              _vm._v(_vm._s(item.SettingTitle)),
                            ]),
                            _c("div", { staticClass: "cost" }, [
                              _vm._v(" " + _vm._s(_vm.$t("center.cost")) + " "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: item.SettingValue,
                                    expression: "item.SettingValue",
                                    modifiers: { number: true },
                                  },
                                ],
                                attrs: { name: "" },
                                domProps: { value: item.SettingValue },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "SettingValue",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "produce" }, [
                              _vm._v(_vm._s(item.Remarks)),
                            ]),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "payment" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn-bg0",
                            on: { click: _vm.payment },
                          },
                          [_vm._v(_vm._s(_vm.$t("center.save")))]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199MyCard1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Voucher1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Tips1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Tips1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Policy.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Policy.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Terms1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/Slice199Terms1.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }