<template>
  <div>
    <div v-html="formData"></div>
  </div>
</template>

<script>
  export default {
    data: () => {
      return {
        dispatch: null,
        text: 'Loading',
        FormData: {},
        rechargeNo: '',
        formData: '',
      }
    },
    created() {
      this.dispatch = this.$store.dispatch
      var query = this.$route.query
      this.rechargeNo = query.rechargeNo
    },
    mounted() {},
    methods: {
      Gateway(GatewayUrl, GatewayData) {
        let that = this
        that.axios({
            method: 'post',
            url: GatewayUrl,
            data: GatewayData,
          })
          .then((res) => {
            console.log(res)
            if (res.Success) {
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
