<template>
  <div class="form-input-container">
    <div class="design-form-header">
      <span v-if="inputConfig.form?.title">{{ inputConfig.form?.title }}</span>
      <el-tooltip
        v-if="inputConfig.form?.toolTip"
        effect="dark"
        :content="inputConfig.form?.toolTip"
        placement="top"
      >
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-button
        style="margin-left: 5px; color: #3f6aff"
        v-if="inputConfig.form?.showPopupTip"
        type="text"
        size="small"
        @click="showFormPopup = true"
        >{{ inputConfig.form?.popTitle }}</el-button
      >
    </div>
    <el-input
      :value="inputValue"
      placeholder="Enter text"
      clearable
      @input="onInputChange"
    ></el-input>
    <span v-if="inputConfig.form?.helpTxt" class="help-text">{{ inputConfig.form?.helpTxt }}</span>
    <FormPopup
      v-model="showFormPopup"
      :title="inputConfig.form?.popTitle"
      :message="inputConfig.form?.richText"
      :width="inputConfig.form?.popWidth"
    />
  </div>
</template>
<script>
import FormPopup from './FormPopop.vue'

export default {
  name: 'FormItemInput',
  components: {
    FormPopup
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      inputValue: '',
      showFormPopup: false,
      inputConfig: {}
    }
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler(newVal, o) {
        this.inputConfig = newVal
        this.inputValue = this.inputConfig.value || ''
      }
    }
  },
  methods: {
    onInputChange(value) {
      this.inputValue = value
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
.form-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ::v-deep(.el-input__inner) {
    background: transparent;
    padding: 1px 16px;
    border-radius: 20px;
    height: 40px;
  }

  .design-form-header {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .help-text {
    font-size: 12px;
    color: #666;
  }
}
</style>
