<script>
import { GetHistoryDataTwo } from '@/api'
import { SupportTicketDict } from '@/views/SupportTicket/dict'

export default {
  name: 'FeedHistoryRecord',
  computed: {
    SupportTicketDict() {
      return SupportTicketDict
    }
  },
  props: {
    orderId: [String, Number],
    customerOrderId: [String, Number]
  },
  data() {
    return {
      tableData: [],
      replyRecords: []
    }
  },
  watch: {
    orderId: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.requestHistory()
          // this.requestHistoryTwo()
        }
      },
      immediate: true
    }
  },
  methods: {
    requestHistory() {
      GetHistoryDataTwo({
        OrderId: this.orderId,
        CurrentPageIndex: 1,
        PageSize: 100
      }).then((res) => {
        if (res.Success) {
          this.tableData = res.Result || []
        }
      })
    },
    /*  requestHistoryTwo() {
        GetHistoryDataTwo({
          OrderId: this.orderId
        }).then((res) => {
          console.log(res, '128789')
          if (res.Success) {
            this.replyRecords = res.Megs || []
          }
        })
      }, */
    handleViewDetails(row) {
      if (window.location.pathname === '/fileQuestionDetail') {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('customerOrderId', row.CustomerOrderId)
        const newUrl =
          window.location.origin + window.location.pathname + '?' + searchParams.toString()
        window.location.replace(newUrl)
      } else {
        this.$router.replace({
          name: 'FileQuestionDetail',
          query: {
            customerOrderId: row.CustomerOrderId
          }
        })
      }
    },
    handleViewOrderDetails(row) {
      this.$router.push({
        name: 'ViewOrderDetails',
        query: {
          orderId: row.OrderId
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="header">
      <span class="title">
        {{ $t('SupportTicket.HistoricalFeedbackRecord') }}
      </span>
    </div>
    <el-table class="table-class" :data="tableData" style="width: 100%" border>
      <el-table-column prop="orderNo" label="Order[Customer Name]" align="center">
        <template slot="header">
          <div>
            Order
            <br />
            [Customer Name]
          </div>
        </template>
        <template slot-scope="scope">
          <span style="white-space: pre-line; text-align: center">
            {{ scope.row.OrderNo }}
            <br />
            [{{ scope.row.CustomerName }}]
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="questionType" :label="$t('SupportTicket.QuestionType')" align="center">
        <template slot-scope="scope">
          <span style="white-space: pre-line">
            {{
              SupportTicketDict.afterProblemType.find(
                (item) => item.value === Number(scope.row.ProblemTypeCode)
              ).label || 'Other'
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="replyRecords" :label="$t('SupportTicket.ReplyRecords')" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.Megs" :key="index">
            <div v-if="item.IsSmReply == true">
              <div style="margin-top: 8px">{{ item.Message }}</div>
              <div>{{ item.CreateTime }}</div>
              <div class="line"></div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="processRecords"
        :label="$t('SupportTicket.ProcessRecords')"
        align="center"
      >
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.Megs" :key="index">
            <div v-if="item.IsSmReply == false">
              <div style="margin-top: 8px">{{ item.Message }}</div>
              <div>{{ item.CreateTime }}</div>
              <div class="line"></div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Processing" width="140" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            style="color: #3f6aff"
            @click="handleViewDetails(scope.row)"
          >
            {{ $t('SupportTicket.Viewdetails') }}
          </el-button>
          <el-button
            type="text"
            size="small"
            style="color: #3f6aff"
            @click="handleViewOrderDetails(scope.row)"
          >
            {{ $t('SupportTicket.ViewOrderDetails') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 24px;

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #15104b;
  }
}
.table-class {
  margin-top: 16px;

  :deep(.el-table__header) {
    th {
      color: #43445c;
      background-color: #f9f9f9 !important;
    }
  }
}
:deep(.el-table) {
  border-radius: 4px !important;
}
.line {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin-top: 8px;
}
</style>
