import {
  GetCurrentMonthList,
  GetSign,
  CheckSignToday
} from '@/api/signIn'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetCurrentMonthListSg({state, commit}, params) {return GetCurrentMonthList(params).then(res => res)},
    GetSignSg({state, commit}, params) {return GetSign(params).then(res => res)},
    CheckSignTodaySg({state, commit}, params) {return CheckSignToday(params).then(res => res)}
  }

}