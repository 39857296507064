import request from '@/utils/request';

export const TotalCount = (data) => request({
    method: "post",
    url: '/api/BuryingLog/TotalCount',
    data
  });
  
export const BuryingLogAdd = (data) => request({
    method: "post",
    url: '/api/BuryingLog/Add',
    data
  });
