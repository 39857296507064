import request from '@/utils/request';

export const checkGuide = (params) => request({
  method: "get",
  url: '/api/Member/CheckGuide',
  params
});
export const OAuthGuide = (data) => request({
method: "ppost",
  url: '/api/Member/OAuthGuide',
  data
});
export const ExistStore = (data) => request({
  method: "post",
  url: '/api/Store/ExistStore',
  data
});
export const CommitBusinessType = (data) => request({
  method: "post",
  url: '/api/Member/CommitBusinessType',
  data
});

