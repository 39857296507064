import {
  GetMemberVo,
  CheckGuide,
  GetAllGradeLis,
  GetGradeSettings,
  GetGradePriceList,
  CalculateFee,
  GetListByCurrentUser,
  AddAsnc,
  CreateStripeSubscription,
  SubmitOrder,
  CreatePaypalSubscriptionPlan,
  LhMemberBankCardDel,
  DisplayDicountHint,
  GetGradePriceVoPerMonth,
  CreateOrder,
  CreateOrderByAirWallex,
  CreateOrderByAirWallexSubscription,
  GenerateAirWallexClientSecret,
  CalculateFeeSevenDayTrial,
  CreateOrderByStripe,
  GetAllGradeList,
  AddUpgradeClick
} from '@/api/member'

export default {
  namespaced: true,
  state: {
    token: '',
    memberInfo: {}
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    },
    setMemberInfo(state, status) {
      state.memberInfo = status;
    }
  },
  actions: {
    GetMemberVoMe({state, commit}, params) {
      return GetMemberVo(params).then(res => {
          if (res?.Result) {
            commit('setMemberInfo', res?.Result);
            return res
          }
          commit('setMemberInfo', {});
          return res
        }
      );
    },
    CheckGuideMe({state, commit}, params) {
      return CheckGuide(params).then(res => res)
    },
    GetAllGradeListMe({state, commit}, params) {
      return GetAllGradeList(params).then(res => res)
    },
    GetAllGradeLisMe({state, commit}, params) {
      return GetAllGradeLis(params).then(res => res)
    },
    GetGradeSettingsMe({state, commit}, params) {
      return GetGradeSettings(params).then(res => res)
    },
    GetGradePriceListMe({state, commit}, params) {
      return GetGradePriceList(params).then(res => res)
    },
    CalculateFeeMe({state, commit}, params) {
      return CalculateFee(params).then(res => res)
    },
    GetListByCurrentUserMe({state, commit}, params) {
      return GetListByCurrentUser(params).then(res => res)
    },
    AddAsncMe({state, commit}, params) {
      return AddAsnc(params).then(res => res)
    },
    CreateStripeSubscriptionMe({state, commit}, params) {
      return CreateStripeSubscription(params).then(res => res)
    },
    SubmitOrderMe({state, commit}, params) {
      return SubmitOrder(params).then(res => res)
    },
    CreatePaypalSubscriptionPlanMe({state, commit}, params) {
      return CreatePaypalSubscriptionPlan(params).then(res => res)
    },
    LhMemberBankCardDelMe({state, commit}, params) {
      return LhMemberBankCardDel(params).then(res => res)
    },
    DisplayDicountHintMe({state, commit}, params) {
      return DisplayDicountHint(params).then(res => res)
    },
    GetGradePriceVoPerMonthMe({state, commit}, params) {
      return GetGradePriceVoPerMonth(params).then(res => res)
    },
    CreateOrderMe({state, commit}, params) {
      return CreateOrder(params).then(res => res)
    },
    CreateOrderByAirWallexMe({state, commit}, params) {
      return CreateOrderByAirWallex(params).then(res => res)
    },
    CreateOrderByAirWallexSubscriptionMe({state, commit}, params) {
      return CreateOrderByAirWallexSubscription(params).then(res => res)
    },
    GenerateAirWallexClientSecretMe({state, commit}, params) {
      return GenerateAirWallexClientSecret(params).then(res => res)
    },
    CalculateFeeSevenDayTrialMe({state, commit}, params) {
      return CalculateFeeSevenDayTrial(params).then(res => res)
    },
    CreateOrderByStripeMe({state, commit}, params) {
      return CreateOrderByStripe(params).then(res => res)
    },
    AddUpgradeClickMe({state, commit}, params) {
      return AddUpgradeClick(params).then(res => res)
    }
  }

}
