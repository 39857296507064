var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ud-video" }, [
    _c(
      "video",
      _vm._g(
        _vm._b(
          {
            ref: "udvideo",
            on: {
              seeking: _vm.handleSeeking,
              timeupdate: _vm.handleTimeupdate,
              seeked: _vm.handleSeeked,
            },
          },
          "video",
          { ..._vm.$attrs },
          false
        ),
        { ..._vm.$listeners }
      ),
      [_c("source", { attrs: { src: _vm.src, type: _vm.type } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }