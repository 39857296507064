<template>
  <div class="scroll" :class="{ show: isActive }">
    <div class="particulars">
      <div class="main">
        <!-- 搜索框 -->
        <div class="searchbox">
          <div class="search_input">
            <div class="search_icon el-icon-search"></div>
            <input type="text" :placeholder="$t('SupportTicket.FilterOrders')" v-model="salesRecord"/>
          </div>
          <div class="topbox">
            <div class="comBtn btn-bg0" @click="SearchOrderNo">
              {{ $t("SupportTicket.Search") }}
            </div>
            <div class="comBtn btn-bg0" @click="toggleMore">
              {{ $t("SupportTicket.Filter") }}
            </div>
            <!--  <div class="comBtn btn-bg0" @click="OpenNewTicketBtn">
              {{ $t("SupportTicket.Export") }}
            </div> -->
          </div>
        </div>
        <!-- 高级搜索框 -->
        <div>
          <el-dialog :visible.sync="isMore" custom-class="mydialog" :center="true">
            <el-form>
              <el-form-item :label="$t('SupportTicket.StoreName')">
                <el-select v-model="selectStore" clearable class="myselect">
                  <el-option
                    v-for="item in stores"
                    :key="item.index"
                    :label="item.StoreName"
                    :value="item.UserId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('SupportTicket.OrderDate')">
                <el-date-picker
                  class="myselect1"
                  v-model="selectTime"
                  type="datetimerange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('orders.to')"
                  @change="timeChange"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('SupportTicket.Order')">
                <div class="Contact_input">
                  <el-input
                    v-model="salesRecord"
                    :placeholder="$t('SupportTicket.FilterOrders')"
                  ></el-input>
                </div>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <button class="comBtn btn-bg0 min-wid200" @click="moreSearch">
                {{ $t("SupportTicket.Confirm") }}
              </button>
            </div>
          </el-dialog>
        </div>
        <!-- 整体表格 -->
        <div class="list">
          <div class="typebox">
            <div
                class="type"
                v-for="(item, index) in types"
                :key="index"
                :class="orderType == index ? 'select' : ''"
                @click="selectType(index)"
              >
              <span>{{ `${$t(item)}` }}</span>
              <span v-if="resultData && resultData[index]" style="color: red;">
                ({{ resultData[index].Num }})
              </span>
            </div>
          </div>
        </div>
        <ul class="main-list">
          <li class="title">
            <div class="content">
              <div class="title_li">{{ $t("SupportTicket.StoreName") }}</div>
              <div class="title_li">{{ $t("SupportTicket.Order") }}</div>
              <div class="title_li">
                {{ $t("SupportTicket.TypeofQuestion") }}
              </div>
              <div class="title_li">
                {{ $t("SupportTicket.ProblemDescription") }}
              </div>
              <div class="title_li">{{ $t("SupportTicket.ProcessResult") }}</div>
              <div class="title_li">{{ $t("SupportTicket.TrackingNumber") }}</div>
              <div class="title_li">{{ $t("SupportTicket.Processing") }}</div>
            </div>
          </li>
          <template v-if="resultDataList && resultDataList.length > 0">
            <li v-for="(item, index) in resultDataList" :key="index" class="common">
              <div class="contentMessage">
                <div class="content_li">{{ item.ShopName }}</div>
                <div class="content_li">
                  <div>{{ item.SalesRecord }}</div>
                  <div>{{ item.OrderNo }}</div>
                </div>
                <div class="content_li">{{ item.ProblemTypeDesc }}</div>
                <div class="content_li">{{ item.Description }}</div>
                <div class="content_li">{{ item.ProcessResult }}</div>
                <div class="content_li">{{ item.LastestTrackingNumber }}</div>
                <div class="content_li">
                  <div class="ViewdetailsContent" @click="ViewDetailsId(item.OrderId)">
                    <p>{{ $t("SupportTicket.Viewdetails") }}</p>
                  </div>
                  <div class="ViewOrderDetails" @click="ViewOrderDetailsBtn(item.OrderId)">
                    <p>{{ $t("SupportTicket.ViewOrderDetails") }}</p>
                  </div>
                  <div class="ModifyBtn">
                    <button @click="ProcessOrderIdBtn(item.OrderId)">
                      {{ $t("SupportTicket.Process") }}
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </template>
          <template v-else>
            <div class="NoDataImg">
              <p><img src="@/assets/imgs/Nodata.png" alt=""></p>
              <p>No Data</p>
            </div>
          </template>
          <div class="pagebox">
            <el-pagination
              layout="prev, pager, next,slot"
              :page-size="pageSize"
              :current-page="quotedList[0].page"
              :page-sizes="[]"
              @current-change="pageChange"
              :total="quotedList[0].total"
              @size-change="handleSizeChange"
            >
              <span class="pageSizeInput">
                <!-- <el-input class="pageSizeBorder" v-model.number="pageSize" @input="handlePageSize"></el-input> -->
                <template>
                  <el-select v-model="pageSize" @change="selectChanged">
                    <el-option
                      v-for="item in optionsSize"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </span>
            </el-pagination>
            <div @keyup.enter="getPageList(0)" class="page_sign">
              <span>{{ $t("orders.get") }}</span>
              <input type="text" v-model="quotedList[0].jumpPage" />
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div id="toTop" @click="toTop(step)" class="BackTop">
      <p>
        <img src="@/assets/imgs/SideBar/BackTopUp.png" alt="" />
      </p>
    </div>
  </div>
</template>
<script>
import { AfterProblemByUdQuery,GetRaisedByUdProblemTypeCount } from '@/api'
export default {
  data: () => {
    return {
      name: "SubmittedByUd",
      isActive: false,
      isMore: false, //是否展示高级搜索
      stores: "", //高级搜索 店铺
      selectStore: "", //选中店铺
      keywords: "", // 搜索订单号
      salesRecord: "", // 搜索销售编号
      types: ["SupportTicket.All", "SupportTicket.OutOfStock", "SupportTicket.ConfirmProductInformation", "SupportTicket.ConfirmOrderInformation","SupportTicket.ContactTheCourier","SupportTicket.TrackingCodesUpdated","SupportTicket.Other","SupportTicket.NoticeOfShippingMethodChange"],
      orderType: 0, //订单状态选择
      pageSize: 10,
      optionsSize: [
        {
          value: 10,
          label: "10",
        },
        {
          value: 20,
          label: "20",
        },
        {
          value: 50,
          label: "50",
        },
        {
          value: 100,
          label: "100",
        },
      ],
      quotedList: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
      ],
      resultData: [],
      resultDataList: [],
      dialogForm: {
        BeginTime: "",
        EndTime: "",
      },
      selectTime: [],
    };
  },
  props: {
    step: {
      //控制速度
      type: Number,
      default: 40,
    },
  },
  created() {
    this.dispatch = this.$store.dispatch;
  },
  mounted() {
    this.GetStatusCount();
   // this.SubmittedByUdList();
  },
   watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    SearchOrderNo() {
      this.SubmittedByUdList();
    },
    moreSearch() {
      // 多条件搜索
      this.isMore = false;
      this.SubmittedByUdList();
    },
    GetStatusCount() {
      GetRaisedByUdProblemTypeCount({})
      .then((res) => {
        this.resultData = res.Result;
        this.SubmittedByUdList();
      })
    },
    SubmittedByUdList() {
      AfterProblemByUdQuery({
        CurrentPageIndex: this.quotedList[0].page,
        PageSize: this.quotedList[0].pageSize,
        Keywords: this.keywords,
        Filter: {
          ProcessingStatus: this.resultData[this.orderType].ProcessingStatus,
          ProblemTypeCode: null,
          UserId: this.selectStore,
          SalesRecord: this.salesRecord,
          SalesRecord: this.contact,
          BeginTime: this.selectTime ? this.selectTime[0] : "",
          EndTime: this.selectTime ? this.selectTime[1] : "",
        },
      })
      .then((res) => {
        this.resultDataList = res.Result;
        let list = this.quotedList;
        list[0].list = res.Items;
        list[0].total = res.TotalItems;
      })
    },
    timeChange() {
      // 高级搜索时间改变
      // console.log(this.selectTime);
      if (this.selectTime.length) {
        // 取值
        this.dialogForm.BeginTime = this.selectTime[0];
        this.dialogForm.EndTime = this.selectTime[1];
      } else {
        this.dialogForm.BeginTime = "";
        this.dialogForm.EndTime = "";
      }
    },
    ViewDetailsId(orderId) {
      this.$router.push({
        name: 'FileQuestionDetail',
        query: {
          orderId,
        },
      })
    },
    ViewOrderDetailsBtn(orderId) {
      this.$router.push({
        name: 'ViewOrderDetails',
        query: {
          orderId,
        },
      })
    },
    ProcessOrderIdBtn(orderId) {
      this.$router.push({
        name: 'FileQuestionDetail',
        query: {
          orderId,
        },
      })
    },
    OpenNewTicketBtn() {
      this.$router.push({ name: 'OpenNewTicket' });
    },
    getPageList(type) {
      //分页器跳转页数
      this.quotedList[type].page =
      this.quotedList[type].jumpPage > 0 ? Number(this.quotedList[type].jumpPage) : 1;
    },
    pageChange(page) {
      //页数改变
      this.quotedList[0].page = page;
      this.SubmittedByUdList();
    },
    handleSizeChange(pageSize) {
      this.quotedList[0].pageSize = pageSize;
      this.SubmittedByUdList();
    },
    selectChanged(pageSize) {
      this.quotedList[0].pageSize = pageSize;
      this.SubmittedByUdList();
    },
    selectType(type) {
      //改变订单筛选类型
      this.orderType = type;
      this.SubmittedByUdList();
    },
    getStores() {
      // 获取高级搜索 店铺搜索
      this.dispatch("store/GetListSt")
        .then((res) => {
          this.stores = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleMore() {
      this.isMore = !this.isMore;
      this.selectTime = "";
      this.selectStore = "";
      this.salesRecord = "";
      if (!this.stores) {
        this.getStores();
      }
    },
    handleScroll(e) {
      this.isActive = document.documentElement.scrollTop < 200;
    },
    toTop(step) {
      document.getElementById("usaContainer").scrollTop -= step;
      if (document.getElementById("usaContainer").scrollTop > 0) {
        var time = setTimeout(() => this.toTop(step), 15);
      } else {
        clearTimeout(time);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$btn-bg5: #3f6aff;
::v-deep .mydialog {
  border-radius: 10px !important;
  width: 580px;
}
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  .main {
    width: 1400px;
    padding: 0 0 32px;
  }
  .searchbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 75px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding-left: 20px;
    margin-bottom: 20px;
    .search_input {
      display: block;
      width: 450px;
      height: 40px;
      background: #f1f2f4;
      border-radius: 30px;
      margin-right: 20px;
      .search_icon {
        display: inline-block;
        margin-top: 15px;
        margin-right: 24px;
        margin-left: 24px;
      }
      input {
        background: transparent !important;
        width: calc(100% - 70px);
        font-size: 16px;
        color: #585858;
        padding-right: 20px;
        font-family: "Regular";
        -webkit-font-smoothing: antialiased;
      }
    }
    .topbox {
      div {
        min-width: 100px;
        height: 40px;
        margin-right: 20px;
      }
      .Synchronize a {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
.main-list {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  li {
    .content {
      border-bottom: 2px solid #f2f2f2;
      width: 100%;
      height: 82px;
      line-height: 82px;
      font-size: 14px;
      color: #000000;
      text-align: center;
      cursor: pointer;
      font-family: "Regular";
      -webkit-font-smoothing: antialiased;
      display: flex;
      flex-direction: row;
      .title_li {
        font-family: "Regular";
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-font-smoothing: antialiased;
        &:nth-child(1) {
          width: 160px;
        }
        &:nth-child(2) {
          width: 160px;
        }
        &:nth-child(3) {
          width: 240px;
        }
        &:nth-child(4) {
          width: 200px;
        }
        &:nth-child(5) {
          width: 200px;
        }
        &:nth-child(6) {
          width: 220px;
        }
        &:nth-child(7) {
          width: 200px;
        }
      }
    }
    .contentMessage {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      font-family: "Regular";
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      color: #000000;
      height: auto;
      width: 100%;
      /*overflow-x: scroll;*/
      overflow: hidden;
      .content_li {
        font-family: "Regular";
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #f2f2f2;
        padding: 0 10px;
        &:nth-child(1) {
          width: 160px;
        }
        &:nth-child(2) {
          width: 160px;
          flex-direction: column;
        }
        &:nth-child(3) {
          width: 240px;
        }
        &:nth-child(4) {
          width: 200px;
        }
        &:nth-child(5) {
          width: 200px;
        }
        &:nth-child(6) {
          width: 220px;
        }
        &:nth-child(7) {
          width: 220px;
          flex-direction: column;
          .ViewdetailsContent {
            margin-top: 15px;
            p {
              color: #BC0C34;
            }
          }
          .ViewOrderDetails {
            margin-top: 15px;
            p {
              color: #BC0C34;
            }
          }
          .ModifyBtn {
            margin-top: 15px;
            margin-bottom: 10px;
            button {
              width: 100px;
              height: 35px;
              border-radius: 50px;
              background-color: #FFB22B !important;
              color: #ffffff !important;
              border: #FFB22B;
            }
          }
        }
      }
    }
  }
}
::v-deep .pagebox .el-pagination .el-select .el-input .el-input__inner {
  border: 1px solid #ccc;
  height: 35px;
  border-radius: 10px;
  margin-left: 10px;
}
.pagebox {
  width: 100%;
  height: 60px;
  padding-bottom: 4px;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
::v-deep .el-pagination {
  display: flex;
  align-items: center;
}
.page_sign {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Contact_input {
  width: 400px;
  height: 40px;
  border: 1px solid #ccc;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 50px;
  padding: 5px 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 50px;
}

.Contact_input input,
::v-deep .el-input__inner,
::v-deep .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 30px;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.myselect,
::v-deep .myselect.el-date-editor {
  width: 400px;
  height: 40px;
  padding: 0 15px;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
}

::v-deep .myselect.el-date-editor {
  width: 400px;
}
.myselect1,
::v-deep .myselect1.el-date-editor {
  width: 400px;
  height: 40px;
  padding: 0 15px;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 25px;
  border: 1px solid #ccc;
  margin-left: 15px;
}
::v-deep .myselect1.el-date-editor {
  width: 400px;
}
::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}
.BackTop {
  position: fixed;
  bottom: 0px;
  right: 20px;
  cursor: pointer;
}
.BackTop img {
  width: 50px;
  height: 50px;
}
::v-deep .list .el-empty__description p {
  display: none;
}
.list {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.list li {
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
}
.typebox {
  width: 100%;
  padding: 0 32px;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
  position: relative;
}
.type {
  height: 80px;
  line-height: 72px;
  padding: 0 8px;
  font-size: 14px;
  float: left;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.select {
  color: $btn-bg5;
  font-size: 14px;
  line-height: 72px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 17px;
    width: 60%;
    height: 2px;
    background: $btn-bg5;
    left: 20%;
  }
}
.NoDataImg{
  text-align: center;
  padding-top: 50px;
  p:nth-child(2) {
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    margin-top: 10px;
    color: #ccc;
  }
}
</style>
