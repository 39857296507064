var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "member_box" }, [
        _c("div", { staticClass: "member_set" }, [
          _c("div", { staticClass: "member_content" }, [
            _vm._m(0),
            _c("div", { staticClass: "member_down" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("SourcingUrl.YouHave")))]),
              _c("p", [_vm._v(_vm._s(_vm.$t("Sourcing.Upgrade")))]),
            ]),
            _c("div", { staticClass: "bind-store" }, [
              _c("p", [
                _c(
                  "span",
                  { staticStyle: { color: "red", "font-size": "20px" } },
                  [_vm._v("*")]
                ),
                _vm._v(" " + _vm._s(_vm.$t("Sourcing.BindMembership")) + " "),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "member_btn" },
            [
              _c("el-button", { on: { click: _vm.comparePriceBtn } }, [
                _vm._v(_vm._s(_vm.$t("Sourcing.ViewPlan"))),
              ]),
              _c("el-button", { on: { click: _vm.addStoreBtn } }, [
                _vm._v(_vm._s(_vm.$t("Sourcing.AddStore"))),
              ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "member_top" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591322032_MemberHuoJian.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }