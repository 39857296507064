<template>
  <div class="page">
    <div class="content">
      <div class="block"></div>
      <div class="main">
        <LoginContentLeft></LoginContentLeft>
        <div class="LoginRight">
          <div class="SpiderFenceHat"></div>
          <div class="LogoBig">
            <img :src="logoImgOne[formLink]" alt="" />
          </div>
          <div></div>
          <div class="register" v-show="type === 0">
            <div class="account">{{ $t('login.account') }}</div>
            <div class="account1">{{ $t('login.account1') }}</div>
            <div class="typebox">
              <div class="face" @click="otherRegist('Facebook')"></div>
              <div class="google" @click="otherRegist('Google')"></div>
            </div>
            <div class="enroll" @click="checkType(1)">
              <span>{{ $t('login.btn') }}</span>
            </div>
            <p class="login">
              <span>{{ $t('login.sign') }}</span>
              <span class="change" @click="toRegist">{{ $t('login.here') }}</span>
            </p>
          </div>
          <div class="register email" v-show="type === 1">
            <div class="account">{{ $t('login.account') }}</div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-position="top"
              class="demo-ruleForm"
            >
              <el-form-item :label="$t('login.email')" prop="email" class="option">
                <el-input size="large" v-model="ruleForm.email"></el-input>
              </el-form-item>
              <el-form-item :label="$t('login.password')" prop="password" class="option1">
                <el-input
                  size="large"
                  type="password"
                  v-model="ruleForm.password"
                  show-password
                ></el-input>
              </el-form-item>
              <div class="rememberMe_forgetPassword">
                <div class="rememberMe">
                  <p><el-checkbox v-model="rememberMe">Remember Me</el-checkbox></p>
                </div>
                <div class="forgetPassword">
                  <p class="" @click="forgetTap">{{ $t('login.forget') }}</p>
                </div>
              </div>
              <el-form-item>
                <div class="loginbox">
                  <div class="submit btn-bg0" @click="signIn(ruleForm)">
                    {{ $t('login.gobtn') }}
                  </div>
                  <p class="noaccount mt10">
                    {{ $t('login.sign')
                    }}<span class="col-col5" @click="toRegist">{{ $t('login.here') }}</span>
                  </p>
                </div>
              </el-form-item>
            </el-form>
            <div class="others">
              <div class="or col-col5">{{ $t('login.or') }}</div>
            </div>
            <div class="otherbox">
              <div class="other2 btn-bg0" @click="otherRegist('Google')">
                <span>
                  <img
                    src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580585464_google-s.png"
                    alt=""
                  />
                </span>
                <p>{{ $t('login.SignGoogle') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { payList, rules } from '../constants'
import { removeToken, setToken } from '@/utils/token'
import { getFrom, searchUrlParams } from '@/utils/tools'
import LoginContentLeft from '../LoginContentLeft.vue'
import { clearTTInfo, getTTSK, isTTLogin } from '@/utils/tthelper'

export default {
  components: { LoginContentLeft },
  data: () => {
    return {
      name: 'login',
      logoImgOne: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoThree.png'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/SliceLoginMlogoXiao.png'),
        'es.usadrop.com': require('@/assets/imgs/NewLogo/ES90x60.png')
      },
      formLink: getFrom(),
      type: 1,
      paymentList: [], // 会员等级
      ruleForm: {
        email: '',
        password: ''
      },
      rememberMe: false,
      rules,
      payList
    }
  },

  computed: mapState({
    login: (state) => state.login
  }),

  created() {
    const { dispatch } = this.$store // 获取授权时获取的state code
    const search = decodeURIComponent(location.search)
    const { MarketingCode, code, state, scope, CofunderCode } = searchUrlParams(search)
    const MarketingCodeLocal = localStorage.getItem('MarketingCode')
    const CofunderCodeLocal = localStorage.getItem('CofunderCode')
    if (MarketingCode) {
      localStorage.setItem('MarketingCode', MarketingCode)
      MarketingCodeLocal && (window.location.href = 'https://www.usadrop.com')
    } else if (CofunderCode) {
      localStorage.setItem('CofunderCode', CofunderCode)
      CofunderCodeLocal && (window.location.href = 'https://www.usadrop.com')
    } else if (code && state) {
      dispatch('login/getAuthBack', {
        Code: code,
        State: state
      }).then((res) => {
        const { Success, Result, ResData } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
          return
        }
        if (scope && scope.indexOf('google') > 0) {
          this.$message.error(this.$t('login.GoogleRegistration'))
          return
        }
        if (ResData) {
          const { UUId } = ResData
          this.$store.commit('setAuthInfo', ResData)
          UUId && this.oauthRegist(UUId)
        }
      })
    }
  },

  mounted() {
    const savedEmail = localStorage.getItem('email')
    const savedPassword = localStorage.getItem('password')
    if (savedEmail && savedPassword) {
      this.ruleForm.email = savedEmail
      this.ruleForm.password = savedPassword
      this.rememberMe = true
    }
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    handleTikTokBusiness(data) {
      const ttsk = getTTSK()
      if (ttsk !== undefined && ttsk !== '') {
        data.TiktokShopTokenKey = ttsk
      }
    },
    checkType(type) {
      this.type = type
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.signIn() // 需要执行的方法方法
      }
    },
    signIn() {
      removeToken() // 登录前清除过期token
      if (this.rememberMe) {
        localStorage.setItem('email', this.ruleForm.email)
        localStorage.setItem('password', this.ruleForm.password)
      } else {
        // 如果没有选择“记住我”，则清除本地存储中的用户名和密码信息
        localStorage.removeItem('email')
        localStorage.removeItem('password')
      }
      const {
        dispatch,
        state: { storeKey }
      } = this.$store
      const {
        ruleForm: { email, password }
      } = this
      if (!email || !password) return

      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      var data = { email, pwd: password }
      this.handleTikTokBusiness(data)
      storeKey && (data.ShopifyTokenKey = this.$store.state.storeKey)
      console.log('登录参数:', data)
      dispatch('login/getLogin', data).then((res) => {
        const { Success } = res || {}
        if (Success) {
          setToken(res.Result)
          this.$store.commit('setStoreKey', '')
          this.getUserInfo()
          this.menterData()
        } else if (res.ErrCode == 'MEMBER_STATE_ABNORMAL') {
          if(this.formLink === 'es.usadrop.com') {
            this.$message('Debido a su inactividad durante un largo período, su cuenta ha sido temporalmente congelada para garantizar su seguridad. Por favor, contacte con el servicio de atención al cliente para confirmar su uso y recuperar el acceso. Gracias.')
          } else if(this.formLink === 'vn.usadrop.com') {
            this.$message('Tài khoản của bạn đã tạm thời bị khoá do không có hoạt động trong 1 thời gian dài. Vui lòng liên hệ support để được hỗ trợ kích hoạt tài khoản.')
          } else {
            this.$message('Due to inactivity for a long time, your account has been temporarily frozen to ensure its security. Please contact customer service to secure it and regain access, thanks.')
          }

        } else {
          this.$message(res.ErrMsg || vm.$t('tips.failed'))
        }
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },
    getUserInfo() {
      // 获取用户信息
      const { dispatch } = this.$store
      dispatch('login/getUserInfo').then((res) => {
        const { Success } = res || {}
        if (Success) {
          this.$store.commit('setUer', res.Result)
          this.CheckGuide()
        }
      })
    },
    CheckGuide() {
      const { dispatch } = this.$store
      dispatch('login/getCheckGuide')
        .then((res) => {
          const { Success } = res || {}
          if (Success && res?.Result) {
            const {
              Result: { IsProfileComplete, IsStoreComplete }
            } = res
            if (!IsProfileComplete) {
              this.$router.push('StepOne')
              return
            }
            if (!IsStoreComplete) {
              this.$router.push('StepTwo')
              return
            }
            if (IsProfileComplete && IsStoreComplete) {
              if (isTTLogin()) {
                window.location.href = `${window.location.origin}/Store`
              } else {
                window.location.href = `${window.location.origin}/orders`
              }
            }
          }
        })
        .finally(() => {
          clearTTInfo()
        })
    },
    menterData() {
      /* 会员等级 */
      const { dispatch } = this.$store
      dispatch('login/getPaymentList').then((res) => {
        const { Result, Success } = res || {}
        if (Success && Result) {
          this.paymentList = res.Result
        }
      })
    },
    toRegist() {
      // 去注册
      this.$router.push('register')
    },
    forgetTap() {
      // 忘记密码
      this.$router.push('password')
    },
    otherRegist(type) {
      // 其他注册方式
      const { dispatch } = this.$store
      var data = { authPlatform: type }
      this.handleTikTokBusiness(data)
      dispatch('login/getAuthorization', data).then((res) => res && (window.location.href = res))
    },
    oauthRegist(UUId) {
      const { dispatch } = this.$store
      const MarketingCode = window.localStorage.getItem('MarketingCode')
      dispatch('login/getAuthRegister', { UUId, MarketingCode }).then((res = {}) => {
        const { Success, Result } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.mt10 {
  margin-top: 10px;
}

@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex_jus {
  justify-content: space-between;
}
@mixin flex_col {
  flex-direction: column;
}
.LogoBig {
  width: 110px;
  height: 110px;
  position: absolute;
  right: 360px;
  z-index: 99999;
  img {
    width: 100px;
    height: 100px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    display: block;
  }
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
  @include flex;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715579907300_background.png')
    no-repeat;
  background-size: cover;
  overflow: hidden;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.main {
  position: relative;
  display: flex;
  height: 750px;
}
.LoginRight {
  width: 505px;
  height: 100%;
}
.register {
  width: 510px;
  height: 748px;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  padding-top: 75px;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
}
::v-deep .el-icon-view {
  margin-left: -50px;
  margin-top: 2px;
}
.account {
  padding-left: 45px;
  margin-top: 20px;
  font-size: 30px;
  font-family: OPPOSans-Heavy, OPPOSans;
  font-weight: 800;
  color: #000000;
  line-height: 35px;
  -webkit-background-clip: text;
  font-family: 'Bold';
}
.account1 {
  padding-left: 45px;
  font-size: 18px;
  color: #000000;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 120px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.email .account1 {
  margin-bottom: 0;
}
.typebox {
  width: 100%;
  height: 107px;
  padding-left: 92px;
  margin-bottom: 125px;
}
.face {
  width: 107px;
  height: 107px;
  background: url('~@/assets/imgs/face.png') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: left;
  cursor: pointer;
}
.google {
  width: 99px;
  height: 99px;
  background: url('~@/assets/imgs/google.png') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: left;
  margin-left: 140px;
  margin-top: 4px;
  cursor: pointer;
}
.enroll {
  width: 345px;
  height: 60px;
  background: #433759;
  border-radius: 30px;
  margin: 0 auto 105px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.login {
  font-size: 14px;
  color: #b8b8b8;
  line-height: 1.1;
  padding-left: 45px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.change {
  color: #4c8bf5;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.option {
  width: 420px;
  height: 110px;
  margin: 0 auto 10px;
  padding-top: 25px;
  padding-left: 3px;
}
.option1 {
  width: 420px;
  height: 120px;
  margin: 0 auto 10px;
  padding-left: 3px;
  padding-top: 10px;
}
::v-deep .el-form-item__label {
  text-align: left;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .option .el-input__inner {
  border-radius: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100%;
  border: 1px solid #cccccc;
}
::v-deep .option1 .el-input__inner {
  border-radius: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100%;
  border: 1px solid #cccccc;
}
.loginbox {
  width: 100%;
  padding: 0 45px 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.submit {
  width: 225px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  background: #474882;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.submit:hover {
  background: #3c3772 !important;
  color: #fff;
}
.noaccount {
  font-size: 14px;
  line-height: 1.1;
  color: #b8b8b8;
  margin-bottom: 50px;
  padding-left: 0px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
}
.noaccount span {
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.others {
  width: 450px;
  height: 2px;
  background: #e5e5e5;
  position: relative;
  margin: 0 auto 35px;
}
.or {
  width: 100px;
  font-size: 20px;
  line-height: 30px;
  background: #fff;
  position: absolute;
  left: 175px;
  top: -14px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.otherbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-left: 25px;
}
.other2 {
  width: 350px;
  height: 50px;
  border-radius: 50px;
  margin-top: 25px;
  margin-left: 60px;
}
.other2 span:nth-child(1) img {
  width: 35px;
  height: 35px;
  margin-left: 25px;
  margin-top: 8px;
  cursor: pointer;
  display: block;
}
.other2 p {
  position: absolute;
  top: 35px;
  left: 165px;
  font-size: 18px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff;
}
.pay_title span {
  width: 15px;
  height: 16px;
  display: block;
  background: url('~@/assets/imgs/close.png') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 20px;
  cursor: pointer;
}
.rememberMe_forgetPassword {
  display: flex;
}
.rememberMe {
  margin-left: 55px;
}
.forgetPassword {
  color: #3f6aff !important;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 150px;
}
</style>
