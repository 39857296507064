<template>
  <div>
    <div class="main-card-box" v-loading="loading">
      <card :cardList="productList" />
    </div>
    <div class="main-footer" v-if="!searchImage">
      <PagePagination
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { getUDProductData } from '@/api/proConnect'
import PagePagination from '@/components/PagePagination.vue'
import card from '@/components/ProductItemList/card.vue'
import { imageSearch } from '@/api/proConnect'

export default {
  name: 'SearchResult',
  components: {
    PagePagination,
    card
  },

  props: {
    searchImage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      productList: [],
      loading: false,
      searchType: ''
    }
  },

  methods: {
    async searchProducts(keyword) {
      this.loading = true
      try {
        const postData = {
          pageNum: this.currentPage,
          size: this.pageSize,
          keyword
        }

        const res = await getUDProductData(postData)
        this.setList(res)
        this.searchType = 'keyword'
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async searchByImage(imageUrl) {
      this.loading = true
      try {
        const postData = {
          pageNum: this.currentPage,
          size: this.pageSize,
          imageUrl
        }

        const res = await imageSearch(postData)
        this.setList(res)
        this.searchType = 'image'
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    setList(res) {
      if (res.success) {
        this.total = res.data.total
        this.productList = res.data.records || []
        this.loading = false
      } else {
        this.$message.error(res.msg || 'error')
      }
      this.loading = false
    },

    pageChange(val) {
      this.currentPage = val
      if ((this.searchType = 'image')) {
        this.searchByImage()
      } else {
        this.searchProducts()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-card-box {
  width: 1310px;
  margin-top: 30px;
}
.main-footer {
  margin-top: 30px;
  width: 1310px;
  height: 70px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
</style>
