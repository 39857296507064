var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hierarchy" }, [
    _c("div", { staticClass: "Overview" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("dashboard.overviwe")))]),
      _c("p"),
    ]),
    _c("div", { staticClass: "ConTent" }, [
      _c("div", { staticClass: "ConTentOne" }, [
        _c("p", { on: { click: _vm.Balance } }, [
          _vm._v(_vm._s(_vm.$t("dashboard.balance"))),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.GetOverViewList.Balance))]),
      ]),
      _c("div", { staticClass: "ConTentTwo" }, [
        _c("p", { on: { click: _vm.Orders } }, [
          _vm._v(_vm._s(_vm.$t("dashboard.ordersFulfill"))),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.GetOverViewList.QuotedOrders))]),
      ]),
      _c("div", { staticClass: "ConTentThree" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("dashboard.fulfilled")))]),
        _c("p", [_vm._v(_vm._s(_vm.GetOverViewList.FulFilledOrders))]),
      ]),
      _c("div", { staticClass: "ConTentFour" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("dashboard.integrated")))]),
        _c("p", [_vm._v(_vm._s(_vm.GetOverViewList.IntegratedStores))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }