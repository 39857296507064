<template>
  <!-- 会员费 -->
  <div id="paypal-button"></div>
</template>
<script>
import { loadScript } from '@paypal/paypal-js'

export default {
  data: () => {
    return {
      dispatch: null
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  props: ['money', 'planId', 'CycleType', 'OrderId'],
  mounted() {
    let that = this
    that.dispatch = that.$store.dispatch
    const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID
    loadScript({
      'client-id': clientId,
      //"AYArbHqan4hwap0jUgtRrlWianMYkuZ9XLqEVurGlE1HGhmWJJfA0uAzJbrGReb4nfIXHSpOVfNvm_rZ",//live 生产
      // 'ASk6RLjNMKmPI-QWqvQNhTG7VRaWdFbgFOMzAWEkEmEwkft_plMauyL5MjRVQ5UmhJfGuWJs-MYhMA8z', //sandbox  测试
      'disable-funding':
        'bancontact,blik,boleto,card,credit,eps,giropay,ideal,itau,maxima,mercadopago,mybank,oxxo,p24,paylater,payu,sepa,sofort,trustly,venmo,wechatpay,zimpler',
      vault: 'true',
      intent: 'subscription'
    })
      .then((paypal) => {
        paypal
          .Buttons({
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'horizontal',
              label: 'subscribe'
            },
            createSubscription: function (data, actions) {
              return that
                .dispatch('member/CreateOrderMe', {
                  PriceId: Number(that.money),
                  PayType: Number(1)
                })
                .then((_data) => {
                  return actions.subscription.create({
                    plan_id: _data.Result.PlanId,
                    custom_id: _data.Result.OrderId + '-' + _data.Result.CycleType
                  })
                })
                .catch((err) => {
                  console.log(err)
                })
              //调用接口(获取计划id) /api/MemberShip/CreatePaypalSubscriptionPlan【STEP1】
            },
            onApprove: function (data, actions) {
              if (data) {
                window.open('/PaypalSuccess')
                //that.$message.success("paypal payment approval");
                //that.suborder(data.subscriptionID);
              } else {
                that.$message.error('paypal payment failure')
              }
              //调用接口(提交订单) /api/MemberShip/SubmitOrder【STEP2】
            }
          })
          .render('#paypal-button')
      })
      .catch((err) => {
        console.error('failed to load the PayPal JS SDK script', err)
      })
  },
  methods: {
    suborder(subscriptionId) {
      let that = this
      that
        .dispatch('member/SubmitOrderMe', {
          PriceId: Number(that.money),
          PayType: Number(1),
          SubscriptionId: subscriptionId
        })
        .then((res) => {
          if (res.Success) {
            /* setTimeout(() => {
              that.$message.success("paypal payment approval");
            }, 5000); */
            // that.$message.success("paypal payment approval");
            this.$router.push('Airwallexsuccess')
            // window.open('Airwallexsuccess')
            setTimeout(() => {
              that.goHome()
            }, 700)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    goHome() {
      window.location.href = `${window.location.origin}/dashboard`
    }
  }
}
</script>
<style scoped>
#paypal-button {
  text-align: center;
  padding-top: 80px;
}
</style>
