var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "block" }),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("LoginContentLeft"),
          _c("div", { staticClass: "LoginRight" }, [
            _c("div", { staticClass: "SpiderFenceHat" }),
            _c("div", { staticClass: "LogoBig" }, [
              _c("img", {
                attrs: { src: _vm.logoImgOne[_vm.formLink], alt: "" },
              }),
            ]),
            _c("div"),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === 0,
                    expression: "type === 0",
                  },
                ],
                staticClass: "register",
              },
              [
                _c("div", { staticClass: "account" }, [
                  _vm._v(_vm._s(_vm.$t("login.account"))),
                ]),
                _c("div", { staticClass: "account1" }, [
                  _vm._v(_vm._s(_vm.$t("login.account1"))),
                ]),
                _c("div", { staticClass: "typebox" }, [
                  _c("div", {
                    staticClass: "face",
                    on: {
                      click: function ($event) {
                        return _vm.otherRegist("Facebook")
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "google",
                    on: {
                      click: function ($event) {
                        return _vm.otherRegist("Google")
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "enroll",
                    on: {
                      click: function ($event) {
                        return _vm.checkType(1)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("login.btn")))])]
                ),
                _c("p", { staticClass: "login" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("login.sign")))]),
                  _c(
                    "span",
                    { staticClass: "change", on: { click: _vm.toRegist } },
                    [_vm._v(_vm._s(_vm.$t("login.here")))]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === 1,
                    expression: "type === 1",
                  },
                ],
                staticClass: "register email",
              },
              [
                _c("div", { staticClass: "account" }, [
                  _vm._v(_vm._s(_vm.$t("login.account"))),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "option",
                        attrs: { label: _vm.$t("login.email"), prop: "email" },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ruleForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "email", $$v)
                            },
                            expression: "ruleForm.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "option1",
                        attrs: {
                          label: _vm.$t("login.password"),
                          prop: "password",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "password", "show-password": "" },
                          model: {
                            value: _vm.ruleForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "password", $$v)
                            },
                            expression: "ruleForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "rememberMe_forgetPassword" }, [
                      _c("div", { staticClass: "rememberMe" }, [
                        _c(
                          "p",
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.rememberMe,
                                  callback: function ($$v) {
                                    _vm.rememberMe = $$v
                                  },
                                  expression: "rememberMe",
                                },
                              },
                              [_vm._v("Remember Me")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "forgetPassword" }, [
                        _c("p", { on: { click: _vm.forgetTap } }, [
                          _vm._v(_vm._s(_vm.$t("login.forget"))),
                        ]),
                      ]),
                    ]),
                    _c("el-form-item", [
                      _c("div", { staticClass: "loginbox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "submit btn-bg0",
                            on: {
                              click: function ($event) {
                                return _vm.signIn(_vm.ruleForm)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("login.gobtn")))]
                        ),
                        _c("p", { staticClass: "noaccount mt10" }, [
                          _vm._v(" " + _vm._s(_vm.$t("login.sign"))),
                          _c(
                            "span",
                            {
                              staticClass: "col-col5",
                              on: { click: _vm.toRegist },
                            },
                            [_vm._v(_vm._s(_vm.$t("login.here")))]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "others" }, [
                  _c("div", { staticClass: "or col-col5" }, [
                    _vm._v(_vm._s(_vm.$t("login.or"))),
                  ]),
                ]),
                _c("div", { staticClass: "otherbox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "other2 btn-bg0",
                      on: {
                        click: function ($event) {
                          return _vm.otherRegist("Google")
                        },
                      },
                    },
                    [
                      _vm._m(0),
                      _c("p", [_vm._v(_vm._s(_vm.$t("login.SignGoogle")))]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("img", {
        attrs: { src: require("@/assets/imgs/google-s.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }