import request from '@/utils/request'

export const LhSourcingQuoteList = (data) =>
  request({
    method: 'post',
    url: '/api/LhSourcingQuote/GetList',
    data
  })

export const NewLhSourcingList = (data) =>
  request({
    method: 'post',
    url: `/api/LhSourcing/List`,
    data
  })
