var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("SupportTicket.ProductsDetail"))),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "list-view" },
      [
        _c("div", { staticClass: "list-item" }, [
          _c("span", { staticClass: "left-product" }, [
            _vm._v(_vm._s(_vm.$t("SupportTicket.Item"))),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.$t("SupportTicket.Quantity")))]),
        ]),
        _vm._l(_vm.ErrorSkuList, function (item, index) {
          return _c(
            "div",
            { key: item.OrderDetailId, staticClass: "list-item" },
            [
              _c(
                "div",
                { staticClass: "left-product" },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { disabled: "" },
                    model: {
                      value: item.ProductRealSku,
                      callback: function ($$v) {
                        _vm.$set(item, "ProductRealSku", $$v)
                      },
                      expression: "item.ProductRealSku",
                    },
                  }),
                ],
                1
              ),
              _c("el-input", {
                staticClass: "quantity-input",
                attrs: { type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.handleInput($event, item)
                  },
                },
                model: {
                  value: item.Num,
                  callback: function ($$v) {
                    _vm.$set(item, "Num", $$v)
                  },
                  expression: "item.Num",
                },
              }),
              _vm.ErrorSkuList.length > 1
                ? _c(
                    "el-button",
                    {
                      staticStyle: { color: "red" },
                      attrs: { size: "small", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.onHandleRemove(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Remove")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }