var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "block" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == 0,
              expression: "type == 0",
            },
          ],
          staticClass: "reset",
        },
        [
          _c("div", { staticClass: "resetpasswords" }, [
            _c("div", { staticClass: "registration" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("password.retrieve")))]),
            ]),
            _c("div", { staticClass: "restContent" }, [
              _c("div", { staticClass: "enter-password mt40" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input,
                      expression: "input",
                    },
                  ],
                  attrs: { placeholder: _vm.$t("password.verification") },
                  domProps: { value: _vm.input },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.input = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "resetPad" }, [
                _c(
                  "button",
                  {
                    staticClass: "comBtn btn-bg6 col-col0",
                    on: { click: _vm.BackBtn },
                  },
                  [_vm._v(_vm._s(_vm.$t("password.back")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "comBtn btn-bg0",
                    on: {
                      click: function ($event) {
                        return _vm.checkType(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("password.next")))]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == 1,
              expression: "type == 1",
            },
          ],
          staticClass: "reset",
        },
        [
          _c("div", { staticClass: "resetpasswords" }, [
            _c("div", { staticClass: "registration" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("password.user")))]),
            ]),
            _c("div", { staticClass: "restContent" }, [
              _c("div", { staticClass: "enter-password mt40" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oldPassword,
                      expression: "oldPassword",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("password.enter"),
                    "show-password": "",
                  },
                  domProps: { value: _vm.oldPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.oldPassword = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "enter-password mt40" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.NewPassword,
                      expression: "NewPassword ",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("password.confirm"),
                    "show-password": "",
                  },
                  domProps: { value: _vm.NewPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.NewPassword = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "resetPad" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "comBtn btn-bg0 mr10",
                      on: { click: _vm.forgetTap },
                    },
                    [_vm._v(_vm._s(_vm.$t("password.modification")))]
                  ),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: {
                        type: "primate",
                        underline: false,
                        href: "/login",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("password.BackLogin")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }