<template>
  <div class="scroll" :class="{ show: isActive }">
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="record_page" :class="[theme == 'common' ? '' : 'dark']">
        <div class="Filter">
          <el-input
            :placeholder="$t('wallet.refunds')"
            v-model="OrderNumber"
            class="input-with-select">
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search" @click="SearchOrderNumber">{{ $t('recharge.search') }}</button>
        </div>
        <div>
          <el-dialog :visible.sync="isMore" custom-class="mydialog" :center="true"> <!-- 高级搜索弹框 -->
            <el-form>
              <div class="dialog_store_box">
                <div class="dialog_store">
                  <p>{{ $t('PaymentOrder.StoreName') }}</p>
                </div>
                <div class="dialog_store_name">
                  <el-form-item>
                    <el-select
                      v-model="selectStore"
                      clearable
                      :placeholder="$t('')"
                      class="mySelectStore">
                      <el-option
                        v-for="item in stores"
                        :key="item.index"
                        :label="item.StoreName"
                        :value="item.UserId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="dialog_store_box"> <!-- 订单号 -->
                <div class="dialog_store">
                  <p>{{ $t('recharge.Type') }}</p>
                </div>
                <div class="dialog_contact_number">
                  <el-select
                    v-model="selectType"
                    clearable
                    class="mySelectStore">
                    <el-option
                      v-for="item in optionsType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 退款时间 -->
              <div class="dialog_store_box">
                <div class="dialog_store">
                  <p>{{ $t('PaymentOrder.Date') }}</p>
                </div>
                <div class="dialog_contact_time">
                  <el-form-item>
                    <div class="Contact_Time">
                      <el-date-picker
                        v-model="selectTime"
                        class="myselect"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        :range-separator="$t('orders.to')"
                        @change="timeChange"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <button @click="moreSearch" class="comBtn btn-bg0 min-wid200">
                {{ $t('orders.Confirm') }}
              </button>
            </div>
          </el-dialog>
        </div>
        <div class="records">
          <el-tabs v-model="activeName"> <!-- 充值记录 -->
            <el-tab-pane :label="$t('recharge.label')" name="recharge" class="recharge">
              <template v-if="rechargeList && rechargeList.length > 0">
                <el-table :data="rechargeList" style="width: 100%">
                  <el-table-column
                    prop="PayTypeName"
                    align="center"
                    :label="$t('recharge.table_tr1')">
                  </el-table-column>
                  <el-table-column prop="PayTime" align="center" :label="$t('recharge.table_tr2')">
                  </el-table-column>
                  <el-table-column
                    prop="RechargeAmount"
                    align="center"
                    :label="$t('recharge.table_tr9')">
                    <template slot-scope="scope">
                      <span>${{ scope.row.RechargeAmount ? scope.row.RechargeAmount : 0 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="FeeAmount"
                    align="center"
                    :label="$t('recharge.table_tr8')">
                    <template slot-scope="scope">
                      <span>${{ scope.row.FeeAmount ? scope.row.FeeAmount : 0 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="TotalAmount"
                    align="center"
                    :label="$t('recharge.table_tr3')">
                    <template slot-scope="scope">
                      <span>${{ scope.row.TotalAmount ? scope.row.TotalAmount : 0 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="PayStatus"
                    align="center"
                    :label="$t('recharge.table_tr4')">
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.PayStatus == 1"
                        style="
                          color: #6788ff;
                          font-family: Regular;
                          -webkit-font-smoothing: antialiased;
                        "
                        >{{ $t('recharge.success') }}</span>
                      <span
                        v-if="scope.row.PayStatus != 1"
                        style="
                          color: #8d8d8d;
                          font-family: Regular;
                          -webkit-font-smoothing: antialiased;"
                        >{{ $t('recharge.unsuccess') }}</span>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="options[0].total"
                    :page-size="pageSize"
                    :current-page="options[0].page"
                    :page-sizes="[]"
                    @size-change="handleSizeChange"
                    @current-change="pageChange">
                    <span class="pageSizeInput">
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="options[0].jumpPage" />
                  </div>
                </div>
              </template>
              <el-empty description="No data" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane :label="$t('recharge.label2')" name="exoenditure"> <!-- 消费记录 -->
              <template v-if="consumeList && consumeList.length > 0">
                <el-table :data="consumeList" style="width: 100%">
                  <el-table-column prop="StoreName" align="center" :label="$t('recharge.Store')">
                  </el-table-column>
                  <el-table-column
                    prop="SalesRecord"
                    align="center"
                    :label="$t('recharge.table_tr5')">
                  </el-table-column>
                  <el-table-column
                    prop="CreateTime"
                    align="center"
                    :label="$t('recharge.table_tr6')">
                  </el-table-column>
                  <el-table-column
                    prop="ChangeAmount"
                    align="center"
                    :label="$t('recharge.table_tr7')">
                    <template slot-scope="scope">
                      <p>
                        <span v-if="scope.row.ChangeAmount < 0">
                          -${{ scope.row.ChangeAmount ? scope.row.ChangeAmount * -1 : 0 }}</span>
                        <span v-else>
                          ${{ scope.row.ChangeAmount ? scope.row.ChangeAmount : 0 }}</span>
                      </p>
                      <p style="color: red" v-if="scope.row.RelCreditCoin != 0">
                        <span v-if="scope.row.RelCreditCoin < 0">
                          <span>(Ucredit:</span>
                          <span
                            >-U{{
                              scope.row.RelCreditCoin ? scope.row.RelCreditCoin * -1 : 0
                            }})</span>
                        </span>
                        <span v-else>
                          <span>(Ucredit:</span>
                          <span>U{{ scope.row.RelCreditCoin ? scope.row.RelCreditCoin : 0 }})</span>
                        </span>
                      </p>
                      <p style="color: red" v-if="scope.row.PaidContainCredits != 0">
                        <span>Credit:</span>
                        <span>{{ scope.row.PaidContainCredits }}</span>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="ChangeTypeName"
                    align="center"
                    :label="$t('recharge.Type')">
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="PayMentOptions[0].total"
                    :page-size="pageSize"
                    :page-sizes="[]"
                    :current-page="PayMentOptions[0].page"
                    @size-change="handleSizeChange"
                    @current-change="pageChange">
                    <span class="pageSizeInput">
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="PayMentOptions[0].jumpPage" />
                  </div>
                </div>
              </template>
              <el-empty description="No data" v-else></el-empty>
            </el-tab-pane>
            <!-- 授信 -->
            <el-tab-pane
              :label="$t('recharge.Credit')"
              name="credit"
              v-if="CenterMessage.HasCreditRecord == true">
              <template v-if="creditList && creditList.length > 0">
                <el-table :data="creditList" style="width: 100%">
                  <el-table-column
                    prop="CreditDate"
                    align="center"
                    :label="$t('recharge.Date')"
                    :formatter="formatterTime">
                  </el-table-column>
                  <el-table-column prop="CreditLimit" align="center" :label="$t('recharge.Amount')">
                    <template slot-scope="scope">
                      <span>
                        <span>${{ parseFloat(scope.row.CreditLimit).toFixed(2) }}</span>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="CreditOptions[0].total"
                    :page-size="pageSize"
                    :page-sizes="[]"
                    :current-page="CreditOptions[0].page"
                    @size-change="handleSizeChange"
                    @current-change="pageChange">
                    <span class="pageSizeInput">
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="CreditOptions[0].jumpPage" />
                  </div>
                </div>
              </template>
              <el-empty description="No data" v-else></el-empty>
            </el-tab-pane>
          </el-tabs>
          <template v-if="activeName === 'exoenditure'">
            <el-button
              class="PaymentOrderExport comBtn border-col5 col-col5"
              @click="ExportExcelBtn"
              >{{ $t('PaymentOrder.Export') }}
            </el-button>
            <el-button class="PaymentOrderFilter" @click="searchFilter"
              >{{ $t('DLZ.Filter') }}
            </el-button>
          </template>
        </div>
      </div>
      <div id="toTop" @click="toTop(step)" class="BackTop">
        <p>
          <img src="../../../assets/imgs/SideBar/BackTopUp.png" alt="" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { baseApiHost } from '../../../utils/env'
export default {
  data: () => {
    return {
      dispatch: null,
      currentPage: 1,
      banner: '',
      itemname: '',
      page: 'recharge',
      activeName: 'recharge', //标签当前选中
      rechargeList: [], // 我的充值列表
      consumeList: [], //消费记录
      creditList: [], // 授信列表
      pageSize: 10,
      CenterMessage: {},
      isActive: false,
      isMore: false,
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      PayMentOptions: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      CreditOptions: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      InputOrder: '', // 订单号
      OrderNumber: '', // 查询
      showBtn: false,
      optionsSize: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ],
      optionsType: [
        {
          value: 0,
          label: 'Recharge'
        },
        {
          value: 1,
          label: 'OrderPay'
        },
        {
          value: 2,
          label: 'Other'
        },
        {
          value: 3,
          label: 'VideoOrderPay'
        },
        {
          value: 4,
          label: 'OrderCancel'
        },
        {
          value: 5,
          label: 'Refund'
        },
        {
          value: 6,
          label: 'Withdraw'
        },
        {
          value: 7,
          label: 'PayBack'
        },
        {
          value: 8,
          label: 'DividedPay'
        },
        {
          value: 9,
          label: 'DisputeAndChargeBack'
        },
        {
          value: 10,
          label: 'AfterSale'
        },
        {
          value: 11,
          label: 'Payin1'
        },
        {
          value: 12,
          label: 'Payin2'
        },
        {
          value: 13,
          label: 'SubscriptionFee'
        }
      ],
      selectTime: [],
      dialogForm: {
        BeginTime: '',
        EndTime: ''
      },
      stores: '', //高级搜索 店铺
      selectStore: '', //选中店铺
      selectType: ''
    }
  },
  components: {},
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.banner = this.$t('recharge.bannername')
    this.itemname = this.$t('recharge.itemname')
    this.getRechageList()
    this.payment()
    this.CreditLimit()
    this.getData()
    if (this.$route.params.type && this.$route.params.type == 'exoenditure') {
      this.activeName = this.$route.params.type
    } else if (this.$route.params.type && this.$route.params.type == 'credit') {
      this.activeName = this.$route.params.type
    } else {
      this.activeName = 'recharge'
    }
    if (this.$route.params.type && this.$route.params.type == 'exoenditure') {
      this.showBtn = true
    } else {
      this.showBtn = false
    }
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = []
        }
      },
      deep: true
    }
  },
  methods: {
    formatterTime(row, column) {  // 截取时间 只展示年月日
      let data = row[column.property]
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(data)
    },
    handleScroll(e) {
      this.isActive = document.documentElement.scrollTop < 200
    },
    getStores() {
      this.dispatch('store/GetListSt')
        .then((res) => {
          this.stores = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toTop(step) {
      document.getElementById('usaContainer').scrollTop -= step
      if (document.getElementById('usaContainer').scrollTop > 0) {
        var time = setTimeout(() => this.toTop(step), 15)
      } else {
        clearTimeout(time)
      }
    },
    SearchOrderNumber() {
      this.payment()
      this.pageChange()
      this.CreditLimit()
    },
    pageChange(page) {
      this.options[0].page = page
      this.PayMentOptions[0].page = page
      this.CreditOptions[0].page = page
      this.getRechageList()
      this.payment()
      this.CreditLimit()
    },
    handleSizeChange(pageSize) {
      this.PayMentOptions[0].pageSize = pageSize
      this.CreditOptions[0].pageSize = pageSize
      this.options[0].pageSize = pageSize
      this.getRechageList()
      this.payment()
      this.CreditLimit()
    },
    getPageList() {
      this.options[0].page = this.options[0].jumpPage > 0 ? Number(this.options[0].jumpPage) : 1
      this.PayMentOptions[0].page =
      this.PayMentOptions[0].jumpPage > 0 ? Number(this.PayMentOptions[0].jumpPage) : 1
      this.CreditOptions[0].page =
      this.CreditOptions[0].jumpPage > 0 ? Number(this.CreditOptions[0].jumpPage) : 1
      this.getRechageList()
      this.payment()
      this.CreditLimit()
    },
    handlePageSize() {
      if (!this.quotedList[0].pageSize) {
        this.quotedList[0].pageSize = 0
      }
    },
    selectChanged(pageSize) {
      this.PayMentOptions[0].pageSize = pageSize
      this.CreditOptions[0].pageSize = pageSize
      this.options[0].pageSize = pageSize
      this.getRechageList()
      this.payment()
      this.CreditLimit()
    },
    getRechageList() {  // 获取充值记录
      let that = this
      that
        .dispatch('recharge/GetMyRechargeListRc', {
          Keyword: that.OrderNumber,
          CurrentPageIndex: that.options[0].page,
          PageSize: that.options[0].pageSize,
          RecordCount: that.options[0].list.length
        })
        .then((res) => {
          if (res.Success) {
            that.rechargeList = res.Items
            that.options[0].total = res.TotalItems
          } else {
            that.$message(res.ErrMsg || that.$t('tips.failed'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    payment() {  // 获取我的消费记录列表
      let that = this
      that
        .dispatch('recharge/GetMyBalanceLogListRc', {
          SalesRecord: that.OrderNumber,
          CurrentPageIndex: that.PayMentOptions[0].page,
          PageSize: that.PayMentOptions[0].pageSize,
          RecordCount: that.PayMentOptions[0].list.length,
          BeginTime: this.selectTime ? this.selectTime[0] : '',
          EndTime: this.selectTime ? this.selectTime[1] : '',
          UserId: this.selectStore,
          ChangeType: this.selectType
        })
        .then((res) => {
          console.log(res.Items, '88888')
          that.consumeList = res.Items
          that.PayMentOptions[0].total = res.TotalItems
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ExportExcelBtn() { // 导出
      this.dispatch('recharge/ExportMyBalanceLogListRc', {
        SalesRecord: this.OrderNumber,
        CurrentPageIndex: this.PayMentOptions[0].page,
        PageSize: this.PayMentOptions[0].pageSize,
        RecordCount: this.PayMentOptions[0].list.length,
        BeginTime: this.selectTime ? this.selectTime[0] : '',
        EndTime: this.selectTime ? this.selectTime[1] : '',
        UserId: this.selectStore,
        ChangeType: this.selectType
      })
        .then((res) => {
          if (res.Success) {
            window.open(baseApiHost() + res.Result)
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CreditLimit() { // 授信列表
      let that = this
      that
        .dispatch('recharge/GetMyCreditLimitRegItemRc', {
          CurrentPageIndex: that.CreditOptions[0].page,
          PageSize: that.CreditOptions[0].pageSize,
          RecordCount: that.CreditOptions[0].list.length,
          Filter: {}
        })
        .then((res) => {
          console.log(res.Items, '88888999')
          that.creditList = res.Items
          that.CreditOptions[0].total = res.TotalItems
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getData() {
      let that = this
      this.dispatch('center/messageCr')
        .then((res) => {
          that.CenterMessage = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchFilter() {
      // 切换高级搜索
      this.isMore = !this.isMore
      this.selectTime = ''
      this.selectStore = ''
      if (!this.stores) {
        this.getStores()
      }
    },
    moreSearch() {
      // 多条件搜索
      this.isMore = false
      this.payment()
    },
    timeChange() {
      if (this.selectTime.length) {
        // 取值
        this.dialogForm.BeginTime = this.selectTime[0]
        this.dialogForm.EndTime = this.selectTime[1]
      } else {
        this.dialogForm.BeginTime = ''
        this.dialogForm.EndTime = ''
      }
    }
  }
}
</script>

<style scoped>
.pageSizeInput >>> .el-input__inner {
  width: 100px !important;
  height: 35px;
  margin-top: -30px;
  border-radius: 20px;
}
.record_page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.records {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 32px;
  margin-top: 30px;
  position: relative;
}
::v-deep .records .el-tabs__content {
  background-color: #fff;
  font-family: 'Regular';
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recharge {
  background-color: #ffffff !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
  color: #000000;
  font-family: 'Regular';
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
}
::v-deep .el-tabs__header {
  margin: 0;
  background-color: #fff;
  font-family: 'Regular';
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: 'Regular';
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: 'Regular';
}
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 3px;
}
::v-deep .el-tabs .el-tabs--top .el-tabs__item:hover {
  background: #6788ff;
  font-family: 'Regular';
}
::v-deep .records .recharge .el-table th,
::v-deep .el-table tr {
  height: 70px;
  font-family: 'Regular';
}
::v-deep .records .recharge .el-table__row {
  height: 80px !important;
}
::v-deep .records .el-tabs__content {
  border-radius: 0 0 10px !important;
}
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
  font-family: 'Regular';
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: 'Regular';
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
}
.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
}
.Filter {
  width: 1320px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
}
::v-deep .Filter .input-with-select {
  width: 1000px;
  height: 30px;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 30px;
}
::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 40px;
}
::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 40px;
  font-family: 'Regular';
}
::v-deep .Filter [class*=' el-icon-'],
[class^='el-icon-'] {
  color: #000;
  font-family: 'Regular';
}
.Filter .search {
  display: inline-block;
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  border: 1px solid #f0f3fb;
}
.Filter .search:hover {
  background: #3c3772;
}
.BackTop {
  position: fixed;
  bottom: 0px;
  right: 20px;
  cursor: pointer;
}
.BackTop img {
  width: 50px;
  height: 50px;
}
.PaymentOrderExport {
  position: absolute;
  top: 20px;
  right: 300px;
  border-radius: 50px;
  width: 180px;
  font-size: 16px;
  border: 2px solid #3f6aff;
}
.PaymentOrderExport:hover {
  background: #3f6aff !important;
  color: #fff !important;
  border: #3f6aff;
}
.PaymentOrderFilter {
  position: absolute;
  top: 20px;
  right: 50px;
  border-radius: 50px;
  width: 180px;
  font-size: 16px;
  background: #15104b;
  color: #fff;
}
.PaymentOrderFilter:hover {
  background: #3c3772;
  color: #fff;
}
::v-deep .mydialog {
  border-radius: 20px !important;
  width: 800px;
}
.dialog_store_box {
  display: flex;
}
.dialog_store {
  margin-top: 40px;
  margin-left: 25px;
}
.dialog_store p {
  font-size: 16px;
}
.dialog_contact_time {
  margin-top: 30px;
  margin-left: 98px;
}
.dialog_store_name {
  margin-top: 30px;
  margin-left: 45px;
}
.dialog_contact_number {
  margin-top: 30px;
  margin-left: 100px;
}
.Contact_input >>> .el-input__inner {
  width: 550px;
  border-radius: 10px;
}
.mySelectStore >>> .el-input__inner {
  width: 550px;
  border-radius: 10px;
}
::v-deep .el-range-editor.el-input__inner {
  width: 550px;
  border-radius: 10px;
}
</style>
