var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formRef",
      attrs: {
        "label-position": "top",
        model: _vm.formModel,
        disabled: _vm.readonly,
      },
    },
    [
      _c(
        "el-form-item",
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.formModel.CausByFunctionalIssues,
                callback: function ($$v) {
                  _vm.$set(_vm.formModel, "CausByFunctionalIssues", $$v)
                },
                expression: "formModel.CausByFunctionalIssues",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("SupportTicket.CauseByFunctionalIssues")) +
                    " "
                ),
              ]),
              _c("el-radio", { attrs: { label: false } }, [
                _vm._v(" " + _vm._s(_vm.$t("SupportTicket.OtherIssues")) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("SupportTicket.VideoLink") } },
        [
          _c("el-input", {
            model: {
              value: _vm.formModel.VideoLink,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "VideoLink", $$v)
              },
              expression: "formModel.VideoLink",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("SupportTicket.ImageUrl") } },
        [
          _c("FeedImageUpload", {
            attrs: {
              "img-upload-path": _vm.imgUploadPath,
              "show-example": true,
              "default-file-list": _vm.defaultFileList,
              readonly: _vm.readonly,
            },
            model: {
              value: _vm.formModel.fileList,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "fileList", $$v)
              },
              expression: "formModel.fileList",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("FeedNeedReSendProducts", {
            ref: "needReSendRef",
            attrs: {
              "order-details": _vm.notExcludeOrderDetails,
              "error-sku-list": _vm.afterSaleDetail?.ErrorSkuList,
              readonly: _vm.readonly,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }