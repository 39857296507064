var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-left" }, [
    _c("div", { staticClass: "titleTopImg" }, [
      _c("div", { staticClass: "LoginImgTitle" }, [
        _c("img", { attrs: { src: _vm.logoImg[_vm.formLink], alt: "" } }),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "ContentWordBox" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("login.YourDropshipping")))]),
      _c("p", [
        _vm._v(" " + _vm._s(_vm.$t("login.withOver")) + " "),
        _c("span", [_vm._v(_vm._s(_vm.$t("login.tenYears")))]),
        _vm._v(" " + _vm._s(_vm.$t("login.ofExperience")) + " "),
      ]),
    ]),
    _c("div", { staticClass: "DropshippingPlatform" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("login.DROPSHIPPING")))]),
    ]),
    _c("div", { staticClass: "DropshippingWorldwide" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("login.KNOWLEDGE")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("login.MARKET")))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "GroupAppraise" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580248413_Group6.png",
          alt: "icon",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }