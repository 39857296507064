var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasLastedNews,
          expression: "hasLastedNews",
        },
      ],
      staticClass: "paybox",
      on: { click: _vm.hideDetail },
    },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "bg" }),
        _c("div", { staticClass: "payContent" }, [
          _c("div", { staticClass: "close", on: { click: _vm.hideDetail } }, [
            _c("img", {
              attrs: { src: require("@/assets/imgs/close.svg"), alt: "" },
            }),
          ]),
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.News?.Title))]),
            _c("div", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(_vm.News?.PublishTime)),
            ]),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.News?.NewsContent) },
            }),
          ]),
        ]),
        _c("div", { staticClass: "copyright" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("lastedNews.OnTime")))]),
          _c("p", { staticStyle: { "margin-top": "8px" } }, [
            _vm._v(_vm._s(_vm.$t("lastedNews.DropshippingFulfillment"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }