var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hello" },
    [
      _c("lazy-slide-captcha", {
        ref: "captcha",
        attrs: {
          width: _vm.width,
          height: _vm.height,
          "show-refresh": true,
          "fail-tip": _vm.failTip,
          "success-tip": _vm.successTip,
        },
        on: { finish: _vm.handleFinish, refresh: _vm.generate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }