import {
  MemberProductList,
  MemberProductRemove
} from '@/api/myProduct'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    MemberProductListMp({state, commit}, params) {return MemberProductList(params).then(res => res)},
    MemberProductRemoveMp({state, commit}, params) {return MemberProductRemove(params).then(res => res)},
  }

}