var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-input-container" },
    [
      _c(
        "div",
        { staticClass: "design-form-header" },
        [
          _vm.inputConfig.form?.title
            ? _c("span", [_vm._v(_vm._s(_vm.inputConfig.form?.title))])
            : _vm._e(),
          _vm.inputConfig.form?.toolTip
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.inputConfig.form?.toolTip,
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-question" })]
              )
            : _vm._e(),
          _vm.inputConfig.form?.showPopupTip
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "5px", color: "#3f6aff" },
                  attrs: { type: "text", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFormPopup = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.inputConfig.form?.popTitle))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-input", {
        attrs: {
          value: _vm.inputValue,
          placeholder: "Enter text",
          clearable: "",
        },
        on: { input: _vm.onInputChange },
      }),
      _vm.inputConfig.form?.helpTxt
        ? _c("span", { staticClass: "help-text" }, [
            _vm._v(_vm._s(_vm.inputConfig.form?.helpTxt)),
          ])
        : _vm._e(),
      _c("FormPopup", {
        attrs: {
          title: _vm.inputConfig.form?.popTitle,
          message: _vm.inputConfig.form?.richText,
          width: _vm.inputConfig.form?.popWidth,
        },
        model: {
          value: _vm.showFormPopup,
          callback: function ($$v) {
            _vm.showFormPopup = $$v
          },
          expression: "showFormPopup",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }