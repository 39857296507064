/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';

export const MemberProductList = (data) => request({
  method: "post",
  url: '/api/MemberProduct/GetList',
  data
});
export const MemberProductRemove = (data) => request({
    method: "post",
    url: '/api/MemberProduct/Remove',
    data
  });
