<template>
  <div class="showDialog" @click.prevent="_showDialog = false" v-show="_showDialog">
    <div class="dialogMain" @click.stop="">
      <div class="closeIcon">
        <img
          @click="_showDialog = false"
          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png"
          alt=""
        />
      </div>
      <div class="main">
        <span class="label">Store:</span>
        <el-select class="select searchSelectItem" placeholder="Please select" v-model="storeId">
          <el-option
            v-for="item in storeList"
            :value="item.UserId"
            :key="item.UserId"
            :label="item.StoreName"
          ></el-option>
        </el-select>
      </div>
      <div v-if="isConnect" class="main" style="margin-top: 20px">
        <span class="label">Product Name:</span>
        <el-select
          filterable
          class="select searchSelectItem"
          placeholder="Please select"
          v-model="productId"
        >
          <el-option
            v-for="item in productList"
            :value="item.ShopProductId"
            :key="item.ShopProductId"
            :label="item.ShopProductName"
          ></el-option>
        </el-select>
      </div>
      <div class="confirm comBtn btn-bg0" @click="confirm">
        {{ isConnect ? 'Update' : 'Confirm' }}
      </div>
      <span class="add" @click="toAdd">Add Shopify Store </span>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
export default {
  name: 'StoreDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    isConnect: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      storeId: null,
      productId: null,
      productList: [],
      storeList: []
    }
  },
  watch: {
    storeId(n, o) {
      this.getProductByStoreId()
    }
  },
  computed: {
    _showDialog: {
      get() {
        return this.showDialog
      },
      set(val) {
        this.$emit('update:showDialog', val)
        if (!val) {
          this.storeId = null
        }
      }
    }
  },
  created() {
    request({
      method: 'get',
      url: '/api/Store/GetList',
      params: {
        storeType: 'Shopify'
      }
    }).then((res) => {
      if (res.Success) {
        this.storeList = res.Result.filter((item) => item.Status === 1) || []
      }
    })
  },
  methods: {
    getProductByStoreId(Keyword) {
      request({
        method: 'post',
        url: '/api/ProductConnect/GetShopProductPending',
        data: {
          CurrentPageIndex: 1,
          Keyword,
          PageSize: 20,
          UserId: this.storeId
        }
      }).then((res) => {
        if (res.Success) {
          this.productList = res.Items || []
        }
      })
    },
    toAdd() {
      this.$router.push('Store')
    },
    confirm() {
      /*  if (!this.storeId) {
        this.$message.error('Please select a store')
      } else {
        if (this.isConnect && !this.productId) {
          this.$message.error('Please select a product')
        } else {
          this.$emit('confirmProductId', { storeId: this.storeId, productId: this.productId });
        }
      } */
      if (!this.storeId) {
        this.$message.error('Please select a store')
        return
      }
      if (this.isConnect && !this.productId) {
        this.$message.error('Please select a product')
        return
      }
      const eventData = { storeId: this.storeId, productId: this.productId }
      if (this.isConnect) {
        this.$emit('confirmProductId', eventData)
      } else {
        this.$emit('confirmStoreId', eventData)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.showDialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  .dialogMain {
    background: #fff;
    width: 650px;
    min-height: 200px;
    border-radius: 20px;
    padding: 20px 30px;
    z-index: 11;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 10%;
    .closeIcon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .main {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      margin-right: 15px;
      .label {
        width: 140px;
        text-align: right;
      }
      .select {
        margin-left: 10px;
        width: 320px;
        height: 46px;
        border: 1px solid #c0c4cc;
        border-radius: 30px;
        ::v-deep .el-input__inner {
          width: 100%;
          border-radius: 30px;
        }
        ::v-deep .el-range-separator {
          width: 10%;
        }
      }
    }
    .confirm {
      margin-top: 50px;
      width: 160px;
    }
  }
  .add {
    margin-top: 6px;
    display: inline-block;
    cursor: pointer;
    color: #04bd00;
  }
}
</style>
