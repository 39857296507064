var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pod-configure" },
    [
      _c(
        "div",
        { staticClass: "design-title" },
        [
          _c("span", [
            _vm._v(
              "Custom product options for helping your customer to design"
            ),
          ]),
          _c("el-switch", {
            attrs: { "inactive-text": "PREVIEW" },
            model: {
              value: _vm.canPreview,
              callback: function ($$v) {
                _vm.canPreview = $$v
              },
              expression: "canPreview",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "configure-main" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("Virtural options")]),
            _vm._l(_vm.configureData, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "left-item",
                  class: _vm.choosedId === item.id ? "active" : "",
                  attrs: { draggable: true },
                  on: {
                    click: function ($event) {
                      return _vm.chooseLayer(index)
                    },
                    dragstart: function ($event) {
                      return _vm.handleDragStart(index)
                    },
                    dragover: function ($event) {
                      $event.preventDefault()
                    },
                    drop: function ($event) {
                      return _vm.handleDrop(index, $event)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "move",
                    attrs: {
                      src: require("@/assets/imgs/detail/move.png"),
                      alt: "",
                    },
                  }),
                  item.type === "image"
                    ? _c("img", {
                        staticClass: "type-pic",
                        attrs: { src: item.value, alt: "" },
                      })
                    : _c("img", {
                        staticClass: "type-pic",
                        attrs: {
                          src: require("@/assets/imgs/detail/pod_ic_T.png"),
                          alt: "",
                        },
                      }),
                  _c("div", { staticClass: "left-title" }, [
                    _vm._v(_vm._s(item.form.title)),
                  ]),
                  _c("el-switch", {
                    staticClass: "switch",
                    model: {
                      value: item.status,
                      callback: function ($$v) {
                        _vm.$set(item, "status", $$v)
                      },
                      expression: "item.status",
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  {
                    staticClass: "collapse",
                    attrs: {
                      name: "1",
                      title: "Options personalization settings",
                    },
                  },
                  [
                    _vm.currentPersonal
                      ? _c("div", { staticClass: "personal-main" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentLayParm?.type === "image",
                                  expression:
                                    "currentLayParm?.type === 'image'",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "setting_title" }, [
                                _vm._v("Select positions for personalization"),
                              ]),
                              _c("div", { staticClass: "sub_title marTB16" }, [
                                _vm._v(
                                  " 'Positions' tells in which place the print file is used to desian. You need to select positions to enable the print file to be personalized by your customers in these positions. "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentLayParm?.type === "i-text",
                                  expression:
                                    "currentLayParm?.type === 'i-text'",
                                },
                              ],
                              staticClass: "setting_title",
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            [_vm._v(" Text content: text ")]
                          ),
                          _c("div", { staticClass: "positions" }, [
                            _c("div", [_vm._v("Positions(Canvas/Layer)")]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.currentPersonal?.positionName)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentLayParm?.type === "image",
                                  expression:
                                    "currentLayParm?.type === 'image'",
                                },
                              ],
                              staticClass: "how",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "setting_title marTB16" },
                                [_vm._v("How to personalize?")]
                              ),
                              _c(
                                "div",
                                { staticClass: "imgchecks" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "checkbox",
                                      model: {
                                        value: _vm.currentPersonal.canUpload,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentPersonal,
                                            "canUpload",
                                            $$v
                                          )
                                        },
                                        expression: "currentPersonal.canUpload",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Upload image(Allow to crop image)"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "checkbox",
                                      model: {
                                        value: _vm.currentPersonal.showPrintImg,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentPersonal,
                                            "showPrintImg",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentPersonal.showPrintImg",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Select from an print file group（Allow end-users to select when uploading images）"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.currentPersonal.showPrintImg,
                                      expression:
                                        "currentPersonal.showPrintImg",
                                    },
                                  ],
                                  staticClass: "printimgs",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "print-hover" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value:
                                              _vm.currentPersonal
                                                .showPrintImgName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.currentPersonal,
                                                "showPrintImgName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentPersonal.showPrintImgName",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Show print file name on hover"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            round: "",
                                          },
                                          on: { click: _vm.openPrintImgs },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "shown-print-imgs" },
                                    _vm._l(
                                      _vm.currentPersonal.printFileImgList,
                                      function (img, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: img.id,
                                            staticClass: "print-files",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "img-btns" },
                                              [
                                                _c("img", {
                                                  staticClass: "print-img-url",
                                                  attrs: {
                                                    src: img.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c("img", {
                                                  staticClass: "del showDel",
                                                  attrs: {
                                                    src: require("@/assets/imgs/detail/del.png"),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delImg(
                                                        img,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "img-name" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "image-name-text",
                                                  },
                                                  [_vm._v(_vm._s(img.name))]
                                                ),
                                                _c("img", {
                                                  staticClass: "icon-edit",
                                                  attrs: {
                                                    src: require("@/assets/imgs/detail/edit.png"),
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editImageName(
                                                        img,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "advance" },
                            [
                              _c(
                                "div",
                                { staticClass: "setting_title marTB16" },
                                [_vm._v("Advanced options")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { checked: "", disabled: "" } },
                                [
                                  _vm._v(
                                    "Show custom ization data on cart/checkout"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "el-collapse-item",
                  {
                    staticClass: "collapse",
                    attrs: { name: "2", title: "Option basic info settings" },
                  },
                  [
                    _vm.currentForm
                      ? _c(
                          "div",
                          { staticClass: "personal-main configure-form" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "formRef",
                                attrs: {
                                  model: _vm.currentForm,
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Option title",
                                      prop: "title",
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please input your name!",
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "formInput",
                                      model: {
                                        value: _vm.currentForm.title,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "title",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Tooltip" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "formInput",
                                      model: {
                                        value: _vm.currentForm.toolTip,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "toolTip",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.toolTip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Help text" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "formInput",
                                      model: {
                                        value: _vm.currentForm.helpTxt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "helpTxt",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.helpTxt",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Popup tip" } },
                                  [
                                    _c("el-checkbox", {
                                      model: {
                                        value: _vm.currentForm.showPopupTip,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "showPopupTip",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.showPopupTip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Title" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "formInput",
                                      model: {
                                        value: _vm.currentForm.popTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "popTitle",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.popTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Width" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "formInput",
                                      model: {
                                        value: _vm.currentForm.popWidth,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentForm,
                                            "popWidth",
                                            $$v
                                          )
                                        },
                                        expression: "currentForm.popWidth",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("el-form-item"),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "fuwenben" },
                              [
                                _c("wangEnduit", {
                                  attrs: {
                                    isClear: false,
                                    boxId: `box${_vm.currentLayParm.id}`,
                                  },
                                  model: {
                                    value: _vm.currentForm.richText,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currentForm, "richText", $$v)
                                    },
                                    expression: "currentForm.richText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "modalDialog",
          attrs: {
            title: "Change Print file Name",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "formInput",
            attrs: { placeholder: "Please input image name" },
            model: {
              value: _vm.editImageItem.name,
              callback: function ($$v) {
                _vm.$set(_vm.editImageItem, "name", $$v)
              },
              expression: "editImageItem.name",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.confirmEditImgName },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PrintGroupDialog", {
        attrs: { showDialogVisible: _vm.dialogImgVisible },
        on: {
          "update:showDialogVisible": function ($event) {
            _vm.dialogImgVisible = $event
          },
          "update:show-dialog-visible": function ($event) {
            _vm.dialogImgVisible = $event
          },
          addSelectedImgs: _vm.addSelectedImgs,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }