<template>
  <div class="particulars">
    <!-- 详情页面 -->
    <div class="Bgrd">
      <p>
        <img src="../../assets/imgs/guide/only.png" alt="">
      </p>
    </div>
    <div class="activeName">
      <div class="ShopyLogo">
        <img src="../../assets/imgs/guide/shopylogo.png" alt="">
      </div>
      <div class="content">
        <div class="Confirm">
            <button @click="forgetTap">{{ $t("wallet.Confirm") }}</button>
        </div>
        <p>{{$t("guide.platform")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "guideSteps",
    data: () => {
      return {

      };
    },
    components: {

    },
    created() {

    },
    methods: {
      forgetTap() {
        this.$router.push("/Upgrade");
      },
    }
  };
</script>

<style scoped>
.particulars {
  width: 100%;
  position: relative;
  background: #FFFFFF;
  min-height: 100vh;
}
.Bgrd {
  width: 100%;
  height: 130px;
  position: relative;
  background-image: url('../../assets/imgs/guide/firstStep.png');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}
.Bgrd img{
position: absolute;
top: 36px;
left: 750px;
}

.activeName {
  margin: auto;
  padding-bottom: 32px;
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
}
.ShopyLogo {
  width: 350px;
  height: 350px;
  margin: 0 auto;
  margin-top: 110px;
  line-height: 350px;
}
.content {
  width: 650px;
  height: 250px;
  margin: 0 auto;
  margin-top: 5px;
}
.Confirm button {
  width: 264px;
  height: 46px;
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  margin-top: 30px;
  margin-left: 150px;
  margin-bottom: 20px;
  color: #ffffff;
}
.content p {
  margin-top: 35px;
  font-size: 22px;
  font-weight: bold;
}
</style>
