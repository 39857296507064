var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName_big_box" }, [
        _c("div", { staticClass: "activeName" }, [
          _c("div", { staticClass: "Video_Left_Box" }, [
            _c("div", { staticClass: "video_left" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "VideoYoutuBe" }, [
                  _c("iframe", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      src: _vm.VideoPath,
                      title: "YouTube video player",
                      frameborder: "0",
                      allow:
                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                      allowfullscreen: "",
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "Intro" }, [
                _c("p", [_vm._v(_vm._s(_vm.Title))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("video.ByUSAdrop")))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "play_video" },
              _vm._l(_vm.options, function (item) {
                return _c(
                  "div",
                  { key: item.index, staticClass: "video_right_box" },
                  [
                    _c("div", { staticClass: "box_right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "video_play",
                          on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
                        },
                        [
                          _c("img", {
                            attrs: { src: item.VideoCover, alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.VideoBtn(
                                  item.VideoPath,
                                  item.Title,
                                  item.PubTime
                                )
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.IsShowVideo,
                                  expression: "IsShowVideo",
                                },
                              ],
                              staticClass: "VideoBtn",
                              on: {
                                click: function ($event) {
                                  return _vm.VideoBtn(
                                    item.VideoPath,
                                    item.Title,
                                    item.PubTime
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-caret-right" })]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "synopsis" }, [
                      _c("p", { staticStyle: { width: "100px" } }, [
                        _vm._v(" " + _vm._s(item.Title) + " "),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "Video_Right_Box" }, [
            _c("div", { staticClass: "video_right" }),
            _c("div", { staticClass: "VideoMemberBtn" }, [
              _c("p", [
                _c(
                  "button",
                  {
                    staticClass: "orders btn-bg0",
                    on: { click: _vm.VoucherBtn },
                  },
                  [_vm._v(_vm._s(_vm.$t("video.package")))]
                ),
              ]),
              _c("p", [
                _c(
                  "button",
                  {
                    staticClass: "orders2 btn-bg0",
                    on: { click: _vm.MoreVideoBtn },
                  },
                  [_vm._v(_vm._s(_vm.$t("video.More")))]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "VideoSizeBtn comBtn",
                on: { click: _vm.ShowVideoSizeBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("video.VideoSize")))])]
            ),
          ]),
          _vm.ShowVideoSize
            ? _c("div", { staticClass: "paybox" }, [
                _c("div", { staticClass: "VideoSizeContentBox" }, [
                  _c(
                    "p",
                    {
                      staticClass: "VideoSizeIconRemove",
                      on: { click: _vm.CloseVideoSize },
                    },
                    [_c("i", { staticClass: "el-icon-remove" })]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/imgs/LoginBg/VideoSize.png"),
                      alt: "",
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }