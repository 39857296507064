var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offcanvas", class: [_vm.notice ? "" : "offcanvas-right"] },
    [
      _c(
        "el-tabs",
        {
          class: [_vm.theme == "common" ? "" : "dark"],
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("notification.all"), name: "all" } },
            [
              _c(
                "div",
                { staticClass: "all" },
                [
                  _vm._l(_vm.NoticesList, function (item, index) {
                    return _c("div", { key: index, staticClass: "box" }, [
                      item.IsReaded == true
                        ? _c("div", { staticClass: "dot" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "updated", on: { click: _vm.TileBtn } },
                        [_vm._v(_vm._s(item.Title))]
                      ),
                      _c(
                        "div",
                        { staticClass: "time", on: { click: _vm.TileBtn } },
                        [_vm._v(_vm._s(item.PublishTime))]
                      ),
                    ])
                  }),
                  _c("div", { staticClass: "notifications" }, [
                    _c("a", { attrs: { href: "../ImportantNotices" } }, [
                      _vm._v(_vm._s(_vm.$t("notification.check"))),
                    ]),
                  ]),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }