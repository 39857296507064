var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _vm.showContent
        ? _c("div", { staticClass: "activeName_big_box" }, [
            _c("div", { staticClass: "activeName" }, [
              _c("div", { staticClass: "HelpCenterTitle" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("HelpCenter.HelpCenter")))]),
              ]),
              _c("div", { staticClass: "help_content" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("HelpCenter.mayInterested")))]),
              ]),
              _c("div", { staticClass: "HelpBigContentBox" }, [
                _c("div", { staticClass: "LeftContentBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "BasicFunctionsBox",
                      on: { click: _vm.BasicFunctionBtn },
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "BasicFunctionsTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[0].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "StoreConnection",
                      on: { click: _vm.StoreConnectionBtn },
                    },
                    [
                      _vm._m(1),
                      _c("div", { staticClass: "StoreConnectionTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[2].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "Message", on: { click: _vm.MessageBtn } },
                    [
                      _vm._m(2),
                      _c("div", { staticClass: "MessageTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[4].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "RightContentBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "PricingPlan",
                      on: { click: _vm.PricingPlanBtn },
                    },
                    [
                      _vm._m(3),
                      _c("div", { staticClass: "PricingPlanTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[1].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "OrderRefund",
                      on: { click: _vm.OrderRefundBtn },
                    },
                    [
                      _vm._m(4),
                      _c("div", { staticClass: "OrderRefundTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[3].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ShippingTracking",
                      on: { click: _vm.ShippingTrackingBtn },
                    },
                    [
                      _vm._m(5),
                      _c("div", { staticClass: "ShippingTrackingTitle" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.BigTitle[5].CatalogName) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "BasicFunctionsImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/BasicOne.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "StoreConnectionImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/StoreOne.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "MessageImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/MessageOne.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "PricingPlanImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/PricingOne.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "OrderRefundImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/OrderOne.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ShippingTrackingImg" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/HelpCenterImg/Shipping.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }