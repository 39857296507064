var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payWay" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm._dialogVisible, "custom-class": "payDialog" },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "payContent" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "title" }, [_vm._v("Shopify user")]),
              _c("img", {
                attrs: {
                  src: require("@/assets/imgs/member_shopify_icon.png"),
                  alt: "",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.Go("shopify")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("GO "),
                    _c("i", { staticClass: "el-icon-right" }),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "diver" }),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "title" }, [_vm._v("Non-Shopify user")]),
              _c("img", {
                staticClass: "twoIcon",
                attrs: {
                  src: require("@/assets/imgs/member_other_icon1.png"),
                  alt: "",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.Go("other")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("GO "),
                    _c("i", { staticClass: "el-icon-right" }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }