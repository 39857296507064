var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "main-card-box",
      },
      [_c("card", { attrs: { cardList: _vm.productList } })],
      1
    ),
    !_vm.searchImage
      ? _c(
          "div",
          { staticClass: "main-footer" },
          [
            _c("PagePagination", {
              attrs: {
                total: _vm.total,
                "page-size": _vm.pageSize,
                "current-page": _vm.currentPage,
              },
              on: { "current-change": _vm.pageChange },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }