/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
import {
  GetWinnerList,
  GetWinnerDetail,
  AddToMyProduct,
  GetPodList,
  GetUSAStoreList,
  TheChartsList,
  GetClearanceList,
  HasNewWinnerProduct,
  ReadWinnerDetail,
  GetCountByTimePeriod,
  GetFirstProduct,
  GetProductExtByProductId,
  WannaExpirePopup,
  PostExpirePopup,
  GetWinnerCountryList,
  GetRightsWithWinnerQuote,
  GetWinnerPrice,
  GetLogisticFee,
  CrawlProductPost,
  GetElectronicsList,
  GetOutdoorSportsList,
  GetCarAccessoriesList,
  GetMothersDayList,
  GetMotherBabyList,
  GetPetsList,
  GetExhibitionList
} from '@/api/winner'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetWinnerListWi({state, commit}, params) {return GetWinnerList(params).then(res => res)},
    GetWinnerDetailWi({state, commit}, params) {return GetWinnerDetail(params).then(res => res)},
    AddToMyProductWi({state, commit}, params) {return AddToMyProduct(params).then(res => res)},
    GetPodListWi({state, commit}, params) {return GetPodList(params).then(res => res)},
    TheChartsListWi({state, commit}, params) {return TheChartsList(params).then(res => res)},
    GetUSAStoreListWi({state, commit}, params) {return GetUSAStoreList(params).then(res => res)},
    GetClearanceListWi({state, commit}, params) {return GetClearanceList(params).then(res => res)},
    HasNewWinnerProductWi({state, commit}, params) {return HasNewWinnerProduct(params).then(res => res)},
    ReadWinnerDetailWi({state, commit}, params) {return ReadWinnerDetail(params).then(res => res)},
    GetCountByTimePeriodWi({state, commit}, params) {return GetCountByTimePeriod(params).then(res => res)},
    GetFirstProductWi({state, commit}, params) {return GetFirstProduct(params).then(res => res)},
    GetProductExtByProductIdWi({state, commit}, params) {return GetProductExtByProductId(params).then(res => res)},
    WannaExpirePopupWi({state, commit}, params) {return WannaExpirePopup(params).then(res => res)},
    PostExpirePopupWi({state, commit}, params) {return PostExpirePopup(params).then(res => res)},
    GetWinnerCountryListWi({state, commit}, params) {return GetWinnerCountryList(params).then(res => res)},
    GetRightsWithWinnerQuoteWi({state, commit}, params) {return GetRightsWithWinnerQuote(params).then(res => res)},
    GetWinnerPriceWi({state, commit}, params) {return GetWinnerPrice(params).then(res => res)},
    GetLogisticFeeWi({state, commit}, params) {return GetLogisticFee(params).then(res => res)},
    CrawlProductPostWi({state, commit}, params) {return CrawlProductPost(params).then(res => res)},
    GetElectronicsListWi({state, commit}, params) {return GetElectronicsList(params).then(res => res)},
    GetOutdoorSportsListWi({state, commit}, params) {return GetOutdoorSportsList(params).then(res => res)},
    GetCarAccessoriesListWi({state, commit}, params) {return GetCarAccessoriesList(params).then(res => res)},
    GetMothersDayListWi({state, commit}, params) {return GetMothersDayList(params).then(res => res)},
    GetMotherBabyListWi({state, commit}, params) {return GetMotherBabyList(params).then(res => res)},
    GetPetsListWi({state, commit}, params) {return GetPetsList(params).then(res => res)},
    GetExhibitionListWi({state, commit}, params) {return GetExhibitionList(params).then(res => res)},
  }

}
