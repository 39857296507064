import {
  GetMyRechargeList,
  GetMyBalanceLogList,
  ExportMyBalanceLogList,
  GetMyCreditLimitRegItemList,
  GetBalanceWithdrawalList,
  ExportBalanceWithdrawalList,
  GetOrderPaymentList,
  GetRefundRecordList,
  ExportRefundRecordList,
  GetGrossProfitList,
  ExportGrossProfitList,
  PaymentRefundRegisterList,
  ExportPaymentRefundRegisterList,
  ExportBusinessOrderList,
  ShopAvailableCollectionList,
  ShopAvailableCollectionExport,
  ShopAvailableTotal,
  CusProfitCollection,
  CusProfitCollectionExport,
  GetNyOrderList,
  ExportNyBusinessOrderList,
  NYRefundRegisterList,
  ExportRefundNyExportList
} from '@/api/recharge'
export default {
  namespaced: true,
  state: {
    token: ''
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {},
    setToken(state, status) {
      state.token = status
    }
  },
  actions: {
    GetMyRechargeListRc({ state, commit }, params) {
      return GetMyRechargeList(params).then((res) => res)
    },
    GetMyBalanceLogListRc({ state, commit }, params) {
      return GetMyBalanceLogList(params).then((res) => res)
    },
    ExportMyBalanceLogListRc({ state, commit }, params) {
      return ExportMyBalanceLogList(params).then((res) => res)
    },
    GetMyCreditLimitRegItemRc({ state, commit }, params) {
      return GetMyCreditLimitRegItemList(params).then((res) => res)
    },
    GetBalanceWithdrawalListRc({ state, commit }, params) {
      return GetBalanceWithdrawalList(params).then((res) => res)
    },
    ExportBalanceWithdrawalListRc({ state, commit }, params) {
      return ExportBalanceWithdrawalList(params).then((res) => res)
    },
    GetOrderPaymentListRc({ state, commit }, params) {
      return GetOrderPaymentList(params).then((res) => res)
    },
    GetRefundRecordListRc({ state, commit }, params) {
      return GetRefundRecordList(params).then((res) => res)
    },
    ExportRefundRecordListRc({ state, commit }, params) {
      return ExportRefundRecordList(params).then((res) => res)
    },
    GetGrossProfitListRc({ state, commit }, params) {
      return GetGrossProfitList(params).then((res) => res)
    },
    ExportGrossProfitListRc({ state, commit }, params) {
      return ExportGrossProfitList(params).then((res) => res)
    },
    PaymentRefundRegisterRC({ state, commit }, params) {
      return PaymentRefundRegisterList(params).then((res) => res)
    },
    ExportPaymentRefundRegisterRC({ state, commit }, params) {
      return ExportPaymentRefundRegisterList(params).then((res) => res)
    },
    ExportBusinessOrderListRC({ state, commit }, params) {
      return ExportBusinessOrderList(params).then((res) => res)
    },
    ShopAvailableCollectionListRC({ state, commit }, params) {
      return ShopAvailableCollectionList(params).then((res) => res)
    },
    ShopAvailableCollectionExportRC({ state, commit }, params) {
      return ShopAvailableCollectionExport(params).then((res) => res)
    },
    ShopAvailableTotalRC({ state, commit }, params) {
      return ShopAvailableTotal(params).then((res) => res)
    },
    CusProfitCollectionRC({ state, commit }, params) {
      return CusProfitCollection(params).then((res) => res)
    },
    CusProfitCollectionExportRC({ state, commit }, params) {
      return CusProfitCollectionExport(params).then((res) => res)
    },
    GetNyOrderListRc({ state, commit }, params) {
      return GetNyOrderList(params).then((res) => res)
    },
    ExportNyBusinessOrderListRc({ state, commit }, params) {
      return ExportNyBusinessOrderList(params).then((res) => res)
    },
    NYRefundRegisterListRC({ state, commit }, params) {
      return NYRefundRegisterList(params).then((res) => res)
    },
    ExportRefundNyExportListRC({ state, commit }, params) {
      return ExportRefundNyExportList(params).then((res) => res)
    },
  }
}
