<template>
  <div class="scroll" :class="{ show: isActive }">
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="record_page" :class="[theme == 'common' ? '' : 'dark']">
        <div class="Filter">
          <el-input :placeholder="$t('DLZ.SearchMonth')" v-model="Month" class="input-with-select">
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search" @click="SearchOrderNumber">{{ $t('DLZ.Search') }}</button>
          <button class="searchFilter" @click="searchFilter">{{ $t('DLZ.Filter') }}</button>
        </div>
        <div>
          <!-- 高级搜索弹框 -->
          <el-dialog :visible.sync="isMore" custom-class="mydialog" :center="true">
            <div class="orderDate">
              <div class="dialog_time">
                <p>{{ $t('orders.dialog_time') }}</p>
              </div>
              <div class="dialog_selectTime">
                <el-form>
                  <el-form-item>
                    <el-date-picker
                      v-model="selectTime"
                      class="myselect"
                      type="monthrange"
                      align="right"
                      unlink-panels
                      value-format="yyyy-MM-dd"
                      :range-separator="$t('orders.to')"
                      @change="timeChange"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <button @click="moreSearch" class="comBtn btn-bg0 min-wid200">
                {{ $t('orders.Confirm') }}
              </button>
            </div>
          </el-dialog>
        </div>
        <div class="records">
          <el-tabs v-model="activeName">
            <!-- 充值记录 -->
            <el-tab-pane :label="$t('DLZ.ProfitStatement')" name="recharge" class="recharge">
              <template v-if="rechargeList && rechargeList.length > 0">
                <el-table :data="rechargeList" style="width: 100%; overflow-x: auto">
                  <el-table-column prop="TargetMonth" align="center" :label="$t('DLZ.Month')">
                  </el-table-column>
                  <el-table-column
                    prop="ReceivedAmount"
                    align="center"
                    :label="$t('DLZ.AmountReceivedCurrency')"
                  >
                  </el-table-column>
                  <!-- 支付手续费 -->
                  <el-table-column
                    prop="ReceivedHandleFee"
                    align="center"
                    :label="$t('DLZ.PaymentProcessingFeeCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="CustomerShare"
                    align="center"
                    :label="$t('DLZ.CustomerShareCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="RefundAmount"
                    align="center"
                    :label="$t('DLZ.TotalRefundAmountCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="AdverAmount"
                    align="center"
                    :label="$t('DLZ.AdvertisingExpensesCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="StoreAmount"
                    align="center"
                    :label="$t('DLZ.StoreExpensesCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="OrderCost"
                    align="center"
                    :label="$t('DLZ.OrderCostCurrency')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="GrossProfitAmount"
                    align="center"
                    :label="$t('DLZ.GrossProfitAmountCurrency')"
                  >
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="options[0].total"
                    :page-size="pageSize"
                    :current-page="options[0].page"
                    :page-sizes="[]"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                  >
                    <span class="pageSizeInput">
                      <template>
                        <el-select v-model="pageSize" @change="selectChanged">
                          <el-option
                            v-for="item in optionsSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t('orders.get') }}</span>
                    <input type="text" v-model="options[0].jumpPage" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="DefaultData">
                  <el-table style="width: 100%">
                    <el-table-column align="center" :label="$t('DLZ.Month')"> </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.AmountReceivedCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.PaymentProcessingFeeCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.CustomerShareCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.TotalRefundAmountCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.AdvertisingExpensesCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.StoreExpensesCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.OrderCostCurrency')">
                    </el-table-column>
                    <el-table-column align="center" :label="$t('DLZ.GrossProfitAmountCurrency')">
                    </el-table-column>
                    <template slot="empty">
                      <div class="NodataImg">
                        <img
                          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927907151_Nodata.png"
                          alt=""
                        />
                        <div>No data</div>
                      </div>
                    </template>
                  </el-table>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
          <div class="currency_style">
            <p>Currency: $</p>
          </div>
          <el-button class="PaymentOrderExport comBtn border-col5 col-col5" @click="ExportExcelBtn"
            >{{ $t('PaymentOrder.Export') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { baseApiHost } from '../../utils/env'
export default {
  data: () => {
    return {
      dispatch: null,
      currentPage: 1,
      page: 'recharge',
      activeName: 'recharge', //标签当前选中
      rechargeList: [], // 我的充值列表
      pageSize: 10,
      selectTime: [],
      dialogForm: {
        TargetStartMonth: '',
        TargetEndMonth: ''
      },
      isActive: false,
      isMore: false, //是否展示高级搜索
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      Month: '', // 查询
      optionsSize: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ]
    }
  },
  components: {},
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.banner = this.$t('recharge.bannername')
    this.itemname = this.$t('recharge.itemname')
    this.getRechageList()
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = []
        }
      },
      deep: true
    }
  },
  methods: {
    SearchOrderNumber() {
      this.pageChange()
    },
    searchFilter() {
      // 切换高级搜索
      let that = this
      this.selectTime = ''
      that.isMore = !that.isMore
    },

    pageChange(page) {
      //分页器页数改变
      this.options[0].page = page
      this.getRechageList()
    },
    handleSizeChange(pageSize) {
      this.options[0].pageSize = pageSize
      this.getRechageList()
    },
    getPageList() {
      //分页器跳转页数
      this.options[0].page = this.options[0].jumpPage > 0 ? Number(this.options[0].jumpPage) : 1
      this.getRechageList()
    },
    handlePageSize() {
      if (!this.quotedList[0].pageSize) {
        this.quotedList[0].pageSize = 0
      }
    },
    selectChanged(pageSize) {
      this.options[0].pageSize = pageSize
      this.getRechageList()
    },
    moreSearch() {
      // 多条件搜索
      this.isMore = false
      this.getRechageList()
    },
    timeChange() {
      // 高级搜索时间改变
      if (this.selectTime.length) {
        // 取值
        this.dialogForm.TargetStartMonth = this.selectTime[0]
        this.dialogForm.TargetEndMonth = this.selectTime[1]
      } else {
        this.dialogForm.TargetStartMonth = ''
        this.dialogForm.TargetEndMonth = ''
      }
    },
    // 获取我的充值列表
    getRechageList() {
      // 获取充值记录
      let that = this
      that
        .dispatch('recharge/GetGrossProfitListRc', {
          TargetMonth: that.Month,
          CurrentPageIndex: that.options[0].page,
          PageSize: that.options[0].pageSize,
          RecordCount: that.options[0].list.length,
          TargetStartMonth: this.selectTime ? this.selectTime[0] : '',
          TargetEndMonth: this.selectTime ? this.selectTime[1] : ''
        })
        .then((res) => {
          if (res.Success) {
            that.rechargeList = res.Items
            that.options[0].total = res.TotalItems
          } else {
            that.$message(res.ErrMsg || that.$t('tips.failed'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ExportExcelBtn() {
      let that = this
      // 根据条件获取订单列表
      this.dispatch('recharge/ExportGrossProfitListRc', {
        CurrentPageIndex: that.options[0].page,
        PageSize: that.options[0].pageSize,
        RecordCount: that.options[0].list.length,
        TargetStartMonth: this.selectTime ? this.selectTime[0] : '',
        TargetEndMonth: this.selectTime ? this.selectTime[1] : '',
        TargetMonth: that.Month
      })
        .then((res) => {
          if (res.Success) {
            window.open(baseApiHost() + res.Result)
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
.NodataImg {
  padding-bottom: 30px;
}
.NodataImg img {
  width: 170px;
  height: 170px;
  margin-top: 80px;
}
::v-deep .el-table th.el-table__cell > .cell {
  word-break: keep-all;
}
::v-deep .el-range-editor.el-input__inner {
  width: 550px;
  border-radius: 10px;
}
::v-deep .mydialog {
  width: 750px;
  border-radius: 10px;
}
.orderDate {
  display: flex;
}
.dialog_time {
  margin-top: 30px;
  margin-left: 20px;
}
.dialog_time p {
  font-size: 18px;
}
.dialog_selectTime {
  margin-top: 20px;
  margin-left: 15px;
}
.pageSizeInput >>> .el-input__inner {
  width: 100px !important;
  height: 35px;
  margin-top: -30px;
  border-radius: 20px;
}
.record_page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
/* 充值页面 */
.records {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 32px;
  margin-top: 30px;
  position: relative;
}
::v-deep .records .el-tabs__content {
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recordsClass {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  margin: auto;
  height: 50px;
}
.InputRecords {
  height: 50px;
}
.InputRecords >>> .el-input__inner {
  height: 50px;
}
.recharge {
  background-color: #ffffff !important;
  /*border-radius: 10px !important;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.banner {
  margin-left: 20px;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
  color: #000000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__header {
  margin: 0;
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 3px;
}
::v-deep .el-tabs .el-tabs--top .el-tabs__item:hover {
  background: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页头部高度 */
::v-deep .records .recharge .el-table th,
::v-deep .el-table tr {
  height: 70px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* 内容区的高度 */
::v-deep .records .recharge .el-table__row {
  height: 80px !important;
}

.records .recharge .paging {
  margin-top: 10px;
  padding-right: 35px;
  padding-left: 32px;
}

::v-deep .records .el-tabs__content {
  border-radius: 0 0 10px !important;
}

.records .recharge .Get1 {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 110px;
  width: 35px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #000000;
  opacity: 0.3;
  border-radius: 5px;
  line-height: 28px;
  text-align: center;
  margin-right: 21px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.records .recharge .Get2 {
  position: absolute;
  bottom: 15px;
  right: 60px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  color: #000000;
  opacity: 0.6;
}
/* 分页切换 */
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* Winners搜索*/
.Filter {
  width: 1320px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  /*margin: auto;
    margin-top: 80px;*/
}
::v-deep .Filter .input-with-select {
  width: 600px;
  height: 30px;
  background: #f5f7fa !important;
  border-radius: 50px;
  margin-left: 30px;
}
::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 40px;
}
::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 40px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .Filter [class*=' el-icon-'],
[class^='el-icon-'] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search {
  display: inline-block;
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .search:hover {
  background: #3c3772;
}
.Filter .searchFilter {
  display: inline-block;
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .searchFilter:hover {
  background: #3c3772;
}
.BackTop {
  position: fixed;
  bottom: 0px;
  right: 20px;
  cursor: pointer;
}

.BackTop img {
  width: 50px;
  height: 50px;
}
.PaymentOrderExport {
  position: absolute;
  top: 20px;
  right: 50px;
  border-radius: 50px;
  width: 180px;
  font-size: 16px;
  border: 2px solid #3f6aff;
}
.PaymentOrderExport:hover {
  background: #3f6aff !important;
  color: #fff !important;
}
.currency_style {
  position: absolute;
  top: 35px;
  left: 185px;
  font-family: 'Regular';
  font-size: 14px;
  font-weight: 600;
}
</style>
