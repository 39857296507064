<script>
export default {
  name: 'FeedNeedReSendProducts',
  props: {
    orderDetails: Array,
    errorSkuList: Array,
    readonly: Boolean
  },
  data() {
    return {
      SkuList: []
    }
  },
  watch: {
    orderDetails: {
      handler: function (newVal) {
        if (this.readonly) return
        if (newVal) {
          this.SkuList = newVal.map((item) => {
            return {
              ...item,
              Num: 0
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    errorSkuList: {
      handler: function (newVal) {
        if (newVal) {
          this.SkuList = newVal
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onHandleRemove(index) {
      this.SkuList.splice(index, 1)
    },
    getReturnedSkuMap() {
      return {
        ErrorSkuList: this.SkuList
      }
    },
    handleInput(value, item) {
      item.Num = value.replace(/[^\d]/g, '')
    }
  }
}
</script>

<template>
  <div>
    <div class="header">
      <span class="title">{{ $t('SupportTicket.NeedProducts') }}</span>
    </div>
    <div class="list-view">
      <div class="list-item">
        <span class="left-product">{{ $t('SupportTicket.Item') }}</span>
        <span>{{ $t('SupportTicket.Quantity') }}</span>
      </div>
      <div class="list-item" v-for="(item, index) in SkuList" :key="index">
        <div class="left-product">
          <el-input style="width: 100%" v-model="item.ProductRealSku" disabled></el-input>
        </div>
        <el-input
          class="quantity-input"
          v-model="item.Num"
          type="number"
          @input="handleInput($event, item)"
        ></el-input>
        <el-button size="small" type="text" style="color: red" @click="onHandleRemove(index)">
          {{ $t('SupportTicket.Remove') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 16px;

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #15104b;
  }
}

.list-view {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .left-product {
      min-width: 40%;
      padding-right: 16px;
    }

    .quantity-input {
      width: 100px;
      margin-right: 16px;
    }
  }
}
</style>
