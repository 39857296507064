var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        { staticClass: "design-form-header" },
        [
          _vm.imageUploadConfig.form?.title
            ? _c("span", [_vm._v(_vm._s(_vm.imageUploadConfig.form?.title))])
            : _vm._e(),
          _vm.imageUploadConfig.form?.toolTip
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.imageUploadConfig.form?.toolTip,
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-question" })]
              )
            : _vm._e(),
          _vm.imageUploadConfig.form?.showPopupTip
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "5px", color: "#3f6aff" },
                  attrs: { type: "text", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFormPopup = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.imageUploadConfig.form?.popTitle))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("span", { staticClass: "help-text" }, [
        _vm._v("Rec." + _vm._s(_vm.getRealSize()) + " , jpg or png"),
      ]),
      _c(
        "div",
        { staticClass: "image-wrap" },
        [
          _vm.imageUploadConfig?.personInfo?.canUpload
            ? _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.showUploading,
                      expression: "showUploading",
                    },
                  ],
                  ref: "uploadRef",
                  attrs: {
                    action: "",
                    accept: "image/*",
                    "auto-upload": false,
                    "show-file-list": false,
                    "on-change": _vm.handleFileChange,
                  },
                },
                [
                  _c("div", { staticClass: "upload-container" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/imgs/pod/icon_upload.svg"),
                        alt: "",
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.uploadImageUrl
            ? _c(
                "div",
                {
                  staticClass: "image-preview-container",
                  class: { selected: _vm.currentSelectImage === -1 },
                  on: {
                    click: function ($event) {
                      return _vm.onPrintImageSelect(-1)
                    },
                  },
                },
                [
                  _c("img", { attrs: { src: _vm.uploadImageUrl, alt: "" } }),
                  _c("i", { staticClass: "el-icon-success select-icon" }),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.printFileList, function (file, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "image-preview-container",
                class: { selected: _vm.currentSelectImage === index },
                on: {
                  click: function ($event) {
                    return _vm.onPrintImageSelect(index)
                  },
                },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      disabled:
                        !_vm.imageUploadConfig.personInfo?.showPrintImgName,
                      effect: "dark",
                      content: file.name,
                      placement: "top",
                    },
                  },
                  [_c("img", { attrs: { src: file.url, alt: "" } })]
                ),
                _c("i", { staticClass: "el-icon-success select-icon" }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.imageUploadConfig.form?.helpTxt
        ? _c("span", { staticClass: "help-text" }, [
            _vm._v(_vm._s(_vm.imageUploadConfig.form?.helpTxt)),
          ])
        : _vm._e(),
      _c("ImageCropDialog", {
        attrs: {
          file: _vm.currentCropFile,
          "output-size": _vm.imageRealSizeConfig,
          "aspect-ratio":
            _vm.imageRealSizeConfig.width / _vm.imageRealSizeConfig.height,
        },
        on: { onCropCallback: _vm.onCropSuccess },
        model: {
          value: _vm.showCropDialog,
          callback: function ($$v) {
            _vm.showCropDialog = $$v
          },
          expression: "showCropDialog",
        },
      }),
      _c("FormPopup", {
        attrs: {
          title: _vm.imageUploadConfig.form?.popTitle,
          message: _vm.imageUploadConfig.form?.richText,
          width: _vm.imageUploadConfig.form?.popWidth,
        },
        model: {
          value: _vm.showFormPopup,
          callback: function ($$v) {
            _vm.showFormPopup = $$v
          },
          expression: "showFormPopup",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }