<template>
  <div class="shopifyUpgrade">
    <div class="leftContent">
      <div class="item1 searchItem">
        <span class="label">Pricing plan:</span>
        <el-select v-model="level" @change="changeLevel">
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="item1 searchItem">
        <span class="label">Store: </span>
        <el-select v-model="storeId">
          <el-option
            v-for="item in storeList"
            :key="item.UserId"
            :value="item.UserId"
            :label="item.StoreName"
          ></el-option>
        </el-select>
      </div>
      <div class="option">Choose your Billing option:</div>
      <!-- <span>Please set a default billing store.</span> -->
      <div class="optionsContent">
        <div
          v-for="item in optionDataList"
          :key="item.PriceId"
          class="optionItem"
          v-show="level === item.GradeId"
          @click="calculatePrice(item)"
          :class="priceType === item.PriceId ? 'activeLabel' : ''"
        >
          <div class="optionEach">
            <el-radio class="radio" :label="item.PriceId" v-model="priceType"
              >{{ radioLabel[item.CycleType]
              }}{{ item.saved ? `(Saved $${item.saved})` : '' }}</el-radio
            >
          </div>
          <div class="optionEach" :style="{ 'padding-left': !NoDiscountBindshop ? '37%' : '30%' }">
            <span v-if="NoDiscountBindshop" style="color: red; text-decoration: line-through">{{
              item.PriceText
            }}</span>
            <span v-if="DiscountBindshop">{{ item.PriceText }}</span>
            <span v-else class="text_item">{{ item.RealPrice }}/{{ timeText[item.PriceId] }}</span>

            <div class="optionsTip1" v-if="NoDiscountBindshop && [1, 4].includes(item.PriceId)">
              <span>{{ $t('member.first-time') }}</span>
            </div>
          </div>
          <div class="optionTip">
            <template v-if="DiscountBindshop">
              {{ $t('member.LinkShopifyStore') }}
            </template>
            <template v-if="NoDiscountBindshop">
              {{ $t('member.First_time') }}
            </template>
          </div>
        </div>
      </div>
      <div class="payWay" @click="submitShopify">
        <img src="@/assets/imgs//UpgradeToPro/shopify.svg" alt="" />
      </div>
      <div class="agree">
        <el-checkbox v-model="checkedAgree">
          I agree to the
          <a target="_blank" href="https://usadrop.com/terms-and-conditions"
            >Terms And Conditions</a
          >
          and
          <a target="_blank" href="https://usadrop.com/privacy-policy">Privacy Policy</a>
        </el-checkbox>
      </div>
    </div>
    <div class="rightContent">
      <shopifyRight :priceInfo="priceInfo" :CycleType="CycleType"></shopifyRight>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import shopifyRight from './components/shopifyRight.vue'

export default {
  components: { shopifyRight },
  data() {
    return {
      radioLabel: ['Monthly', '3 months', 'Yearly'],
      optionType: 2,
      priceType: null,
      CycleType: null,
      checkedAgree: false,
      dispatch: null,
      level: null,
      storeId: '',
      storeList: [],
      optionDataList: [],
      priceInfo: null,
      timeText: { 1: 'Mon', 2: '3 months', 3: 'Yearly', 4: 'Mon', 5: '3 months', 6: 'Yearly' },
      levelOptions: [
        { label: 'Pro', value: 2 },
        { label: 'Plus', value: 3 }
      ],
      DiscountBindshop: false,
      NoDiscountBindshop: false
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
    this.level = this.$route.params.id * 1
    this.priceType = this.$route.params.priceType * 1
    this.getPriceInfo()
    this.getStoreList()
    this.calculate()
    this.DisplayDicountHint()
  },
  methods: {
    submitShopify() {
      if (!this.checkedAgree) {
        this.$message.error(this.$t('member.pleaseAgree'))
        return
      }
      if (!this.priceType) {
        this.$message.error(this.$t('Please choose your Billing option'))
        return
      }
      if (!this.priceInfo) {
        this.$message.warning(this.$t('member.empty'))
        return
      }
      if (!this.storeId) {
        this.$message.warning('Please set a default billing store.')
        return
      }

      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      request({
        method: 'post',
        url: '/api/MemberShip/CreateOrderByShopify',
        data: {
          PriceId: this.priceType,
          PayType: 10,
          ShopifyUserId: this.storeId,
          ShopifyBillingReturnUrl: `${process.env.VUE_APP_UD_HOST}/Airwallexsuccess`
        }
      })
        .then((res) => {
          if (res.success) {
            var win = window.open(res.Result?.ShopifyParam?.ConfirmationUrl)
            if (!win) {
              console.log('弹窗无法打开,采用 window.location.href')
              window.location.href = res.Result?.ShopifyParam?.ConfirmationUrl
            } else {
              console.log('Popup opened')
            }
            loading.close()
          } else {
            this.$message.warning(res.ErrMsg)
            loading.close()
          }
        })
        .catch((err) => {
          loading.close()
        })
    },
    getPriceInfo() {
      request({
        method: 'get',
        url: '/api/MemberShip/GetGradePriceList'
      }).then((res) => {
        if (res.Result?.length) {
          //计算下节省的钱1,2,3,4,5,6分别是pro和plus的月 季度 年
          let proMon = res.Result.find((item) => item.PriceId === 1)
          let proSea = res.Result.find((item) => item.PriceId === 2)
          let proYear = res.Result.find((item) => item.PriceId === 3)
          let plusMon = res.Result.find((item) => item.PriceId === 4)
          let plusSea = res.Result.find((item) => item.PriceId === 5)
          let plusYear = res.Result.find((item) => item.PriceId === 6)
          proSea['saved'] = (proMon.Price * 3 - proSea.Price).toFixed(2)
          proYear['saved'] = (proMon.Price * 12 - proYear.Price).toFixed(2)
          plusSea['saved'] = (plusMon.Price * 3 - plusSea.Price).toFixed(2)
          plusYear['saved'] = (plusMon.Price * 12 - plusYear.Price).toFixed(2)
          res.Result?.forEach((element) => {
            if (!['professional_season', 'premium_season'].includes(element.AppleProductId)) {
              this.optionDataList.push(element)
            }
          })
          this.CycleType = res.Result.find((item) => item.PriceId === this.priceType).CycleType
        } else {
          if (!res.Success) {
            this.$message.error(res.ErrMsg)
          }
        }
      })
    },
    // 获取shopify店铺列表
    getStoreList() {
      request({
        method: 'get',
        url: '/api/Store/GetList',
        params: {
          storeType: 'Shopify'
        }
      })
        .then((res) => {
          if (res.Result?.length) {
            const filteredStores = res.Result.filter((store) => store.Status === 1)
            if (filteredStores.length) {
              this.storeId = filteredStores[0].UserId
              this.storeList = filteredStores
            } else {
              this.storeId = null
              this.storeList = []
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching store list:', error)
        })
    },
    /* 判断会员权益显示的内容 */
    DisplayDicountHint() {
      this.dispatch('member/DisplayDicountHintMe')
        .then((res) => {
          if (res.Result.HasDiscount == true && res.Result.HasBindShop == false) {
            this.DiscountBindshop = true
          }
          if (res.Result.HasDiscount == true && res.Result.HasBindShop == true) {
            this.NoDiscountBindshop = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changeLevel(levle) {
      this.priceType = null
      this.priceInfo = {}
    },
    calculatePrice(item) {
      this.priceType = item.PriceId
      this.CycleType = item.CycleType
      this.calculate()
    },
    calculate() {
      request({
        method: 'post',
        url: '/api/MemberShip/CalculateFee',
        data: { PriceId: this.priceType }
      }).then((res) => {
        if (res.Success) {
          this.priceInfo = res.Result
        } else {
          this.$message.error(res.ErrMsg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$color: #3f6aff;
@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active {
  color: #3f6aff;
}
.shopifyUpgrade {
  width: 1400px;
  height: 750px;
  //   margin: 0 auto;
  display: flex;

  .leftContent {
    width: calc(100% - 350px);
    height: 100%;
    background: #ffffff;
    margin-right: 40px;
    border-radius: 10px;
    padding: 40px;
    .searchItem {
      width: 80%;
      display: flex;
      color: #333333;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .label {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
        width: 90px;
      }

      ::v-deep .el-input__inner {
        width: 560px;
        border-radius: 30px;
      }
      ::v-deep .el-range-separator {
        width: 10%;
      }
    }
    .option {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      margin: 40px 0 20px 0;
    }
    .optionsContent {
      border: 1px solid #eff1f3;
      overflow: auto;
      margin-bottom: 70px;
      border: 1px solid #eff1f3;
      border-radius: 6px;
      border-top: none;
      .optionItem {
        cursor: pointer;
        border-top: 1px solid #eff1f3;
        height: 74px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        position: relative;
        @include flex_center;
        justify-content: space-around;
        .optionEach {
          width: 25%;
          height: 100%;
          @include flex_center;
          .text_item {
            margin-left: 16px;
          }
          .radio {
            width: 80px;
            text-align: left;
          }
          //   border: 1px solid red;
          &:nth-child(2) {
            width: 75%;
            // padding-left: 27%;
            text-align: left;
            justify-content: flex-start;
          }
        }
        .optionTip {
          color: #32cd32;
          position: absolute;
          left: 80px;
          bottom: 5px;
          font-size: 14px;
        }
        .optionsTip1 {
          margin-left: 30px;
          position: absolute;
          right: 30px;
          color: #32cd32;
        }
        // &:nth-child(2) {
        //   width: 60%;
        // }
      }
      .activeLabel {
        color: $color;
        ::v-deep .el-radio__input.is-checked {
          .el-radio__inner {
            border-color: $color;
            background: $color;
          }
        }
        ::v-deep .el-radio__label {
          color: $color;
        }
      }
    }
    .payWay {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      img {
        width: 230px;
        height: 50px;
        cursor: pointer;
      }
    }
    .agree {
      text-align: center;
      font-weight: 12px;
      font-weight: 500;
      a {
        color: $color;
      }
    }
  }
  .rightContent {
    width: 350px;
    height: 100%;
  }
}
</style>
