import request from '@/utils/request'

//根据订单号搜索需要进行问题反馈的订单
export const OrderQueryByCustomer = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/OrderQueryByCustomer',
    data
  })
//检查订单是否有售后单待处理
export const CheckProcessing = (orderId) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/CheckProcessing',
    params: {
      orderId: orderId
    }
  })

//获取订单详情，带地址信息
export const GetOrderAndAddressAndDetail = (orderId) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetOrderAndAddressAndDetail',
    params: {
      orderId: orderId
    }
  })
//获取各个状态售后问题的数量
export const GetProcessingStatusCount = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetProcessingStatusCount',
    data
  })
//获取速麦提出的售后问题的各个类型数量,-1 表示全部
export const GetRaisedByUdProblemTypeCount = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetRaisedByUdProblemTypeCount',
    data
  })

//获取历史售后
export const GetHistoryData = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetHistoryData',
    data
  })
// 获取历史售后2
export const GetHistoryDataTwo = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetHistoryData2',
    params: data
  })
//获取上传的图片列表
export const GetOrderFiles = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetOrderFiles',
    data
  })

//获取售后消息记录
export const GetCustomerOrderMessageRecords = (orderId) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetCustomerOrderMessageRecords',
    params: {
      orderId: orderId
    }
  })

//保存提交的问题
export const SaleProblemsByCustomer = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/SaleProblemsByCustomer',
    data
  })

//保存回复
export const SaveReplyByCustomer = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/SaveReplyByCustomer',
    data
  })

//获取售后详情 https://webapi.test.wiioreg.com/index.html
// 参数customerOrderId
export const queryAfterSaleDetail = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/GetAfterProblemDetail',
    params: data
  })

//售后单的分页
export const AfterProblemQuery = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/AfterProblemQuery',
    data
  })
//售后单的分页(UD)
export const AfterProblemByUdQuery = (data) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/AfterProblemByUdQuery',
    data
  })
// 标记问题已解决
export const SaleProblemsSolved = (customerOrderId) =>
  request({
    method: 'post',
    url: '/api/CustomAfterSale/SaleProblemsSolved',
    params: {
      customerOrderId: customerOrderId
    }
  })
