import request from '@/utils/request';

export const GetPaymentTypes = (params) => request({
  method: "get",
  url: '/api/Recharge/GetPaymentTypes',
  params
});
export const SubmitBankTransfer = (data) => request({
  method: "post",
  url: '/api/Recharge/SubmitBankTransfer',
  data
});
export const SubmitPaypalOffline = (data) => request({
  method: "post",
  url: '/api/Recharge/SubmitPaypalOffline',
  data
});
export const CreateCheckout = (data) => request({
    method: "post",
    url: '/api/Stripe/CreateCheckout',
    data
  });
  export const SubmitPaypal = (data) => request({
    method: "post",
    url: '/api/Recharge/SubmitPaypal',
    data
  });
  export const CalculateFeeAmount = (data) => request({
    method: "post",
    url: '/api/Recharge/CalculateFeeAmount',
    data
  });
  export const SubmitOceanPay = (data) => request({
    method: "post",
    url: '/api/Recharge/SubmitOceanPay',
    data
  });
  export const GetOceanPayRequestForm = (data) => request({
    method: "post",
    url: '/api/Recharge/GetOceanPayRequestForm',
    data
  });
  export const CapturePaypalOrder = (data) => request({
    method: "post",
    url: '/api/Recharge/CapturePaypalOrder',
    data
  });
  export const CreatePaypalOrder = (data) => request({
    method: "post",
    url: '/api/Recharge/CreatePaypalOrder',
    data
  });
  export const CreateAirWallexOrder = (data) => request({
    method: "post",
    url: '/api/Recharge/CreateAirWallexOrder',
    data
  });
  // 获取流水列表
  export const TaxBalanceList = (data) => request({
    method: "post",
    url: '/api/LhTaxBalance/List',
    data
  });
  // 获取卡号列表
   export const GetCardList = (data) => request({
    method: "post",
    url: '/api/LhTaxBalance/GetCardList',
    data
  });
  // 获取最后提现信息
  export const LastBalanceWithdrawal = (data) => request({
    method: "post",
    url: '/api/Member/LastBalanceWithdrawal',
    data
  });