var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme === "common" ? "" : "dark"],
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-tabs",
            { staticClass: "source-tab" },
            [
              _c(
                "el-tab-pane",
                { staticClass: "first" },
                [
                  _vm.quotedList[0].list.length > 0
                    ? [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.quotedList[0].list } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.$t("Sourcing.SourcingImage"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c(
                                        "div",
                                        { staticClass: "content" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: scope.row.ProductImage,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1000208590
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: _vm.$t("Sourcing.ProductLinks") },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          { staticClass: "product_link_box" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "product_link" },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.ProductLink)
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-copy-document copy_btn",
                                                attrs: {
                                                  "data-clipboard-text":
                                                    scope.row.ProductLink,
                                                  id: "quoting_copy",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyData(
                                                      "quoting_copy"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                2150566948
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "140",
                                "show-overflow-tooltip": "",
                                align: "center",
                                label: _vm.$t("Sourcing.Name"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          { staticClass: "title_name" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.ProductTitle) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                1951250724
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.$t("Sourcing.state"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          {
                                            class: _vm.switchState(
                                              scope.row.OfferStatus
                                            ).className,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.switchState(
                                                    scope.row.OfferStatus
                                                  ).title
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                2769510854
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.$t("Sourcing.ProductPrice"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c("div", {}, [
                                        scope.row.MallPrice
                                          ? _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.MallPrice
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c("div", [_vm._v("--")]),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                2278424983
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.$t("Sourcing.Description"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c(
                                        "div",
                                        { staticClass: "product_describe" },
                                        [
                                          scope.row.ProductDescribe
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.ProductDescribe
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _c("div", [_vm._v("--")]),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                142637860
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                width: "220px",
                                label: _vm.$t("Sourcing.check"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _c(
                                        "div",
                                        { staticClass: "signBox" },
                                        [
                                          _vm.switchState(scope.row.OfferStatus)
                                            .title == "Quoted"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "sign-box-btn comBtn col-col5",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewDetail(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("Sourcing.views")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.switchState(scope.row.OfferStatus)
                                            .title == "To Be Quoted"
                                            ? _c("div", [
                                                _vm._v(
                                                  " Within 1 working day "
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.IsViewDetail === false &&
                                          scope.row.QuoteStatus === 1
                                            ? _c("div", {
                                                staticClass: "signContent",
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                102495831
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _c("el-empty", {
                        staticClass: "no-data",
                        attrs: { description: _vm.$t("Sourcing.NoData") },
                      }),
                  _c(
                    "div",
                    { staticClass: "pagebox" },
                    [
                      _c("PagePagination", {
                        attrs: {
                          total: _vm.quotedList[0].total,
                          "page-size": _vm.quotedList[0].pageSize,
                          "current-page": _vm.quotedList[0].page,
                        },
                        on: { "current-change": _vm.pageChange },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.paymentList.IsAdmin == true
            ? _c(
                "el-button",
                {
                  staticClass: "orders comBtn btn-bg5",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.sourcingBtn.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Sourcing.SourcingAliexpressProducts")) + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.Fulfilled
            ? _c(
                "div",
                { staticClass: "speed-progress" },
                [
                  _c("div", { staticClass: "progress-fulfilled" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("Sourcing.ProductsSourced"))),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.MembershipInterests.UsedTimes)),
                    ]),
                    _c("span", [_vm._v("/")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.MembershipInterests.TotalTimes)),
                    ]),
                  ]),
                  _c("el-progress", {
                    staticStyle: { width: "170px", "margin-top": "8px" },
                    attrs: { percentage: _vm.progressBar, "show-text": false },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "monthly-red",
                      on: {
                        click: function ($event) {
                          return _vm.monthlyRed()
                        },
                      },
                    },
                    [
                      _vm.paymentList.GradeId === 1
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576583_MemberJICHUSlice.png",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.paymentList.GradeId === 2
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576645_MemberVIPSlice16.png",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.paymentList.GradeId === 3
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576615_MemberSVIPSlice16.png",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm.MembershipInterests.DayLimit &&
                  _vm.MembershipInterests.DayRemainTimes === 0
                    ? [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              placement: "right",
                              "open-delay": _vm.delayTime,
                            },
                          },
                          [
                            _c("div", { staticClass: "icon-question" }, [
                              _c("i", { staticClass: "el-icon-question" }),
                            ]),
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t("Sourcing.HaveToday"))),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Sourcing.SalesmanAgent"))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MemberPop", { attrs: { visible: _vm.memberPopupBox } }),
      _c("AgentPop", {
        attrs: { visible: _vm.displayAgent },
        on: {
          "update:visible": function ($event) {
            _vm.displayAgent = $event
          },
        },
      }),
      _c("MemberBindBox", { ref: "child" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }