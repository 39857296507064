<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="HelpCenterTitleBig">{{ $t("HelpCenter.HelpCenter") }}</div>
    <div class="activeName_big_box">
      <div class="activeName">
        <!-- 大盒子 -->
        <div class="HelpCenterBigBox">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item  v-for="(item) in HelpInfos" :name="item.Id" :key="item.Id">
              <template slot="title">
                {{ item.Title }}
              </template>
              <div>
                <div>{{ item.CreateTime }}</div>
                <div v-html="item.HelpContent"></div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "BasicFunction",
      banner: "",
      itemname: "",
      SearchTitle: '', // 搜索标题
      HelpInfos: [],
      InfoById: [],
      CatalogId: 0,
      activeNames: ['1']
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  created() {
    this.dispatch = this.$store.dispatch
    let search = decodeURIComponent(location.search);
    if (search) {
      let params = {};
      search = search.substring(1).split("&");
      search.map(item => {
        let index = item.indexOf("=");
        let key = item.substring(0, index);
        let value = item.substring(index + 1).split("#")[0];
        params[key] = value;
      });
      console.dir(params["Id"])
      if(params && params["Id"]){
        this.CatalogId = Number(params["Id"])
      }
    }
  },
  mounted() {
    this.GetHelpInfos();
  },
  methods: {
    GetHelpInfos() {
      this.dispatch('help/GetHelpInfosHp', {
          searchKey: "",
          CatalogId: this.CatalogId
        })
        .then(res => {
          this.HelpInfos = res.Result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleChange(id) {
      var currentId = 0
      // 拿到id数组里面最后一个数据
      if(id.length>0){
        currentId = id[id.length -1]
      }
      // 定义一个空对象把数据塞进去
      var currentIndex = {}
      // 循环出对象
      this.HelpInfos.map((item,index)=>{
        if(item.Id === currentId){
          currentIndex = index
        }
      })
      // 判断HelpContent是否为空
      if(this.HelpInfos[currentIndex] && !this.HelpInfos[currentIndex].HelpContent){
        this.dispatch('help/GetHelpInfoByIdHp', currentId)
        .then(res => {
          this.HelpInfos[currentIndex].HelpContent =  res.Result.HelpContent;
        })
        .catch(err => {
          console.log(err);
        });
      }
    }
  }
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
 /* background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  position: relative;
}
.activeName_big_box {
  padding-top: 30px;
}
.HelpCenterTitleBig {
  width: 80%;
  color: #15104B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 30px;
  font-weight: 600;
}
.HelpCenterBigBox {
  width: 1320px;
  background: #fff;
  border-radius: 10px;
  position: relative;
}
.HelpCenterBigBox >>> .el-collapse {
  width: 1280px;
  height: auto;
  margin-left: 20px;
}
.HelpCenterBigBox >>> .el-collapse-item__header {
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
}
</style>
