import {
  GetCommissionInfo,
  GetCommissionRecordsByPage,
  GetCommissionWithdrawalsByPage,
  ApplyWithdraw,
  CheckShareRole,
  SubmitBalanceWithdrawal
} from '@/api/alliance'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetCommissionInfoAi({state, commit}, params) {
      return GetCommissionInfo(params).then(res => res)
    },
    GetCommissionRecordsByPageAi({state, commit}, params) {
      return GetCommissionRecordsByPage(params).then(res => res)
    },
    GetCommissionWithdrawalsByPageAi({state, commit}, params) {
      return GetCommissionWithdrawalsByPage(params).then(res => res)
    },
    ApplyWithdrawAi({state, commit}, params) {
      return ApplyWithdraw(params).then(res => res)
    },
    CheckShareRoleAi({state, commit}, params) {
      return CheckShareRole(params).then(res => res)
    },
    SubmitBalanceWithdrawalAi({state, commit}, params) {
      return SubmitBalanceWithdrawal(params).then(res => res)
    }
  }
}
