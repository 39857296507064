import {
  GetByClick,
  AddQuestionnaireResults,
  GetAlltList,
  CompleteTask,
  RunTask
} from '@/api/surveyFile'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetByClickSf({state, commit}, params) {return GetByClick(params).then(res => res)},
    AddQuestionnaireResultsSf({state, commit}, params) {return AddQuestionnaireResults(params).then(res => res)},
    GetAlltListSf({state, commit}, params) {return GetAlltList(params).then(res => res)},
    CompleteTaskSf({state, commit}, params) {return CompleteTask(params).then(res => res)},
    RunTaskSf({state, commit}, params) {return RunTask(params).then(res => res)}
  }

}