<template>
  <div class="particulars">
      <img src="@/assets/imgs/cancel.png" alt="">
      <div class="successful">forfeiture of payment</div>
  </div>
</template>
<script>
  export default {
    data: () => {
      return {
      };
    },
    methods: {

    }
  };
</script>

<style scoped>
.particulars img {
    display: block;
    width: 248px;
    height: 200px;
    margin: auto;
    margin-top: 300px;
}
.particulars .successful {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #AAAAAA;
    text-align: center;
    margin-top: 57px;
}
</style>