import request from '@/utils/request';

export const GetVideoList = (data) => request({
  method: "post",
  url: '/api/Video/GetVideoList',
  data
});
export const GetRecommendList = (data) => request({
  method: "post",
  url: '/api/Video/GetRecommendList',
  data
});

