<template>
  <!-- 详情弹出框 -->
  <div class="paybox" v-show="hasLastedNews" @click="hideDetail">
    <div class="main">
      <div class="bg"></div>
      <div class="payContent">
        <div class="close" @click="hideDetail">
          <img src="@/assets/imgs/close.svg" alt="" />
        </div>
        <div class="title">
          <span>{{ News?.Title }}</span>
          <div class="subTitle">{{ News?.PublishTime }}</div>
        </div>
        <div class="content">
          <span v-html="News?.NewsContent"></span>
        </div>
      </div>
      <div class="copyright">
        <p>{{ $t('lastedNews.OnTime') }}</p>
        <p style="margin-top: 8px">{{ $t('lastedNews.DropshippingFulfillment') }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => {
    return {
      News: {},
      hasLastedNews: false,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0 //倒计时 计数器
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
    this.dispatch('notification/GetLastedNo')
      .then((res) => {
        this.News = res?.Result
        if (this.News) {
          this.hasLastedNews = true
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    hideDetail() {
      //关闭消息详情
      this.hasLastedNews = false
    }
  }
}
</script>
<style lang="scss" scoped>
/* 详情弹出框 */
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  @extend .flex;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .main {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
  }
  .bg {
    width: 5.78125rem;
    height: 200px;
    background: #15104b;
    position: absolute;
    z-index: 100;
    bottom: 60px;
    border-radius: 20px;
  }
  .payContent {
    width: 5.40625rem;
    background: #ffffff;
    position: relative;
    border-radius: 20px;
    position: absolute;
    z-index: 102;
    bottom: 0px;
    height: 800px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    .close img {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    .title {
      width: 100%;
      @extend .flex;
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      padding-top: 30px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
        opacity: 0.8;
      }
    }
    .content {
      width: 100%;
      padding: 30px 100px 150px;
      height: 650px;
      overflow-y: auto;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .content p {
      word-wrap: break-word;
    }
    .content::-webkit-scrollbar {
      display: none;
    }
  }
  .copyright {
    background: url('../assets/imgs/guide/notice_YS.png') no-repeat;
    background-size: contain;
    width: 1110px;
    height: 220px;
    @extend .flex;
    padding-bottom: 20px;
    z-index: 103;
    color: #ffffff;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 103;
    bottom: 0;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
}
</style>
