<template>
  <div class="printImagesContent">
    <div class="upload-content">
      <div class="searchBar">
        <el-upload
          v-if="showUpload"
          accept="image/*"
          action=""
          class="upload-pic"
          multiple
          :show-file-list="false"
          :http-request="customUploadRequest"
        >
          <el-button size="small" round type="primary">+ Upload</el-button>
        </el-upload>
        <span v-if="!showUpload"></span>
        <el-input
          clearable=""
          v-model="searchKeyword"
          @clear="clearKeyword"
          @keyup.enter.native="enterSearch"
          placeholder="enter keyword"
          class="form-input input-search"
        ></el-input>
      </div>
      <div class="upload-pic-content">
        <template v-if="imgList?.length">
          <div class="upload-pic-flex">
            <div class="print-div" v-for="(item, index) in imgList" :key="item.id">
              <div class="imgsCheck" @click.prevent="checkImg(index)">
                <el-image class="img" :src="item.imageUrl" alt="" lazy></el-image>
                <template v-if="showUpload">
                  <img
                    @click.stop="delImg(item, index)"
                    class="del showDel"
                    src="@/assets/imgs/detail/X.png"
                  />
                </template>
                <el-checkbox
                  @click.stop="checkImg(index)"
                  size="large"
                  class="checkbox"
                  :value="item.checked"
                ></el-checkbox>
              </div>
              <div class="pic-names">
                <div class="name">{{ item.fileName }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <el-empty
            :description="
              showUpload
                ? 'Upload your files to make your ideas come true]'
                : 'No results found matching your query'
            "
            :image-size="360"
            image="http://eshop-bucket.hzpdex.com/web/1718873581690_Frame.png"
          ></el-empty>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgList: {
      type: Array,
      required: true,
      default: () => []
    },
    tabName: {
      type: String | Number,
      required: true
    },
    showUpload: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      searchKeyword: ''
    }
  },
  methods: {
    enterSearch(e) {
      if (e.keyCode === 13) {
        this.$emit('enterSearch', this.searchKeyword)
      }
    },
    clearKeyword() {
      this.$emit('enterSearch', '')
    },
    customUploadRequest(file) {
      this.$emit('customUploadRequest', file)
    },
    checkImg(index) {
      this.$emit('checkImg', index, this.tabName)
    },
    delImg(index) {
      this.$emit('delImg', index, this.tabName)
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.printImagesContent {
  .upload-content {
    // padding: 0 60px;
    padding-left: 20px;
    height: calc(100% - 100px);
    overflow: hidden;
    .searchBar {
      margin-bottom: 20px;
      @include flex_center;
      width: 100%;
      padding-inline-start: 0;
      .input-search {
        width: 280px;
        ::v-deep .el-input__inner {
          border-radius: 20px;
        }
      }
    }
    .upload-pic-content {
      width: 100%;
      overflow-y: hidden;
      height: 480px;
      .upload-pic-flex {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        .print-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .imgsCheck {
            position: relative;
            width: 180px;
            height: 180px;
            margin-bottom: 10px;
            .del {
              display: none;
              font-size: 14px;
              cursor: pointer;
              width: 30px;
              height: 30px;
              position: absolute;
              right: 10px;
              top: 10px;
              // left: calc(50% - 10px);
              // top: calc(50% - 10px);
            }
            &:hover {
              .showDel {
                display: block;
              }
            }
            .img {
              cursor: pointer;
              width: 100%;
              height: 100%;
              border-radius: 6px;
              border: 1px solid #e5e5e5;
            }
            .checkbox {
              position: absolute;
              right: 10px;
              top: 155px;
              ::v-deep .el-checkbox__input {
                .el-checkbox__inner {
                  border-color: #15104b;
                }
              }
              //   ::v-deep .el-checkbox__input {
              //     width: 20px;
              //     height: 20px;
              //     .el-checkbox__inner {
              //       width: 100%;
              //       height: 100%;
              //     }
              //   }
            }
          }

          .pic-names {
            font-size: 15px;
            font-weight: 600;
            color: #333333;
            .name {
              width: 180px;
              @include ellipse;
            }
          }
        }
      }
      .empty-pic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #666666;
        img {
          width: 360px;
          height: 360px;
          margin-bottom: 48px;
        }
      }
    }
  }
}
</style>
