var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "main-card-box" },
      [
        _c(
          "el-tabs",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { "before-leave": _vm.beforeSwitch },
            on: { "tab-click": _vm.tabClick },
            model: {
              value: _vm.tabName,
              callback: function ($$v) {
                _vm.tabName = $$v
              },
              expression: "tabName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "All", name: "all" } },
              [
                _c("card", {
                  attrs: {
                    cardList: _vm.productList,
                    productType: _vm.productType,
                  },
                }),
              ],
              1
            ),
            _vm._l(_vm.productPlatforms, function (value, key) {
              return _c(
                "el-tab-pane",
                { key: key, attrs: { label: key, name: key } },
                [
                  _c("card", {
                    attrs: {
                      cardList: _vm.productList,
                      productType: _vm.productType,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c("UpgradeDialog", {
          attrs: {
            visible: _vm.upgradeVisible,
            title: "Get Pro Plan for more features",
          },
          on: {
            "update:visible": function ($event) {
              _vm.upgradeVisible = $event
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main-footer" },
      [
        _c("PagePagination", {
          attrs: {
            total: _vm.total,
            "page-size": _vm.pageSize,
            "current-page": _vm.currentPage,
          },
          on: { "current-change": _vm.pageChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }