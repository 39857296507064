import request from '@/utils/request';

export const ResetPwd = (data) => request({
  method: "post",
  url: '/api/Login/ResetPwd',
  data
});
export const ResetPassword = (params) => request({
    method: "get",
    url: '/api/Login/ResetPassword',
    params
  });


