var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.Dashboard ? _c("div") : _vm._e(),
    _c(
      "div",
      {
        staticClass: "particulars",
        class: [_vm.theme == "common" ? "" : "dark"],
      },
      [
        _c(
          "div",
          { staticClass: "activeName" },
          [
            _c("div", { staticClass: "display_area" }, [
              _c(
                "div",
                { staticClass: "focus" },
                [
                  _c(
                    "el-carousel",
                    {
                      ref: "swiper",
                      attrs: {
                        interval: 5000,
                        arrow: "always",
                        height: "320px",
                      },
                    },
                    _vm._l(_vm.imagesUrl, function (item) {
                      return _c(
                        "el-carousel-item",
                        { key: item.id, staticClass: "swiper" },
                        [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: item.PicUrl, alt: "none" },
                          }),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "chart" },
              [
                _c(
                  "button",
                  {
                    staticClass: "seven",
                    class: [_vm.dataTime == "0" ? "selected" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.setTime("0")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("dashboard.seven")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "month",
                    class: [_vm.dataTime == "1" ? "selected" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.setTime("1")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("dashboard.month")) + " ")]
                ),
                _c("div", { staticClass: "paid_orders_number" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.PaidOrdersNumber"))),
                ]),
                _c("echarts", {
                  staticClass: "echarts",
                  attrs: { dayType: _vm.dataTime },
                }),
                _c("div", { staticClass: "paid_days" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.PaidDays"))),
                ]),
              ],
              1
            ),
            _c("dashboardBalance"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }