<template>
  <div class="menu-item-warp">
    <template v-for="child in route">
      <div :key="child.path" v-if="!child.meta?.hidden" :class="child.children ? 'menuItems' : ''">
        <el-submenu :key="child.name" :index="child.path" v-if="child.children">
          <template slot="title">
            <div class="silder-menu-item">
              <img v-if="child.meta?.icon" :src="child.meta?.icon" alt="icon" />
              <span slot="title" class="silder-menu-item-title">{{
                child?.meta?.title || child.name
              }}</span>
            </div>
            <!--递归调用组件自身 -->
          </template>
          <MenuItem v-if="child.children?.length" :route="child.children"></MenuItem>
        </el-submenu>
        <el-menu-item
          v-else
          :index="child.path"
          :test="child.path"
          :class="$route.matched[0].path == child.path ? 'is-active' : ''">
          <div class="silder-menu-item">
            <img v-if="child.meta?.icon" :src="child.meta?.icon" alt="icon" />
            <span slot="title">{{ child.meta?.title }}</span>
            <p v-show="child.meta?.lock && showMemuLock">
              <i class="el-icon-lock"></i>
            </p>
          </div>
        </el-menu-item>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'MenuItem',
  props: ['route'],
  computed: {
    ...mapState['showMemuLock']
  },
  data() {
    return {
      showMemuLock: this.$store.state.showMemuLock
    }
  },
  mounted() {
    this.$store.dispatch('requestMenuLock')
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.menu-item-warp {
  .el-menu-item {
    font-size: 16px !important;
    color: rgb(171, 172, 195);
  }
  .menuItems {
    .el-menu-item {
      font-size: 14px !important;
      margin-left: 30px;
    }
  }
  .is-active {
    color: #ffffff !important;
  }
  .silder-menu-item {
    display: flex;
    align-items: center;
    .silder-menu-item-title {
      font-size: 16px;
    }
    img {
      width: 32px;
      height: 32px;
      margin-left: -20px;
    }
    span {
      margin-left: 20px;
    }
  }
}

::v-deep .el-submenu__title {
  .el-submenu__icon-arrow {
    right: 0;
  }
}
</style>
