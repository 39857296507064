var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feed-missing-item" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("SupportTicket.MissingItem")))]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(_vm._s(_vm.$t("SupportTicket.TipsIf"))),
            ]),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { background: "#3f6aff", border: "none" },
              attrs: { size: "small", round: "", type: "primary" },
              on: { click: _vm.onAddMissItem },
            },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.AddMissingltem")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "validateFormRef",
          attrs: { inline: true, model: _vm.formModel, disabled: _vm.readonly },
        },
        _vm._l(_vm.formModel.missItems, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("SupportTicket.MissingItem"),
                    prop: "missItems." + index + ".sku",
                    rules: {
                      required: true,
                      message: "Please select Missing Item",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: item.sku,
                        callback: function ($$v) {
                          _vm.$set(item, "sku", $$v)
                        },
                        expression: "item.sku",
                      },
                    },
                    _vm._l(_vm.productSelectOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("SupportTicket.MissingPart") } },
                [
                  _c("el-input", {
                    model: {
                      value: item.part,
                      callback: function ($$v) {
                        _vm.$set(item, "part", $$v)
                      },
                      expression: "item.part",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("SupportTicket.MissingQuantity"),
                    prop: "missItems." + index + ".num",
                    rules: {
                      required: true,
                      message: "Please Input Missing Quantity",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    on: {
                      input: function ($event) {
                        return _vm.handleInput($event, item)
                      },
                    },
                    model: {
                      value: item.num,
                      callback: function ($$v) {
                        _vm.$set(item, "num", $$v)
                      },
                      expression: "item.num",
                    },
                  }),
                ],
                1
              ),
              _vm.formModel.missItems.length > 1
                ? _c(
                    "el-button",
                    {
                      staticStyle: { color: "red" },
                      attrs: {
                        size: "small",
                        type: "text",
                        disabled: _vm.readonly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onRemoveMissItem(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Remove")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }