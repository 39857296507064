<script>
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import FeedNeedReSendProducts from '@/views/SupportTicket/widgets/FeedNeedReSendProducts.vue'
import FeedMissingItem from '@/views/SupportTicket/widgets/FeedMissingItem.vue'
import { baseApiHost } from '@/utils/env'

//Item missing 少发
export default {
  name: 'FeedTemplate4',
  props: {
    dataSource: Object,
    imgUploadPath: String,
    afterSaleDetail: Object,
    readonly: Boolean
  },
  components: {
    FeedMissingItem,
    FeedNeedReSendProducts,
    FeedImageUpload
  },
  data() {
    return {
      formModel: {
        TempPath: null,
        IsPic: false,
        fileList: []
      }
    }
  },
  watch: {
    afterSaleDetail: {
      handler: function (newVal) {
        if (!newVal) return
        this.formModel.fileList = newVal.FileList || []
        const imageList = []
        this.formModel.fileList.forEach((item) => {
          let url = ''
          if (item.Url.startsWith('/')) {
            url = baseApiHost() + item.Url
          } else {
            url = baseApiHost() + '/' + item.Url
          }
          imageList.push({
            name: item.FileName,
            url: url
          })
        })
        this.defaultFileList = imageList
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    notExcludeOrderDetails: function () {
      return this.dataSource.OrderDetails?.filter((item) => !item.IsExclude)
    }
  },
  methods: {
    getTemplateData() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            let data = {
              ...this.formModel,
              TempPath: this.imgUploadPath,
              IsPic: this.formModel.fileList.length > 0,
              ...this.$refs.needReSendRef.getReturnedSkuMap()
            }
            this.$refs.missItemRef.getMissProducts().then((missData) => {
              data = { ...data, ...missData }
              resolve(data)
            })
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <el-form ref="formRef" label-position="top" :model="formModel" :disabled="readonly">
    <el-form-item>
      <FeedMissingItem
        ref="missItemRef"
        :order-details="notExcludeOrderDetails"
        :missing-map="afterSaleDetail.MissingMap"
        :readonly="readonly"
      />
    </el-form-item>
    <el-form-item>
      <FeedImageUpload
        v-model="formModel.fileList"
        :img-upload-path="imgUploadPath"
        :show-example="true"
        :readonly="readonly"
      />
    </el-form-item>
    <el-form-item>
      <FeedNeedReSendProducts
        ref="needReSendRef"
        :error-sku-list="afterSaleDetail.ErrorSkuList"
        :order-details="notExcludeOrderDetails"
        :readonly="readonly"
      />
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped></style>
