var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-left" }, [
    _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: { src: _vm.logoImg[_vm.formLink], alt: "" },
      }),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "welcome-title" }, [
      _c(
        "div",
        {
          staticClass: "ft-56 fw-800 color2",
          staticStyle: { "margin-top": "50px" },
        },
        [_vm._v("Launch Your")]
      ),
      _c("div", { staticClass: "ft-40 fw-800 color1 mb-24" }, [
        _vm._v("7-Figure Dropshipping"),
      ]),
      _c("div", { staticClass: "ft-40 fw-800 color1 mb-24" }, [
        _vm._v("Journey Today!"),
      ]),
      _c("div", { staticClass: "ft-32 fw-700 color2 mb-48" }, [
        _vm._v("Master the essentials to build a profitable online store."),
      ]),
      _c("div", { staticClass: "btnContent" }, [
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Building Your Store"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Finding Winning Products"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Advertising Strategies"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Leveraging USAdrop"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "ft-28 color2 mb-48 fw-600" }, [
        _vm._v(" Unlock everything you need to succeed with USAdrop. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }