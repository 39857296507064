<template>
<div>
  <div class="container">
    <div class="loading_text">
      <p >
        <img src="../../assets/imgs/UpgradeToPro/successful.png" alt="">
      </p>
    </div>
    <div class="USAdrop_box">
      <p>{{ $t("Help.submitted") }}</p>
    </div>
    <div class="Confirm_btn">
      <button @click="ConfirmBtn">Confirm</button>
    </div>
  </div>
</div>

</template>
<script>
export default {
  data: () => {
    return {
    };
  },
  created() {
  },
  components: {
  },
  mounted() {
  },
  methods: {
    ConfirmBtn() {
      this.$router.push('/Winners')
    }
  }
};
</script>
<style scoped>
.loading_text {
  text-align: center;
  margin-top: 99px;
}
.loading_text img {
  width: 350px;
  height: 250px;
}
.successful_box {
  text-align: center;
  margin-top: 25px;
}
.successful_box p{
  text-align: center;
  font-size: 26px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.USAdrop_box {
  text-align: center;
}
.USAdrop_box p{
  text-align: center;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
  color: #4d4d4d;
}
.Confirm_btn {
  text-align: center;
}
.Confirm_btn button {
  border: 0;
  margin-top: 30px;
  width: 150px;
  height: 40px;
  line-height: 40px;
  background: #93df89;
  color: #fff;
  border-radius: 10px;
}
.headerBg_Img {
  margin-top: 50px;
  margin-left: 80px;
}
.headerBg_Img img{
  width: 130px;
  height: 130px;
}
</style>
