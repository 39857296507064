var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "TaxPage" }, [
    _c("div", { staticClass: "mainPage" }, [
      _c("div", { staticClass: "balanceNum" }, [
        _c("span", [_vm._v("Balance:")]),
        _c("span", [
          _vm._v(
            " $ " + _vm._s(_vm.formatAmount(_vm.totalBalance.TotalBalance))
          ),
        ]),
      ]),
      _c("div", { staticClass: "searchBox" }, [
        _c("div", { staticClass: "searchItems" }, [
          _c(
            "div",
            { staticClass: "searchItem" },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v("Select Bank Card"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { id: "cardSelect", clearable: "" },
                  on: { change: _vm.changeOption },
                  model: {
                    value: _vm.cardType,
                    callback: function ($$v) {
                      _vm.cardType = $$v
                    },
                    expression: "cardType",
                  },
                },
                _vm._l(_vm.cardOptions, function (item) {
                  return _c(
                    "el-option",
                    { key: item.value, attrs: { value: item.value } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "optionItem",
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.cardNo))]),
                          _c("span", [_vm._v("$ " + _vm._s(item.balance))]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "searchItem" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("Date")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": _vm.$t("orders.to"),
                  "start-placeholder": _vm.$t(""),
                  "end-placeholder": _vm.$t(""),
                },
                model: {
                  value: _vm.dateTime,
                  callback: function ($$v) {
                    _vm.dateTime = $$v
                  },
                  expression: "dateTime",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "button",
          {
            staticClass: "comBtn btn-bg0 min-wid200",
            on: { click: _vm.searchBtn },
          },
          [_vm._v(" " + _vm._s(_vm.$t("orders.search")) + " ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { height: "428px", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "OrderDate",
                  label: "Date",
                  align: "center",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "State",
                  label: "State",
                  align: "center",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "OrderNumber",
                  align: "center",
                  label: "Bank Settlement Number",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "CardNo", align: "center", label: "Card No." },
              }),
              _c("el-table-column", {
                attrs: { prop: "AmountStr", align: "center", label: "Amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            class: {
                              "positive-amount": scope.row.Amount >= 0,
                              "negative-amount": scope.row.Amount < 0,
                            },
                          },
                          [
                            scope.row.Amount >= 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#04BD00" } },
                                  [
                                    _vm._v(
                                      "$ +" +
                                        _vm._s(
                                          _vm.formatAmount(scope.row.Amount)
                                        )
                                    ),
                                  ]
                                )
                              : _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "$ " +
                                      _vm._s(_vm.formatAmount(scope.row.Amount))
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.tableData && _vm.tableData.length === 0
                ? _c(
                    "div",
                    { attrs: { slot: "empty" }, slot: "empty" },
                    [
                      _c("el-empty", {
                        attrs: { description: _vm.$t("notification.NoData") },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "pagebox" },
      [
        _c("PagePagination", {
          attrs: {
            total: _vm.pageData.total,
            "page-size": _vm.pageData.pageSize,
            "current-page": _vm.pageData.page,
          },
          on: { "current-change": _vm.pageChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }