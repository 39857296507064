<script>
export default {
  name: 'FeedOrderInformation',
  props: {
    title: {
      type: String,
      default: 'Current order information'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      default: () => {}
    },
    countryList: Array
  },
  data() {
    return {
      formData: {},
      rules: {
        Contact: [{ required: true, message: 'Contact  cannot be empty ', trigger: 'blur' }],
        MobilePhone: [
          { required: true, message: 'MobilePhone  cannot be empty ', trigger: 'blur' }
        ],
        Company: [{ required: true, message: 'Company  cannot be empty ', trigger: 'blur' }],
        TelePhone: [{ required: true, message: 'TelePhone  cannot be empty ', trigger: 'blur' }],
        Email: [{ required: true, message: 'Email  cannot be empty ', trigger: 'blur' }],
        ZipCode: [{ required: true, message: 'ZipCode  cannot be empty ', trigger: 'blur' }],
        Country: [{ required: true, message: 'Country  cannot be empty ', trigger: 'change' }],
        Province: [{ required: true, message: 'Province  cannot be empty ', trigger: 'blur' }],
        City: [{ required: true, message: 'City  cannot be empty ', trigger: 'blur' }],
        Address: [
          { required: true, message: 'Detailed Address  cannot be empty ', trigger: 'blur' }
        ],
        Address2: [
          { required: true, message: 'Detailed Address2  cannot be empty ', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    address: {
      handler(newData) {
        if (newData) {
          this.formData = newData
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getInputAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.validateFormRef.validate((valid) => {
          if (valid) {
            resolve({
              Address: this.formData
            })
          } else {
            reject('error submit!!')
            this.$message.error('Has empty filed of reconfirm the information!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="header">
      <span class="title">{{ title }}</span>
    </div>
    <div>
      <el-form
        ref="validateFormRef"
        :model="formData"
        label-position="left"
        label-width="auto"
        :rules="rules"
        :disabled="readonly"
        :hide-required-asterisk="readonly"
        :show-message="false"
      >
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.ContactName')" prop="Contact">
              <el-input style="width: 100%" v-model="formData.Contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.Address')" prop="Address">
              <el-input style="width: 100%" v-model="formData.Address" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.Country')" prop="Country">
              <el-select style="width: 100%" v-model="formData.Country" filterable>
                <el-option
                  v-for="(item, index) in countryList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.AddressTwo')" prop="Address2">
              <el-input style="width: 100%" v-model="formData.Address2" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.States')" prop="Province">
              <el-input style="width: 100%" v-model="formData.Province"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.phone')" prop="TelePhone">
              <el-input style="width: 100%" v-model="formData.TelePhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.PostCode')" prop="ZipCode">
              <el-input style="width: 100%" v-model="formData.ZipCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.City')" prop="City">
              <el-input style="width: 100%" v-model="formData.City"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.MobilePhone')" prop="MobilePhone">
              <el-input style="width: 100%" v-model="formData.MobilePhone"></el-input>
            </el-form-item>
          </el-col> -->
          <!--  <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.Emailaddress')" prop="Email">
              <el-input style="width: 100%" v-model="formData.Email"></el-input>
            </el-form-item>
          </el-col> -->
          <!--  <el-col :span="12">
            <el-form-item :label="$t('SupportTicket.CompanyName')" prop="Company">
              <el-input style="width: 100%" v-model="formData.Company"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 16px;

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #15104b;
  }
}

:deep(.el-form-item__label) {
  padding-right: 10px !important;
  padding-bottom: 0 !important;
}

:deep(.el-input.is-disabled .el-input__inner) {
  color: #606266 !important;
}
</style>
