var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPopup
    ? _c("div", { staticClass: "footer" }, [
        _vm._m(0),
        _c(
          "button",
          {
            staticClass: "AcceptAllBtn",
            attrs: { id: "euCookieAcceptWP" },
            on: {
              click: function ($event) {
                return _vm.acceptCookie("granted")
              },
            },
          },
          [_vm._v("Accept all")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "RejectAllBtn",
            attrs: { id: "euCookieDenyWP" },
            on: { click: _vm.rejectCookie },
          },
          [_vm._v("Reject all")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "content" }, [
      _vm._v(
        'We use cookies (and other similar technologies) for many purposes, including to improve your experience on our site and for ads and analytics. Click "Accept all" to accept these uses. '
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://usadrop.com/privacy-policy/",
            target: "_blank",
          },
        },
        [_vm._v("Read more in our Cookie Policy.")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }