var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sync" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            width: "750px",
            title: "Sync Data",
            "custom-class": "usadialog",
            "close-on-click-modal": false,
            "label-suffix": ":",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Title", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "please Enter Title" },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "title", $$v)
                          },
                          expression: "formData.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date", prop: "rangeTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "picker-options": _vm.pickerOptions,
                          "range-separator": "-",
                          "start-placeholder": "Start Date",
                          "end-placeholder": "End Date",
                          align: "right",
                        },
                        model: {
                          value: _vm.formData.rangeTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "rangeTime", $$v)
                          },
                          expression: "formData.rangeTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn wid200",
                  on: { click: _vm.handleClose },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "comBtn btn-bg0 wid200",
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }