<template>
  <div>
    <stripe-element-payment
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
      :locale="locale"
    />
    <button @click="pay">Submit</button>
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
export default {
  components: {
    StripeElementPayment
  },
  props: ['stripeone'],
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_CLIENT_ID,
      //pk: 'pk_live_51M8znTKO2UI62y8eVg6bn28NVe5K8cE39bmWcRmaDfF4x3mR4Lai2RPPaJHugICm7Ne3okLdR6LdBHy667GioEeh00IgMdtqhu', // 正式
      //pk: 'pk_test_51M8znTKO2UI62y8eFEnZNxvXuP8BhYVh5m3kNELUb1QJSA5f7VnuAFHD8uxLJTvUbLWfjnleRjQOE2FkSmeH62tG00yUdm0e3g', // 测试
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: this.stripeone
      },
      confirmParams: {
        // return_url: 'http://localhost:8080/success', // success url
        return_url: window.location.protocol + '//' + window.location.host + '/Airwallexsuccess'
      },
      locale: 'US'
    }
  },
  mounted() {
    //this.generatePaymentIntent();
  },
  methods: {
    async generatePaymentIntent() {
      const paymentIntent = await apiCallToGeneratePaymentIntent() // this is just a dummy, create your own API call
      this.elementsOptions.clientSecret = paymentIntent.client_secret
    },
    pay() {
      this.$refs.paymentRef.submit()
    }
  }
}
</script>

<style scoped>
button {
  width: 150px;
  height: 40px;
  background: #15104b;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #15104b;
  margin-top: 25px;
  margin-left: 200px;
}
</style>
