<template>
  <el-dialog
    :visible.sync="dialogVisible"
    class="container"
    width="540px"
    title="Your store"
    top="50px"
    :show-close="false">
    <div class="content">
      <div class="line"></div>
      <!-- 用<hr>标签代替el-divider -->
      <div class="scrollbar" style="height: 360px; overflow-y: auto">
        <!-- 自定义滚动条 -->
        <div class="shop-list">
          <div
            v-for="(shop, index) in shoplist"
            :key="index"
            class="shop-item"
            @click="selectShop(shop)">
            <div class="shop-content" :class="{ selected: shop === selectedShop }">
              <div class="shop-image" v-bind:style="{ backgroundImage: 'url(你的图片路径)' }">
                <span>{{ shop.ShopName.substring(0, 2) }}</span>
              </div>
              <div class="shop-info">
                <div class="shop-title">{{ shop.ShopName }}</div>
                <div class="shop-address">{{ shop.ShopId }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <button type="button" class="confirm-button" @click="confirmSelection">Confirm</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      selectedShop: null
    }
  },
  props: ['shoplist'],
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    selectShop(shop) {
      this.selectedShop = shop
    },
    confirmSelection() {
      this.$emit('didShopClick', this.selectedShop)
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  width: 540px;
  height: 526px;
  font-size: 16px;
  font-weight: 400;
}
.container ::v-deep .el-dialog {
  border-radius: 10px; /* 添加圆角 */
}
.container ::v-deep .el-dialog__header {
  height: 54px; /* 设置为你想要的高度 */
}
.container ::v-deep .el-dialog__body {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.content {
  display: flex;
  flex-direction: column;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.scrollbar {
  padding-top: 28px;
  padding-left: 40px;
  padding-right: 40px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
  }
}
.shop-list {
  display: flex;
  flex-direction: column;
}
.shop-item {
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.shop-content {
  padding: 4px;
  width: 100%;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  border: 1px solid transparent; /* 添加默认边框样式 */
}
.selected {
  border-color: #3f6aff; /* 设置选中时的边框颜色 */
  border-radius: 14px;
}
.shop-image {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 14px;
  background-size: cover; /* 确保图片完整覆盖容器 */
  display: flex;
  justify-content: center; /* 文字水平居中 */
  align-items: center; /* 文字垂直居中 */
  background-color: #d9e1ff; /* 图片加载前的背景色，可以根据需要调整 */
  color: #000; /* 字体颜色，根据需要调整 */
  font-weight: bold; /* 加粗显示店铺名的前两个字母 */
  margin-right: 10px; /* 与右侧信息的间距 */
}
.shop-info {
  display: flex;
  flex-direction: column; /* 使shop-title和shop-address垂直排列 */
}
.shop-title {
  width: 360px;
  margin: 2px 0; /* 调整间距 */
  white-space: nowrap; /* 确保文本在同一行显示 */
  overflow: hidden; /* 隐藏超出容器部分的文本 */
  text-overflow: ellipsis; /* 使用省略号表示文本被截断 */
  color: #000;
  font-size: 16px;
}
.shop-address {
  width: 360px;
  margin: 2px 0; /* 调整间距 */
  white-space: nowrap; /* 确保文本在同一行显示 */
  overflow: hidden; /* 隐藏超出容器部分的文本 */
  text-overflow: ellipsis; /* 使用省略号表示文本被截断 */
  font-size: 14px;
  color: #666666;
}
.confirm-button {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #15104b;
  border: none;
  border-radius: 22px;
  cursor: pointer;
}
</style>
