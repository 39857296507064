<template>
  <div class="container">
    <img src="../assets/imgs/loading.gif" alt="" class="loading" />
    <p class="loading_text">Tiktok Store is connecting... {{ step }}/2</p>

    <template v-if="showDialog">
      <TTShopSelectDialog :shoplist="shoplist" @didShopClick="didShopClick"></TTShopSelectDialog>
    </template>
  </div>
</template>
<script>
import { setToken, removeToken } from '@/utils/token'
import { queryTTStoreList, selectedStore } from '@/api/tiktok'
import { setTTShopInfo, clearTTInfo } from '@/utils/tthelper'
import TTShopSelectDialog from '@/components/Tiktok/TTShopSelectDialog.vue' // 根据实际路径调整

export default {
  components: {
    TTShopSelectDialog
  },
  data: () => {
    return {
      step: 1,
      showDialog: false,
      shoplist: []
    }
  },
  created() {
    console.log('🔔 loading... tt')
    // http://localhost:8080/ttloading?app_key=6bttb99siffur&code=GCP_ptxDKgAAAAD7PS_Mo0i25kxfs7GjbQLGVVEBqSwfPf5oEGmZ75P3J4uydeAtFcd1fSI4-lX2KqQj5-ASEgZpOmxibeWjWdQfaWEjKO-BFW5Xa7UqfaXsGmd3dmp14IxXYihkgZRyAzU9VUqI07-he0UWCNIS42qph7KzK2CK-NpS_ep51y0vow

    let that = this
    // 获取授权时获取的参数
    let search = decodeURIComponent(location.search)
    if (search) {
      let params = {}
      search = search.substring(1).split('&')
      search.map((item) => {
        let index = item.indexOf('=')
        let key = item.substring(0, index)
        let value = item.substring(index + 1).split('#')[0]
        params[key] = value
      })
      if (params.code) {
        console.log('🔔step2 query store list')
        that.getStep2(params)
      } else {
        console.log('🔔step1 没有code  params:', params)
      }
    } else {
      console.log('🔔error: no Params')
    }
  },

  mounted() {
    // 自动加载进度
    // this.setTime();
  },
  methods: {
    goHome() {
      window.location.href = `${window.location.origin}/`
    },
    prehander() {
      //为了防止请求带个人信息,去掉登录信息
      removeToken()
    },
    handleShopBinding(res) {
      let that = this
      // 1：店铺绑定过
      const JwtToken = res.Result.JwtToken
      console.log('🔔 ResultType = 1 绑定过,准备进入应用, JwtToken=', JwtToken)
      //店铺绑定过
      setToken(JwtToken)
      setTimeout(() => {
        that.step = 2
        setTimeout(() => {
          window.location.href = `${window.location.origin}/Upgrade`
        }, 850)
      }, 850)
    },
    didShopClick(item) {
      console.log('选中item:', item)

      let that = this

      const params = {
        key: item.ShopKey
      }

      let loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      selectedStore(params)
        .then((res) => {
          loading.close()
          if (res.Success) {
            console.log('🔔step2 query success')
            const ResultType = res.Result.ResultType

            if (ResultType == 0) {
              // 1：店铺未绑定过
              that.handleUnboundShop(res)
            } else if (ResultType == 1) {
              // 1：店铺绑定过
              that.handleShopBinding(res)
            } else if (ResultType == 2) {
              //需要选择店铺绑定
              that.selectShopForBinding(res)
            } else {
              console.log('🔔step2 query fail 001')
            }
          } else {
            this.$message(res.ErrMsg)
            console.log('🔔step2 query fail  002')
          }
        })
        .catch((err) => {
          console.log('选择店铺错误:', err)
        })
    },
    selectShopForBinding(res) {
      this.showDialog = true
      const shops = res.Result.KeyNames
      this.shoplist = shops
    },
    handleUnboundShop(res) {
      let that = this
      // ：店铺未绑定过
      try {
        const ttInfoFirstObj = res.Result.KeyNames[0]
        // 检查ttInfoFirstObj是否存在并且是一个对象
        if (ttInfoFirstObj && typeof ttInfoFirstObj === 'object') {
          setTTShopInfo(ttInfoFirstObj)
          console.log('🔔 ResultType = 0 未绑定过,准备跳转登录,ttsk=', ttInfoFirstObj.ShopKey)
        } else {
          console.log('🔔 ResultType = 0 未绑定过, ttsk获取失败')
        }
        //：店铺未绑定过
        setTimeout(() => {
          window.location.href = `${window.location.origin}`
        }, 1200)
      } catch (error) {
        console.log('🔔 ResultType = 0 error:', error)
      }
    },
    getStep2(params) {
      let that = this
      that.prehander()
      that.step = 2
      let loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      //下面是去注册/登录并且绑定的操作
      queryTTStoreList(params)
        .then((res) => {
          loading.close()
          if (res.Success) {
            console.log('🔔step2 query success')
            const ResultType = res.Result.ResultType

            if (ResultType == 0) {
              // 1：店铺未绑定过
              that.handleUnboundShop(res)
            } else if (ResultType == 1) {
              // 1：店铺绑定过
              that.handleShopBinding(res)
            } else if (ResultType == 2) {
              //需要选择店铺绑定
              that.selectShopForBinding(res)
            } else {
              console.log('🔔step2 query fail 001')
            }
          } else {
            this.$message(res.ErrMsg)
            console.log('🔔step2 query fail  002')
          }
        })
        .catch((err) => {
          console.log('🔔step2 query fail  003')
        })
    },
    setTime() {
      let that = this
      let step = that.step
      if (step < 3) {
        setTimeout(() => {
          step++
          that.step = step
          that.setTime()
        }, 666)
      }
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 393px;
  height: 70px;
  margin-bottom: 40px;
}

.loading_text {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #aaaaaa;
  line-height: 34px;
}
</style>
