<template>
  <div class="offcanvas" :class="[notice ? '' : 'offcanvas-right']">
    <el-tabs v-model="activeName" :class="[theme == 'common' ? '' : 'dark']">
      <el-tab-pane :label="$t('notification.all')" name="all">
        <div class="all">
          <div class="box" v-for="(item, index) in NoticesList" :key="index">
            <div class="dot" v-if="item.IsReaded == true"></div>
            <div class="updated" @click="TileBtn">{{ item.Title }}</div>
            <div class="time" @click="TileBtn">{{ item.PublishTime }}</div>
          </div>
          <div class="notifications"><a href="../ImportantNotices">{{ $t("notification.check") }}</a></div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      activeName: "all",
      NoticesList: [],// 所有公告列表
      dispatch: null
    };
  },
  created(){
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData();
  },
  computed: mapState(["userInfo", "theme"]),
  props: ["notice"],
  methods: {
    // 获取所有公告列表
    getData() {
      this.dispatch('notification/GetTopNo', { num: 2 })
        .then(res => {
          this.NoticesList = res.Result;
        })
        .catch(err => {
          console.log(err, '服务器开小差');
        });
    },
    TileBtn() {
      this.$router.push("/ImportantNotices");
    }
  }
};
</script>
<style scoped>
.offcanvas {
  width: 392px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1001;
  background: #fafafb;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.24);
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
}
.offcanvas-right {
  right: -390px;
}
::v-deep .el-tabs,
::v-deep .el-tab-pane {
  height: 100%;
}
::v-deep .el-tabs__content {
  height: calc(100% - 64px);
}
::v-deep .el-tabs__nav {
  margin-left: 56px;
}
::v-deep .el-tabs__nav-scroll {
  text-align: center;
  line-height: 49px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #d2d9f2;
}
::v-deep .el-tabs__item {
  padding: 0 40px;
  font-size: 20px;
}
/* ::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: #6788ff;
}
::v-deep .el-tabs__active-bar{
  background: #6788ff;
} */
::v-deep .el-tabs__header {
  margin: 0;
  padding-top: 14px;
}
/* first */
.all {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-left: 42px;
  padding-bottom: 100px;
  overflow: auto;
}
.box {
  line-height: 20px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
}
.dot {
  width: 12px;
  height: 12px;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: -8px;
}
.updated {
  font-size: 14px;
  color: #000000;
  margin-bottom: 15px;
  margin-left: 100px;
}
.time {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.notifications {
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 35px;
}
.notifications a {
  color: #6984e8;
}
</style>
