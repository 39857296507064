import request from '@/utils/request'
const PRE_PATH = '/podapi'
//删除素材
export const delPodImage = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: PRE_PATH + '/material/image/del',
    data
  })
//   图片查询
export const getCommonImages = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: PRE_PATH + '/material/image/list/common',
    data
  })
//   我的图片查询
export const getMyImages = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: PRE_PATH + '/material/image/list/private',
    data
  })
//   上传图片素材
export const uploadMyImage = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: PRE_PATH + '/material/image/upload',
    data
  })

// 获取配置设计数据
export const getDesign = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopDesign/getDesign',
    data
  })
// 获取我的设计产品列表
export const getPageDesign = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopDesign/pageMyDesign',
    data
  })
// 详情配置数据
export const getSpu = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopifyPublish/getSpu',
    data
  })
// 详情数据
export const getShopifyConfig = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopifyPublish/getDesignAndConfig',
    data
  })
// 保存配置
export const saveShopifyConfig = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopifyPublish/saveShopifyConfig',
    data
  })

// 删除设计
export const delPodTemplate = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopDesign/del',
    data
  })
// 发布到shopify
export const publishShopify = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopifyPublish/publishToShopify',
    data
  })
// 编辑shopify
export const convertToPodProduct = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopifyPublish/convertToPodProduct',
    data
  })
// 复制设计数据
export const copyDesign = (data) =>
  request({
    method: 'post',
    url: PRE_PATH + '/shopDesign/copyDesign',
    data
  })
///公共素材
export const getClassifyList = () =>
  request({
    method: 'post',
    url: PRE_PATH + '/material/image/classify/list'
  })
