var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sensesConfig?.options?.length > 1
    ? _c(
        "div",
        { staticClass: "select-scene-wrapper" },
        [
          _c(
            "div",
            { staticClass: "select-scene-header" },
            [
              _vm.config.title
                ? _c("span", [_vm._v(_vm._s(_vm.config.title))])
                : _vm._e(),
              _vm.config.toolTip
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: _vm.config.toolTip,
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  )
                : _vm._e(),
              _vm.config.showPopupTip
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "5px", color: "#3f6aff" },
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showFormPopup = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.config.popTitle))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.config.title },
              model: {
                value: _vm.currentSceneId,
                callback: function ($$v) {
                  _vm.currentSceneId = $$v
                },
                expression: "currentSceneId",
              },
            },
            _vm._l(_vm.sensesConfig?.options, function (scene, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: scene.name, value: scene.id },
              })
            }),
            1
          ),
          _vm.config.helpTxt
            ? _c("span", { staticClass: "help-text" }, [
                _vm._v(_vm._s(_vm.config.helpTxt)),
              ])
            : _vm._e(),
          _c("FormPopup", {
            attrs: {
              title: _vm.config.popTitle,
              message: _vm.config.richText,
              width: _vm.config.popWidth,
            },
            model: {
              value: _vm.showFormPopup,
              callback: function ($$v) {
                _vm.showFormPopup = $$v
              },
              expression: "showFormPopup",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }