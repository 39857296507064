/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
export const cardListMixin = {
    data(){
        return {
            cardList: [],
            cardInfo: {
              type: 0,
              page: 1, //当前页
              pageSize: 8,
              total: 0, //总条数
              jumpPage: 1, //跳转页数
            },//爆款列表
            chartInfo:{
                type: 0,
                page: 1, //当前页
                pageSize: 5,
                total: 0, //总条数
                jumpPage: 1, //跳转页数
            },
            chartList: []
        }
    },
    methods:{
        // 卡片数据
        getData() {
            // 获取爆款列表
            let that = this;
            let type = Number(that.type);
            that.dispatch(that.getDataApi, {
                ProductName: that.searchText,
                TimePeriod: type, //0：一个月前，1：一个月内；2：一个星期内
                CurrentPageIndex: that.cardInfo.page,
                PageSize: that.cardInfo.pageSize,
                RecordCount: that.cardList.length,
                OrderByHotLevel: 0,
                ProductSpecial:that.ProductSpecial
              })
              .then((res) => {
                if (res.Success) {
                  // that.$set(that, 'cardList', res.Items)
                    that.cardInfo.total = res.TotalItems
                    that.cardList = res.Items
                    // console.log('mixin', this.cardList);

                }
              })
              .catch((err) => {
                console.log(err);
              });
        },
        // 排行榜
        TheChartsData() {
            let that = this;
            let type = Number(that.type);
            // debugger
            that.dispatch(that.getDataApi, {
                ProductName: '',
                TimePeriod: type, //0：一个月前，1：一个月内；2：一个星期内
                CurrentPageIndex: that.chartInfo.page,
                PageSize: that.chartInfo.pageSize,
                RecordCount: that.chartList.length,
                OrderByHotLevel: 1, // 热度: 1 降序，0默认，-1升序
                ProductSpecial: that.ProductSpecial
              })
              .then((res) => {
                if (res.Success) {
                    that.chartList = res.Items
                    that.cardInfo.total = res.TotalItems
                } else {
                  console.log(err);
                }
              })
              .catch((err) => {
                console.log(err);
              });
        },
    }
}
