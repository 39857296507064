<script>
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import FeedNeedReSendProducts from '@/views/SupportTicket/widgets/FeedNeedReSendProducts.vue'
import { baseApiHost } from '@/utils/env'

//Item damaged 货物破损
export default {
  name: 'FeedTemplate3',
  props: {
    dataSource: Object,
    imgUploadPath: String,
    afterSaleDetail: Object,
    readonly: Boolean
  },
  components: {
    FeedNeedReSendProducts,
    FeedImageUpload
  },
  data() {
    return {
      formModel: {
        TempPath: null,
        IsPic: false,
        fileList: [],
        CausByFunctionalIssues: false,
        VideoLink: null
      },
      defaultFileList: []
    }
  },
  watch: {
    afterSaleDetail: {
      handler: function (newVal) {
        if (newVal) {
          this.formModel.fileList = newVal.FileList || []
          const imageList = []
          this.formModel.fileList.forEach((item) => {
            let url = ''
            if (item.Url.startsWith('/')) {
              url = baseApiHost() + item.Url
            } else {
              url = baseApiHost() + '/' + item.Url
            }
            imageList.push({
              name: item.FileName,
              url: url
            })
          })
          this.defaultFileList = imageList
          this.formModel.VideoLink = newVal.ErrorAttachedData?.VideoLink || ''
          this.formModel.CausByFunctionalIssues =
            newVal.ErrorAttachedData?.CausByFunctionalIssues || false
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    notExcludeOrderDetails: function () {
      return this.dataSource.OrderDetails?.filter((item) => !item.IsExclude)
    }
  },
  methods: {
    getTemplateData() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            let data = {
              ...this.formModel,
              TempPath: this.imgUploadPath,
              IsPic: this.formModel.fileList.length > 0,
              ...this.$refs.needReSendRef.getReturnedSkuMap()
            }
            resolve(data)
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <el-form ref="formRef" label-position="top" :model="formModel" :disabled="readonly">
    <el-form-item>
      <el-radio-group v-model="formModel.CausByFunctionalIssues">
        <el-radio :label="true">
          {{ $t('SupportTicket.CauseByFunctionalIssues') }}
        </el-radio>
        <el-radio :label="false">
          {{ $t('SupportTicket.OtherIssues') }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="$t('SupportTicket.VideoLink')">
      <el-input v-model="formModel.VideoLink"></el-input>
    </el-form-item>
    <el-form-item :label="$t('SupportTicket.ImageUrl')">
      <FeedImageUpload
        v-model="formModel.fileList"
        :img-upload-path="imgUploadPath"
        :show-example="true"
        :default-file-list="defaultFileList"
        :readonly="readonly"
      />
    </el-form-item>
    <el-form-item>
      <FeedNeedReSendProducts
        ref="needReSendRef"
        :order-details="notExcludeOrderDetails"
        :error-sku-list="afterSaleDetail?.ErrorSkuList"
        :readonly="readonly"
      />
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped></style>
