<!--
 * @Author: jiangshiyang
 * @Date: 2024-05-24 09:11:46
 * @LastEditors: jiangshiyang
 * @Description: sourcingURL页面的图片匹配产品组件
-->
<template>
  <div>
    <el-upload
      action=""
      class="sourcing-upload"
      list-type="picture-card"
      :on-remove="handleRemove"
      :http-request="uploadImg"
      accept="image/*"
      v-loading="loading"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <div class="matched-pruducts" v-loading="loading" v-if="searchResultVisible">
      <div class="title">
        <p>
          {{ $t('Sourcing.amp') }}
          <b>
            {{ $t('Sourcing.ampBold') }}
          </b>
        </p>
        <p class="pointer" @click="viewMore" v-if="currentImageUrl">
          {{ $t('productDetails.more') }}
        </p>
      </div>
      <div class="img-list" v-if="matchedProducts.length">
        <div v-for="product in matchedProducts" :key="product.goodsCode" class="img-item">
          <img :src="product.mainImage" alt="" @click="toDetail(product)" />
          <p class="product-name text-ellipsis-2">{{ product.name }}</p>
          <p class="product-price">${{ product.minPrice }}</p>
        </div>
      </div>
      <el-empty :description="$t('Sourcing.NoData')" v-else />
      <div class="tips">
        {{ $t('Sourcing.sourcingImageTips') }}
        <a class="text-blue" href="javascript:void(0)" @click="$emit('showProductNameCard')">
          {{ $t('Sourcing.sourcingClickText') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { imageSearch } from '@/api/proConnect'
import { transProductName } from '@/utils/tools'
import { last, isEmpty } from 'lodash'
import { OSSUploadType, useJSCore } from 'sm-js-core'

export default {
  components: {},
  data() {
    return {
      loading: false,
      searchResultVisible: false,
      matchedProducts: [],
      currentImageUrl: '',
      imageMap: {}
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.searchResultVisible = false
      this.$delete(this.imageMap, file.uid)
      this.currentImageUrl = isEmpty(this.imageMap) ? '' : last(Object.values(this.imageMap))
      this.matchedProducts = []
    },

    viewMore() {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.open(
        `${process.env.VUE_APP_UD_SHOP_HOST}/searchdetail/index?imageId=${this.currentImageUrl}&tk=${tk}`
      )
    },

    toDetail(item) {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.open(`${process.env.VUE_APP_UD_SHOP_HOST}/productDetail/v2/${item.goodsCode}?tk=${tk}`)
    },

    async uploadImg(options) {
      this.loading = true
      try {
        const { url } = await useJSCore().oss.upload(options.file, {
          uploadType: OSSUploadType.SEARCH,
          datePath: false
        })

        this.loading = false
        this.currentImageUrl = url
        this.searchImg(url)
        this.imageMap[options.file.uid] = url
      } catch (error) {
        console.error(error)
        this.$message.error(this.$t('searchdetail.uploadFail'))
        this.loading = false
      }
    },

    searchImg(imageUrl) {
      const postData = {
        pageNum: 1,
        size: 5,
        imageUrl
      }
      this.loading = true
      imageSearch(postData)
        .then((res) => {
          this.loading = false
          const sliceData = res.data?.records?.slice(0, 5) || []
          if (res.success) {
            this.matchedProducts =
              sliceData?.map((item) => ({
                ...item,
                name: transProductName(item)
              })) || []
          }
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.searchResultVisible = true
        })
    }
  },

  watch: {
    currentImageUrl: function (val) {
      this.$emit('updateProductImage', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.sourcing-upload {
  display: flex;
  ::v-deep .el-upload {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 64px;
    height: 64px;
  }
}

.matched-pruducts {
  height: 476px;
  background-color: #eff1f3;
  padding: 24px 40px;
  margin-top: 24px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    p:nth-of-type(1) {
      font-size: 22px;
    }
    p:nth-of-type(2) {
      font-size: 18px;
    }
  }
  .img-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    .img-item {
      width: 210px;
      img {
        width: 210px;
        height: 210px;
        cursor: pointer;
        object-fit: contain;
      }
      p {
        margin-top: 14px;
        width: 220px;
        color: #333;
      }
      .product-price {
        font-size: 22px;
      }
    }
  }
  .tips {
    color: #999;
    font-size: 24px;
    margin-top: 32px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
