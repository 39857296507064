var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("meta", { attrs: { name: "referrer", content: "never" } }),
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "Details-figure" }, [
          _c("div", { staticClass: "focus" }, [
            _vm.productDetail.MainPic
              ? _c(
                  "div",
                  {
                    staticClass: "banner trans",
                    on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
                  },
                  [
                    _c(
                      "vue-photo-zoom-pro",
                      {
                        attrs: {
                          "high-url": _vm.currentUrl,
                          "out-zoomer": true,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.currentUrl },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "indicators" },
              _vm._l(_vm.ThumbPhotos, function (item, index) {
                return _c("img", {
                  key: index,
                  class: {
                    con: index == _vm.imgindex,
                    displayX: !item.isDisplay,
                  },
                  attrs: { src: item.url, alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.select(index)
                    },
                  },
                })
              }),
              0
            ),
            _c("button", { staticClass: "prev", on: { click: _vm.prev } }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576523_right-arrow.png",
                  alt: "",
                },
              }),
            ]),
            _c("button", { staticClass: "next", on: { click: _vm.next } }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576553_left-arrow.png",
                  alt: "",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "synopsis" },
            [
              _c("div", { staticClass: "Product_btn" }, [
                _c(
                  "button",
                  { staticClass: "btn-bg0", on: { click: _vm.OneClickImport } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("SourcingProductDetails.product")) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "intro" }, [
                _vm._v(_vm._s(_vm.productDetail.ProductName)),
              ]),
              _c("div", { staticClass: "referenceWord" }, [
                _vm.productDetail.Quoter == "auto"
                  ? _c("p", [
                      _c(
                        "span",
                        { staticStyle: { color: "red", "ont-size": "22px" } },
                        [_vm._v("*")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("SourcingProductDetails.UseAgent")) +
                          " "
                      ),
                    ])
                  : _c("p", [
                      _c(
                        "span",
                        { staticStyle: { color: "red", "ont-size": "22px" } },
                        [_vm._v("*")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("SourcingProductDetails.Quotation")) +
                          " "
                      ),
                    ]),
              ]),
              [
                _vm.productDetail.QuoteContent[0].NewQuotes != null
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "NewQuotesContent" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              disabled: true,
                            },
                            model: {
                              value: _vm.ProductText,
                              callback: function ($$v) {
                                _vm.ProductText = $$v
                              },
                              expression: "ProductText",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "price" }, [
                          _c("span", { staticClass: "labelname" }, [
                            _vm._v(
                              _vm._s(_vm.$t("SourcingProductDetails.price"))
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-family": "Regular",
                                "font-size": "22px",
                                "margin-left": "10px",
                                "-webkit-font-smoothing": "antialiased",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("SourcingProductDetails.sum")) +
                                  " " +
                                  _vm._s(_vm.Price)
                              ),
                            ]
                          ),
                        ]),
                        !_vm.HasDefault &&
                        _vm.productDetail &&
                        _vm.productDetail.Attributes
                          ? _vm._l(
                              _vm.productDetail.Attributes,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      class: item.IsImgAttr
                                        ? "picture"
                                        : "measure",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "labelname" },
                                            [
                                              _vm._v(
                                                _vm._s(item.AttributesName)
                                              ),
                                            ]
                                          ),
                                          item.IsImgAttr
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "imgas" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.AttrValues[
                                                            item.CurrentIndex
                                                          ]
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      item.IsImgAttr
                                        ? _vm._l(
                                            item.PicList,
                                            function (pic, j) {
                                              return _c(
                                                "el-radio-group",
                                                {
                                                  key: j,
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.radioChange()
                                                    },
                                                  },
                                                  model: {
                                                    value: item.CurrentIndex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "CurrentIndex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.CurrentIndex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    {
                                                      staticClass: "image",
                                                      attrs: {
                                                        plain: "",
                                                        label: j,
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: pic,
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._l(
                                            item.AttrValues,
                                            function (attr, i) {
                                              return _c(
                                                "el-radio-group",
                                                {
                                                  key: i,
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.radioChange()
                                                    },
                                                  },
                                                  model: {
                                                    value: item.CurrentIndex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "CurrentIndex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.CurrentIndex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    {
                                                      staticClass: "s",
                                                      attrs: {
                                                        plain: "",
                                                        label: i,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(attr))]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                ])
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
              ],
              _vm.productDetail.QuoteStatus == 1
                ? _c("div", { staticClass: "SourcingPriceWord" }, [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("SourcingProductDetails.process")) +
                        " "
                    ),
                    _c("p", { staticStyle: { "margin-left": "12px" } }),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "address" },
                [
                  _c("span", { staticClass: "labelname" }, [
                    _vm._v(_vm._s(_vm.$t("SourcingProductDetails.country"))),
                  ]),
                  _vm._l(_vm.ProductCountryCode, function (item, index) {
                    return _c(
                      "el-radio-group",
                      {
                        key: index,
                        on: {
                          change: function ($event) {
                            return _vm.changeCountry(index)
                          },
                        },
                        model: {
                          value: _vm.radio3,
                          callback: function ($$v) {
                            _vm.radio3 = $$v
                          },
                          expression: "radio3",
                        },
                      },
                      [
                        _c("el-radio-button", {
                          staticClass: "sellcountry",
                          attrs: { plain: "", label: item },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("div", [
                _vm.productDetail.HasConfirmSatisfied == false
                  ? _c("div", [
                      _c("div", { staticClass: "Satisfaction" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("SourcingProductDetails.satisfied"))
                          ),
                        ]),
                        _c(
                          "p",
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.QuoteYes(true)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("SourcingProductDetails.Yes"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.QuoteYes(false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("SourcingProductDetails.No"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.productDetail.HasConfirmSatisfied == true
                  ? _c("div")
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _vm.isImg
            ? _c("div", { staticClass: "product-details" }, [
                _c("img", {
                  attrs: {
                    src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591749183_xinbai.jpg",
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "activeContent" }, [
        _c(
          "h3",
          {
            staticStyle: {
              position: "absolute",
              margin: "0px 0px 2px 0rem",
              "font-family": "Regular",
              "-webkit-font-smoothing": "antialiased",
              "line-height": "55px",
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("SourcingProductDetails.Description")) + " "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "storyline",
            class: [_vm.hideWords ? "deschide" : ""],
            attrs: { id: "storyline" },
            domProps: { innerHTML: _vm._s(_vm.productDetail.ProductDesc) },
          },
          [_vm._v(" " + _vm._s(_vm.productDetail.ProductDesc) + " ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }