import {
  GetDetail,
  MemberProductRemove,
  MemberProductEdit
} from '@/api/pending'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetDetailPd({state, commit}, params) {return GetDetail(params).then(res => res)},
    MemberProductRemovePd ({state, commit}, params) {return MemberProductRemove(params).then(res => res)},
    MemberProductEditPd({state, commit}, params) {return MemberProductEdit(params).then(res => res)}
  }

}