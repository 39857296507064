var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.singlePage
        ? _c(
            "div",
            { staticClass: "layout" },
            [
              _c("Slider"),
              _c(
                "div",
                { staticClass: "mainContent" },
                [
                  _c("div", { staticClass: "customer" }, [_c("Customer")], 1),
                  _c("Header"),
                  _c(
                    "div",
                    { staticClass: "container", attrs: { id: "usaContainer" } },
                    [_c("router-view")],
                    1
                  ),
                  _c("Footer"),
                ],
                1
              ),
            ],
            1
          )
        : _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }