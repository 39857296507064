<script>
import FeedHistoryRecord from '@/views/SupportTicket/widgets/FeedHistoryRecord.vue'
import FeedOrderDetail from '@/views/SupportTicket/widgets/FeedOrderDetail.vue'
import FeedTemplate1 from '@/views/SupportTicket/template/FeedTemplate1.vue'
import FeedTemplate2 from '@/views/SupportTicket/template/FeedTemplate2.vue'
import FeedTemplate3 from '@/views/SupportTicket/template/FeedTemplate3.vue'
import FeedTemplate4 from '@/views/SupportTicket/template/FeedTemplate4.vue'
import FeedTemplate5 from '@/views/SupportTicket/template/FeedTemplate5.vue'
import FeedTemplate6 from '@/views/SupportTicket/template/FeedTemplate6.vue'
import { SupportTicketDict } from '@/views/SupportTicket/dict'
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import { GetOrderAndAddressAndDetail, queryAfterSaleDetail, SaleProblemsByCustomer } from '@/api'
import { GetCountryList } from '@/api/order'

export default {
  name: 'FileQuestion',
  components: {
    FeedImageUpload,
    FeedOrderDetail,
    FeedHistoryRecord,
    FeedTemplate1,
    FeedTemplate2,
    FeedTemplate3,
    FeedTemplate4,
    FeedTemplate5,
    FeedTemplate6
  },
  data() {
    return {
      buttonLoading: false,
      afterProblemType: SupportTicketDict.afterProblemType,
      afterDealType: SupportTicketDict.afterDealType,
      currentAfterDealType: [],
      currentTemplate: 'FeedTemplate1',
      showErrorProduct: false,
      countryList: [],
      formModel: {
        //1:Confirm/Reply 3:AgreeSolution 0: NoNeedProcess 驳回,不用处理
        Type: 1,
        CustomerOrderId: this.$route.query.customerOrderId || 0,
        OrderId: this.$route.query.orderId,
        OrderNo: null,
        SalesRecord: null,
        ProblemTypeCode: null,
        DealType: null,
        Description: null,
        Reply: null,
        ContactPostReason: null,
        TempPath: `Upload/Images/TempPath/orderProblem/${
          this.$store.state.member.memberInfo?.MemberId
        }/${new Date().getTime()}`
      },
      rules: {
        ProblemTypeCode: [
          {
            required: true,
            message: 'Problem type can not be empty',
            trigger: 'change'
          }
        ],
        DealType: [
          {
            required: true,
            message: 'Deal type can not be empty',
            trigger: 'change'
          }
        ],
        Description: [
          {
            required: true,
            message: 'Please input Problem Description',
            trigger: 'blur'
          }
        ],
        Reply: [
          {
            required: true,
            message: 'Please input Reply',
            trigger: 'blur'
          }
        ],
        ContactPostReason: [
          {
            required: true,
            message: 'Please input Reason For Return By Post Office',
            trigger: 'blur'
          }
        ]
      },
      dataSource: {
        Address: {}
      },
      afterSaleDetail: {}
    }
  },
  watch: {
    'formModel.ProblemTypeCode': function (newValue) {
      if ([9, 10, 11, 13].includes(newValue)) {
        this.currentAfterDealType = this.afterDealType.slice(0, 2)
      } else {
        this.currentAfterDealType = this.afterDealType
      }
      if (!this.formModel.CustomerOrderId) {
        this.formModel.DealType = null
      }
      //是补发问题并且几种特殊的问题类型要展示地址信息
      let isReplacement = this.formModel.DealType === 2 && [2, 3, 5, 6, 7].includes(newValue)
      if (newValue === 0 || isReplacement) {
        this.currentTemplate = 'FeedTemplate2'
        this.showErrorProduct = newValue === 0
      } else if (newValue === 9) {
        this.currentTemplate = 'FeedTemplate3'
      } else if (newValue === 10) {
        this.currentTemplate = 'FeedTemplate4'
      } else if (newValue === 11) {
        this.currentTemplate = 'FeedTemplate5'
      } else if (newValue === 13) {
        this.currentTemplate = 'FeedTemplate6'
      } else {
        this.currentTemplate = 'FeedTemplate1'
      }
    },
    'formModel.DealType': function (newValue) {
      if (newValue) {
        //是补发问题并且几种特殊的问题类型要展示地址信息
        let isReplacement =
          newValue === 2 && [2, 3, 5, 6, 7].includes(this.formModel.ProblemTypeCode)
        if (isReplacement) {
          this.currentTemplate = 'FeedTemplate2'
          this.showErrorProduct = false
        } else {
          if (this.currentTemplate === 'FeedTemplate2') {
            this.currentTemplate = 'FeedTemplate1'
          }
        }
      }
    }
  },
  mounted() {
    GetCountryList().then((res) => {
      if (res.Result?.length > 0) {
        this.countryList = res.Result.map((item) => {
          return {
            label: item.EnglishName,
            value: item.DoubleCharacterCode
          }
        })
      }
    })
    let loading = this.$loading({
      lock: true,
      spinner: 'el-icon-loading',
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
      target: '.mainContent'
    })
    GetOrderAndAddressAndDetail(this.formModel.OrderId)
      .then((res) => {
        if (res.Success) {
          this.dataSource = res.Result || {}
          this.formModel.OrderNo = this.dataSource.OrderNo
          this.formModel.SalesRecord = this.dataSource.SalesRecord
          this.dataSource.Address = {
            Id: this.dataSource.AddressId,
            Contact: this.dataSource.Contact,
            Email: this.dataSource.Email,
            MobilePhone: this.dataSource.MobilePhone,
            TelePhone: this.dataSource.TelePhone,
            Company: this.dataSource.Company,
            Country: this.dataSource.CountryCode,
            Province: this.dataSource.Province,
            City: this.dataSource.City,
            Area: this.dataSource.Area,
            Address: this.dataSource.Address,
            Address2: this.dataSource.Address2,
            ZipCode: this.dataSource.ZipCode,
            HouseNumber: this.dataSource.HouseNumber,
            VatNumber: this.dataSource.VatNumber,
            CountryName: this.dataSource.CountryName,
            CountryNameEn: this.dataSource.CountryNameEn,
            FullAddress: this.dataSource.FullAddress
          }
          this.requestAfterSaleDetail()
        }
      })
      .finally(() => {
        loading.close()
      })
  },
  methods: {
    requestAfterSaleDetail() {
      if (this.formModel.CustomerOrderId) {
        queryAfterSaleDetail({
          customerOrderId: this.formModel.CustomerOrderId
        }).then((res) => {
          if (res.Success) {
            this.afterSaleDetail = {
              ...res.Result
            }
            this.formModel.ProblemTypeCode = this.afterSaleDetail.ProblemTypeCode
            this.formModel.DealType = this.afterSaleDetail.DealType
            this.formModel.Description = this.afterSaleDetail.Description
            this.formModel.Reply = this.afterSaleDetail?.LastProcessResult?.Reply
            this.formModel.ContactPostReason =
              this.afterSaleDetail?.ContactAttachedData?.ContactPostReason
          }
        })
      }
    },
    onConfirm() {
      this.$refs.submitFormRef?.validate((valid) => {
        if (valid) {
          this.$refs.templateRef.getTemplateData().then((data) => {
            let params = {
              ...this.formModel,
              ...data
            }
            this.buttonLoading = true
            SaleProblemsByCustomer(params)
              .then((res) => {
                if (res.Success) {
                  this.$router.replace({
                    name: 'SupportTicket'
                  })
                } else {
                  this.$message.error(res?.ErrMsg || 'Error')
                }
              })
              .finally(() => {
                this.buttonLoading = false
              })
          })
        } else {
          this.$message.error('Fill in all required fields')
          return false
        }
      })
    },
    onNoNeedProcess() {
      this.buttonLoading = true
      let params = {
        ...this.afterSaleDetail,
        ...this.formModel,
        ...this.afterSaleDetail.ReturnAttachedData,
        Type: 0
      }
      SaleProblemsByCustomer(params)
        .then((res) => {
          if (res.Success) {
            this.$router.replace({
              name: 'SupportTicket'
            })
          } else {
            this.$message.error(res?.ErrMsg || 'Error')
          }
        })
        .finally(() => {
          this.buttonLoading = false
        })
    }
  }
}
</script>

<template>
  <div class="particulars">
    <div class="activeName">
      <div class="header">
        <span class="title">{{ $t('SupportTicket.Feedback') }}</span>
      </div>
      <div class="container-layout">
        <el-form ref="submitFormRef" label-position="top" :model="formModel" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item style="margin-bottom: 0" :label="$t('SupportTicket.OrderNumber')">
                <el-input v-model="formModel.SalesRecord" disabled></el-input>
                <span class="tracking-number"
                  >{{ $t('SupportTicket.TrackingNumber') }} :&nbsp;
                  {{ dataSource?.LogisticTransitNo || '-' }}
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('SupportTicket.QuestionType')" prop="ProblemTypeCode">
                <el-select
                  style="width: 100%"
                  v-model="formModel.ProblemTypeCode"
                  placeholder="--Filter By Type Of Problem--"
                  filterable
                  clearable
                  :disabled="formModel.CustomerOrderId != 0"
                >
                  <el-option
                    v-for="item in afterProblemType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            :label="$t('SupportTicket.DealType')"
            prop="DealType"
            v-if="formModel.ProblemTypeCode && ![0, 1].includes(formModel.ProblemTypeCode)"
          >
            <el-select
              style="width: calc(50% - 10px)"
              v-model="formModel.DealType"
              placeholder="--Select The Appropriate--"
              clearable
            >
              <el-option
                v-for="item in currentAfterDealType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('SupportTicket.ProblemDescription')" prop="Description">
            <el-input
              v-model="formModel.Description"
              type="textarea"
              :rows="2"
              :disabled="
                formModel.CustomerOrderId != 0 && !afterSaleDetail?.LastProcessResult?.ReplyIsNull
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="[5, 7].includes(formModel.ProblemTypeCode) && [1, 2].includes(formModel.DealType)"
            :label="$t('SupportTicket.ContactPostReason')"
            prop="ContactPostReason"
          >
            <el-input v-model="formModel.ContactPostReason" type="textarea" :rows="2"></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              formModel.CustomerOrderId != 0 && !afterSaleDetail?.LastProcessResult?.ReplyIsNull
            "
            :label="$t('SupportTicket.Reply')"
            prop="Reply"
          >
            <el-input v-model="formModel.Reply" type="textarea" :rows="2"></el-input>
          </el-form-item>
          <keep-alive>
            <component
              ref="templateRef"
              :is="currentTemplate"
              :data-source="dataSource"
              :show-error-product="showErrorProduct"
              :img-upload-path="formModel.TempPath"
              :country-list="countryList"
              :after-sale-detail="afterSaleDetail"
            />
          </keep-alive>
        </el-form>
        <FeedOrderDetail ref="orderDetailRef" :data-source="dataSource">
          <template #header_extra>
            <el-button
              size="small"
              round
              type="primary"
              :loading="buttonLoading"
              @click="onConfirm"
            >
              Confirm
            </el-button>
            <el-button
              v-if="formModel.CustomerOrderId != 0"
              size="small"
              round
              plain
              :loading="buttonLoading"
              @click="onNoNeedProcess"
            >
              {{ $t('SupportTicket.NoNeedProcess') }}
            </el-button>
          </template>
        </FeedOrderDetail>
        <FeedHistoryRecord :order-id="formModel.OrderId" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.particulars {
  width: 100%;
  height: auto;
  background: #eff1f3;
  padding-bottom: 32px;
}

.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  background: white;

  .header {
    display: flex;
    min-height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #d9d9d9;

    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      color: #15104b;
    }
  }

  .container-layout {
    padding: 16px 40px;

    .tracking-number {
      color: #666;
      font-size: 13px;
    }

    :deep(.el-form-item) {
      margin-bottom: 10px;
    }

    :deep(.el-input__inner) {
      border-radius: 37px;
      //background: #f1f2f4;
      //border: none;
    }

    :deep(.el-textarea__inner) {
      border-radius: 10px;
      //background: #f1f2f4;
      //border: none;
    }

    :deep(.el-input--suffix) {
      border-radius: 37px;
      //background: #f1f2f4;
    }
  }
}

:deep(.el-dialog) {
  border-radius: 20px;
}

:deep(.el-input.is-disabled .el-input__inner) {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}

:deep(.el-textarea.is-disabled .el-textarea__inner) {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}
</style>
