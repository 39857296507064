var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { position: "relative", "z-index": "0" },
      attrs: { id: _vm.boxId },
    },
    [_c("div", { ref: "editor", staticClass: "text" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }