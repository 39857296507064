var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-left" }, [
    _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: { src: _vm.logoImg[_vm.formLink], alt: "" },
      }),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "welcome-title" }, [
      _c(
        "div",
        {
          staticClass: "ft-56 fw-800 color2",
          staticStyle: { "margin-top": "50px" },
        },
        [_vm._v("Welcome to")]
      ),
      _c("div", { staticClass: "ft-56 fw-800 color1 mb-24" }, [
        _vm._v("USAdrop Ads Agency"),
      ]),
      _c("div", { staticClass: "color2 fw-500 mb-24 ft-20" }, [
        _vm._v("USAdrop Facebook Ads Account"),
      ]),
      _c("div", { staticClass: "ft-56 fw-800 color2 mb-48" }, [
        _vm._v("USAdrop Ads-agency Service"),
      ]),
      _c("div", { staticClass: "btnContent" }, [
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009301_icon1.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("Up to "),
            _c("span", { staticClass: "color1" }, [_vm._v("5%")]),
            _vm._v(" Cash Back"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009336_icon2.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _c("span", { staticClass: "color1" }, [_vm._v("0%")]),
            _vm._v(" Charging Free"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009409_icon3.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("Stable Ads Account"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009445_icon4.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("No Spending Limits"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }