<template>
  <div class="select-scene-wrapper" v-if="sensesConfig?.options?.length > 1">
    <div class="select-scene-header">
      <span v-if="config.title">{{ config.title }}</span>
      <el-tooltip v-if="config.toolTip" effect="dark" :content="config.toolTip" placement="top">
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-button
        style="margin-left: 5px; color: #3f6aff"
        v-if="config.showPopupTip"
        type="text"
        size="small"
        @click="showFormPopup = true"
        >{{ config.popTitle }}</el-button
      >
    </div>
    <el-select v-model="currentSceneId" :placeholder="config.title">
      <el-option
        v-for="(scene, index) in sensesConfig?.options"
        :key="index"
        :label="scene.name"
        :value="scene.id"
      >
      </el-option>
    </el-select>
    <span v-if="config.helpTxt" class="help-text">{{ config.helpTxt }}</span>
    <FormPopup
      v-model="showFormPopup"
      :title="config.popTitle"
      :message="config.richText"
      :width="config.popWidth"
    />
  </div>
</template>
<script>
import FormPopup from '@/views/PodPage/components/widgets/FormPopop.vue'

export default {
  name: 'SceneSelect',
  components: { FormPopup },
  props: {
    value: {
      type: String,
      default: null
    },
    sensesConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentSceneId: null,
      showFormPopup: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentSceneId = newValue
      }
    },
    currentSceneId(newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    config() {
      return this.sensesConfig?.config || {}
    }
  }
}
</script>

<style scoped lang="scss">
.select-scene-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .select-scene-header {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .help-text {
    font-size: 12px;
    color: #666;
    padding-top: 5px;
  }
}
</style>
