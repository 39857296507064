import {
  GetDetail
} from '@/api/quotedInfo'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetDetailQi({state, commit}, params) {return GetDetail(params).then(res => res)},
  }

}