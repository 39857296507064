import request from '@/utils/request';

export const LhHelpMessage = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhHelpMessage/Add',
    data
  });
  export const GetHelpCenterCatalogs = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Catalog/GetHelpCenterCatalogs',
    data
  });
  export const GetHelpInfoById = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhHelpInfo/GetHelpInfoById',
    data
  });
  export const GetHelpInfos = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhHelpInfo/GetHelpInfos',
    data
  });