var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPreviewDialog
    ? _c(
        "div",
        {
          staticClass: "image-viewer__wrapper",
          on: {
            click: function ($event) {
              _vm.showPreviewDialog = false
            },
          },
        },
        [
          _c("div", { staticClass: "image-viewer__canvas" }, [
            _c("img", {
              style: {
                transform:
                  "rotate(" +
                  _vm.rotation +
                  "deg) scale(" +
                  _vm.zoomFactor +
                  ")",
                transition: "transform 0.3s ease",
                "max-height": "100%",
                "max-width": "100%",
              },
              attrs: { src: _vm.imageList[_vm.currentIndex], alt: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "image-viewer__btn image-viewer__close",
              on: {
                click: function ($event) {
                  _vm.showPreviewDialog = false
                },
              },
            },
            [_c("i", { staticClass: "el-icon-close" })]
          ),
          _c(
            "div",
            {
              staticClass: "image-viewer__btn image-viewer__actions",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "image-viewer__actions__inner" }, [
                _c("i", {
                  staticClass: "el-icon-zoom-out",
                  on: { click: _vm.zoomOut },
                }),
                _c("i", {
                  staticClass: "el-icon-zoom-in",
                  on: { click: _vm.zoomIn },
                }),
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: { click: _vm.resetZoom },
                }),
                _c("i", {
                  staticClass: "el-icon-refresh-left",
                  on: { click: _vm.rotateLeft },
                }),
                _c("i", {
                  staticClass: "el-icon-refresh-right",
                  on: { click: _vm.rotateRight },
                }),
              ]),
            ]
          ),
          _vm.imageList.length > 1
            ? _c(
                "div",
                {
                  staticClass: "image-viewer__btn image-viewer__prev",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleViewerPrev.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-arrow-left" })]
              )
            : _vm._e(),
          _vm.imageList.length > 1
            ? _c(
                "div",
                {
                  staticClass: "image-viewer__btn image-viewer__next",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleViewerNext.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-arrow-right" })]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }