<template>
  <div class="ad-login">
    <div class="login-info">
      <img
        src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580690869_logo.svg"
        alt=""
        class="logo1" />
      <div class="welcome-title">
        <div>
          <span class="ft-56 fw-800 color2">Welcome to </span>
          <span class="ft-56 fw-800 color1 mb-24">Co-Founder</span>
        </div>
        <div class="Investment color2 fw-500 mb-24 ft-20">0 Capital Investment</div>
        <div class="ft-56 fw-800 color2 mb-48">USAdrop Co-Founder Service</div>
        <div class="ft-40 fw-600 color2 mb-48">PERMANENT 30% PROFIT SHARE</div>
        <div class="ft-20 fw-600 color2">COOPERATION MODE:</div>
        <div class="DropshippinglPaymentl">
          <p class="color2"></p>
          <p class="ft-20 fw-600 color2">Dropshipping lPaymentl</p>
        </div>
        <div class="DropshippinglPaymentl">
          <p class="color2"></p>
          <p class="ft-20 fw-600 color2">Up To 5% Ads Cash Back</p>
        </div>
        <div class="DropshippinglPaymentl">
          <p class="color2"></p>
          <p class="ft-20 fw-600 color2">QuickFund For Your Dropshipping Business</p>
        </div>
      </div>
    </div>
    <div class="login-content">
      <img
        src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580690869_logo.svg"
        alt=""
        class="logo2" />
      <p class="login-head">Welcome to Co-Founder</p>
      <el-form label-position="top" :rules="rules" :model="formData" ref="formRef">
        <el-form-item label="E-mail" class="option" prop="username">
          <el-input v-model="formData.username" />
        </el-form-item>
        <el-form-item label="Password" class="option" prop="password">
          <el-input v-model="formData.password" :show-password="true" />
        </el-form-item>
        <div class="login-bottom">
          <div class="btns">
            <el-button @click="signIn('formRef')">Sign In</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: 'Field  cannot be empty ', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please enter the correct Email address',
            trigger: ['blur', 'change']
          }
        ],
        password: [{ required: true, message: 'Field  cannot be empty', trigger: 'blur' }]
      }
    }
  },
  methods: {
    signIn(formName) {
      const {
        dispatch,
        state: { storeKey }
      } = this.$store
      let data = { email: this.formData.username, pwd: this.formData.password }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          dispatch('login/getLogin', data).then((res) => {
            const { Success } = res || {}
            if (Success) {
              window.location.href = `${process.env.VUE_APP_FOUNDER_HOST}/home/index?tk=${res.Result.Token}`
            } else {
              this.$message(res.ErrMsg || vm.$t('tips.failed'))
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580786555_loginBg.png')
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .Investment {
    margin-top: 20px;
  }
  .DropshippinglPaymentl {
    display: flex;
    margin-top: 10px;
    p:nth-child(1) {
      width: 7px;
      height: 7px;
      background: #15104b;
      border-radius: 50%;
      margin-top: 12px;
    }
    p:nth-child(2) {
      margin-left: 10px;
    }
  }
  .login-info {
    margin-right: 80px;
    text-align: left;
    margin-top: -6%;
    .logo1 {
      width: 215px;
      height: 128px;
      margin-bottom: 40px;
    }
    .btnContent {
      display: flex;
      width: 620px;
      flex-wrap: wrap;
      justify-content: space-between;
      .btnInfo {
        background: #e0e3f2;
        box-shadow: 2px 3px 4px 0px #231d6540;
        width: 260px;
        border-radius: 16px;
        margin-bottom: 40px;
        font-size: 16px;
        color: $color2;
        display: flex;
        align-items: center;
        padding: 8px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        .text {
          padding-left: 8px;
          color: $color2;
          font-weight: 600;
          border-left: 1.5px solid $color2;
        }
      }
    }
  }
  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .logo2 {
      width: 90px;
      height: 64px;
      position: absolute;
      left: 40px;
      margin-bottom: 30px;
    }
    .login-head {
      font-size: 26px;
      color: #000000;
      font-weight: 500;
      margin-top: 104px;
      text-align: center;
      margin-bottom: 40px;
    }
    .forget {
      width: 100%;
      text-align: right;
      position: absolute;
      top: 44px;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        border-radius: 50px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
      }
    }
    .login-bottom {
      margin-top: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .el-button {
        width: 226px;
        height: 44px;
        background: $color2;
        font-size: 16px;
        margin-bottom: 8px;
        color: #ffffff;
        border-radius: 30px;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
    }
    .demo-ruleForm {
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>
