<!--
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
-->
<!--
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
-->
<!--
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
-->
<template>
  <div class="page">
    <div class="content">
      <!-- 发送验证码 -->
      <div class="reset">
        <!-- <div class="resetpasswords"> -->
          <div class="registration">
            <img src="../../assets/imgs/HTSCIT.png" alt="">
          </div>
          <div class="col-col0">{{ $t("password.AnEmail") }}</div>
          <div class="params">{{params}}</div>
          <button class="comBtn btn-bg0" @click="confirm">{{ $t("password.Confirm") }}</button>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      name: "verificationCode",
      params: '',
    };
  },
  created() {
   let that = this;
   that.params = that.$route.params.value;
  },
  methods: {
    confirm() {
     this.$router.push("/Login");
    }
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  background: #15104B;
  padding-top: 280px;
  .reset {
    position: relative;
    margin: 0 auto;
    width: 840px;
    height: 374px;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 0px;
    font-size: 20px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    .registration {
      img{
        width: 76px;
        height: 76px;
        margin-bottom: 45px;

      }
    }
    button {
      width: 200px;
      height: 44px; 
      margin-top: 50px;
      font-size: 16px;
    }
   
  }
}
</style>
