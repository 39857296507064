<template>
  <div class="particulars souring-url">
    <div class="active-name">
      <div class="sourcing">{{ $t('SourcingUrl.aliexpress') }}</div>
      <div class="speed-progress" v-if="Fulfilled">
        <div class="progress-fulfilled">
          <span>{{ $t('SourcingUrl.ProductsSourced') }}</span>
          <span>{{ MembershipInterests.UsedTimes }}</span>
          <span>/</span>
          <span>{{ MembershipInterests.TotalTimes }}</span>
        </div>
        <el-progress
          :percentage="pmgressbar"
          style="width: 130px; margin-top: 8px"
          :show-text="false"
        ></el-progress>
        <div class="monthly-red" @click="monthlyRed()">
          <span v-if="paymentList.GradeId === 1">
            <img src="../../assets/imgs/NewLogo/MemberJICHUSlice.png" alt="" />
          </span>
          <span v-if="paymentList.GradeId === 2">
            <img src="../../assets/imgs/NewLogo/MemberVIPSlice16.png" alt="" />
          </span>
          <span v-if="paymentList.GradeId === 3">
            <img src="../../assets/imgs/NewLogo/MemberSVIPSlice16.png" alt="" />
          </span>
        </div>
        <div
          class="icon-question"
          v-if="MembershipInterests.DayLimit && MembershipInterests.DayRemainTimes === 0"
        >
          <i class="el-icon-question" @mouseenter="enter" @mouseleave="leave"></i>
        </div>
        <div class="display" v-if="displayUSAdropInfo">
          <p>{{ $t('SourcingUrl.HaveToday') }}</p>
          <p>{{ $t('SourcingUrl.SalesmanAgent') }}</p>
        </div>
      </div>
      <div class="progress">
        <div class="progress-item">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365064_Frame%20427319476%402x.png"
            alt=""
          />
          <p>{{ $t('SourcingUrl.EnterPurchasingInfomation') }}</p>
        </div>
        <div class="progress-line">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716446219509_Frame%20427319475%402x.png"
            alt=""
          />
        </div>
        <div class="progress-item">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365159_Frame%20427319477%402x.png"
            alt=""
          />
          <p>{{ $t('SourcingUrl.USAdropQuotation') }}</p>
        </div>
        <div class="progress-line">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716446219509_Frame%20427319475%402x.png"
            alt=""
          />
        </div>
        <div class="progress-item">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365202_Frame%20427319478%402x.png"
            alt=""
          />
          <p>{{ $t('SourcingUrl.ViewQuote') }}</p>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item-title">
          <span class="required">*</span>
          <span>{{ $t('SourcingUrl.productLinks') }}</span>
        </div>
        <div class="form-item-content">
          <el-input
            type="text"
            :placeholder="$t('SourcingUrl.product')"
            @input="setSearchUrl"
            v-model="searchUrl"
            maxlength="1000"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-title">
          <span class="required">*</span>
          <span>{{ $t('SourcingUrl.productImage') }}</span>
          <span class="subtitle">{{ $t('SourcingUrl.productImageDesc') }}</span>
        </div>
        <div class="form-item-content">
          <SourcingURLImage
            @updateProductImage="updateProductImage"
            @showProductNameCard="showProductNameCard"
          />
        </div>
      </div>
      <transition name="fade">
        <div class="product-name-card" v-show="showProductName">
          <div class="form-item">
            <div class="form-item-title">
              <!-- <span class="required">*</span> -->
              <span>{{ $t('SourcingUrl.productName') }}</span>
            </div>
            <div class="form-item-content">
              <el-input
                type="text"
                :placeholder="$t('SourcingUrl.placeholderName')"
                v-model="productTitle"
                maxlength="500"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              <!-- <span class="required">*</span> -->
              <span>{{ $t('SourcingUrl.describe') }}</span>
            </div>
            <div class="form-item-content">
              <el-input type="textarea" :rows="3" v-model="describe" maxlength="500" />
            </div>
          </div>
          <div slot="footer" class="dialog_footer">
            <el-button class="save" type="primary" @click.stop="save">
              {{ $t('SourcingUrl.Confirm') }}
            </el-button>
          </div>
        </div>
      </transition>
    </div>
    <memberPop :visible="memberPopupVisible" />
    <!-- 联系业务员 -->
    <agentPop :visible.sync="agentVisible" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { sourcingAdd } from '@/api/souring'
import memberPop from './components/memberPop.vue'
import agentPop from './components/agentPop.vue'
import SourcingURLImage from './components/SourcingURLImage.vue'

export default {
  name: 'sourcingURL',
  data: () => ({
    dispatch: null,
    page: 'sourcing',
    searchUrl: '', // 搜索链接
    ComparePrice: false,
    MembershipInterests: [],
    memberPopupVisible: false,
    Fulfilled: true,
    pmgressbar: 0,
    paymentList: [],
    displayUSAdropInfo: false,
    agentVisible: false,
    productTitle: '',
    productImage: '',
    describe: '',
    showProductName: false
  }),
  components: { memberPop, agentPop, SourcingURLImage },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.globalClick(this.CloseBtn)
    // this.getData()
    this.membership()
    this.menterData()
    const _self = this
    document.onkeydown = function () {
      const key = window.event.keyCode
      if (key === 13) {
        _self.save()
        /* console.log(e, '123') */
      }
    }
  },
  computed: mapState(['userInfo', 'theme']),
  methods: {
    enter() {
      this.displayUSAdropInfo = true
    },
    leave() {
      this.displayUSAdropInfo = false
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          // 价格重置
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    monthlyRed() {
      this.$router.push('/Upgrade')
    },

    async membership() {
      const that = this
      that
        .dispatch('memberRights/GetRightsWithSourcingMr')
        .then((res) => {
          that.MembershipInterests = res.Result
          // eslint-disable-next-line operator-linebreak
          that.pmgressbar =
            (that.MembershipInterests.UsedTimes / that.MembershipInterests.TotalTimes) * 100
          if (this.MembershipInterests.TotalTimes === '-1') {
            this.Fulfilled = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    setSearchUrl() {
      // eslint-disable-next-line prefer-destructuring
      this.searchUrl = this.searchUrl.split('?')[0]
    },

    checkURL(url) {
      const pattern = /^https?:\/\//i
      return pattern.test(url)
    },

    validate() {
      // 产品链接，图片，标题都是必填
      if (!this.searchUrl || !this.productImage) {
        return {
          result: false,
          message: this.$t('SourcingUrl.productInfoRequired')
        }
      }
      if (!this.checkURL(this.searchUrl)) {
        return {
          result: false,
          message: this.$t('SourcingUrl.productLinkHttp')
        }
      }
      return {
        result: true,
        message: 'success'
      }
    },

    updateProductImage(imgUrl) {
      this.productImage = imgUrl
    },

    // 添加按钮
    async save() {
      const query = this.$route.query
      const { from = 1 } = query
      const validateRes = this.validate()
      if (!validateRes.result) {
        this.$message.error(validateRes.message)
        return false
      }
      await this.membership()
      if (this.MembershipInterests.Enabled === true) {
        const that = this
        const loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        sourcingAdd({
          ProductLink: this.searchUrl,
          ProductTitle: this.productTitle,
          ProductDescribe: this.describe,
          ProductImage: this.productImage,
          SourcingSource: from ?? '1'
        })
          .then((res) => {
            loading.close()
            if (res?.Success) {
              this.$message.success(this.$t('SourcingUrl.successfully'))
              that.searchUrl = ''
              this.$router.push('/Sourcing')
            } else {
              this.$message.error(this.$t('SourcingUrl.TheLinkFailed'))
            }
          })
          .catch(() => {
            this.$message.error(this.$t('SourcingUrl.failed'))
          })
        /* this.memberPopupVisible = false */
      } else if (this.MembershipInterests.RemainTimes === 0) {
        this.memberPopupVisible = true
      } else {
        this.agentVisible = true
      }
    },

    showProductNameCard() {
      this.showProductName = true
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-question {
  position: absolute;
  left: 242px;
  top: 0;
}
::v-deep .icon-question .el-icon-question {
  font-size: 20px;
  color: #3f6aff;
}

.display-info {
  width: 360px;
  height: 55px;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  left: 265px;
  top: -10px;
}
.display-info p:nth-child(1) {
  font-size: 14px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
  padding-left: 10px;
}
.display-info p:nth-child(2) {
  font-size: 14px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
}

.monthly-red {
  position: absolute;
  top: -4px;
  left: 218px;
  cursor: pointer;
}
.monthly-red span img {
  width: 22px;
  height: 22px;
}
.monthly-red span img:hover {
  transform: scale(1.1);
}

.progress {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px 100px;
  img {
    width: 56px;
  }
  .progress-item {
    min-width: 200px;
    text-align: center;
    p {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .progress-line {
    margin: 0 -20px;
    flex-shrink: 1;
    img {
      width: 300px;
      position: relative;
      top: -18px;
    }
  }
}

.form-item {
  padding: 0 40px;
  margin: 12px 0;
  .form-item-title {
    display: flex;
    align-items: flex-start;
    font-family: 'Regular';
    font-size: 14px;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    .subtitle {
      color: #999999;
      padding-left: 16px;
    }
  }
  .form-item-content {
    ::v-deep input {
      height: 40px;
      width: 800px;
      border: 1px solid #d9d9d9;
      border-radius: 24px;
      padding: 0 20px;
      -webkit-font-smoothing: antialiased;
    }
    ::v-deep .el-textarea__inner {
      border: 1px solid #d9d9d9;
      border-radius: 10px;
    }
  }
}
::v-deep .el-input--suffix input {
  border: none;
  background: none;
  display: block;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dialog_footer {
  margin-top: 64px;
  text-align: center;
}

.save {
  width: 200px;
  height: 44px;
  border: none;
  border-radius: 50px !important;
  font-size: 16px !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #474882;
}
.save:hover {
  background: #3c3772 !important;
  color: #fff;
}
</style>

<style lang="scss" scoped>
.required {
  color: #ff0000;
  margin-right: 2px;
}
@mixin inputS {
  width: 511px;
  margin-left: 40px;
  margin-top: 20px;
  background: #f9fafb;
  border: 1px solid #d7d7d8;
  border-radius: 50px;
  overflow: hidden;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.souring-url {
  width: 100%;
  min-height: calc(100vh - 80px);
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  font-family: PingFang SC-Regular, PingFang SC;
  .active-name {
    width: 1320px;
    padding-bottom: 60px;
    box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
    border-radius: 10px;
    position: relative;
    font-family: Poppins-Regular, Poppins;
    color: #333333;
    background: #ffffff;
    .speed-progress {
      width: 240px;
      height: 60px;
      position: absolute;
      top: 27px;
      left: 200px;
      font-size: 14px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .progress-fulfilled {
        span {
          // &:nth-child(1) {
          //   margin-right: 10px;
          // }
          &:nth-child(2) {
            margin-left: 20px;
          }
          &:nth-child(3) {
            margin-left: 5px;
          }
          &:nth-child(4) {
            margin-left: 5px;
          }
        }
      }
    }
    .sourcing {
      width: 100%;
      height: 80px;
      line-height: 80px;
      background: #f9f9f9;
      font-size: 16px;
      padding-left: 50px;
      font-family: 'Regular';
    }
  }

  /* 定义fade动画 */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .fade-enter-to, .fade-leave /* .fade-leave-active in <2.1.8 */ {
    opacity: 1;
  }
}
</style>
