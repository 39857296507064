var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars offlineOrder",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "ContentBigContent" }, [
          _c("div", { staticClass: "HeaderTop" }, [
            _c("div", { staticClass: "BaseInfoWord" }, [
              _vm._v(_vm._s(_vm.$t("OfflineOrder.BaseInfo"))),
            ]),
            _c("div", { staticClass: "SaveBtn" }, [
              _c(
                "button",
                { staticClass: "comBtn btn-bg0", on: { click: _vm.SaveBtn } },
                [_vm._v(_vm._s(_vm.$t("OfflineOrder.Save")))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "line" }),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "infobox",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.stopClick.apply(null, arguments)
                  },
                },
              },
              [
                _c("el-form", [
                  _c("div", { staticClass: "FormContentBox" }, [
                    _c(
                      "div",
                      { staticClass: "optionsLeft" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "option",
                            attrs: { prop: "CountryNameEn" },
                          },
                          [
                            _c("div", { staticClass: "StoreBox" }, [
                              _c("p", { staticClass: "StoreName" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("OfflineOrder.Store"))),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "inputbox" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "countrys",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "OfflineOrder.PleaseSelect"
                                        ),
                                      },
                                      model: {
                                        value: _vm.SaveMassage.StoreName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.SaveMassage,
                                            "StoreName",
                                            $$v
                                          )
                                        },
                                        expression: "SaveMassage.StoreName",
                                      },
                                    },
                                    _vm._l(_vm.StoreList, function (item) {
                                      return _c("el-option", {
                                        key: item.StoreId,
                                        attrs: {
                                          label: item.StoreName,
                                          value: item.StoreName,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "ZipCodeBox" }, [
                              _c("p", { staticClass: "ZipCodeName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.ContactName"))
                                  ),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.ContactName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SaveMassage,
                                          "ContactName",
                                          $$v
                                        )
                                      },
                                      expression: "SaveMassage.ContactName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "option",
                            attrs: { prop: "CountryNameEn" },
                          },
                          [
                            _c("div", { staticClass: "StoreBox" }, [
                              _c("p", { staticClass: "CountryName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.Country"))
                                  ),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "inputbox" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "countrys",
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflineOrder.PleaseSelect"
                                        ),
                                      },
                                      model: {
                                        value: _vm.SaveMassage.Country,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.SaveMassage,
                                            "Country",
                                            $$v
                                          )
                                        },
                                        expression: "SaveMassage.Country",
                                      },
                                    },
                                    _vm._l(_vm.countrys, function (item) {
                                      return _c("el-option", {
                                        key: item.index,
                                        attrs: {
                                          label: item.EnglishName,
                                          value: item.DoubleCharacterCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "CityName" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("OfflineOrder.City"))),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.City,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.SaveMassage, "City", $$v)
                                      },
                                      expression: "SaveMassage.City",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "CityName" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("OfflineOrder.Email"))),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.Email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.SaveMassage, "Email", $$v)
                                      },
                                      expression: "SaveMassage.Email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "TaxnumberBox" }, [
                              _c("p", { staticClass: "TaxnumbeName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.TaxNumber"))
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.TaxNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SaveMassage,
                                          "TaxNumber",
                                          $$v
                                        )
                                      },
                                      expression: "SaveMassage.TaxNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "optionsRight" },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "OrderNoName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.OrderNo"))
                                  ),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.OrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SaveMassage,
                                          "OrderNo",
                                          $$v
                                        )
                                      },
                                      expression: "SaveMassage.OrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "PhoneName" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("OfflineOrder.Phone"))),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "PhoneInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.Phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.SaveMassage, "Phone", $$v)
                                      },
                                      expression: "SaveMassage.Phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "StatesName" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("OfflineOrder.States"))),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "StatesInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.States,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.SaveMassage, "States", $$v)
                                      },
                                      expression: "SaveMassage.States",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "AddressName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.Address"))
                                  ),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.Address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SaveMassage,
                                          "Address",
                                          $$v
                                        )
                                      },
                                      expression: "SaveMassage.Address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "option", attrs: { prop: "ZipCode" } },
                          [
                            _c("div", { staticClass: "CityBox" }, [
                              _c("p", { staticClass: "ZIPName" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("OfflineOrder.PostCode"))
                                  ),
                                ]),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ZipCodeInput" },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.SaveMassage.PostCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SaveMassage,
                                          "PostCode",
                                          $$v
                                        )
                                      },
                                      expression: "SaveMassage.PostCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "AddTableBox" }, [
          _c("div", { staticClass: "RequiredOne" }, [_vm._v("*")]),
          _c("div", { staticClass: "RequiredTwo" }, [_vm._v("*")]),
          _c("div", { staticClass: "RequiredThree" }, [_vm._v("*")]),
          _c("div", { staticClass: "RequiredFour" }, [_vm._v("*")]),
          _c("div", { staticClass: "AddTable" }, [
            _c("div", { staticClass: "HeaderTop" }, [
              _c("div", { staticClass: "BaseInfoWord" }, [
                _vm._v(_vm._s(_vm.$t("OfflineOrder.ProductInfo"))),
              ]),
            ]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              { staticClass: "AddMessageBtn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-plus", type: "primary" },
                    on: { click: _vm.AddDataBtn },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("OfflineOrder.AddNewInfo")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "MessageContent" },
              [
                _c(
                  "el-table",
                  {
                    ref: "singleTable",
                    staticStyle: {
                      width: "98%",
                      "border-radius": "10px",
                      "margin-left": "10px",
                    },
                    attrs: { data: _vm.tableData, "highlight-current-row": "" },
                    on: { "current-change": _vm.handleCurrentChange },
                  },
                  [
                    _c("el-table-column", {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        property: "date",
                        label: _vm.$t("OfflineOrder.ProductName"),
                        width: "260",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: scope.row.ProductName,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "ProductName", $$v)
                                  },
                                  expression: "scope.row.ProductName",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "name",
                        label: _vm.$t("OfflineOrder.ProductVariants"),
                        width: "290",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: scope.row.Variants,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "Variants", $$v)
                                  },
                                  expression: "scope.row.Variants",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "address",
                        label: _vm.$t("OfflineOrder.Quantity"),
                        width: "260",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: scope.row.Quantity,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "Quantity", $$v)
                                  },
                                  expression: "scope.row.Quantity",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "address",
                        label: _vm.$t("OfflineOrder.ProductLink"),
                        width: "260",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: scope.row.ProductLink,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "ProductLink", $$v)
                                  },
                                  expression: "scope.row.ProductLink",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "", width: "210" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "col-col5",
                                  staticStyle: {
                                    "font-family": "Regular",
                                    "-webkit-font-smoothing": "antialiased",
                                    "font-size": "16px",
                                  },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.row,
                                        scope.index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("OfflineOrder.remove")))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }