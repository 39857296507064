var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "Bgrd" }),
    _c("div", { staticClass: "activeName" }, [
      _c("div", { staticClass: "CreditBack_box" }, [
        _c("div", [
          _vm._m(0),
          _c("div", { staticClass: "Payment_id" }, [
            _c("p", [_vm._v("Payment id:")]),
            _c("p", [_vm._v(_vm._s(_vm.$route.query.paymentId))]),
          ]),
          _c("div", { staticClass: "Payment_Status" }, [
            _c("p", [_vm._v("Status:")]),
            _vm.$route.query.payStatus == 1
              ? _c("p", [_vm._v("  Success")])
              : _c("p", [_vm._v("  Fail")]),
          ]),
          _c("div", { staticClass: "Payment_Amount" }, [
            _c("p", [_vm._v("Amount:")]),
            _c("p", [_vm._v("  " + _vm._s(_vm.$route.query.orderAmount))]),
          ]),
          _c("div", { staticClass: "Payment_CardNumber" }, [
            _c("p", [_vm._v("CardNumber:")]),
            _c("p", [_vm._v("  " + _vm._s(_vm.$route.query.cardNumber))]),
          ]),
          _c("div", { staticClass: "Payment_CardType" }, [
            _c("p", [_vm._v("CardType:")]),
            _c("p", [_vm._v("  " + _vm._s(_vm.$route.query.cardType))]),
          ]),
          _vm.$route.query.payStatus != 1
            ? _c("div", { staticClass: "Payment_Desription" }, [
                _c("p", [_vm._v("Desription:")]),
                _c("p", [
                  _vm._v("  " + _vm._s(_vm.$route.query.paymentDetails)),
                ]),
                _c("p", [
                  _vm._v("  " + _vm._s(_vm.$route.query.paymentSolutions)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "Confirm" }, [
            _c("button", { on: { click: _vm.btn } }, [
              _vm._v("Back to recharge records"),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "Credit_card" }, [
      _c("span", [_vm._v("Pay method")]),
      _c("span", [_vm._v("  Credit card")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }