<template>
  <div class="particulars">
    <div class="Bgrd"></div>
    <div class="activeName">
      <div class="CreditBack_box">
        <div>
          <p class="Credit_card">
            <span>Pay method</span>
            <span>&nbsp;&nbsp;Credit card</span>
          </p>
          <div class="Payment_id">
            <p>Payment id:</p>
            <p>{{$route.query.paymentId}}</p>
          </div>
          <div class="Payment_Status">
            <p>Status:</p>
            <p v-if="$route.query.payStatus == 1">&nbsp;&nbsp;Success</p>
            <p v-else>&nbsp;&nbsp;Fail</p>
          </div>
          <div class="Payment_Amount">
            <p>Amount:</p>
            <p>&nbsp;&nbsp;{{$route.query.orderAmount}}</p>
          </div>
          <div class="Payment_CardNumber">
            <p>CardNumber:</p>
            <p>&nbsp;&nbsp;{{$route.query.cardNumber}}</p>
          </div>
          <div class="Payment_CardType">
            <p>CardType:</p>
            <p>&nbsp;&nbsp;{{$route.query.cardType}}</p>
          </div>
          <div class="Payment_Desription" v-if="$route.query.payStatus != 1">
            <p>Desription:</p>
            <p>&nbsp;&nbsp;{{$route.query.paymentDetails}}</p>
            <p>&nbsp;&nbsp;{{$route.query.paymentSolutions}}</p>
          </div>
          <div class="Confirm">
            <button @click="btn">Back to recharge records</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>export default {
  name: "Dashboard",
  data: () => {
    return {
      CreditBackPort: ''
    };
  },
  components: {},
  mounted() {},
  created() {
    var query = this.$route.query;
    this.CreditBackPort = query.CreditBackPort;
  },
  methods: {
    btn() {
      this.$router.push('/TopUpHistory')
    },
    CreditBack() {
      request({
        method: "get",
        data: {
        }
      })
      .then(res => {
      })
      .catch(err => {
        console.log(err);
      });
    }
  },
};
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  font-family: "Regular";
}
.Bgrd {
  width: 100%;
  height: 130px;
  position: relative;
  background: linear-gradient(132deg, #1b1941, #22224b,#202251);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Regular";
}
.activeName {
  margin: auto;
  padding-bottom: 32px;
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  font-family: "Regular";
}
.CreditBack_box {
  width: 100%;
  height: 500px;
}
.Credit_card {
  padding-top: 30px;
  font-size: 20px;
  margin-left: 450px;
  display: flex;
}
.Payment_id {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 25px;
    border-bottom: 1px solid #000;
  }
}
.Payment_Status {
  font-size: 20px;
  margin-left: 350px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 5px;
    line-height: 35px;
    border-bottom: 1px solid #000;
  }
}
.Payment_Amount {
  font-size: 20px;
  margin-left: 350px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 5px;
    line-height: 35px;
    border-bottom: 1px solid #000;
  }
}
.Payment_CardNumber {
  font-size: 20px;
  margin-left: 350px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 5px;
    line-height: 35px;
    border-bottom: 1px solid #000;
  }
}
.Payment_CardType {
  font-size: 20px;
  margin-left: 350px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 5px;
    line-height: 35px;
    border-bottom: 1px solid #000;
  }
}
.Payment_Desription {
  font-size: 20px;
  margin-left: 350px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  margin-top: 15px;
  p:nth-child(2){
    margin-left: 5px;
    line-height: 35px;
    border-bottom: 1px solid #000;
  }
}
.Confirm button {
  width: 250px;
  height: 46px;
  background: #474882;
  border-radius: 10px;
  border: 0;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #ffffff;
  margin-left: 550px;
}
</style>
