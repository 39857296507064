<template>
  <div class="particulars">
    <div class="main">
      <!-- 搜索框 -->
      <div class="searchbox">
        <div class="search_input">
          <div class="search_icon el-icon-search"></div>
          <input
            type="text"
            :placeholder="$t('SupportTicket.PSPlease')"
            v-model="OrderNo"
          />
        </div>
        <div class="topbox">
          <div class="comBtn btn-bg0" @click="SearchOrderNo">
            {{ $t('SupportTicket.Search') }}
          </div>
          <div class="comBtn btn-bg0" @click="BackBtn">
            {{ $t('SupportTicket.Back') }}
          </div>
        </div>
      </div>
      <!-- 搜索出来的信息 -->
      <div class="records">
        <el-tabs>
          <template>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column
                align="center"
                :label="$t('SupportTicket.StoreName')"
                prop="UserName"
              ></el-table-column>
              <el-table-column
                align="center"
                :label="$t('SupportTicket.Order')"
                prop="OrderNo"
              >
                <template slot-scope="scope">
                  <div>
                    <p>{{ scope.row.SalesRecord }}</p>
                    <!-- <p>{{ scope.row.OrderNo }}</p>
                    <p>{{ scope.row.OrderId }}</p> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('SupportTicket.OrderDate')"
                prop="OrderTime"
              >
                <template slot-scope="scope">
                  <div>
                    <p>{{ scope.row.OrderTime }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('SupportTicket.ProductInformation')"
              >
                <template slot-scope="scope">
                  <div
                    v-for="(detail, index) in scope.row.OrderDetailVos"
                    :key="index"
                    class="productDetails"
                  >
                    <div class="productImg">
                      <img :src="detail.ProductPictureUrl" alt="" />
                    </div>
                    <div class="productAttribute">
                      <p>{{ detail.ProductName }}</p>
                      <p>SKU:</p>
                      <p>
                        {{ detail.ProductRealSku }} 
                        <span style="color: red">{{ detail.Num }}</span>
                      </p>
                      <p>Attributes:</p>
                      <p>{{ detail.Spec }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('SupportTicket.TrackingNumber')"
                prop="TrackingNumber"
              >
                <template slot-scope="scope">
                  <div>
                    <p>{{ scope.row.TrackingNumber || '——' }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('SupportTicket.Processing')"
              >
                <template slot-scope="scope">
                  <div class="ViewOrderDetailsBtn">
                    <el-button
                      type="text"
                      @click="viewOrderDetails(scope.row.OrderId)"
                    >
                      {{ $t('SupportTicket.ViewOrderDetails') }}
                    </el-button>
                  </div>
                  <div class="FileQuestionBtn">
                    <el-button
                      type="text"
                      @click="fileQuestion(scope.row.OrderId)"
                    >
                      {{ $t('SupportTicket.FileQuestion') }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-tabs>
      </div>
      <!-- 确认弹窗 -->
      <div>
        <el-dialog
          :visible.sync="isMore"
          custom-class="mydialog"
          :title="$t('SupportTicket.Prompt')"
        >
          <div class="line"></div>
          <div class="OperationFailed">
            Operation failed for the following reasons: This order has other
            issues in Processing!
          </div>
          <div class="NoticeClick">
            <span><i class="el-icon-question"></i></span>
            <span>
              Click the Yes button will jump to the issues processing page of
              this order
            </span>
          </div>
          <div class="FunctionButton">
            <el-button round @click="CloseBtn">
              {{ $t('SupportTicket.Close') }}
            </el-button>
            <el-button round @click="navigateYes">
              {{ $t('SupportTicket.Yes') }}
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import { CheckProcessing, OrderQueryByCustomer } from '@/api'

  export default {
    data: () => {
      return {
        name: 'OpenNewTicket',
        tableData: [],
        orderDetail: [],
        OrderNo: '', // 订单号搜索
        orderId: 'orderId',
        isMore: false,
        customerOrderId: 'customerOrderId'
      }
    },

    created() {
      /*  OrderQueryByCustomer().then((res) => {
        console.log(res)
      }) */
    },
    mounted() {
      const _self = this;
      document.onkeydown = function (e) {
        let key = window.event.keyCode;
        if (key === 13) {
          _self.SearchOrderNo();
        }
      };
    },
    methods: {
      SearchOrderNo() {
        if(this.OrderNo) {
          this.OrderQueryByCustomerList()
        }
      },
      OrderQueryByCustomerList() {
        OrderQueryByCustomer({
          Keywords: this.OrderNo,
          Filter: {
            TabType: 4
          }
        }).then((res) => {
          this.tableData = res.Items
        })
      },
      // 查看订单详情
      viewOrderDetails(orderId) {
        this.$router.push({
          name: 'ViewOrderDetails',
          query: {
            orderId
          }
        })
      },
      // 提交问题
      fileQuestion(orderId) {
        CheckProcessing(orderId).then((res) => {
          if (res && res.Result) {
            const { CustomerOrderId } = res.Result
            if (CustomerOrderId === 0) {
              this.FileQuestionId(orderId)
            } else {
              this.isMore = true
              this.$data.customerOrderId = CustomerOrderId
              // this.isMore=  true;
              // this.navigateYes(CustomerOrderId)
            }
          }
        })
      },
      navigateYes() {
        const { customerOrderId } = this.$data
        this.$router.push({
          name: 'FileQuestionDetail',
          query: {
            customerOrderId
          }
        })
      },
      FileQuestionId(orderId) {
        this.$router.push({
          name: 'FileQuestion',
          query: {
            orderId
          }
        })
      },
      BackBtn() {
        this.$router.push({ name: 'SupportTicket' })
      },
      CloseBtn() {
        this.isMore = false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .particulars {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: #eff1f3;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    .main {
      width: 1400px;
      padding: 0 0 32px;
    }
    .searchbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 75px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      padding-left: 20px;
      margin-bottom: 20px;
      .search_input {
        display: block;
        width: 650px;
        height: 40px;
        background: #f1f2f4;
        border-radius: 30px;
        margin-right: 20px;
        .search_icon {
          display: inline-block;
          margin-top: 15px;
          margin-right: 24px;
          margin-left: 24px;
        }
        input {
          background: transparent !important;
          width: calc(100% - 70px);
          font-size: 16px;
          color: #585858;
          padding-right: 20px;
          font-family: 'Regular';
          -webkit-font-smoothing: antialiased;
        }
      }
      .topbox {
        div {
          min-width: 100px;
          height: 40px;
          margin-right: 20px;
        }
        .Synchronize a {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .PSwords {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      background: #d3dfff;
      border-radius: 10px;
      padding-left: 20px;
      margin-bottom: 20px;
      p {
        font-size: 16px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
    .records {
      width: 100%;
      border-radius: 10px !important;
      overflow: hidden;
      position: relative;
    }
    .productDetails {
      display: flex;
      .productImg {
        display: flex; /* 将 .productImg 设置为 Flexbox 布局 */
        align-items: center;
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
      }
      .productAttribute {
        font-size: 12px;
        margin-left: 15px;
        p:nth-child(1) {
          color: #3f6aff;
          font-size: 12px;
        }
      }
    }
    .ViewOrderDetailsBtn {
      .el-button {
        color: #bc0c34;
        font-size: 16px;
      }
    }
    .FileQuestionBtn {
      margin-top: 20px;
      .el-button {
        width: 125px;
        height: 40px;
        background: #15104b;
        color: #fff;
        border-radius: 50px;
        font-size: 14px;
      }
    }
  }
  ::v-deep .mydialog {
    width: 600px;
    height: auto;
    .line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      margin-top: -20px;
    }
    .OperationFailed {
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
      color: #000;
    }
    .NoticeClick {
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
      word-break: break-word;
      span:nth-child(1) {
        font-size: 20px;
        color: #999999;
      }
      span:nth-child(2) {
        color: #999999;
      }
    }
    .FunctionButton {
      text-align: center;
      margin-top: 40px;
      .el-button:nth-child(1) {
        width: 160px;
        border: 1px solid #15104b;
        color: #15104b;
        background: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .el-button:nth-child(2) {
        width: 160px;
        border: 1px solid #15104b;
        color: #fff;
        background: #15104b;
        font-size: 16px;
        font-weight: 600;
        margin-left: 30px;
      }
    }
  }
  ::v-deep .records .el-tabs__content {
    background-color: #fff;
    border-radius: 10px;
  }
</style>
