<template>
  <div class="page">
    <!-- this is register page！ -->
    <div class="content">
      <!--      <div class="block"></div>-->
      <!-- 选择注册方式 -->
      <div class="main" v-if="type === 1">
        <LoginContentLeft></LoginContentLeft>
        <div class="RegisterRight">
          <!-- <p class="login">
            <span>{{ $t("login.login") }}</span>
            <span class="change" @click="loginIn"> {{ $t("register.Sign") }}</span>
          </p> -->
          <div class="register">
            <div class="LogoBig">
              <!-- <img src="../../../assets/imgs/NewLogo/LogoOne.svg" alt="" /> -->
              <img :src="logoImgOne[formLink]" alt="">
            </div>
            <div class="account">{{ $t("register.header") }}</div>
            <div class="account1">{{ $t("register.account1") }}</div>
            <div class="register-message">
              <p class="StartStepMessageTwo">
                with
                <span class="col-col5">60k+</span>
                dropshippers!
              </p>
            </div>
          </div>
          <email-register></email-register>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginContentLeft from "../../LoginContentLeft.vue";
import EmailRegister from "../EmailRegister.vue";
import { mapState } from "vuex";
import "@/assets/css/famfamfam-flags.css";
import { getFrom } from "@/utils/tools";
import {
  registerOptionOrders,
  registerOptions,
  registerPayList,
  registerRules,
} from "../../constants";

export default {
  data: () => {
    return {
      name: "register",
      logoImgOne:{
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoThree.png'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/SliceLoginMlogoXiao.png'),
        'es.usadrop.com': require('@/assets/imgs/NewLogo/ES90x60.png')
      },
      formLink: getFrom(),
      type: 1, //注册方式
      step: 1, //邮箱注册进度
      selectCode: "+1",
      optionsCopy: [],
      PhoneCode: "",
      DisplayUnable: false,
      displayUSAdropInfo: false,
      selectedClass: "famfamfam-flag-us",
      Name: "",
      // 注册信息
      ruleForm: {
        fullname: "",
        email: "",
        marketingCode: "",
      },
      rules: registerRules,
      options: registerOptions,
      optionOrders: registerOptionOrders,
      RegisterMessage: {}, // 会员注册的所有国家
      mobileNumber: "",
      verifyCode: "",
      password: "",
      agreeChecked: false,
      displayShowCode: false,
      pwd: "",
      value1: "",
      valueOrder: "",
      payList: registerPayList,
    };
  },
  created() {
    const {
      authInfo,
      $store: {
        state: { storeKey },
        dispatch,
      },
    } = this;
    const { registerType } = authInfo || {};
    this.type = registerType || 1;
    storeKey && (this.type = 1);
    const search = decodeURIComponent(location.search);
    const { code, state, scope } = search || {};
    if (code && state) {
      dispatch("login/getAuthBack", {
        Code: code,
        State: state,
      }).then((res) => {
        const { ResData } = res || {};
        this.authInfo = ResData;
        // if (scope?.indexOf("google") > 0) {
        //   this.type = 3;
        //   return
        // }
        // this.type = 2;
      });
    }
  },
  computed: mapState(["authInfo"]),
  components: {
    LoginContentLeft,
    EmailRegister,
  },
  methods: {
    loginIn() {
      this.$router.push("login");
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
.page {
  background: #15104b;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    .main {
      position: relative;
      display: flex;

      .RegisterRight {
        width: 505px;
        height: 748px;
        background: #ffffff;
        border-radius: 0 30px 30px 0;
        font-family: "Regular";
        -webkit-font-smoothing: antialiased;

        .register {
          border-bottom: 1px solid #e4e6ef;
          padding-bottom: 10px;

          .LogoBig {
            margin-left: 30px;
            margin-top: 20px;
            height: 60px;
            width: 90px;

            img {
              height: 60px;
              width: 90px;
            }
          }

          .account {
            font-size: 25px;
            color: #433759;
            font-weight: 600;
            line-height: 42px;
            margin-bottom: 10px;
            font-family: "Regular";
            -webkit-font-smoothing: antialiased;
            text-align: center;
          }

          .account1 {
            font-size: 18px;
            color: #433759;
            text-align: center;
            font-family: "Regular";
            -webkit-font-smoothing: antialiased;
          }

          .register-message {
            text-align: center;
            font-size: 18px;
            font-family: "Regular";
            -webkit-font-smoothing: antialiased;
            font-weight: 400;
            color: #333333;
            span {
              color: #93df89;
              font-size: 20px;
              font-weight: 600;
              font-family: "Regular";
              -webkit-font-smoothing: antialiased;
            }
          }
        }
      }
    }
  }
}
</style>
