import request from '@/utils/request';

//  通过Code获取店铺列表
export const queryTTStoreList = (data) => request({
    method: "post",
    url: '/api/Store/ReceiveRefreshTTSTokenOrKeys',
    data
  });

  // 选择一个tt店铺
export const selectTTStore = (data) => request({
    method: "post",
    url: '/api/Store/RefreshTTSTokenByKey',
    data
  });
  
//查询tt链接
  export const queryTTlink = (data) => request({
    method: "post",
    url: '/api/Member/TiktokShopConnect',
    data
  })

  export const selectedStore = (data) => request({
    method: "post",
    url: '/api/Store/RefreshTTSTokenByKey',
    data
  })