import request from '@/utils/request'

// 未关联数据
export function getNoConnectData(data) {
  return request({
    url: '/api/ProductConnect/GetShopProductPending',
    method: 'post',
    data: data
  })
}

// 已关联数据
export function getHasConnectData(data) {
  return request({
    url: '/api/ProductConnect/GetShopProductComplete',
    method: 'post',
    data: data
  })
}

// 同步数据
export function batchSyncProduct(data) {
  return request({
    url: '/api/ProductConnect/BatchSyncProduct',
    method: 'post',
    data
  })
}

// 店铺下拉数据
export function getShopListData() {
  return request({
    url: '/api/ProductConnect/GetShopList',
    method: 'get'
  })
}

// 已关联详情
export function getConnectedDetailData(data) {
  return request({
    url: '/api/ProductConnect/GetShopProductVariantsComplete',
    method: 'post',
    data: data
  })
}

// 获取店铺产品所有子SKU
export function getProductChildData(data) {
  return request({
    url: '/api/ProductConnect/GetShopProductVariants',
    method: 'post',
    data: data
  })
}

export function submitMapping(data) {
  return request({
    url: '/api/ProductConnect/SubmitMapping',
    method: 'post',
    data
  })
}

// 获取UD商品信息
export function getUDProductData(data) {
  return request({
    url: '/goodsapi/goods/search',
    method: 'post',
    data: {
      ...data,
      platform: 2
    }
  })
}

export function findProductListHot(data) {
  return request({
    url: '/goodsapi/goods/searchHot',
    method: 'post',
    data: {
      platform: 2,
      ...data
    }
  })
}

// 图片上传
export function uploadFile(file) {
  const fileData = new FormData()
  fileData.append('file', file)
  return request({
    url: '/goodsapi/oss/uploadFile',
    method: 'post',
    data: fileData,
    timeout: 60000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function uploadFileOss() {
  return request({
    url: '/goodsapi/oss/sign',
    method: 'get',
    timeout: 60000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 图片搜索
export function imageSearch(data) {
  return request({
    url: '/goodsapi/goods/searchByImage',
    method: 'post',
    data: {
      ...data,
      platform: 2
    }
  })
}
/* 视频分类 */
export function videoType(data) {
  return request({
    url: '/goodsapi/video/type/list',
    method: 'post',
    data
  })
}
/* 视频列表/搜索 */
export function videoSearch(data) {
  return request({
    url: '/goodsapi/video/search',
    method: 'post',
    data
  })
}
/* 视频列表/搜索 */
export function videoAllType(data) {
  return request({
    url: '/goodsapi/video/allType',
    method: 'post',
    data
  })
}
/* 视频详情 */
export function videoGetOne(params) {
  return request({
    url: '/goodsapi/video/getOne',
    method: 'get',
    timeout: 60000,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params
  })
}

/* 小白视频 */
export function getVideoData() {
  return request({
    url: '/api/LhAcadamy/GetAcadamyCatlogList',
    method: 'post'
  })
}

export function getRecommendList(data) {
  return request({
    url: '/api/LhAcadamy/List',
    method: 'post',
    data
  })
}

export function updVideoProcess(data) {
  return request({
    url: '/api/LhAcadamy/UpdateVideoProcess',
    method: 'post',
    data
  })
}

export function getCommentList(data) {
  return request({
    url: '/api/LhAcadamy/GetCommentList',
    method: 'post',
    data
  })
}

export function addComment(data) {
  return request({
    url: '/api/LhAcadamy/AddComment',
    method: 'post',
    data
  })
}

/* 新增播放次数 */
export function AddPlayTime(data) {
  return request({
    url: '/api/LhAcadamy/AddPlayTime',
    method: 'post',
    data
  })
}

/* 详情页关联视频 */
export function ListFromDetail(data) {
  return request({
    url: '/api/LhAcadamy/ListFromDetail',
    method: 'post',
    data
  })
}

/* 更新视频播放进度 */
export function UpdateVideoProcess(data) {
  return request({
    url: '/api/LhAcadamy/UpdateVideoProcess',
    method: 'post',
    data
  })
}

/* 购买视频课程 */
export function BuyVedioRights(data) {
  return request({
    url: '/api/LhAcadamy/BuyVedioRights',
    method: 'post',
    data
  })
}

// 视频详情页面
export function getVideoDetail(params) {
  return request({
    url: '/api/LhAcadamy/Detail',
    method: 'get',
    timeout: 60000,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params
  })
}
