var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formRef",
      attrs: {
        "label-position": "top",
        model: _vm.formModel,
        disabled: _vm.readonly,
      },
    },
    [
      _c(
        "el-form-item",
        [
          _c("FeedImageUpload", {
            attrs: {
              "img-upload-path": _vm.imgUploadPath,
              "show-example": false,
              "default-file-list": _vm.defaultFileList,
              readonly: _vm.readonly,
            },
            model: {
              value: _vm.formModel.fileList,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "fileList", $$v)
              },
              expression: "formModel.fileList",
            },
          }),
        ],
        1
      ),
      _c("FeedOrderInformation", {
        ref: "addressRef",
        attrs: {
          title: _vm.$t("SupportTicket.ReconfirmTheInformation"),
          "country-list": _vm.countryList,
          address: _vm.formModel.Address,
          readonly: _vm.readonly,
        },
      }),
      !_vm.readonly
        ? _c("FeedOrderInformation", {
            attrs: {
              "country-list": _vm.countryList,
              address: _vm.OrderAddress,
              readonly: true,
            },
          })
        : _vm._e(),
      _vm.showErrorProduct
        ? _c("FeedErrorProduct", {
            ref: "errorProductRef",
            attrs: { "order-details": _vm.notExcludeOrderDetails },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }