var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "sourcing-upload",
          attrs: {
            action: "",
            "list-type": "picture-card",
            "on-remove": _vm.handleRemove,
            "http-request": _vm.uploadImg,
            accept: "image/*",
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm.searchResultVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "matched-pruducts",
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("Sourcing.amp")) + " "),
                  _c("b", [
                    _vm._v(" " + _vm._s(_vm.$t("Sourcing.ampBold")) + " "),
                  ]),
                ]),
                _vm.currentImageUrl
                  ? _c(
                      "p",
                      { staticClass: "pointer", on: { click: _vm.viewMore } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("productDetails.more")) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm.matchedProducts.length
                ? _c(
                    "div",
                    { staticClass: "img-list" },
                    _vm._l(_vm.matchedProducts, function (product) {
                      return _c(
                        "div",
                        { key: product.goodsCode, staticClass: "img-item" },
                        [
                          _c("img", {
                            attrs: { src: product.mainImage, alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(product)
                              },
                            },
                          }),
                          _c(
                            "p",
                            { staticClass: "product-name text-ellipsis-2" },
                            [_vm._v(_vm._s(product.name))]
                          ),
                          _c("p", { staticClass: "product-price" }, [
                            _vm._v("$" + _vm._s(product.minPrice)),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("el-empty", {
                    attrs: { description: _vm.$t("Sourcing.NoData") },
                  }),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("Sourcing.sourcingImageTips")) + " "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-blue",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("showProductNameCard")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Sourcing.sourcingClickText")) + " "
                    ),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }