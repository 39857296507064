<template>
  <div class="print-dialog">
    <el-dialog
      :draggable="true"
      title="Add to print file group"
      :visible.sync="_showDialogVisible"
      class="modalDialog"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" style="height: 200px">
        <el-tab-pane
          v-for="category in categories"
          :key="category.id"
          :label="category.name"
          :name="category.id + ''"
        >
          <PrintImages
            @enterSearch="enterSearch"
            @customUploadRequest="customUploadRequest"
            @checkImg="checkImg"
            @delImg="delImg"
            :imgList="imgList[category.id]"
            :tabName="category.id"
            :showUpload="category.id === 'myFile'"
          ></PrintImages>
          <div class="tab-loading" v-show="loading"><i class="el-icon-loading"></i></div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <span class="selected"> {{ selectedImgs.length }} selected</span>
        <el-button round type="primary" @click="confirmEditImgName"> Add </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { OSSUploadType, useJSCore } from 'sm-js-core'
import PrintImages from './PrintImages.vue'
import { uploadMyImage, getMyImages, getCommonImages, delPodImage, getClassifyList } from '../api'
export default {
  components: {
    PrintImages
  },
  props: {
    showDialogVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      categories: [
        {
          name: 'MyUploads',
          id: 'myFile'
        }
      ],
      selectedImgs: [],
      activeName: 'myFile',
      searchKeyword: '',
      imgList: {
        myFile: []
      },
      loading: false
    }
  },
  watch: {
    imgList: {
      handler(val, oldVal) {
        console.log('数据变化', val)
      },
      deep: true
    }
  },
  computed: {
    _showDialogVisible: {
      get() {
        if (this.showDialogVisible) {
          this.getMyImagesFn()
        }
        return this.showDialogVisible
      },
      set(val) {
        this.$emit('update:showDialogVisible', val)
      }
    }
  },
  created() {
    this.getClassifyListFn()
  },
  methods: {
    getClassifyListFn() {
      this.categories = [
        {
          name: 'MyUploads',
          id: 'myFile'
        }
      ]
      this.activeName = 'myFile'
      getClassifyList().then((res) => {
        if (res.code === 0) {
          const categories = res.body?.map((item) => {
            this.$set(this.imgList, item.id, []) // 双向绑定，不然无法更新数据
            return { name: item.classifyName, id: item.id }
          })
          this.categories.push(...categories)
        }
      })
    },
    // 删除图片
    delImg(item, index) {
      delPodImage({ idList: [item.id] }).then((res) => {
        if (res.code === 0) {
          const delSelectedIndex = this.selectedImgs.findIndex((item) => item.id === item.id)
          if (delSelectedIndex > -1) {
            this.selectedImgs.splice(delSelectedIndex, 1)
          }
          this.$message.success('Delete successful!')
          this.getMyImagesFn()
        }
      })
    },
    // 搜索
    enterSearch(name) {
      this.getMyImagesFn(name)
    },
    // 选中和取消选中图片
    checkImg(index, tabName) {
      this.$set(this.imgList[tabName][index], 'checked', !this.imgList[tabName][index].checked)
      if (this.imgList[tabName][index].checked) {
        this.selectedImgs.push(this.imgList[tabName][index])
      } else {
        const cancelIndex = this.selectedImgs.find(
          (item) => item.id === this.imgList[tabName][index]?.id
        )
        this.selectedImgs.splice(cancelIndex, 1)
      }
      this.$forceUpdate()
    },
    handleClose() {
      this._showDialogVisible = false
    },
    // 通过name搜索
    // 获取图片列表
    async getMyImagesFn(name) {
      const Methods = this.activeName === 'myFile' ? getMyImages : getCommonImages
      const params =
        this.activeName === 'myFile'
          ? { pageNum: 1, size: 1000, fileName: name }
          : { pageNum: 1, size: 1000, fileName: name, classifyId: this.activeName } //category: this.activeName
      try {
        this.loading = true
        await Methods(params).then((res) => {
          if (res.code === 0) {
            res.body.records = res.body?.records.map((item) => {
              if (this.selectedImgs.find((_item) => _item.id === item.id)) {
                return { ...item, checked: true }
              } else {
                return { ...item, checked: false }
              }
            })
            this.$set(this.imgList, this.activeName, [...res.body?.records] || [])
            this.$forceUpdate()
          }
          this.loading = false
        })
      } catch (error) {
        this.loading = false
      }
    },
    confirmEditImgName() {
      this._showDialogVisible = false
      const _deepSelectedImgs = useJSCore().obj.deepClone(this.selectedImgs)
      this.$emit('addSelectedImgs', _deepSelectedImgs)
      this.selectedImgs = []
    },
    customUploadRequest(file) {
      useJSCore()
        .oss.upload(file.file, {
          uploadType: OSSUploadType.POD_MATERIAL,
          datePath: true,
          calculateWidthHeight: true
        })
        .then((res) => {
          console.log('imageUrl', res)
          uploadMyImage([
            {
              fileName: res.name.split('.')[0],
              imageUrl: res.url,
              imageFormat: res.type,
              imageSize: res.size,
              imageLength: res.height,
              imageWidth: res.width
            }
          ]).then((_res) => {
            if (_res.code === 0) {
              this.getMyImagesFn()
            }
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modalDialog {
  ::v-deep .el-dialog {
    width: 880px;
    height: 720px;
    border-radius: 26px;
    .el-dialog__body {
      height: calc(100% - 130px);
      padding-top: 0px;
    }
  }
  .tab-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    cursor: not-allowed;
    background: #fff;
    opacity: 0.9;
    top: 0;
    font-size: 36px;
    color: #15104b;
  }
  .form-input {
    ::v-deep .el-input__inner {
      border-radius: 36px;
      border: 1px solid #e5e5e5;
    }
  }
  .selected {
    font-size: 16px;
    margin-right: 16px;
  }
}
</style>
