<!--
 * @Author: jiangshiyang
 * @Date: 2024-07-22 11:24:39
 * @LastEditors: jiangshiyang
 * @Description: 会员升级弹窗
-->

<template>
  <div class="member-tips">
    <el-dialog :visible.sync="visible" class="tips-dialog" top="35vh" @close="handleClose">
      <div class="dialog-footer">
        <el-button class="footer-btn" @click="handleClose">Cancel </el-button>
        <el-button type="primary" class="footer-btn" @click="jumpPage">Upgrade </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'UpgradeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  uodated(oldValue, newValue) {
    console.log({ oldValue, newValue })
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },

    jumpPage() {
      // this.$emit('confirm')
      this.$router.push('/Upgrade')
    }
  }
}
</script>
<style lang="scss" scoped>
.member-tips {
  ::v-deep .tips-dialog {
    &.el-dialog__wrapper {
      border: 1px solid red;
      z-index: 100010 !important;
    }
    .el-dialog {
      width: 496px;
      height: 316px;
      border-radius: 38px;
      background: url('https://udshop-test.hzpdex.com/static/bg_upgrade_dialog.svg') no-repeat;
      background-size: 496px 316px;

      .el-dialog__header {
        display: none;
      }

      .tips-wrap {
        padding: 48px 40px;
        font-size: 20px;
        text-align: center;
      }

      .dialog-footer {
        position: absolute;
        bottom: 70px;
        left: 76px;
        right: 76px;
        display: flex;
        justify-content: center;
      }

      .footer-btn {
        width: 200px;
        height: 42px;
        border-radius: 30px;
        font-size: 16px;
      }
    }
  }
}
</style>
