export const payList = [
  {
    id: 0,
    img: require("../../assets/imgs/NewLogin/LunBo1.jpg"),
  },
  {
    id: 1,
    img: require("../../assets/imgs/NewLogin/LunBoOne.jpg"),
  },
  {
    id: 2,
    img: require("../../assets/imgs/NewLogin/LunBo3.jpg"),
  },
  {
    id: 3,
    img: require("../../assets/imgs/NewLogin/LunBo4.jpg"),
  },
  {
    id: 4,
    img: require("../../assets/imgs/NewLogin/LunBo5.jpg"),
  }
];


export const rules = {
  email: [
    {required: true, message: "Email cannot be empty", trigger: ["blur", "change"]},
    {
      required: true,
      type: "email",
      message: "Please enter the correct Email address",
      trigger: ["blur", "change"]
    }
  ],
  password: [{required: true, message: "Password cannot be empty", trigger: ["blur", "change"]}]
};

export const registerRules = {
  fullname: [{required: true, message: "Field cannot be empty", trigger: "blur"}],
  email: [
    {required: true, message: "Field cannot be empty", trigger: "blur"},
    {
      type: "email",
      message: "Please enter the correct Email address",
      trigger: ["blur", "change"]
    }
  ]
};

export const registerOptions = [{
  id: 1,
  value: "EST 12:00PM-02:00PM",
  label: "EST 12:00PM-02:00PM"
},
  {
    id: 2,
    value: "EST 21:00PM-22:00PM",
    label: "EST 21:00PM-22:00PM"
  }
];

export const registerOptionOrders = [
  {
    id: 1,
    value: "Not started yet",
    label: "Not started yet"
  },
  {
    id: 2,
    value: "1-5 orders",
    label: "1-5 orders"
  },
  {
    id: 3,
    value: "6-20 orders",
    label: "6-20 orders"
  },
  {
    id: 4,
    value: "21-50 orders",
    label: "21-50 orders"
  },
  {
    id: 5,
    value: "More than 50 orders",
    label: "More than 50 orders"
  }
];

export const registerPayList = [
  {
    id: 0,
    img: require("../../assets/imgs/NewLogin/LunBo1.jpg")
  },
  {
    id: 1,
    img: require("../../assets/imgs/NewLogin/LunBoOne.jpg"),
  },
  {
    id: 2,
    img: require("../../assets/imgs/NewLogin/LunBo3.jpg")
  },
  {
    id: 3,
    img: require("../../assets/imgs/NewLogin/LunBo4.jpg")
  },
  {
    id: 4,
    img: require("../../assets/imgs/NewLogin/LunBo5.jpg")
  }
]


