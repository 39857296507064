<template>
  <div class="member_box" v-if="visible">
    <div class="member_set">
      <div class="member_content">
        <div class="member_top">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591322032_MemberHuoJian.png"
            alt=""
          />
        </div>
        <div class="member_down">
          <p>{{ $t('SourcingUrl.YouHave') }}</p>
          <p>{{ $t('Sourcing.Upgrade') }}</p>
        </div>
        <div class="bind-store">
          <p>
            <span style="color: red; font-size: 20px">*</span>
            {{ $t('Sourcing.BindMembership') }}
          </p>
        </div>
      </div>
      <div class="member_btn">
        <el-button @click="comparePriceBtn">{{ $t('Sourcing.ViewPlan') }}</el-button>
        <el-button @click="addStoreBtn">{{ $t('Sourcing.AddStore') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberPop',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {}
  },
  methods: {
    comparePriceBtn() {
      this.$router.push('/Upgrade')
    },
    addStoreBtn() {
      this.$router.push('/Store')
    }
  }
}
</script>

<style scoped lang="scss">
.member_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  .member_set {
    width: 380px;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    left: calc(60% - 400px);
    top: calc(50% - 450px);
    margin-top: 160px;
    background-color: #2a293e;
    height: 500px;
    border-radius: 10px;
    position: relative;
  }
  .member_top {
    width: 230px;
    height: 220px;
    margin-left: 50px;
    img {
      width: 100%;
      height: 100%;
      margin-top: 35px;
      margin-left: 20px;
    }
  }

  .member_down {
    width: 380px;
    margin-top: 0px;

    p:nth-child(1) {
      text-align: center;
      margin-top: 50px;
      font-size: 18px;
      color: #fff;
      word-break: normal;
    }

    p:nth-child(2) {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      color: #fff;
      word-break: normal;
    }
  }

  .bind-store {
    p:nth-child(1) {
      color: #fff;
      font-size: 14px;
      margin-top: 30px;
      text-align: center;
      line-height: 40px;
      word-break: normal;
    }
  }

  .member_btn {
    width: 100%;
    margin-left: -25px;
    width: 150px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    margin-top: 20px;
    display: flex;
    .el-button:nth-child(1) {
      margin-left: 85px;
      height: 45px;
      margin-bottom: -150px;
      border-radius: 50px;
      font-size: 16px;
      color: #fff;
      background: #93df89;
      border: 1px solid #93df89;
      &:hover {
        background: #bff4b8;
        color: #fff;
      }
    }
    .el-button:nth-child(2) {
      margin-left: 30px;
      height: 45px;
      border-radius: 50px;
      font-size: 16px;
      color: #fff;
      background: #93df89;
      border: 1px solid #93df89;
      &:hover {
        background: #bff4b8;
        color: #fff;
      }
    }
  }
}
</style>
