<template>
  <div>
    <el-dialog :visible.sync="showDialog" destroy-on-close>
      <div class="dialog-header">
        <div class="header-title">{{ $t('store.orders') }}</div>
        <!--        <i style="cursor: pointer" class="el-icon-question" @click="showHelpDrawer = true"></i>-->
      </div>
      <div class="dialog-body">
        <el-form :model="formModel" :rules="rules" ref="ruleForm" label-width="auto">
          <el-form-item label="Original URL of Shopify store" prop="name">
            <el-row>
              <el-col :span="16">
                <el-input
                  v-model.trim="formModel.name"
                  :placeholder="$t('store.errorStoreName')"
                  clearable
                >
                  <el-tooltip
                    slot="suffix"
                    effect="dark"
                    content="To find it, go to your Shopify dashboard. Go to Settings > Domains. Look for the only or oldest domain that ends in 'myshopify.com' format."
                  >
                    <i style="cursor: pointer" class="el-icon-question el-input__icon"> </i>
                  </el-tooltip>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="my-shopify">{{ $t('store.myshopify') }}</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item :label="$t('store.accessToken')" prop="accessToken">
            <el-input
              v-model.trim="formModel.accessToken"
              :placeholder="$t('store.errorAccessToken')"
              clearable
            ></el-input>
          </el-form-item>
          <!--          <el-form-item :label="$t('store.apiKey')" prop="apiKey">-->
          <!--            <el-input-->
          <!--              v-model.trim="formModel.apiKey"-->
          <!--              :placeholder="$t('store.errorApiKey')"-->
          <!--              clearable-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item :label="$t('store.apiSecretKey')" prop="apiSecretKey">-->
          <!--            <el-input-->
          <!--              v-model.trim="formModel.apiSecretKey"-->
          <!--              :placeholder="$t('store.errorApiSecretKey')"-->
          <!--              clearable-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
        </el-form>
        <div class="no-shopify-store-title">
          <p>{{ $t('store.NotShopify') }}</p>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button
          class="cancel-button"
          :loading="buttonLoading"
          plain
          round
          @click="showDialog = false"
        >
          {{ $t('store.cancel') }}
        </el-button>
        <el-button
          class="confirm-button"
          :loading="buttonLoading"
          round
          type="primary"
          @click="confirmStore"
        >
          {{ $t('store.save') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { AddStoreForHand } from '@/api/store'

export default {
  name: 'AddShopifyStoreDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false,
      showHelpDrawer: false,
      buttonLoading: false,
      formModel: {
        //店铺名字
        name: '',
        accessToken: '',
        apiKey: '',
        apiSecretKey: ''
      },
      rules: {
        name: [{ required: true, message: this.$t('tips.Field'), trigger: 'blur' }],
        accessToken: [{ required: true, message: this.$t('tips.Field'), trigger: 'blur' }],
        apiKey: [{ required: true, message: this.$t('tips.Field'), trigger: 'blur' }],
        apiSecretKey: [{ required: true, message: this.$t('tips.Field'), trigger: 'blur' }]
      }
    }
  },
  watch: {
    value(newValue) {
      this.showDialog = newValue
    },
    showDialog(newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    confirmStore() {
      this.$refs.ruleForm?.validate((valid) => {
        if (valid) {
          let params = {
            StoreName: this.formModel.name,
            Token: this.formModel.accessToken,
            StoreType: 'Shopify'
          }
          this.buttonLoading = true
          AddStoreForHand(params)
            .then((res) => {
              this.buttonLoading = false
              if (res.Success) {
                this.showDialog = false
                this.$emit('onAddSuccess')
              } else {
                this.$message.error(res.ErrMsg)
              }
            })
            .catch(() => {
              this.buttonLoading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 12px 16px;

  .header-title {
    background-color: #ffffff;
    color: #333333;
    font-size: 16px;
    padding-right: 8px;
  }
}

.dialog-body {
  padding: 24px;

  .no-shopify-store-title {
    text-align: center;
  }

  .no-shopify-store-title p {
    font-style: italic;
    color: rgb(76, 139, 245);
  }

  .my-shopify {
    height: 19px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 19px;
    opacity: 0.8;
    margin-left: 10px;
  }

  ::v-deep .el-input__inner {
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    overflow: hidden;
  }
}

.dialog-footer {
  padding: 14px 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .cancel-button {
    width: 200px;
  }

  .confirm-button {
    width: 200px;
    margin-left: 100px;
  }
}
</style>
