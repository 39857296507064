export const getTTSK = () => sessionStorage.getItem("tt_shop_key") || "";

export const isTTLogin = () => {
  return getTTSK().length > 0
}
export const setTTShopInfo = (res) => {
  console.log('setTTShopInfo:',res)
  const { ShopKey, ShopName, ShopId } = res || {};
  sessionStorage.setItem("tt_shop_key", ShopKey);
  sessionStorage.setItem("tt_shop_name", ShopName);
  sessionStorage.setItem("tt_shop_id", ShopId);
  return res;
};

export const clearTTInfo = () => {
  sessionStorage.removeItem("tt_shop_key");
  sessionStorage.removeItem("tt_shop_name");
  sessionStorage.removeItem("tt_shop_id");
  console.log('TT信息清空')
};
