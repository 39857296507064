var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "particulars" }, [
      _c("img", {
        attrs: { src: require("@/assets/imgs/cancel.png"), alt: "" },
      }),
      _c("div", { staticClass: "successful" }, [
        _vm._v("forfeiture of payment"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }