const nodeEnv = process.env.NODE_ENV

export const baseApiHost = () => {
  let apiHost = process.env.VUE_APP_BASE_API
  // switch (nodeEnv) {
  //   case 'development':
  //     apiHost = 'https://webapi.test.wiioreg.com'
  //     //apiHost = 'http://127.0.0.1:5000';
  //     //apiHost = 'http://172.17.1.151:5000'
  //     //apiHost = 'http://172.16.1.210:8087'
  //     break
  //   case 'test':
  //     // https://webapi.test.wiioreg.com
  //     apiHost = 'https://webapi.test.wiioreg.com'
  //     break
  //   case 'prod':
  //     apiHost = 'https://webapi.usadrop.com'
  //     break
  // }
  return apiHost
}
