<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="Details-figure">
        <div class="focus">
          <div
            class="banner trans"
            @mouseenter="enter"
            @mouseleave="leave"
            v-if="productDetail.MainPic">
            <vue-photo-zoom-pro :high-url="currentUrl" :out-zoomer="true">
              <img :src="currentUrl" class="img" />
            </vue-photo-zoom-pro>
          </div>
          <div class="indicators"> <!-- Indicators 小图片 -->
            <img
              v-for="(item, index) in ThumbPhotos"
              :src="item.url"
              alt=""
              @click="select(index)"
              :class="{ con: index == imgindex, displayX: !item.isDisplay }"
              :key="index" />
          </div>
          <button @click="prev" class="prev"> <!-- Controls 左右箭头 -->
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926687225_left-arrow.png"
              alt="" />
          </button>
          <button @click="next" class="next">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926687318_right-arrow.png"
              alt="" />
          </button>
        </div>
        <div class="synopsis"> <!-- 简介 -->
          <div class="intro">{{ productDetail.ProductName }}</div>
          <div class="AliexpressLink_SourceUrl">
            <a
              :href="productDetail.SourceUrl"
              target="_blank"
              style="font-family: Regular; -webkit-font-smoothing: antialiased; font-size: 14px">
              <p>{{ $t('productDetails.AliexpressLink') }}</p>
            </a>
          </div>
          <div class="price">
            <span class="labelname">{{ $t('productDetails.DetailsPrice') }}</span>
            <span>
              <span
                style="
                  font-weight: 500;
                  font-family: Regular;
                  -webkit-font-smoothing: antialiased;
                  font-size: 22px;
                  margin-left: 15px;
                  margin-top: 5px;">{{ $t('productDetails.sum') }} {{ Price.toFixed(2) }}</span>
            </span>
          </div>
          <template v-if="productDetail.ProductType !== 'normal'">
            <div v-for="(attr, index) in productDetail.Attributes" :key="index">
              <div :class="attr.PicList && attr.PicList.length > 0 ? 'picture' : 'measure'">
                <template v-if="attr.PicList && attr.PicList.length > 0">
                  <div>
                    <span class="labelname">{{ $t(attr.AttributesName) }}:</span>
                    <span class="imgas" v-for="(item, index) in Productimg" :key="item.index">
                      <span v-if="imageindex == index">{{ item }}</span>
                    </span>
                  </div>
                  <el-radio-group
                    v-model="radio2"
                    v-for="(item, index) in ProductColor"
                    :key="index"
                    @change="imageChange(index)">
                    <el-radio-button plain :label="index" class="image">
                      <img :src="item" alt="" />
                    </el-radio-button>
                  </el-radio-group>
                </template>
                <template v-else>
                  <div>
                    <span class="labelname">{{ $t(attr.AttributesName) }}</span>
                  </div>
                  <el-radio-group
                    v-model="radio1"
                    v-for="(item, index) in Productimage"
                    :key="index"
                    @change="radioChange(index)">
                    <el-radio-button plain :label="item" class="s"></el-radio-button>
                  </el-radio-group>
                </template>
              </div>
            </div>
          </template>
          <h3
            style="
              position: absolute;
              margin: 0px 0px 2px 0rem;
              font-family: Regular;
              -webkit-font-smoothing: antialiased;
              margin-top: 20px;">
            {{ $t('productDetails.Description') }}
          </h3>
          <div
            id="storyline"
            class="storyline"
            :class="[hideWords ? 'deschide' : '']"
            v-html="productDetail.Description">
            {{ productDetail.Description }}
          </div>
        </div>
        <div class="product-details" v-if="isImg">
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926751838_xinbai.jpg"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
export default {
  data: () => {
    return {
      dispatch: null,
      page: 'ProductInfo',
      banner: '',
      itemname: '',
      productId: '', //商品id
      productType: '', //商品类型
      productDetail: {}, //商品详情
      isImg: false, //是否包含图片
      hideWords: true, //是否隐藏文字
      radio1: '',
      radio2: '0',
      imgindex: '0', //控制变量，默认显示第一张
      timer: null, //定时器
      code: true, //判断类别，后面用详情字段替代
      Productindex: 0, //产品索引
      imageindex: 0, //
      Productimage: [],
      ProductColor: [], // 产品的颜色图片
      ProductPic: [], // 产品价格
      Productimg: [], // 产品的颜色
      Price: 0,
      currentUrl: '',
      ThumbPhotos: []
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
    if (!this.$route.query.id) { // 未找到商品id
    }
    this.productType = this.$route.query.type
    this.productId = this.$route.query.id
  },
  components: {
    vuePhotoZoomPro
  },
  mounted() {
    this.banner = this.$t('productDetails.bannername')
    this.itemname = this.$t('productDetails.itemname')
    this.getDate()
  },
  computed: mapState(['userInfo', 'theme']),
  methods: {
    showSpec(index) {
      if (this.ProductColor[index]) {
        this.currentUrl = this.ProductColor[index]
      }
    },
    calcPrice() {
      var colorName = ''
      if (this.Productimg.length > 0) {
        colorName = this.Productimg[this.imageindex]
      }
      if (this.productDetail.ProductType === 'normal') {
        if (this.productDetail.SpecList) {
          var spec = this.productDetail.SpecList[0]
          if (spec) {
            this.Price = spec.Price
          }
        }
      } else {
        if (this.productDetail.Attributes && this.productDetail.Attributes.length > 1) {
          this.productDetail.SpecList.map((x) => {
            if (x.Spec2 == 'Color') {
              if (x.SpecValue1 == this.radio1 && x.SpecValue2 == colorName) {
                this.Price = x.Price
              }
            } else {
              if (x.SpecValue1 == colorName && x.SpecValue2 == this.radio1) {
                this.Price = x.Price
              }
            }
          })
        } else {
          this.productDetail.SpecList.map((x) => {
            if (x.SpecValue1 === colorName) {
              this.Price = x.Price
            }
          })
        }
      }
    },
    imageChange(val) {
      this.imageindex = val
      this.calcPrice()
      this.showSpec(val)
    },
    radioChange(val) {
      this.Productindex = val
      this.calcPrice()
    },
    next: function (event) {  // 下一张
      if (event && event.currentTarget) {
        clearInterval(this.timer)
      }
      if (this.ThumbPhotos && this.imgindex == this.ThumbPhotos.length - 1) {
        this.imgindex = 0
        this.ThumbPhotos.map((item, index) => {
          if (index <= 3) {
            item.isDisplay = true
          } else {
            item.isDisplay = false
          }
        })
      } else {
        this.imgindex++
        if (this.ThumbPhotos[this.imgindex]) {
          this.ThumbPhotos[this.imgindex].isDisplay = true
        }
        if (this.ThumbPhotos[this.imgindex - 4]) {
          this.ThumbPhotos[this.imgindex - 4].isDisplay = false
        }
      }
      this.currentUrl = this.productDetail.PhotoList[this.imgindex].Imageurl
    },
    prev: function (event) {  // 上一张
      if (event && event.currentTarget) {
        clearInterval(this.timer)
      }
      if (this.imgindex <= 0) {
        this.imgindex = this.ThumbPhotos.length - 1
        this.ThumbPhotos.map((item, index) => {
          if (index <= this.imgindex - 4) {
            item.isDisplay = false
          } else {
            item.isDisplay = true
          }
        })
      } else {
        this.imgindex--
        if (this.ThumbPhotos[this.imgindex]) {
          this.ThumbPhotos[this.imgindex].isDisplay = true
        }
        if (this.ThumbPhotos[this.imgindex + 4]) {
          this.ThumbPhotos[this.imgindex + 4].isDisplay = false
        }
      }
      this.currentUrl = this.productDetail.PhotoList[this.imgindex].Imageurl
    },
    select(index) {   // 通过点击小圆点获取对应的索引，进行赋值；
      this.imgindex = index
      if (this.productDetail.PhotoList) {
        this.currentUrl = this.productDetail.PhotoList[this.imageindex].Imageurl
      }
    },
    enter: function () {  // 鼠标移入view
      clearInterval(this.timer)
    },
    leave: function () {
      this.timer = setInterval(this.next.bind(this), 2000)
    },
    getDate() { // 获取我的产品页面的详情
      this.dispatch('pending/GetDetailPd', {
          Id: Number(this.productId)
        })
        .then((res) => {
          let productDetail = res.Result
          var result = []
          var obj = {}
          for (var i = 0; i < productDetail.SpecList.length; i++) {
            if (!obj[productDetail.SpecList[i].SpecValue1]) {
              result.push(productDetail.SpecList[i])
              obj[productDetail.SpecList[i].SpecValue1] = true
            }
          }
          this.ProductPic = result
          if (res.Result.ProductType == 'normal') {
            this.productDetail = productDetail
          } else {
            var hasColor = productDetail.Attributes.some((item) => {
              if (item.AttributesName.toLowerCase() === 'color') {
                return true
              }
            })
            console.log('hasColor:' + hasColor)
            let isFirst = true
            productDetail.Attributes.map((item) => {
              if (hasColor) {
                if (item.AttributesName.toLowerCase() === 'color') {
                  let arr = []
                  arr = item.PicList
                  this.ProductColor = arr
                  this.Productimg = item.AttributesValue.split(',')
                } else {
                  item.AttributesValue = item.AttributesValue.split(',')
                  for (let i = 0; i < item.AttributesValue.length; i++) {
                    this.Productimage.push(item.AttributesValue[i])
                    this.radio1 = this.Productimage[0]
                  }
                }
              } else {
                if (isFirst) {
                  let arr = []
                  arr = item.PicList
                  this.ProductColor = arr
                  this.Productimg = item.AttributesValue.split(',')
                } else {
                  item.AttributesValue = item.AttributesValue.split(',')
                  for (let i = 0; i < item.AttributesValue.length; i++) {
                    this.Productimage.push(item.AttributesValue[i])
                    this.radio1 = this.Productimage[0]
                  }
                }
                isFirst = false
              }
            })
            this.productDetail = productDetail
          }
          this.calcPrice()
          if (this.productDetail.PhotoList) {
            this.productDetail.PhotoList.map((item, index) => {
              this.ThumbPhotos.push({
                url: item.Imageurl,
                isDisplay: index <= 3 ? true : false
              })
            })
            this.currentUrl = this.productDetail.PhotoList[this.imageindex].Imageurl
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  font-family: 'Regular';
}
.activeName {
  width: 1320px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 50px;
  font-family: 'Regular';
}
.Details-figure { /* 详情图 */
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
  overflow: hidden;
}
.AliexpressLink_SourceUrl {
  margin-left: 390px;
  margin-top: 10px;
  word-break: normal;
  width: 200px;
}
.focus {
  padding-top: 50px;
  width: 624px;
  float: left;
}
.banner .img {
  width: 560px;
  height: 560px;
  position: relative;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.img {
  float: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.banner {
  width: 560px;
  height: 560px;
  margin-left: 64px;
  position: relative;
  z-index: 9;
}
::v-deep .el-carousel__container {
  height: 560px;
}
.trans {
  transition: all 0.5s;
}
.indicators {
  margin-left: 60px;
  margin-top: 22px;
  height: 100px;
  img {
    width: 100px;
    height: 100px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    margin-left: 35px;
    margin-top: 10px;
  }
  .con {
    width: 100px;
    height: 100px;
    margin-left: 11px;
  }
  .displayX {
    display: none;
  }
}
.prev {
  position: absolute;
  top: 678px;
  left: 30px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}
.next {
  position: absolute;
  top: 678px;
  left: 630px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}
.synopsis { /* <!-- 简介 --> */
  font-size: 20px;
  width: 560px;
  float: right;
  margin-right: 65px;
  padding-top: 50px;
  font-family: 'Regular';
}
.intro {
  font-size: 18px;
  font-family: 'Regular';
  font-weight: 300;
  color: #191919;
}
.price {
  margin-top: 36px;
  font-family: 'Regular';
  span:nth-child(1) {
    font-size: 18px;
    font-weight: 300;
    color: #555656;
    line-height: 25px;
  }
  span:nth-child(2) {
    font-size: 30px;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
  }
}
.measure {
  margin-top: 36px;
  font-family: 'Regular';
  span {
    font-size: 18px;
    font-weight: 300;
    color: #555656;
  }
}
.labelname {
  display: inline-block;
}
::v-deep .measure .el-radio-button__inner {
  display: inline-block;
  height: 30px;
  line-height: 28px !important;
  border-radius: 50px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border: 1px solid #dcdfe6;
  margin-right: 24px;
  font-family: 'Regular';
}
::v-deep .measure .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: 'Regular';
}
::v-deep .measure .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: 'Regular';
}
::v-deep .measure .el-radio-button__inner {
  transition: all 0s linear;
}
::v-deep .measure .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: 'Regular';
}
.picture {
  margin-top: 21px;
  font-family: 'Regular';
  span {
    font-size: 18px;
    font-weight: 300;
    color: #555656;
  }
}
::v-deep .picture .el-radio-button__inner {
  display: inline-block;
  width: 85px;
  height: 85px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border: 2px solid #dcdfe6;
  margin-right: 24px;
  font-family: 'Regular';
}
.picture .el-radio-button__inner img {
  width: 80px;
  height: 80px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
::v-deep .picture .el-radio-group {
  margin-top: 10px;
  font-family: 'Regular';
}
.picture .labelname {
  font-family: 'Regular';
}
::v-deep .picture .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: 'Regular';
}
::v-deep .picture .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: 'Regular';
}
::v-deep .picture .el-radio-button__inner {
  transition: all 0s linear;
}
::v-deep .picture .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
}
.address {
  margin-top: 21px;
  font-family: 'Regular';
  span {
    font-size: 18px;
    font-weight: 300;
    color: #555656;
  }
}
::v-deep .address .el-radio-button__inner {
  display: inline-block;
  width: 75px;
  height: 30px;
  line-height: 28px !important;
  border-radius: 50px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border: 1px solid #dcdfe6;
}
.address .sellcountry {
  margin-right: 14px;
}
.storyline {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-top: 70px;
  line-height: 30px;
  border-top: 1px solid #000000;
}
::v-deep .storyline .detailmodule_html {
  width: 100% !important;
  text-align: center;
  font-family: 'Regular';
}
::v-deep .storyline img {
  max-width: 550px;
  height: auto;
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
::v-deep .storyline p {
  width: 100% !important;
  text-align: left;
  padding: 0 1rem;
  font-family: 'Regular';
}
::v-deep .storyline span {
  width: 100% !important;
  text-align: center;
  font-size: 13pt !important;
  word-break: keep-all;
  font-family: 'Regular';
}
.deschide {
  overflow: hidden;
  width: 100%;
}
::v-deep .deschide .detailmodule_html {
  text-align: left;
  font-family: 'Regular';
}
::v-deep .deschide .detailmodule_html img {
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
::v-deep .deschide .detailmodule_html p {
  width: 100% !important;
  text-align: left;
  font-family: 'Regular';
}
::v-deep .deschide .detailmodule_html span {
  width: 100% !important;
  text-align: left;
  font-family: 'Regular';
}
.Product button {
  display: inline-block;
  margin-top: 70px;
  margin-left: 180px;
  width: 200px;
  height: 55px;
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  font-size: 16px;
  font-family: 'Regular';
  font-weight: 400;
  color: #ffffff;
}
::v-deep .Product .el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background: #f2f2f2;
  color: #b6b6b6;
  font-family: 'Regular';
}
.product-details {
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  margin-top: 32px;
  position: relative;
  background: #f9fafb;
}
.product-details img {
  width: 1184px;
  height: 2089px;
  margin: 68px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.el-tabs__active-bar {
  top: 30px;
  border-radius: 20px !important;
}
</style>
