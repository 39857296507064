var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showCropDialog,
        title: "Crop Image",
        width: "50%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showCropDialog = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showCropDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onCrop } },
                    [_vm._v(" Crop ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "cropper-container" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.image,
                expression: "image",
              },
            ],
            ref: "cropperImage",
            attrs: { src: _vm.image, alt: "Image for cropping" },
          }),
        ]),
        _c(
          "div",
          { staticClass: "zoom-container" },
          [
            _c(
              "el-button-group",
              { staticClass: "ml-2", attrs: { size: "small" } },
              [
                _c(
                  "el-button",
                  { attrs: { round: "" }, on: { click: _vm.onZoomOut } },
                  [_c("i", { staticClass: "el-icon-zoom-out" })]
                ),
                _c(
                  "el-button",
                  { attrs: { round: "" }, on: { click: _vm.onZoomIn } },
                  [_c("i", { staticClass: "el-icon-zoom-in" })]
                ),
                _c(
                  "el-button",
                  { attrs: { round: "" }, on: { click: _vm.onReset } },
                  [_c("i", { staticClass: "el-icon-refresh-left" })]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }