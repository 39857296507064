var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "print-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "modalDialog",
          attrs: {
            draggable: true,
            title: "Add to print file group",
            visible: _vm._showDialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm._showDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { height: "200px" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.categories, function (category) {
              return _c(
                "el-tab-pane",
                {
                  key: category.id,
                  attrs: { label: category.name, name: category.id + "" },
                },
                [
                  _c("PrintImages", {
                    attrs: {
                      imgList: _vm.imgList[category.id],
                      tabName: category.id,
                      showUpload: category.id === "myFile",
                    },
                    on: {
                      enterSearch: _vm.enterSearch,
                      customUploadRequest: _vm.customUploadRequest,
                      checkImg: _vm.checkImg,
                      delImg: _vm.delImg,
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "tab-loading",
                    },
                    [_c("i", { staticClass: "el-icon-loading" })]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("span", { staticClass: "selected" }, [
                _vm._v(" " + _vm._s(_vm.selectedImgs.length) + " selected"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.confirmEditImgName },
                },
                [_vm._v(" Add ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }