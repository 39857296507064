<template>
  <div class="particulars souringUrl" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="Sourcing">{{ $t('SourcingUrl.aliexpress') }}</div>
      <div class="speed-progress" v-if="Fulfilled">
        <div class="progress-fulfilled">
          <span>{{ $t('SourcingUrl.ProductsSourced') }}</span>
          <span>{{ MembershipInterests.UsedTimes }}</span>
          <span>/</span>
          <span>{{ MembershipInterests.TotalTimes }}</span>
        </div>
        <el-progress
          :percentage="pmgressbar"
          style="width: 130px; margin-top: 8px"
          :show-text="false"
        ></el-progress>
        <div class="MonthlyRed" @click="MonthlyRed()">
          <span v-if="paymentList.GradeId == 1">
            <img src="../../assets/imgs/NewLogo/MemberJICHUSlice.png" alt="" />
          </span>
          <span v-if="paymentList.GradeId == 2">
            <img src="../../assets/imgs/NewLogo/MemberVIPSlice16.png" alt="" />
          </span>
          <span v-if="paymentList.GradeId == 3">
            <img src="../../assets/imgs/NewLogo/MemberSVIPSlice16.png" alt="" />
          </span>
        </div>
        <div
          v-if="MembershipInterests.DayLimit == true && MembershipInterests.DayRemainTimes == 0"
          class="IconQuestion"
        >
          <i class="el-icon-question" @mouseenter="enter" @mouseleave="leave"></i>
        </div>
        <div class="DisplayInfo" v-if="displayUSAdropInfo">
          <p>{{ $t('SourcingUrl.HaveToday') }}</p>
          <p>{{ $t('SourcingUrl.SalesmanAgent') }}</p>
        </div>
      </div>
      <!--  <div class="PromptStatement">
        <p> <span style="color:red;font-size:20px;">*</span> We are proud to offer most if not all products available and will be glad</p>
        <p style="margin-left: 15px;">to assist you in finding what you're looking for. Contact agent now!</p>
      </div> -->
      <div class="ProductLink">
        <input
          type="text"
          :placeholder="$t('SourcingUrl.product')"
          @input="handlerPageNo"
          v-model="searchUrl"
        />
      </div>

      <div class="Destination">
        <span class="required">*</span>
        <span>{{ $t('SourcingUrl.ShipTo') }}</span>
      </div>
      <div class="Country">
        <el-select
          v-model="selectCountrys"
          multiple
          filterable
          :multiple-limit="multiplelimit"
          :placeholder="$t('SourcingUrl.selctCountry')"
          class="Country1"
        >
          <el-option
            v-for="item in countrys"
            :key="item.index"
            :label="item.EnglishName"
            :value="item.DoubleCharacterCode"
          >
          </el-option>
        </el-select>
      </div>
      <div class="CheckAttribute">
        <div class="Destination">
          <span class="required">*</span>
          <span>{{ $t('SourcingUrl.FillQuote') }}</span>
        </div>
        <el-input
          class="Country1"
          v-model="DesiredAttribute"
          :placeholder="$t('SourcingUrl.FillQuote')"
        ></el-input>
      </div>
      <div slot="footer" class="dialog_footer">
        <!--  <el-button class="Cancel" @click="dialogFormVisible">{{
          $t("products.reset")
        }}</el-button> -->
        <el-button class="Save btn-bg0" type="primary" @click.stop="save" v-if="SaveConfirm == 0">{{
          $t('SourcingUrl.Confirm')
        }}</el-button>
        <el-button
          class="Save"
          type="primary"
          disabled
          @click.stop="save"
          v-show="SaveConfirm == 1"
          >{{ $t('SourcingUrl.Confirm') }}</el-button
        >
      </div>
    </div>
    <div class="member_box" v-if="memberPopupBox">
      <div class="member_set">
        <div class="member_content">
          <div class="member_top">
            <img src="../../assets/imgs/UpgradeToPro/HuoJianAnYe.png" alt="" />
          </div>
          <div class="member_down">
            <p>{{ $t('SourcingUrl.YouHave') }}</p>
            <p>{{ $t('SourcingUrl.Upgrade') }}</p>
          </div>
          <div class="Bindstore">
            <p>
              <span style="color: red; font-size: 20px">*</span>
              {{ $t('SourcingUrl.BindMembership') }}
            </p>
          </div>
        </div>
        <div class="member_btn">
          <el-button @click="ComparePriceBtn">{{ $t('SourcingUrl.ViewPlan') }}</el-button>
          <el-button @click="AddStoreBtn">{{ $t('SourcingUrl.AddStore') }}</el-button>
        </div>
      </div>
    </div>
    <!-- 联系业务员 -->
    <div class="Agent_box" v-if="displayAgent">
      <div class="Agent_set">
        <div class="member_Close_btn" @click="CloseAgentBtn">
          <i class="el-icon-remove-outline"></i>
        </div>
        <div class="Agent_content">
          <div class="Agent_top">
            <p><img src="../../assets/imgs/header/ziyuan1.png" alt="" /></p>
          </div>
          <div class="Agent_down">
            <p>{{ $t('SourcingUrl.Reached') }}</p>
            <p>{{ $t('SourcingUrl.pleaseTomorrow') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data: () => {
    return {
      dispatch: null,
      page: 'sourcing',
      banner: '',
      itemname: '',
      activeName: 'first',
      searchUrl: '', //搜索链接
      countrys: [], //国家列表
      multiplelimit: Number(3), // 选择国家最多为3
      selectCountrys: [], //已选国家
      ComparePrice: false,
      MembershipInterests: [],
      memberPopupBox: false,
      Fulfilled: true,
      pmgressbar: 0,
      SaveConfirm: 0,
      paymentList: [],
      displayUSAdropInfo: false,
      displayAgent: false,
      DesiredAttribute: '' //DesiredAttribute
    }
  },
  components: {},
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.globalClick(this.CloseBtn)
    this.banner = this.$t('products.bannername')
    this.itemname = this.$t('products.itemname')
    this.getData()
    this.Membership()
    this.menterData()
    const _self = this
    document.onkeydown = function (e) {
      let key = window.event.keyCode
      if (key === 13) {
        _self.save()
        /* console.log(e, '123') */
      }
    }
  },
  computed: mapState(['userInfo', 'theme']),
  methods: {
    enter: function (e) {
      this.displayUSAdropInfo = true
    },
    leave: function (e) {
      this.displayUSAdropInfo = false
    },
    CloseAgentBtn() {
      this.displayAgent = false
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          // 价格重置
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    MonthlyRed() {
      this.$router.push('/Upgrade')
    },
    Membership() {
      let that = this
      that
        .dispatch('memberRights/GetRightsWithSourcingMr')
        .then((res) => {
          that.MembershipInterests = res.Result
          that.pmgressbar =
            (that.MembershipInterests.UsedTimes / that.MembershipInterests.TotalTimes) * 100
          if (this.MembershipInterests.TotalTimes == '-1') {
            this.Fulfilled = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ComparePriceBtn() {
      this.$router.push('/Upgrade')
    },
    AddStoreBtn() {
      this.$router.push('/Store')
    },
    CloseBtn(event) {
      this.memberPopupBox = false
    },
    dialogComparePrice() {
      this.ComparePrice = true
    },
    handlerPageNo() {
      this.searchUrl = this.searchUrl.split('?')[0]
      console.log(this.searchUrl)
    },
    // 获取国家列表
    getData() {
      let that = this
      that
        .dispatch('souring/GetSourcingCountryListSr')
        .then((res) => {
          that.countrys = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 重置按钮
    dialogFormVisible() {
      this.searchUrl = ''
      this.selectCountrys = []
    },
    // 添加按钮
    save() {
      this.Membership()
      if (this.MembershipInterests.Enabled == true) {
        if (this.searchUrl == '') {
          return this.$message.warning(this.$t('SourcingUrl.Url'))
        } else {
          this.searchUrl = this.searchUrl.split('?')[0]
        }
        if (this.selectCountrys == '') {
          return this.$message.warning(this.$t('SourcingUrl.cannot'))
        }
        if (this.DesiredAttribute == '') {
          return this.$message.warning(this.$t('SourcingUrl.TheEmpty'))
        }
        if (this.SaveConfirm === 1) {
          return
        }
        this.SaveConfirm = this.SaveConfirm === 0 ? 1 : 1
        let that = this
        let loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        that
          .dispatch('souring/CrawlProductPostSr', {
            url: this.searchUrl,
            type: Number(2),
            quoteCountryString: this.selectCountrys,
            WanaQuoteProperty: this.DesiredAttribute //Sourcing文本框
          })
          .then((res) => {
            loading.close()
            if (res?.Success) {
              this.$message.success(this.$t('SourcingUrl.successfully'))
              if (res.ResData == 'auto') {
                this.$router.push({
                  name: 'Sourcing',
                  params: {
                    type: '1'
                  }
                })
              } else {
                this.$router.push({
                  name: 'Sourcing',
                  params: {
                    type: '0'
                  }
                })
              }
              /* this.$router.push("/Sourcing"); */
              that.searchUrl = ''
              that.selectCountrys = []
              setTimeout(() => {
                this.SaveConfirm = this.SaveConfirm === 0 ? 1 : 0
              }, 5000)
            } else {
              setTimeout(() => {
                location.reload()
              }, 3000)
              that.searchUrl = ''
              that.selectCountrys = []
              this.$message.error(this.$t('SourcingUrl.TheLinkFailed'))
            }
          })
          .catch((err) => {
            this.$message.error(this.$t('SourcingUrl.failed'))
          })
        /* this.memberPopupBox = false */
      } else {
        if (this.MembershipInterests.RemainTimes == 0) {
          this.memberPopupBox = true
        } else {
          this.displayAgent = true
        }
      }
      /*  this.memberPopupBox = false */

      /* this.memberPopupBox = true */

      // var reg=/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      // if(!reg.test(this.searchUrl)){
      // 	return this.$message.warning(this.$t("products.incorrect"))
      // }
    }
  }
}
</script>
<style scoped>
.Agent_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_set {
  width: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  left: calc(60% - 400px);
  top: calc(50% - 300px);
  margin-top: 160px;
  background-color: #5c5a86;
  height: 250px;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_top {
  display: flex;
}
.Agent_top p:nth-child(1) img {
  margin-top: 65px;
  width: 345px;
  margin-left: 15px;
}
.Agent_top p:nth-child(2) {
  margin-top: 65px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_down {
  width: 380px;
  height: 200px;
  margin-top: 30px;
}
.Agent_down p:nth-child(1) {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Agent_down p:nth-child(2) {
  text-align: center;
  font-size: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_Close_btn {
  position: absolute;
  top: 8px;
  right: 10px;
}
::v-deep .member_Close_btn .el-icon-remove-outline {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
.IconQuestion {
  position: absolute;
  top: 2px;
  left: 235px;
}
::v-deep .IconQuestion .el-icon-question {
  font-size: 20px;
  color: #3f6aff;
}
.PromptStatement {
  position: absolute;
  top: 10px;
  right: 40px;
}
.PromptStatement p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 30px;
}
.DisplayInfo {
  width: 360px;
  height: 55px;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  left: 265px;
  top: -10px;
}
.DisplayInfo p:nth-child(1) {
  font-size: 14px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
  padding-left: 10px;
}
.DisplayInfo p:nth-child(2) {
  font-size: 14px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
}
.member_title {
  width: 12px;
  height: 12px;
  background: url('../../assets/imgs/close.png') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
}
.MonthlyRed {
  position: absolute;
  top: 0px;
  left: 205px;
  cursor: pointer;
}
.MonthlyRed span img {
  width: 22px;
  height: 22px;
}
.MonthlyRed span img:hover {
  transform: scale(1.1);
}
.el-select-dropdown__item {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_set {
  width: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  left: calc(60% - 400px);
  top: calc(50% - 450px);
  margin-top: 160px;
  background-color: #2a293e;
  height: 500px;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_top {
  width: 230px;
  height: 220px;
  margin-left: 50px;
}
.member_top img {
  width: 100%;
  height: 100%;
  margin-top: 35px;
  margin-left: 20px;
}
.member_down {
  width: 380px;
  margin-top: 0px;
}
.member_down p:nth-child(1) {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_down p:nth-child(2) {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Bindstore p:nth-child(1) {
  color: #fff;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  word-break: normal;
}
.member_btn {
  margin-left: 55px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border-radius: 50px;
  margin-top: 20px;
  display: flex;
}
.member_btn >>> .el-button:nth-child(1) {
  margin-left: 5px;
  height: 45px;
  margin-bottom: -150px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn >>> .el-button:nth-child(2) {
  margin-left: 30px;
  height: 45px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn >>> .el-button:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}
.member_btn >>> .el-button:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}
.AliexpressBtn {
  position: absolute;
  top: 20px;
  right: 50px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #4c8bf5;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-input--suffix input {
  border: none;
  background: none;
  display: block;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dialog_footer {
  margin-top: 35px;
  margin-left: 403px;
}
.CheckAttribute {
  margin-top: 20px;
  position: relative;
}
.Cancel {
  width: 200px;
  height: 48px;
  border: 2px solid #409efe !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  color: #4c8bf5 !important;
  background-color: #fff;
}

.Save {
  margin-left: 84px !important;
  width: 214px;
  height: 48px;
  border: none;
  border-radius: 50px !important;
  font-size: 16px !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #474882;
}
.Save:hover {
  background: #3c3772 !important;
  color: #fff;
}
</style>
<style lang="scss" scoped>
.required {
  color: #ff0000;
}
@mixin inputS {
  width: 511px;
  margin-left: 40px;
  margin-top: 20px;
  background: #f9fafb;
  border: 1px solid #d7d7d8;
  border-radius: 50px;
  overflow: hidden;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.souringUrl {
  width: 100%;
  min-height: calc(100vh - 80px);
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  font-family: PingFang SC-Regular, PingFang SC;
  .activeName {
    width: 1320px;
    padding-bottom: 60px;
    box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
    border-radius: 10px;
    position: relative;
    font-family: Poppins-Regular, Poppins;
    color: #333333;
    background: #ffffff;
    .speed-progress {
      width: 240px;
      height: 60px;
      position: absolute;
      top: 27px;
      left: 200px;
      font-size: 14px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .progress-fulfilled {
        span {
          // &:nth-child(1) {
          //   margin-right: 10px;
          // }
          &:nth-child(2) {
            margin-left: 20px;
          }
          &:nth-child(3) {
            margin-left: 5px;
          }
          &:nth-child(4) {
            margin-left: 5px;
          }
        }
      }
    }
    .Sourcing {
      width: 100%;
      height: 80px;
      line-height: 80px;
      background: #f9f9f9;
      font-size: 16px;
      padding-left: 50px;
      font-family: 'Regular';
    }
    .ProductLink input {
      height: 50px;
      width: calc(100% - 80px);
      margin: 40px;
      // background: #ffffff;
      border: 1px solid #878787;
      border-radius: 24px;
      padding-left: 34px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .Destination {
      display: flex;
      align-items: flex-start;
      padding-left: 40px;
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .Country {
      .Country1 {
        @include inputS;
      }
    }
    .CheckAttribute {
      .Country1 {
        @include inputS;
        ::v-deep .el-input__inner {
          border-radius: 25px;
          border: none;
          font-family: 'Regular';
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }
}
</style>
