<template>
  <div class="page">
    <div class="content">
      <div class="block"></div>
      <!-- 重置密码 -->
      <div class="reset" v-show="type == 0">
        <div class="resetpasswords">
          <div class="registration">
            <span>{{ $t("password.retrieve") }}</span>
          </div>
         <!--<div class="retrieve">{{ $t("password.retrieve") }}</div>-->
          <div class="restContent">
            <div class="enter-password mt40">
              <input v-model="input" :placeholder="$t('password.verification')" />
            </div>
            <div class="resetPad">
              <button class="comBtn btn-bg6 col-col0" @click="BackBtn">{{ $t("password.back") }}</button>
              <button class="comBtn btn-bg0" @click="checkType(1)">{{ $t("password.next") }}</button>
            </div>
          </div>

        </div>
      </div>
      <!-- 重置密码1 -->
      <div class="reset" v-show="type == 1">
        <div class="resetpasswords">
          <div class="registration">
            <span>{{ $t("password.user") }}</span>
          </div>
          <div class="restContent">
           <div class="enter-password mt40">
              <input type="text" :placeholder="$t('password.enter')" v-model="oldPassword" show-password>
          </div>

          <div class="enter-password mt40">
            <!-- <p style="width: 500px;height:40px;"> -->
              <input type="text" :placeholder="$t('password.confirm')" v-model="NewPassword " show-password>
            <!-- </p> -->
          </div>
          <div class="resetPad">
            <button class="comBtn btn-bg0 mr10" @click="forgetTap">{{ $t("password.modification") }}</button>
            <el-link type = "primate" :underline="false" style="margin-left:5px;" href = "/login">{{ $t("password.BackLogin") }}</el-link>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      dispatch: null,
      name: "password",
      type: 0,
      input: "",
      oldPassword: "",
      NewPassword: "",
      meaasge: [],
    };
  },
  mounted() {
  },
  created() {
    let that = this;
    that.dispatch = that.$store.dispatch
    // 获取授权时获取的id Email
    let search = decodeURIComponent(location.search);
    if (search) {
      let params = {};
      search = search.substring(1).split("&");
      search.map(item => {
        let index = item.indexOf("=");
        let key = item.substring(0, index);
        let value = item.substring(index + 1).split("#")[0];
        params[key] = value;
        that.meaasge.push(value)
      });
      this.type = 1;
      // con
    }
  },
  methods: {
    checkType(type) {
      if (this.input == '') {
        return this.$message.error(this.$t('password.empty'))
      } else {
        this.getData()
        // this.type = type;
      }
    },
    // 重置密码
    getData() {
      let that = this;
      that.dispatch('password/ResetPwdPs',JSON.stringify(that.input))
        .then(res => {
          if (res.Success == true) {
            that.$router.push({
              name: "verificationCode",
              params: {
                value: that.input
              }
            });
          } else {
            that.$message.error(res.ErrMsg)
            return
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    forgetTap(){
      if (this.oldPassword == '' || this.NewPassword == "") {
        return this.$message.error(this.$t('password.cannot'))
      } else {
        this.passwordData()
        //this.$router.push("login")
      }
    },
    passwordData() {
      let that = this;
      that.axios({
        method: "post",
        url: "/api/Login/ResetPassword",
        data: {
          Email: this.meaasge[1],
          Id: this.meaasge[0],
          NewPassword: this.NewPassword
        }
      })
      .then(res => {
        if(res.data.Success){
          that.$message.success(that.$t('password.successfully'))
          that.$router.push("login")
        }else{
          console.dir(res)
          if(res.data.ErrCode == "RESET_GUID_INVALID"){
            that.$message.error(that.$t('password.link_valid'))
          }else{
            that.$message.error(that.$t('password.failure'))
          }
        }
      })
      .catch(err => {
        that.$message.error(that.$t('password.failure'))
        console.log(err);
      });
    },
    BackBtn() {
      this.$router.history.go(-1);
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .enter-passwordOne .el-input__inner {
  outline: none;
  background: none;
  border:1px solid #ccc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .enter-passwordTwo .el-input__inner {
  outline: none;
  background: none;
  border:1px solid #ccc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt40 {
  margin-bottom: 40px!important;
}
.mr10 {
  margin-right: 10px!important;
}
.enter-password {
  input {
    width: 530px;
    height: 58px;
    border: 1px solid #000000;
    border-radius: 38px;
    padding-left: 24px;
    font-size: 16px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #999999;
  }

}
.content {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 280px;
  background: #15104B;
  // overflow: hidden;
  .reset {
    border-radius: 20px;
    width: 840px;
    // height: 312px;
    background: #ffffff;
    margin: 0 auto;
    box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.13);
    .resetpasswords {
      .registration {
          height: 80px;
          font-size: 20px;
          border-radius: 20px 20px 0 0;
          font-family: "Regular";
          -webkit-font-smoothing: antialiased;
          color: #333333;
          @include flex;
          border-bottom:1px solid #E4E6EF;;
        }
        .restContent {
          // height: 232px;
          padding: 50px 0 40px;
          @include flex;
          justify-content: space-between;
          flex-direction: column;
          .resetPad {
            width: 100%;
            height: 48px;
            display: flex;
            font-size: 16px;
            justify-content: center;
            button {
              width: 200px;
              height: 42px;
              &:nth-child(1) {
              margin-right: 40px;
              font-weight: 600;
              }
            }
          }
        }
      }


  }
}

.NEXT {
  width: 841px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin-top: 36px;
}

.NEXT button {
  width: 334px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  color: #fff;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}


/* 重置密码1 */
.reset1 {
  width: 841px;
  height: 501px;
  background: #ffffff;
  position: absolute;
  top: auto;
  left: auto;
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  overflow: hidden;
}

.reset1 .retrieve {
  margin-top: 45px;
  margin-left: 154px;
  margin-left: 154px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.reset1 .retrieve1 {
  margin-top: 21px;
  margin-left: 154px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #000000;
  opacity: 0.4;
}

.reset1 .enter-password input {
  margin-top: 36px;
  margin-left: 154px;
  width: 529px;
  height: 58px;
  border: 1px solid #000000;
  border-radius: 38px;
  padding-left: 24px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #000000;
}

.reset1 .enter-password button {
  position: relative;
  top: 0;
  left: -60px;
  display: inline-block;
  border: 0;
  line-height: 18px;
  text-align: center;
}

.reset1 .NEXT {
  margin-top: 36px;
}

.reset1 .NEXT button {
  height: 42px;
  line-height: 42px;
}
</style>
