var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "validateFormRef",
            attrs: {
              model: _vm.formData,
              "label-position": "left",
              "label-width": "auto",
              rules: _vm.rules,
              disabled: _vm.readonly,
              "hide-required-asterisk": _vm.readonly,
              "show-message": false,
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.ContactName"),
                          prop: "Contact",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.Contact,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "Contact", $$v)
                            },
                            expression: "formData.Contact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.Address"),
                          prop: "Address",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { type: "textarea" },
                          model: {
                            value: _vm.formData.Address,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "Address", $$v)
                            },
                            expression: "formData.Address",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.Country"),
                          prop: "Country",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "" },
                            model: {
                              value: _vm.formData.Country,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "Country", $$v)
                              },
                              expression: "formData.Country",
                            },
                          },
                          _vm._l(_vm.countryList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.AddressTwo"),
                          prop: "Address2",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { type: "textarea" },
                          model: {
                            value: _vm.formData.Address2,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "Address2", $$v)
                            },
                            expression: "formData.Address2",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.States"),
                          prop: "Province",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.Province,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "Province", $$v)
                            },
                            expression: "formData.Province",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.phone"),
                          prop: "TelePhone",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.TelePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "TelePhone", $$v)
                            },
                            expression: "formData.TelePhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.PostCode"),
                          prop: "ZipCode",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.ZipCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "ZipCode", $$v)
                            },
                            expression: "formData.ZipCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("SupportTicket.City"),
                          prop: "City",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.City,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "City", $$v)
                            },
                            expression: "formData.City",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }