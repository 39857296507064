var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-login" }, [
    _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: { src: _vm.logoImg[_vm.formLink], alt: "" },
      }),
      _vm._m(0),
    ]),
    _c(
      "div",
      { staticClass: "login-content" },
      [
        _c("img", {
          staticClass: "logo2",
          attrs: { src: _vm.logoImgOne[_vm.formLink], alt: "" },
        }),
        _c("p", { staticClass: "login-head" }, [_vm._v("Welcome to USAdrop")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: _vm.$t("login.email"), prop: "email" },
              },
              [
                _c("el-input", {
                  attrs: { size: "large" },
                  model: {
                    value: _vm.ruleForm.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "email", $$v)
                    },
                    expression: "ruleForm.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: _vm.$t("login.password"), prop: "password" },
              },
              [
                _c("el-input", {
                  attrs: {
                    size: "large",
                    type: "password",
                    "show-password": "",
                  },
                  model: {
                    value: _vm.ruleForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "password", $$v)
                    },
                    expression: "ruleForm.password",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "forget" }, [
              _c("p", { on: { click: _vm.forgetTap } }, [
                _vm._v(_vm._s(_vm.$t("login.forget"))),
              ]),
            ]),
            _c("el-form-item", [
              _c("div", { staticClass: "loginbox" }, [
                _c(
                  "div",
                  {
                    staticClass: "submit btn-bg0",
                    on: {
                      click: function ($event) {
                        return _vm.signIn(_vm.ruleForm)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("login.gobtn")) + " ")]
                ),
                _c("p", { staticClass: "noaccount mt10" }, [
                  _vm._v(" " + _vm._s(_vm.$t("login.sign")) + " "),
                  _c(
                    "span",
                    {
                      staticClass: "col-col5",
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.toRegist },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("login.here")) + " ")]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "welcome-title" }, [
      _c("div", { staticClass: "ft-40 fw-800 color2" }, [
        _vm._v("Launch Your"),
      ]),
      _c("div", { staticClass: "ft-40 fw-800 color1 mb-24" }, [
        _vm._v("7-Figure Dropshipping"),
      ]),
      _c("div", { staticClass: "ft-40 fw-800 color1 mb-24" }, [
        _vm._v("Journey Today!"),
      ]),
      _c("div", { staticClass: "ft-32 fw-700 color2 mb-48" }, [
        _vm._v("Master the essentials to build a profitable online store."),
      ]),
      _c("div", { staticClass: "btnContent" }, [
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Building Your Store"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Finding Winning Products"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Advertising Strategies"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("div", { staticClass: "content-icon" }, [
            _c("i", { staticClass: "el-icon-check" }),
          ]),
          _c("div", { staticClass: "content-words" }, [
            _vm._v("Leveraging USAdrop"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "ft-28 color2 mb-48 fw-600" }, [
        _vm._v(" Unlock everything you need to succeed with USAdrop. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }