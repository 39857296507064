<template>
  <div class="index-group-box">
    <div class="scrollX left-button" @click="prevSlide">
      <img
        src="@/assets/imgs/detail/ic_arr_left.png"
        alt="左箭头"
        style="width: 16px; height: 16px"
      />
    </div>
    <!-- 卡片 -->
    <div class="index-group-boxIn" ref="groupBoxRef">
      <img
        v-for="(item, index) in productFiles"
        :key="'thumb-' + index"
        :src="item"
        :alt="'Thumbnail ' + index"
        class="group-card-n"
        :class="{ 'group-card-s': currentIndex === index }"
        @click="handleThumbnailClick(index)"
      />
    </div>
    <div class="scrollX right-button" @click="nextSlide">
      <img
        src="@/assets/imgs/detail/ic_arr_right.png"
        alt="右箭头"
        style="width: 16px; height: 16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productFiles: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentSelectedIndex: this.currentIndex,
      groupInfo: [],
      direction: 'right',
      groupBoxRef: null
    }
  },
  methods: {
    updateScrollXPosition() {
      setTimeout(() => {
        let element = document.querySelector('.group-card-s')
        if (element) {
          element.scrollIntoView({ inline: 'center', block: 'nearest', behavior: 'smooth' })
        }
      }, 50)
      this.$emit('didThumbnailsIndexChange', Math.trunc(this.currentSelectedIndex))
    },
    prevSlide() {
      if (this.$refs.groupBoxRef && this.currentSelectedIndex > 0) {
        this.currentSelectedIndex--
      }
    },
    nextSlide() {
      const lastCardIndex = this.productFiles.length - 1
      if (this.currentSelectedIndex < lastCardIndex) {
        this.currentSelectedIndex++
      }
    },
    handleThumbnailClick(index) {
      this.currentSelectedIndex = index
    }
  },
  mounted() {
    this.updateScrollXPosition() // 确保首次渲染后立即调用
  },
  watch: {
    currentSelectedIndex: function (newValue) {
      this.updateScrollXPosition()
    }
  }
}
</script>

<style lang="scss" scoped>
.index-group-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 66px;
  -webkit-user-select: none;
  user-select: none;
}

.scrollX {
  width: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scrollX:hover {
  cursor: pointer;
  background-color: #65447d;
}

.index-group-boxIn {
  display: flex;

  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: auto;
  flex: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  margin-left: 32px;
  margin-right: 32px;
}

.index-group-boxIn::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.group-card-n {
  cursor: pointer;
  margin-right: 18px;
  width: 66px;
  min-width: 66px;
  height: 66px;
  border-radius: 4px;
  border: 1px solid #e5e5e5; /* 更简洁的方式合并 border-color 和 border-width */
  box-sizing: border-box; /* 保证边框宽度包含在元素总宽度内 */

  &.group-card-s {
    cursor: pointer;
    margin-right: 18px;
    width: 66px;
    min-width: 66px;
    height: 66px;
    border-radius: 4px;
    border: 2px solid #15104b; /* 更简洁的方式合并 border-color 和 border-width */
    box-sizing: border-box; /* 保证边框宽度包含在元素总宽度内 */
  }
}

.left-button {
  left: 0;
  // background-color: #eff1f3;
  width: 16px;
}

.right-button {
  right: 0;
  // background-color: #eff1f3;
  width: 16px;
}

.scrollX:hover {
  background-color: transparent;
}

.left-button img,
.right-button img {
  width: 16px;
  height: 100%;
}
</style>
