<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <meta name="referrer" content="never"></meta>
    <div class="activeName">
      <div class="Details-figure">
        <div class="focus">
          <div
            class="banner trans"
            @mouseenter="enter"
            @mouseleave="leave"
            v-if="productDetail.MainPic">
            <vue-photo-zoom-pro :high-url="currentUrl" :out-zoomer="true">
              <img :src="currentUrl" class="img" />
            </vue-photo-zoom-pro>
          </div>
          <div class="indicators">
            <img
              v-for="(item, index) in ThumbPhotos"
              :src="item.url"
              alt=""
              @click="select(index)"
              :class="{ con: index == imgindex, displayX: !item.isDisplay }"
              :key="index"
            /> <!-- Indicators 小图片 -->
          </div>
          <button @click="prev" class="prev">  <!-- Controls 左右箭头 -->
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576523_right-arrow.png" alt="" />
          </button>
          <button @click="next" class="next">
            <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576553_left-arrow.png" alt="" />
          </button>
        </div>
        <div class="synopsis">  <!-- 简介 -->
          <div class="Product_btn">
            <button class="btn-bg0" @click="OneClickImport">
              {{ $t("SourcingProductDetails.product") }}
            </button>
          </div>
          <div class="intro">{{ productDetail.ProductName }}</div>
          <div class="referenceWord">
            <p v-if="productDetail.Quoter == 'auto'">
              <span style="color: red; ont-size: 22px">*</span>
              {{ $t("SourcingProductDetails.UseAgent") }}
            </p>
            <p v-else>
              <span style="color: red; ont-size: 22px">*</span>
              {{ $t("SourcingProductDetails.Quotation") }}
            </p>
          </div>
          <template>
            <div v-if="productDetail.QuoteContent[0].NewQuotes != null">
              <div class="NewQuotesContent">
                <el-input type="textarea" autosize :disabled="true" v-model="ProductText">
                </el-input>
              </div>
            </div>
            <div v-else>
              <div class="price">
                <span class="labelname">{{ $t("SourcingProductDetails.price") }}</span>
                <span
                  style="
                    font-family: Regular;
                    font-size: 22px;
                    margin-left: 10px;
                    -webkit-font-smoothing: antialiased;"
                  >{{ $t("SourcingProductDetails.sum") }} {{ Price }}</span>
              </div>
              <template v-if="!HasDefault && productDetail && productDetail.Attributes">
                <div v-for="(item, index) in productDetail.Attributes" :key="index">
                  <div :class="item.IsImgAttr ? 'picture' : 'measure'">
                    <div>
                      <span class="labelname">{{ item.AttributesName }}</span>
                      <template v-if="item.IsImgAttr">
                        <span class="imgas">
                          <span>{{
                            item.AttrValues[item.CurrentIndex]
                          }}</span>
                        </span>
                      </template>
                    </div>
                    <template v-if="item.IsImgAttr">
                      <el-radio-group
                        v-model="item.CurrentIndex"
                        v-for="(pic, j) in item.PicList"
                        :key="j"
                        @change="radioChange()"
                      >
                        <el-radio-button plain :label="j" class="image">
                          <img :src="pic" alt="" />
                        </el-radio-button>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <el-radio-group
                        v-model="item.CurrentIndex"
                        v-for="(attr, i) in item.AttrValues"
                        :key="i"
                        @change="radioChange()"
                      >
                        <el-radio-button plain class="s" :label="i">{{ attr }}</el-radio-button>
                      </el-radio-group>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <div v-if="productDetail.QuoteStatus == 1" class="SourcingPriceWord">
            <span style="color: red">*</span>
            {{ $t('SourcingProductDetails.process') }}
            <p style="margin-left: 12px"></p>
          </div>
          <div class="address">
            <span class="labelname">{{ $t("SourcingProductDetails.country") }}</span>
            <el-radio-group
              v-model="radio3"
              v-for="(item, index) in ProductCountryCode"
              :key="index"
              @change="changeCountry(index)"
            >
              <el-radio-button plain :label="item" class="sellcountry"></el-radio-button>
            </el-radio-group>
          </div>
          <div> <!-- Sourcing客户报价满意度 -->
            <div v-if="productDetail.HasConfirmSatisfied == false">
              <div class="Satisfaction">
                <p>{{ $t("SourcingProductDetails.satisfied") }}</p>
                <p><el-button @click="QuoteYes(true)">{{ $t("SourcingProductDetails.Yes") }}</el-button></p>
                <p><el-button @click="QuoteYes(false)">{{ $t("SourcingProductDetails.No") }}</el-button></p>
              </div>
            </div>
            <div v-if="productDetail.HasConfirmSatisfied == true"></div>
          </div>
        </div>
        <div class="product-details" v-if="isImg">
          <img src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591749183_xinbai.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="activeContent">
      <h3
        style="
          position: absolute;
          margin: 0px 0px 2px 0rem;
          font-family: Regular;
          -webkit-font-smoothing: antialiased;
          line-height: 55px;">
        {{ $t("SourcingProductDetails.Description") }}
      </h3>
      <div
        id="storyline"
        class="storyline"
        :class="[hideWords ? 'deschide' : '']"
        v-html="productDetail.ProductDesc">
        {{ productDetail.ProductDesc }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "quotedInfo",
      banner: "",
      itemname: "",
      productId: "", //商品id
      productType: "", //商品类型
      productDetail: {}, //商品详情
      isImg: false, //是否包含图片
      hideWords: true, //是否隐藏文字
      radio1: "",
      radio2: "0",
      radio3: "",
      imgindex: "0", //控制变量，默认显示第一张
      timer: null, //定时器
      code: true, //判断类别，后面用详情字段替代
      CurrentProductSpec: [], // 产品尺码
      ProductCountryCode: [], // 产品的国家,
      ProductText: "",
      sizeIndex: 0,
      Price: "0",
      CountryIndex: 0,
      currentUrl: "",
      ThumbPhotos: [],
      HasDefault: false,
      textarea1: "",
    };
  },
  created() {
    this.dispatch = this.$store.dispatch;
    this.timer = setInterval(this.next.bind(this), 2000);
    if (!this.$route.query.id) { // 未找到商品id
      this.$router.push("Sourcing");
    }
    this.productType = this.$route.query.type; //1Quoted详情 2我的产品详情
    this.productId = this.$route.query.id;
    this.getDate();
    this.CalcPrice();
  },
  components: {
    vuePhotoZoomPro,
  },
  mounted() {
    this.banner = this.$t("productDetails.bannername");
    this.itemname = this.$t("productDetails.itemname");
  },
  computed: mapState(["userInfo", "theme"]),
  methods: {
    showSpec() {
      if (this.productDetail.Attributes) {
        var imgAttr = this.productDetail.Attributes.find((item) => item.IsImgAttr);
        if (imgAttr) {
          this.currentUrl = imgAttr.PicList[imgAttr.CurrentIndex];
        }
      }
    },
    changeCountry(index) {
      if (this.productDetail.QuoteContent[0].NewQuotes) {
        this.productDetail.QuoteContent.map((x) => {
          if (x.CountryCode == this.ProductCountryCode[index]) {
            this.ProductText = x.NewQuotes;
          }
        });
      } else {
        this.CurrentProductSpec = this.productDetail.QuoteContent[index].SkuQuotes;
        this.CalcPrice();
      }
    },
    radioChange() {
      this.CalcPrice();
      this.showSpec();
    },
    toggleWords() { // 展示所有描述
      this.hideWords = !this.hideWords;
    },
    //下一张
    next: function () {
      if (this.ThumbPhotos && this.imgindex == this.ThumbPhotos.length - 1) {
        this.imgindex = 0;
        this.ThumbPhotos.map((item, index) => {
          if (index <= 3) {
            item.isDisplay = true;
          } else {
            item.isDisplay = false;
          }
        });
      } else {
        this.imgindex++;
        if (this.ThumbPhotos[this.imgindex]) {
          this.ThumbPhotos[this.imgindex].isDisplay = true;
        }
        if (this.ThumbPhotos[this.imgindex - 4]) {
          this.ThumbPhotos[this.imgindex - 4].isDisplay = false;
        }
      }
      this.currentUrl = this.productDetail.PhotoList[this.imgindex].Imageurl;
    },
    //上一张
    prev: function () {
      if (this.imgindex <= 0) {
        this.imgindex = this.ThumbPhotos - 1;
        this.ThumbPhotos.map((item, index) => {
          if (index <= this.imgindex - 4) {
            item.isDisplay = false;
          } else {
            item.isDisplay = true;
          }
        });
      } else {
        this.imgindex--;
        if (this.ThumbPhotos[this.imgindex]) {
          this.ThumbPhotos[this.imgindex].isDisplay = true;
        }
        if (this.ThumbPhotos[this.imgindex + 4]) {
          this.ThumbPhotos[this.imgindex + 4].isDisplay = false;
        }
      }
      this.currentUrl = this.productDetail.PhotoList[this.imgindex].Imageurl;
    },
    select(index) {
      this.imgindex = index;
      if (this.productDetail.PhotoList) {
        this.currentUrl = this.productDetail.PhotoList[this.imageindex].Imageurl;
      }
    },
    enter: function () { // 鼠标移入view
      clearInterval(this.timer);
    },
    leave: function () {
      this.timer = setInterval(this.next.bind(this), 2000);
    },
    getDate() { // 获取我的产品页面的详情
      this
        .dispatch("quotedInfo/GetDetailQi", {
          id: Number(this.productId),
        })
        .then((res) => {
          let productDetail = res.Result;
          if (productDetail.MainPic) {
            productDetail.MainPic = productDetail.MainPic.split(",");
          }
          productDetail.QuoteContent = JSON.parse(productDetail.QuoteContent);
          console.log(productDetail.QuoteContent);
          productDetail.QuoteContent.map((item, index) => {
            this.ProductCountryCode.push(item.CountryCode);
            this.radio3 = this.ProductCountryCode[0];
            if (index == 0 && item.NewQuotes) {
              this.ProductText = item.NewQuotes;
            }
          });
          this.CurrentProductSpec = productDetail.QuoteContent[0].SkuQuotes;
          productDetail.Attributes.map((item) => {
            if (item.AttributesName == "Default") {
              this.HasDefault = true;
              item.AttrValues = [];
              item.AttrValues.push("Default");
              item.CurrentIndex = 0;
            } else {
              item.CurrentIndex = 0;
              if (item.AttributesValue && item.AttributesValue.length > 0) {
                item.AttrValues = item.AttributesValue.split(",");
              }
            }
          });
          this.productDetail = productDetail;
          this.CalcPrice();
          if (this.productDetail.PhotoList) {
            this.productDetail.PhotoList.map((item, index) => {
              this.ThumbPhotos.push({
                url: item.Imageurl,
                isDisplay: index <= 3 ? true : false,
              });
            });
            this.currentUrl = this.productDetail.PhotoList[this.imageindex].Imageurl;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CalcPrice() {
      var selectedSpecValue = "";
      var selectedSpecValueOld = "";
      this.productDetail.Attributes.map((item) => {
        if (selectedSpecValue.length > 0) {
          selectedSpecValue = selectedSpecValue + ", " + item.AttrValues[item.CurrentIndex];
          selectedSpecValueOld = selectedSpecValueOld + " " + item.AttrValues[item.CurrentIndex];
        } else {
          selectedSpecValue = item.AttrValues[item.CurrentIndex];
          selectedSpecValueOld = item.AttrValues[item.CurrentIndex];
        }
      });
      console.log(selectedSpecValue);
      this.CurrentProductSpec.map((item) => {
        if (
          item.SourcingProductSpecVaule.trim() === selectedSpecValue.trim() ||
          item.SourcingProductSpecVaule.trim() === selectedSpecValueOld.trim()
        ) {
          this.Price = item.Price;
        }
      });
    },
    AreSatisfied() {  /* 客户报价满意度 */
      this.dispatch("souring/ConfirmQuoteSr", {
        Satisfied: this.WQuoted,
        SourcingQuoteId: this.productDetail.Id,
      })
        .then((res) => {
          if (res.Success == true) {
          } else {
            this.$message.error(res.ErrMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    QuoteYes(flag) {
      this.productDetail.HasConfirmSatisfied = true;
      this.WQuoted = flag;
      this.AreSatisfied();
    },
    /* 一键导入产品 */
    OneClickImport() {
      this.dispatch("winner/CrawlProductPostWi", {
        url: this.productDetail.ProductUrl,
        type: 1,
      })
        .then((res) => {
          if (res.Success) {
            this.productDetail.ProductUrl = "";
            this.$router.push("ImportList");
            this.$message.success(this.$t("SourcingProductDetails.successfully"));
          } else {
            this.$message.error(res.ErrMsg);
          }
        })
        .catch((err) => {
          this.$message.error(this.$t("SourcingProductDetails.failed"));
        });
    },
  },
};
</script>

<style scoped lang="scss">
.referenceWord {
  margin-top: 15px;
  color: #32cd32;
  font-size: 18px;
  word-break: normal !important;
}
.SourcingPriceWord {
  margin-top: 20px;
  color: #ff8100;
  font-size: 18px;
  word-break: normal;
}
.NewQuotesContent {
  margin-top: 18px;
  ::v-deep .NewQuotesContent .el-textarea.is-disabled .el-textarea__inner {
    background: #fff;
    color: red;
    font-size: 20px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    border-radius: 50px;
  }
}
.Product_btn {
  width: 250px;
  height: 50px;
  margin-top: 5px;
  button {
    display: inline-block;
    width: 200px;
    height: 40px;
    border-radius: 45px;
    border: 0;
    font-size: 16px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #ffffff;
    &:hover {
      background: #67689a;
      color: #fff;
    }
  }
}
.indicators .displayX {
  display: none;
}
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  min-width: 1320px;
  padding-bottom: 32px;
  background: #eff1f3;
}
.activeName {
  width: 1320px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 30px;
}
/* 详情图 */
.Details-figure {
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
  overflow: hidden;
}
.focus {
  padding-top: 50px; /* <!-- Wrapper for slides 轮播图片 --> */
  width: 624px;
  float: left;
}
.banner .img {
  width: 560px;
  height: 560px;
  position: relative;
}
.img {
  float: left;
}
.banner {
  width: 560px;
  height: 560px;
  margin-left: 64px;
  position: relative;
}
::v-deep .el-carousel__container {
  height: 560px;
}
.trans {
  transition: all 0.5s;
}
.indicators {
  margin-left: 60px;
  margin-top: 22px;
  height: 100px;
  img {
    width: 100px;
    height: 100px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    margin-left: 35px;
    margin-top: 10px;
  }
  .con {
    width: 90px;
    height: 90px;
    margin-left: 11px;
  }
}
.prev {
  position: absolute;
  top: 680px;
  left: 30px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}
.next {
  position: absolute;
  top: 680px;
  left: 630px;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}
.synopsis { /* <!-- 简介 --> */
  font-size: 20px;
  width: 560px;
  float: right;
  margin-right: 65px;
  padding-top: 50px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.intro {
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #191919;
}
.price {
  margin-top: 36px;
  color: #93df89;
  .price span:nth-child(1) {
    font-size: 18px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #93df89;
    line-height: 25px;
    width: 155px;
  }
  .price span:nth-child(2) {
    font-size: 30px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    line-height: 25px;
  }
  .price span:nth-child(3) {
    font-size: 30px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    line-height: 25px;
  }
  .price span:nth-child(4) {
    font-size: 30px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    line-height: 25px;
  }
}
.measure {
  margin-top: 36px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  .measure span {
    font-size: 18px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #555656;
  }
}
.labelname {
  width: 100px;
  height: 35px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .measure .el-radio-button__inner {
  display: inline-block;
  height: 30px;
  line-height: 28px !important;
  border-radius: 50px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border: 1px solid #dcdfe6;
  margin-right: 24px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-radio-button__inner {
  transition: all 0s linear;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 8px;
}
.picture {
  margin-top: 21px;
  .picture span {
    font-size: 18px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #555656;
    display: inline-block;
    margin-bottom: 10px;
  }
}
::v-deep .picture .el-radio-button__inner {
  width: 81px;
  height: 81px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-top-width: 0px;
  padding-left: 0px;
  border: 2px solid #dcdfe6;
  margin-right: 24px;
  margin-bottom: 10px;
}
.picture .el-radio-button__inner img {
  width: 77px;
  height: 77px;
}
::v-deep .picture .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .picture .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .picture .el-radio-button__inner {
  transition: all 0s linear;
}
::v-deep .picture .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.address {
  margin-top: 21px;
  .address span {
    font-size: 18px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #555656;
  }
}
::v-deep .address .el-radio-button__inner {
  display: inline-block;
  width: 75px;
  height: 30px;
  line-height: 28px !important;
  border-radius: 50px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border: 1px solid #dcdfe6;
}
.address .sellcountry {
  margin-right: 14px;
}
.activeContent {
  width: 1320px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 0px;
}
.activeContent h3 {
  border-bottom: 2px solid #ccc;
  width: 100%;
  padding-left: 45px;
  font-size: 22px;
}
.storyline {
  font-size: 14px;  /* storyline */
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #000000;
  margin-top: 20px;
  line-height: 30px;
  padding-top: 80px;
  padding-left: 30px;
  padding: 0 1rem;
  padding-top: 80px;
}
::v-deep .storyline div {
  text-align: left !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .storyline img {
  max-width: 550px;
  height: auto;
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
::v-deep .storyline p {
  width: 100% !important;
  text-align: left;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .storyline .detailmodule_html {
  width: 100% !important;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.product-details {
  width: 1320px;
  border-radius: 10px;
  margin: auto;
  margin-top: 32px;
  position: relative;
  background: #f9fafb;
  .product-details img {
    width: 1184px;
    height: 2089px;
    margin: 68px;
  }
}
.el-tabs__active-bar {
  top: 30px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 20px !important;
}
.Satisfaction { /*客户报价满意度*/
  width: 100%;
  height: 40px;
  display: flex;
  margin-top: 35px;
  .Satisfaction p:nth-child(1) {
    font-size: 16px;
    margin-top: 8px;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
  }
  .Satisfaction p:nth-child(2) {
    font-size: 16px;
    margin-left: 20px;
    line-height: 0px;
    border-radius: 10px;
    color: red;
    border: 2px solid #6788ff;
  }
}
::v-deep .Satisfaction .el-button {
  border: none;
  background: none;
  color: #6788ff;
  text-align: center;
}
.Satisfaction p:nth-child(3) {
  font-size: 16px;
  margin-left: 20px;
  line-height: 40px;
  border-radius: 10px;
  color: red;
  border: 2px solid #6788ff;
  text-align: center;
}
</style>
