import {
  TotalCount,
  BuryingLogAdd
} from '@/api/takeNotes'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    TotalCountTn({state, commit}, params) {return TotalCount(params).then(res => res)},
    BuryingLogAddTn({state, commit}, params) {return BuryingLogAdd(params).then(res => res)}
  }

}