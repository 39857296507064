<script>
export default {
  name: 'FeedMissingItem',
  props: {
    orderDetails: Array,
    readonly: Boolean,
    missingMap: Array
  },
  data() {
    return {
      productSelectOptions: [],
      formModel: {
        missItems: [
          {
            sku: null,
            part: null,
            num: 0
          }
        ]
      }
    }
  },
  watch: {
    orderDetails: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.productSelectOptions = newVal.map((item) => {
            return {
              value: item.ProductRealSku,
              label: item.ProductRealSku
            }
          })
          if (this.readonly) return
          this.formModel.missItems = [
            {
              sku: this.productSelectOptions[0].value,
              part: null,
              num: 0
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    missingMap: {
      handler: function (newVal) {
        if (newVal) {
          this.formModel.missItems = newVal
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onAddMissItem() {
      this.formModel.missItems.push({
        sku: this.productSelectOptions[0].value,
        part: null,
        num: 0
      })
    },
    onRemoveMissItem(index) {
      this.formModel.missItems.splice(index, 1)
    },
    getMissProducts(formName) {
      return new Promise((resolve, reject) => {
        this.$refs.validateFormRef.validate((valid) => {
          if (valid) {
            resolve({
              MissingMap: this.formModel.missItems
            })
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    },
    handleInput(value, item) {
      item.num = value.replace(/[^\d]/g, '')
    }
  }
}
</script>

<template>
  <div class="feed-missing-item">
    <div class="header">
      <div class="title-container">
        <div>{{ $t('SupportTicket.MissingItem') }}</div>
        <div class="tips">{{ $t('SupportTicket.TipsIf') }}</div>
      </div>
      <el-button
        size="small"
        round
        type="primary"
        style="background: #3f6aff; border: none"
        @click="onAddMissItem"
      >
        {{ $t('SupportTicket.AddMissingltem') }}
      </el-button>
    </div>
    <el-form ref="validateFormRef" :inline="true" :model="formModel" :disabled="readonly">
      <div v-for="(item, index) in formModel.missItems" :key="index">
        <el-form-item
          :label="$t('SupportTicket.MissingItem')"
          :prop="'missItems.' + index + '.sku'"
          :rules="{
            required: true,
            message: 'Please select Missing Item',
            trigger: 'blur'
          }"
        >
          <el-select v-model="item.sku">
            <el-option
              v-for="item in productSelectOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('SupportTicket.MissingPart')">
          <el-input v-model="item.part" />
        </el-form-item>
        <el-form-item
          :label="$t('SupportTicket.MissingQuantity')"
          :prop="'missItems.' + index + '.num'"
          :rules="{
            required: true,
            message: 'Please Input Missing Quantity',
            trigger: 'blur'
          }"
        >
          <el-input v-model="item.num" type="number" @input="handleInput($event, item)" />
        </el-form-item>
        <el-button
          v-if="formModel.missItems.length > 1"
          size="small"
          type="text"
          style="color: red"
          :disabled="readonly"
          @click="onRemoveMissItem(index)"
        >
          {{ $t('SupportTicket.Remove') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.feed-missing-item {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .tips {
        color: #999999;
        line-height: 14px;
      }
    }
  }
}

:deep(.el-form-item__label) {
  padding-right: 10px !important;
}
</style>
