<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <!-- 详情页面 -->
    <!-- 内容 -->
    <div class="main" v-loading="loading">
      <!-- 左侧 -->
      <div class="left">
        <div class="title">Your Store's Product</div>
        <div class="search">
          <div class="s-wrap">
            <el-input
              class="s-item search-input"
              placeholder="Enter Product Name"
              v-model="searchData.name"
              size="small"
            ></el-input>
            <el-select class="s-item search-input" v-model="searchData.cateValue" size="small">
              <el-option
                v-for="item in shopList"
                :key="item.UserId"
                :label="item.StoreName"
                :value="item.UserId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s-btns">
            <el-button
              class="s-item btns-item"
              size="small"
              @click="searchStoreData"
              :disabled="!searchData.cateValue"
              >Search</el-button
            >
            <el-button
              class="s-item btns-item"
              size="small"
              @click="openSync"
              :disabled="!searchData.cateValue"
              >Sync</el-button
            >
          </div>
        </div>
        <div class="box lt-box">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="Waiting Connect" name="first" class="tab-wrap">
              <div
                v-for="(item, index) in ltData"
                :key="index"
                class="card pointer"
                :class="currentLt.ShopProductId === item.ShopProductId ? 'is-active' : ''"
                @click="handleLtClick(item)"
              >
                <div class="card-img">
                  <el-popover
                    placement="right"
                    :open-delay="hoverDelayTime"
                    :width="hoverImgWidth"
                    trigger="hover"
                  >
                    <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                    <template slot="reference">
                      <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                    </template>
                  </el-popover>
                </div>
                <div class="card-detail">
                  <el-tooltip
                    effect="dark"
                    :open-delay="hoverDelayTime"
                    :content="item.ShopProductName"
                    placement="top-start"
                  >
                    <span class="detail-text">{{ item.ShopProductName }} </span>
                  </el-tooltip>
                  <div class="detail-list">
                    <span>Store Name: {{ item.ShopName }}</span>
                    <span v-show="item.Price">${{ item.Price }}</span>
                  </div>
                </div>
                <div class="card-btns">
                  <!-- <el-button class="card-btnItem" size="small">Choose</el-button> -->
                  <el-button class="card-btnItem" size="small" @click="searchImg(item)"
                    >Search IMG</el-button
                  >
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Connected" name="second" class="tab-wrap">
              <div
                v-for="(item, index) in ltData"
                :key="index"
                class="card pointer"
                :class="currentLt.ShopProductId === item.ShopProductId ? 'is-active' : ''"
                @click="handleLtClick(item)"
              >
                <div class="card-img">
                  <el-popover
                    placement="right"
                    :open-delay="hoverDelayTime"
                    :width="hoverImgWidth"
                    trigger="hover"
                  >
                    <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                    <template slot="reference">
                      <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                    </template>
                  </el-popover>
                </div>
                <div class="card-detail">
                  <el-tooltip
                    effect="dark"
                    :open-delay="hoverDelayTime"
                    :content="item.ShopProductName"
                    placement="top-start"
                  >
                    <span class="detail-text">{{ item.ShopProductName }}</span>
                  </el-tooltip>
                  <div class="detail-list">
                    <span>Store Name: {{ item.ShopName }}</span>
                    <span v-show="item.Price">${{ item.Price }}</span>
                  </div>
                </div>
                <div class="card-btns">
                  <el-button class="card-btnItem view-btn" size="small" @click="viewDetail(item)"
                    >Detail</el-button
                  >
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="pagination" v-show="ltData?.length !== 0">
          <el-pagination
            layout="prev, pager, next"
            :currentPage="ltPageData.currentPage"
            :pageSize="ltPageData.pageSize"
            :total="ltPageData.total"
            @size-change="handleLtSizeChange"
            @current-change="handleLtCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="right">
        <div class="title">USAdrop Products</div>
        <div class="search">
          <div class="s-wrap">
            <el-input
              class="s-item search-input2"
              placeholder="Enter Product Name"
              v-model="keyword"
              size="small"
            >
              <template slot="prefix">
                <el-upload
                  ref="uploadImageRef"
                  :action="actionss"
                  accept="image/*"
                  :show-file-list="false"
                  :http-request="uploadImg">
                  <el-button
                    circle
                    size="small"
                    style="background: transparent; border: none"
                    :loading="imgLoading"
                    v-if="!searchImage" >
                    <img
                      v-if="!imgLoading"
                      class="search-img"
                      src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924727110_icon_upload.svg"
                      alt=""
                    />
                  </el-button>
                  <el-image class="search-img" v-if="searchImage" :src="searchImage" />
                </el-upload>
              </template>
            </el-input>
          </div>
          <div class="s-btns">
            <el-button class="s-item btns-item" size="small" @click="rtSearch">Search</el-button>
          </div>
        </div>
        <template v-if="rtData?.length !== 0">
          <div class="box rt-box">
            <div v-for="(item, index) in rtData" :key="index" class="card">
              <div class="card-img">
                <el-popover
                  placement="right"
                  :open-delay="hoverDelayTime"
                  :width="hoverImgWidth"
                  trigger="hover"
                >
                  <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                  <template slot="reference">
                    <img :src="item.ShopProductMainPic" style="width: 100%; height: 100%" />
                  </template>
                </el-popover>
              </div>
              <div class="card-detail">
                <el-tooltip
                  effect="dark"
                  :open-delay="hoverDelayTime"
                  :content="item.ShopProductName"
                  placement="top-start"
                >
                  <span class="detail-text">{{ item.ShopProductName }}</span>
                </el-tooltip>
                <div class="detail-list">
                  <span style="color: #3f6aff">${{ item.Price }}</span>
                </div>
                <div class="detail-list">
                  <span>Cost Price</span>
                </div>
              </div>
              <div class="card-btns">
                <el-button class="card-btnItem" size="small" @click="openConnect(item)"
                  >Connect</el-button
                >
                <el-button class="card-btnItem view-btn" size="small" @click="jumpView(item)"
                  >View</el-button
                >
              </div>
            </div>
          </div>
          <div class="pagination" v-show="rtData?.length !== 0">
            <el-pagination
              layout="prev, pager, next"
              :currentPage="rtPageData.currentPage"
              :pageSize="rtPageData.pageSize"
              :total="rtPageData.total"
              @size-change="handleRtSizeChange"
              @current-change="handleRtCurrentChange"
            >
            </el-pagination>
          </div>
        </template>
        <template v-else>
          <div class="rt-box">
            <div class="content">
              <div class="title">
                To get the quotation immediately, match your store products with USAdrop's.
              </div>
              <div v-for="(item, index) in sectionData" :key="index" class="section">
                {{ `${index + 1}.${item.content}` }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- connect 弹窗 -->
    <connect-dialog
      ref="connectRef"
      :type="connectType"
      :visible.sync="connectVisible"
      :hoverDelayTime="hoverDelayTime"
      :hoverImgWidth="hoverImgWidth"
      @confirm="connectConfirm"
    />
    <!-- sync 弹窗 -->
    <sync-dialog :visible.sync="syncShow" @confirm="syncConfirm" ref="syncRef" />
  </div>
</template>
<script>
import connectDialog from './connectDialog.vue'
import syncDialog from './syncDialog.vue'
import { mapState } from 'vuex'
import { transProductName, transSpecData, useSearch } from '@/utils/tools'

import {
  batchSyncProduct,
  getConnectedDetailData,
  getHasConnectData,
  getNoConnectData,
  getProductChildData,
  getShopListData,
  getUDProductData,
  imageSearch,
  submitMapping
} from '@/api/proConnect'
import { OSSUploadType, useJSCore } from 'sm-js-core'

export default {
  name: 'ProConnect',
  data: () => {
    return {
      hoverImgWidth: '300',
      hoverDelayTime: 300,
      activeName: 'first',
      searchData: {
        name: '',
        cateValue: ''
      },
      shopList: [],
      keyword: '',
      currentLt: {},
      currentRt: {},
      ltData: [],
      rtData: [],
      loading: false,
      goodsCode: '',
      sectionData: [
        {
          content: "Click 'Search IMG' to match USAdrop products"
        },
        {
          content: 'Typing the product name to search'
        },
        {
          content: "Click 'ask for a quote, await USAdrop to quote (Quoting)"
        }
      ],
      connectVisible: false,
      connectType: 'common',
      // 分页
      // ltTableData: [],
      ltPageData: {
        pageIndex: 1,
        pageSize: 20,
        total: 0
      },
      rtPageData: {
        pageIndex: 1,
        pageSize: 20,
        total: 0
      },
      /* 同步 */
      syncShow: false,
      action: '/goodsapi/oss/uploadFile',
      actionss: '/goodsapi/oss/sign',
      imgLoading: false,
      searchImage: ''
    }
  },
  components: {
    connectDialog,
    syncDialog
  },
  created() {},
  async mounted() {
    await this.getShopList()
    // console.clear()
    if (useSearch('goodsCode')) {
      this.goodsCode = useSearch('goodsCode')
      this.rtSearch()
    }
    this.searchStoreData()
  },
  computed: mapState(['userInfo', 'theme']),
  methods: {
    // 查询列表
    searchStoreData() {
      if (!this.searchData.cateValue) return
      this.ltData = []
      this.loading = true
      if (this.activeName === 'first') {
        // 未关联
        this.searchNoConnect()
      } else {
        // 已关联
        this.searchHasConnect()
      }
    },
    openSync() {
      this.syncShow = true
    },
    // 同步数据
    syncConfirm(formData) {
      let postData = {
        BeginTime: formData.rangeTime[0],
        EndTime: formData.rangeTime[1],
        Title: formData.title,
        UserId: this.searchData.cateValue
        // ProductIds: 'string'
      }
      batchSyncProduct(postData)
        .then((res) => {
          if (res.Success) {
            this.$refs.syncRef.loading = false
            this.syncShow = false
            this.$message.success('Sync Date Success!')
            this.searchStoreData()
          }
        })
        .catch(() => {
          this.$refs.syncRef.loading = false
        })
    },

    searchNoConnect() {
      let postData = {
        UserId: this.searchData.cateValue,
        Keyword: this.searchData.name,
        CurrentPageIndex: this.ltPageData.pageIndex,
        PageSize: this.ltPageData.pageSize
        // RecordCount: 0
      }
      getNoConnectData(postData)
        .then((res) => {
          this.loading = false
          if (res.Success) {
            // this.ltTableData = res?.data
            this.ltData = res?.Items
            this.ltPageData.total = res?.data?.length ?? 0
            // this.ltData = res.data.slice(
            //   (this.ltPageData.pageIndex - 1) * this.ltPageData.pageSize,
            //   this.ltPageData.pageSize * this.ltPageData.pageIndex
            // )
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    searchHasConnect() {
      let postData = {
        UserId: this.searchData.cateValue,
        Keyword: this.searchData.name,
        CurrentPageIndex: this.ltPageData.pageIndex,
        PageSize: this.ltPageData.pageSize
        // RecordCount: 0
      }
      getHasConnectData(postData)
        .then((res) => {
          this.loading = false
          if (res.Success) {
            this.ltData = res.Items
            this.ltPageData.total = res?.Items?.length ?? 0
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleTabClick() {
      this.currentLt = {}
      this.ltData = []
      this.ltPageData.pageIndex = 1
      this.ltPageData.pageSize = 20
      this.ltPageData.total = 0
      this.searchStoreData()
    },
    handleLtClick(itemData) {
      if (this.currentLt.ShopProductId === itemData.ShopProductId) {
        this.currentLt = {}
      } else {
        this.currentLt = itemData
      }
    },
    searchImg(itemData) {
      const postData = {
        pageNum: this.ltPageData.pageIndex,
        size: this.ltPageData.pageSize,
        imageUrl: itemData.ShopProductMainPic
      }
      this.loading = true
      imageSearch(postData)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.rtPageData.total = res.data.total
            this.rtPageData.pageIndex = res.data.current
            this.rtPageData.pageSize = res.data.size
            this.rtData =
              res.data.records?.map((item) => ({
                ShopProductId: item.goodsCode,
                ShopProductMainPic: item.mainImage,
                ShopProductName: transProductName(item),
                ShopName: '',
                Price: item.minPrice,
                goodsSkuList: item.goodsSkuList,
                IsConnected: false
              })) || []
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    rtSearch() {
      this.loading = true
      this.searchImage = ''
      let postData = {
        pageNum: this.rtPageData.pageIndex,
        size: this.rtPageData.pageSize,
        keyword: this.keyword,
        goodsCode: this.goodsCode
      }
      getUDProductData(postData)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.rtPageData.total = res.data.total
            this.rtPageData.pageIndex = res.data.current
            this.rtPageData.pageSize = res.data.size
            this.rtData = res.data.records.map((item) => ({
              ShopProductId: item.goodsCode,
              ShopProductMainPic: item.mainImage,
              ShopProductName: transProductName(item),
              ShopName: '',
              Price: item.minPrice,
              goodsSkuList: item.goodsSkuList,
              IsConnected: false
            }))
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    async getShopList() {
      await getShopListData().then((res) => {
        this.shopList = res.data
        if (this?.shopList?.length !== 0) {
          this.searchData.cateValue = this.shopList[0].UserId
        }
      })
    },

    /* 分页 */
    handleLtSizeChange(value) {
      this.ltPageData.pageSize = value
      // this.ltData = this.ltTableData.slice(
      //   (this.ltPageData.pageIndex - 1) * this.ltPageData.pageSize,
      //   this.ltPageData.pageSize * this.ltPageData.pageIndex
      // )
      this.searchStoreData()
    },
    handleLtCurrentChange(value) {
      this.ltPageData.pageIndex = value
      // this.ltData = this.ltTableData.slice(
      //   (this.ltPageData.pageIndex - 1) * this.ltPageData.pageSize,
      //   this.ltPageData.pageSize * this.ltPageData.pageIndex
      // )
      this.searchStoreData()
    },
    handleRtSizeChange(value) {
      this.rtPageData.pageSize = value
      this.rtSearch()
    },
    handleRtCurrentChange(value) {
      this.rtPageData.pageIndex = value
      this.rtSearch()
    },
    /* connect 弹窗 */
    openConnect(itemData) {
      if (JSON.stringify(this.currentLt) === '{}') {
        this.$message.warning('Please select a store product first')
        return
      }
      this.loading = true
      this.connectType = 'common'
      this.currentRt = itemData
      let postData = {
        UserId: this.searchData.cateValue,
        ShopProductId: this.currentLt.ShopProductId,
        ProductName: this.currentLt.ShopProductName
      }
      getProductChildData(postData)
        .then((res) => {
          this.loading = false
          if (res.success) {
            // 右侧 ud 数据
            this.$refs.connectRef.conRtData = itemData.goodsSkuList.map((item) => ({
              ShopProductId: item.goodsCode,
              MallVariantId: item.goodsSkuCode,
              MallVariantPic: item.image ? item.image : itemData.ShopProductMainPic,
              MallVariantName: '',
              MallVariantWeight: JSON.parse(item.shippingInfo).weight,
              Sepecs: transSpecData(item.specValueInfoList),
              Price: item.channelPrice
            }))
            // 左侧 商品数据
            // 1.IsSumaiProcessed true  关联 ud
            // 2.ConnectedMallProduct null wait connecte
            // 3. ConnectedMallProduct {...data} connected
            const data = []
            res.data.forEach((item) => {
              if (item.IsSumaiProcessed) {
                data.push({
                  ShopProductId: item.ShopProductId,
                  ShopVariantId: item.ShopVariantId,
                  ShopVariantPic: item.MainPic,
                  ShopVariantName: item.ProductName,
                  Sepecs: [item.SKU, item.VariantName],
                  Price: item.Price,
                  IsSumaiProcessed: item.IsSumaiProcessed,
                  ConnectedMallProduct: item.ConnectedMallProduct
                })
              } else {
                data.unshift({
                  ShopProductId: item.ShopProductId,
                  ShopVariantId: item.ShopVariantId,
                  ShopVariantPic: item.MainPic,
                  ShopVariantName: item.ProductName,
                  Sepecs: [item.SKU, item.VariantName],
                  Price: item.Price,
                  IsSumaiProcessed: item.IsSumaiProcessed,
                  ConnectedMallProduct: item.ConnectedMallProduct
                })
              }
            })
            this.$refs.connectRef.conLtData = data
            this.connectVisible = true
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    jumpView(itemData) {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.open(
        `${process.env.VUE_APP_UD_SHOP_HOST}/productDetail/v2/${itemData.ShopProductId}?tk=${tk}`
      )
    },
    connectConfirm(data) {
      if (data.length === 0) {
        this.$message.warning('please choose connect data!')
        return
      }
      let postData = {
        UserId: this.searchData.cateValue,
        ShopProductId: this.currentLt.ShopProductId,
        ShopProductName: this.currentLt.ShopProductName,
        ShopProductMainPic: this.currentLt.ShopProductMainPic,
        MallProductId: this.currentRt.ShopProductId,
        MallProductName: this.currentRt.ShopProductName,
        MallProductMainPic: this.currentRt.ShopProductMainPic,
        RelationList: []
      }
      data.forEach((item) => {
        let list = {
          ShopVariantId: item.leftData.ShopVariantId,
          ShopVariantName: item.leftData.Sepecs.join(','),
          ShopVariantPic: item.leftData.ShopVariantPic,
          MallVariantId: item.rightData.MallVariantId,
          MallVariantName: item.rightData.Sepecs.join(','),
          MallVariantPic: item.rightData.MallVariantPic,
          QuotedPrice: 0
        }
        postData.RelationList.push(list)
      })
      submitMapping(postData).then((res) => {
        if (res.Success) {
          this.syncShow = false
          this.keyword = ''
          this.rtData = []
          this.connectVisible = false
          this.$refs.connectRef.resetData()
          this.searchStoreData()
          this.$message.success('Connect Successfully!')
        } else {
          this.$message.error(res.ErrMsg)
        }
      })
    },
    handelSpecs(sku, color) {
      const list = []
      if (sku) {
        list.push(sku)
      }
      if (color) {
        list.push(color)
      }
      return list
    },
    /* 详情 */
    viewDetail(itemData) {
      this.connectType = 'view'
      let postData = {
        UserId: this.searchData.cateValue,
        ShopProductId: itemData.ShopProductId,
        ProductName: itemData.ShopProductName
      }
      const connectData = []
      getConnectedDetailData(postData).then((res) => {
        res.data.forEach((item) => {
          connectData.push({
            leftData: {
              ConnectedMallProduct: item.ConnectedMallProduct,
              ShopProductId: item.ShopProductId,
              ShopVariantId: item.ShopVariantId,
              ShopVariantPic: item.MainPic,
              ShopVariantName: item.ProductName,
              Sepecs: this.handelSpecs(item.SKU, item.VariantName),
              Price: item.Price
            },

            rightData: {
              ShopProductId: item.ConnectedMallProduct.MallProductId,
              MallVariantId: item.ConnectedMallProduct.MallVariantId,
              MallVariantPic: item.ConnectedMallProduct.MallVariantPic,
              MallVariantName: item.ConnectedMallProduct.MallProductName,
              MallVariantWeight: 0,
              Sepecs: [
                item.ConnectedMallProduct.MallVariantName.split(',')[0],
                item.ConnectedMallProduct.MallVariantName.split(',')[1]
              ],
              Price: 0
            }
          })
        })
        this.$refs.connectRef.hasConnect = connectData
        this.connectVisible = true
      })
    },
    async uploadImg(options) {
      this.imgLoading = true
      try {
        const { url } = await useJSCore().oss.upload(options.file, {
          uploadType: OSSUploadType.SEARCH,
          datePath: false
        })

        this.imgLoading = false
        this.searchImg({
          ShopProductMainPic: url
        })
      } catch (error) {
        console.error(error)
        this.$message.error(this.$t('searchdetail.uploadFail'))
        this.imgLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.search-input) {
  width: 100%;
  .el-input__inner {
    border-radius: 32px;
    background-color: #eff1f3 !important;
    border-color: #eff1f3;
  }
}

:deep(.search-input2) {
  width: 100%;
  .el-input__inner {
    border-radius: 32px;
    background-color: #eff1f3 !important;
    border-color: #eff1f3;
    padding-left: 44px !important;
  }

  .search-img {
    width: 24px;
    height: 24px;
  }
}

:deep(.el-input__prefix) {
  display: flex;
  align-items: center;
}
.particulars {
  // width: 100%;
  width: 1320px;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  padding-bottom: 32px;
  box-sizing: border-box;
  font-family: 'Regular';
  .main {
    width: 100%;
    height: 100%;
    padding-right: 30px;
    display: flex;
  }
}

.search {
  display: flex;
  .s-wrap {
    flex: 1;
  }
  .s-btns {
    width: 140px;
    padding-left: 30px;
    box-sizing: border-box;
  }
  .s-item + .s-item {
    margin-top: 5px;
  }
  .btns-item {
    width: 100px;
    border-radius: 32px;
    font-size: 16px;
    font-weight: bold;
    color: #15104b;
    background-color: #eff1f3;
    // border-color: #eff1f3;
    box-sizing: border-box;
    border: none;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    + .btns-item {
      margin-left: 0;
    }
  }
}

.left,
.right {
  flex: 1;
  background-color: #fff;
  padding: 25px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  .title {
    font-size: 21px;
  }
  > div + div {
    margin-top: 5px;
  }
}

.left {
  margin-right: 30px;
}

.lt-box {
  .tab-wrap {
    max-height: 620px;
    overflow: auto;
  }
}

.rt-box {
  padding-top: 20px;
  box-sizing: border-box;
  max-height: calc(100% - 120px);
  overflow: auto;
  .content {
    padding-top: 30px;
    font-size: 14px;
    .title {
      color: #666666;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .section {
      color: #999999;
      line-height: 22px;
    }
  }
}

.box {
  .card {
    width: 100%;
    height: 106px;
    padding: 8px;
    display: flex;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-bottom: 1px solid #eff1f3;

    &:hover {
      border: 1px solid #3f6aff;
    }
    & + .card {
      margin-top: 8px;
    }
    .card-img {
      width: 90px;
      height: 90px;
    }
    .card-detail {
      flex: 1;
      box-sizing: border-box;
      padding: 0 16px;
      .detail-text {
        font-size: 14px;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-line-clamp: 1;
      }
      .detail-list {
        margin-top: 8px;
        color: #999999;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
      }
    }
    .card-btns {
      width: 100px;
      .card-btnItem {
        width: 100%;
        border-radius: 32px;
        background-color: #e0e7ff;
        color: #15104b;
        font-size: 14px;
        border-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          opacity: 0.7;
        }
        & + .card-btnItem {
          margin-left: 0;
          margin-top: 8px;
        }
      }
      .view-btn {
        background-color: #3f6aff;
        color: #fff;
      }
    }
  }
  .is-active {
    border: 1px solid #3f6aff;
  }
}
.pagination {
  height: 40px;
  display: flex;
  align-items: center;
}
</style>
