var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "activeName" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "wrap" }, [
          _c(
            "div",
            { staticClass: "video" },
            [
              _c("DpVideo", {
                key: _vm.currentVideoData.Id,
                ref: "dpVideo",
                attrs: {
                  videoId: _vm.currentVideoData.Id,
                  poster: _vm.currentVideoData.CoverUrl,
                  src: _vm.currentVideoData.VideoUrl,
                  canSeeTime: _vm.currentVideoData.CanSeeTime,
                  seekTime: _vm.currentVideoData.seekTime,
                  id: `dp-tutorial-detail`,
                },
                on: {
                  play: function ($event) {
                    return _vm.handlePlay(_vm.currentVideoData)
                  },
                  canSeePause: function ($event) {
                    return _vm.handleCanSeePause(_vm.index)
                  },
                  videoEnded: _vm.handleVideoEnded,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.currentVideoData.Name) + " "),
            ]),
            _c("div", { staticClass: "show_icon_content" }, [
              _c(
                "div",
                {
                  staticClass: "content",
                  class: ["content", { expanded: _vm.isExpanded }],
                },
                [_vm._v(" " + _vm._s(_vm.currentVideoData.Introduce) + " ")]
              ),
              _c(
                "div",
                { staticClass: "show_icon", on: { click: _vm.toggleContent } },
                [
                  !_vm.isExpanded
                    ? _c("i", { staticClass: "el-icon-arrow-down" })
                    : _c("i", { staticClass: "el-icon-arrow-up" }),
                ]
              ),
            ]),
          ]),
        ]),
        _vm.upgradeVisible
          ? _c("div", { staticClass: "big_video" }, [
              _c("div", { staticClass: "members_video" }, [
                _vm._v("This course is exclusive to members"),
              ]),
              _c("div", { staticClass: "become_video" }, [
                _vm._v("Become a member to watch the full video"),
              ]),
              _c(
                "div",
                {
                  staticClass: "subscribe_now_btn",
                  on: { click: _vm.jumpPage },
                },
                [_c("p", [_vm._v("Subscribe now")])]
              ),
              _vm.currentVideoData.Price != 0
                ? _c(
                    "div",
                    {
                      staticClass: "subscribe_price_box",
                      on: { click: _vm.ByVideo },
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "videp_price" }, [
                        _vm._v(
                          "Buy for $" + _vm._s(_vm.currentVideoData.Price)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.show_next_video
          ? _c("div", { staticClass: "big_video" }, [
              _c("div", { staticClass: "per_next_toplay_box" }, [
                _vm.previousVideoData
                  ? _c("div", { staticClass: "previous_lesson" }, [
                      _c("p", [_vm._v("Previous Lesson")]),
                      _c(
                        "div",
                        {
                          staticClass: "previous_lesson_video",
                          on: {
                            click: function ($event) {
                              return _vm.playNextVideo(_vm.previousVideoData)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.previousVideoData.CoverUrl,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "function_buttons" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pre_video_img",
                      on: { click: _vm.preVideo },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/video/pre_video.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "to_replay_img",
                      on: { click: _vm.toReplayVideo },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/video/to_replay.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "next_video_img",
                      on: { click: _vm.nextVideo },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/video/next_video.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm.nextVideoData
                  ? _c("div", { staticClass: "next_lesson" }, [
                      _c("p", [_vm._v("Next Lesson")]),
                      _c(
                        "div",
                        {
                          staticClass: "next_lesson_video",
                          on: {
                            click: function ($event) {
                              return _vm.playNextVideo(_vm.nextVideoData)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.nextVideoData.CoverUrl, alt: "" },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "more" }, [
          _c("div", { staticClass: "title", on: { click: _vm.MoreBtn } }, [
            _vm._v("More courses"),
          ]),
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.moreList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "list-item",
                  on: {
                    click: function ($event) {
                      return _vm.playBigVideo(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-video" }, [
                    _c("div", { staticClass: "coverurl_video" }, [
                      _c("img", { attrs: { src: item.CoverUrl, alt: "" } }),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-describe" }, [
                    _vm._v(_vm._s(item.Name)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("div", { staticClass: "comments" }, [_vm._v("Comments：")]),
          _c(
            "div",
            { staticClass: "block" },
            _vm._l(_vm.commentList, function (item, index) {
              return _c("div", { key: index, staticClass: "block-item" }, [
                _c(
                  "div",
                  { staticClass: "lt" },
                  [
                    _c(
                      "el-image",
                      {
                        staticClass: "avatar",
                        attrs: { src: item.Avatar || _vm.defaultAvatar },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "rt" }, [
                  _c("div", { staticClass: "header" }, [
                    _c("div", { staticClass: "user" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.MemberName)),
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(item.CreateTime.split(" ")[0])),
                      ]),
                    ]),
                    _c("div", { staticClass: "replay" }, [
                      _vm._v(_vm._s(item.Comment)),
                    ]),
                  ]),
                  _c("div", { staticClass: "footer" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.Reply,
                            expression: "item.Reply",
                          },
                        ],
                      },
                      [_vm._v("Reply: " + _vm._s(item.Reply))]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "add" },
            [
              _c("el-input", {
                staticClass: "add-input",
                attrs: { placeholder: "Add a new comment..." },
                model: {
                  value: _vm.comment,
                  callback: function ($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.addComment } },
            [_vm._v("Comment")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lock_img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/imgs/Bank-Transfer/lock.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }