var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("Your Store's Product"),
            ]),
            _c("div", { staticClass: "search" }, [
              _c(
                "div",
                { staticClass: "s-wrap" },
                [
                  _c("el-input", {
                    staticClass: "s-item search-input",
                    attrs: { placeholder: "Enter Product Name", size: "small" },
                    model: {
                      value: _vm.searchData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "name", $$v)
                      },
                      expression: "searchData.name",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticClass: "s-item search-input",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.searchData.cateValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "cateValue", $$v)
                        },
                        expression: "searchData.cateValue",
                      },
                    },
                    _vm._l(_vm.shopList, function (item) {
                      return _c("el-option", {
                        key: item.UserId,
                        attrs: { label: item.StoreName, value: item.UserId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "s-btns" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "s-item btns-item",
                      attrs: {
                        size: "small",
                        disabled: !_vm.searchData.cateValue,
                      },
                      on: { click: _vm.searchStoreData },
                    },
                    [_vm._v("Search")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "s-item btns-item",
                      attrs: {
                        size: "small",
                        disabled: !_vm.searchData.cateValue,
                      },
                      on: { click: _vm.openSync },
                    },
                    [_vm._v("Sync")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "box lt-box" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleTabClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "tab-wrap",
                        attrs: { label: "Waiting Connect", name: "first" },
                      },
                      _vm._l(_vm.ltData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "card pointer",
                            class:
                              _vm.currentLt.ShopProductId === item.ShopProductId
                                ? "is-active"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.handleLtClick(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-img" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      "open-delay": _vm.hoverDelayTime,
                                      width: _vm.hoverImgWidth,
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: item.ShopProductMainPic },
                                    }),
                                    _c("template", { slot: "reference" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.ShopProductMainPic },
                                      }),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-detail" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      "open-delay": _vm.hoverDelayTime,
                                      content: item.ShopProductName,
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "detail-text" }, [
                                      _vm._v(
                                        _vm._s(item.ShopProductName) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "detail-list" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Store Name: " + _vm._s(item.ShopName)
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.Price,
                                          expression: "item.Price",
                                        },
                                      ],
                                    },
                                    [_vm._v("$" + _vm._s(item.Price))]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-btns" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "card-btnItem",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.searchImg(item)
                                      },
                                    },
                                  },
                                  [_vm._v("Search IMG")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "tab-wrap",
                        attrs: { label: "Connected", name: "second" },
                      },
                      _vm._l(_vm.ltData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "card pointer",
                            class:
                              _vm.currentLt.ShopProductId === item.ShopProductId
                                ? "is-active"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.handleLtClick(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-img" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      "open-delay": _vm.hoverDelayTime,
                                      width: _vm.hoverImgWidth,
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: item.ShopProductMainPic },
                                    }),
                                    _c("template", { slot: "reference" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.ShopProductMainPic },
                                      }),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-detail" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      "open-delay": _vm.hoverDelayTime,
                                      content: item.ShopProductName,
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "detail-text" }, [
                                      _vm._v(_vm._s(item.ShopProductName)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "detail-list" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Store Name: " + _vm._s(item.ShopName)
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.Price,
                                          expression: "item.Price",
                                        },
                                      ],
                                    },
                                    [_vm._v("$" + _vm._s(item.Price))]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-btns" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "card-btnItem view-btn",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(item)
                                      },
                                    },
                                  },
                                  [_vm._v("Detail")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ltData?.length !== 0,
                    expression: "ltData?.length !== 0",
                  },
                ],
                staticClass: "pagination",
              },
              [
                _c("el-pagination", {
                  attrs: {
                    layout: "prev, pager, next",
                    currentPage: _vm.ltPageData.currentPage,
                    pageSize: _vm.ltPageData.pageSize,
                    total: _vm.ltPageData.total,
                  },
                  on: {
                    "size-change": _vm.handleLtSizeChange,
                    "current-change": _vm.handleLtCurrentChange,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("USAdrop Products")]),
              _c("div", { staticClass: "search" }, [
                _c(
                  "div",
                  { staticClass: "s-wrap" },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "s-item search-input2",
                        attrs: {
                          placeholder: "Enter Product Name",
                          size: "small",
                        },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "prefix" },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadImageRef",
                                attrs: {
                                  action: _vm.actionss,
                                  accept: "image/*",
                                  "show-file-list": false,
                                  "http-request": _vm.uploadImg,
                                },
                              },
                              [
                                !_vm.searchImage
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          background: "transparent",
                                          border: "none",
                                        },
                                        attrs: {
                                          circle: "",
                                          size: "small",
                                          loading: _vm.imgLoading,
                                        },
                                      },
                                      [
                                        !_vm.imgLoading
                                          ? _c("img", {
                                              staticClass: "search-img",
                                              attrs: {
                                                src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924727110_icon_upload.svg",
                                                alt: "",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.searchImage
                                  ? _c("el-image", {
                                      staticClass: "search-img",
                                      attrs: { src: _vm.searchImage },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "s-btns" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "s-item btns-item",
                        attrs: { size: "small" },
                        on: { click: _vm.rtSearch },
                      },
                      [_vm._v("Search")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.rtData?.length !== 0
                ? [
                    _c(
                      "div",
                      { staticClass: "box rt-box" },
                      _vm._l(_vm.rtData, function (item, index) {
                        return _c("div", { key: index, staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-img" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    "open-delay": _vm.hoverDelayTime,
                                    width: _vm.hoverImgWidth,
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.ShopProductMainPic },
                                  }),
                                  _c("template", { slot: "reference" }, [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: item.ShopProductMainPic },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-detail" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    "open-delay": _vm.hoverDelayTime,
                                    content: item.ShopProductName,
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "detail-text" }, [
                                    _vm._v(_vm._s(item.ShopProductName)),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "detail-list" }, [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#3f6aff" } },
                                  [_vm._v("$" + _vm._s(item.Price))]
                                ),
                              ]),
                              _vm._m(0, true),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-btns" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "card-btnItem",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openConnect(item)
                                    },
                                  },
                                },
                                [_vm._v("Connect")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "card-btnItem view-btn",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpView(item)
                                    },
                                  },
                                },
                                [_vm._v("View")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.rtData?.length !== 0,
                            expression: "rtData?.length !== 0",
                          },
                        ],
                        staticClass: "pagination",
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            layout: "prev, pager, next",
                            currentPage: _vm.rtPageData.currentPage,
                            pageSize: _vm.rtPageData.pageSize,
                            total: _vm.rtPageData.total,
                          },
                          on: {
                            "size-change": _vm.handleRtSizeChange,
                            "current-change": _vm.handleRtCurrentChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("div", { staticClass: "rt-box" }, [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " To get the quotation immediately, match your store products with USAdrop's. "
                            ),
                          ]),
                          _vm._l(_vm.sectionData, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "section" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(`${index + 1}.${item.content}`) +
                                    " "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]
      ),
      _c("connect-dialog", {
        ref: "connectRef",
        attrs: {
          type: _vm.connectType,
          visible: _vm.connectVisible,
          hoverDelayTime: _vm.hoverDelayTime,
          hoverImgWidth: _vm.hoverImgWidth,
        },
        on: {
          "update:visible": function ($event) {
            _vm.connectVisible = $event
          },
          confirm: _vm.connectConfirm,
        },
      }),
      _c("sync-dialog", {
        ref: "syncRef",
        attrs: { visible: _vm.syncShow },
        on: {
          "update:visible": function ($event) {
            _vm.syncShow = $event
          },
          confirm: _vm.syncConfirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detail-list" }, [
      _c("span", [_vm._v("Cost Price")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }