var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "HelpCenterTitleBig" }, [
        _vm._v(_vm._s(_vm.$t("HelpCenter.HelpCenter"))),
      ]),
      _c("div", { staticClass: "activeName_big_box" }, [
        _c("div", { staticClass: "activeName" }, [
          _c(
            "div",
            { staticClass: "HelpCenterBigBox" },
            [
              _c(
                "el-collapse",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                _vm._l(_vm.HelpInfos, function (item) {
                  return _c(
                    "el-collapse-item",
                    { key: item.Id, attrs: { name: item.Id } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(item.Title) + " "),
                      ]),
                      _c("div", [
                        _c("div", [_vm._v(_vm._s(item.CreateTime))]),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(item.HelpContent) },
                        }),
                      ]),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }