<script>
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import FeedReturnInfo from '@/views/SupportTicket/widgets/FeedReturnInfo.vue'
import { baseApiHost } from '@/utils/env'

//Returned by customer 退款退货
export default {
  name: 'FeedTemplate6',
  props: {
    dataSource: Object,
    imgUploadPath: String,
    afterSaleDetail: Object,
    readonly: Boolean
  },
  components: {
    FeedReturnInfo,
    FeedImageUpload
  },
  data() {
    return {
      formModel: {
        TempPath: null,
        IsPic: false,
        fileList: []
      },
      defaultFileList: [],
      returnInfos: {}
    }
  },
  watch: {
    afterSaleDetail: {
      handler: function (newVal) {
        if (!newVal) return
        this.formModel.fileList = newVal.FileList || []
        const imageList = []
        this.formModel.fileList.forEach((item) => {
          let url = ''
          if (item.Url.startsWith('/')) {
            url = baseApiHost() + item.Url
          } else {
            url = baseApiHost() + '/' + item.Url
          }
          imageList.push({
            name: item.FileName,
            url: url
          })
        })
        this.defaultFileList = imageList
        this.returnInfos = {
          ReturnedTrackingNumber: newVal.ReturnAttachedData?.ReturnedTrackingNumber || '',
          ReturnedSkuMap: newVal.ReturnAttachedData.ReturnedSkuMap || {}
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    notExcludeOrderDetails: function () {
      return this.dataSource.OrderDetails?.filter((item) => !item.IsExclude)
    }
  },
  methods: {
    getTemplateData() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            this.$refs.needReSendRef
              .getReturnProducts()
              .then((returnData) => {
                let data = {
                  ...this.formModel,
                  ...returnData,
                  TempPath: this.imgUploadPath,
                  IsPic: this.formModel.fileList.length > 0
                }
                resolve(data)
              })
              .catch((error) => {
                reject(error)
              })
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <el-form ref="formRef" label-position="top" :model="formModel" :disabled="readonly">
    <el-form-item>
      <FeedReturnInfo
        ref="needReSendRef"
        :order-details="notExcludeOrderDetails"
        :return-infos="returnInfos"
        :readonly="readonly"
      />
    </el-form-item>
    <el-form-item>
      <FeedImageUpload
        v-model="formModel.fileList"
        :img-upload-path="imgUploadPath"
        :show-example="true"
        :default-file-list="defaultFileList"
        :readonly="readonly"
      />
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped></style>
