import request from '@/utils/request'

export const GetList = (params) =>
  request({
    method: 'get',
    url: '/api/Store/GetList',
    params
  })

export const GetOauthRedirectUrl = (params) =>
  request({
    method: 'get',
    url: '/api/Store/GetOauthRedirectUrl',
    params
  })
export const AddStore = (data) =>
  request({
    method: 'post',
    url: '/api/Store/AddStore',
    data
  })
export const AddStoreForHand = (data) =>
  request({
    method: 'post',
    url: '/api/Store/AddStoreForHand',
    data
  })
export const RefreshStore = (data) =>
  request({
    method: 'post',
    url: '/api/Store/RefreshStore',
    data
  })

export const ReceiveShopifyToken = (data) =>
  request({
    method: 'post',
    url: '/api/Store/ReceiveShopifyToken',
    data
  })
export const ShopifyConnect = (data) =>
  request({
    method: 'post',
    url: '/api/Member/ShopifyConnect',
    data
  })
export const GetShopifyTokenKey = (data) =>
  request({
    method: 'post',
    url: '/api/Member/GetShopifyTokenKey',
    data
  })
export const ReceiveAndRefreshShopifyToken = (data) =>
  request({
    method: 'post',
    url: '/api/Store/ReceiveAndRefreshShopifyToken',
    data
  })
export const InventoryGetList = (data) =>
  request({
    method: 'post',
    url: '/api/WmsInventory/GetList',
    data
  })
export const InventoryExport = (data) =>
  request({
    method: 'post',
    url: '/api/WmsInventory/Export',
    data
  })
export const GetWarehouseList = (params) =>
  request({
    method: 'get',
    url: '/api/WmsInventory/GetWarehouseList',
    params
  })
export const TiktokShopConnect = (data) =>
  request({
    method: 'post',
    url: '/api/Member/TiktokShopConnect',
    data
  })
export const ReceiveRefreshTTSTokenOrKeys = (data) =>
  request({
    method: 'post',
    url: '/api/Store/ReceiveRefreshTTSTokenOrKeys',
    data
  })
export const RefreshTTSTokenByKey = (data) =>
  request({
    method: 'post',
    url: '/api/Store/RefreshTTSTokenByKey',
    data
  })
