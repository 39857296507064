<template>
  <div class="page">
    <div class="activeName">
      <div class="detail" :model="CheckList">
        <div class="StepBigBox">
         <LoginContentLeft></LoginContentLeft>
          <div class="StepOneRight">
            <div class="StepMessageBox">
              <div class="LogoBig">
                <img src="../../assets/imgs/NewLogo/LogoOne.png" alt="" />
              </div>
              <div class="guide_FillInYou">
                <p>{{ $t("guide.FillInYou") }}</p>
              </div>
              <div class="StartStepMessage">
                <p>{{ $t("guide.StartJourney") }}</p>
                <p>
                  {{ $t("guide.with") }}
                  <span class="col-col5"
                    style="font-size: 20px;font-weight: 600;font-family: Regular;-webkit-font-smoothing: antialiased;"
                    >{{ $t("guide.60k") }}</span>
                  {{ $t("guide.dropshippers") }}
                </p>
              </div>
              <div class="Order_quantity"> <!-- 订单量 -->
                <p>{{ $t("guide.business") }}</p>
                <div class="how_many">
                  <el-select
                    v-model="value2"
                    :placeholder="$t('guide.HowMany')"
                    style="width: 440px;height: 40px; margin-top: 10px;font-size:13px;font-family:Regular;-webkit-font-smoothing: antialiased;margin-left: -190px;">
                    <el-option
                      v-for="item in optionOrders"
                      :key="item.id"
                      :label="item.label"
                      :value="item.value"
                      style="border: #ccc;outline: none;font-size:13px;font-family:Regular;-webkit-font-smoothing: antialiased;">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="GuideTelephone">
                <p>{{ $t("guide.telephone") }}</p>
                <span>*</span>
              </div>
              <div class="selectbox">
                <div class="select">
                    <el-select
                      v-model="selectCode"
                      filterable
                      :filter-method="dataFilter"
                      :placeholder="$t('guide.CountryCode')"
                      @change="ChangeFlag"
                      class="select-inner">
                      <template slot="prefix">
                        <i :class="selectedClass" style="margin-top:17px"></i>
                      </template>
                      <el-option
                        v-for="item in optionsCopy"
                        :key="item.Id"
                        :label="'(' + '+' + item.PhoneCode + ')'"
                        :value="item.Id">
                        <span style="float: left; color: #8492a6; font-size: 13px">
                          <i :class="item.NationalFlag"></i>&nbsp;
                          {{ item.Name + "(+" + item.PhoneCode + ")" }}
                        </span>
                      </el-option>
                    </el-select>
                  </div>
                  <input v-model="CheckList.Mobile" type="text"  :placeholder="$t('guide.phone')" />
              </div>
              <div class="IconInfo" @mouseenter="enter" @mouseleave="leave">
                <i class="el-icon-info" style="font-size: 20px;"></i>
              </div>
              <div class="DisplayInfo btn-bg2" v-if="displayUSAdropInfo">
                <p>{{ $t("guide.privacyUSAdrop") }}</p>
                <p>{{ $t("guide.infomation") }}</p>
              </div>
              <div class="codebox" style="margin-top: 20px;">
                <input
                  v-model="verifyCode"
                  type="text"
                  :placeholder="$t('register.code')"
                  style="font-size:13px;font-family:Regular;-webkit-font-smoothing: antialiased;color:#000!important;" />
                <button class="nnnn" @click="getVerifyCode">{{ $t("guide.Send") }}</button>
                <div v-show="displayShowCode" ref="child" class="paybox" @click="closeBtn"> <!-- 图片验证码 -->
                  <div class="ValidationBox">
                    <Validation :phoneNum="CheckList.Mobile" :phoneCode="selectCode"></Validation>
                  </div>
                </div>
                <div class="UnableVerification">
                  <p @click.stop="DisplayBtn()">{{ $t("guide.NoCode") }}</p>
                </div>
                <div class="UnableReceive" v-show="DisplayUnable">
                  <p style="margin-top: -5px;">{{ $t("guide.EmailsUs") }}</p>
                  <p style="margin-top: -6px;">{{ $t("guide.infoUsadropcom") }}</p>
                </div>
              </div>
              <div class="conTent">
                <p style="margin-top: 20px;">{{ $t("guide.whatsApp") }}</p>
                <p style="margin-top: 20px;">
                  <el-input v-model="CheckList.WhatsApp" name="" :placeholder="$t('')"></el-input>
                </p>
              </div>
              <div class="Confirm">
                <button @click="btn" class="btn-bg0">{{ $t("guide.next") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginContentLeft from '@/views/Login/LoginContentLeft.vue';
import { isMobileNumber } from "@/utils/tools";
import Validation from "@/components/GraphicValidation.vue";
import "@/assets/css/famfamfam-flags.css";
export default {
  name: "StepOne",
  data: () => {
    return {
      dispatch: null,
      weChat: "",
      verifyCode: "",
      active: 0,
      PhoneCode: "",
      RegisterMessage: {},
      CheckList: {},
      selectCode: "+1",
      DisplayUnable: false,
      displayUSAdropInfo: false,
      optionsCopy: [],
      selectedClass: "famfamfam-flag-us", // 联系时间
      value2: "",
      options: [
        {
          id: 1,
          value: "EST 12:00PM-02:00PM",
          label: "EST 12:00PM-02:00PM"
        },
        {
          id: 2,
          value: "EST 21:00PM-22:00PM",
          label: "EST 21:00PM-22:00PM"
        }
      ],
      optionOrders: [
        {
          id: 1,
          value: "Not started yet",
          label: "Not started yet"
        },
        {
          id: 2,
          value: "1-5 orders",
          label: "1-5 orders"
        },
        {
          id: 3,
          value: "6-20 orders",
          label: "6-20 orders"
        },
        {
          id: 4,
          value: "21-50 orders",
          label: "21-50 orders"
        },
        {
          id: 5,
          value: "More than 50 orders",
          label: "More than 50 orders"
        }
      ],
      displayShowCode: false
    };
  },
  components: {
    Validation,
    LoginContentLeft
  },
  mounted() {
    this.getData();
    this.CheckGuide();
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  methods: {
    enter: function(e) {
      this.displayUSAdropInfo = true;
    },
    leave: function(e) {
      this.displayUSAdropInfo = false;
    },
    dataFilter(val) {
      this.value = val;
      if (val) {
        this.optionsCopy = this.RegisterMessage.filter(item => {
          if (
            !!~item.EnglishName.indexOf(val) ||
            !!~item.EnglishName.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        this.optionsCopy = this.RegisterMessage;
      }
    },
    DisplayBtn() {
      this.DisplayUnable = true;
    },
    DisplayButton() {
      this.DisplayUnable = false;
    },
    CheckGuide() {
      this.dispatch('guide/checkGuideGu')
        .then(res => {
          this.CheckList = res.Result.ProfileInfo;
          if (res.Result.IsStoreComplete == true) {
            this.$message.success(this.$("guide.YourSuccessfully"));
          } else {
            this.$router.push("StepOne");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getData() {
      let that = this;
      this.dispatch('login/registerCountryList')
        .then(res => {
          if (res.Success) {
            that.RegisterMessage = res.Result;
            that.optionsCopy = res.Result;
          } else {
            this.$message(that.$t("tips.registerCountry"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    ChangeFlag(selectedKey) {
      var tempObj = this.optionsCopy.find(function(i) {
        return i.Id == selectedKey;
      });
      if (tempObj) {
        this.selectedClass = tempObj.NationalFlag;
        this.selectCode = `${"+" + tempObj.PhoneCode}`;
      }
    },
    btn() {
      if (this.CheckList.Fullname == "") {
        return this.$message.error(this.$t("guide.FullnameMandatory"));
      }
      if (this.CheckList.Email == "") {
        return this.$message.error(this.$t("guide.EmailMandatory"));
      }
      if (this.CheckList.Mobile == "") {
        return this.$message.error(this.$t("guide.MobileMandatory"));
      }
      if (this.value2 == "") {
        return this.$message.error(this.$t("guide.FieldEmpty"));
      }
      var _phoneCode = this.selectCode.replace("+", "");
      this.dispatch('guide/OAuthGuideGu', {
          FillName: this.CheckList.Fullname,
          Email: this.CheckList.Email,
          PhoneCode: _phoneCode,
          Telephone: this.CheckList.Mobile,
          VerifyCode: this.verifyCode,
          Skype: this.CheckList.Skype,
          Facebook: this.CheckList.Facebook,
          WhatsApp: this.CheckList.WhatsApp, //联系时间
          OrdersByDay: this.value2
        })
        .then(res => {
          if (res.Success) {
            this.$message.success(this.$t("guide.FillSuccessfully"));
            this.$router.push("/StepTwo");
          } else {
            this.$message.error(res.ErrMsg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //图片验证码
    getVerifyCode() {
      this.displayShowCode = true;
    },
    closeBtn() {
      setTimeout(() => {
        this.displayShowCode = false;
      }, 2000);
    },
    ErrorCloseBtn() {
      this.displayShowCode = false;
    }
  }
};
</script>

<style scoped lang="scss">
.IconInfo {
  position: absolute;
  top: 365px;
  right: 5px;
  cursor: pointer;
}
.DisplayInfo {
  position: absolute;
  bottom: 319px;
  right: 29px;
  color: #fff;
  z-index: 9999;
  background: #93df89;
  border-radius: 10px;
  font-size: 12px;
  padding: 6px 10px;
}
.StepBigBox {
  position: relative;
  display: flex;
  height: 750px;
}
.StepOneRight {
  width: 800px;
  height: 748px;
  margin-right: -40px;
}
.StepMessageBox {
  width: 510px;
  height: 748px;
  position: absolute;
  right: 250px;
  background: #fff;
  padding-top: 75px;
}
.register {
  width: 510px;
  height: 754px;
  position: absolute;
  right: 0px;
  top: -5px;
  background: #fff;
  padding-top: 75px;
}
.LogoBig {
  width: 120px;
  height: 120px;
  position: absolute;
  right: 360px;
  top: 0;
  z-index: 99999;
  .LogoBig img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.guide_FillInYou {
  margin-top: 25px;
  font-size: 26px;
  font-weight: 600;
  color: #433759;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 85px;
}
.StartStepMessage {
  margin-top: 10px;
  .StartStepMessage p:nth-child(1) {
    font-size: 18px;
    color: #4e415f;
    margin-left: 115px;
  }
  .StartStepMessage p:nth-child(2) {
    font-size: 18px;
    color: #4e415f;
    margin-left: 145px;
  }
}
.UnableVerification {
  position: absolute;
  top: 15px;
  right: 80px;
  color: #4c8bf5;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  .UnableVerification p {
    font-size: 14px;
    color: #4c8bf5;
    cursor: pointer;
    font-style: italic;
    font-family: "Regular";
    -webkit-font-smoothing: antialiased;
  }
  .UnableVerification p:hover {
    transform: scale(1.2);
  }
}
.UnableReceive {
  width: 300px;
  height: 30px;
  position: absolute;
  bottom: -35px;
  left: 35px;
  display: flex;
  font-family: "Regular";
  .UnableReceive p {
    font-size: 14px;
    color: #858585;
    line-height: 30px;
  }
  .UnableReceive p:nth-child(1) {
    font-size: 14px;
    color: #858585;
    line-height: 30px;
  }
  .UnableReceive p:nth-child(2) {
    font-size: 14px;
    color: #858585;
    line-height: 30px;
    font-weight: 600;
  }
}
.nnnn {
  width: 130px;
  height: 46px;
  background: #efeff7;
  border-radius: 50px;
  border: 0;
  color: #5e5ee0;
  font-size: 15px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: -5px;
}
.nnnn:hover {
  background: #f9f9fa;
  color: #5e5ee0;
}
.codebox {
  display: flex;
  position: relative;
}
.codebox input {
  width: 170px;
  height: 45px;
  border: 1px solid #b2b2b2;
  margin-right: 20px;
  padding-left: 10px;
  border-radius: 50px;
  margin-left: 30px;
}
.activeName {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #150f4b;
  overflow: hidden;
}
.detail {
  margin-top: 50px;
  margin-left: 250px;
  position: relative;
}
.detail .content {
  height: 70px;
  line-height: 50px;
  position: relative;
}
.detail .content span:nth-child(1) {
  width: 10px;
  height: 10px;
  line-height: 10px;
  color: red;
  display: block;
  position: absolute;
  top: 15px;
  left: 175px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.detail .content span:nth-child(2) {
  display: inline-block;
  width: 168px;
  text-align: right;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
}
.detail .content span:nth-child(3) {
  display: inline-block;
  margin-left: 53px;
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #666666;
  width: 500px;
}
::v-deep .detail .content .el-input__inner {
  height: 40px;
  width: 100%;
  padding: 0 !important;
  border-radius: 50px;
  text-indent: 20px;
  line-height: 35px;
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.detail .conTent p:nth-child(1) {
  display: inline-block;
  width: 168px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000;
  margin-left: 35px;
}
.detail .conTent p:nth-child(2) {
  display: inline-block;
  margin-left: 35px;
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000;
  width: 450px;
}
::v-deep .detail .conTent .el-input__inner {
  height: 40px;
  width: 100%;
  padding: 0 !important;
  border-radius: 50px;
  text-indent: 20px;
  line-height: 35px;
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Confirm button {
  width: 250px;
  height: 46px;
  border-radius: 50px;
  border: 0;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #ffffff;
  margin-left: 150px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}
.Confirm button:hover {
  background: #67689a;
  color: #fff;
}
::v-deep .el-step__icon {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  box-sizing: border-box;
  background: #fff;
  transition: 0.15s ease-out;
}
::v-deep .el-step__head {
  margin-left: 50px !important;
}
.selectbox {
  width: 440px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid #b2b2b2;
  padding: 9px 30px 9px 115px;
  position: relative;
  margin-left: 30px;
  margin-top: 50px;
}
.selectbox input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.GuideTelephone p {
  position: absolute;
  top: 320px;
  left: 30px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.GuideTelephone span {
  display: block;
  width: 10px;
  height: 10px;
  line-height: 10px;
  position: absolute;
  color: red;
  top: 320px;
  left: 125px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.select {
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 15px;
  border-radius: 50px 0 0 50px;
  overflow: hidden;
  background: none !important;
  border-right: 1px solid #eee;
}
::v-deep .select .el-select {
  height: 48px;
  line-height: 48px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .select .el-input--suffix {
  height: 48px;
}
::v-deep .select .el-input__inner {
  background: none !important;
  height: 48px;
  line-height: 48px;
  width: 145px;
  padding-left: 30px;
  background: none;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
}
.time_orders { /* 订单量 */
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
}
.Order_quantity p {
  color: #858585;
  font-size: 13px;
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  margin-top: 18px;
  margin-left: 20px;
  font-style: italic;
}
.how_many {
  margin-left: 0px;
}
.how_many{
  ::v-deep .el-input__inner{
  border-radius: 50px;
}}
.Order_quantity p {
  color: #858585;
  font-size: 13px;
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  margin-top: 30px;
  margin-left: 35px;
}
.how_many {
  margin-left: 220px;
}
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.ValidationBox {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
</style>
