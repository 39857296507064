<!--
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
-->
<template>
  <div class="particulars" >
    <!-- 详情页面 -->
    <!-- 内容 -->
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      page: "orders", //导航栏选中
      banner: "",
      itemname: ""
    };
  },
  components: {
  },
  mounted() {
  },
//   computed: mapState(["userInfo", "theme"]),
  methods: {},
//   watch: {
//     $route: function(to) {
//       // 跳转支付后确保下拉框可查看详情
//       if (to.name == "pending") {
//         this.page = "pending";
//       } else {
//         this.page = "product";
//       }
//     }
//   }
};
</script>

<style scoped>
.particulars {
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.particulars.dark {
  background: #1f1e2e !important;
  background-image: url('../../assets/imgs/Christmas/XueHua.png');
}
</style>
