<template>
  <div class="detail_container">
    <div class="banner">
      ARE YOU STILL ON THE FREE PLAN?  <span class="red_color1">PLUS</span>&nbsp;USERS MAKE ON
      AVERAGE<span class="red_color1">&nbsp;10X MORE REVENUE</span>
    </div>
    <div class="money_container">
      <div class="money_item">
        <div class="inner_item inner_item1">
          <img
            class="save_icon"
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927362623_upgrade_save_icon.png"
            alt=""
          />
          <img
            class="img_icon"
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927362804_upgrade_icon.png"
            alt=""
          />
          <div class="btns">
            <div class="btn" :class="type === 'Mon' ? 'active' : ''" @click="showType('Mon')">
              Monthly
            </div>
            <div class="btn" :class="type === 'Year' ? 'active' : ''" @click="showType('Year')">
              Yearly
            </div>
          </div>
        </div>
      </div>
      <div class="money_item">
        <div
          class="inner_item inner_item2"
          :class="containHover === 'free' ? 'active_item' : ''"
          @mouseover="containHover = 'free'"
        >
          <span class="status">Free</span>
          <div class="sum">
            $0<span class="type">/{{ type }}</span>
          </div>
          <div
            class="btn"
            @click="$router.push('/Products/Winner')"
            :class="btnHover === 'free' ? 'btn1' : ''"
            @mouseover="btnHover = 'free'"
          >
            Get Started
          </div>
        </div>
      </div>
      <div class="money_item">
        <div
          class="inner_item inner_item2"
          :class="containHover === 'pro' ? 'active_item' : ''"
          @mouseover="containHover = 'pro'"
        >
          <span class="status">Pro</span>
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927417616_pro_icon.svg"
            alt=""
          />
          <div class="sum">
            <span v-if="memberInfo.pro[type]?.DiscountType === -1"
              >${{ memberInfo.pro[type]?.Price }}</span
            >
            <span v-else>${{ memberInfo.pro[type]?.RealPrice }}</span>
            <span class="type">/{{ type }}</span>
          </div>
          <span class="price_text" v-if="memberInfo.pro[type]?.DiscountType >= 0">{{
            memberInfo.pro[type].PriceText
          }}</span>
          <div
            class="btn"
            @click="goPay(1, memberInfo.pro[type]?.CycleType, memberInfo.pro[type]?.PriceId)"
            :class="btnHover === 'pro' ? 'btn1' : ''"
            @mouseover="btnHover = 'pro'"
          >
            Upgrade Now
          </div>
        </div>
      </div>
      <div class="money_item">
        <div
          class="inner_item inner_item2"
          :class="containHover === 'plus' ? 'active_item' : ''"
          @mouseover="containHover = 'plus'"
        >
          <span class="status">Plus</span>
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927444376_plus_icon.svg"
            alt=""
          />
          <div class="sum">
            <span v-if="memberInfo.plus[type]?.DiscountType === -1"
              >${{ memberInfo.plus[type]?.Price }}</span
            >
            <span v-else>${{ memberInfo.plus[type]?.RealPrice }}</span>
            <span class="type">/{{ type }}</span>
          </div>
          <span class="price_text" v-if="memberInfo.plus[type]?.DiscountType >= 0">{{
            memberInfo.plus[type].PriceText
          }}</span>
          <div
            class="btn"
            @click="goPay(2, memberInfo.plus[type]?.CycleType, memberInfo.plus[type]?.PriceId)"
            :class="btnHover === 'plus' ? 'btn1' : ''"
            @mouseover="btnHover = 'plus'"
          >
            Upgrade
          </div>
        </div>
      </div>
    </div>
    <div class="data_container">
      <div class="data_item">
        <div class="data_item_title" v-for="(item, index) in columTitle" :key="index">
          <el-tooltip :content="item.tooltip" placement="right" popper-class="tooltip_width">
            <span>{{ item.title }}</span>
          </el-tooltip>
        </div>
      </div>
      <template v-for="(m, i) in valueList">
        <div class="data_item value_item" :key="i">
          <div class="data_item_title data_item_value" v-for="(item, index) in m" :key="index">
            <span v-if="item.title !== 'false' && item.title !== 'true'">{{ item.title }}</span>
            <img
              v-if="item.title === 'true'"
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927482049_upgrade_check_icon.png"
              alt=""
            />
            <img
              v-if="item.title === 'false'"
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927482074_upgrade_close_icon.png"
              alt=""
            />
          </div>
        </div>
      </template>
    </div>
    <PayDialogVue :dialogVisible.sync="dialogVisible" @goUpgrade="goUpgrade"></PayDialogVue>
  </div>
</template>
<script>
import request from '@/utils/request'
import PayDialogVue from './PayDialog.vue'
export default {
  components: {
    PayDialogVue
  },
  data() {
    return {
      showShopify: false, //配置项 如果true 直接去升级页面
      routeInfo: {},
      dialogVisible: false,
      dispatch: null,
      columTitle: [],
      valueList: [[], [], []],
      type: 'Mon', //mon or year
      memberInfo: {
        plus: {
          Year: null,
          Mon: null
        },
        pro: {
          Year: null,
          Mon: null
        }
      },
      containHover: 'pro',
      btnHover: 'pro',
      radioInfo: {
        Mon: { radio1: 1, radio2: 1 },
        Year: { radio1: 3, radio2: 3 }
      }
    }
  },
  created() {
    this.getTableList()
    this.getMemberInfo()
  },
  methods: {
    showType(type) {
      this.type = type
    },
    getTableList() {
      request({
        method: 'post',
        url: '/api/Grade/GetGradeSettings'
      })
        .then((res) => {
          if (res.Result.length > 0) {
            res.Result.forEach((item, index) => {
              if (index == 0) {
                this.columTitle = item.SettingVos.map((_item) => ({
                  title: _item.Title,
                  tooltip: _item.Tooltip
                }))
              }
              this.valueList[index] = item.SettingVos.map((_item) => ({
                title: _item.Remarks
              }))
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getMemberInfo() {
      request({
        method: 'get',
        url: '/api/MemberShip/GetGradePriceList'
      }).then((res) => {
        if (res.Result) {
          this.memberInfo = {
            plus: {
              Year: res.Result.filter((item) => item.GradeId === 3 && item.CycleType === 2)[0],
              Mon: res.Result.filter((item) => item.GradeId === 3 && item.CycleType === 0)[0]
            },
            pro: {
              Year: res.Result.filter((item) => item.GradeId === 2 && item.CycleType === 2)[0],
              Mon: res.Result.filter((item) => item.GradeId === 2 && item.CycleType === 0)[0]
            }
          }
          console.log('memberInfo', this.memberInfo)
        }
      })
    },
    goUpgrade(type) {
      if (type === 'shopify') {
        this.goShopify(this.routeInfo)
      } else if (type === 'other') {
        this.goOther(this.routeInfo)
      }
      this.dialogVisible = false
    },
    goShopify({ gradeId }) {
      let priceType = 1
      if (gradeId + 1 === 2) {
        priceType = { Mon: 1, Year: 3 }
      } else if (gradeId + 1 === 3) {
        priceType = { Mon: 4, Year: 6 }
      }
      this.$router.push({
        name: 'Shopify',
        params: {
          id: gradeId + 1,
          priceType: priceType[this.type]
        }
      })
    },
    goOther({ gradeId, CycleType, priceId }) {
      this.$router.push({
        name: 'Payment',
        params: {
          id: gradeId + '',
          radio: this.type === 'Mon' ? 1 : 3,
          cycleType: CycleType,
          priceId: priceId
        }
      })
    },
    goPay(gradeId, CycleType, priceId) {
      this.routeInfo = { gradeId, CycleType, priceId }
      this.dialogVisible = true
    }
  }
}
</script>
<style>
.tooltip_width {
  max-width: 320px;
  font-size: 12px !important;
  word-break: normal;
}
</style>
<style lang="scss" scoped>
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
$blue: #15104b;

.detail_container {
  width: 1400px;
  font-family: 'Regular';

  .banner {
    height: 64px;
    width: 100%;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    border-radius: 9px;
    margin-bottom: 48px;
    background: #93df89;
    @extend .flex_center;
  }
  .money_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .money_item {
      width: 25%;
      padding: 0 24px;
      .inner_item {
        position: relative;
        display: flex;
        height: 200px;
        background: #fff;
        border-radius: 16px;
        flex-direction: column;
        padding: 14px 0 14px 0;
      }
      .inner_item1 {
        height: 230px;
        justify-content: space-between;
        background: transparent;
        margin-top: -30px;
        padding: 0px;
        .save_icon {
          width: 180px;
          margin-top: 10px;
        }
        .img_icon {
          width: 100px;
          height: 120px;
          position: absolute;
          right: 0px;
          top: 38px;
        }
        .btns {
          width: 300px;
          height: 64px;
          background: #fff;
          @extend .flex_center;
          justify-content: space-between;
          font-size: 18px;
          font-weight: 600;
          color: $blue;
          border-radius: 60px;
          padding: 4px;
          .btn {
            cursor: pointer;
            height: 100%;
            width: 49%;
            @extend .flex_center;
            border-radius: 60px;
          }
          .active {
            background: $blue;
            color: #fff;
          }
        }
      }
      .inner_item2 {
        justify-content: space-around;
        align-items: center;
        img {
          width: 105px;
          height: 105px;
          position: absolute;
          left: -50px;
          top: -60px;
        }
        .status {
          font-size: 24px;
          font-weight: 600;
          color: $blue;
        }
        .sum {
          font-size: 40px;
          font-weight: 700;
          color: $blue;
        }
        .price_text {
          color: #d70000;
          text-decoration: line-through;
          font-size: 16px;
        }
        .type {
          font-size: 18px;
          font-weight: 400;
          color: $blue;
        }
        .btn {
          width: 208px;
          height: 40px;
          @extend .flex_center;
          color: #fff;
          border-radius: 30px;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          border: 2px solid $blue;
          color: $blue;
          background: #fff;
        }
        .btn1 {
          background: $blue;
          border: none;
          color: #fff;
        }
        .btn2 {
        }
        .btn3 {
          background: #eff1f3;
          color: $blue;
        }
      }
      .active_item {
        border: 2px solid $blue;
      }
    }
  }
  .data_container {
    margin-bottom: 40px;
    margin-top: 24px;
    padding-bottom: 16px;
    padding-left: 16px;
    width: 100%;
    background: #fff;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    .data_item {
      width: 25%;
      display: flex;
      flex-direction: column;
      .data_item_title {
        padding: 0 24px;
        font-family: 'Regular';
        height: 20px;
        margin-top: 20px;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        color: #1b1c27;
        height: 40px;
        line-height: 21px;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .value_item {
      border-left: 1px solid #d9d9d9;
      .data_item_title {
        font-family: 'Regular';
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #1b1c27;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.red_color1 {
  color: #ff0000;
}
.red_color2 {
  color: #d70000;
}
</style>
