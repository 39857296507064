var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "bread", class: [_vm.theme == "common" ? "" : "dark"] },
    [
      _c(
        "el-breadcrumb",
        { attrs: { "separator-class": "el-icon-arrow-right" } },
        _vm._l(_vm.breadListLast, function (item, index) {
          return _c(
            "el-breadcrumb-item",
            {
              key: item.index,
              class:
                index == _vm.breadListLast.length - 1 &&
                _vm.breadListLast.length > 1
                  ? "last-bread"
                  : "",
              attrs: { to: item.path },
            },
            [_vm._v(_vm._s(item.title))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }