<template>
  <div class="content-left">
    <div class="titleTopImg">
      <div class="LoginImgTitle">
        <!-- <img src="../../assets/imgs/NewLogo/LogoOne.svg" alt="icon" /> -->
        <img :src="logoImg[formLink]" alt="" />
      </div>
      <div class="GroupAppraise">
        <!-- <img src="../../assets/imgs/NewLogin/Group6.png" alt="icon" /> -->
        <img
          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580248413_Group6.png"
          alt="icon"
        />
      </div>
    </div>
    <div class="ContentWordBox">
      <p>{{ $t('login.YourDropshipping') }}</p>
      <p>
        {{ $t('login.withOver') }} <span>{{ $t('login.tenYears') }}</span>
        {{ $t('login.ofExperience') }}
      </p>
    </div>
    <div class="DropshippingPlatform">
      <p>{{ $t('login.DROPSHIPPING') }}</p>
    </div>
    <div class="DropshippingWorldwide">
      <p>{{ $t('login.KNOWLEDGE') }}</p>
      <p>{{ $t('login.MARKET') }}</p>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      // 三个国家的logo（美国、越南、西班牙）
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png'),
        'es.usadrop.com': require('@/assets/imgs/NewLogo/ES215x189.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style lang="scss" scoped>
.content-left {
  height: 748px;
  width: 908px;
  background-image: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580086548_Group94.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #231d64;
  color: #ffffff;
  border-bottom-left-radius: 26px;
  border-top-left-radius: 26px;

  .titleTopImg {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    padding: 0 46px 0 50px;

    .LoginImgTitle {
      width: 215px;
      height: 189px;

      img {
        width: 100%;
      }
    }

    .GroupAppraise {
      width: 158px;
      height: 29px;

      img {
        width: 100%;
        margin-top: 26px;
      }
    }
  }

  .ContentWordBox {
    font-size: 34px;
    font-family: 'Bold';
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    line-height: 55px;
    margin-top: 20px;
    padding: 0 23px 0 50px;
    span {
      font-size: 50px;
      font-weight: 900;
    }
  }
  .DropshippingPlatform {
    padding: 0 23px 0 50px;
    font-size: 34px;
    font-weight: 600;
    margin-top: 100px;
    line-height: 2;
    font-family: 'Bold';
  }
  .DropshippingWorldwide {
    padding: 0 23px 0 50px;
    margin-top: 25px;
    font-size: 20px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
}
</style>
