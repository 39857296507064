<template>
  <div class="member-tips">
    <el-dialog :visible.sync="visible" class="tips-dialog" top="35vh" @close="handleClose">
      <div class="members_video">This course is exclusive to members</div>
      <div class="become_video">Become a member to watch the full video</div>
      <div class="dialog-footer">
       <!--  <el-button class="footer-btn" @click="handleClose">Cancel </el-button> -->
        <el-button type="primary" class="footer-btn" @click="jumpPage">Upgrade </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'UpgradeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  uodated(oldValue, newValue) {
    console.log({ oldValue, newValue })
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },

    jumpPage() {
      this.$router.push('/Upgrade')
    }
  }
}
</script>
<style lang="scss" scoped>
.member-tips {
  ::v-deep .tips-dialog {
    &.el-dialog__wrapper {
      z-index: 100010 !important;
    }
    .el-dialog {
      background: none;
      .el-dialog__header {
        display: none;
      }

      .tips-wrap {
        padding: 48px 40px;
        font-size: 20px;
        text-align: center;
      }

      .dialog-footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }

      .footer-btn {
        width: 200px;
        height: 42px;
        border-radius: 30px;
        font-size: 16px;
      }

      .members_video {
        font-size: 40px;
        color: #fff;
        font-weight: 500;
        display: flex;
        justify-content: center;
      }

      .become_video {
        font-size: 28px;
        color: #fff;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}
</style>
