<template>
  <div class="particulars offlineOrder" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="ContentBigContent"> <!-- 填写信息的大盒子 -->
        <div class="HeaderTop">
          <div class="BaseInfoWord">{{ $t('OfflineOrder.BaseInfo') }}</div>
          <div class="SaveBtn">
            <button class="comBtn btn-bg0"  @click="SaveBtn">{{ $t('OfflineOrder.Save') }}</button>
          </div>
        </div>
        <div class="line"></div>
        <div>
          <div class="infobox" @click.stop="stopClick"><!-- 订单用户信息form -->
            <el-form>
              <div class="FormContentBox">
                <div class="optionsLeft">
                  <el-form-item class="option" prop="CountryNameEn">
                    <div class="StoreBox">
                      <p class="StoreName">
                        <span>{{ $t('OfflineOrder.Store') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="inputbox">
                        <el-select
                          v-model="SaveMassage.StoreName"
                          class="countrys"
                          :placeholder="$t('OfflineOrder.PleaseSelect')">
                          <el-option
                            v-for="item in StoreList"
                            :key="item.StoreId"
                            :label="item.StoreName"
                            :value="item.StoreName"></el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="ZipCodeBox">
                      <p class="ZipCodeName">
                        <span>{{ $t('OfflineOrder.ContactName') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.ContactName"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="CountryNameEn">
                    <div class="StoreBox">
                      <p class="CountryName">
                        <span>{{ $t('OfflineOrder.Country') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="inputbox">
                        <el-select
                          class="countrys"
                          v-model="SaveMassage.Country"
                          filterable
                          :placeholder="$t('OfflineOrder.PleaseSelect')">
                          <el-option
                            v-for="item in countrys"
                            :key="item.index"
                            :label="item.EnglishName"
                            :value="item.DoubleCharacterCode"></el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="CityName">
                        <span>{{ $t('OfflineOrder.City') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.City"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="CityName">
                        <span>{{ $t('OfflineOrder.Email') }}</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.Email"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="TaxnumberBox">
                      <p class="TaxnumbeName">
                        <span>{{ $t('OfflineOrder.TaxNumber') }}</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.TaxNumber"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <div class="optionsRight">
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="OrderNoName">
                        <span>{{ $t('OfflineOrder.OrderNo') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.OrderNo"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="PhoneName">
                        <span>{{ $t('OfflineOrder.Phone') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="PhoneInput">
                        <el-input v-model="SaveMassage.Phone"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="StatesName">
                        <span>{{ $t('OfflineOrder.States') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="StatesInput">
                        <el-input v-model="SaveMassage.States"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="AddressName">
                        <span>{{ $t('OfflineOrder.Address') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.Address"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="option" prop="ZipCode">
                    <div class="CityBox">
                      <p class="ZIPName">
                        <span>{{ $t('OfflineOrder.PostCode') }}</span>
                        <span style="color:red;">*</span>
                      </p>
                      <div class="ZipCodeInput">
                        <el-input v-model="SaveMassage.PostCode"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div class="AddTableBox"> <!-- 添加信息表格 -->
        <div class="RequiredOne">*</div>
        <div class="RequiredTwo">*</div>
        <div class="RequiredThree">*</div>
        <div class="RequiredFour">*</div>
        <div class="AddTable">
          <div class="HeaderTop">
            <div class="BaseInfoWord">{{ $t('OfflineOrder.ProductInfo') }}</div>
          </div>
          <div class="line"></div>
          <div class="AddMessageBtn">
            <el-button
              icon="el-icon-plus"
              @click="AddDataBtn"
              type="primary">
              {{ $t('OfflineOrder.AddNewInfo') }}
            </el-button>
          </div>
          <div class="MessageContent">
            <el-table
              ref="singleTable"
              :data="tableData"
              highlight-current-row
              @current-change="handleCurrentChange"
              style="width: 98%;border-radius: 10px;margin-left: 10px;">
              <el-table-column
                property="date"
                :label="$t('OfflineOrder.ProductName')"
                width="260"
                style="margin-left: 15px;">
                <template slot-scope="scope">
                  <el-input clearable v-model="scope.row.ProductName"></el-input>
                </template>
              </el-table-column>
              <el-table-column property="name" :label="$t('OfflineOrder.ProductVariants')" width="290">
                <template slot-scope="scope">
                  <el-input clearable v-model="scope.row.Variants"></el-input>
                </template>
              </el-table-column>
              <el-table-column property="address" :label="$t('OfflineOrder.Quantity')" width="260">
                <template slot-scope="scope">
                  <el-input clearable v-model="scope.row.Quantity"></el-input>
                </template>
              </el-table-column>
              <el-table-column property="address" :label="$t('OfflineOrder.ProductLink')" width="260">
                <template slot-scope="scope">
                  <el-input clearable v-model="scope.row.ProductLink"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="" width="210">
                <template slot-scope="scope">
                  <el-button style="font-family: Regular; -webkit-font-smoothing: antialiased;font-size:16px;"
                    @click="handleDelete(scope.row,scope.index)" type="text" size="small" class="col-col5">{{ $t('OfflineOrder.remove') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      page: "orders", //导航栏选中
      banner: "",
      itemname: "",
      tableData: [{}], //添加内容
      currentRow: null,
      SaveMassage: {},
      StoreList: [], //店铺列表
      countrys: [], // 国家列表
      selectStore: "", //已选店铺
      selectCountry: "",
      dispatch: null
    };
  },
  components: {
  },
  created(){
    this.dispatch = this.$store.dispatch;
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  mounted() {
    this.banner = this.$t("store.bannername");
    this.itemname = this.$t("store.itemname");
    this.getStoreList();
    this.getCountrys();
  },
  methods: {
    stopClick() { // 阻止冒泡
      return;
    },
    getStoreList() {
      this.dispatch('store/GetListSt', {
          storeType: this.Offline,
          quoteStoreString: this.selectStore
        })
        .then(res => {
          if (res.Success) {
            this.StoreList = res.Result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCountrys() { // 获取高级搜索国家
      this.dispatch('order/GetCountryListOd')
        .then(res => {
          this.countrys = res.Result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    SaveBtn() {
      this.dispatch('order/AddOfflineOrderOd', {
          StoreName: this.SaveMassage.StoreName,
          OrderNo: this.SaveMassage.OrderNo,
          ContactName: this.SaveMassage.ContactName,
          Country: this.SaveMassage.Country,
          States: this.SaveMassage.States,
          City: this.SaveMassage.City,
          Address: this.SaveMassage.Address,
          Phone: this.SaveMassage.Phone,
          Email: this.SaveMassage.Email,
          PostCode: this.SaveMassage.PostCode,
          TaxNumber: this.SaveMassage.TaxNumber,
          Detail:  this.tableData
        })
        .then(res => {
          if (res.Success) {
            setTimeout(() => {
              window.location.href = `${window.location.origin}/Orders`
            },100)
          } else {
            this.$message.error(res.ErrMsg);
          }
          this.getStoreList();
        })
        .catch(err => {
          console.log(err);
        });
    },
    AddDataBtn() {
      let addinfo = {
        ProductName: "",
        Variants: "",
        Quantity: "",
        ProductLink: ""
      }
      this.tableData.push(addinfo)
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleDelete(index) {
      this.tableData.splice(index, 1);
    }
  }
};
</script>
<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  font-family: "Regular";
}
.activeName {
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  padding-bottom: 20px;
  font-family: "Regular";
}
.ContentBigContent {
  width: 1320px;
  height: 550px;
  background: #fff;
  border-radius: 10px;
  margin-top: 0px;
  position: relative;
}
.HeaderTop {
  position: relative;
  height: 80px;
}
.BaseInfoWord {
  position: absolute;
  top: 43px;
  left: 30px;
  font-size: 20px;
}
.SaveBtn {
  position: absolute;
  top: 30px;
  right: 30px;
}
::v-deep .SaveBtn button {
  width: 102px;
  border-radius: 25px;
  color: #333333;
  background-color: #F9FAFB;
  border: 1px solid #DBDBDB;
}
::v-deep .SaveBtn button:hover{
  background: #3C3772 !important;
  color: #fff;
}
.line {
  width: 1290px;
  height: 1px;
  background: #999999;
  margin-left: 15px;
}
.FormContentBox {
  display: flex;
}
.optionsLeft {
  width: 500px;
  height: 460px;
  margin-left: 80px;
}
.optionsRight {
  width: 600px;
  height: 460px;
}
.StoreBox {
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
}
.ZipCodeBox {
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 30px;
}
.CityBox {
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 30px;
}
.TaxnumberBox {
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 30px;
}
.ZipCodeName {
  margin-top: 0px;
  margin-left: 10px;
}
.StoreName {
  margin-left: 76px;
}
.CountryName {
  margin-left: 55px;
}
.CityName {
  margin-left: 80px;
}
.PhoneName {
  margin-left: 130px;
}
.TaxnumbeName {
  margin-left: 40px;
}
.OrderNoName {
  margin-left: 115px;
}
.ProvinceName {
  margin-left: 85px;
}
.StatesName {
  margin-left: 135px;
}
.AddressName {
  margin-left: 125px;
}
.ZIPName {
  margin-left: 115px;
}
.countrys {
  margin-left: 25px;
}
::v-deep .inputbox .el-input__inner {
  border-radius: 25px;
  width: 270px;
}
::v-deep .ZipCodeInput .el-input__inner {
  margin-left: 32px;
  width: 270px;
}
::v-deep .PhoneInput .el-input__inner {
  margin-left: 35px;
  width: 270px;
}
::v-deep .ProvinceInput .el-input__inner {
  margin-left: 35px;
  width: 270px;
}
::v-deep .StatesInput .el-input__inner {
  margin-left: 35px;
  width: 270px;
}
::v-deep .el-form-item {
  margin: 0;
}
.AddTableBox {
  width: 1320px;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
}
.AddTable {
  margin-top: 30px;
  padding-bottom: 10px;
}
::v-deep .AddMessageBtn .el-button{
  margin-top: 15px;
  margin-left: 15px;
  background: #15104B;
  border: #15104B;
  font-size: 16px;
  border-radius: 50px;
}
::v-deep .MessageContent .el-table th>.cell {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.RequiredOne {
  position: absolute;
  top: 150px;
  left: 132px;
  color: red;
  font-size: 18px;
  z-index: 9;
}
.RequiredTwo {
  position: absolute;
  top: 150px;
  left: 410px;
  color: red;
  font-size: 18px;
  z-index: 9;
}
.RequiredThree {
  position: absolute;
  top: 150px;
  left: 640px;
  color: red;
  font-size: 18px;
  z-index: 9;
}
.RequiredFour {
  position: absolute;
  top: 150px;
  right: 390px;
  color: red;
  font-size: 18px;
  z-index: 9;
}
::v-deep .el-input__inner {
  border-radius: 25px!important;
}
</style>
