<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box">
      <div class="activeName">
        <div class="Video_Left_Box">
          <div class="video_left">
            <div class="box">
              <div class="VideoYoutuBe">
                <iframe width="100%" height="100%"
                  :src="VideoPath"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
            </div>
            <div class="Intro">
              <p>{{Title}}</p>
              <p>{{ $t("video.ByUSAdrop") }}</p>
            </div>
          </div>
          <div class="play_video">
            <div class="video_right_box" v-for="item in options" :key="item.index">
              <div class="box_right">
                <div class="video_play" @mouseenter="enter"
                  @mouseleave="leave">
                  <img @click="VideoBtn(item.VideoPath,item.Title,item.PubTime)" :src="item.VideoCover" alt="">
                  <div class="VideoBtn" v-show="IsShowVideo" @click="VideoBtn(item.VideoPath,item.Title,item.PubTime)">
                    <i class="el-icon-caret-right"></i>
                  </div>
                </div>
              </div>
              <div class="synopsis">
                <p style="width: 100px;">
                  {{ item.Title }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="Video_Right_Box">
          <div class="video_right"></div>
          <div class="VideoMemberBtn">
            <p>
              <button  class="orders btn-bg0" @click="VoucherBtn">{{ $t("video.package") }}</button>
            </p>
            <p>
              <button class="orders2 btn-bg0" @click="MoreVideoBtn">{{ $t("video.More") }}</button>
            </p>
          </div>
          <div class="VideoSizeBtn comBtn " @click="ShowVideoSizeBtn">
            <p>{{ $t("video.VideoSize") }}</p>
          </div>
        </div>
        <div class="paybox" v-if="ShowVideoSize">
          <div class="VideoSizeContentBox">
            <p class="VideoSizeIconRemove" @click="CloseVideoSize">
              <i class="el-icon-remove"></i>
            </p>
            <img src="../../assets/imgs/LoginBg/VideoSize.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "video",
      activeName: "first",
      currentPage: 1,
      options: [],
      VideoList: [],
      VideoPath: "",
      Title: "",
      PubTime: "",
      IsShowVideo: false,
      ShowVideoSize: false // 视频尺寸
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData();
  },
  methods: {
    ShowVideoSizeBtn() {
      this.ShowVideoSize= true;
    },
    CloseVideoSize() {
      this.ShowVideoSize= false;
    },
    enter: function() {
      this.IsShowVideo = true;
    },
    leave: function() {
      this.IsShowVideo = false;
    },
    VoucherBtn(){
      this.$router.push("/Voucher");
    },
    MoreVideoBtn(){
      this.$router.push("/AllVideos");
    },
    getData() {
      let that = this;
      let type = Number(that.activeName);
      that.dispatch('video/GetRecommendListVi')
        .then((res) => {
          that.options = res.Result;
          this.VideoPath = that.options[0].VideoPath;
          this.Title = that.options[0].Title;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    VideoBtn(VideoPath,Title,PubTime) {
      this.VideoPath = VideoPath;
      this.Title = Title;
      this.PubTime = PubTime;
    }
  }
};
</script>
<style>
::v-deep .el-empty {
  padding-top: 10px !important;
}
</style>
<style scoped>
/* 视频弹窗 */
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.VideoSizeContentBox {
  position: absolute;
  width: 500px;
  height: 350px;
  background: #fff;
  top: 150px;
  left: 300px;
  border-radius: 10px;
}
.VideoSizeContentBox img{
  width: 600px;
  height: 350px;
  border-radius: 8px;
}
.VideoSizeIconRemove {
  position: absolute;
  top: 10px;
  right: -80px;
}
.VideoSizeIconRemove >>> .el-icon-remove {
  font-size: 20px;
}
.VideoSizeBtn {
  position: absolute;
  bottom: 180px;
  right: 130px;
  width: 290px;
  height: 40px;
  background: #BC0C34;
  border-radius: 50px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  color: #ffffff;
}
.VideoSizeBtn p{
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}
.VideoSizeBtn:hover {
  background: #fc4343;
}
.VideoSizeBtn i {
  font-size: 26px;
  color: #fff;
}
.VideoSizeBtn >>> .el-icon-caret-right {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  left: 130px;
  font-size: 26px;
}
.play_video {
  width: 820px;
  height: 230px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}
.VideoBtn {
  margin-left: 65px;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  bottom: 120px;
  position: absolute;
  background:#000;
  opacity: 0.8;

}
.VideoBtn:hover{
  background:red;
}
.VideoBtn i {
  font-size: 26px;
  color: #fff;
}
.VideoBtn .el-icon-caret-right:before {
  font-size: 26px;
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  left: 15px;
  font-size: 30px;
}
.Intro p:nth-child(1){
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-size: 18px;
  color: #999999;
  font-weight: 600;
}
.Intro p:nth-child(2){
  width: 100px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  margin-left: 750px;
  color: red;
}
.video_play {
  width: 190px;
  height: 190px;
  margin-left: 15px;
}
.video_play img{
  width: 180px;
  height: 180px;
  transition: all 0.5s;
}
.video_play img:hover{
  transform: scale(1.2);
}
.video_right {
  background-color: #15104B;
  background-image: url('../../assets/imgs/UpgradeToPro/Group2041_procVideo.jpg');
  background-repeat: no-repeat;
  height: 450px;
  width: 300px;
  position: absolute;
  top: 0px;
  right: 130px;
  border-radius: 10px;
  background-size: 100% 100%;
}
.VideoPic {
  position: absolute;
  top: 75px;
  left: -20px;
}
.synopsis {
  width:  125px;
  height: 20px;
  margin-top: 8px;
  margin-left: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.VideoPic img {
  width: 290px;
  height: 240px;
}
.video_left {
  width: 830px;
  height: 520px;
  float: left;
}
.particulars {
  width: 100%;
  min-height: 100vh;
  min-width: 1320px;
  padding-bottom: 32px;
  position: relative;
  background: #eff1f3;
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
}
::v-deep .el-tabs__nav-scroll {
  width: 1320px;
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
.VideoYoutuBe >>> .el-empty {
  padding-top: 100px !important;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: rgb(255 255 255 / 50%);
}
/* 按钮 */
.activeName .orders {
  position: absolute;
  bottom: 240px;
  right: 128px;
  width: 130px;
  height: 40px;
   background: #474882;
  border-radius: 50px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.activeName .orders2 {
  position: absolute;
  bottom: 240px;
  right: 295px;
  width: 130px;
  height: 40px;
  background: #474882;
  border-radius: 50px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.activeName .orders2:hover{
  background: #3C3772 !important;
  color: #fff;
}
.activeName .orders:hover{
  background: #3C3772 !important;
  color: #fff;
}
.VideoSizeBox {
  position: absolute;
  bottom: 180px;
  right: 130px;
  width: 290px;
  height: 40px;
  background: #474882;
  border-radius: 10px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}
.VideoSizeBox p {
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}
.VideoMemberBtn p{
  padding-right: 90px;
}
.box {
  width: 830px;
  height: 450px;
  background: #ccc;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.VideoYoutuBe {
  width: 830px;
  height: 450px;
}
</style>
