<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('myPackage.package')" name="first">
          <template v-if="Voucher && Voucher.length > 0">
            <el-table :data="Voucher" style="width: 100%">
              <el-table-column prop="EndDate" align="center" :label="$t('Voucher.date')">
              </el-table-column>
              <el-table-column
                prop="CouponAmount"
                align="center"
                :label="$t('Voucher.CouponAmout')">
              </el-table-column>
              <el-table-column
                prop="DaysRemaining"
                align="center"
                :label="$t('Voucher.DaysRemaining')">
              </el-table-column>
              <el-table-column
                prop="IsValid"
                align="center"
                :label="$t('Voucher.Coupon')"
                :formatter="formatBoolean"
                :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
          </template>
          <el-empty description="No data" v-else></el-empty>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="pagebox">
      <PagePagination
      :total="options.total"
      :page-size="options.pageSize"
      :current-page="options.page"
      @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import PagePagination from '@/components/PagePagination.vue';
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "myPackage",
      activeName: "first",
      currentPage: 1,
      value: "",
      Voucher: [],
      options: 
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },

    };
  },
  components: {
    PagePagination
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatBoolean: function (row, index) {
      var ret = "";
      if (row.IsValid == true) {
        ret = "Active";
      } else {
        ret = "Expired";
      }
      return ret;
    },
    pageChange(page) { //分页器页数改变
      this.options.page = page;
      this.getData();
    },
    getPageList() {  //  分页器跳转页数
      this.options.page = this.options.jumpPage > 0 ? Number(this.options.jumpPage) : 1;
      this.getData();
    },
    getData() {
      let that = this;
      that.dispatch('card/GetCouponListCd', {
          Filter: {
            MemberId: 0,
          },
          CurrentPageIndex: that.options.page,
          PageSize: that.options.pageSize,
          RecordCount: that.options.list.length,
        })
        .then((res) => {
          that.Voucher = res.Items;
          this.options.total = res.TotalItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #3F6AFF;
  font-family: "Regular";
  font-weight: 500;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #3F6AFF;
  width: 50%!important;
  left: 25%;
  border-radius: 10px;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  background: #eff1f3;
}
::v-deep .el-table th,
.el-table tr {
  height: 64px;
  font-weight: 500;
}
::v-deep .el-table__row {
  height: 86px !important;
}
::v-deep .el-table thead {  /* 头部颜色 */
  font-size: 14px;
  font-family: "Regular";
  font-weight: 500;
  color: #000000;
  color: rgb(0, 0, 0, 0.8);
}
::v-deep .el-table .cell { /* 列表字体颜色 */
  font-size: 14px;
  font-family: "Regular";
  font-weight: 400;
  color: rgb(0, 0, 0, 0.8);
}
::v-deep .element-style {
  font-size: 14px;
  font-family: "Regular";
}
/* 分页 */
::v-deep .el-pager li.active {
  font-family: "Regular";
}
.pagebox {
  width: 1320px;
  padding-top:40px
}
/* 按钮 */
.activeName .orders {
  position: absolute;
  top: 20px;
  right: 32px;
  width: 178px;
  height: 40px;
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 16px;
  font-family: "Regular";
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.btn {
  width: 135px;
  height: 38px;
  background: rgb(191, 204, 249, 0.15);
  border-radius: 10px;
  font-size: 14px;
  font-family: "Regular";
  font-weight: 400;
  color: #3F6AFF;
  border: 0;
}
</style>
