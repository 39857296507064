<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<template>
  <div class="particulars">
    <!-- 详情页面 -->
    <!-- 内容 -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      page: 'podDetailMain'
    }
  },
  components: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.particulars {
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.particulars.dark {
  background: #1f1e2e !important;
}
</style>
