var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "particulars souring-url" },
    [
      _c(
        "div",
        { staticClass: "active-name" },
        [
          _c("div", { staticClass: "sourcing" }, [
            _vm._v(_vm._s(_vm.$t("SourcingUrl.aliexpress"))),
          ]),
          _vm.Fulfilled
            ? _c(
                "div",
                { staticClass: "speed-progress" },
                [
                  _c("div", { staticClass: "progress-fulfilled" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("SourcingUrl.ProductsSourced"))),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.MembershipInterests.UsedTimes)),
                    ]),
                    _c("span", [_vm._v("/")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.MembershipInterests.TotalTimes)),
                    ]),
                  ]),
                  _c("el-progress", {
                    staticStyle: { width: "130px", "margin-top": "8px" },
                    attrs: { percentage: _vm.pmgressbar, "show-text": false },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "monthly-red",
                      on: {
                        click: function ($event) {
                          return _vm.monthlyRed()
                        },
                      },
                    },
                    [
                      _vm.paymentList.GradeId === 1
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/imgs/NewLogo/MemberJICHUSlice.png"),
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.paymentList.GradeId === 2
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/imgs/NewLogo/MemberVIPSlice16.png"),
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.paymentList.GradeId === 3
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/imgs/NewLogo/MemberSVIPSlice16.png"),
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm.MembershipInterests.DayLimit &&
                  _vm.MembershipInterests.DayRemainTimes === 0
                    ? _c("div", { staticClass: "icon-question" }, [
                        _c("i", {
                          staticClass: "el-icon-question",
                          on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
                        }),
                      ])
                    : _vm._e(),
                  _vm.displayUSAdropInfo
                    ? _c("div", { staticClass: "display" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("SourcingUrl.HaveToday"))),
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("SourcingUrl.SalesmanAgent"))),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "progress" }, [
            _c("div", { staticClass: "progress-item" }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365064_Frame%20427319476%402x.png",
                  alt: "",
                },
              }),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("SourcingUrl.EnterPurchasingInfomation"))),
              ]),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "progress-item" }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365159_Frame%20427319477%402x.png",
                  alt: "",
                },
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("SourcingUrl.USAdropQuotation")))]),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "progress-item" }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716445365202_Frame%20427319478%402x.png",
                  alt: "",
                },
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("SourcingUrl.ViewQuote")))]),
            ]),
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("div", { staticClass: "form-item-title" }, [
              _c("span", { staticClass: "required" }, [_vm._v("*")]),
              _c("span", [_vm._v(_vm._s(_vm.$t("SourcingUrl.productLinks")))]),
            ]),
            _c(
              "div",
              { staticClass: "form-item-content" },
              [
                _c("el-input", {
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("SourcingUrl.product"),
                    maxlength: "1000",
                  },
                  on: { input: _vm.setSearchUrl },
                  model: {
                    value: _vm.searchUrl,
                    callback: function ($$v) {
                      _vm.searchUrl = $$v
                    },
                    expression: "searchUrl",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("div", { staticClass: "form-item-title" }, [
              _c("span", { staticClass: "required" }, [_vm._v("*")]),
              _c("span", [_vm._v(_vm._s(_vm.$t("SourcingUrl.productImage")))]),
              _c("span", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("SourcingUrl.productImageDesc"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-item-content" },
              [
                _c("SourcingURLImage", {
                  on: {
                    updateProductImage: _vm.updateProductImage,
                    showProductNameCard: _vm.showProductNameCard,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showProductName,
                    expression: "showProductName",
                  },
                ],
                staticClass: "product-name-card",
              },
              [
                _c("div", { staticClass: "form-item" }, [
                  _c("div", { staticClass: "form-item-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("SourcingUrl.productName"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-item-content" },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("SourcingUrl.placeholderName"),
                          maxlength: "500",
                        },
                        model: {
                          value: _vm.productTitle,
                          callback: function ($$v) {
                            _vm.productTitle = $$v
                          },
                          expression: "productTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-item" }, [
                  _c("div", { staticClass: "form-item-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("SourcingUrl.describe"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-item-content" },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 3, maxlength: "500" },
                        model: {
                          value: _vm.describe,
                          callback: function ($$v) {
                            _vm.describe = $$v
                          },
                          expression: "describe",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "dialog_footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "save",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.save.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("SourcingUrl.Confirm")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("memberPop", { attrs: { visible: _vm.memberPopupVisible } }),
      _c("agentPop", {
        attrs: { visible: _vm.agentVisible },
        on: {
          "update:visible": function ($event) {
            _vm.agentVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "progress-line" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716446219509_Frame%20427319475%402x.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "progress-line" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1716446219509_Frame%20427319475%402x.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }