var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "content" }, [
      _vm.type === 1
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("LoginContentLeft"),
              _c(
                "div",
                { staticClass: "RegisterRight" },
                [
                  _c("div", { staticClass: "register" }, [
                    _c("div", { staticClass: "LogoBig" }, [
                      _c("img", {
                        attrs: { src: _vm.logoImgOne[_vm.formLink], alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "account" }, [
                      _vm._v(_vm._s(_vm.$t("register.header"))),
                    ]),
                    _c("div", { staticClass: "account1" }, [
                      _vm._v(_vm._s(_vm.$t("register.account1"))),
                    ]),
                    _vm._m(0),
                  ]),
                  _c("email-register"),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "register-message" }, [
      _c("p", { staticClass: "StartStepMessageTwo" }, [
        _vm._v(" with "),
        _c("span", { staticClass: "col-col5" }, [_vm._v("60k+")]),
        _vm._v(" dropshippers! "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }