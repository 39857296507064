<template>
  <div class="container">
    <div class="headerBg_Img">
      <img src="../../assets/imgs/NewLogo/LogoOne.png" alt="" />
    </div>
    <div class="loading_text">
      <p>
        <img src="../../assets/imgs/UpgradeToPro/successful.png" alt="" />
      </p>
    </div>
    <div class="successful_box">
      <p>{{ $t('AirwallexSuccess.YourPayment') }}</p>
    </div>
    <div class="USAdrop_box">
      <p>{{ $t('AirwallexSuccess.ThankYou') }}</p>
    </div>
    <div class="Confirm_btn">
      <button @click="ConfirmBtn">{{ $t('AirwallexSuccess.Confirm') }}</button>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {}
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    // 自动加载进度
    this.menterData()
  },
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          gtag('set', 'user_data', { email: res.Result.Email }) // 抓取用户邮箱
          gtag('event', 'conversion', {
            send_to: 'AW-10836134056/uVNFCKqxsq8ZEKiRia8o',
            value: res.Result.LastShipOrderTotalAmount, // 抓取会员费
            currency: 'USD'
          })
          fbq('track', 'Subscribe', {
            value: res.Result.LastShipOrderTotalAmount, //final_price_without_currency替换动态价值代码
            currency: 'USD'
          })
          /*  console.log(res.Result.LastShipOrderTotalAmount, 'fbfbfbfbfbfbfbfbfb')
          console.log(res.Result.LastShipOrderTotalAmount, 'googlegooglegoogle') */
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ConfirmBtn() {
      window.location.href = `${window.location.origin}/ProfileSub`
    }
  }
}
</script>
<style scoped>
.loading_text {
  text-align: center;
  margin-top: 20px;
}
.loading_text img {
  width: 350px;
  height: 250px;
}
.successful_box {
  text-align: center;
  margin-top: 25px;
}
.successful_box p {
  text-align: center;
  font-size: 26px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.USAdrop_box {
  text-align: center;
}
.USAdrop_box p {
  text-align: center;
  font-size: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
  color: #4d4d4d;
}
.Confirm_btn {
  text-align: center;
}
.Confirm_btn button {
  border: 0;
  margin-top: 50px;
  width: 150px;
  height: 40px;
  line-height: 40px;
  background: #15104b;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}
.Confirm_btn button:hover {
  background: #3c3772;
  color: #fff;
}
.headerBg_Img {
  margin-top: 50px;
  margin-left: 80px;
}
.headerBg_Img img {
  width: 130px;
  height: 130px;
}
</style>
