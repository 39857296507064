<script>
import ImageCropDialog from '@/components/ImageCropDialog/index.vue'
import FormPopup from './FormPopop.vue'
import { OSSUploadType, useJSCore } from 'sm-js-core'

export default {
  name: 'FormItemImage',
  components: {
    ImageCropDialog,
    FormPopup
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => ({})
    },
    imageRealSize: {
      type: Object,
      default: () => ({
        width: 0,
        height: 0
      })
    }
  },
  data() {
    return {
      imageUploadConfig: {},
      imageRealSizeConfig: {
        width: 0,
        height: 0
      },
      showFormPopup: false,
      showUploading: false,
      showCropDialog: false,
      currentCropFile: null,
      currentSelectImage: -1,
      uploadImageUrl: '',
      uploadImageSize: {
        width: 0,
        height: 0
      }
    }
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler(newVal, o) {
        this.imageUploadConfig = newVal
      }
    },
    imageRealSize: {
      immediate: true,
      deep: true,
      handler(newVal, o) {
        this.imageRealSizeConfig = newVal
      }
    }
  },
  computed: {
    printFileList() {
      if (this.config.personInfo?.showPrintImg) {
        return this.config?.personInfo?.printFileImgList || []
      }
      return []
    }
  },
  methods: {
    updateValue(value) {
      console.log(value)
      this.$emit('input', value)
    },
    handleFileChange(uploadFile) {
      this.currentCropFile = uploadFile.raw
      this.showCropDialog = true
      this.$refs.uploadRef?.clearFiles()
    },
    async onCropSuccess(croppedFile) {
      try {
        this.showUploading = true
        const { url, width, height } = await useJSCore().oss.upload(croppedFile, {
          uploadType: OSSUploadType.POD_SHOPIFY,
          calculateWidthHeight: true
        })
        this.uploadImageUrl = url
        this.uploadImageSize = {
          width: width,
          height: height
        }
        this.updateValue({
          url: url,
          ...this.uploadImageSize
        })
        this.currentSelectImage = -1
        this.showUploading = false
      } catch (error) {
        this.showUploading = false
        console.error(error)
      }
    },
    onPrintImageSelect(index) {
      if (this.currentSelectImage === index) {
        this.currentSelectImage = -2
        this.updateValue({})
        return
      }
      this.currentSelectImage = index
      if (index === -1) {
        this.updateValue({
          url: this.uploadImageUrl,
          ...this.uploadImageSize
        })
      } else {
        const printFile = this.printFileList[index]
        this.updateValue({
          url: printFile.url,
          width: printFile.width,
          height: printFile.height
        })
      }
    },
    getRealSize() {
      return `${this.imageRealSize.width} * ${this.imageRealSize.height}`
    }
  }
}
</script>
<template>
  <div style="width: 100%">
    <div class="design-form-header">
      <span v-if="imageUploadConfig.form?.title">{{ imageUploadConfig.form?.title }}</span>
      <el-tooltip
        v-if="imageUploadConfig.form?.toolTip"
        effect="dark"
        :content="imageUploadConfig.form?.toolTip"
        placement="top"
      >
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-button
        style="margin-left: 5px; color: #3f6aff"
        v-if="imageUploadConfig.form?.showPopupTip"
        type="text"
        size="small"
        @click="showFormPopup = true"
        >{{ imageUploadConfig.form?.popTitle }}</el-button
      >
    </div>
    <span class="help-text">Rec.{{ getRealSize() }} , jpg or png</span>
    <div class="image-wrap">
      <el-upload
        v-loading="showUploading"
        v-if="imageUploadConfig?.personInfo?.canUpload"
        action=""
        ref="uploadRef"
        accept="image/*"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleFileChange"
      >
        <div class="upload-container">
          <img src="@/assets/imgs/pod/icon_upload.svg" alt="" />
        </div>
      </el-upload>
      <div
        v-if="uploadImageUrl"
        class="image-preview-container"
        :class="{ selected: currentSelectImage === -1 }"
        @click="onPrintImageSelect(-1)"
      >
        <img :src="uploadImageUrl" alt="" />
        <i class="el-icon-success select-icon"></i>
      </div>
      <div
        v-for="(file, index) in printFileList"
        :key="index"
        class="image-preview-container"
        :class="{ selected: currentSelectImage === index }"
        @click="onPrintImageSelect(index)"
      >
        <el-tooltip
          :disabled="!imageUploadConfig.personInfo?.showPrintImgName"
          effect="dark"
          :content="file.name"
          placement="top"
        >
          <img :src="file.url" alt="" />
        </el-tooltip>
        <i class="el-icon-success select-icon"></i>
      </div>
    </div>
    <span v-if="imageUploadConfig.form?.helpTxt" class="help-text">{{
      imageUploadConfig.form?.helpTxt
    }}</span>

    <ImageCropDialog
      v-model="showCropDialog"
      :file="currentCropFile"
      :output-size="imageRealSizeConfig"
      :aspect-ratio="imageRealSizeConfig.width / imageRealSizeConfig.height"
      @onCropCallback="onCropSuccess"
    />

    <FormPopup
      v-model="showFormPopup"
      :title="imageUploadConfig.form?.popTitle"
      :message="imageUploadConfig.form?.richText"
      :width="imageUploadConfig.form?.popWidth"
    />
  </div>
</template>

<style scoped lang="scss">
.design-form-header {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    margin-right: 8px;
  }
}

.image-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.help-text {
  font-size: 12px;
  color: #666;
}

.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #ccc;

  &:hover {
    border: 1px solid #ccc;
  }

  img {
    width: 38px;
    height: 38px;
  }
}

.image-preview-container {
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 93%;
    height: 93%;
    object-fit: contain;
  }

  &.selected {
    border: 1px solid #333;
  }

  .select-icon {
    display: none;
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 20px;
  }

  &.selected .select-icon {
    display: block;
  }
}
</style>
