var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "particulars" }, [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "title_title" }, [
          _c("h1", [_vm._v("Affiliate World Europe 2024")]),
          _c("div", { staticClass: "insights_title" }, [
            _vm._v("Insights and Opportunities for Direct Sellers"),
          ]),
        ]),
        _c("div", { staticClass: "exhibition_img" }, [
          _c("img", {
            attrs: {
              src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725698073785_meta-img.jpg",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "left_image_right_text" }, [
          _c("div", { staticClass: "left_img_box" }, [
            _c("img", {
              attrs: {
                src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700367204_img8-2.jpg",
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "right_text_title" }, [
            _c("p", [
              _vm._v(
                "As Affiliate World Europe 2024 comes to a close, we're excited to share the highlights from this fantastic event held in Budapest from September 5th to 6th. This year's conference offered a wealth of knowledge and networking opportunities tailored for digital marketers, eCommerce professionals, and direct sellers like you."
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "keyinsights_box" }, [
          _c("div", { staticClass: "key_insights_box" }, [
            _vm._v(" Key Insights from the Speaker Sessions "),
          ]),
          _c("div", { staticClass: "keyinsights_title" }, [
            _c("p", [
              _vm._v(" 1. Meta Ads Track: Advanced Creative Strategies "),
            ]),
            _c("p", [
              _vm._v(
                " Discover how leveraging A/B and multivariate testing can refine your ad creatives for better results. Speakers emphasized the importance of localizing ad techniques to resonate with diverse audiences and enhance engagement. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "google_ads_track" }, [
            _c("div", { staticClass: "google_ads_title" }, [
              _vm._v("2. Google Ads Track: Dynamic Creative Optimization "),
            ]),
            _c("div", { staticClass: "left_image_right_text_two" }, [
              _c("div", { staticClass: "google_ads_img_box" }, [
                _c("div", { staticClass: "google_ads_img" }, [
                  _c("img", {
                    attrs: {
                      src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725690667021_image.png",
                      alt: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "google_ads_words_one" }, [
                  _c("p", [_vm._v("Sasha Vitez")]),
                  _c("p", [_vm._v("Senior Growth Consultant,")]),
                  _c("p", [_vm._v("Google")]),
                ]),
              ]),
              _c("div", { staticClass: "explorepo_tential_title" }, [
                _vm._v(
                  " Explore the potential of Dynamic Creative Optimization (DCO) in display advertising. By utilizing AI-driven automation and optimizing creative elements, you can significantly improve ad performance and conversion rates. The track also delved into effective bidding strategies and data-driven decision-making in Google Ads. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "native_advertising_track" }, [
            _c("div", { staticClass: "native_advertising_title" }, [
              _vm._v(
                "3. Native Advertising Track: Crafting Effective Headlines"
              ),
            ]),
            _c("div", { staticClass: "native_advertising_words" }, [
              _vm._v(
                "Master the art of creating compelling native ad headlines to capture attention and drive clicks. The session covered navigating the native ad bidding and auction systems and analyzing ad performance metrics to maximize ROI. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "google_ads_track_two" }, [
            _c("div", { staticClass: "left_image_right_text_two" }, [
              _c("div", { staticClass: "explorepo_tential_title_two" }, [
                _c("p", [
                  _vm._v(
                    " 4. TikTok Track: Harnessing Influencer Partnerships "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Learn how to collaborate with influencers on TikTok to boost brand visibility and engagement. Tips included crafting high-performing ad copy and leveraging TikTok’s algorithm updates for better targeting and content strategies. "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "google_ads_img_two" }, [
                  _c("img", {
                    attrs: {
                      src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725691812869_image.png",
                      alt: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "google_ads_words" }, [
                  _c("p", [_vm._v("Niv Shaked")]),
                  _c("p", [_vm._v("Head of Indirect Sales, TikTok")]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "key_takeaways_sellers_box" }, [
          _c("div", { staticClass: "key_takeaway_big_title" }, [
            _vm._v("Key Takeaways for Direct Sellers"),
          ]),
          _c("div", { staticClass: "key_takeaway_img" }, [
            _c("img", {
              attrs: {
                src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725692348912_Affiliate-World-Dubai-2024-Panel-1024x683.jpeg",
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "key_takeaways_sellers" }, [
            _c("div", { staticClass: "key_takeaways_title" }, [
              _vm._v("1. Diversify Traffic Sources"),
            ]),
            _c("div", { staticClass: "key_takeaways_words" }, [
              _vm._v(
                " A major theme at this year's Affiliate World was the shift towards diversifying traffic sources. While platforms like Facebook remain crucial, there's a growing emphasis on exploring new channels such as TikTok, native ads, and Google. This diversification is essential for optimizing your marketing strategy and reaching a broader audience. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "key_takeaways_sellers" }, [
            _c("div", { staticClass: "key_takeaways_title" }, [
              _vm._v("2. Harness the Power of AI"),
            ]),
            _c("div", { staticClass: "key_takeaways_words" }, [
              _vm._v(
                " Artificial intelligence and machine learning are transforming ad management. Sessions on AI-driven ad optimization, advanced scaling strategies, and creative testing highlighted how these technologies can enhance your campaigns. AI can streamline content creation and campaign management, leading to more effective and personalized advertising. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "key_takeaways_sellers" }, [
            _c("div", { staticClass: "key_takeaways_title" }, [
              _vm._v("3. Optimize Supply Chains and Product Sourcing"),
            ]),
            _c("div", { staticClass: "key_takeaways_words" }, [
              _vm._v(
                " The direct selling industry is evolving with a clear focus on supply chain optimization and exploring new markets. Streamlining product sourcing and offering innovative payment solutions were emphasized as key growth areas. This aligns perfectly with USAdrop’s commitment to providing comprehensive solutions beyond just fulfillment. "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "success_stories_box" }, [
          _c("div", { staticClass: "case_study_box_one" }, [
            _c("div", { staticClass: "case_study_title" }, [
              _vm._v(
                " Case Study 1: Boosting Customer Satisfaction with High-Quality Fulfillment "
              ),
            ]),
            _c("div", { staticClass: "case_study_box" }, [
              _c("div", { staticClass: "toma_img" }, [
                _c("img", {
                  attrs: {
                    src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725699996588_img1.jpg",
                    alt: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "toma_content" }, [
                _vm._v(
                  " A rising fashion direct seller saw a 35% increase in sales in August after integrating USAdrop’s product supply and brand customization. By leveraging our high-quality products and trending styles, they effectively met customer needs and maximized profit margins. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "case_study_box_one" }, [
            _c("div", { staticClass: "case_study_title" }, [
              _vm._v(
                " Case Study 2: Streamlining Global Expansion Fulfillment "
              ),
            ]),
            _c("div", { staticClass: "case_study_box" }, [
              _c("div", { staticClass: "toma_content_two" }, [
                _vm._v(
                  " Another success story comes from a children’s toy retailer who expanded their international market presence. By using USAdrop’s global fulfillment options and local warehouses, they reduced shipping times by 40%, improved customer satisfaction, and significantly increased repeat orders. "
                ),
              ]),
              _c("div", { staticClass: "toma_img_two" }, [
                _c("img", {
                  attrs: {
                    src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700059360_img2-6.jpg",
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "case_study_box_two" }, [
            _c("div", { staticClass: "case_study_img" }, [
              _c("img", {
                attrs: {
                  src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284572_img6.jpg",
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "case_study_content" }, [
              _c("p", [
                _vm._v(" In addition to fulfillment services, we also offer: "),
              ]),
              _c("ul", { staticClass: "service-list" }, [
                _c("li", [_vm._v("Ad agency services")]),
                _c("li", [_vm._v("Streamlined payment solutions")]),
                _c("li", [_vm._v("30% Profit Co-founder")]),
                _c("li", [
                  _vm._v("Local warehouses for faster global delivery"),
                ]),
                _c("li", [_vm._v("......")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "fulfillment_rate_content" }, [
            _vm._v(
              " If you haven’t explored these services yet, now is the perfect time to integrate them into your business and enhance your order fulfillment rates. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "visual_insights_box" }, [
          _c("div", { staticClass: "visual_insights_big_title" }, [
            _vm._v("Visual Insights"),
          ]),
          _c("div", { staticClass: "customer_testimonial_title" }, [
            _vm._v("Customer Testimonial:"),
          ]),
          _c("div", { staticClass: "fulfillment_services" }, [
            _vm._v(
              " “USAdrop’s fulfillment services have transformed our order timing issues. The results speak for themselves, with noticeable improvements in customer feedback and profit margins!” "
            ),
          ]),
          _c("div", { staticClass: "fashion_direct" }, [
            _vm._v(" — [Khaled], Fashion Direct Seller. "),
          ]),
        ]),
        _c("div", { staticClass: "whats_next_box" }, [
          _c("div", { staticClass: "whats_next_content_one" }, [
            _vm._v(
              " Looking ahead, the insights gained from Affiliate World Europe are invaluable. We’re excited to apply these experiences to our products and continue supporting your direct selling success. We look forward to seeing you at the next Affiliate World event! "
            ),
          ]),
          _c("div", { staticClass: "whats_next_content_one" }, [
            _vm._v(
              " Thank you to everyone who joined us and made this event a success. Stay tuned for more updates and tips to help you expand your direct selling business with USAdrop. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "img_box" }, [
          _c("p", [
            _c("img", {
              attrs: {
                src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284738_img3-4.jpg",
                alt: "",
              },
            }),
          ]),
          _c("p", [
            _c("img", {
              attrs: {
                src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700284692_img4-9.jpg",
                alt: "",
              },
            }),
          ]),
          _c("p", [
            _c("img", {
              attrs: {
                src: "https://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1725700435239_74.jpg",
                alt: "",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }