var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", [
        _c("div", { staticClass: "orderTitle" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("SupportTicket.Order")))]),
          _c("span", [_vm._v(_vm._s(_vm.orderDetails.SalesRecord))]),
          _c(
            "button",
            { staticClass: "comBtn btn-bg0", on: { click: _vm.BackBtn } },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Back")) + " ")]
          ),
        ]),
        _c("div", { staticClass: "RecipientInformationBox" }, [
          _c("div", { staticClass: "RecipientInformation" }, [
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("SupportTicket.RecipientInformation")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "TableContentBox" }, [
            _c("div", { staticClass: "left-main" }, [
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.CompanyName1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.Contact)),
                ]),
              ]),
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.Country1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.CountryNameEn)),
                ]),
              ]),
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.States1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.Province)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "left-main" }, [
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.TelePhone1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.TelePhone)),
                ]),
              ]),
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.City1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.City)),
                ]),
              ]),
              _c("div", { staticClass: "left-content" }, [
                _c("div", { staticClass: "The-recipient" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.PostCode1"))),
                ]),
                _c("div", { staticClass: "The-content" }, [
                  _vm._v(_vm._s(_vm.orderDetails.ZipCode)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "AddressMain" }, [
            _c("div", { staticClass: "AddressOneContent" }, [
              _c("div", {}, [_vm._v(_vm._s(_vm.$t("SupportTicket.Address1")))]),
              _c("div", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(_vm._s(_vm.orderDetails.Address1)),
              ]),
            ]),
            _c("div", { staticClass: "AddressTwoContent" }, [
              _c("div", {}, [_vm._v(_vm._s(_vm.$t("SupportTicket.Address2")))]),
              _c("div", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(_vm._s(_vm.orderDetails.Address2)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "ProductDetailBox" },
            [
              _c("div", { staticClass: "ProductDetail" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.ProductDetail"))),
                ]),
                _c("span", [
                  _vm._v("(" + _vm._s(_vm.$t("SupportTicket.Total"))),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.orderDetails.TotalNum))]),
                _c("span", [_vm._v(")")]),
              ]),
              _vm._l(_vm.orderDetails.OrderDetails, function (detail, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "ProductName" }, [
                    _c("div", { staticClass: "SupportTicketProductName" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("SupportTicket.ProductName")) + " "
                      ),
                    ]),
                    _c("div", { staticClass: "detailProductName" }, [
                      _vm._v(_vm._s(detail.ProductName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "ProductSkuBox" }, [
                    _c("div", { staticClass: "ProductSku" }, [
                      _c("div", { staticClass: "ProductSkuName" }, [
                        _vm._v(_vm._s(_vm.$t("SupportTicket.ProductSku"))),
                      ]),
                      _c("div", [_vm._v(_vm._s(detail.ProductRealSku))]),
                    ]),
                    _c("div", { staticClass: "Quantity" }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("SupportTicket.Quantity")) + ":"),
                      ]),
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(detail.Num)),
                      ]),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
          _c("div", { staticClass: "LogisticTransitNo" }, [
            _c("div", { staticClass: "SupportTicketLogisticTransitNoName" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("SupportTicket.LogisticTransitNo")) + " "
              ),
            ]),
            _c("div", { staticClass: "orderDetailsLogisticTransitNo" }, [
              _vm._v(_vm._s(_vm.orderDetails.LogisticTransitNo)),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }