var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "header" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("SupportTicket.HistoricalFeedbackRecord")) + " "
          ),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticClass: "table-class",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "orderNo",
                label: "Order[Customer Name]",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "white-space": "pre-line",
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(scope.row.OrderNo) + " "),
                          _c("br"),
                          _vm._v(" [" + _vm._s(scope.row.CustomerName) + "] "),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _vm._v(" Order "),
                  _c("br"),
                  _vm._v(" [Customer Name] "),
                ]),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              prop: "questionType",
              label: _vm.$t("SupportTicket.QuestionType"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { "white-space": "pre-line" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.SupportTicketDict.afterProblemType.find(
                              (item) =>
                                item.value === Number(scope.row.ProblemTypeCode)
                            ).label || "Other"
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "replyRecords",
              label: _vm.$t("SupportTicket.ReplyRecords"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.Megs, function (item, index) {
                    return _c("div", { key: index }, [
                      item.IsSmReply == true
                        ? _c("div", [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "8px" } },
                              [_vm._v(_vm._s(item.Message))]
                            ),
                            _c("div", [_vm._v(_vm._s(item.CreateTime))]),
                            _c("div", { staticClass: "line" }),
                          ])
                        : _vm._e(),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "processRecords",
              label: _vm.$t("SupportTicket.ProcessRecords"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.Megs, function (item, index) {
                    return _c("div", { key: index }, [
                      item.IsSmReply == false
                        ? _c("div", [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "8px" } },
                              [_vm._v(_vm._s(item.Message))]
                            ),
                            _c("div", [_vm._v(_vm._s(item.CreateTime))]),
                            _c("div", { staticClass: "line" }),
                          ])
                        : _vm._e(),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "Processing",
              width: "140",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#3f6aff" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewDetails(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("SupportTicket.Viewdetails")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#3f6aff" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewOrderDetails(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("SupportTicket.ViewOrderDetails")) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }