<template>
  <!-- 充值 -->
  <div id="paypal-button-container"></div>
</template>
<script>
import { loadScript } from "@paypal/paypal-js";

export default {
  data: () => {
    return {
      dispatch:null
    };
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  props: ["money", "totalmoney"],
  mounted() {
    let that = this;
    this.dispatch = that.$store.dispatch
    const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID
    loadScript({
      "client-id": clientId,
        //"AYArbHqan4hwap0jUgtRrlWianMYkuZ9XLqEVurGlE1HGhmWJJfA0uAzJbrGReb4nfIXHSpOVfNvm_rZ",//live
        //"ASk6RLjNMKmPI-QWqvQNhTG7VRaWdFbgFOMzAWEkEmEwkft_plMauyL5MjRVQ5UmhJfGuWJs-MYhMA8z",//sandbox
        "disable-funding":"bancontact,blik,boleto,card,credit,eps,giropay,ideal,itau,maxima,mercadopago,mybank,oxxo,p24,paylater,payu,sepa,sofort,trustly,venmo,wechatpay,zimpler",
    })
      .then((paypal) => {
        paypal
          .Buttons({
            createOrder: function (data, actions) {
             return  that.dispatch('wallet/CreatePaypalOrderWa', {RechargeAmount: Number(that.totalmoney)
              }).then((_data) => {
                  return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: that.money,
                      },
                      custom_id: _data.Result.RechargeOrderId,
                      invoice_id: _data.Result.RechargeNo
                    },
                  ],
                });
              })
            },
            onApprove: function (data, actions) {
              that.dispatch('wallet/CapturePaypalOrderWa', {OrderId: data.orderID})
                .then((res) => {
                  if (res.Success) {
                    that.$message.success('payment success')
                  } else {
                    that.$message.error(res.ErrMsg)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            },
          })
          .render("#paypal-button-container");
      })
      .catch((err) => {
        console.error("failed to load the PayPal JS SDK script", err);
      });
  },
  methods: {},
};
</script>
<style scoped>
#paypal-button-container {
  text-align: center;
  padding-top: 80px;
}
</style>
