var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scroll", class: { show: _vm.isActive } }, [
    _c("div", { staticClass: "particulars" }, [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "searchbox" }, [
          _c("div", { staticClass: "search_input" }, [
            _c("div", { staticClass: "search_icon el-icon-search" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.salesRecord,
                  expression: "salesRecord",
                },
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("SupportTicket.FilterOrders"),
              },
              domProps: { value: _vm.salesRecord },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.salesRecord = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "topbox" }, [
            _c(
              "div",
              {
                staticClass: "comBtn btn-bg0",
                on: { click: _vm.SearchOrderNo },
              },
              [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Search")) + " ")]
            ),
            _c(
              "div",
              { staticClass: "comBtn btn-bg0", on: { click: _vm.toggleMore } },
              [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Filter")) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "comBtn btn-bg0",
                on: { click: _vm.OpenNewTicketBtn },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("SupportTicket.OpenNewTicket")) + " "
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.isMore,
                  "custom-class": "mydialog",
                  center: true,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isMore = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SupportTicket.StoreName") } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "myselect",
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.selectStore,
                              callback: function ($$v) {
                                _vm.selectStore = $$v
                              },
                              expression: "selectStore",
                            },
                          },
                          _vm._l(_vm.stores, function (item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: {
                                label: item.StoreName,
                                value: item.UserId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SupportTicket.OrderDate") } },
                      [
                        _c("el-date-picker", {
                          staticClass: "myselect1",
                          attrs: {
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": _vm.$t("orders.to"),
                          },
                          on: { change: _vm.timeChange },
                          model: {
                            value: _vm.selectTime,
                            callback: function ($$v) {
                              _vm.selectTime = $$v
                            },
                            expression: "selectTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SupportTicket.Order") } },
                      [
                        _c(
                          "div",
                          { staticClass: "Contact_input" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "SupportTicket.FilterOrders"
                                ),
                              },
                              model: {
                                value: _vm.salesRecord,
                                callback: function ($$v) {
                                  _vm.salesRecord = $$v
                                },
                                expression: "salesRecord",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "comBtn btn-bg0 min-wid200",
                        on: { click: _vm.moreSearch },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("SupportTicket.Confirm")) + " "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            { staticClass: "typebox" },
            _vm._l(_vm.types, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "type",
                  class: _vm.orderType == index ? "select" : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectType(index)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(`${_vm.$t(item)}`))]),
                  _vm.resultData && _vm.resultData[index]
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(" (" + _vm._s(_vm.resultData[index].Num) + ") "),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "ul",
          { staticClass: "main-list" },
          [
            _c("li", { staticClass: "title" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.StoreName"))),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.Order"))),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("SupportTicket.TypeofQuestion")) + " "
                  ),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("SupportTicket.ProblemDescription")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.ProcessResult"))),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.TrackingNumber"))),
                ]),
                _c("div", { staticClass: "title_li" }, [
                  _vm._v(_vm._s(_vm.$t("SupportTicket.Processing"))),
                ]),
              ]),
            ]),
            _vm.resultDataList && _vm.resultDataList.length > 0
              ? _vm._l(_vm.resultDataList, function (item, index) {
                  return _c("li", { key: index, staticClass: "common" }, [
                    _c("div", { staticClass: "contentMessage" }, [
                      _c("div", { staticClass: "content_li" }, [
                        _vm._v(_vm._s(item.ShopName)),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _c("div", [_vm._v(_vm._s(item.OrderNo))]),
                        _c("div", [_vm._v(_vm._s(item.SalesRecord))]),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _vm._v(_vm._s(item.ProblemTypeDesc)),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _vm._v(_vm._s(item.Description)),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _vm._v(_vm._s(item.ProcessResult)),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _vm._v(_vm._s(item.LastestTrackingNumber)),
                      ]),
                      _c("div", { staticClass: "content_li" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ViewdetailsContent",
                            on: {
                              click: function ($event) {
                                return _vm.ViewDetailsId(item.CustomerOrderId)
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("SupportTicket.Viewdetails"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ViewOrderDetails",
                            on: {
                              click: function ($event) {
                                return _vm.ViewOrderDetailsBtn(item.OrderId)
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("SupportTicket.ViewOrderDetails"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "ModifyBtn" }, [
                          _vm.orderType === 0
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.ModifyOrderIdBtn(
                                          item.OrderId,
                                          item.CustomerOrderId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("SupportTicket.Modify")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.orderType === 1
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.ProcessOrderIdBtn(
                                          item.CustomerOrderId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("SupportTicket.Process")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                })
              : [_vm._m(0)],
            _c(
              "div",
              { staticClass: "pagebox" },
              [
                _c(
                  "el-pagination",
                  {
                    attrs: {
                      layout: "prev, pager, next,slot",
                      "page-size": _vm.pageSize,
                      "current-page": _vm.quotedList[0].page,
                      "page-sizes": [],
                      total: _vm.quotedList[0].total,
                    },
                    on: {
                      "current-change": _vm.pageChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "pageSizeInput" },
                      [
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.selectChanged },
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.optionsSize, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "page_sign",
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.SubmittedByUdList.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("orders.get")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quotedList[0].jumpPage,
                          expression: "quotedList[0].jumpPage",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.quotedList[0].jumpPage },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.quotedList[0],
                            "jumpPage",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "BackTop",
        attrs: { id: "toTop" },
        on: {
          click: function ($event) {
            return _vm.toTop(_vm.step)
          },
        },
      },
      [_vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "NoDataImg" }, [
      _c("p", [
        _c("img", {
          attrs: { src: require("@/assets/imgs/Nodata.png"), alt: "" },
        }),
      ]),
      _c("p", [_vm._v("No Data")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: { src: require("@/assets/imgs/SideBar/BackTopUp.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }