<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      page: "Upgrade",
      banner: "",
      itemname: ""
    };
  },
  components: {
  },
  mounted() {
    this.banner = this.$t("member.bannername");
    this.itemname = this.$t("member.itemname");
  },
  computed: mapState(["userInfo", "theme"]),
  methods: {},
  watch: {
    $route: function(to) {
      // 跳转支付后确保下拉框可查看详情
      if (to.name == "Payment") {
        this.page = "Payment";
      } else {
        this.page = "member";
      }
    }
  }
};
</script>

<style scoped>

.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 30px;
}
</style>
