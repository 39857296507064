var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "box-wrapp" }, [
        _vm.cardList.length === 0
          ? _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [_c("el-empty")],
              1
            )
          : _c(
              "div",
              { staticClass: "bigbox" },
              _vm._l(_vm.cardList, function (item) {
                return _c("div", { key: item.id, staticClass: "box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "images",
                      class: _vm.blur ? "blur" : "",
                      on: {
                        click: function ($event) {
                          return _vm.goDetail(item)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: item.mainImage, alt: "" } })]
                  ),
                  _c(
                    "div",
                    { staticClass: "simple", class: _vm.blur ? "blur" : "" },
                    [_vm._v(_vm._s(_vm._f("productName")(item)))]
                  ),
                  _c("div", { staticClass: "lists_num" }, [
                    _vm._v("Lists: " + _vm._s(_vm._f("lists")(item))),
                  ]),
                  _c("div", { staticClass: "btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "connect_btn",
                        on: {
                          click: function ($event) {
                            return _vm.connect(item.goodsCode)
                          },
                        },
                      },
                      [_vm._v("Connect")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "list_btn",
                        on: {
                          click: function ($event) {
                            return _vm.addProduct(item.goodsCode)
                          },
                        },
                      },
                      [_vm._v("Import")]
                    ),
                  ]),
                ])
              }),
              0
            ),
      ]),
      _c("UpgradeDialog", {
        attrs: {
          visible: _vm.upgradeVisible,
          title: "Get Pro Plan for more features",
        },
        on: {
          "update:visible": function ($event) {
            _vm.upgradeVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }