<template>
  <div id="app" class="container">
    <!--    <keep-alive>-->
    <!--      <router-view v-if="$route?.meta.keepAlive"/>-->
    <!--    </keep-alive>-->
    <Layout>
      <!--      <router-view ></router-view>-->
    </Layout>

    <!--    <router-view ></router-view>-->
  </div>
</template>
<script>
import Layout from '@/components/Layout/index.vue'

export default {
  components: { Layout },
  created() {},
  methods: {}
}
</script>
<style>
body {
  font-size: 12px;
}
</style>
