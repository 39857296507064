import request from '@/utils/request'

export const GetMemberVo = (data) =>
  request({
    method: 'post',
    url: '/api/Member/GetMemberVo',
    data
  })
export const CheckGuide = (params) =>
  request({
    method: 'get',
    url: '/api/Member/CheckGuide',
    params
  })
export const GetAllGradeLis = (data) =>
  request({
    method: 'post',
    url: '/api/Grade/GetAllGradeLis',
    data
  })
export const GetGradeSettings = (data) =>
  request({
    method: 'post',
    url: '/api/Grade/GetGradeSettings',
    data
  })
export const GetGradePriceList = (params) =>
  request({
    method: 'get',
    url: '/api/MemberShip/GetGradePriceList',
    params
  })
export const CalculateFee = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CalculateFee',
    data
  })
export const GetListByCurrentUser = (data) =>
  request({
    method: 'post',
    url: '/api/LhMemberBankCard/GetListByCurrentUser',
    data
  })
export const AddAsnc = (data) =>
  request({
    method: 'post',
    url: '/api/LhMemberBankCard/AddAsnc',
    data
  })
export const CreateStripeSubscription = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreateStripeSubscription',
    data
  })
export const SubmitOrder = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/SubmitOrder',
    data
  })
export const CreatePaypalSubscriptionPlan = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreatePaypalSubscriptionPlan',
    data
  })
export const LhMemberBankCardDel = (params) =>
  request({
    method: 'get',
    url: '/api/LhMemberBankCard/Delete',
    params
  })

export const DisplayDicountHint = (params) =>
  request({
    method: 'get',
    url: '/api/MemberShip/DisplayDicountHint',
    params
  })
export const GetGradePriceVoPerMonth = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/GetGradePriceVoPerMonth',
    data
  })
export const CreateOrder = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreateOrder',
    data
  })
export const CreateOrderByAirWallex = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreateOrderByAirWallex',
    data
  })
export const CreateOrderByAirWallexSubscription = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreateOrderByAirWallexSubscription',
    data
  })
export const GenerateAirWallexClientSecret = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/GenerateAirWallexClientSecret',
    data
  })
export const CalculateFeeSevenDayTrial = (params) =>
  request({
    method: 'get',
    url: '/api/MemberShip/CalculateFeeSevenDayTrial',
    params
  })
export const CreateOrderByStripe = (data) =>
  request({
    method: 'post',
    url: '/api/MemberShip/CreateOrderByStripe',
    data
  })
export const GetAllGradeList = (params) =>
  request({
    method: 'get',
    url: '/api/Grade/GetAllGradeList',
    params
  })
export const AddUpgradeClick = (data) =>
  request({
    method: 'post',
    url: `/api/BuryingLog/AddUpgradeClick?grade=${data.grade}`,
    data
  })
