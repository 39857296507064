import request from '@/utils/request';

export const GetByClick = (params) => request({// 获取问卷调查
    method: "get",
    url: '/api/LhTaskQuestionnaire/GetByClick',
    params
  });
  export const AddQuestionnaireResults = (data) => request({// 提交问卷调查
    method: "post",
    url: '/api/LhTaskQuestionnaireResults/Add',
    data
  });
  export const GetAlltList = (params) => request({ //获取任务列表
    method: "get",
    url: '/api/LhMemberTask/GetAll',
    params
  });
  export const CompleteTask = (data) => request({//完成内容调查
    method: "post",
    url: '/api/LhMemberTask/CompleteTask',
    data
  });
  export const RunTask = (data) => request({
    method: "post",
    url: '/api/LhMemberTask/RunTask',
    data
  });