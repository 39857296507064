import request from '@/utils/request'
export const ExportOrder = (data) =>
  request({
    method: 'post',
    url: '/api/Order/ExportOrder',
    data
  })

export const GetRightsWithProcessOrder = (params) =>
  request({
    method: 'get',
    url: '/api/MemberRights/GetRightsWithProcessOrder',
    params
  })

export const GetOrderListTwo = (data) =>
  request({
    method: 'post',
    url: '/api/Order/GetOrderList2',
    data
  })
export const GetOrderPaymentsAmount = (data) =>
  request({
    method: 'post',
    url: '/api/Order/GetOrderPaymentsAmount',
    data
  })

export const GetOrderVoByOrderId = (data) =>
  request({
    method: 'post',
    url: '/api/Order/GetOrderVoByOrderId"',
    data
  })
export const ExchangeItemStatus = (data) =>
  request({
    method: 'post',
    url: '/api/Order/ExchangeItemStatus',
    data
  })
export const GetAddress = (params) =>
  request({
    method: 'get',
    url: '/api/Address/GetAddress',
    params
  })

export const GetOrderPaymentsAmountBySearch = (data) =>
  request({
    method: 'post',
    url: '/api/Order/GetOrderPaymentsAmountBySearch',
    data
  })
export const SaveOrderPayments = (data) =>
  request({
    method: 'post',
    url: '/api/Order/SaveOrderPayments',
    data
  })
export const UpdateAddress = (data) =>
  request({
    method: 'post',
    url: '/api/Address/UpdateAddress',
    data
  })
export const GetCountryList = (params) =>
  request({
    method: 'get',
    url: '/api/Common/GetCountryList',
    params
  })
// /api/Order/AddOfflineOrder
export const AddOfflineOrder = (data) =>
  request({
    method: 'post',
    url: '/api/Order/AddOfflineOrder',
    data
  })
