/*
 * @name: 
 * @test: test font
 * @msg: 
 * @param: 
 * @return: 
 */
import {
  authBack,
  authRegister,
  login,
  getUser,
  getMemberVo,
  authorization,
  checkMobileExist,
  getVetifyEmail,
  LhFirstStepRecord,
  register,
  getRegisterCountryList,
  getVetifyMarketingCode
} from '@/api/login';

import {checkGuide} from '@/api/guide';

export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status; 
    }
  },
  actions: {
    getAuthBack({state, commit}, params) {
      return authBack(params).then(res => res)
    },

    getLogin({state, commit}, params) {
      return login(params).then(res => res)
    },

    getUserInfo({state, commit}, params) {
      return getUser(params).then(res => res)
    },

    getCheckGuide({state, commit}, params) {
      return checkGuide(params).then(res => res)
    },

    getPaymentList({state, commit}, params) {
      return getMemberVo(params).then(res => res);
    },

    getAuthorization({state, commit}, params) {
      return authorization(params).then(res => res);
    },
    getAuthRegister({state, commit}, params) {
      return authRegister(params).then(res => res)
    },

    getCheckMobileExist ({state, commit}, params) {
      return checkMobileExist(params).then(res => res)
    },

    verifyEmail({state, commit}, params){
      return getVetifyEmail(params).then(res => res)
    },
    getLhFirstStepRecord({state, commit}, params){
      return LhFirstStepRecord(params).then(res => res)
    },
    getRegister({state, commit}, params){
      return register(params).then(res => res)
    },
    registerCountryList({state, commit}, params){
      return getRegisterCountryList(params).then(res => res)
    },
    registerMarketingCode({state, commit}, params){
      return getVetifyMarketingCode(params).then(res => res)
    },
  },
}
