var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "podDetail" },
    [
      _c("div", { staticClass: "infoContent" }, [
        _c("div", { staticClass: "leftPics" }, [
          _c("img", {
            staticClass: "mainPic",
            attrs: { src: _vm.showMainUrl, alt: "" },
          }),
          _c(
            "div",
            { staticClass: "thumbCard" },
            [
              _c("product-thumbnail-scroll-card-vue", {
                attrs: {
                  productFiles: _vm.imgCarouseList,
                  currentIndex: _vm.currentImgIndex,
                },
                on: { didThumbnailsIndexChange: _vm.getMainPicUrl },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "rightSkuAConfigure" }, [
          _c(
            "div",
            { staticClass: "skuInfo" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.detailInfo?.title)),
              ]),
              _c("div", { staticClass: "price" }, [
                _vm._v(_vm._s("$" + (_vm.currentSkuObj?.channelPrice || 0))),
              ]),
              _vm._l(
                _vm.detailInfo?.goodsDetailSpecList,
                function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "skusContent" },
                    [
                      _c("div", { staticClass: "labelTitle" }, [
                        _c("span", [
                          _vm._v(_vm._s(item?.specName["en"]) + ": "),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.showObj[item?.specName["en"]])),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "skuItems" },
                        [
                          item.specValue[0].fileUrl
                            ? _vm._l(item.specValue, function (_sku, _index) {
                                return _c("img", {
                                  key: _sku.specValueCode,
                                  staticClass: "skuPic",
                                  class:
                                    _vm.showObj[item?.specName["en"] + "Id"] ===
                                    _sku.specValueCode
                                      ? "active"
                                      : "",
                                  attrs: { src: _sku.fileUrl, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.pickSpec(
                                        index,
                                        _index,
                                        _sku,
                                        item?.specName["en"]
                                      )
                                    },
                                  },
                                })
                              })
                            : _vm._l(item.specValue, function (_sku, _index) {
                                return _c(
                                  "span",
                                  {
                                    key: _sku.specValueCode,
                                    staticClass: "skuTxt",
                                    class:
                                      _vm.showObj[
                                        item?.specName["en"] + "Id"
                                      ] === _sku.specValueCode
                                        ? "active"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.pickSpec(
                                          index,
                                          _index,
                                          _sku,
                                          item?.specName["en"]
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_sku["en"]))]
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "podShowConfigure" },
            [
              _c("PodShowConfigure", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeScenesConfig?.options?.length,
                    expression: "activeScenesConfig?.options?.length",
                  },
                ],
                attrs: {
                  "design-config": _vm.configureData,
                  "design-data": _vm.diySkuData,
                  "active-scenes-index": _vm.activeScenesIndex,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.configureData?.scenesConfig?.options.length > 1
        ? _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticClass: "senseSelect",
                  style: {
                    "margin-bottom": _vm.activeScenesConfig?.options?.length
                      ? "0"
                      : "2rem",
                  },
                  on: { change: _vm.handleScenesChange },
                  model: {
                    value: _vm.activeScenesIndex,
                    callback: function ($$v) {
                      _vm.activeScenesIndex = $$v
                    },
                    expression: "activeScenesIndex",
                  },
                },
                _vm._l(
                  _vm.configureData?.scenesConfig?.options,
                  function (scene, index) {
                    return _c("el-option", {
                      key: scene.id,
                      attrs: { label: scene.name, value: index },
                    })
                  }
                ),
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "text" },
                  on: { click: _vm.editSense },
                },
                [_vm._v("edit Sense")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeScenesConfig?.options?.length,
              expression: "activeScenesConfig?.options?.length",
            },
          ],
          staticClass: "configureContent",
        },
        [
          _c("PodConfigure", {
            attrs: {
              activeScenesIndex: _vm.activeScenesIndex,
              configWholeData: _vm.activeScenesConfig,
            },
            on: {
              updateData: _vm.handleConfigChange,
              updatePreview: _vm.handlePreview,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "configureSave" },
        [
          _c(
            "el-button",
            {
              staticClass: "saveBtn",
              attrs: { type: "primary", loading: _vm.loading, round: "" },
              on: { click: _vm.saveConfig },
            },
            [_vm._v("Save")]
          ),
          _c(
            "el-button",
            {
              staticClass: "saveBtn",
              attrs: { loading: _vm.loading, round: "" },
              on: { click: _vm.backUp },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
      _c("sense-name-dialog", {
        attrs: { currentSenseForm: _vm.currentSense },
        on: { confirmSense: _vm.confirmSense },
        model: {
          value: _vm.isShowSenseNameDialog,
          callback: function ($$v) {
            _vm.isShowSenseNameDialog = $$v
          },
          expression: "isShowSenseNameDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }