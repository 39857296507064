var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "agent_box" }, [
        _c("div", { staticClass: "agent_set" }, [
          _c(
            "div",
            {
              staticClass: "member_close_btn",
              on: { click: _vm.closeAgentBtn },
            },
            [_c("i", { staticClass: "el-icon-remove-outline" })]
          ),
          _c("div", { staticClass: "agent_content" }, [
            _vm._m(0),
            _c("div", { staticClass: "agent_down" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("Sourcing.Reached")))]),
              _c("p", [_vm._v(_vm._s(_vm.$t("Sourcing.pleaseTomorrow")))]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "agent_top" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591372997_ziyuan1.png",
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }