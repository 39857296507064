<template>
  <div v-if="showPopup" class="footer">
    <p class="content">We use cookies (and other similar technologies) for many purposes, including to improve your experience on our site and for ads and analytics.
      Click "Accept all" to accept these uses. <a href="https://usadrop.com/privacy-policy/" target="_blank">Read more in our Cookie Policy.</a></p>
    <button class="AcceptAllBtn" @click="acceptCookie('granted')" id="euCookieAcceptWP">Accept all</button>  <!-- 同意按钮 -->
    <button class="RejectAllBtn" @click="rejectCookie" id="euCookieDenyWP">Reject all</button> <!-- 拒绝按钮 -->
  </div>
</template>
<script>
// 定义全局常量
const CONSENT_OPTION_KEY = 'gtag.consent.option';
const ACCEPT_BUTTON_ID = '#euCookieAcceptWP';
const DENY_BUTTON_ID = '#euCookieDenyWP';
export default {
  components: {
  },
  data() {
    return {
      showPopup: true // 控制弹出提示是否显示
    };
  },
  mounted() {
    const defaultOption = {  // 设置默认选项
      'analytics_storage': 'denied',
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'wait_for_update': 500,
      'region': ['EU', 'UK'],
    };
    gtag('consent', 'default', defaultOption);
    gtag('js', new Date());
    gtag('config', 'G-V1ZE2SP1PW');
    gtag('config', 'GTM-N3X4G29');
    // 其他设置
    gtag('set', 'url_passthrough', true);
    gtag('set', 'ads_data_redaction', true);
    // 根据以前的用户同意选项自动触发代码同意追踪及cookie
    if (localStorage.getItem(CONSENT_OPTION_KEY)) {
      const option = JSON.parse(localStorage.getItem(CONSENT_OPTION_KEY));
      gtag('consent', 'update', option);
      // 如果用户之前已经同意过，则隐藏弹出提示
      this.showPopup = false;
    }
    // 监听点击事件
    document.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    // 移除点击事件监听器
    document.removeEventListener('click', this.handleClick);
  },
  methods: {
    acceptCookie(decision) {
      console.log(decision,'1111')
      // 更新同意选项
      const option = {
        'analytics_storage': decision,
        'ad_storage': decision,
        'ad_user_data': decision,
        'ad_personalization': decision
      };
      this.updateConsent(option);
      // 同意后隐藏弹出提示
      this.showPopup = false;
    },
    rejectCookie() {
      // 拒绝后不更新同意选项，下次继续弹出
      // 显示弹出提示
      this.showPopup = false;
    },
    updateConsent(option) {
      // 使用 gtag 更新同意选项
      gtag('consent', 'update', option);
      // 保存用户同意选项到本地存储
      localStorage.setItem(CONSENT_OPTION_KEY, JSON.stringify(option));
    },
    handleClick(e) {
      // 检查点击目标是否是同意或拒绝按钮
      if (e.target.matches(ACCEPT_BUTTON_ID) || e.target.matches(DENY_BUTTON_ID)) {
        const decision = e.target.matches(ACCEPT_BUTTON_ID) ? 'granted' : 'granted';
        // 更新同意选项并保存到本地存储
        const option = {
          'analytics_storage': decision,
          'ad_storage': decision,
          'ad_user_data': decision,
          'ad_personalization': decision
        };
        this.updateConsent(option);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 80px;
  background: #E0E8FF;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 100px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% - 60px);
    background: #E0E8FF;
    left: 60px;
    bottom: 0;
  }
  .headerContent {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    .breadContent {
      width: calc(100% - 300px);
      display: flex;
      align-items: flex-end;
      padding-left: 56px;
      height: 100%;
      padding-bottom: 15px;
    }
  }
  .content {
    width: 1000px;
    font-size: 14px;
    color: #15104B;
    word-wrap:  break-word;
    line-height: 30px;
    a {
      text-decoration: underline;
    }
  }
  .AcceptAllBtn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #3f6aff;
    color: #3F6AFF;
    font-size: 16px;
    background: #fff;
    margin-left: 30px;
  }
  .AcceptAllBtn:hover {
    background: #3f6aff;
    color: #fff !important;
  }
  .RejectAllBtn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #3f6aff;
    color: #3F6AFF;
    font-size: 16px;
    background: #fff;
    margin-left: 30px;
  }
  .RejectAllBtn:hover {
    background: #3f6aff;
    color: #fff !important;
  }
}
</style>
