var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("el-pagination", {
        attrs: {
          layout: _vm.layout,
          "page-sizes": [10, 20, 30, 40],
          total: _vm.total,
          "page-size": _vm.pageSize,
          "current-page": _vm.currentPage,
        },
        on: { "current-change": _vm.pageChange },
      }),
      _c(
        "div",
        {
          staticClass: "page-sign",
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.jumpPageChange.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { on: { click: _vm.jumpPageChange } }, [
            _vm._v(_vm._s(_vm.$t("Winners.Get2"))),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.goPageVal,
                expression: "goPageVal",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.goPageVal },
            on: {
              change: _vm.pageChangeVal,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.goPageVal = $event.target.value
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }