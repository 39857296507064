import request from '@/utils/request';

export const GetCommissionInfo = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Marketing/GetCommissionInfo',
    data
  });

export const GetCommissionRecordsByPage = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Marketing/GetCommissionRecordsByPage',
    data
  });
  export const GetCommissionWithdrawalsByPage = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Marketing/GetCommissionWithdrawalsByPage',
    data
  });

export const SubmitBalanceWithdrawal = (data) => request({// 获取问卷调查
  method: "post",
  url: '/api/Member/SubmitBalanceWithdrawal',
  data
});

export const CheckShareRole = (data) => request({// 是否是合伙人
  method: "post",
  url: '/api/Share/CheckShareRole',
  data
});
export const ApplyWithdraw = (data) => request({// 获取问卷调查
  method: "post",
  url: '/api/Marketing/ApplyWithdraw',
  data
});
