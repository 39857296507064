<template>
  <div class="email">
    <div class="StepOneBox">
      <div class="step-box">
        <div class="steps">
          <div class="step" :class="[step === 1 ? 'active' : '']">
            <p class="step_name">{{ $t('register.name1') }}</p>
          </div>
          <div class="step_line"></div>
          <div class="step" :class="[step === 2 ? 'active' : '']">
            <p class="step_name">{{ $t('register.name2') }}</p>
          </div>
        </div>
        <div v-if="step === 1" class="step-form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="rule-form">
            <el-form-item prop="fullname" class="option">
              <div class="option-inner">
                <p class="REQUIRED">*</p>
                <el-input
                  v-model.trim="ruleForm.fullname"
                  :placeholder="$t('register.fullname')"
                  auto-complete="off"
                  class="option-inner-input"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="email" class="option">
              <div class="option-inner">
                <p class="REQUIREDTwo">*</p>
                <el-input
                  v-model.trim="ruleForm.email"
                  :placeholder="$t('register.email')"
                  auto-complete="off"
                  class="option-inner-input"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="marketingCode" class="option">
              <div class="option-inner">
                <p class="REQUIRED"></p>
                <el-input
                  v-model.trim="ruleForm.marketingCode"
                  :placeholder="$t('Invitation code')"
                  auto-complete="off"
                  class="option-inner-input"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="next-btn btn-bg0" @click="nextStep">
                {{ $t('register.next') }}
              </div>
              <p class="login">
                <span>{{ $t('login.login') }}</span>
                <span class="change" @click="loginIn"> {{ $t('register.Sign') }}</span>
              </p>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="step === 2" class="step-two">
          <div class="order-quantity">
            <p class="order-quantity-des">Let us help you to scale your business!</p>
            <div class="how-many">
              <el-select
                v-model="valueOrder"
                placeholder="How many orders daily?"
                class="order-quantity-select"
              >
                <el-option
                  v-for="item in optionOrders"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                  class="order-quantity-select-option"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-box">
            <div class="select-box-quantity">
              <div class="select">
                <el-select
                  v-model.trim="selectCode"
                  filterable
                  :filter-method="dataFilter"
                  :placeholder="$t('register.select')"
                  @change="ChangeFlag"
                  class="select-inner"
                >
                  <template slot="prefix">
                    <i :class="selectedClass" style="margin-top: 15px"></i>
                  </template>
                  <el-option
                    v-for="item in optionsCopy"
                    :key="item.Id"
                    :label="'(' + '+' + item.PhoneCode + ')'"
                    :value="item.Id"
                    style="color: #000; font-family: Regular; -webkit-font-smoothing: antialiased"
                  >
                    <span
                      style="
                        float: left;
                        color: #8492a6;
                        font-size: 13px;
                        font-family: Regular;
                        -webkit-font-smoothing: antialiased;
                      "
                    >
                      <i :class="item.NationalFlag"></i>&nbsp;
                      {{ item.Name + '(+' + item.PhoneCode + ')' }}</span
                    >
                  </el-option>
                </el-select>
              </div>
              <input
                v-model.trim="mobileNumber"
                type="text"
                :placeholder="$t('register.phone')"
                class="mobile-number"
              />
              <div class="icon-info" @mouseenter="enter(e)" @mouseleave="leave()">
                <i class="el-icon-info"></i>
              </div>
              <div class="display-info" v-if="displayUSAdropInfo" @mouseleave="leave()">
                <p>Your privacy is very important to USAdrop and your</p>
                <p>infomation will never be shared or sold.</p>
              </div>
            </div>
            <p class="please-enter-phone">Please enter phone number to complete registration</p>
          </div>
          <div class="code-box">
            <div class="code-box-group">
              <div class="code-input">
                <el-input
                  v-model.trim="verifyCode"
                  maxlength="6"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  :placeholder="$t('register.code')"
                  class="code-input-verifyCode"
                ></el-input>
              </div>
              <div class="code-send c-pointer col-col5" @click="getVerifyCode">
                {{ $t('register.send') }}
              </div>
              <div v-if="displayShowCode" ref="child" class="pay-box" @click="closeBtn">
                <div class="ValidationBox">
                  <Validation :phoneNum="mobileNumber" :phoneCode="selectCode"></Validation>
                </div>
              </div>
              <div class="unable-verification">
                <p @click="displayBtn()">No Code?</p>
              </div>
            </div>
            <div class="unable-receive-two" v-if="DisplayUnable">
              <p>Emails us:</p>
              <p>info@usadrop.com</p>
            </div>
          </div>
          <div class="info">
            <el-input
              v-model.trim="password"
              maxlength="20"
              type="password"
              show-password
              :placeholder="$t('register.password')"
              autocomplete="new-password"
              class="info-input"
            ></el-input>
          </div>
          <p class="agree">
            <el-checkbox v-model.trim="agreeChecked" type="checkbox" />
            {{ $t('register.agree') }}
            <a href="https://usadrop.com/terms-and-conditions/" class="Agree1" target="_blank">{{
              $t('register.user')
            }}</a>
            {{ $t('register.and') }}
            <a href="https://usadrop.com/privacy-policy/" class="Agree1" target="_blank">{{
              $t('register.privacy')
            }}</a>
          </p>
          <div class="step-btn">
            <div class="last-btn btn-bg6 col-col1" @click="backStep">{{ $t('register.last') }}</div>
            <div class="next-btn btn-bg0" @click="nextStep">{{ $t('register.nextTow') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Validation from '@/components/GraphicValidation/index.vue'
import { isEmail, isMobileNumber } from '@/utils/tools'
import { registerOptionOrders, registerRules } from '../constants'
import { mapState } from 'vuex'
import { setToken, removeToken } from '@/utils/token'
import { getFrom, searchUrlParams } from '@/utils/tools'
import { getTTSK } from '@/utils/tthelper'
import Cookies from 'js-cookie'

export default {
  components: { Validation },
  data: () => {
    return {
      fromLink: getFrom(),
      ruleForm: {
        fullname: '',
        email: '',
        marketingCode: ''
      },
      rules: registerRules,
      step: 1,
      agreeChecked: false,
      password: '',
      DisplayUnable: false,
      displayShowCode: false,
      verifyCode: '',
      displayUSAdropInfo: false,
      mobileNumber: '',
      optionsCopy: [],
      selectedClass: 'famfamfam-flag-us',
      selectCode: '+1',
      optionOrders: registerOptionOrders,
      valueOrder: '',
      PhoneCode: '',
      FromSite: 'acadamy.usadrop.com',
      marketingCodeStatus: true,
      emailStatus: false,
      RegisterMessage: {} // 会员注册的所有国家
    }
  },
  computed: mapState({
    login: (state) => state.login
  }),
  created() {
    this.dispatch = this.$store.dispatch
    let that = this
    // 获取授权时获取的state code
    let search = decodeURIComponent(location.search)
    if (search) {
      console.log('d=11111'.search)
      let params = {}
      search = search.substring(1).split('&')
      search.map((item) => {
        let index = item.indexOf('=')
        let key = item.substring(0, index)
        let value = item.substring(index + 1).split('#')[0]
        params[key] = value
      })
      if (params.d != undefined) {
        localStorage.setItem('MarketingCode', params.d)
        Cookies.set('MarketingCode', params.d)
        if (
          localStorage.getItem('MarketingCode') != undefined &&
          localStorage.getItem('MarketingCode') != 'undefined'
        ) {
          this.dispatch('alliance/CheckShareRoleAi', {
            MarketingCode: params.d
          })
            .then((res) => {
              if (res.Result.IsCoFunder == true) {
                window.location.href = res.Result.CoFunderShareLink
              } else {
                if (params.register != undefined) {
                  window.location.href = '/Register'
                } else {
                  if (location.pathname == '/v') {
                    localStorage.setItem('fromLink', 'vn.usadrop.com')
                    window.location.href = 'https://vn.usadrop.com'
                  } else {
                    localStorage.setItem('fromLink', 'app.usadrop.com');
                    window.location.href = 'https://app.usadrop.com/register?from=acadamy'
                  }
                  return
                }
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
    this.getData()
  },
  methods: {
    handleTikTokBusiness(data) {
      const ttsk = getTTSK()
      if (ttsk !== undefined && ttsk !== '') {
        data.TiktokShopTokenKey = ttsk
      }
    },
    loginIn() {
      this.$router.push({
        path: '/login',
        query: {
          from: 'acadamy'
        }
      })
    },
    getData() {
      const { dispatch } = this.$store
      dispatch('login/registerCountryList').then((res) => {
        if (res?.Success) {
          this.RegisterMessage = res.Result
          this.optionsCopy = res.Result
          this.initFlag()
        }
      })
    },
    initFlag() {
      if (this.fromLink === 'vn.usadrop.com') {
        this.selectCode = '+84'
        this.selectedClass = 'famfamfam-flag-vn'
      } else {
        this.selectCode = '+1'
        this.selectedClass = 'famfamfam-flag-us'
      }
    },
    dataFilter(val) {
      this.value = val
      if (val) {
        this.optionsCopy = this.RegisterMessage.filter((item) => {
          if (
            !!~item.EnglishName.indexOf(val) ||
            (!!~item.EnglishName.toUpperCase().indexOf(val.toUpperCase()) &&
              !!~item.PhoneCode.indexOf(val)) ||
            !!~item.PhoneCode.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true
          }
        })
      } else {
        this.optionsCopy = this.RegisterMessage
      }
    },
    ChangeFlag(selectedKey) {
      const tempObj = this.optionsCopy.find(function (i) {
        return i.Id === selectedKey
      })
      if (tempObj) {
        this.selectedClass = tempObj.NationalFlag
        this.selectCode = `${'+' + tempObj.PhoneCode}`
      }
    },
    getVerifyCode() {
      const {
        mobileNumber,
        selectCode,
        $store: { dispatch }
      } = this
      this.PhoneCode = selectCode
      if (mobileNumber) {
        dispatch('login/getCheckMobileExist', {
          mobileNumber,
          PhoneCode: Number(selectCode)
        }).then((res) => {
          const { Success } = res
          if (Success) {
            this.displayShowCode = true
          } else {
            this.$message.error(res.ErrMsg || vm.$t('tips.failed'))
          }
        })
        return
      }
      this.$message.error(this.$t('tips.phoneEmpty'))
    },
    closeBtn() {
      setTimeout(() => {
        this.displayShowCode = false
      }, 2000)
    },
    displayBtn() {
      this.DisplayUnable = !this.DisplayUnable
    },
    loading() {
      return this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    CheckGuide() {
      const { dispatch } = this.$store
      dispatch('login/getCheckGuide').then((res) => {
        const { Success, Result } = res || {}
        if (Success && Result) {
          const {
            Result: { IsProfileComplete, IsStoreComplete, HadCheckBusType }
          } = res
          if (!IsProfileComplete) {
            this.$router.push('StepOne')
            return
          }
          if (!IsStoreComplete) {
            this.$router.push('NewStepTwo')
            return
          }
          if (IsProfileComplete && IsStoreComplete) {
            window.location.href = `${window.location.origin}/NewStore`
          }
        }
      })
    },
    getUserInfo() {
      const { dispatch } = this.$store
      dispatch('login/getUserInfo').then((res) => {
        const { Success } = res || {}
        if (Success) {
          this.$store.commit('setUer', res.Result)
          this.CheckGuide()
        }
      })
    },
    handleCode() {
      const localStorageCode = localStorage.getItem('MarketingCode') || ''
      const cookieCode = Cookies.get('MarketingCode') || ''
      // 当两个code 同时存在，使用localStorageCode， 并覆盖cookieCode
      if (localStorageCode !== '' && cookieCode !== '') {
        const currentCode = localStorageCode
        Cookies.set('MarketingCode', currentCode)
        return currentCode
      }
      // localStorageCode存在，cookieCode不存在
      if (localStorageCode) {
        return localStorageCode
      }
      // cookieCode存在, localStorageCode不存在
      if (cookieCode) {
        return cookieCode
      }

      // 都不存在
      return ''
    },
    registerReq() {
      const adscode = localStorage.getItem('CofunderCode')
      this.PhoneCode = this.selectCode
      const {
        mobileNumber,
        verifyCode,
        password,
        ruleForm,
        selectCode,
        Name,
        value1,
        valueOrder,
        $store: { dispatch }
      } = this // value1 时间
      const { fullname, email, marketingCode } = ruleForm || {}

      // 处理邀请码
      const inviteCode = this.handleCode()
      const currentCode = marketingCode || inviteCode
      const data = {
        Account: fullname,
        Email: email,
        MarketingCode: currentCode, // || code,
        CofunderCode: adscode,
        Passwd: password,
        VerifyCode: verifyCode, //147258
        MobileNumber: mobileNumber,
        PhoneCode: Number(selectCode),
        CountryCnName: Name,
        ContactTime: value1,
        OrdersByDay: valueOrder,
        FromSite: this.FromSite,
        BizType: 0
      }
      if (this.$store.state.storeKey) {
        data.ShopifyTokenKey = this.$store.state.storeKey
      }
      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const that = this
      that.handleTikTokBusiness(data)
      dispatch('login/getRegister', data).then((res) => {
        loading.close()
        if (res.ErrCode === '0') {
          removeToken()
          setToken(res.Result)
          this.getUserInfo()
        } else {
          this.$message.error(res.ErrMsg || vm.$t('tips.failed'))
        }
      })
    },
    record() {
      const { dispatch } = this.$store
      const { emailStatus, step } = this
      const code = localStorage.getItem('MarketingCode')
      const data = {
        UserName: this.ruleForm.fullname,
        Email: this.ruleForm.email,
        MarketingCode: this.ruleForm.marketingCode || code // || code
      }
      dispatch('login/getLhFirstStepRecord', data).then((res) => {
        this.marketingCodeStatus = res?.Success
        if (res?.Success) {
          this.step = step + 1
        } else {
          this.$message.error(res.ErrMsg)
          return
        }
      })
    },

    // 验证邮箱是否有重复
    emailDate() {
      const { marketingCode } = this.ruleForm
      let { email, fullname } = this.ruleForm || {}
      const { dispatch } = this.$store
      dispatch('login/verifyEmail', { email: this.ruleForm.email })
        .then((res) => {
          this.emailStatus = res?.Success
          if (res?.Success) {
            if (marketingCode) {
              this.InvitationCodeDate()
              return
            }
            email && fullname && this.record()
          } else {
            return this.$message.error(res.ErrMsg)
          }
        })
        .catch(() => {
          this.emailStatus = false
        })
    },
    // 验证邀请码是否正确
    InvitationCodeDate() {
      const { fullname, email } = this.ruleForm || {}
      const { dispatch } = this.$store
      dispatch('login/registerMarketingCode', { code: this.ruleForm.marketingCode })
        .then((res) => {
          this.emailStatus = res?.Success
          if (res?.Success) {
            email && fullname && this.record()
          } else {
            return this.$message.error(res.ErrMsg)
          }
        })
        .catch(() => {
          this.emailStatus = false
        })
    },
    backStep() {
      this.step = this.step - 1
    },
    nextStep() {
      const { step } = this
      const { fullname, email, marketingCode } = this.ruleForm || {}
      // 第一步
      if (step === 1) {
        isEmail(email)?.val && this.emailDate()
        return
      }
      // 第二步
      if (step === 2) {
        const { mobileNumber, verifyCode, password, agreeChecked, valueOrder } = this // value1 时间
        const obj = isMobileNumber(mobileNumber)
        if (!obj?.val) {
          this.$message.error(obj.message)
          return
        }
        if (!verifyCode) {
          this.$message.error(this.$t('tips.codeEmpty'))
          return
        }
        if (!password) {
          this.$message.error(this.$t('register.password'))
          return
        }
        if (!agreeChecked) {
          this.$message.error(this.$t('tips.notAgreement'))
          return
        }
        if (!valueOrder) {
          this.$message.error(this.$t('tips.Field'))
          return
        }
        this.registerReq()
      }
    },
    leave() {
      this.displayUSAdropInfo = false
    },
    enter() {
      this.displayUSAdropInfo = true
    }
  }
}
</script>
<style lang="scss" scoped>
$error_col: #bc0c34;
$ative_col: #3f6aff;
.email {
  .StepOneBox {
    padding-top: 20px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 30px;
      width: calc(100% - 70px);
      height: 2px;
      background-color: #e5e5e5;
      bottom: -30px;
    }
    .step-box {
      .steps {
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .step {
          font-size: 16px;
          color: #999999;
          font-weight: 600;
          font-family: 'Regular';
          -webkit-font-smoothing: antialiased;
        }
        .active {
          color: $ative_col;
        }
        .step_line {
          width: 123px;
          height: 11px;
          background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581469092_step-line.png')
            no-repeat;
          background-size: cover;
          -webkit-background-size: cover;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
    .step-form {
      margin-top: 20px;
      padding: 20px 40px 20px 30px;
      .rule-form {
        .option {
          ::v-deep .el-form-item__error {
            margin-left: 20px;
          }
          &.is-error {
            ::v-deep .el-input__inner {
              border-color: $error_col !important;
            }
          }
          .option-inner-input {
            ::v-deep .el-input__inner {
              height: 44px;
              border-radius: 50px;
              border: 1px solid #cccccc;
              font-family: 'Regular';
              -webkit-font-smoothing: antialiased;
              &:focus {
                border-color: #3f6aff;
              }
            }
          }
          .option-inner {
            display: flex;
            p {
              width: 20px;
              color: $error_col;
            }
          }
        }
        ::v-deep .el-form-item__error {
          color: $error_col;
        }
        .next-btn {
          width: 230px;
          height: 44px;
          border-radius: 50px;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px auto;
          font-family: 'Regular';
          -webkit-font-smoothing: antialiased;
        }
        .next-btn:hover {
          background: #3c3772 !important;
          color: #fff;
        }
      }
      .login {
        text-align: center;
        margin-top: -24px;
        font-size: 14px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        color: #999999;
        .change {
          color: #3f6aff;
          cursor: pointer;
        }
      }
    }
    .step-two {
      .order-quantity {
        .order-quantity-des {
          color: #858585;
          font-size: 13px;
          font-style: italic;
          margin-top: 0px;
          padding-left: 40px;
          font-family: 'Regular';
          -webkit-font-smoothing: antialiased;
        }
        .how-many {
          padding: 0 40px;
          .order-quantity-select {
            width: 100%;
            height: 44px;
            margin-top: 10px;
            font-size: 13px;
            font-family: Regular, sans-serif;
            -webkit-font-smoothing: antialiased;
            border: 1px solid #cccccc;
            border-radius: 50px;
            ::v-deep .el-input__inner {
              border: none;
              padding-left: 15px;
            }
            .order-quantity-select-option {
              border: #ccc;
              outline: none;
              font-size: 13px;
              font-family: Regular, sans-serif;
              -webkit-font-smoothing: antialiased;
            }
          }
        }
      }
      .select-box {
        padding: 0 40px;
        margin-top: 20px;
        .select-box-quantity {
          position: relative;
          display: flex;
          border-radius: 50px;
          border: 1px solid #666666;
          .select {
            height: 44px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              height: 80%;
              top: 10%;
              right: 0;
              width: 1px;
              background: #cccccc;
              filter: blur(0.5px);
            }
            .select-inner {
              color: #000;
              height: 44px;
              padding-left: 15px;
              ::v-deep .el-input__inner {
                background: none;
                border-radius: 0px;
                height: 44px;
                width: 100px;
              }
            }
          }
          .mobile-number {
            padding-left: 10px;
            font-size: 14px;
          }
          .icon-info {
            position: absolute;
            top: 10px;
            right: -30px;
            cursor: pointer;
            font-size: 20px;
          }
          .display-info {
            position: absolute;
            top: 40px;
            right: -30px;
            color: #fff;
            z-index: 9999;
            background: #93df89;
            border-radius: 10px;
            padding: 6px 10px;
            line-height: 18px;
          }
        }
        .please-enter-phone {
          width: auto;
          margin-top: 10px;
          color: red;
        }
      }
      .code-box {
        padding: 0 40px;
        margin-top: 10px;
        position: relative;
        .code-box-group {
          display: flex;
          .code-input {
            .code-input-verifyCode {
              color: #8b8b8b;
              font-family: Regular, sans-serif;
              -webkit-font-smoothing: antialiased;
              ::v-deep .el-input__inner {
                width: 188px;
                height: 44px;
                line-height: 44px;
                outline: 0;
                border: 1px solid #666666;
                color: #b8b8b8;
                font-family: 'Regular';
                -webkit-font-smoothing: antialiased;
                border-radius: 50px;
              }
            }
          }
          .code-send {
            width: 130px;
            height: 44px;
            font-size: 16px;
            background: #efeff7;
            border-radius: 50px;
            color: #5e5ee0;
            font-family: 'Regular';
            -webkit-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            &:hover {
              background: #f9f9fa;
              color: #5e5ee0;
            }
          }
          .unable-verification {
            color: #4c8bf5;
            font-size: 14px;
            cursor: pointer;
            font-style: italic;
            font-family: 'Regular';
            -webkit-font-smoothing: antialiased;
            width: 130px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .pay-box {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            background: rgba(0, 0, 0, 0.6);
            .ValidationBox {
              vertical-align: middle;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 200px;
            }
          }
        }
        .unable-receive-two {
          display: flex;
          color: #858585;
          position: absolute;
          top: 45px;
          font-weight: 600;
        }
      }
      .info {
        padding: 10px 40px 20px 40px;
        margin: 10px auto 10px;
        ::v-deep .el-input__inner {
          border-radius: 50px;
          height: 45px;
          border: 1px solid #cccccc;
        }
      }
      .agree {
        width: 440px;
        line-height: 1.3;
        font-size: 14px;
        color: #b8b8b8;
        padding-left: 10px;
        margin: 0 auto;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        word-break: normal;
        a {
          color: #4c8bf5;
        }
        ::v-deep .el-checkbox__inner {
          transform: translateY(-2px);
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 24px;
          cursor: pointer;
          border: 1px solid #b2b2b2;
          border-radius: 50%;
          vertical-align: middle;
        }
      }
      .step-btn {
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .last-btn {
          width: 200px;
          height: 42px;
          line-height: 42px;
          border-radius: 50px;
          font-size: 14px;
          color: #5e5ee0;
          text-align: center;
          cursor: pointer;
          background: #efeff7;
          border: 1px solid #f9f9fa;
          &:hover {
            background: #f9f9fa;
            color: #000 !important;
            opacity: 0.8;
          }
        }
        .next-btn {
          width: 200px;
          height: 44px;
          line-height: 42px;
          border-radius: 50px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: #3c3772 !important;
            color: #fff;
          }
        }
      }
    }
  }
}
.display-info {
  font-size: 14px;
}
</style>
