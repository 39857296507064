<script>
import FeedImageUpload from '@/views/SupportTicket/widgets/FeedImageUpload.vue'
import FeedOrderInformation from '@/views/SupportTicket/widgets/FeedOrderInformation.vue'
import FeedErrorProduct from '@/views/SupportTicket/widgets/FeedErrorProduct.vue'
import { baseApiHost } from '@/utils/env'
//
export default {
  name: 'FeedTemplate2',
  props: {
    dataSource: Object,
    showErrorProduct: Boolean,
    imgUploadPath: String,
    countryList: Array,
    afterSaleDetail: Object,
    readonly: Boolean
  },
  components: { FeedErrorProduct, FeedOrderInformation, FeedImageUpload },
  data() {
    return {
      //备份一份制度数据
      OrderAddress: {},
      formModel: {
        TempPath: null,
        IsPic: false,
        fileList: [],
        Address: {}
      },
      defaultFileList: []
    }
  },
  watch: {
    dataSource: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.Address) {
          this.OrderAddress = JSON.parse(JSON.stringify(this.dataSource.Address))
          if (this.afterSaleDetail.Address) {
            return
          }
          this.formModel.Address = newVal.Address
        }
      },
      deep: true,
      immediate: true
    },
    afterSaleDetail: {
      handler: function (newVal) {
        if (!newVal) return
        this.formModel.fileList = newVal.FileList || []
        const imageList = []
        this.formModel.fileList.forEach((item) => {
          let url = ''
          if (item.Url.startsWith('/')) {
            url = baseApiHost() + item.Url
          } else {
            url = baseApiHost() + '/' + item.Url
          }
          imageList.push({
            name: item.FileName,
            url: url
          })
        })

        if (
          imageList.map((e) => e.url).join(',') !== this.defaultFileList.map((e) => e.url).join(',')
        ) {
          this.defaultFileList = imageList
        }
        if (newVal.Address) {
          this.formModel.Address = newVal.Address
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    notExcludeOrderDetails: function () {
      return this.dataSource.OrderDetails?.filter((item) => !item.IsExclude)
    }
  },
  methods: {
    getTemplateData() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            this.$refs.addressRef?.getInputAddress().then((address) => {
              let data = {
                ...this.formModel,
                TempPath: this.imgUploadPath,
                IsPic: this.formModel.fileList.length > 0,
                ...this.$refs.errorProductRef?.getErrorSkuList(),
                ...address
              }
              resolve(data)
            })
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>

<template>
  <el-form ref="formRef" label-position="top" :model="formModel" :disabled="readonly">
    <el-form-item>
      <FeedImageUpload
        v-model="formModel.fileList"
        :img-upload-path="imgUploadPath"
        :show-example="false"
        :default-file-list="defaultFileList"
        :readonly="readonly"
      />
    </el-form-item>
    <FeedOrderInformation
      ref="addressRef"
      :title="$t('SupportTicket.ReconfirmTheInformation')"
      :country-list="countryList"
      :address="formModel.Address"
      :readonly="readonly"
    />
    <FeedOrderInformation
      v-if="!readonly"
      :country-list="countryList"
      :address="OrderAddress"
      :readonly="true"
    />
    <FeedErrorProduct
      v-if="showErrorProduct"
      ref="errorProductRef"
      :order-details="notExcludeOrderDetails"
    />
  </el-form>
</template>

<style lang="scss" scoped></style>
