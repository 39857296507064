var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feed-return-info" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("SupportTicket.ReturnedInfo"))),
            ]),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { background: "#3f6aff", border: "none" },
              attrs: { size: "small", round: "", type: "primary" },
              on: { click: _vm.onAddMissItem },
            },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.AddReturnedSku")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "validateFormRef",
          attrs: {
            model: _vm.formModel,
            "label-position": "right",
            "label-width": "auto",
            disabled: _vm.readonly,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("SupportTicket.ReturnedTrackingNumber"),
                props: "ReturnedTrackingNumber",
                rules: {
                  required: true,
                  message: "Please Input Return TrackingNumber",
                  trigger: "blur",
                },
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "70%" },
                model: {
                  value: _vm.formModel.ReturnedTrackingNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.formModel, "ReturnedTrackingNumber", $$v)
                  },
                  expression: "formModel.ReturnedTrackingNumber",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.formModel.returnItems, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("SupportTicket.ReturnedItem"),
                              prop: "returnItems." + index + ".skuId",
                              rules: {
                                required: true,
                                message: "Please select Return Item",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: item.skuId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "skuId", $$v)
                                  },
                                  expression: "item.skuId",
                                },
                              },
                              _vm._l(_vm.productSelectOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("SupportTicket.ReturnedQuantity"),
                              prop: "returnItems." + index + ".quantity",
                              rules: {
                                required: true,
                                message: "Please Input Return Quantity",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number" },
                              on: {
                                input: function ($event) {
                                  return _vm.handleInput($event, item)
                                },
                              },
                              model: {
                                value: item.quantity,
                                callback: function ($$v) {
                                  _vm.$set(item, "quantity", $$v)
                                },
                                expression: "item.quantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _vm.formModel.returnItems.length > 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: {
                                  size: "small",
                                  type: "text",
                                  disabled: _vm.readonly,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRemoveMissItem(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("SupportTicket.Remove")) +
                                    " "
                                ),
                              ]
                            )
                          : _c("div"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }