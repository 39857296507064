import {
  GetTop,
  GetUnReadNewsCount,
  LhNewsGet,
  LhNewsGetAll,
  GetLasted
} from '@/api/notification'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetTopNo({state, commit}, params) {return GetTop(params).then(res => res)},
    GetUnReadNewsCountNo({state, commit}, params) {return GetUnReadNewsCount(params).then(res => res)},
    LhNewsGetNo({state, commit}, params) {return LhNewsGet(params).then(res => res)},
    LhNewsGetAllNo({state, commit}, params) {return LhNewsGetAll(params).then(res => res)},
    GetLastedNo({state, commit}, params) {return GetLasted(params).then(res => res)}
  }

}