<template>
  <div class="podDetail">
    <div class="infoContent">
      <div class="leftPics">
        <img :src="showMainUrl" alt="" class="mainPic" />
        <div class="thumbCard">
          <product-thumbnail-scroll-card-vue
            :productFiles="imgCarouseList"
            :currentIndex="currentImgIndex"
            @didThumbnailsIndexChange="getMainPicUrl"
          ></product-thumbnail-scroll-card-vue>
        </div>
      </div>
      <div class="rightSkuAConfigure">
        <div class="skuInfo">
          <div class="title">{{ detailInfo?.title }}</div>
          <div class="price">{{ '$' + (currentSkuObj?.channelPrice || 0) }}</div>
          <div
            class="skusContent"
            v-for="(item, index) in detailInfo?.goodsDetailSpecList"
            :key="item.id"
          >
            <div class="labelTitle">
              <span>{{ item?.specName['en'] }}: </span>
              <span>{{ showObj[item?.specName['en']] }}</span>
            </div>
            <div class="skuItems">
              <template v-if="item.specValue[0].fileUrl">
                <img
                  :class="
                    showObj[item?.specName['en'] + 'Id'] === _sku.specValueCode ? 'active' : ''
                  "
                  @click="pickSpec(index, _index, _sku, item?.specName['en'])"
                  v-for="(_sku, _index) in item.specValue"
                  :key="_sku.specValueCode"
                  class="skuPic"
                  :src="_sku.fileUrl"
                  alt=""
                />
              </template>
              <template v-else>
                <span
                  :class="
                    showObj[item?.specName['en'] + 'Id'] === _sku.specValueCode ? 'active' : ''
                  "
                  @click="pickSpec(index, _index, _sku, item?.specName['en'])"
                  class="skuTxt"
                  v-for="(_sku, _index) in item.specValue"
                  :key="_sku.specValueCode"
                  >{{ _sku['en'] }}</span
                >
              </template>
            </div>
          </div>
        </div>
        <div class="podShowConfigure">
          <PodShowConfigure
            v-show="activeScenesConfig?.options?.length"
            :design-config="configureData"
            :design-data="diySkuData"
            :active-scenes-index="activeScenesIndex"
          />
        </div>
      </div>
    </div>
    <div v-if="configureData?.scenesConfig?.options.length > 1">
      <el-select
        class="senseSelect"
        v-model="activeScenesIndex"
        @change="handleScenesChange"
        :style="{ 'margin-bottom': activeScenesConfig?.options?.length ? '0' : '2rem' }"
      >
        <el-option
          v-for="(scene, index) in configureData?.scenesConfig?.options"
          :key="scene.id"
          :label="scene.name"
          :value="index"
        ></el-option>
      </el-select>
      <el-button style="margin-left: 10px" type="text" @click="editSense">edit Sense</el-button>
    </div>

    <div class="configureContent" v-show="activeScenesConfig?.options?.length">
      <PodConfigure
        :activeScenesIndex="activeScenesIndex"
        :configWholeData="activeScenesConfig"
        @updateData="handleConfigChange"
        @updatePreview="handlePreview"
      ></PodConfigure>
    </div>

    <div class="configureSave">
      <el-button @click="saveConfig" type="primary" class="saveBtn" :loading="loading" round
        >Save</el-button
      >
      <el-button @click="backUp" class="saveBtn" :loading="loading" round>Back</el-button>
    </div>
    <sense-name-dialog
      @confirmSense="confirmSense"
      v-model="isShowSenseNameDialog"
      :currentSenseForm="currentSense"
    ></sense-name-dialog>
  </div>
</template>
<script>
import SenseNameDialog from '../components/SenseNameDialog.vue'
import PodConfigure from '@/views/PodPage/components/PodConfigure.vue'
import ProductThumbnailScrollCardVue from '@/components/ProductThumbnailScrollCard.vue'
import PodShowConfigure from '../components/PodShowConfigure.vue'
import { getSpu, getShopifyConfig, saveShopifyConfig } from '../api.js'
export default {
  components: {
    PodShowConfigure,
    ProductThumbnailScrollCardVue,
    PodConfigure,
    SenseNameDialog
  },
  data() {
    return {
      detailInfo: {},
      showObj: {},
      configureData: {
        scenesConfig: {
          options: [],
          config: {
            title: '',
            toolTip: '',
            helpTxt: '',
            showPopupTip: false,
            popTitle: '',
            popWidth: 0
          }
        }
      }, //配置参数数据
      activeScenesConfig: {},
      imgCarouseList: [],
      currentImgIndex: 0,
      currentSkuObj: {},
      diySkuData: {},
      baseDesignInfo: {},
      loading: false,
      designNo: '',
      spuNo: '',
      oldConfig: [],
      isPublish: false,
      hasValidLayer: false,
      colorfulStages: {},
      activeScenesIndex: 0, //当前激活的那个场景
      isShowSenseNameDialog: false, //是否显示编辑场景名称弹窗
      currentSense: {}
    }
  },
  // provide() {
  //   return {
  //     activeScenesIndex: this.activeScenesIndex
  //   }
  // },
  created() {
    this.designNo = this.$route.query.id
    this.spuNo = this.$route.query.spu
    this.getSpuFn()
  },
  computed: {
    showMainUrl() {
      return this.imgCarouseList[this.currentImgIndex]
    }
  },
  methods: {
    editSense() {
      this.isShowSenseNameDialog = true
      this.currentSense = {
        ...this.configureData?.scenesConfig.config,
        options: this.configureData?.scenesConfig.options
          ? JSON.parse(JSON.stringify(this.configureData?.scenesConfig.options))
          : []
        // name: this.configureData?.scenesConfig?.options[this.activeScenesIndex].name,
        // id: this.configureData?.scenesConfig.options[this.activeScenesIndex].id
      }
    },
    confirmSense(formInfo) {
      this.isShowSenseNameDialog = false
      this.configureData.scenesConfig.options = [...formInfo.options]
      this.configureData.scenesConfig.config = {
        ...this.configureData?.scenesConfig.config,
        ...formInfo
      }
    },
    // 获取产品数据 sku价格等
    getSpuFn() {
      getSpu({ spuNo: this.spuNo, platform: 2 }).then((res) => {
        if (res.code === 0 && res.body) {
          let { goodsDetailSpecList, goodsSkuList, goodsTransList } = res.body
          goodsDetailSpecList = goodsDetailSpecList?.map((spec) => {
            const specName = JSON.parse(spec.specName || '[]')
            const specValue = JSON.parse(spec.specValue || '[]')
            this.showObj[specName['en']] = specValue[0]['en']
            this.showObj[specName['en'] + 'Id'] = specValue[0]?.specValueCode
            return {
              ...spec,
              specName,
              specValue
            }
          })
          goodsSkuList = goodsSkuList?.map((sku) => {
            return {
              ...sku,
              specValueCodeItem: JSON.parse(sku.specValueCodeItem)
            }
          })
          const title = goodsTransList?.find((lang) => lang.country === 'en')?.subjectTrans
          this.detailInfo = { ...res.body, goodsDetailSpecList, goodsSkuList, title }
          this.getImgCarListFn()
          this.getCurrentSku()
          this.getConfig()
        }
      })
    },
    async getConfig() {
      let loading = this.$loading({
        lock: false,
        fullScreen: true, // 是否为全屏 Dialog
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        await getShopifyConfig({ designNo: this.designNo }).then((res) => {
          if (res.code === 0 && res.body) {
            this.diySkuData = JSON.parse(res.body?.designVO?.designInfo)
            this.baseDesignInfo = res.body?.designVO
            this.isPublish = !res.body?.designVO?.publishState ? false : true
            this.oldConfig = res.body?.shopifyConfigVO?.shopifyConfig
              ? JSON.parse(res.body?.shopifyConfigVO?.shopifyConfig)
              : []
            this.dealMultiSense()
            // this.dealData()
          }
        })
        loading.close()
      } catch (error) {
        console.log(error, 'error')
        loading.close()
      }
    },
    handleScenesChange() {
      const currentSense = this.configureData?.scenes[this.activeScenesIndex]
      this.$set(this.activeScenesConfig, 'options', currentSense.config.options)
      this.$set(this.activeScenesConfig, 'canPreview', currentSense.config.canPreview)
      // this.$forceUpdate()
    },
    dealMultiSense() {
      const scenes = []
      let senseNames = { options: [], config: {} }
      if (this.oldConfig?.scenesConfig) {
        senseNames.config = this.oldConfig.scenesConfig.config
        senseNames.options = this.oldConfig.scenesConfig.options
      }
      this.diySkuData?.scenes.forEach((scene, index) => {
        if (!this.oldConfig?.scenesConfig) {
          senseNames.options.push({
            name: scene.name,
            id: scene.id
          })
        }
        const sceneObj = { name: scene.name, id: scene.id, config: {} }
        const hasOldSense = this.oldConfig.scenes?.find((old) => old.id === scene.id)?.config //找到旧配置中的场景
        if (hasOldSense) {
          const senData = this.dealData(scene?.designInfo?.stages, hasOldSense)
          sceneObj.config = senData
        } else {
          const senData = this.dealData(scene?.designInfo?.stages)
          sceneObj.config = senData
        }
        scenes.push(sceneObj)
      })
      this.configureData = {
        scenes,
        scenesConfig: senseNames,
        product: this.baseDesignInfo.spuNo,
        product_cms: this.baseDesignInfo.productBaseNo
      }
      this.activeScenes = 0
      this.activeScenesConfig = this.configureData.scenes[0].config
    },
    //配置项处理部分
    dealData(eagerStage, oldEagerStage) {
      let that = this
      let canPreview = true
      let layerList = []
      const stages = Object.keys(eagerStage)
      const baseFormInfo = {
        title: '',
        toolTip: '',
        helpTxt: '',
        richText: '',
        popWidth: null,
        popTitle: '',
        showOptionTitle: false
      }
      let index = 0
      stages.forEach((key) => {
        const position = { image: 0, 'i-text': 0 }
        let colorful = false
        eagerStage[key].objects.forEach((item) => {
          if (item.id === 'product' && item.backgroundColor) {
            // 此块板是否支持配置背景色
            colorful = true
          }
          if (item.layerType === 'custom' && item.src !== eagerStage[key].shadowSrc) {
            position[item.type]++
            index++
            const personInfo =
              item.type === 'image'
                ? {
                    type: 'image',
                    positionName: key + '/position' + position[item.type],
                    canUpload: true,
                    showPrintImg: false,
                    showPrintImgName: false,
                    printFileImgList: []
                  }
                : {
                    type: 'i-text',
                    textAlign: 'center',
                    limit: 1110,
                    AutoScale: false,
                    width: 0,
                    positionName: key + '/position' + position[item.type]
                  }
            layerList.push({
              id: item.id,
              status: false,
              type: item.type,
              value: item.type === 'image' ? item.src : item.text,
              form: { ...baseFormInfo, title: `option${index}` },
              personInfo
            })
          }
        })
      })
      if (oldEagerStage) {
        canPreview = oldEagerStage.canPreview
      }
      layerList = layerList.map((layer) => {
        const oldLay = oldEagerStage?.options?.find((old) => old.id === layer.id)
        if (oldLay) {
          oldLay.value = layer.value
          return oldLay
        } else {
          return layer
        }
      })
      return {
        canPreview,
        options: layerList
        // colorfulStages: this.colorfulStages
      }
    },
    handlePreview(isPreview) {
      if (!this.configureData.scenes) return
      this.$set(this.activeScenesConfig, 'canPreview', isPreview)
      this.$set(this.configureData.scenes[this.activeScenesIndex], 'config', {
        ...this.activeScenesConfig,
        canPreview: isPreview
      })
    },
    handleConfigChange(data) {
      if (!this.configureData.scenes) return
      this.activeScenesConfig = { ...this.activeScenesConfig, options: data }
      // this.configureData.scenes[this.activeScenesIndex]
      this.$set(this.configureData.scenes[this.activeScenesIndex], 'config', {
        ...this.activeScenesConfig
      })
    },
    //商品处理部分
    pickSpec(index, _index, item, specName) {
      if (item.fileUrl) {
        this.currentImgIndex = _index
      }
      this.showObj[specName] = item.en
      this.showObj[specName + 'Id'] = item.specValueCode
      this.$forceUpdate()
      this.getCurrentSku()
    },
    getMainPicUrl(index) {
      this.currentImgIndex = index
    },
    getCurrentSku() {
      const keys = []
      for (const key in this.showObj) {
        if (key.includes('Id')) {
          keys.push(this.showObj[key])
        }
      }
      this.detailInfo.goodsSkuList?.forEach((item) => {
        if (keys.every((_item) => item.specValueCodeItem.includes(_item))) {
          this.currentSkuObj = item
        }
      })
    },
    // 获取所有的图片
    getImgCarListFn() {
      this.detailInfo.goodsDetailSpecList?.forEach((item) => {
        if (item.specValue?.length && item.specValue[0].fileUrl) {
          item.specValue.forEach((_item) => {
            this.imgCarouseList.push(_item.fileUrl)
          })
        }
      })
      this.imgCarouseList.push(...this.detailInfo.images)
    },
    // 保存配置项
    async saveConfig() {
      for (let m = 0; m < this.configureData.scenes.length; m++) {
        const scene = this.configureData.scenes[m]?.config?.options
        const currentSenseName = this.configureData.scenes[m].name
        if (scene) {
          for (let i = 0; i < scene.length; i++) {
            const item = scene[i]
            if (
              item.status &&
              item.type === 'image' &&
              !item.personInfo.canUpload &&
              !item.personInfo.showPrintImg
            ) {
              this.$message.error(
                `${currentSenseName}: You must select at least one personalization way for the option of image personalization in open.`
              )
              return
            } else {
              if (
                item.status &&
                item.type === 'image' &&
                item.personInfo.showPrintImg &&
                !item.personInfo.printFileImgList.length
              ) {
                this.$message.error(
                  `${currentSenseName}:Please check settings again. You must complete the required settings.`
                )
                return
              }
            }
          }
        }
      }

      const config = {
        ...this.configureData
      }
      let loading = this.$loading({
        lock: false,
        fullScreen: true, // 是否为全屏 Dialog
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        await saveShopifyConfig({
          designNo: this.designNo,
          platform: 2,
          shopifyConfig: JSON.stringify(config)
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('Save successful!')
          }
        })
        loading.close()
      } catch (error) {
        loading.close()
      }
    },
    backUp() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.podDetail {
  width: 1400px;
  padding-bottom: 24px;
  .infoContent {
    display: flex;
    width: 100%;
    background: #f9fafb;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 40px 60px 24px 40px;

    .leftPics {
      width: 560px;
      height: 100%;
      margin-right: 60px;
      .mainPic {
        width: 560px;
        height: 560px;
        margin-bottom: 40px;
      }
      .thumbCard {
        width: 100%;
      }
    }

    .rightSkuAConfigure {
      flex: 1;
      display: flex;
      flex-direction: column;
      .skuInfo {
        min-height: 300px;
        width: 100%;
        .title {
          width: 700px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 24px;
          font-weight: 600;
          color: #15104b;
        }
        .price {
          font-size: 32px;
          font-weight: 700;
          margin: 22px 0;
          color: #3b9c39;
        }
        .skusContent {
          margin-bottom: 20px;
          .labelTitle {
            display: flex;
            font-size: 18px;
            color: #333333;
            margin-bottom: 10px;
          }
          .skuItems {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: flex-start;
            .skuPic {
              cursor: pointer;
              width: 68px;
              height: 68px;
              min-width: 68px;
              border-radius: 4px;
            }
            .skuTxt {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border-radius: 14px;
              padding: 5px 20px;
              font-size: 14px;
            }
            .active {
              border: 1px solid #15104b;
            }
          }
        }
      }
      .podShowConfigure {
        height: 430px;
        margin-top: 20px;
        overflow: auto;
      }
    }
  }
  .configureSave {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .saveBtn {
      font-size: 16px;
      font-weight: 500;
      width: 340px;
      height: 40px;
    }
  }
  .senseSelect {
    width: 276px;
  }
  .configureContent {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    // height: 1424px;
  }
}
</style>
