import {
  // GetDetail,
  LhSourcingQuoteList,
  MemberProductRemove,
  MemberProductEdit
} from '@/api/quoted'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    LhSourcingQuoteListQt({state, commit}, params) {return LhSourcingQuoteList(params).then(res => res)},
    // GetDetailQt({state, commit}, params) {return GetDetail(params).then(res => res)},
    // MemberProductRemoveQt({state, commit}, params) {return MemberProductRemove(params).then(res => res)},
    // MemberProductEditQt({state, commit}, params) {return MemberProductEdit(params).then(res => res)}
  }

}