import {
  GetPaymentTypes,
  SubmitBankTransfer,
  SubmitPaypalOffline,
  CreateCheckout,
  SubmitPaypal,
  CalculateFeeAmount,
  SubmitOceanPay,
  GetOceanPayRequestForm,
  CapturePaypalOrder,
  CreatePaypalOrder,
  CreateAirWallexOrder,
  TaxBalanceList,
  GetCardList,
  LastBalanceWithdrawal
} from '@/api/wallet'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) { 
      state.token = status;
    }
  },
  actions:{
    GetPaymentTypesWa({state, commit}, params) {return GetPaymentTypes(params).then(res => res)},
    SubmitBankTransferWa({state, commit}, params) {return SubmitBankTransfer(params).then(res => res)},
    CreateCheckoutWa({state, commit}, params) {return CreateCheckout(params).then(res => res)},
    SubmitPaypalWa({state, commit}, params) {return SubmitPaypal(params).then(res => res)},
    CalculateFeeAmountWa({state, commit}, params) {return CalculateFeeAmount(params).then(res => res)},
    SubmitOceanPayWa({state, commit}, params) {return SubmitOceanPay(params).then(res => res)},
    GetOceanPayRequestFormWa({state, commit}, params) {return GetOceanPayRequestForm(params).then(res => res)},
    CapturePaypalOrderWa({state, commit}, params) {return CapturePaypalOrder(params).then(res => res)},
    CreatePaypalOrderWa({state, commit}, params) {return CreatePaypalOrder(params).then(res => res)},
    CreateAirWallexOrderWa({state, commit}, params) {return CreateAirWallexOrder(params).then(res => res)},
    TaxBalanceListWa({state, commit}, params) {return TaxBalanceList(params).then(res => res)},
    GetCardListWa({state, commit}, params) {return GetCardList(params).then(res => res)},
    LastBalanceWithdrawalWa({state, commit}, params) {return LastBalanceWithdrawal(params).then(res => res)},
    SubmitPaypalOfflineWa({state, commit}, params) {return SubmitPaypalOffline(params).then(res => res)},
  }
}