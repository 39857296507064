/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
import {
  GetAgentById,
  message,
  ModifyMember,
  GetPublishSettings,
  SavePublishSettings,
  CancelSubscription,
  SubmitComment
} from '@/api/center'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetAgentByIdCr({state, commit}, params) {
      return GetAgentById(params).then(res => res)
    },
    messageCr({state, commit}, params) {
      return message(params).then(res => res)
    },
    ModifyMemberCr({state, commit}, params) {
      return ModifyMember(params).then(res => res)
    },
    GetPublishSettingsCr({state, commit}, params) {
      return GetPublishSettings(params).then(res => res)}
      ,
    SavePublishSettingsCr({state, commit}, params) {
      return SavePublishSettings(params).then(res => res)
    },
    CancelSubscriptionCr({state, commit}, params) {
      return CancelSubscription(params).then(res => res)
    },
    SubmitCommentCr({state, commit}, params) {
      return SubmitComment(params).then(res => res)
    },
  }

}
