<template>
  <div>
    <div class="box-wrapp">
      <div v-if="cardList.length === 0" style="width: 100%; display: flex; justify-content: center">
        <el-empty />
      </div>
      <div class="bigbox" v-else>
        <div class="box" v-for="item in cardList" :key="item.id">
          <div class="images" :class="blur ? 'blur' : ''" @click="goDetail(item)">
            <img :src="item.mainImage" alt="" />
          </div>
          <div class="simple" :class="blur ? 'blur' : ''">{{ item | productName }}</div>
          <div class="lists_num">Lists: {{ item | lists }}</div>
          <div class="btn">
            <button class="connect_btn" @click="connect(item.goodsCode)">Connect</button>
            <button class="list_btn" @click="addProduct(item.goodsCode)">Import</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员提示弹窗 -->
    <UpgradeDialog :visible.sync="upgradeVisible" title="Get Pro Plan for more features" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UpgradeDialog from '@/components/UpgradeDialog.vue'
import { productTypeMap } from '@/views/Products/constants'
import { importPublish } from '@/api/winner'

export default {
  props: {
    cardList: {
      type: Array,
      required: true
    },
    blur: {
      type: Boolean,
      default: false
    },
    productType: {
      type: Number,
      default: productTypeMap.Winner
    }
  },

  components: {
    UpgradeDialog
  },

  filters: {
    productName(val) {
      if (val.ProductName) {
        return val.ProductName
      }
      const findTrans = val?.goodsTransList?.find((item) => item.country === 'en')
      return findTrans?.subjectTrans
    },
    lists: (val) => {
      if (val.Lists) {
        return val.Lists
      }
      return Math.floor(Math.random() * (2000 - 0 + 1)) + 0
    }
  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.member.memberInfo
    })
  },

  data() {
    return {
      upgradeVisible: false
    }
  },

  methods: {
    addProduct(goodsCode) {
      importPublish({ goodsCode })
        .then((res) => {
          console.log(res)
          if (res.Success) {
            this.$router.push('/ImportList')
            this.$message({
              message: this.$t('tips.add'),
              duration: 1000,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.ErrMsg || this.$t('tips.failed'),
              duration: 1000,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    connect(goodsCode) {
      if (this.memberInfo.GradeId === 1) {
        // 普通用户需要升级才能查看
        this.upgradeVisible = true
        return
      }
      this.$router.push({
        path: '/ProConnect',
        query: {
          goodsCode: goodsCode
        }
      })
    },

    goDetail(item) {
      console.log('🚀 goodsCode:', item.goodsCode)
      let upgradeFlag = false
      // Winner 会员就可以查看，Top100 需要Plus会员才能查看
      if (this.productType === productTypeMap.Winner) {
        upgradeFlag = this.memberInfo.GradeId === 1
      } else if (this.productType === productTypeMap.Top100) {
        upgradeFlag = this.memberInfo.GradeId < 3
      }
      if (upgradeFlag) {
        // 普通用户需要升级才能查看
        this.upgradeVisible = true
        return
      }
      this.$router.push({
        path: `/ProductDetail/${item.goodsCode}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bigbox {
  display: grid;
  // flex-wrap: wrap;
  grid-template-columns: repeat(4, 300px);
  grid-gap: 30px 37px;
  // padding-bottom: 12px;
  // justify-content: space-between;
  .box {
    // margin-top: 24px;
    padding: 30px;
    width: 300px;
    // height: 385px;
    background: #f6f6fa;
    box-shadow: 2px 4px 6px 0px #0000001a;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    &:hover {
      transform: scale(1.05);
    }
    .images {
      width: 240px;
      height: 240px;
      margin: 0 auto;
      cursor: pointer;
      &.blur {
        filter: blur(15px);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .simple {
      font-size: 14px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 200px;
      height: 55px;
      margin: 15px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 25px;
      word-break: normal;
      &.blur {
        filter: blur(8px);
      }
    }
    .lists_num {
      // margin-left: 45px;
      color: #999999;
      font-size: 14px;
    }
    .btn {
      // margin-left: 30px;
      margin-top: 20px;
      .connect_btn {
        color: #15104b;
        background: #fff;
        border-radius: 50px;
        font-size: 14px;
        width: 102px;
        height: 25px;
        border: 1px solid #15104b;
        &:hover {
          background: #15104b;
          color: #fff;
        }
      }
      .list_btn {
        color: #fff;
        background: #15104b;
        border-radius: 50px;
        font-size: 14px;
        width: 102px;
        height: 25px;
        border: 1px solid #15104b;
        margin-left: 15px;
        &:hover {
          background: #15104b;
          color: #fff;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
