var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formRef",
      attrs: {
        "label-position": "top",
        model: _vm.formModel,
        disabled: _vm.readonly,
      },
    },
    [
      _c(
        "el-form-item",
        [
          _c("FeedReturnInfo", {
            ref: "needReSendRef",
            attrs: {
              "order-details": _vm.notExcludeOrderDetails,
              "return-infos": _vm.returnInfos,
              readonly: _vm.readonly,
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("FeedImageUpload", {
            attrs: {
              "img-upload-path": _vm.imgUploadPath,
              "show-example": true,
              "default-file-list": _vm.defaultFileList,
              readonly: _vm.readonly,
            },
            model: {
              value: _vm.formModel.fileList,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "fileList", $$v)
              },
              expression: "formModel.fileList",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }