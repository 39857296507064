var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName_big_box" }, [
        _c("div", { staticClass: "activeName" }, [
          _c("div", { staticClass: "FAQTitle" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("FAQ.FAQ")))]),
          ]),
          _c("div", { staticClass: "AgentBox" }, [
            _c("div", { staticClass: "AgentMessage" }, [
              _c("div", { staticClass: "AgentImgLeft" }, [
                _c("img", {
                  attrs: {
                    src: _vm.baseApiHost() + _vm.CenterList.Avatar,
                    alt: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "AgentMessageRight" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("SideBar.Hi")) + " "),
                  _c("span", { staticStyle: { color: "#53c248" } }, [
                    _vm._v(_vm._s(_vm.userInfo.RealName) + ","),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("SideBar.how_doing")) + " "),
                ]),
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("SideBar.I_m")) + " "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-style": "italic",
                        "text-decoration": "underline",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.CenterList.AgentName))]
                  ),
                  _vm._v(" , " + _vm._s(_vm.$t("SideBar.YourAgent")) + " "),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.$t("SideBar.FeelMe")) + " !")]),
              ]),
            ]),
            _c("div", { staticClass: "AgentMethods" }, [
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://api.whatsapp.com/send?phone=+" +
                        _vm.CenterList.WhatsApp +
                        "&text=Hello",
                      target: "_blank",
                    },
                  },
                  [
                    _c("div", { staticClass: "member_Whatsapp" }, [
                      _vm._m(0),
                      _c("span", [_vm._v(_vm._s(_vm.$t("SideBar.WhatsApp")))]),
                    ]),
                  ]
                ),
              ]),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "skype:" + _vm.CenterList.Skype + "?chat",
                      target: "_blank",
                    },
                  },
                  [
                    _c("div", { staticClass: "member_Skype" }, [
                      _vm._m(1),
                      _c("span", [_vm._v(_vm._s(_vm.$t("SideBar.Skype")))]),
                    ]),
                  ]
                ),
              ]),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://t.me/" + _vm.CenterList.Telegram,
                      target: "_blank",
                    },
                  },
                  [
                    _c("div", { staticClass: "member_Telegram" }, [
                      _vm._m(2),
                      _c("span", [_vm._v(_vm._s(_vm.$t("SideBar.Telegram")))]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "FAQMessageBox" }, [
            _c(
              "div",
              {
                staticClass: "HowUSAdrop_words",
                on: { click: _vm.HowUSAdropBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("FAQ.HowUSAdrop")))]), _vm._m(3)]
            ),
            _c(
              "div",
              {
                staticClass: "shippingTime_words",
                on: { click: _vm.shippingTimeBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("FAQ.shippingTime")))]), _vm._m(4)]
            ),
            _c(
              "div",
              {
                staticClass: "shippingCost_words",
                on: { click: _vm.shippingCostBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("FAQ.shippingCost")))]), _vm._m(5)]
            ),
            _c(
              "div",
              {
                staticClass: "productItem_words",
                on: { click: _vm.productItemBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("FAQ.productItem")))]), _vm._m(6)]
            ),
            _c(
              "div",
              {
                staticClass: "lookingFor_words",
                on: { click: _vm.lookingForBtn },
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("FAQ.lookingFor")))]), _vm._m(7)]
            ),
          ]),
        ]),
        _vm.ShowZaLoBox
          ? _c("div", { staticClass: "zalo_box" }, [
              _c("div", { staticClass: "zalo_box_bgImg" }, [
                _c(
                  "div",
                  { staticClass: "zalo_Icon", on: { click: _vm.CloaseZalo } },
                  [_c("i", { staticClass: "el-icon-circle-close" })]
                ),
                _c("div", { staticClass: "Zalo_Img" }, [
                  _c("img", { attrs: { src: _vm.CenterList.ZaloQR, alt: "" } }),
                ]),
                _c("div", { staticClass: "CenterList_AgentName" }, [
                  _c("p", [_vm._v(_vm._s(_vm.CenterList.AgentName))]),
                ]),
                _c("div", { staticClass: "SideBar_ScanZalo" }, [
                  _vm._v(" " + _vm._s(_vm.$t("SideBar.ScanZalo")) + " "),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.ShowMessageBoardBox
          ? _c("div", { staticClass: "MessageBoardBox" }, [
              _c("div", { staticClass: "MessageBoardContent" }, [
                _c("div", { staticClass: "header_title" }, [
                  _c("div", { staticClass: "SideBarComment" }, [
                    _c("p", [_vm._v(" " + _vm._s(_vm.$t("SideBar.Comment")))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "SideBarIconClose",
                      on: { click: _vm.CloseMessageBoard },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ]),
                _c("div", { staticClass: "line" }),
                _c(
                  "div",
                  { staticClass: "Message_content" },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 2, resize: "none" },
                      model: {
                        value: _vm.keywords,
                        callback: function ($$v) {
                          _vm.keywords = $$v
                        },
                        expression: "keywords",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "footerbox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "Cancel btn_com comBtn col-col0 border-col0",
                      on: { click: _vm.CloseMessageBoard },
                    },
                    [_vm._v(_vm._s(_vm.$t("store.cancel")))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "Save btn_com comBtn btn-bg0 border-col0",
                      on: { click: _vm.SubmitComment },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("SideBar.Submit")) + " ")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("../../../assets/imgs/UpgradeToPro/whatsapp-logo-hero.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("../../../assets/imgs/UpgradeToPro/Skype123.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", [
        _c("img", {
          attrs: {
            src: require("../../../assets/imgs/UpgradeToPro/telegram1.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }