/*
 * @Author: jiangshiyang
 * @Date: 2024-05-22 15:06:47
 * @LastEditors: jiangshiyang
 * @Description:
 */
import request from '@/utils/request'

export const GetSourcingCountryList = (params) =>
  request({
    // 获取问卷调查
    method: 'get',
    url: '/api/Common/GetSourcingCountryList',
    params
  })

export const CrawlProductPost = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: '/api/CrawlProduct/CrawlProductPost',
    data
  })

export const ConfirmQuote = (data) =>
  request({
    // 获取问卷调查
    method: 'post',
    url: '/api/LhSourcingQuote/ConfirmQuote',
    data
  })

/**
 * @description 新增sourcing报价
 * @param {*} data
 * @returns
 */
export const sourcingAdd = (data) =>
  request({
    method: 'post',
    url: '/api/LhSourcing/Add',
    data
  })
