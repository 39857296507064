<template>
  <div class="connect">
    <el-dialog
      :visible="visible"
      width="1200px"
      title="Products Connection"
      custom-class="usadialog"
      @close="handleClose"
      top="8vh"
    >
      <div class="dialog-wrap">
        <div class="title">
          <div>Match your store products with USAdrop's to get the quotation immediately.</div>
          <div class="sub-title" v-if="type === 'view'">
            Here Are The Products That Have Been Mapped. If You Need To Modify It, Please Go To The
            [Waiting Connect] Page To Select The Product To Remap, Or Contact Your Salesperson.
          </div>
        </div>
        <div class="wrap">
          <div class="left">
            <div class="caption">Your Stores Product</div>
            <div class="list">
              <div
                class="list-inner"
                v-for="(item, index) in [...leftConnect, ...conLtData]"
                :key="index"
              >
                <div
                  class="card pointer"
                  :class="{
                    'is-active':
                      currentLt.ShopVariantId === item.ShopVariantId ||
                      leftConnect.map((item) => item.ShopVariantId).includes(item.ShopVariantId),
                    'is-gray':
                      JSON.stringify(currentLt) !== '{}' &&
                      ![
                        ...leftConnect.map((item) => item.ShopVariantId),
                        currentLt.ShopVariantId
                      ].includes(item.ShopVariantId)
                  }"
                  @click="handleLtClick(item)"
                >
                  <div class="card-img">
                    <el-popover
                      placement="right"
                      :open-delay="hoverDelayTime"
                      :width="hoverImgWidth"
                      trigger="hover"
                    >
                      <img :src="item.ShopVariantPic" style="width: 100%; height: 100%" />
                      <template slot="reference">
                        <img :src="item.ShopVariantPic" style="width: 100%; height: 100%" />
                      </template>
                    </el-popover>
                  </div>
                  <el-tooltip
                    effect="dark"
                    :open-delay="hoverDelayTime"
                    :content="item.ShopVariantName"
                    placement="top-start"
                  >
                    <div>
                      <span class="card-description">
                        {{ item.ShopVariantName }}
                      </span>
                    </div>
                  </el-tooltip>

                  <div class="card-info">
                    <el-tooltip
                      effect="dark"
                      :open-delay="hoverDelayTime"
                      :content="item.Sepecs.join(',')"
                      placement="top-start"
                    >
                      <div class="card-specs">{{ item.Sepecs.join(',') }}</div>
                    </el-tooltip>
                    <div class="card-connect">
                      <img
                        class="info-img"
                        src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924870248_connect.png"
                        alt=""
                      />
                      <span v-if="item.IsSumaiProcessed">Connected BY UD</span>
                      <template v-else>
                        <span v-if="item.ConnectedMallProduct">Connected</span>
                        <span v-else>Waiting Connect</span>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="card-price">${{ item.Price }}</div> -->
                </div>
                <div class="item-mask" v-if="item.IsSumaiProcessed" />
              </div>
            </div>
          </div>
          <div class="middle">
            <div v-for="(item, index) in hasConnect" :key="index" class="m-li">
              <img
                src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924920268_has-connect.png"
                alt=""
                @click="removeConnect(item, index)"
              />
            </div>
          </div>
          <div class="right">
            <div class="caption">USAdrop Product</div>
            <div class="list">
              <div
                class="list-inner"
                v-for="(item, index) in [...rightConnect, ...conRtData]"
                :key="index"
              >
                <div
                  class="card pointer"
                  :class="{
                    'is-active':
                      currentRt.MallVariantId === item.MallVariantId ||
                      rightConnect.map((item) => item.MallVariantId).includes(item.MallVariantId),
                    'is-gray':
                      JSON.stringify(currentRt) !== '{}' &&
                      ![
                        ...rightConnect.map((item) => item.MallVariantId),
                        currentRt.MallVariantId
                      ].includes(item.MallVariantId)
                  }"
                  :key="index"
                  @click="handleRtClick(item)"
                >
                  <div class="card-img">
                    <el-popover
                      placement="right"
                      :open-delay="hoverDelayTime"
                      :width="hoverImgWidth"
                      trigger="hover"
                    >
                      <img :src="item.MallVariantPic" style="width: 100%; height: 100%" />
                      <template slot="reference">
                        <img :src="item.MallVariantPic" style="width: 100%; height: 100%" />
                      </template>
                    </el-popover>
                  </div>
                  <el-tooltip
                    effect="dark"
                    :open-delay="hoverDelayTime"
                    :content="item.MallVariantWeight + ' kg'"
                    placement="top-start"
                  >
                    <div>
                      <!-- {{ item.MallVariantName }} -->
                      <span class="card-description"> {{ item.MallVariantWeight }} kg </span>
                    </div>
                  </el-tooltip>
                  <div class="card-info">
                    <el-tooltip
                      effect="dark"
                      :open-delay="hoverDelayTime"
                      :content="item.Sepecs.join(',')"
                      placement="top-start"
                    >
                      <div class="card-specs">{{ item.Sepecs.join(',') }}</div>
                    </el-tooltip>
                    <!-- <div>
                    <img class="info-img" src="@/assets/imgs/connect.png" alt="" />
                    <span>Waiting Connect</span>
                  </div> -->
                  </div>
                  <!-- <div class="card-price">${{ item.Price }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" v-if="type !== 'view'">
        <button class="cancelBtn wid200" @click="handleClose">Cancel</button>
        <button class="comBtn btn-bg0 wid200" @click="handleConfirm">Confirm</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ConnectDialog',
  props: {
    type: {
      type: String,
      default: 'common'
    },
    hoverDelayTime: {
      type: Number,
      default: 0
    },
    hoverImgWidth: {
      type: String,
      default: '200'
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      conLtData: [],
      conRtData: [],
      currentLt: {},
      currentRt: {},
      hasConnect: []
    }
  },
  components: {},
  created() {},
  mounted() {},
  computed: {
    ...mapState(['userInfo', 'theme']),
    rightConnect() {
      return this.hasConnect.map((item) => item.rightData)
    },
    leftConnect() {
      return this.hasConnect.map((item) => item.leftData)
    }
  },

  methods: {
    resetData() {
      this.currentLt = {}
      this.currentRt = {}
      this.conLtData = []
      this.conRtData = []
      this.hasConnect = []
    },
    handleClose() {
      this.resetData()
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$emit('confirm', this.hasConnect)
    },
    /* 列表点击事件 */
    handleLtClick(itemData) {
      if (this.currentLt.ShopVariantId === itemData.ShopVariantId) {
        this.currentLt = {}
        return
      }
      if (itemData.IsSumaiProcessed) {
        return
      }
      if (this.leftConnect.map((item) => item.ShopVariantId).includes(itemData.ShopVariantId)) {
        return
      }
      if (JSON.stringify(this.currentLt) === '{}') {
        this.currentLt = itemData
      }
      this.handleConnect()
    },
    handleRtClick(itemData) {
      if (this.currentRt.MallVariantId === itemData.MallVariantId) {
        this.currentRt = {}
        return
      }
      if (this.rightConnect.map((item) => item.MallVariantId).includes(itemData.MallVariantId)) {
        return
      }
      if (JSON.stringify(this.currentRt) === '{}') {
        this.currentRt = itemData
      }
      this.handleConnect()
    },
    handleConnect() {
      if (JSON.stringify(this.currentLt) !== '{}' && JSON.stringify(this.currentRt) !== '{}') {
        this.hasConnect.push({
          leftData: this.currentLt,
          rightData: this.currentRt
        })
        this.conLtData = this.conLtData.filter(
          (f) => f.ShopVariantId !== this.currentLt.ShopVariantId
        )
        this.conRtData = this.conRtData.filter(
          (f) => f.MallVariantId !== this.currentRt.MallVariantId
        )
        this.currentLt = {}
        this.currentRt = {}
      }
    },
    removeConnect(item, index) {
      if (this.type === 'view') {
        return
      }
      this.hasConnect.splice(index, 1)
      this.conLtData.unshift(item.leftData)
      this.conRtData.unshift(item.rightData)
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 16px;
}

.is-gray {
  opacity: 0.5;
  &:hover {
    border-color: transparent !important;
  }
}
.wrap {
  display: flex;
  max-height: 500px;
  box-sizing: border-box;
  margin-top: 24px;
  color: #000;
  .middle {
    width: 56px;
    padding-top: 40px;
    box-sizing: border-box;
    .m-li {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
      & + .m-li {
        margin-top: 8px;
      }
    }
  }
  .left,
  .right {
    flex: 1;
  }
  .caption {
    height: 40px;
    display: flex;
    background-color: #eff1f3;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: sticky;
    top: 0;
  }
}

.list {
  .list-inner {
    position: relative;
    & + .list-inner {
      margin-top: 8px;
    }
  }

  .item-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    border: 1px solid transparent;
  }
  .card {
    height: 90px;
    display: flex;
    padding: 8px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #eff1f3;
    &:hover {
      border-color: #3f6aff;
    }

    .card-img {
      width: 72px;
      height: 72px;
    }
    .card-description {
      width: 170px;
      margin: 10px;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: break-word;
      -webkit-line-clamp: 3;
      word-break: keep-all;
    }
    .card-info {
      width: 140px;
      margin: 10px 20px;
      box-sizing: border-box;

      .card-specs {
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-line-clamp: 1;
      }

      > div {
        + div {
          margin-top: 8px;
        }
      }
      .card-connect {
        display: flex;
        align-items: center;
        background: #dfe9ff;
      }
      .info-img {
        width: 16px;
        height: 16px;
      }
    }
    .card-price {
      margin-top: 10px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 24px;
    }
  }
  .is-active {
    border: 1px solid #3f6aff;
  }
}

.dialog-wrap {
  height: 580px;
  overflow: auto;
}

.sub-title {
  color: #999999;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  width: 870px;
  text-align: center;
  margin-top: 10px;
  word-break: keep-all;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .wid200 {
    width: 200px;
  }
  .cancelBtn {
    background-color: #fff;
    border-radius: 25px;
    color: #15104b;
    height: 40px;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    margin-right: 40px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
