var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-tips" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "tips-dialog",
          attrs: { visible: _vm.visible, top: "35vh" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "members_video" }, [
            _vm._v("This course is exclusive to members"),
          ]),
          _c("div", { staticClass: "become_video" }, [
            _vm._v("Become a member to watch the full video"),
          ]),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.jumpPage },
                },
                [_vm._v("Upgrade ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }