<template>
  <Validation :phoneNum="mobileNumber" :phoneCode="areaCode" @onSuccess="onSuccess"></Validation>
</template>
<script>
import Validation from "@/components/GraphicValidation/index.vue";
import {useSearch} from "@/utils/tools";

export default {
  name: "captcha",
  components:{Validation},
  data(){
    return{
      mobileNumber: '',
      areaCode: ''
    }
  },
  mounted(){
    const searchParams = new URLSearchParams(window.location.search);
    this.areaCode = searchParams.get('areaCode')
    this.mobileNumber = searchParams.get('mobile')
  },
  methods: {
    onSuccess() {
      window.parent.postMessage('closeCaptcha', '*')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
