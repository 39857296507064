var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "activeName" }, [
      _c("div", { staticClass: "header" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("SupportTicket.Feedback"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "container-layout" },
        [
          _c(
            "el-form",
            {
              ref: "submitFormRef",
              attrs: {
                "label-position": "top",
                model: _vm.formModel,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: _vm.$t("SupportTicket.OrderNumber") },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formModel.SalesRecord,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel, "SalesRecord", $$v)
                              },
                              expression: "formModel.SalesRecord",
                            },
                          }),
                          _c("span", { staticClass: "tracking-number" }, [
                            _vm._v(
                              _vm._s(_vm.$t("SupportTicket.TrackingNumber")) +
                                " :  " +
                                _vm._s(
                                  _vm.dataSource?.LogisticTransitNo || "-"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("SupportTicket.QuestionType"),
                            prop: "ProblemTypeCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "--Filter By Type Of Problem--",
                                filterable: "",
                                clearable: "",
                                disabled: _vm.formModel.CustomerOrderId != 0,
                              },
                              model: {
                                value: _vm.formModel.ProblemTypeCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModel,
                                    "ProblemTypeCode",
                                    $$v
                                  )
                                },
                                expression: "formModel.ProblemTypeCode",
                              },
                            },
                            _vm._l(_vm.afterProblemType, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formModel.ProblemTypeCode &&
              ![0, 1].includes(_vm.formModel.ProblemTypeCode)
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("SupportTicket.DealType"),
                        prop: "DealType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "calc(50% - 10px)" },
                          attrs: {
                            placeholder: "--Select The Appropriate--",
                            clearable: "",
                          },
                          model: {
                            value: _vm.formModel.DealType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "DealType", $$v)
                            },
                            expression: "formModel.DealType",
                          },
                        },
                        _vm._l(_vm.currentAfterDealType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("SupportTicket.ProblemDescription"),
                    prop: "Description",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      disabled:
                        _vm.formModel.CustomerOrderId != 0 &&
                        !_vm.afterSaleDetail?.LastProcessResult?.ReplyIsNull,
                    },
                    model: {
                      value: _vm.formModel.Description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "Description", $$v)
                      },
                      expression: "formModel.Description",
                    },
                  }),
                ],
                1
              ),
              [5, 7].includes(_vm.formModel.ProblemTypeCode) &&
              [1, 2].includes(_vm.formModel.DealType)
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("SupportTicket.ContactPostReason"),
                        prop: "ContactPostReason",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 2 },
                        model: {
                          value: _vm.formModel.ContactPostReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "ContactPostReason", $$v)
                          },
                          expression: "formModel.ContactPostReason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formModel.CustomerOrderId != 0 &&
              !_vm.afterSaleDetail?.LastProcessResult?.ReplyIsNull
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("SupportTicket.Reply"),
                        prop: "Reply",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 2 },
                        model: {
                          value: _vm.formModel.Reply,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "Reply", $$v)
                          },
                          expression: "formModel.Reply",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "keep-alive",
                [
                  _c(_vm.currentTemplate, {
                    ref: "templateRef",
                    tag: "component",
                    attrs: {
                      "data-source": _vm.dataSource,
                      "show-error-product": _vm.showErrorProduct,
                      "img-upload-path": _vm.formModel.TempPath,
                      "country-list": _vm.countryList,
                      "after-sale-detail": _vm.afterSaleDetail,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("FeedOrderDetail", {
            ref: "orderDetailRef",
            attrs: { "data-source": _vm.dataSource },
            scopedSlots: _vm._u([
              {
                key: "header_extra",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          round: "",
                          type: "primary",
                          loading: _vm.buttonLoading,
                        },
                        on: { click: _vm.onConfirm },
                      },
                      [_vm._v(" Confirm ")]
                    ),
                    _vm.formModel.CustomerOrderId != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              round: "",
                              plain: "",
                              loading: _vm.buttonLoading,
                            },
                            on: { click: _vm.onNoNeedProcess },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("SupportTicket.NoNeedProcess")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("FeedHistoryRecord", {
            attrs: { "order-id": _vm.formModel.OrderId },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }