var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-upload-container" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.action,
            headers: _vm.headers,
            "file-list": _vm.defaultFileList,
            data: {
              Action: _vm.imgUploadPath,
            },
            limit: _vm.uploadLimit,
            "show-file-list": false,
            "on-remove": _vm.handleRemove,
            "list-type": "picture",
            "on-progress": _vm.handleUploadProgress,
            "on-success": (res, file) => {
              _vm.handleUploadSuccess(res, file)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-container" },
            [
              _c(
                "div",
                {
                  staticClass: "title-view",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("SupportTicket.ImageUrl"))),
                  ]),
                  _vm.showExample
                    ? _c("i", {
                        staticClass: "el-icon-question info-icon",
                        on: {
                          click: function ($event) {
                            _vm.showImageExampleDialog = true
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "upload-button",
                  attrs: {
                    loading: _vm.uploading,
                    size: "small",
                    round: "",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.uploading
                        ? _vm.uploadPercent
                        : _vm.$t("SupportTicket.UploadFile")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "image-card" },
        _vm._l(_vm.uploadImages, function (item) {
          return _c(
            "div",
            {
              key: item.Url,
              staticClass: "image-container",
              on: {
                mouseover: function ($event) {
                  return _vm.handleMouseOver(item)
                },
                mouseleave: function ($event) {
                  return _vm.handleMouseLeave(item)
                },
              },
            },
            [
              _c("el-image", {
                staticClass: "img",
                attrs: { src: _vm.getUrl(item.Url) },
              }),
              !item.showActions
                ? _c("div", { staticClass: "actions" }, [
                    _c("i", {
                      staticClass: "el-icon-zoom-in icon-class",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePreview(item)
                        },
                      },
                    }),
                    !_vm.readonly
                      ? _c("i", {
                          staticClass: "el-icon-delete icon-class icon-space",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleRemove(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("SupportTicket.Example"),
            visible: _vm.showImageExampleDialog,
            width: "30%",
            "show-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImageExampleDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "example-image" },
            _vm._l(_vm.exampleImgs, function (src, index) {
              return _c("el-image", {
                key: index,
                staticClass: "img",
                attrs: { src: src, "preview-src-list": _vm.exampleImgs },
              })
            }),
            1
          ),
        ]
      ),
      _c("ImagePreviewDialog", {
        attrs: {
          "preview-src-list": [_vm.dialogImageUrl],
          "initial-index": _vm.currentPreviewIndex,
        },
        model: {
          value: _vm.showPreviewDialog,
          callback: function ($$v) {
            _vm.showPreviewDialog = $$v
          },
          expression: "showPreviewDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }