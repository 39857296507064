<template>
  <div class="page-box">
    <el-pagination
      :layout="layout"
      :page-sizes="[10, 20, 30, 40]"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
    <div class="page-sign"  @keyup.enter="jumpPageChange">
      <span @click="jumpPageChange">{{ $t("Winners.Get2") }}</span>
      <input type="text" v-model="goPageVal" @change="pageChangeVal"/>
    </div>
  </div>
</template>

<script>
export default {
  props:{
     layout:{
      default: "prev, pager, next",
      type: String
    },
    pageSize:{
       type: Number
    },
    total: {
      type: Number
    },
    currentPage:{
      type: Number
    },
  },
  data() {
    return {
      goPageVal:this.currentPage
    }
  },
  watch:{
    currentPage(n,o){
      this.goPageVal = n
    }
  },
  methods: {
    pageChangeVal(e) {
     let val = Number(e.target.value.replace(/[^\d]/g, ''))
     val = val || 1
      this.$emit('current-change',val)
    },
    pageChange(value) {
      this.$emit('current-change',value)
    },
    jumpPageChange() {
      this.$emit('current-change',this.goPageVal)
    },

  }
}
</script>
<style scoped lang="scss">
  .page-box {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    border-radius: 10px;
    .page-sign{
      height: 28px;
      line-height: 28px;
      color: #585858;
      font-size: 14px;
      float: right;
      padding-right: 35px;
      font-family: "Regular",sans-serif;
      -webkit-font-smoothing: antialiased;
      input {
        width: 45px;
        height: 28px;
        border: 1px solid #858585;
        border-radius: 8px;
        text-align: center;
        font-family: "Regular",serif;
        -webkit-font-smoothing: antialiased;
      }
      span {
        display: inline-block;
        cursor: pointer;
        padding-right: 15px;
        font-family: "Regular",sans-serif;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
</style>
