import icon from '@/assets/imgs/UpgradeToPro/WinnersSanJiao.png'

export const productTypeMap = {
  'Find Products': 0,
  Winner: 1,
  Top100: 2,
}

export const productPlatforms = {
  Amazon: {
    searchWord: 'AMAZON_HOT'
  },
  Aliexpress: {
    searchWord: 'ALI_EXPRESS_HOT'
  },
  Tiktok: {
    searchWord: 'TIKTOK_HOT'
  }
}

export const members = [
  {
    id: 0,
    list: 'All',
    img: require('@/assets/imgs/UpgradeToPro/AllWinners3.png'),
    PayTitle: 'Products',
    pathName: 'FindProducts'
  },
  {
    id: 1,
    list: 'This',
    img: require('@/assets/imgs/UpgradeToPro/LastWinners3.png'),
    PayTitle: 'Winner',
    PayName: "Products",
    pathName: 'Winner'
  },
  {
    id: 2,
    list: "This'",
    img: require('@/assets/imgs/UpgradeToPro/ThisWinners3.png'),
    PayTitle: 'Top 100',
    PayName: "Products",
    pathName: 'Top100'
  }
]

// pro会员模糊数据
export const fuzzyProductList = [
  {
    id: 0,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang1.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 963,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 963
  },
  {
    id: 1,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang2.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 896,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 896
  },
  {
    id: 2,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang3.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 999,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 999
  },
  {
    id: 3,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang4.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 536,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 536
  },
  {
    id: 4,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang5.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 654,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 654
  },
  {
    id: 5,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang6.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 456,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true,
    Lists: 456
  },
  {
    id: 6,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang7.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 693,
    isFuzzy: true,
    cardSubTitle: 'Weekly Fulfilled',
    Lists: 693
  },
  {
    id: 7,
    mainImage: require('@/assets/imgs/WinnerProduct/zhongjiandang8.jpg'),
    ProductName: "This Month's Winner",
    cardIcon: icon,
    TotalSold: 693,
    isFuzzy: true,
    cardSubTitle: 'Weekly Fulfilled',
    Lists: 693
  }
]

export const seniorProductList = [
  {
    id: 0,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji1.jpg'),
    ProductName: "This Week's Winner",
    cardBy: 'By USAdrop',
    cardIcon: icon,
    TotalSold: 365,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 1,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji2.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 489,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 2,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji3.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 688,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 3,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji4.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 798,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 4,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji5.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 555,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 5,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji6.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 741,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 6,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji7.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 888,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  },
  {
    id: 7,
    MainPic: require('@/assets/imgs/WinnerProduct/gaoji8.jpg'),
    ProductName: "This Week's Winner",
    cardIcon: icon,
    TotalSold: 651,
    cardSubTitle: 'Weekly Fulfilled',
    isFuzzy: true
  }
]

// plus会员模糊数据
export const defaultProductList = [
  {
    id: 0,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji1.jpg'),
    ProductName: 'Conjunto Camisa e Saia de cetim Berlin Conjunto Camisa e Saia de',
    Lists: 543
  },
  {
    id: 1,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji2.jpg'),
    ProductName: "This Week's Winner",
    Lists: 234
  },
  {
    id: 2,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji3.jpg'),
    ProductName: 'Conjunto Camisa e Saia de cetim Berlin Conjunto Camisa e Saia de',
    Lists: 657
  },
  {
    id: 3,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji4.jpg'),
    ProductName: "This Week's Winner",
    Lists: 987
  },
  {
    id: 4,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji5.jpg'),
    ProductName:
      'Conjunto Camisa e Saia de cetim Berlin Conjunto Camisa e Saia de Conjunto Camisa e Saia de cetim Berlin Conjunto Camisa e Saia de',
    Lists: 652
  },
  {
    id: 5,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji6.jpg'),
    ProductName: "This Week's Winner",
    Lists: 340
  },
  {
    id: 6,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji7.jpg'),
    ProductName: 'Conjunto Camisa e Saia de cetim Berlin',
    Lists: 1636
  },
  {
    id: 7,
    mainImage: require('@/assets/imgs/WinnerProduct/gaoji8.jpg'),
    ProductName: "This Week's Winner",
    Lists: 987
  }
]
