var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-login" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "login-content" },
      [
        _c("img", {
          staticClass: "logo2",
          attrs: {
            src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580690869_logo.svg",
            alt: "",
          },
        }),
        _c("p", { staticClass: "login-head" }, [
          _vm._v("Welcome to USAdrop Quickfund"),
        ]),
        _c(
          "el-form",
          {
            ref: "formRef",
            attrs: {
              "label-position": "top",
              rules: _vm.rules,
              model: _vm.formData,
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "E-mail", prop: "username" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.formData.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "username", $$v)
                    },
                    expression: "formData.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "Password", prop: "password" },
              },
              [
                _c("el-input", {
                  attrs: { "show-password": true },
                  model: {
                    value: _vm.formData.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "password", $$v)
                    },
                    expression: "formData.password",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "login-bottom" }, [
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.signIn("formRef")
                        },
                      },
                    },
                    [_vm._v("Sign In")]
                  ),
                  _c("div", { staticClass: "small" }, [
                    _vm._v(" Don't have an account? "),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#3f6aff" },
                        on: { click: _vm.goRegister },
                      },
                      [_vm._v("Sign Up Here")]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580690869_logo.svg",
          alt: "",
        },
      }),
      _c("div", { staticClass: "welcome-title" }, [
        _c("div", { staticClass: "ft-56 fw-800 color2" }, [
          _vm._v("Welcome to"),
        ]),
        _c("div", { staticClass: "ft-56 fw-800 color1 mb-40" }, [
          _vm._v("USAdrop Shopify Sales"),
        ]),
        _c("div", { staticClass: "ft-24 fw-80s0 color2 mb-40" }, [
          _vm._v("Partner with USAdrop for Unprecedented Growth."),
        ]),
        _c("div", { staticClass: "ft-40 fw-800 color2 mb-48" }, [
          _vm._v("Expand Your Shopify Saleswith USAdrop!"),
        ]),
        _c("div", { staticClass: "btnContent" }, [
          _c("div", { staticClass: "btnInfo" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Financial Backing")]),
            _c("div", { staticClass: "word" }, [
              _vm._v(
                " Enjoy robust startup funds from the very beginning, ensuring worry-free business initiation. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "btnInfo" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Order Fulfillment")]),
            _c("div", { staticClass: "word" }, [
              _vm._v(
                " Our efficient order fulfillment system guarantees timely delivery of your products to customers "
              ),
            ]),
          ]),
          _c("div", { staticClass: "btnInfo" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Payment Gateway")]),
            _c("div", { staticClass: "word" }, [
              _vm._v(
                " Secure and reliable payment gateways covering a variety of payment methods, offering compre-hensive support for your business "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }