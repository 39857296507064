<template>
  <div class="ad-login">
    <div class="login-info">
      <img :src="logoImg[formLink]" alt="" class="logo1" />
      <div class="welcome-title">
        <div class="ft-40 fw-800 color2">Launch Your</div>
        <div class="ft-40 fw-800 color1 mb-24">7-Figure Dropshipping</div>
        <div class="ft-40 fw-800 color1 mb-24">Journey Today!</div>
        <div class="ft-32 fw-700 color2 mb-48">Master the essentials to build a profitable online store.</div>
        <div class="btnContent">
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Building Your Store</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Finding Winning Products</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Advertising Strategies</div>
          </div>
          <div class="btnInfo">
            <div class="content-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="content-words">Leveraging USAdrop</div>
          </div>
        </div>
        <div class="ft-28 color2 mb-48 fw-600">
          Unlock everything you need to succeed with USAdrop.
        </div>
      </div>
    </div>
    <div class="login-content">
      <img :src="logoImgOne[formLink]" alt="" class="logo2" />
      <p class="login-head">Welcome to USAdrop</p>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        class="demo-ruleForm">
        <el-form-item :label="$t('login.email')" prop="email" class="option">
          <el-input size="large" v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.password')" prop="password" class="option">
          <el-input
            size="large"
            type="password"
            v-model="ruleForm.password"
            show-password
          ></el-input>
        </el-form-item>
        <div class="forget">
          <p class="" @click="forgetTap">{{ $t('login.forget') }}</p>
        </div>
        <el-form-item>
          <div class="loginbox">
            <div class="submit btn-bg0" @click="signIn(ruleForm)">
              {{ $t('login.gobtn') }}
            </div>
            <p class="noaccount mt10">
              {{ $t('login.sign') }}
              <span class="col-col5" style="cursor: pointer;" @click="toRegist">
                {{ $t('login.here') }}
              </span>
            </p>
          </div>
        </el-form-item>
        </el-form>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { payList, rules } from '../constants'
import { removeToken, setToken } from '@/utils/token'
import { getFrom, searchUrlParams } from '@/utils/tools'
import { clearTTInfo, getTTSK, isTTLogin } from '@/utils/tthelper'

export default {
  data: () => {
    return {
      name: 'login',
      logoImgOne: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoThree.png'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/SliceLoginMlogoXiao.png')
      },
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom(),
      type: 1,
      paymentList: [], // 会员等级
      ruleForm: {
        email: '',
        password: ''
      },
      rememberMe: false,
      rules,
      payList
    }
  },

  computed: mapState({
    login: (state) => state.login
  }),

  created() {
    const { dispatch } = this.$store // 获取授权时获取的state code
    const search = decodeURIComponent(location.search)
    const { MarketingCode, code, state, scope, CofunderCode } = searchUrlParams(search)
    const MarketingCodeLocal = localStorage.getItem('MarketingCode')
    const CofunderCodeLocal = localStorage.getItem('CofunderCode')
    if (MarketingCode) {
      localStorage.setItem('MarketingCode', MarketingCode)
      MarketingCodeLocal && (window.location.href = 'https://www.usadrop.com')
    } else if (CofunderCode) {
      localStorage.setItem('CofunderCode', CofunderCode)
      CofunderCodeLocal && (window.location.href = 'https://www.usadrop.com')
    } else if (code && state) {
      dispatch('login/getAuthBack', {
        Code: code,
        State: state
      }).then((res) => {
        const { Success, Result, ResData } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
          return
        }
        if (scope && scope.indexOf('google') > 0) {
          this.$message.error(this.$t('login.GoogleRegistration'))
          return
        }
        if (ResData) {
          const { UUId } = ResData
          this.$store.commit('setAuthInfo', ResData)
          UUId && this.oauthRegist(UUId)
        }
      })
    }
  },

  mounted() {
    const savedEmail = localStorage.getItem('email')
    const savedPassword = localStorage.getItem('password')
    if (savedEmail && savedPassword) {
      this.ruleForm.email = savedEmail
      this.ruleForm.password = savedPassword
      this.rememberMe = true
    }
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    handleTikTokBusiness(data) {
      const ttsk = getTTSK()
      if (ttsk !== undefined && ttsk !== '') {
        data.TiktokShopTokenKey = ttsk
      }
    },
    checkType(type) {
      this.type = type
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.signIn() // 需要执行的方法方法
      }
    },
    signIn() {
      removeToken() // 登录前清除过期token
      if (this.rememberMe) {
        localStorage.setItem('email', this.ruleForm.email)
        localStorage.setItem('password', this.ruleForm.password)
      } else {
        // 如果没有选择“记住我”，则清除本地存储中的用户名和密码信息
        localStorage.removeItem('email')
        localStorage.removeItem('password')
      }
      const {
        dispatch,
        state: { storeKey }
      } = this.$store
      const {
        ruleForm: { email, password }
      } = this
      if (!email || !password) return

      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      var data = { email, pwd: password }
      this.handleTikTokBusiness(data)
      storeKey && (data.ShopifyTokenKey = this.$store.state.storeKey)
      console.log('登录参数:', data)
      dispatch('login/getLogin', data).then((res) => {
        const { Success } = res || {}
        if (Success) {
          setToken(res.Result)
          this.$store.commit('setStoreKey', '')
          this.getUserInfo()
          this.menterData()
        } else {
          this.$message(res.ErrMsg || vm.$t('tips.failed'))
        }
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },
    getUserInfo() {
      // 获取用户信息
      const { dispatch } = this.$store
      dispatch('login/getUserInfo').then((res) => {
        const { Success } = res || {}
        if (Success) {
          this.$store.commit('setUer', res.Result)
          this.CheckGuide()
        }
      })
    },
    CheckGuide() {
      const { dispatch } = this.$store
      dispatch('login/getCheckGuide')
        .then((res) => {
          const { Success } = res || {}
          if (Success && res?.Result) {
            const {
              Result: { IsProfileComplete, IsStoreComplete }
            } = res
            if (!IsProfileComplete) {
              this.$router.push('StepOne')
              return
            }
            if (!IsStoreComplete) {
              this.$router.push('StepTwo')
              return
            }
            if (IsProfileComplete && IsStoreComplete) {
              if (isTTLogin()) {
                window.location.href = `${window.location.origin}/Store`
              } else {
                window.location.href = `${window.location.origin}/orders`
              }
            }
          }
        })
        .finally(() => {
          clearTTInfo()
        })
    },
    menterData() {
      /* 会员等级 */
      const { dispatch } = this.$store
      dispatch('login/getPaymentList').then((res) => {
        const { Result, Success } = res || {}
        if (Success && Result) {
          this.paymentList = res.Result
        }
      })
    },
    toRegist() {
      // 去注册
      this.$router.push({
        path: 'register',
        query: {
          from: 'acadamy'
        }
      })
    },
    forgetTap() {
      // 忘记密码
      this.$router.push('password')
    },
    otherRegist(type) {
      // 其他注册方式
      const { dispatch } = this.$store
      var data = { authPlatform: type }
      this.handleTikTokBusiness(data)
      dispatch('login/getAuthorization', data).then((res) => res && (window.location.href = res))
    },
    oauthRegist(UUId) {
      const { dispatch } = this.$store
      const MarketingCode = window.localStorage.getItem('MarketingCode')
      dispatch('login/getAuthRegister', { UUId, MarketingCode }).then((res = {}) => {
        const { Success, Result } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580863839_loginBg.png')
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  .login-info {
    margin-right: 80px;
    text-align: left;
    margin-top: -6%;
    .logo1 {
      width: 215px;
      height: 150px;
      margin-bottom: 40px;
    }
    .btnContent {
      display: flex;
      width: 800px;
      flex-wrap: wrap;
      justify-content: space-between;
      .btnInfo {
        width: 400px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        .content-icon {
          width: 40px;
          height: 40px;
          background: $color2;
          ::v-deep .el-icon-check{
            font-size: 26px;
            color: #fff;
            line-height: 40px;
            margin-left: 7px;
            font-weight: 800;
          }
        }
        .content-words {
          font-size: 24px;
          font-weight: 600;
          margin-left: 20px;
        }
      }
    }
  }
  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .logo2 {
      width: 100px;
      height: 85px;
    }
    .login-head {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      text-align: center;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        border-radius: 50px;
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
      }
    }
    .forget {
      width: 100%;
      text-align: right;
      color: #3f6aff !important;
      font-size: 14px;
      cursor: pointer;
    }
    .submit {
      width: 225px;
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      color: #fff;
      background: #474882;
      text-align: center;
      border-radius: 50px;
      margin-top: 60px;
      margin-left: 100px;
      &:hover {
        background: #3c3772 !important;
        color: #fff;
      }
    }
    .noaccount {
      margin-top: 15px;
      margin-left: 80px;
    }
  }
}
</style>
