var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "connect" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            width: "1200px",
            title: "Products Connection",
            "custom-class": "usadialog",
            top: "8vh",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c("div", { staticClass: "dialog-wrap" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", [
                _vm._v(
                  "Match your store products with USAdrop's to get the quotation immediately."
                ),
              ]),
              _vm.type === "view"
                ? _c("div", { staticClass: "sub-title" }, [
                    _vm._v(
                      " Here Are The Products That Have Been Mapped. If You Need To Modify It, Please Go To The [Waiting Connect] Page To Select The Product To Remap, Or Contact Your Salesperson. "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "wrap" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "caption" }, [
                  _vm._v("Your Stores Product"),
                ]),
                _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(
                    [..._vm.leftConnect, ..._vm.conLtData],
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list-inner" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card pointer",
                              class: {
                                "is-active":
                                  _vm.currentLt.ShopVariantId ===
                                    item.ShopVariantId ||
                                  _vm.leftConnect
                                    .map((item) => item.ShopVariantId)
                                    .includes(item.ShopVariantId),
                                "is-gray":
                                  JSON.stringify(_vm.currentLt) !== "{}" &&
                                  ![
                                    ..._vm.leftConnect.map(
                                      (item) => item.ShopVariantId
                                    ),
                                    _vm.currentLt.ShopVariantId,
                                  ].includes(item.ShopVariantId),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLtClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "card-img" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        "open-delay": _vm.hoverDelayTime,
                                        width: _vm.hoverImgWidth,
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.ShopVariantPic },
                                      }),
                                      _c("template", { slot: "reference" }, [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          attrs: { src: item.ShopVariantPic },
                                        }),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    "open-delay": _vm.hoverDelayTime,
                                    content: item.ShopVariantName,
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.ShopVariantName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "card-info" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        "open-delay": _vm.hoverDelayTime,
                                        content: item.Sepecs.join(","),
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card-specs" }, [
                                        _vm._v(_vm._s(item.Sepecs.join(","))),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "card-connect" },
                                    [
                                      _c("img", {
                                        staticClass: "info-img",
                                        attrs: {
                                          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924870248_connect.png",
                                          alt: "",
                                        },
                                      }),
                                      item.IsSumaiProcessed
                                        ? _c("span", [
                                            _vm._v("Connected BY UD"),
                                          ])
                                        : [
                                            item.ConnectedMallProduct
                                              ? _c("span", [
                                                  _vm._v("Connected"),
                                                ])
                                              : _c("span", [
                                                  _vm._v("Waiting Connect"),
                                                ]),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          item.IsSumaiProcessed
                            ? _c("div", { staticClass: "item-mask" })
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _c(
                "div",
                { staticClass: "middle" },
                _vm._l(_vm.hasConnect, function (item, index) {
                  return _c("div", { key: index, staticClass: "m-li" }, [
                    _c("img", {
                      attrs: {
                        src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715924920268_has-connect.png",
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeConnect(item, index)
                        },
                      },
                    }),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "caption" }, [
                  _vm._v("USAdrop Product"),
                ]),
                _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(
                    [..._vm.rightConnect, ..._vm.conRtData],
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list-inner" },
                        [
                          _c(
                            "div",
                            {
                              key: index,
                              staticClass: "card pointer",
                              class: {
                                "is-active":
                                  _vm.currentRt.MallVariantId ===
                                    item.MallVariantId ||
                                  _vm.rightConnect
                                    .map((item) => item.MallVariantId)
                                    .includes(item.MallVariantId),
                                "is-gray":
                                  JSON.stringify(_vm.currentRt) !== "{}" &&
                                  ![
                                    ..._vm.rightConnect.map(
                                      (item) => item.MallVariantId
                                    ),
                                    _vm.currentRt.MallVariantId,
                                  ].includes(item.MallVariantId),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRtClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "card-img" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        "open-delay": _vm.hoverDelayTime,
                                        width: _vm.hoverImgWidth,
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.MallVariantPic },
                                      }),
                                      _c("template", { slot: "reference" }, [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          attrs: { src: item.MallVariantPic },
                                        }),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    "open-delay": _vm.hoverDelayTime,
                                    content: item.MallVariantWeight + " kg",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "card-description" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.MallVariantWeight) +
                                            " kg "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "card-info" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        "open-delay": _vm.hoverDelayTime,
                                        content: item.Sepecs.join(","),
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card-specs" }, [
                                        _vm._v(_vm._s(item.Sepecs.join(","))),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
          ]),
          _vm.type !== "view"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "cancelBtn wid200",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "comBtn btn-bg0 wid200",
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v("Confirm")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }