var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "particulars" },
    [
      _c(
        "div",
        { staticClass: "active_name" },
        [
          _c("H1", { staticClass: "title" }, [
            _vm._v("Welcome To USAdrop Academy"),
          ]),
          _c(
            "div",
            { staticClass: "banner" },
            [
              _c("el-image", {
                staticClass: "banner-img",
                attrs: {
                  src: "https://file.securepago.com/ud/1731044475571_a7aa645d-193b-45f9-bbef-b540c764d6cd.jpg",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "category" },
            _vm._l(_vm.cateList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.Id,
                  staticClass: "category-item",
                  class: _vm.activeTab === item.Id ? "active-tab" : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.Name) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.videoList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "content-item",
                  on: {
                    click: function ($event) {
                      return _vm.videDetail(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "box_video" }, [
                      _c("img", { attrs: { src: item.CoverUrl, alt: "" } }),
                    ]),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(item.Name) + " "),
                    ]),
                    item.Process == 0
                      ? _c("div", { staticClass: "group" }, [
                          _c("p", [_vm._v("Watch Now")]),
                        ])
                      : _c(
                          "div",
                          [
                            _c("el-progress", {
                              attrs: {
                                percentage: item.Process,
                                color: _vm.customColor,
                                "stroke-width": 20,
                              },
                            }),
                            _c("div", { staticClass: "progress-label" }, [
                              _vm._v(" " + _vm._s(item.Process) + "% "),
                              _c("span", [_vm._v("Complete")]),
                            ]),
                          ],
                          1
                        ),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("SubscribeNowDialog", {
        attrs: {
          visible: _vm.upgradeVisible,
          title: "Get Pro Plan for more features",
        },
        on: {
          "update:visible": function ($event) {
            _vm.upgradeVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }