var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sense-name-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "46%",
            visible: _vm.showDialogVisible,
            title: "Sense Setting",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { round: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { round: "", type: "primary" },
                          on: { click: _vm.confirmSense },
                        },
                        [_vm._v("Confirm")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                "label-position": "right",
                "label-width": "120px",
                model: _vm.currentSenseForm,
              },
            },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Sense Name settings", name: "1" } },
                    _vm._l(
                      _vm.currentSenseForm.options,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.id,
                            attrs: {
                              label: `Sense${index + 1} Name`,
                              prop: "options." + index + ".name",
                              rules: [
                                {
                                  required: true,
                                  message: "Please input Sense Name!",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "formInput",
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: "Sense Option basic info settings",
                        name: "3",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Sense Title", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "formInput",
                            model: {
                              value: _vm.currentSenseForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "title", $$v)
                              },
                              expression: "currentSenseForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tooltip" } },
                        [
                          _c("el-input", {
                            staticClass: "formInput",
                            model: {
                              value: _vm.currentSenseForm.toolTip,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "toolTip", $$v)
                              },
                              expression: "currentSenseForm.toolTip",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Help text" } },
                        [
                          _c("el-input", {
                            staticClass: "formInput",
                            model: {
                              value: _vm.currentSenseForm.helpTxt,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "helpTxt", $$v)
                              },
                              expression: "currentSenseForm.helpTxt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Popup tip" } },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.currentSenseForm.showPopupTip,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentSenseForm,
                                  "showPopupTip",
                                  $$v
                                )
                              },
                              expression: "currentSenseForm.showPopupTip",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Title" } },
                        [
                          _c("el-input", {
                            staticClass: "formInput",
                            model: {
                              value: _vm.currentSenseForm.popTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "popTitle", $$v)
                              },
                              expression: "currentSenseForm.popTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Width" } },
                        [
                          _c("el-input", {
                            staticClass: "formInput",
                            model: {
                              value: _vm.currentSenseForm.popWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "popWidth", $$v)
                              },
                              expression: "currentSenseForm.popWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "fuwenben" },
                        [
                          _c("wangEnduit", {
                            attrs: { boxId: "boxSense" },
                            model: {
                              value: _vm.currentSenseForm.richText,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentSenseForm, "richText", $$v)
                              },
                              expression: "currentSenseForm.richText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }