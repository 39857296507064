export const SupportTicketDict = {
  afterProblemType: [
    {
      label: 'Modify The Related Order Information Of Customer',
      value: 0,
      remark: '修改客户订单相关信息'
    },
    {
      label: 'Cancel Order',
      value: 1,
      remark: '取消订单'
    },
    {
      label: 'No Tracking Information',
      value: 2,
      remark: '查询不到物流信息，无跟踪号'
    },
    {
      label: 'The Tracking Information Has Not Been Updated For A Long Time',
      value: 3,
      remark: '物流信息长时间未更新'
    },
    {
      label: 'Item Has Arrived At Destination Country But Not Delivered',
      value: 5,
      remark: '已到目的地未妥投'
    },
    {
      label: 'Sent To The Wrong Address',
      value: 6,
      remark: '发错国家'
    },
    {
      label: 'Returned In Transit',
      value: 7,
      remark: '退回'
    },
    {
      label: 'Item Damaged',
      value: 9,
      remark: '货物破损'
    },
    {
      label: 'Item Missing',
      value: 10,
      remark: '少发'
    },
    {
      label: 'Sent The Wrong Product',
      value: 11,
      remark: '发错产品'
    },
    {
      label: 'Returned By Customer',
      value: 13,
      remark: '退款退货'
    }
  ],
  afterDealType: [
    {
      label: 'Refund',
      value: 1,
      remark: '退款退货'
    },
    {
      label: 'Replacement',
      value: 2,
      remark: '补发'
    },
    {
      label: "Enquire About The Order's Status",
      value: 3,
      remark: '订单咨询'
    }
  ]
}

//售后状态
export const ProcessingStatus = {
  //待处理
  WaitProcessing: 0,
  //待确认
  WaitConfirmed: 1,
  // 已处理
  Confirmed: 2,
  // 已补发
  Reissued: 3,
  //待客户处理
  PendingCustomerProcessing: 4,
  //预处理待审核
  PreProcessingPendingReview: 10,
  //预处理审核不通过
  PreProcessingReviewFailed: 11
}
