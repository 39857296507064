<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageCropDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    file: {
      type: File,
      default: null
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    outputSize: {
      type: Object,
      default() {
        return { width: 400, height: 400 }
      }
    }
  },
  data() {
    return {
      showCropDialog: false,
      image: '',
      cropper: null
    }
  },
  watch: {
    value(n, o) {
      if (n) {
        this.initCropper()
      }
      this.showCropDialog = n
    },
    showCropDialog(n, o) {
      if (!n) {
        this.cropper?.destroy()
        this.cropper = null
        this.image = ''
      }
      this.$emit('input', n)
    }
  },
  methods: {
    initCropper() {
      if (!this.file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result
        this.$nextTick(() => {
          if (this.$refs.cropperImage) {
            this.cropper = new Cropper(this.$refs.cropperImage, {
              aspectRatio: this.aspectRatio,
              data: {
                width: 400 * this.aspectRatio,
                height: 400
              },
              minCropBoxWidth: 400 * this.aspectRatio,
              minCropBoxHeight: 400,
              viewMode: 0
            })
          }
        })
      }
      reader.readAsDataURL(this.file)
    },
    onCrop() {
      try {
        const croppedCanvas = this.cropper?.getCroppedCanvas({
          minWidth: this.outputSize.width,
          minHeight: this.outputSize.height,
          imageSmoothingQuality: 'high'
        })
        croppedCanvas?.toBlob((blob) => {
          this.$emit('onCropCallback', new File([blob], this.file.name, { type: this.file.type }))
          this.showCropDialog = false
        }, 'image/png')
      } catch (e) {
        this.$emit('onCropCallback', this.file)
        this.showCropDialog = false
      }
    },
    // 缩小
    onZoomOut() {
      if (this.cropper) {
        this.cropper.zoom(-0.1)
      }
    },

    // 放大
    onZoomIn() {
      if (this.cropper) {
        this.cropper.zoom(0.1)
      }
    },

    // 重置
    onReset() {
      if (this.cropper) {
        this.cropper.reset()
      }
    },
    emitUpdateVisible(value) {
      this.$emit('update:visible', value)
    },
    emitCropCallback(data) {
      this.$emit('onCropCallback', data)
    }
  }
}
</script>

<template>
  <el-dialog
    :visible.sync="showCropDialog"
    title="Crop Image"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="modal-body">
      <div class="cropper-container">
        <img v-show="image" ref="cropperImage" :src="image" alt="Image for cropping" />
      </div>
      <div class="zoom-container">
        <el-button-group class="ml-2" size="small">
          <el-button round @click="onZoomOut"><i class="el-icon-zoom-out"></i></el-button>
          <el-button round @click="onZoomIn"><i class="el-icon-zoom-in"></i></el-button>
          <el-button round @click="onReset"><i class="el-icon-refresh-left"></i></el-button>
        </el-button-group>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showCropDialog = false">Cancel</el-button>
        <el-button type="primary" @click="onCrop"> Crop </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: relative;
  z-index: 99999;
  .cropper-container {
    display: block;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .zoom-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
