<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
        <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('myCard.card')" name="first">
          <template v-if="CardList && CardList.length > 0">
            <el-table :data="CardList" style="width: 100%">
              <el-table-column prop="EndDate" align="center" :label="$t('myCard.date')">
              </el-table-column>
              <el-table-column prop="Quantity" align="center" :label="$t('myCard.quantity')">
              </el-table-column>
              <el-table-column prop="DaysRemaining" align="center" :label="$t('myCard.days')">
              </el-table-column>
              <el-table-column prop="IsValid" align="center" :label="$t('myCard.coupon')"  :formatter="formatBoolean" :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
          </template>
          <el-empty description="No data" v-else></el-empty>
        </el-tab-pane>
        </el-tabs>
        <!-- 分页 -->
        <div class="pagebox">
          <PagePagination
            :total="options.total"
            :page-size="options.pageSize"
            :current-page="options.page"
            @current-change="pageChange" />
        </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PagePagination from '@/components/PagePagination.vue';
export default {
  data: () => {
    return {
      dispatch: null,
      page: "myCard",
      activeName: "first",
      currentPage: 1,
      CardList: [],
      value: "",
      options: 
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        },
    };
  },
  components: { PagePagination
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatBoolean: function(row,index) {
      var ret = ''
      if(row.IsValid == true) {
        ret = "Active"
      } else {
        ret = "Expired"
      }
      return ret;
    },
    pageChange(page) {  // 分页器页数改变
      this.options.page = page;
      this.getData();
    },
    getPageList() {  // 分页器跳转页数
      this.options.page = this.options.jumpPage > 0 ? Number(this.options.jumpPage) : 1;
      this.getData();
    },
    getData() {
      let that = this;
      that.dispatch('card/GetCardListCd', {
        Filter: {
          MemberId: 0
        },
        CurrentPageIndex: that.options.page,
        PageSize: that.options.pageSize,
        RecordCount: that.options.list.length
      })
      .then(res => {
        that.CardList = res.Items;
        this.options.total = res.TotalItems
      })
      .catch(err => {
        console.log(err)
      })
    }
  },
};
</script>
<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #6788ff;
  font-family: "Regular";
  font-weight: 500;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}
::v-deep .el-tabs__header {
    margin: 0;
}
::v-deep .el-tabs__nav-wrap::after {
    background: #eff1f3;
}
::v-deep .el-pager li.active {
  font-family: "Regular";
}
::v-deep .el-table th,
.el-table tr {
  height: 64px;
  font-weight: 500;
}
::v-deep .el-table__row {
  height: 86px !important;
}
::v-deep .el-table thead { /* 头部颜色 */
  font-size: 18px;
  font-family: "Regular";
  font-weight: 500;
  color: rgb(0, 0, 0, .8);
}
/* 列表字体颜色 */
::v-deep .el-table .cell {
  font-size: 14px;
  font-family: "Regular";
  font-weight: 400;
  color: rgb(0, 0, 0, .8);
}
/* 分页 */
.pagebox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
}
</style>
