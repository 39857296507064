var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c(
        "div",
        { staticClass: "activeName" },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { "padding-bottom": "10px" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t(""), name: "first" } },
                [
                  _c(
                    "div",
                    { staticClass: "bigbox" },
                    _vm._l(_vm.options, function (item) {
                      return _c(
                        "div",
                        { key: item.index, staticClass: "box" },
                        [
                          _c("iframe", {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              src: item.VideoPath,
                              title: "YouTube video player",
                              frameborder: "0",
                              allow:
                                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                              allowfullscreen: "",
                            },
                          }),
                          _c("div", { staticClass: "synopsis" }, [
                            _vm._v(_vm._s(item.Title)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagebox" },
        [
          _c("PagePagination", {
            attrs: {
              total: _vm.options1.total,
              "page-size": _vm.options1.pageSize,
              "current-page": _vm.options1.page,
            },
            on: { "current-change": _vm.pageChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }