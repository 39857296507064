var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("SupportTicket.OrderDetail"))),
        ]),
        _vm._t("header_extra"),
      ],
      2
    ),
    _c("div", { staticClass: "order-item-list" }, [
      _c(
        "div",
        { staticClass: "left-product" },
        _vm._l(_vm.dataSource?.OrderDetails, function (item) {
          return _c(
            "div",
            { key: item.OrderDetailId, staticClass: "product-item" },
            [
              _c("img", { attrs: { src: item.ProductPictureUrl } }),
              _c("div", { staticClass: "product-info" }, [
                _c("span", { staticClass: "product-name" }, [
                  _vm._v(
                    _vm._s(
                      item.IsExclude ? item.ProductName : item.ProductRealSku
                    )
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "product-desc",
                    class: { exclude: item.IsExclude },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(item.Num) +
                        "*$" +
                        _vm._s(item?.QuotedPrice || "0.00") +
                        " "
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "right-view" }, [
        _c("span", [
          _vm._v("$" + _vm._s(_vm.dataSource?.QuotedPrice || "0.00")),
        ]),
        _c("span", { staticStyle: { "text-align": "center" } }, [
          _vm._v(" " + _vm._s(_vm.dataSource?.Contact || "-") + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.dataSource?.CountryCode || "-") + " "),
        ]),
        _c("span", { staticStyle: { "text-align": "center" } }, [
          _vm._v(" " + _vm._s(_vm.dataSource?.LogisticTransitNo || "-") + " "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }