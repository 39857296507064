<template>
  <div class="payWay">
    <el-dialog :visible.sync="_dialogVisible" custom-class="payDialog">
      <div class="payContent">
        <div class="content">
          <div class="title">Shopify user</div>
          <img src="@/assets/imgs/member_shopify_icon.png" alt="" />
          <div class="button" @click="Go('shopify')">
            <span>GO <i class="el-icon-right"></i></span>
          </div>
        </div>
        <div class="diver"></div>
        <div class="content">
          <div class="title">Non-Shopify user</div>
          <img class="twoIcon" src="@/assets/imgs/member_other_icon1.png" alt="" />
          <div class="button" @click="Go('other')">
            <span>GO <i class="el-icon-right"></i></span>
          </div>
        </div>
      </div>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    _dialogVisible: {
      get() {
        return this.dialogVisible
      },
      set(val) {
        this.$emit('update:dialogVisible', val)
      }
    }
  },
  methods: {
    Go(type) {
      this.$emit('goUpgrade', type)
    }
  }
}
</script>
<style lang="scss" scoped>
$color: #15104b;
.payWay {
  ::v-deep .payDialog {
    width: 720px;
    border-radius: 20px;
    .el-dialog__body {
      padding: 0;
    }
    .payContent {
      padding: 28px 30px 20px;
      font-size: 24px;
      color: $color;
      display: flex;
      width: 100%;
      .content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .title {
          height: auto;
        }
        img {
          width: 64px;
          height: 64px;
          margin: 40px 0;
        }
        .twoIcon {
          width: 174px;
          height: 68px;
        }
        .button {
          width: 200px;
          height: 44px;
          cursor: pointer;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          color: $color;
          border: 2px solid $color;
          &:hover {
            border-color: $color;
            background: $color;
            color: #fff;
            cursor: pointer;
            i {
              color: #fff;
            }
          }
        }
      }
      .diver {
        height: 250px;
        border: 1px dashed #999999;
      }
    }
  }
}
</style>
