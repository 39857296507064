var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "Details-figure" }, [
          _c("div", { staticClass: "focus" }, [
            _vm.productDetail.MainPic
              ? _c(
                  "div",
                  {
                    staticClass: "banner trans",
                    on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
                  },
                  [
                    _c(
                      "vue-photo-zoom-pro",
                      {
                        attrs: {
                          "high-url": _vm.currentUrl,
                          "out-zoomer": true,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.currentUrl },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "indicators" },
              _vm._l(_vm.ThumbPhotos, function (item, index) {
                return _c("img", {
                  key: index,
                  class: {
                    con: index == _vm.imgindex,
                    displayX: !item.isDisplay,
                  },
                  attrs: { src: item.url, alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.select(index)
                    },
                  },
                })
              }),
              0
            ),
            _c("button", { staticClass: "prev", on: { click: _vm.prev } }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926687225_left-arrow.png",
                  alt: "",
                },
              }),
            ]),
            _c("button", { staticClass: "next", on: { click: _vm.next } }, [
              _c("img", {
                attrs: {
                  src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926687318_right-arrow.png",
                  alt: "",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "synopsis" },
            [
              _c("div", { staticClass: "intro" }, [
                _vm._v(_vm._s(_vm.productDetail.ProductName)),
              ]),
              _c("div", { staticClass: "AliexpressLink_SourceUrl" }, [
                _c(
                  "a",
                  {
                    staticStyle: {
                      "font-family": "Regular",
                      "-webkit-font-smoothing": "antialiased",
                      "font-size": "14px",
                    },
                    attrs: {
                      href: _vm.productDetail.SourceUrl,
                      target: "_blank",
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("productDetails.AliexpressLink"))),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("span", { staticClass: "labelname" }, [
                  _vm._v(_vm._s(_vm.$t("productDetails.DetailsPrice"))),
                ]),
                _c("span", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-family": "Regular",
                        "-webkit-font-smoothing": "antialiased",
                        "font-size": "22px",
                        "margin-left": "15px",
                        "margin-top": "5px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("productDetails.sum")) +
                          " " +
                          _vm._s(_vm.Price.toFixed(2))
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm.productDetail.ProductType !== "normal"
                ? _vm._l(_vm.productDetail.Attributes, function (attr, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          class:
                            attr.PicList && attr.PicList.length > 0
                              ? "picture"
                              : "measure",
                        },
                        [
                          attr.PicList && attr.PicList.length > 0
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c("span", { staticClass: "labelname" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t(attr.AttributesName)) +
                                          ":"
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.Productimg,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: item.index,
                                            staticClass: "imgas",
                                          },
                                          [
                                            _vm.imageindex == index
                                              ? _c("span", [
                                                  _vm._v(_vm._s(item)),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._l(
                                  _vm.ProductColor,
                                  function (item, index) {
                                    return _c(
                                      "el-radio-group",
                                      {
                                        key: index,
                                        on: {
                                          change: function ($event) {
                                            return _vm.imageChange(index)
                                          },
                                        },
                                        model: {
                                          value: _vm.radio2,
                                          callback: function ($$v) {
                                            _vm.radio2 = $$v
                                          },
                                          expression: "radio2",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            staticClass: "image",
                                            attrs: { plain: "", label: index },
                                          },
                                          [
                                            _c("img", {
                                              attrs: { src: item, alt: "" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ]
                            : [
                                _c("div", [
                                  _c("span", { staticClass: "labelname" }, [
                                    _vm._v(_vm._s(_vm.$t(attr.AttributesName))),
                                  ]),
                                ]),
                                _vm._l(
                                  _vm.Productimage,
                                  function (item, index) {
                                    return _c(
                                      "el-radio-group",
                                      {
                                        key: index,
                                        on: {
                                          change: function ($event) {
                                            return _vm.radioChange(index)
                                          },
                                        },
                                        model: {
                                          value: _vm.radio1,
                                          callback: function ($$v) {
                                            _vm.radio1 = $$v
                                          },
                                          expression: "radio1",
                                        },
                                      },
                                      [
                                        _c("el-radio-button", {
                                          staticClass: "s",
                                          attrs: { plain: "", label: item },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                        ],
                        2
                      ),
                    ])
                  })
                : _vm._e(),
              _c(
                "h3",
                {
                  staticStyle: {
                    position: "absolute",
                    margin: "0px 0px 2px 0rem",
                    "font-family": "Regular",
                    "-webkit-font-smoothing": "antialiased",
                    "margin-top": "20px",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("productDetails.Description")) + " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "storyline",
                  class: [_vm.hideWords ? "deschide" : ""],
                  attrs: { id: "storyline" },
                  domProps: {
                    innerHTML: _vm._s(_vm.productDetail.Description),
                  },
                },
                [_vm._v(" " + _vm._s(_vm.productDetail.Description) + " ")]
              ),
            ],
            2
          ),
          _vm.isImg
            ? _c("div", { staticClass: "product-details" }, [
                _c("img", {
                  attrs: {
                    src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926751838_xinbai.jpg",
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }