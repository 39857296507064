var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "activeName" }, [
      _c("div", { staticClass: "detail", attrs: { model: _vm.CheckList } }, [
        _c(
          "div",
          { staticClass: "StepBigBox" },
          [
            _c("LoginContentLeft"),
            _c("div", { staticClass: "StepOneRight" }, [
              _c("div", { staticClass: "StepMessageBox" }, [
                _vm._m(0),
                _c("div", { staticClass: "guide_FillInYou" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("guide.FillInYou")))]),
                ]),
                _c("div", { staticClass: "StartStepMessage" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("guide.StartJourney")))]),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("guide.with")) + " "),
                    _c(
                      "span",
                      {
                        staticClass: "col-col5",
                        staticStyle: {
                          "font-size": "20px",
                          "font-weight": "600",
                          "font-family": "Regular",
                          "-webkit-font-smoothing": "antialiased",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("guide.60k")))]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("guide.dropshippers")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "Order_quantity" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("guide.business")))]),
                  _c(
                    "div",
                    { staticClass: "how_many" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "440px",
                            height: "40px",
                            "margin-top": "10px",
                            "font-size": "13px",
                            "font-family": "Regular",
                            "-webkit-font-smoothing": "antialiased",
                            "margin-left": "-190px",
                          },
                          attrs: { placeholder: _vm.$t("guide.HowMany") },
                          model: {
                            value: _vm.value2,
                            callback: function ($$v) {
                              _vm.value2 = $$v
                            },
                            expression: "value2",
                          },
                        },
                        _vm._l(_vm.optionOrders, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            staticStyle: {
                              border: "#ccc",
                              outline: "none",
                              "font-size": "13px",
                              "font-family": "Regular",
                              "-webkit-font-smoothing": "antialiased",
                            },
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "GuideTelephone" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("guide.telephone")))]),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("div", { staticClass: "selectbox" }, [
                  _c(
                    "div",
                    { staticClass: "select" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-inner",
                          attrs: {
                            filterable: "",
                            "filter-method": _vm.dataFilter,
                            placeholder: _vm.$t("guide.CountryCode"),
                          },
                          on: { change: _vm.ChangeFlag },
                          model: {
                            value: _vm.selectCode,
                            callback: function ($$v) {
                              _vm.selectCode = $$v
                            },
                            expression: "selectCode",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _c("i", {
                              class: _vm.selectedClass,
                              staticStyle: { "margin-top": "17px" },
                            }),
                          ]),
                          _vm._l(_vm.optionsCopy, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.Id,
                                attrs: {
                                  label: "(" + "+" + item.PhoneCode + ")",
                                  value: item.Id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("i", { class: item.NationalFlag }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          item.Name +
                                            "(+" +
                                            item.PhoneCode +
                                            ")"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.CheckList.Mobile,
                        expression: "CheckList.Mobile",
                      },
                    ],
                    attrs: { type: "text", placeholder: _vm.$t("guide.phone") },
                    domProps: { value: _vm.CheckList.Mobile },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.CheckList, "Mobile", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "IconInfo",
                    on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-info",
                      staticStyle: { "font-size": "20px" },
                    }),
                  ]
                ),
                _vm.displayUSAdropInfo
                  ? _c("div", { staticClass: "DisplayInfo btn-bg2" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("guide.privacyUSAdrop")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("guide.infomation")))]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "codebox",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.verifyCode,
                          expression: "verifyCode",
                        },
                      ],
                      staticStyle: {
                        "font-size": "13px",
                        "font-family": "Regular",
                        "-webkit-font-smoothing": "antialiased",
                        color: "#000!important",
                      },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("register.code"),
                      },
                      domProps: { value: _vm.verifyCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.verifyCode = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "button",
                      { staticClass: "nnnn", on: { click: _vm.getVerifyCode } },
                      [_vm._v(_vm._s(_vm.$t("guide.Send")))]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.displayShowCode,
                            expression: "displayShowCode",
                          },
                        ],
                        ref: "child",
                        staticClass: "paybox",
                        on: { click: _vm.closeBtn },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ValidationBox" },
                          [
                            _c("Validation", {
                              attrs: {
                                phoneNum: _vm.CheckList.Mobile,
                                phoneCode: _vm.selectCode,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "UnableVerification" }, [
                      _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.DisplayBtn()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("guide.NoCode")))]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.DisplayUnable,
                            expression: "DisplayUnable",
                          },
                        ],
                        staticClass: "UnableReceive",
                      },
                      [
                        _c("p", { staticStyle: { "margin-top": "-5px" } }, [
                          _vm._v(_vm._s(_vm.$t("guide.EmailsUs"))),
                        ]),
                        _c("p", { staticStyle: { "margin-top": "-6px" } }, [
                          _vm._v(_vm._s(_vm.$t("guide.infoUsadropcom"))),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "conTent" }, [
                  _c("p", { staticStyle: { "margin-top": "20px" } }, [
                    _vm._v(_vm._s(_vm.$t("guide.whatsApp"))),
                  ]),
                  _c(
                    "p",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("el-input", {
                        attrs: { name: "", placeholder: _vm.$t("") },
                        model: {
                          value: _vm.CheckList.WhatsApp,
                          callback: function ($$v) {
                            _vm.$set(_vm.CheckList, "WhatsApp", $$v)
                          },
                          expression: "CheckList.WhatsApp",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Confirm" }, [
                  _c(
                    "button",
                    { staticClass: "btn-bg0", on: { click: _vm.btn } },
                    [_vm._v(_vm._s(_vm.$t("guide.next")))]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "LogoBig" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/NewLogo/LogoOne.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }