<template>
  <div>
    <div class="main-card-box">
      <card :cardList="productList" :productType="productType" :blur="blur" />
    </div>
    <div class="main-footer" v-if="!blur">
      <PagePagination
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { findProductListHot } from '@/api/proConnect'
import PagePagination from '@/components/PagePagination.vue'
import {
  productTypeMap,
  defaultProductList,
  seniorProductList,
  fuzzyProductList
} from '../constants'
import { mapState } from 'vuex'
import card from '@/components/ProductItemList/card.vue'

export default {
  name: 'WinnerProducts',
  components: {
    PagePagination,
    card
  },

  props: {
    productType: {
      type: Number,
      default: productTypeMap.Winner
    }
  },

  computed: {
    ...mapState({
      memberInfo: (state) => state.member.memberInfo
    }),
    blur() {
      return this.productType === productTypeMap.Top100
        ? this.memberInfo.GradeId < 3
        : this.memberInfo?.GradeId < 2
    }
  },

  data() {
    return {
      total: 0,
      pageSize: 8,
      currentPage: 1,
      productList: [],
      loading: false,
      upgradeVisible: false
    }
  },

  mounted() {
    this.getProducts()
  },

  methods: {
    async getAllProducts() {
      if (!this.memberInfo?.GradeId) {
        return
      }
      this.loading = true
      const { data } = await findProductListHot({
        pageNum: this.currentPage,
        size: this.pageSize,
        // 0 find  1 winning 2 top
        hotType: this.productType,
        isVip: this.memberInfo?.GradeId,
        vipLevel: this.memberInfo?.VipLevel
      })
      this.total = data.total
      this.productList = data.records
      this.loading = false
    },

    getProducts() {
      // 普通会员只能看Find Products
      if (this.memberInfo?.GradeId === 1) {
        this.productList =
          this.productType === productTypeMap.Top100 ? defaultProductList : fuzzyProductList
      } else if (this.memberInfo?.GradeId === 2 && this.productType === productTypeMap.Top100) {
        // pro会员看不到top100
        this.productList = defaultProductList
      } else {
        this.getAllProducts()
      }
    },

    pageChange(val) {
      this.currentPage = val
      this.getProducts()
    }
  }
}
</script>

<style lang="scss" scoped>
.main-card-box {
  width: 1310px;
  margin-top: 30px;
}
.main-footer {
  margin-top: 30px;
  width: 1310px;
  height: 70px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
</style>
