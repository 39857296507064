import {
  GetList,
  GetOauthRedirectUrl,
  AddStore,
  RefreshStore,
  ReceiveShopifyToken,
  ShopifyConnect,
  GetShopifyTokenKey,
  ReceiveAndRefreshShopifyToken,
  InventoryGetList,
  InventoryExport,
  GetWarehouseList,
  TiktokShopConnect,
  ReceiveRefreshTTSTokenOrKeys,
  RefreshTTSTokenByKey
} from '@/api/store'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetListSt({state, commit}, params) {return GetList(params).then(res => res)},
    GetOauthRedirectUrlSt({state, commit}, params) {return GetOauthRedirectUrl(params).then(res => res)},
    AddStoreSt({state, commit}, params) {return AddStore(params).then(res => res)},
    RefreshStoreSt({state, commit}, params) {return RefreshStore(params).then(res => res)},
    ReceiveShopifyTokenSt({state, commit}, params) {return ReceiveShopifyToken(params).then(res => res)},
    ShopifyConnectSt({state, commit}, params) {return ShopifyConnect(params).then(res => res)},
    GetShopifyTokenKeySt({state, commit}, params) {return GetShopifyTokenKey(params).then(res => res)},
    ReceiveAndRefreshShopifyTokenSt({state, commit}, params) {return ReceiveAndRefreshShopifyToken(params).then(res => res)},
    InventoryGetListSt({state, commit}, params) {return InventoryGetList(params).then(res => res)},
    GetWarehouseListSt({state, commit}, params) {return GetWarehouseList(params).then(res => res)},
    InventoryExportSt({state, commit}, params) {return InventoryExport(params).then(res => res)},
    TiktokShopConnectSt({state, commit}, params) {return TiktokShopConnect(params).then(res => res)},
    ReceiveRefreshTTSTokenOrKeysSt({state, commit}, params) {return ReceiveRefreshTTSTokenOrKeys(params).then(res => res)},
    RefreshTTSTokenByKeySt({state, commit}, params) {return RefreshTTSTokenByKey(params).then(res => res)},
  }

}
