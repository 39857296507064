var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WinnerProducts", {
    attrs: { productType: _vm.productTypeMap.Top100 },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }