<template>
  <div class="shopifyContent">
    <div class="title">
      <span>{{ $t('member.confirmation') }}</span>
    </div>
    <div class="diver"></div>
    <div class="payInfo">
      <div class="item">
        <div class="title">User account:</div>
        <div class="msg">{{ userInfo.LoginName || '' }}</div>
      </div>
      <div class="item">
        <div class="title">Pricing plan:</div>
        <div>{{ radioLabel[CycleType] || '' }}</div>
      </div>
      <div class="item">
        <div class="title">Billing option:</div>
        <div>{{ priceInfo?.TargetGradeName || '' }}</div>
      </div>
      <div class="item">
        <div class="title">Subtotal:</div>
        <div>
          ${{
            Number(
              priceInfo?.RegularAmount - priceInfo?.Discount - priceInfo?.RefereeDiscount || 0
            ).toFixed(2) || 0.0
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">Expiration date:</div>
        <div>{{ priceInfo?.EndDate || '' }}</div>
      </div>
    </div>
    <div class="diver diver1"></div>
    <div class="totalSum">
      ${{
        Number(
          priceInfo?.RegularAmount - priceInfo?.Discount - priceInfo?.RefereeDiscount || 0
        ).toFixed(2) || 0.0
      }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  props: ['priceInfo', 'CycleType'],
  data() {
    return {
      radioLabel: ['Monthly', '3 months', 'Yearly']
    }
  }
}
</script>
<style lang="scss" scoped>
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shopifyContent {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: url('../../../../../assets/imgs//UpgradeToPro/Upgradetopro.png') no-repeat;
  background-size: cover;
  color: #ffffff;
  padding: 12px 24px 24px 24px;
  .title {
    font-size: 18px;
  }
  .diver {
    background: #efefef;
    width: 100%;
    height: 1px;
    margin-top: 10px;
  }
  .diver1 {
    background: #ffffff;
    opacity: 0.3;
  }
  .payInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 5px;
    .item {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-around;
      font-size: 14px;
      .title {
        width: 120px;
        margin-right: 10px;
        text-align: left;
        font-size: 14px;
      }
      div {
        &:nth-child(2) {
          text-align: right;
          width: calc(100% - 120px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .totalSum {
    font-size: 32px;
    font-weight: 700;
    margin-top: 32px;
    text-align: center;
    width: 100%;
  }
}
</style>
