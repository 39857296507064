import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/token'
import { getNewToken } from '@/api/login'
import { judgePrototype } from '@/utils/tools'
import { baseApiHost } from './env'

// 创建axios实例
const service = axios.create({
  baseURL: '',
  timeout: 1200000,
  headers: {
    'Content-Type': 'application/json'
  }
})
service.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      if (token === 'timeout' || token === 'refresh') {
        // token过期重置
        return getNewToken()
          .then((res) => {
            config.headers['Authorization'] = 'Bearer ' + res
            // config.headers['Content-Type'] = 'application/json'
            return config
          })
          .catch(() => {
            return Promise.reject()
          })
      } else {
        config.headers['Authorization'] = 'Bearer ' + token
        // config.headers['Content-Type'] = 'application/json'
        return config
      }
    } else {
      // 不存在token
      return config
    }
  },
  (error) => {
    Promise.reject(error)
  }
)
const responseAdapter = (response) => {
  if (response.success === undefined || response.code === undefined) {
    return {
      code:
        response.Success || response.code === 0 ? 10000 : response.ErrCode || response.msg || -1,
      success: response.Success || response.code === 0,
      debugMsg: response.ErrMsg || response.msg,
      msg: response.ErrMsg || response.msg,
      data: response.Result || response.MapData || response.ResData || response.body
    }
  }
  return response
}
// 返回拦截器
service.interceptors.response.use(
  (response) => {
    const { data } = response
    if (data) {
      const judgeType = judgePrototype(data)
      if (
        judgeType === 'String' ||
        judgeType === 'Number' ||
        judgeType === 'Boolean' ||
        judgeType === 'Array'
      )
        return data
      const { Success, ErrMsg } = data
      // if (!Success) {
      //   Message.error({
      //     message: ErrMsg || '未知错误'
      //   });
      //   return null;
      // }
      return data
    }
    Message.error({
      message: 'Server deviation~'
    })
    return null
  },
  (error) => {
    /*  Message.error({
      message: 'Server deviation~'
    }) */
    if (error.response.status === 401) {
      // 清空缓存
      window.sessionStorage.clear()
      // 跳转到登录
      window.location.href = `${window.location.origin}/`
    }

    return Promise.reject(error)
  }
)

export default service
