import {
  GetOverView
} from '@/api/dashboard'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetOverViewDb({state, commit}, params) {
      return GetOverView(params).then(res => res)
    },
  }

}