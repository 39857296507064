var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDialog, "destroy-on-close": "" },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-header" }, [
            _c("div", { staticClass: "header-title" }, [
              _vm._v(_vm._s(_vm.$t("store.orders"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "dialog-body" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.formModel,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Original URL of Shopify store",
                        prop: "name",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: _vm.$t("store.errorStoreName"),
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formModel.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formModel.name",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        slot: "suffix",
                                        effect: "dark",
                                        content:
                                          "To find it, go to your Shopify dashboard. Go to Settings > Domains. Look for the only or oldest domain that ends in 'myshopify.com' format.",
                                      },
                                      slot: "suffix",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-question el-input__icon",
                                        staticStyle: { cursor: "pointer" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("span", { staticClass: "my-shopify" }, [
                              _vm._v(_vm._s(_vm.$t("store.myshopify"))),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("store.accessToken"),
                        prop: "accessToken",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("store.errorAccessToken"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formModel.accessToken,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formModel,
                              "accessToken",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formModel.accessToken",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "no-shopify-store-title" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("store.NotShopify")))]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancel-button",
                  attrs: { loading: _vm.buttonLoading, plain: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("store.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "confirm-button",
                  attrs: {
                    loading: _vm.buttonLoading,
                    round: "",
                    type: "primary",
                  },
                  on: { click: _vm.confirmStore },
                },
                [_vm._v(" " + _vm._s(_vm.$t("store.save")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }