import request from '@/utils/request';

export const GetCurrentMonthList = (params) => request({// 获取问卷调查
    method: "get",
    url: '/api/LhMemberSign/GetCurrentMonthList',
    params
  });
  export const GetSign = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhMemberSign/Sign',
    data
  });
  export const CheckSignToday = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhMemberSign/CheckSignToday',
    data
  });