<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box" v-if="showContent">
      <div class="activeName">
        <div class="HelpCenterTitle">
          <p>{{ $t("HelpCenter.HelpCenter") }}</p>
        </div>
        <div class="help_content">
          <p>{{ $t("HelpCenter.mayInterested") }}</p>
        </div>
        <div class="HelpBigContentBox">
          <div class="LeftContentBox">
            <div class="BasicFunctionsBox" @click="BasicFunctionBtn">
              <div class="BasicFunctionsImg">
                <p><img src="../../assets/imgs/HelpCenterImg/BasicOne.png" alt=""></p>
              </div>
              <div class="BasicFunctionsTitle">
                <p>
                  {{BigTitle[0].CatalogName}}
                </p>
              </div>
            </div>
            <div class="StoreConnection" @click="StoreConnectionBtn"> <!-- Store Connection -->
              <div class="StoreConnectionImg">
                <p><img src="../../assets/imgs/HelpCenterImg/StoreOne.png" alt=""></p>
              </div>
              <div class="StoreConnectionTitle">
                <p>
                  {{BigTitle[2].CatalogName}}
                </p>
              </div>
            </div>
            <div class="Message" @click="MessageBtn"> <!-- Message -->
              <div class="MessageImg">
                <p><img src="../../assets/imgs/HelpCenterImg/MessageOne.png" alt=""></p>
              </div>
              <div class="MessageTitle">
                <p>
                  {{BigTitle[4].CatalogName}}
                </p>
              </div>
            </div>
          </div>
          <div class="RightContentBox"> <!-- 右侧内容 -->
            <div class="PricingPlan" @click="PricingPlanBtn"> <!-- PricingPlan -->
              <div class="PricingPlanImg">
                <p><img src="../../assets/imgs/HelpCenterImg/PricingOne.png" alt=""></p>
              </div>
              <div class="PricingPlanTitle">
                <p>
                  {{BigTitle[1].CatalogName}}
                </p>
              </div>
            </div>
            <div class="OrderRefund" @click="OrderRefundBtn"> <!-- Order and Refund -->
              <div class="OrderRefundImg">
                <p><img src="../../assets/imgs/HelpCenterImg/OrderOne.png" alt=""></p>
              </div>
              <div class="OrderRefundTitle">
                <p>
                  {{BigTitle[3].CatalogName}}
                </p>
              </div>
            </div>
            <div class="ShippingTracking" @click="ShippingTrackingBtn"> <!-- Shipping and Tracking-->
              <div class="ShippingTrackingImg">
                <p><img src="../../assets/imgs/HelpCenterImg/Shipping.png" alt=""></p>
              </div>
              <div class="ShippingTrackingTitle">
                <p>
                  {{BigTitle[5].CatalogName}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      showContent: false,
      dispatch: null,
      page: "HelpCenter",
      BigTitle: [], // 大标题
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  created(){
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.GetHelpCenterCatalogs();
  },
  methods: {
    GetHelpCenterCatalogs() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
          this.showContent = true
          this.BigTitle = res.Result;

        })
        .catch(err => {
          console.log(err);
        });
    },
    BasicFunctionBtn() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
         this.BigTitle = res.Result;
          this.$router.push({
            path: "/BasicFunction",
            query: {
              Id: 3,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    StoreConnectionBtn() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
          this.BigTitle = res.Result;
          this.$router.push({
            path: "/StoreConnection",
            query: {
              Id: 5,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    MessageBtn() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
        this.BigTitle = res.Result;
          this.$router.push({
            path: "/MessageTouch",
            query: {
              Id: 7,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    PricingPlanBtn() {
     this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
          this.BigTitle = res.Result;
          this.$router.push({
            path: "/PricingPlan",
            query: {
              Id: 4,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    OrderRefundBtn() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
          this.BigTitle = res.Result;
          this.$router.push({
            path: "/OrderRefund",
            query: {
              Id: 6,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });

    },
    ShippingTrackingBtn() {
      this.dispatch('help/GetHelpCenterCatalogsHp')
        .then(res => {
          this.BigTitle = res.Result;
          this.$router.push({
            path: "/ShippingTracking",
            query: {
              Id: 8,
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
  }
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  position: relative;
}
.HelpCenterTitle {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #15104B;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.help_content {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #7677c2;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HelpBigContentBox {
  width: 900px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin-left: 210px;
  margin-top: 30px;
}
.LeftContentBox {
  width: 355px;
  height: 400px;
  position: absolute;
  top: 0px;
  left: 50px;
}
.RightContentBox {
  width: 350px;
  height: 400px;
  position: absolute;
  top: 0px;
  right: 60px;
}
/* Basic Functions */
.BasicFunctionsBox {
  position: absolute;
  top: 60px;
  left: 60px;
}
.BasicFunctionsImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.BasicFunctionsImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.BasicFunctionsImg p img{
  width: 42px;
  height: 42px;
  margin-left: 5px;
  margin-top: 6px;
}
.BasicFunctionsImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 8px;
}
.BasicFunctionsTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 145px;
  font-size: 18px;
  cursor: pointer;
}
.BasicFunctionsTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 155px;
  font-size: 20px;
  cursor: pointer;
}
/* Store Connection */
.StoreConnection {
  position: absolute;
  top: 170px;
  left: 60px;
}
.StoreConnectionImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.StoreConnectionImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.StoreConnectionImg p img{
  width: 42px;
  height: 42px;
  margin-left: 6px;
  margin-top: 8px;
}
.StoreConnectionImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 8px;
}
.StoreConnectionTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 160px;
  font-size: 18px;
  cursor: pointer;
}
.StoreConnectionTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 180px;
  font-size: 20px;
  cursor: pointer;
}
/* Message */
.Message {
  position: absolute;
  bottom: 55px;
  left: 60px;
  cursor: pointer;
}
.MessageImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.MessageImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.MessageImg p img{
  width: 42px;
  height: 42px;
  margin-left: 6px;
  margin-top: 6px;
}
.MessageImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 8px;
}
.MessageTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 250px;
  font-size: 18px;
}
.MessageTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 280px;
  font-size: 20px;
}
/* PricingPlan */
.PricingPlan {
  position: absolute;
  top: 60px;
  left: 0px;
  cursor: pointer;
}
.PricingPlanImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.PricingPlanImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.PricingPlanImg p img{
  width: 42px;
  height: 42px;
  margin-left: 6px;
  margin-top: 6px;
}
.PricingPlanImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 8px;
}
.PricingPlanTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 145px;
  font-size: 18px;
}
.PricingPlanTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 155px;
  font-size: 20px;
}
/* Order and Refund */
.OrderRefund {
  position: absolute;
  top: 170px;
  left: 0px;
  cursor: pointer;
}
.OrderRefundImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.OrderRefundImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.OrderRefundImg p img{
  width: 42px;
  height: 42px;
  margin-left: 6px;
  margin-top: 6px;
}
.OrderRefundImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 8px;
}
.OrderRefundTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 165px;
  font-size: 18px;
}
.OrderRefundTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 185px;
  font-size: 20px;
}

/* Shipping and Tracking */
.ShippingTracking {
  position: absolute;
  bottom: 55px;
  left: 0px;
  cursor: pointer;
}
.ShippingTrackingImg p{
  width: 60px;
  height: 60px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.ShippingTrackingImg p:hover{
  width: 62px;
  height: 62px;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.ShippingTrackingImg p img{
  width: 42px;
  height: 42px;
  margin-left: 6px;
  margin-top: 8px;
}
.ShippingTrackingImg p img:hover{
  width: 45px;
  height: 45px;
  margin-left: 6px;
  margin-top: 6px;
}
.ShippingTrackingTitle p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 205px;
  font-size: 18px;
}
.ShippingTrackingTitle p:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18px;
  left: 80px;
  width: 225px;
  font-size: 20px;
}
/* 暗夜 */
body .dark {
  background: #1f1e2e;
}
</style>
