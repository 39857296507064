var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "particulars" },
    [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "winners_box" }, [
          _c(
            "div",
            { staticClass: "Filter" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "Video name" },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchBtn.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.searchType,
                    callback: function ($$v) {
                      _vm.searchType = $$v
                    },
                    expression: "searchType",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "prepend", icon: "el-icon-search" },
                    slot: "prepend",
                  }),
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "search", on: { click: _vm.searchBtn } },
                [_vm._v(_vm._s(_vm.$t("MyProduct.search")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bigbox" },
            _vm._l(_vm.productList, function (item, index) {
              return _c("div", { key: item.id, staticClass: "box" }, [
                _c(
                  "div",
                  {
                    staticClass: "images",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.enterProduct(index)
                      },
                      mouseleave: function ($event) {
                        return _vm.leaveProduct()
                      },
                    },
                  },
                  [
                    _c("ud-video", {
                      ref: "video" + index,
                      refInFor: true,
                      attrs: {
                        poster: item.coverUrl,
                        src: item.videoUrl,
                        canSeeTime: 30,
                      },
                      on: { canSeePause: _vm.handleCanSeePause },
                    }),
                    _vm.currentIndexImg === index
                      ? _c("div", { staticClass: "contents_exhibition" }, [
                          _c("div", { staticClass: "custom_controls" }, [
                            _c(
                              "div",
                              {
                                staticClass: "video_player_icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.togglePlay(index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  class: _vm.isPlaying[index]
                                    ? "el-icon-video-pause"
                                    : "el-icon-video-play",
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "video_amplify_icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleFullscreen(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-full-screen" })]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "productExhibition_description" },
                            [_c("p", [_vm._v(_vm._s(item.videoDesc))])]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "video_title" }, [
                  _c("p", [_vm._v(_vm._s(item.videoName))]),
                ]),
                _c("div", { staticClass: "video_time" }, [
                  _c("p", [_vm._v(_vm._s(_vm.formatDate(item.createTime)))]),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "pagebox" },
            [
              _c("PagePagination", {
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.pageSize,
                  "current-page": _vm.currentPage,
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("UpgradeDialog", {
        attrs: {
          visible: _vm.upgradeVisible,
          title: "Get Pro Plan for more features",
        },
        on: {
          "update:visible": function ($event) {
            _vm.upgradeVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }