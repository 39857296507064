export const getToken = () => {
  const time = parseInt(`${new Date().getTime() / 1000}`);
  const USAdrop_user = JSON.parse(sessionStorage.getItem("USAdrop_user"));
  if (USAdrop_user) {
    let expiresTime = USAdrop_user.expiresTime;
    if (time < expiresTime - 3000) {
      // 5分钟内不刷新
      return sessionStorage.getItem("Authorization");
    } else if (time < expiresTime) {
      // token已使用5分钟但并未过期
      return "refresh";
    } else {
      return "timeout";
    }
  } else {
    return false;
  }
}

export const setToken = (res) => {
  res.expiresTime = parseInt(`${new Date().getTime() / 1000}`) + res?.ExpiresIn;
  sessionStorage.setItem("USAdrop_user", JSON.stringify(res));
  sessionStorage.setItem("Authorization", res?.Token);
  // 添加存储方式
  localStorage.setItem("USAdrop_user", JSON.stringify(res));
  localStorage.setItem("Authorization", res.Token);
  return res;
}

export const removeToken = () => {
  //清理用户信息
  clearUserInfo()
  sessionStorage.removeItem("Authorization");
  sessionStorage.removeItem("USAdrop_user");
}

export const clearUserInfo = () => {
  localStorage.removeItem('email')
  localStorage.removeItem('password')
}