var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "successful_box" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("AirwallexSuccess.YourPayment")))]),
    ]),
    _c("div", { staticClass: "USAdrop_box" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("AirwallexSuccess.ThankYou")))]),
    ]),
    _c("div", { staticClass: "Confirm_btn" }, [
      _c("button", { on: { click: _vm.ConfirmBtn } }, [
        _vm._v(_vm._s(_vm.$t("AirwallexSuccess.Confirm"))),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "headerBg_Img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/NewLogo/LogoOne.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_text" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src: require("../../assets/imgs/UpgradeToPro/successful.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }