import request from '@/utils/request';

export const GetRightsWithSourcing = (params) => request({
  method: "get",
  url: '/api/MemberRights/GetRightsWithSourcing',
  params
});
export const GetRightsWithProcessOrder = (params) => request({
  method: "get",
  url: 'api/MemberRights/GetRightsWithProcessOrder',
  params
});
export const GetRightsWithPublishProduct = (params) => request({
  method: "get",
  url: '/api/MemberRights/GetRightsWithPublishProduct',
  params
});

