<template>
  <div>
    <div v-if="BindStore" class="SideBarBigBoxStore">
      <!-- 头部绑定店铺导航 -->
      <div class="LinkMyStore">
        <p @click="LinkMyStoreBtn">{{ $t('Customer.ConnectUSAdrop') }}</p>
        <p @click="helpBtn">{{ $t('Customer.NoOffline') }}</p>
      </div>
    </div>
    <div class="Bind_store_box">
      <!-- 导航引导 -->
      <div class="NavPopBigBox" v-if="NavPopBigBoxPop">
        <!-- Sourcing -->
        <div class="NavGuideSourcing" v-if="CloseSourcingBtn">
          <p>
            <span>(1/5)</span>&nbsp;{{ $t('Customer.SourceProduct') }} <br />
            {{ $t('Customer.supplierPortals') }}
          </p>
          <el-button @click="NextOrders">{{ $t('Customer.Next') }}</el-button>
        </div>
        <!-- Orders -->
        <div class="NavGuideOrders" v-if="CloseOrdersBtn">
          <p><span>(2/5)</span>&nbsp;{{ $t('Customer.FulfillUSAdrop') }}</p>
          <div class="NavGuideOrdersBtn">
            <el-button @click="BackSourcing">{{ $t('Customer.Back') }}</el-button>
            <el-button @click="NextWinner">{{ $t('Customer.Next') }}</el-button>
          </div>
        </div>
        <!-- Winner -->
        <div class="NavGuideWinner" v-if="CloseWinnerBtn">
          <!-- <div class="line"></div> -->
          <p><span>(3/5)</span>&nbsp;{{ $t('Customer.Handpicked') }}</p>
          <div class="NavGuideWinnerBtn">
            <el-button @click="BackOrders">{{ $t('Customer.Back') }}</el-button>
            <el-button @click="NextProduct">{{ $t('Customer.Next') }}</el-button>
          </div>
        </div>
        <!-- Product -->
       <!--  <div class="NavGuideProduct" v-if="CloseProductBtn">
          <p><span>(4/6)</span>&nbsp;{{ $t('Customer.productCategories') }}</p>
          <div class="NavGuideProductBtn">
            <el-button style="" @click="BackWinners">{{ $t('Customer.Back') }}</el-button>
            <el-button @click="NextPricing">{{ $t('Customer.Next') }}</el-button>
          </div>
        </div> -->
        <!-- 2023Summer -->
        <!-- <div class="NavGuide2023Summer" v-if="CloseSummerBtn">
            <p><span>(4/6)</span>&nbsp;{{ $t("Customer.CheckProducts") }}</p>
            <div class="NavGuide2023SummerBtn">
              <el-button @click="BackWinnerNext">{{ $t("Customer.Back") }}</el-button>
              <el-button @click="NextPricing">{{ $t("Customer.Next") }}</el-button>
            </div>
          </div> -->
        <!-- Pricing -->
        <div class="NavGuidePricing" v-if="ClosePricingBtn">
          <p><span>(4/5)</span>&nbsp;{{ $t('Customer.SpecialMemberships') }}</p>
          <div class="NavGuidePricingBtn">
            <el-button @click="BackWallet">{{ $t('Customer.Back') }}</el-button>
            <el-button @click="NextWallet">{{ $t('Customer.Next') }}</el-button>
          </div>
        </div>
        <!-- Wallet -->
        <div class="NavGuideWallet" v-if="CloseWalletBtn">
          <p>
            <span>(5/5)</span>&nbsp;{{ $t('Customer.ManageBoost') }} <br />
            {{ $t('Customer.dropshippingBusiness') }}
          </p>
          <div class="NavGuideWalletBtn">
            <el-button @click="BackPricing">{{ $t('Customer.Back') }}</el-button>
            <el-button @click="CloseOkWallet">{{ $t('Customer.Ok') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 头部导航 class="HeaderTitle" -->
      <!-- 业务员 -->
      <div v-if="paymentList.AgentId > 0">
        <div class="AgentBox">
          <div class="Agent" @click.stop="MyAgentBox">
            <img src="@/assets/imgs/NewLogo/IMG_0172.png" alt="" />
          </div>
          <div class="headPortraitImg" @click.stop="MyAgentBox"></div>
        </div>
        <div class="Contact_agent" v-if="ContactHere">
          <div class="remove_outline" @click="RemoveOutline">
            <i class="el-icon-remove-outline"></i>
          </div>
          <!-- <p>Hi ! Contact me here !</p> -->
          <p>{{ $t('Customer.HiContact') }}</p>
        </div>
        <div v-if="ContentTwo" class="Contact_agent">
          <div class="remove_outline" @click="RemoveOutline">
            <i class="el-icon-remove-outline"></i>
          </div>
          <p>
            {{ $t('Customer.everything') }} <br />
            {{ $t('Customer.helpYou') }}
          </p>
        </div>
        <div v-if="ContentThree" class="Contact_agent_three">
          <div class="remove_outline" @click="RemoveOutline">
            <i class="el-icon-remove-outline"></i>
          </div>
          <p>
            {{ $t('Customer.wellToday') }} <br />
            {{ $t('Customer.LetMe') }}
          </p>
        </div>
        <div v-if="ContentFour" class="Contact_agent_Pssp">
          <div class="remove_outline" @click="RemoveOutline">
            <i class="el-icon-remove-outline"></i>
          </div>
          <p>{{ $t('Customer.Pssp') }}</p>
        </div>
        <div v-if="ContentFive" class="Contact_agent">
          <div class="remove_outline" @click="RemoveOutline">
            <i class="el-icon-remove-outline"></i>
          </div>
          <p>{{ $t('Customer.HeyThere') }}</p>
        </div>
      </div>
      <!-- 联系业务员弹窗 -->
      <div class="member_box" v-if="memberPopupBox">
        <div class="member_set">
          <div class="member_el-icon-close">
            <i class="el-icon-close" @click="close"></i>
          </div>
          <div class="headportrait">
            <img :src="baseApiHost() + CenterList.Avatar" alt="" />
          </div>
          <div class="member_RealName">
            <p>
              {{ $t('Customer.Hi') }}&nbsp;<span style="color: #53c248"
                >{{ userInfo.RealName }},</span
              >&nbsp;{{ $t('Customer.how_doing') }}
            </p>
          </div>
          <div class="member_AgentNames">
            <p>
              {{ $t('Customer.I_m') }}
              <span style="font-style: italic; text-decoration: underline; font-size: 18px">{{
                CenterList.AgentName
              }}</span
              >&nbsp;,&nbsp;{{ $t('Customer.YourAgent') }}
            </p>
          </div>
          <div class="member_hello">
            <p>{{ $t('Customer.FeelMe') }}&nbsp;!</p>
          </div>
          <a
            :href="'https://api.whatsapp.com/send?phone=+' + CenterList.WhatsApp + '&text=Hello'"
            target="_blank"
          >
            <div class="member_Whatsapp">
              <span>
                <span><img src="@/assets/imgs/UpgradeToPro/whatsapp-logo-hero.png" alt="" /></span>
              </span>
              <span>{{ $t('Customer.WhatsApp') }}</span>
            </div>
          </a>
          <a :href="'skype:' + CenterList.Skype + '?chat'" target="_blank">
            <div class="member_Skype">
              <span>
                <span><img src="@/assets/imgs/UpgradeToPro/Skype123.png" alt="" /></span>
              </span>
              <span>{{ $t('Customer.Skype') }}</span>
            </div>
          </a>
          <a :href="'https://t.me/' + CenterList.Telegram" target="_blank">
            <div class="member_Telegram">
              <span>
                <span><img src="@/assets/imgs/UpgradeToPro/telegram1.png" alt="" /></span>
              </span>
              <span>{{ $t('Customer.Telegram') }}</span>
            </div>
          </a>
        </div>
      </div>
      <!-- 任务打卡活动 -->
      <!--  v-if="ShowSurveyImg" -->
      <div v-if="ShowSurveyImg">
        <!-- 调查问卷按钮 -->
        <div class="SurveyFileBox" v-if="paymentList.HasRegistTaskNoOver == true">
          <div class="SurveyFileContent" @click="SurveyFileBtn">
            <img src="@/assets/imgs/SurveyFile/Group2193.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 调查问卷进度列表 -->
      <div class="QuestionBox" v-if="displayQuestionBox">
        <div class="QuestionTopBox">
          <div class="DontMissOut_Img">
            <!-- 小灯 -->
            <div class="Group_img">
              <img src="../../assets/imgs/SurveyFile/Group2184.png" alt="" />
            </div>
            <!-- 文案 -->
            <div class="DontMissOut_Word">
              <p>{{ $t('Customer.DontMissOut') }}</p>
            </div>
            <!-- 关闭按钮 -->
            <div class="CloseRulesBtn_Pop">
              <p @click="CloseRulesBtn"><i class="el-icon-circle-close"></i></p>
            </div>
          </div>
          <div class="ProgressBarBox">
            <!-- 进度条 -->
            <div class="ProgressBar_Pop">
              <!-- 进度条 -->
              <div class="elProgressBox">
                <el-progress
                  :percentage="pmgressbar"
                  :show-text="false"
                  :color="customColor"
                  :stroke-width="10"
                ></el-progress>
              </div>
              <!-- 进度次数 -->
              <div class="CompletedBox">
                <span>{{ $t('Customer.Completed') }}</span>
                <span style="margin-left: 8px">{{ SurveyAllFileNumber.Executed }}</span
                >&nbsp;/
                <span>{{ SurveyAllFileNumber.Total }}</span>
              </div>
            </div>
            <!-- 图标 -->
            <div class="GroupIcon">
              <img src="../../assets/imgs/SurveyFile/Group2181(1).png" alt="" />
            </div>
          </div>
        </div>
        <!-- 任务列表 -->
        <div class="QuestionBottomBox">
          <div>
            <div class="SurpriseJackpot">
              <p>
                {{ $t('Customer.Surprise') }}
                <br />
                {{ $t('Customer.Credits') }}
              </p>
            </div>
            <div class="RulesCompletedBox">
              <div class="RulesTitle" @click="OpenRulesBtn">
                <p><i class="el-icon-question"></i></p>
                <p>{{ $t('Customer.RulesDescription') }}</p>
              </div>
            </div>
          </div>
          <div class="QuestionBottomBox">
            <div class="FinishBox" v-for="item in SurveyAllFileList" :key="item.Tag">
              <el-button v-if="item.ExecutionStatus == 4" class="FinishQuestionnaire" disabled>{{
                item.Title
              }}</el-button>
              <el-button
                v-else-if="item.ExecutionStatus != 2"
                class="FinishQuestionnaire"
                @click="GetByClick(item)"
                >{{ item.Title }}</el-button
              >
              <el-button
                v-else-if="item.ExecutionStatus == 2"
                class="FinishQuestionnaire"
                @click="GetByClick(item)"
                disabled
                >{{ item.Title }}</el-button
              >
              <div class="FinishSwitch">
                <el-switch
                  v-if="item.ExecutionStatus == 2"
                  v-model="CompleteValue"
                  disabled
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
                <el-switch
                  v-else-if="item.ExecutionStatus != 2"
                  v-model="SurveyValue"
                  disabled
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 调查问卷规则弹窗 -->
      <div class="RulesPopUp_box" v-if="DisplayRulesDetails">
        <div class="RulesPopUp_Content_Box">
          <div class="RulesDescriptionTitle">
            <p>{{ $t('Customer.RulesDescription') }}</p>
            <p @click="CloseRulesIconBtn"><i class="el-icon-circle-close"></i></p>
          </div>
          <div class="USAdropCampaignTitle">
            <p>{{ $t('Customer.USAdropCampaign') }}</p>
          </div>
          <div class="Customer_WeAreDelighted">
            <p>{{ $t('Customer.WeAreDelighted') }}</p>
          </div>
          <div class="Customer_newCustomer">
            <p>
              <span>{{ $t('Customer.newCustomer') }}</span>
              <span>{{ $t('Customer.Ucredits') }}</span>
            </p>
          </div>
          <div class="Customer_InAddition">
            <p>
              <span>{{ $t('Customer.InAddition') }}</span>
              <span>{{ $t('Customer.thank-you') }}</span>
              <span>{{ $t('Customer.enhanceCustomers') }}</span>
            </p>
          </div>
          <div class="Customer_WeAreDelighted">
            <p>{{ $t('Customer.shoppingeExperience') }}</p>
          </div>
          <div class="Customer_TaskOne">
            <p>{{ $t('Customer.TaskOne') }}</p>
          </div>
          <div class="Customer_TaskTwo">
            <p>{{ $t('Customer.TaskTwo') }}</p>
          </div>
          <div class="Customer_OnceSuccessfully">
            <p>
              <span>{{ $t('Customer.successfullyComplete') }}</span>
              <span>{{ $t('Customer.threeHundredUcredits') }}</span>
            </p>
          </div>
          <div class="Customer_InAddition">
            <p>
              <span>{{ $t('Customer.TheseUcreditsBusiness') }}</span>
              <!-- <span>{{ $t("Customer.threeHundredUcredits") }}</span> -->
            </p>
          </div>
          <!--  <div class="Customer_OnceSuccessfully">
              <p>
                <span>{{ $t("Customer.OnceSuccessfully") }}</span>
                <span>{{ $t("Customer.threeHundredUcredits") }}</span>
              </p>
            </div> -->
          <!--  <div class="Customer_InAddition">
              <p>
                <span>{{ $t("Customer.equivalentTo") }}</span>
                <span>{{ $t("Customer.threeHundred") }}</span>
                <span>{{ $t("Customer.TheseUcredits") }}</span>
              </p>
            </div> -->
          <!--  <div class="Customer_considerableSavings">
              <p>{{ $t("Customer.considerableSavings") }}</p>
            </div> -->
          <!--  <div class="WeHaveTitle">
              <p>{{ $t("Customer.haveAwards") }}</p>
            </div>
            <div class="RuleOneTitle">
              <p>1. {{ $t("Customer.TakeCards") }}</p>
            </div>
            <div class="RuleTwoTitle">
              <p>
                2. {{ $t("Customer.BrowseMembership") }}
              </p>
            </div> -->
          <!-- 任务三和任务四规则 未绑定店铺-->
          <!-- <div>
              <div class="RuleTwoTitle">
                <p>3. {{ $t("Customer.BindingShopify") }}</p>
              </div>
              <div class="RuleTwoTitle">
                <p>4. {{ $t("Customer.fulfillmentOrder") }}</p>
              </div>
            </div>
            <div class="EveryParticipant">
              <p>{{ $t("Customer.EveryParticipant") }}</p>
            </div>
            <div class="CloseRulesBtn" @click="CloseRulesIconBtn">
              <i class="el-icon-circle-close"></i>
            </div>-->
        </div>
      </div>
      <!-- 调查问卷详情 -->
      <div class="SurveyFileDetailsBox" v-if="SurveyDetailsHide">
        <div class="SurveyDetailsContent">
          <!-- 问卷调查表头 -->
          <div class="SurveyFileTitle">
            <p>{{ $t('Customer.Questionnaire') }}</p>
            <p>{{ $t('Customer.Following') }}</p>
          </div>
          <div>
            <div class="SurveyDetailListBox">
              <div v-for="(item, index) in HaverRead" :key="index" class="SurveyDetailListTitle">
                <p>
                  <span style="color: red">*</span>
                  {{ index + 1 }}. {{ item.Title }}
                </p>
                <template v-if="!item.IsCanInput">
                  <el-radio-group v-model="item.answerStr">
                    <template v-for="(answer, index) in item.Items">
                      <el-radio :key="index" :label="answer.Value">{{ answer.Value }}</el-radio>
                    </template>
                  </el-radio-group>
                </template>
                <template v-else>
                  <el-input v-model="item.answerStr" placeholder=""></el-input>
                </template>
              </div>
            </div>
            <div class="CloseSubmitBtn">
              <el-button @click="CloseSurveyDetailsBtn">{{ $t('Customer.Close') }}</el-button>
              <el-button @click="SubmitSurveyBtn">{{ $t('Customer.Submit') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import request from '@/utils/request'
import { getToken } from '@/utils/token'
import { baseApiHost } from '../../utils/env'
let defaultImg = require('@/assets/imgs/face.png')
export default {
  props: ['page'],
  data() {
    return {
      baseApiHost: baseApiHost,
      dispatch: null,
      customColor: '#32CD32',
      value: true,
      selectedOptions: [],
      paymentList: [],
      selectItem: '1',
      defaultImg: defaultImg,
      memberPopupBox: false,
      Rules_Box: false,
      secondSettings: [],
      isSelectSettings: false, //开始选择菜单
      isNotice: false, //消息弹框
      switchTheme: '',
      value1: false, //主题开关
      selected: 1,
      CenterList: [], // 当前用户业务员
      CenterMessage: [],
      notification: [], // 通告一个月的记录
      ContactHere: false, // 业务员弹窗
      activity: false, //签到活动
      SpecialRewards: false,
      SignInList: [],
      SurveyDetailList: [],
      CheckMark: true,
      BeOverdue: false, // 签到打卡结束
      BindStore: false, //是否绑定店铺
      BindStoreList: [], // 是否绑定店铺
      ContentTwo: false, //第二句
      ContentThree: false, //第三句
      ContentFour: false, //第四句
      ContentFive: false, //第五句
      displayQuestionBox: false, // 调查问卷标题信息
      DisplayRulesDetails: false, // 规则详情弹窗
      SurveyDetailsHide: false, //任务问卷详情弹窗
      SurveyFileList: [], //获取调查问卷内容列表
      SurveyAllFileList: [], // 获取所有任务列表
      SurveyAllFileNumber: [], //获取任务总数、个数
      SurveyValue: false, // 未完成开关
      CompleteValue: true, //完成任务开关
      SurveyId: 0, // 任务列表id
      radio: '',
      pmgressbar: 0, // 进度条
      activeIndex: '1',
      orderPopBox: false,
      NavPopBigBoxPop: false, // 大盒子
      CloseSourcingBtn: false, // Sourcing引导
      CloseOrdersBtn: false, // Orders引导
      CloseWinnerBtn: false, // Winners引导
      ClosePricingBtn: false, // Pricing引导
      CloseWalletBtn: false, // Wallet引导
      CloseProductBtn: false, // Product引导
      CloseSummerBtn: false, // Summer引导
      ShowClickUSAdrop: false, // 设置按钮
      ShowContactNOW: true, //醒目文案
      ShowLock: false,
      ShowSurveyImg: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'theme', 'account', 'logout']),
    HaverRead: function () {
      var arr = []
      for (var j = 0; j < this.SurveyDetailList.Questions.length; j++) {
        var item = this.SurveyDetailList.Questions[j]
        var isAdd = true
        if (!!item.ParentTaskQuestionId) {
          console.log(item.ParentTaskQuestionId, 'ParentTaskQuestionId')
          for (var i = 0; i < this.SurveyDetailList.Questions.length; i++) {
            if (this.SurveyDetailList.Questions[i].Id == item.ParentTaskQuestionId) {
              //  isAdd =false;
              console.log(
                item.ParentTaskQuestionValue,
                this.SurveyDetailList.Questions[i].answerStr
              )
              if (
                !this.SurveyDetailList.Questions[i].answerStr ||
                item.ParentTaskQuestionValue != this.SurveyDetailList.Questions[i].answerStr
              ) {
                var isAdd = false
              }
              break
            }
          }
        }
        if (isAdd) {
          arr.push(item)
        } else {
          this.$set(item, 'answerStr', null)
        }
      }
      return arr
    },
    defaultActive() {
      console.log(this.$route)
      return this.$route.path
    }
  },
  //import引入的组件
  components: {
    // noticeCom: noticeCom,
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.globalClick(this.handleClickOut)
    this.menterData()
    this.getData()
    this.GetUnReadNewsCount()
    this.Contact() // 第一句
    this.ContactTwo() // 第二句
    this.ContactThree() // 第三句
    this.ContactFour() // 第四句
    this.ContactFive() // 第五句
    this.SpecialLog()
    this.CheckGuide() // 是否绑定店铺
    this.CheckGuidePop()
    this.SurveyFileImg()
    if (this.page == 'Store') {
      this.value4 = 1
    } else if (this.page == 'wallet') {
      this.value4 = 2
    }
    if (!this.userInfo) {
      if (getToken()) {
        this.getUserInfo()
      } else {
        this.$router.push('/')
      }
    }
  },
  //方法集合
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClickPush(path) {
      // 修复切换相同的路由 会重新请求页面和控制台报错问题
      if (path !== this.$route.path) {
        console.log(path)
        this.$router.push({ path: path })
      }
    },
    WelcomeBtn() {
      console.log(1111)
    },
    /* 划过显示设置的文案 */
    enterSetting: function () {
      this.ShowClickUSAdrop = true
      this.$forceUpdate()
    },
    leaveSetting: function () {
      this.ShowClickUSAdrop = false
      this.$forceUpdate()
    },
    closeBtn() {
      this.ShowContactNOW = false
    },
    /* Sourcing引导 */
    NextOrders() {
      this.CloseSourcingBtn = false
      this.CloseOrdersBtn = true
    },
    BackSourcing() {
      this.CloseSourcingBtn = true
      this.CloseOrdersBtn = false
    },
    NextWinner() {
      this.CloseWinnerBtn = true
      this.CloseOrdersBtn = false
    },
    BackOrders() {
      this.CloseOrdersBtn = true
      this.CloseWinnerBtn = false
    },
    BackWinners() {
      this.CloseWinnerBtn = true
      this.CloseProductBtn = false
    },
    BackWinnerNext() {
      this.CloseProductBtn = true
      this.CloseSummerBtn = false
    },
    NextProduct() {
      this.CloseWinnerBtn = false
      //this.CloseProductBtn = true
      this.ClosePricingBtn = true;
    },
    NextSummer() {
      this.CloseProductBtn = false
      this.CloseSummerBtn = true
    },
    NextPricing() {
      this.CloseProductBtn = false
      this.ClosePricingBtn = true
    },
    BackWinner() {
      this.CloseWinnerBtn = true
      this.ClosePricingBtn = false
    },
    BackWallet() {
      this.ClosePricingBtn = false
      this.CloseWinnerBtn = true
      /* this.CloseProductBtn = true
      this.ClosePricingBtn = false */
    },
    NextWallet() {
      this.ClosePricingBtn = false
      this.CloseWalletBtn = true
    },
    BackPricing() {
      this.ClosePricingBtn = true
      this.CloseWalletBtn = false
    },
    /* 记录头部导航弹窗 */
    CloseOkWallet() {
      let that = this
      that.AddNavPop()
      that.NavPopBigBoxPop = false
      that.CloseSourcingBtn = false
      /*     that.SurveyFileImg(); */
      that.SurveyFileBtn()
      /* if(this.SurveyAllFileNumber.Total ==0 ) {
          that.SurveyFileImg();
          this.displayQuestionBox = false;
        } else {
          that.SurveyFileBtn();
        } */
    },
    CheckGuidePop() {
      let that = this
      that
        .dispatch('takeNotes/TotalCountTn', {
          AppFrom: 'UD',
          EventType: 'GuidePop',
          EventPath: '/pageHeader',
          TriggerType: 101
        })
        .then((res) => {
          if (res.Success) {
            if (res.ResData > 0) {
              that.NavPopBigBoxPop = false
              that.CloseSourcingBtn = false
              //that.SurveyFileBtn();
            } else {
              that.NavPopBigBoxPop = true
              that.CloseSourcingBtn = true
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 记录接口 */
    AddNavPop() {
      let that = this
      this.dispatch('takeNotes/BuryingLogAddTn', {
        TriggerType: 101,
        Memo: 'click',
        AppFrom: 'UD',
        EventType: 'GuidePop',
        EventPath: '/pageHeader'
      })
        .then((res) => {
          if (res.Success) {
          } else {
            console.log(err)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 记录Order弹窗 */
    OrderPopBtn() {
      let that = this
      that
        .dispatch('takeNotes/TotalCountTn', {
          AppFrom: 'UD',
          EventType: 'GuidePop',
          EventPath: '/pageHeader',
          TriggerType: 101
        })
        .then((res) => {
          if (res.Success) {
            if (res.ResData == 0) {
              that.orderPopBox = true
            } else {
              that.$router.push('/orders')
            }
          } else {
            that.$router.push('/orders')
          }
          that.AddResotion()
        })
        .catch((err) => {
          that.$router.push('/orders')
        })
    },
    AddResotion() {
      let that = this
      this.dispatch('takeNotes/BuryingLogAddTn', {
        TriggerType: 101,
        Memo: 'click',
        AppFrom: 'UD',
        EventType: 'GuidePop',
        EventPath: '/orders'
      })
        .then((res) => {
          if (res.Success) {
          } else {
            console.log(err)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    BindMyStore() {
      this.$router.push('/Store')
    },
    SkipBtn() {
      this.$router.push('/orders')
    },
    /* 提交调查问卷 */
    SubmitSurveyBtn() {
      let that = this
      var reqData = {}
      reqData.TaskQuestionnaireId = that.SurveyDetailList.Id
      reqData.Results = []
      that.HaverRead.map((item) => {
        reqData.Results.push({
          Id: item.Id,
          Value: item.answerStr,
          IsAsk: true
        })
      })
      this.dispatch('surveyFile/AddQuestionnaireResultsSf', {
        ...reqData
      })
        .then((res) => {
          if (res.Success) {
            this.$message.success(this.$t('Customer.SubmittedSuccessfully'))
            setTimeout(() => {
              this.SurveyDetailsHide = false
            }, 300)
            this.CompleteTask(that.SurveyId)
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          // this.$message.error("The Value field is required.");
        })
    },
    /* 调查问卷 */
    GetByClick(item) {
      switch (item.Tag) {
        case 'AfterQuestionnaire':
          this.dispatch('surveyFile/GetByClickSf', {
            id: 1
          })
            .then((res) => {
              console.log(res, '1111')
              if (res.Success == true) {
                this.SurveyDetailsHide = true
                this.SurveyDetailList = res.ResData
                this.SurveyId = item.Id
              } else {
                this.$message.error(res.ErrMsg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
          break
        case 'AfterBindStore':
          this.$router.push('Store')
          this.CompleteRunTask(item.Id)
          break
        case 'AfterHelpCenter':
          this.CompleteTask(item.Id)
          this.$router.push('HelpCenter')
          break
        case 'AfterRecharge':
          this.$router.push('Wallet')
          this.CompleteRunTask(item.Id)
          break
      }
    },
    CloseSurveyDetailsBtn() {
      this.SurveyDetailsHide = false
    },
    OpenRulesBtn() {
      this.DisplayRulesDetails = true
    },
    /* 获取所有任务列表 */
    SurveyFileBtn() {
      let that = this
      this.dispatch('surveyFile/GetAlltListSf', {
        groupName: 'LhRegistTaskService'
      })
        .then((res) => {
          /* 获取任务列表 */
          if (res.Result.length == 0) {
            that.displayQuestionBox = false
          } else {
            that.displayQuestionBox = true
          }
          that.SurveyAllFileList = res.Result
          /* 获取进度 */
          that.SurveyAllFileNumber = res.MapData
          that.pmgressbar =
            (that.SurveyAllFileNumber.Executed / that.SurveyAllFileNumber.Total) * 100
          that.CheckGuide()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    SurveyFileImg() {
      let that = this
      this.dispatch('surveyFile/GetAlltListSf', {
        groupName: 'LhRegistTaskService'
      })
        .then((res) => {
          /* 获取任务列表 */
          if (res.Result.length == 0) {
            this.ShowSurveyImg = false
          } else {
            this.ShowSurveyImg = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 获取完成所有任务列表 */
    CompleteTask(Id) {
      let that = this
      request({
        method: 'post',
        url: '/api/LhMemberTask/CompleteTask' + '?taskId=' + Id,
        data: {
          taskId: Id
        }
      })
        .then((res) => {
          if (res.Success) {
            this.SurveyFileBtn()
          } else {
            console.log(err)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 任务进行中 */
    CompleteRunTask(Id) {
      let that = this
      request({
        method: 'post',
        url: '/api/LhMemberTask/RunTask' + '?taskId=' + Id,
        data: {
          taskId: Id
        }
      })
        .then((res) => {
          if (res.Success) {
            this.SurveyFileBtn()
          } else {
            console.log(err)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CloseRulesIconBtn() {
      this.DisplayRulesDetails = false
    },
    CloseRulesBtn() {
      this.displayQuestionBox = false
    },
    BrowseHelpCenter() {
      this.$router.push('HelpCenter')
    },
    TopWalletBtn() {
      this.$router.push('wallet')
    },
    ConnectStoreBtn() {
      this.$router.push('Store')
    },
    HelpBtn() {
      this.$router.push('HelpCenter')
    },
    /*签到按钮接口*/
    ClickSignIn() {
      this.dispatch('signIn/GetSignSg')
        .then((res) => {
          if (res.Success == true) {
            this.CurrentMonthList()
            this.$message.success(this.$t('header.signeduccessfully'))
          } else {
            S
            this.CheckSignToday()
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 签到列表数据*/
    CurrentMonthList() {
      this.dispatch('signIn/GetCurrentMonthListSg')
        .then((res) => {
          this.SignInList = res
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /*今日是否签到*/
    CheckSignToday() {
      this.dispatch('signIn/CheckSignTodaySg')
        .then((res) => {
          if (res.Result == false) {
            this.CheckMark = true
          } else if (res.Result == true) {
            this.CheckMark = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ClickSignBtn() {
      this.ClickSignIn()
    },
    /*签到弹出框*/
    SignIn() {
      this.activity = true
      this.CurrentMonthList()
      this.CheckSignToday()
    },
    ActivityOutline() {
      this.activity = false
    },
    /* 签到规则*/
    OpenRules() {
      this.Rules_Box = true
    },
    CloseRules() {
      this.Rules_Box = false
    },
    GetUnReadNewsCount() {
      this.dispatch('notification/GetUnReadNewsCountNo', { data: {} })
        .then((res) => {
          if (res.Success) {
            this.notification = res.Result
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ProfileUser() {
      this.$router.push('Profile')
    },
    headerBasic() {
      this.$router.push('Upgrade')
    },
    headerProfessional() {
      this.$router.push('Upgrade')
    },
    headerPremium() {
      this.$router.push('Upgrade')
    },
    /* 签到 */
    RemoveSpecial() {
      this.SpecialRewards = false
    },
    SpecialLog() {
      setTimeout(() => {
        this.SpecialRewards = true
      }, 8000)
    },
    /* 业务员*/
    ContactTwo() {
      setTimeout(() => {
        this.ContentTwo = true
      }, 2000)
      setTimeout(() => {
        this.ContentTwo = false
      }, 5000)
    },
    ContactThree() {
      setTimeout(() => {
        this.ContentThree = true
      }, 5000)
      setTimeout(() => {
        this.ContentThree = false
      }, 8000)
    },
    ContactFour() {
      setTimeout(() => {
        this.ContentFour = true
      }, 8000)
      setTimeout(() => {
        this.ContentFour = false
      }, 10000)
    },
    ContactFive() {
      setTimeout(() => {
        this.ContentFive = true
      }, 10000)
      setTimeout(() => {
        this.ContentFive = false
      }, 12000)
    },
    Contact() {
      setTimeout(() => {
        this.ContactHere = true
      }, 12000)
    },
    RemoveOutline() {
      this.ContactHere = false
    },
    /*  MyAgentBox() {
        this.memberPopupBox = !this.memberPopupBox;
        this.AgentData();
      }, */
    MyAgentBox() {
      this.$router.push('/FAQ')
    },
    close() {
      this.memberPopupBox = false
    },
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    AgentData() {
      let that = this
      this.dispatch('center/GetAgentByIdCr', {
        id: this.CenterMessage.AgentId
      })
        .then((res) => {
          if (res.Success == false) {
            /* this.$message.error(res.ErrMsg) */
          } else {
            that.CenterList = res.Result
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getData() {
      let that = this
      this.dispatch('center/messageCr')
        .then((res) => {
          that.CenterMessage = res.Result
          that.rawData = { ...res.Result }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClickOut(event) {
      this.isSelectSettings = false
      // }
    },
    /* pagePush(page) {
        if (page != this.page) {
          this.$router.push({ path: page });
        }
      }, */
    pagePush1() {
      this.$router.push({
        name: 'Video',
        params: {
          id: this.selected
        }
      })
    },
    selectSourcing(select) {
      if (this.page != select) {
        this.$router.push({ path: '/' + select })
      }
    },
    // 获取用户信息
    getUserInfo() {
      const vm = this
      this.dispatch('home/GetUserHm')
        .then((res) => {
          if (res.Success) {
            this.$store.commit('setUer', res.Result)
            this.CheckGuide()
          } else {
            this.$message(res.ErrMsg || vm.$t('tips.failed'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    hghf() {
      // console.log(111111);
    },
    toggleNotice() {
      // console.log(111111);
      // 切换消息
      this.$router.push('/ImportantNotices')
    },
    ProfileBtn() {
      this.$router.push('/ProfileSub')
    },
    SlicelgoutBtn() {
      // 清空缓存
      window.sessionStorage.clear()
      // 跳转到登录
      // window.location.reload()
      this.$router.push('/')
    },
    startSetting() {
      this.value1 = this.$store.state.switchTheme
      this.isSelectSettings = !this.isSelectSettings
    },
    // 设置菜单级层判断
    selectedSettings(index) {
      let that = this
      if (that.settings[index].children) {
        that.secondSettings = that.settings[index].children
      } else {
        that.changePage(that.settings[index].value)
      }
      this.changeLeftBackground = index
    },
    // 设置菜单跳转
    /*   changePage(value) {
        let that = this;
        if (value == "theme") {
          // this.$store.commit("changeTheme");
          return;
        } else if (value == "logout") {
          // 清空缓存
          window.sessionStorage.clear();
          // 跳转到登录
          this.$router.replace("/");
        } else {
          if (value != this.page) {
            this.$router.push("/" + value);
          }
        }
        that.secondSettings = [];
        that.isSelectSettings = false;
      }, */
    // 主题开关
    themeChange(e) {
      this.$store.commit('isshow', e)
      this.$store.commit('changeTheme')
      this.isSelectSettings = false
    },
    /* 活动打卡结束 */
    PunchOut() {
      this.BeOverdue = false
    },
    // 帮助
    helpBtn() {
      this.$router.push('/Store')
    },
    /* 绑定店铺 */
    LinkMyStoreBtn() {
      this.$router.push('/Store')
    },
    /* 是否绑定店铺 */
    CheckGuide() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result
          // 价格重置
          //console.log(res.Result, '123');
          if (res.Result.IsStoreComplete == true) {
            this.BindStoreList = res.Result
            this.BindStore = false
          } else {
            this.BindStore = true
          }
          if (res.Result.More7daysNoStoreOrNoGrade == true) {
            this.ShowLock = true
          } else {
            this.ShowLock = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dashboardBtn() {
      this.$router.push('/dashboard')
    },
    winnersBtn() {
      this.$router.push('/winners')
    }
  }
}
</script>
<style scoped>
.liBackground {
  border: 1px solid #30539c;
}
.SideBarBigBox {
  position: relative;
}
/* 绑定店铺 */
.LinkMyStore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 80px;
  cursor: pointer;
  background: #93df89;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  padding-bottom: 0px;
}
.LinkMyStore p:nth-child(1) {
  text-align: center;
  line-height: 35px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.LinkMyStore p:nth-child(2) {
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-style: italic;
  color: rgb(76, 139, 245);
  margin-top: -6px;
}
.SideBarBox {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 198px;
  height: 100%;
  background: #15104b;
}
.headerCommon {
  position: absolute;
  left: 230px;
  width: 80%;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.HeaderTitle {
  top: 60px;
}
.HeaderTitleTop {
  top: 15px;
}
.HeaderContent {
  display: flex;
  flex-direction: row;
}
.SideBarBoxStoreCommon {
  position: fixed;
  left: 0px;
  width: 213px;
  height: 100%;
  background: #15104b;
  z-index: 99;
}
.SideBarTitle {
  top: 0px;
}
.SideBarBoxStoreTop {
  top: 0px;
}
.HeaderContent {
  display: flex;
  flex-direction: row;
}
.multitudinous_icon {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
}
.contact_agent {
  display: flex;
}
.contact_agent p:nth-child(1) {
  font-size: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 70px;
  font-weight: bold;
  color: #15104b;
  word-break: normal;
}
.contact_agent p:nth-child(2) {
  font-size: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 70px;
  font-weight: bold;
  color: #15104b;
  margin-left: 30px;
  word-break: normal;
}
.SliceSmallBell_img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 1px;
  right: 235px;
  cursor: pointer;
}
.SliceSmallBell_img img {
  width: 20px;
  height: 20px;
  margin-top: 18px;
  margin-right: 30px;
  display: block;
}
.SliceProfile_img img {
  width: 20px;
  height: 20px;
  margin-top: 18px;
  margin-right: 30px;
  cursor: pointer;
  display: block;
}
.Slicelgout_img img {
  width: 20px;
  height: 20px;
  margin-top: 18px;
  margin-right: 30px;
  cursor: pointer;
  display: block;
}
.Welcome_Copywriting p {
  margin-top: 13px;
  color: #15104b;
  font-size: 23px;
  margin-right: 5px;
}
.head_sculpture {
  display: flex;
  margin-top: 30px;
}
.head_sculpture img {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  margin-left: 10px;
  /* display: block; */
}
.GradeId_Img p span img {
  width: 20px;
  height: 20px;
  border-radius: 0px;
}
.userInfoRealName {
  font-size: 16px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 5px;
  margin-left: 10px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.active {
  font-size: 18px;
  color: #fff;
}
.MyStore_menu {
  display: flex;
  margin-top: 20px;
  margin-left: 0px;
}
.MyStore_menu img {
  width: 25px;
  height: 25px;
}
.MyStore_menu p:nth-child(2) {
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
  margin-left: 10px;
}
.Products_menu {
  display: flex;
  margin-top: 0px;
  margin-left: 14.5px;
}
.Products_menu img {
  width: 23px;
  height: 23px;
  margin-left: 8px;
}
.Products_menu p:nth-child(2) {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
  margin-left: 10px;
}
.Sourcing_menu {
  display: flex;
  margin-top: 0px;
  margin-left: 0px;
}
.Sourcing_menu img {
  width: 25px;
  height: 25px;
}
.Sourcing_menu p:nth-child(2) {
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
  margin-left: 10px;
}
::v-deep .el-menu-item.is-active {
  color: #fff !important;
}
::v-deep .el-menu {
  background-color: transparent;
  border-right: none;
}
::v-deep .el-menu:hover {
  background-color: transparent !important;
  font-size: 20px;
}
::v-deep .el-submenu__title {
  padding-left: 0px !important;
  height: 45px;
  line-height: 45px;
}
::v-deep .el-submenu__title:hover {
  background-color: transparent !important;
}
::v-deep .el-icon-arrow-down {
  margin-left: 10px !important;
}
::v-deep .el-menu:hover {
  background-color: transparent;
}
::v-deep .el-menu-vertical-demo .el-menu-item:hover {
  background-color: transparent;
  background: none;
  -webkit-transform: scale(1.1, 1);
  -moz-transform: scale(1.1, 1);
  -o-transform: scale(1.1, 1);
}
::v-deep .el-menu-item {
  height: 50px;
  line-height: 50px;
}
::v-deep .tac .el-menu-item:hover {
  background-color: transparent !important;
  background: none !important;
}
::v-deep .el-submenu__title:hover {
  background: none;
}
::v-deep .el-submenu .el-menu-item {
  background-color: transparent;
  font-size: 12px !important;
}
::v-deep .el-col-12 {
  width: 80%;
}
::v-deep .el-col-12:hover {
  background: none;
  background-color: transparent;
}
::v-deep .el-submenu__icon-arrow {
  position: absolute;
  top: 53%;
  right: 5px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 12px;
}
.Agent {
  position: fixed;
  bottom: 205px;
  right: 30px;
  z-index: 99 !important;
  cursor: pointer;
  background: none;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.Agent img {
  width: 70px;
  height: 70px;
}
.Agent img:hover {
  width: 75px;
  height: 75px;
}
.headPortraitImg {
  position: fixed;
  bottom: 212px;
  right: 22px;
  /*border-radius: 30px;*/
  z-index: 99 !important;
  cursor: pointer;
  background: none;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.headPortraitImg img {
  width: 80px;
  height: 90px;
}
.Contact_agent {
  width: auto;
  height: 50px;
  background: #fff;
  line-height: 50px;
  position: fixed;
  bottom: 280px;
  right: 45px;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 10px;
}
.Contact_agent_three {
  width: auto;
  height: 55px;
  background: #fff;
  line-height: 50px;
  position: fixed;
  bottom: 280px;
  right: 35px;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 10px;
}
.Contact_agent_three p {
  line-height: 20px;
  color: #000;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
}
.Contact_agent_three::before {
  content: '';
  position: absolute;
  bottom: -15%;
  right: 60px;
  width: 15px;
  height: 15px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
}
.remove_outline {
  position: fixed;
  bottom: 300px;
  right: 33px;
  /*border-radius: 30px;*/
  z-index: 9999999999999 !important;
  cursor: pointer;
  font-size: 12px;
}
.Contact_agent p {
  line-height: 20px;
  color: #000;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
}
.Contact_agent::before {
  content: '';
  position: absolute;
  bottom: -15%;
  left: 90px;
  width: 15px;
  height: 15px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
}
.Contact_agent_Pssp {
  width: 200px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  position: fixed;
  bottom: 280px;
  right: 35px;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 10px;
}
.Contact_agent_Pssp p {
  line-height: 20px;
  color: #000;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
  word-break: normal;
}
.Contact_agent_Pssp::before {
  content: '';
  position: absolute;
  bottom: -15%;
  left: 90px;
  width: 15px;
  height: 15px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
}
.member_el-icon-close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.headportrait img {
  width: 80px;
  height: 80px;
  /*box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);*/
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 25px;
}
.member_box {
  /* width: 100%;
    height: 100%; */
  position: fixed;
  top: 300px;
  right: 200px;
  z-index: 99999;
  /*background: rgba(0, 0, 0, 0.6);*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_set {
  width: 640px;
  height: 220px;
  left: calc(50% - 660px);
  top: calc(50% - 500px);
  /*background: #000;
    opacity: 0.5;
    background: #fff;*/
  background-image: linear-gradient(#1f378b, #445790);
  opacity: 0.8;
  border-radius: 20px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_RealName {
  position: absolute;
  top: 25px;
  left: 120px;
}
.member_RealName p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 18px;
}
.member_AgentNames {
  position: absolute;
  top: 60px;
  left: 120px;
}
.member_AgentNames p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 14px;
}
.member_hello {
  position: absolute;
  top: 90px;
  left: 120px;
}
.member_hello p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 14px;
}
.member_Email {
  position: absolute;
  top: 85px;
  left: 20px;
}
.member_Email img {
  width: 30px;
  height: 30px;
}
.member_Email span:nth-child(2) {
  position: absolute;
  left: 40px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-wrap: break-word;
  height: 50px;
  width: 125px;
}
.member_Whatsapp {
  width: 165px;
  height: 50px;
  /*background: #ccc;*/
  position: absolute;
  top: 150px;
  left: 235px;
  line-height: 35px;
  border-radius: 10px;
  background: #2bb51b;
}
.member_Whatsapp img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 8px;
}
.member_Whatsapp span:nth-child(2) {
  position: absolute;
  left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  width: 200px;
  margin-top: 8px;
  font-size: 18px;
}
.member_Skype {
  width: 165px;
  height: 50px;
  /*background: #ccc;*/
  position: absolute;
  top: 150px;
  left: 30px;
  line-height: 45px;
  border-radius: 10px;
  background: #15ace5;
}
.member_Skype img {
  width: 33px;
  height: 33px;
  margin-top: 10px;
  margin-left: 25px;
}
.member_Skype span:nth-child(2) {
  position: absolute;
  left: 80px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  height: 50px;
  width: 100px;
  margin-top: 2px;
  word-wrap: break-word;
  font-size: 18px;
  /*  background: red; */
}

/* Telegram */
.member_Telegram {
  width: 165px;
  height: 50px;
  /*background: #ccc;*/
  position: absolute;
  top: 150px;
  left: 440px;
  line-height: 45px;
  border-radius: 10px;
  background: #15ace5;
}
.member_Telegram img {
  width: 31px;
  height: 31px;
  margin-top: 10px;
  margin-left: 15px;
}
.member_Telegram span:nth-child(2) {
  position: absolute;
  left: 60px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  height: 50px;
  width: 100px;
  margin-top: 2px;
  word-wrap: break-word;
  font-size: 18px;
  /*  background: red; */
}
/* 问卷调查 */
.SurveyFileBox {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 350px;
  right: 45px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
.SurveyFileContent img {
  width: 100%;
  height: 100%;
}
/* 问卷调查弹窗 */
.Question_box {
  /* width: 100%;
    height: 100%; */
  position: fixed;
  top: 300px;
  right: 200px;
  z-index: 99999;
  /*background: rgba(0, 0, 0, 0.6);*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Question_content {
  width: 565px;
  height: 220px;
  left: calc(50% - 660px);
  top: calc(50% - 500px);
  /*background: #000;
    opacity: 0.5;*/
  background: #fff;
  border-radius: 20px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.QuestionBox {
  width: 270px;
  height: auto;
  position: fixed;
  right: 10px;
  bottom: 260px;
  background: #ffffff;
  z-index: 99;
  border-radius: 8px;
  padding-bottom: 10px;
}
.QuestionTopBox {
  /*background: #6e9ae3;*/
  background: #bc0c33;
  width: 270px;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.DontMissOut_Img {
  display: flex;
}
.Group_img img {
  margin-left: 30px;
}
.DontMissOut_Word p {
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  margin-top: 15px;
  margin-left: 20px;
}
.CloseRulesBtn_Pop p {
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 30px;
}
.ProgressBarBox {
  display: flex;
}
.ProgressBar_Pop {
  margin-top: 10px;
  margin-left: 10px;
}
.GroupIcon {
  margin-left: 20px;
}
.GroupIcon img {
  width: 73px;
  height: 70px;
}
.elProgressBox {
  width: 150px;
}
.elProgressBox >>> .el-progress-bar {
  width: 100%;
}
.SurpriseJackpot p {
  font-size: 13px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 10px;
  word-break: normal;
  margin-top: 10px;
}
.RulesCompletedBox {
}
.CompletedBox {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}
.elProgressBoxImg {
  position: absolute;
  top: 30px;
  left: 10px;
}
.elProgressBoxImg span img:nth-child(2) {
  position: absolute;
  top: 0px;
  left: 130px;
}
.ContentBigBox {
  position: relative;
}
.ghostBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
}
.ghostBox p:nth-child(1) img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 99;
}
.ghostBox p:nth-child(2) img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 30px;
  left: 130px;
  z-index: 99;
}
.HalloweenBox {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}
.HalloweenBox img {
  width: 130px;
  height: 50px;
}
.NanGuaSpiderWeb {
  position: absolute;
  top: 135px;
  right: 0;
}
.NanGuaSpiderWeb img {
  width: 130px;
  height: 150px;
}
.PumpkinDynamics {
  position: absolute;
  top: 165px;
  right: 0px;
}
.PumpkinDynamics img {
  width: 100px;
  height: 80px;
}

.SurveyDetailListBox {
  margin-left: 80px;
  margin-top: 85px;
}
.SurveyDetailListTitle {
  padding-top: 0px;
}
.SurveyDetailListTitle p {
  padding-top: 10px;
}
.SurveyListContent p {
  margin-left: 15px;
  margin-top: 30px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyDetailListTitle {
  width: 700px;
  margin-top: -5px;
}
.SurveyDetailListTitle >>> .el-input__inner {
  width: 700px;
  margin-top: 10px;
  border-radius: 50px;
}
.SurveyDetailListTitle >>> .el-radio__label {
  line-height: 40px;
}
.SurveyFileDetailsBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyDetailsContent {
  width: 850px;
  left: calc(50% - 430px);
  top: calc(50% - 450px);
  margin-top: 180px;
  background-color: #fff;
  /*height: 450px;*/
  height: 530px;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  overflow-x: auto;
  padding-bottom: 50px;
}
.SurveyDetailsContent::-webkit-scrollbar {
  display: none;
}
.SurveyFileTitle {
  width: 850px;
  height: 80px;
  background: #bc0c34;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  z-index: 99;
}
.SurveyFileTitle p:nth-child(1) {
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding-top: 8px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyFileTitle p:nth-child(2) {
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding-top: 8px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.CloseSurveyDetails {
  position: absolute;
  top: 12px;
  right: 15px;
}
.CloseSurveyDetails >>> .el-icon-circle-close {
  color: #fff;
  font-size: 26px;
  cursor: pointer;
}
.CloseRulesBtn {
  text-align: center;
}
.CloseRulesBtn >>> .el-icon-circle-close {
  font-size: 40px;
  color: #fff;
  margin-top: 40px;
  cursor: pointer;
}
.RulesDescriptionTitle {
  width: 100%;
  height: 40px;
  background: #bc0c34;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: flex-end;
}
.RulesDescriptionTitle p:nth-child(1) {
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-right: 275px;
}
.RulesDescriptionTitle p:nth-child(2) {
  font-size: 22px;
  color: #fff;
  line-height: 40px;
  margin-right: 20px;
  cursor: pointer;
}
.USAdropCampaignTitle p {
  text-align: center;
  color: #bc0c34;
  font-size: 18px;
  margin-top: 15px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.WeHaveTitle p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.RuleOneTitle p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.RuleTwoTitle p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.RuleThreeTitle p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.RuleForuTitle p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.EveryParticipant p {
  font-size: 16px;
  margin-left: 50px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
  color: #52c593;
}
.RulesPopUp_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.RulesPopUp_Content_Box {
  width: 800px;
  left: calc(50% - 400px);
  top: calc(50% - 450px);
  margin-top: 230px;
  background-color: #fff;
  /*height: 450px;
    height: 360px;*/
  height: auto;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-bottom: 25px;
}
.RulesTitle {
  display: flex;
  margin-left: 8px;
  margin-top: 8px;
}
.RulesTitle p:nth-child(1) {
  color: #000;
  font-size: 18px;
}
.RulesTitle p:nth-child(2) {
  color: #000;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  margin-left: 5px;
}
.FinishBox {
  display: flex;
  margin-top: 10px;
}
.FinishBox >>> .el-button {
  background: none;
  border: none;
  font-size: 14px;
  margin-left: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 185px;
  cursor: pointer;
  padding-top: 8px;
  padding-left: 0px;
}
.FinishBox >>> .el-button.is-disabled {
  background: none;
  border: none;
}
.FinishSwitch >>> .el-switch__core {
  margin-top: 10px;
  margin-left: 20px;
}
.ConnectBox {
  display: flex;
  margin-top: 10px;
}
.ConnectBox:hover {
  background: #cccccc;
  width: 100%;
  height: 40px;
}
.ConnectQuestionnaire {
  font-size: 16px;
  margin-left: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 8px;
  width: 220px;
  cursor: pointer;
}
.ConnectSwitch >>> .el-switch__core {
  margin-top: 15px;
}
.CloseSubmitBtn {
  margin-left: 180px;
  margin-top: 30px;
}
.CloseSubmitBtn >>> .el-button:nth-child(1) {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  width: 150px;
  margin-left: 50px;
  border-radius: 50px;
}
.CloseSubmitBtn >>> .el-button:nth-child(2) {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  width: 150px;
  margin-left: 50px;
  background: #bc0c34;
  color: #fff;
  border-radius: 50px;
}
.CloseSubmitBtn >>> .el-button:nth-child(2):hover {
  width: 155px;
  height: 45px;
}
.CloseSubmitBtn >>> .el-button:nth-child(1):hover {
  width: 155px;
  height: 45px;
}

.NavPopBigBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.6);
}
.NavPopContentSourcing {
  width: 400px;
  height: 145px;
  /* background: #fff; */
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  left: calc(50% - 650px);
  top: calc(50% - 500px);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #fff;
}
.NavPopContentOneWord p {
  text-align: center;
  padding-top: 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.NavPopContentOneWord >>> .el-button {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 130px;
  height: 35px;
  margin-left: 130px;
  line-height: 5px;
  margin-top: 20px;
}
/* Sourcing引导 */
.NavGuideSourcing {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 780px);
  top: 11em;
}
.NavGuideSourcing p {
  position: absolute;
  top: 35px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 330px;
  word-break: normal;
}
.NavGuideSourcing >>> .el-button {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 130px;
  height: 35px;
  margin-left: 230px;
  line-height: 5px;
  margin-top: 98px;
  border-radius: 50px;
}
/* Orders引导 */
.NavGuideOrders {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 780px);
  top: 15.5em;
  line-height: 30px;
}
.NavGuideOrders p {
  position: absolute;
  top: 30px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
  width: 330px;
  word-break: normal;
}
.NavGuideOrdersBtn >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 180px;
  height: 38px;
  width: 80px;
  line-height: 5px;
  border-radius: 50px;
}
.NavGuideOrdersBtn >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 38px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 95px;
  border-radius: 50px;
}
/* Winner引导 */
.NavGuideWinner {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 780px);
  /*left: calc(50% - 450px);*/
  top: 20em;
}
.NavGuideWinner p {
  position: absolute;
  top: 30px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 330px;
  word-break: normal;
}
.NavGuideWinnerBtn {
  display: flex;
}
.NavGuideWinnerBtn >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  width: 80px;
  height: 35px;
  line-height: 5px;
  margin-left: 175px;
  margin-top: 95px;
  border-radius: 50px;
}
.NavGuideWinnerBtn >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 35px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 95px;
  border-radius: 50px;
}
.line {
  width: 100px;
  height: 5px;
  background: #fff;
  border-radius: 50px;
  border: #fff;
  position: absolute;
  top: 0px;
  left: 125px;
}

/* Product引导 */
.NavGuideProduct {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 780px);
  /*left: calc(50% - 450px);*/
  top: 24.8em;
}
.NavGuideProduct p {
  position: absolute;
  top: 30px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 330px;
  word-break: normal;
}

.NavGuideProductBtn {
  display: flex;
}
.NavGuideProductBtn >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 175px;
  width: 80px;
  margin-top: 90px;
  height: 35px;
  line-height: 5px;
  border-radius: 50px;
}
.NavGuideProductBtn >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 35px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 90px;
  border-radius: 50px;
}
.line {
  width: 100px;
  height: 5px;
  background: #fff;
  border-radius: 50px;
  border: #fff;
  position: absolute;
  top: 0px;
  left: 125px;
}

/* 2023Summer引导 */
.NavGuide2023Summer {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 750px);
  /*left: calc(50% - 450px);*/
  top: 25.2em;
}
.NavGuide2023Summer p {
  position: absolute;
  top: 40px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 330px;
  word-break: normal;
}
.NavGuide2023SummerBtn {
  display: flex;
}
.NavGuide2023SummerBtn >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 165px;
  width: 100px;
  height: 35px;
  line-height: 5px;
  margin-top: 95px;
  border-radius: 50px;
}
.NavGuide2023SummerBtn >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 35px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 95px;
  border-radius: 50px;
}
/* Pricing */
.NavGuidePricing {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav.png') no-repeat;
  background-size: 100% 100%;
  width: 500px;
  height: 160px;
  position: absolute;
  left: calc(50% - 780px);
  /*left: calc(50% - 325px);*/
  top: 34.5em;
}
.NavGuidePricing p {
  position: absolute;
  top: 40px;
  left: 130px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
  width: 330px;
  word-break: normal;
}
.NavGuidePricingBtn {
  display: flex;
}
.NavGuidePricing >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 185px;
  height: 35px;
  line-height: 5px;
  margin-top: 100px;
  border-radius: 50px;
}
.NavGuidePricing >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 35px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 100px;
  border-radius: 50px;
}
/* Wallet */
.NavGuideWallet {
  background: url('../../assets/imgs/NavGuide/Group_Pop_Nav6.png');
  background-size: 100% 100%;
  width: 400px;
  height: 188px;
  position: absolute;
  right: 35px;
  top: 130px;
}
.NavGuideWallet p {
  position: absolute;
  top: 70px;
  left: 32px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
  width: 330px;
  word-break: normal;
}
.NavGuideWalletBtn {
  display: flex;
}
.NavGuideWalletBtn >>> .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 77px;
  width: 100px;
  height: 35px;
  line-height: 5px;
  margin-top: 126px;
  border-radius: 50px;
}
.NavGuideWalletBtn >>> .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 100px;
  height: 35px;
  margin-left: 50px;
  line-height: 5px;
  margin-top: 126px;
  border-radius: 50px;
}
.Customer_WeAreDelighted {
  margin-left: 50px;
  margin-top: 10px;
}
.Customer_WeAreDelighted p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Customer_newCustomer {
  margin-left: 50px;
  margin-top: 3px;
}
.Customer_newCustomer p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_newCustomer p span:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
}

.Customer_InAddition {
  margin-left: 50px;
  margin-top: 3px;
}
.Customer_InAddition p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_InAddition p span:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Customer_TaskOne {
  text-align: center;
  margin-top: 20px;
}
.Customer_TaskOne p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Customer_TaskTwo {
  text-align: center;
  margin-top: 5px;
}
.Customer_TaskTwo p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Customer_OnceSuccessfully {
  margin-left: 50px;
  margin-top: 25px;
}
.Customer_OnceSuccessfully p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_OnceSuccessfully p span:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
}

.Customer_considerableSavings {
  margin-left: 50px;
  margin-top: 3px;
}
.Customer_considerableSavings p {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
</style>
