var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Validation", {
    attrs: { phoneNum: _vm.mobileNumber, phoneCode: _vm.areaCode },
    on: { onSuccess: _vm.onSuccess },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }