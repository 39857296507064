<template>
  <div class="TaxPage">
      <div class="mainPage">
          <div class="balanceNum">
            <span>Balance:</span>
            <span> $ {{ formatAmount(totalBalance.TotalBalance) }}</span>
          </div>
          <div class="searchBox">
              <div class="searchItems">
                  <div class="searchItem">
                      <span class="label">Select Bank Card</span>
                      <el-select v-model="cardType" id="cardSelect" clearable="" @change="changeOption">
                          <el-option
                              v-for="item in cardOptions"
                              :key="item.value"
                              :value="item.value"
                              >
                            <div class="optionItem" style="width: 100%; display: flex; justify-content: space-between;">
                              <span>{{item.cardNo}}</span>
                              <span>$ {{item.balance}}</span>
                            </div>
                          </el-option>
                      </el-select>
                  </div>
                 <div class="searchItem">
                      <span class="label">Date</span>
                      <el-date-picker
                          v-model="dateTime"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          :range-separator="$t('orders.to')"
                          :start-placeholder="$t('')"
                          :end-placeholder="$t('')"
                          >
                      </el-date-picker>
                  </div>
              </div>
              <button class="comBtn btn-bg0 min-wid200" @click="searchBtn">
                {{ $t("orders.search") }}
              </button>
          </div>
          <div class="table">
              <el-table
                  height="428px"
                  :data="tableData"
                  style="width: 100%">
                  <el-table-column
                      prop="OrderDate"
                      label="Date"
                      align="center"
                      width="180">
                  </el-table-column>
                  <el-table-column
                      prop="State"
                      label="State"
                      align="center"
                      width="180">
                  </el-table-column>
                  <el-table-column
                      prop="OrderNumber"
                      align="center"
                      label="Bank Settlement Number">
                  </el-table-column>
                  <el-table-column
                      prop="CardNo"
                      align="center"
                      label="Card No.">
                  </el-table-column>
                  <el-table-column prop="AmountStr" align="center" label="Amount">
                    <template slot-scope="scope">
                      <div :class="{'positive-amount': scope.row.Amount >= 0, 'negative-amount': scope.row.Amount < 0}">
                        <span v-if="scope.row.Amount >= 0" style="color: #04BD00;">$ +{{ formatAmount(scope.row.Amount) }}</span>
                        <span v-else style="color: red;">$ {{ formatAmount(scope.row.Amount) }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <div slot="empty" v-if="tableData&&tableData.length===0">
                      <el-empty :description="$t('notification.NoData')"></el-empty>
                  </div>
              </el-table>
          </div>
      </div>
      <div class="pagebox">
          <PagePagination
            :total="pageData.total"
            :page-size="pageData.pageSize"
            :current-page="pageData.page"
            @current-change="pageChange"
          />
      </div>
  </div>
</template>
<script>
import PagePagination from '@/components/PagePagination.vue';
export default {
  components: {
      PagePagination
  },
  data(){
    return {
      pageData:{
        total:0,
        pageSize: 10,
        page:1,
      },
      cardType:'',
      totalBalance: [],
      cartType:'1',
      dateTime: '',
      dialogForm: {
        BeginDate: "",
        EndDate: "",
      },
      tableData: [],
      cardOptions:[]
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.dateTime = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.GetCardList();
    this.GetTaxBalanceList();
  },
  methods:{
    formatAmount(amount) {
      // 将金额保留两位小数
      return Number(amount).toFixed(2);
    },
    // 获取卡号列表
    GetCardList() {
      let that = this;
      that.dispatch('wallet/GetCardListWa')
        .then(res => {
          this.totalBalance = res.Result;
          this.cardOptions = res.Result.taxForegroundBalanceCardVos.map(item => ({
            value: item.CardNo,
            cardNo: item.CardNo,
            balance: item.Balance,
          }));
          console.log(res, 889898)
        })
    },
    // 获取流水列表
    GetTaxBalanceList() {
      let that = this;
      that.dispatch('wallet/TaxBalanceListWa', {
        CustomerCode: "",
        CardNo: this.cardType,
        BeginDate: this.dateTime ? this.dateTime[0] : "",
        EndDate: this.dateTime ? this.dateTime[1] : "",
        PageIndex: this.pageData.page,
        PageSize: this.pageData.pageSize
      })
        .then(res => {
          this.tableData = res.Items;
          this.pageData.total = res.TotalItems;
        })
    },
    searchBtn() {
      this.GetTaxBalanceList()
    },
    timeChange() {
      if (this.dateTime.length) {
        this.dialogForm.BeginDate = this.dateTime[0];
        this.dialogForm.EndDate = this.dateTime[1];
      } else {
        this.dialogForm.BeginDate = "";
        this.dialogForm.EndDate = "";
      }
    },
    pageChange(page) {
      console.log(page,'page');
      this.pageData.page = page;
      this.GetTaxBalanceList();
    },
    getSelectOption(){
      return this.cardOptions.find(item => item.value === this.cardType)
    },
    changeOption(val){
      this.cardType= val
      console.log('111111', val)
      if (!val) {
        document.getElementById('cardSelect').outerHTML = '<input type="text" readonly="readonly" autocomplete="off" placeholder="Select" id="cardSelect" class="el-input__inner">'
      } else {
        const {cardNo, balance} = this.getSelectOption()
        document.getElementById('cardSelect').outerHTML=   `<div id="cardSelect" class="el-input__inner"><div style="display:flex;justify-content:space-between;color:#15104B"><span>${cardNo}</span><span>$${balance}</span></div></div>`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin optionItem() {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.el-select-dropdown {
  @include optionItem
}
.TaxPage {
  width: 1400px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  .mainPage  {
      .balanceNum {
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
        color: #15104B;
        font-size: 30px;
        font-weight: 500;
        span {
          font-size: 30px;
          font-weight: 500;
        }
      }
      .searchBox {
          width: 100%;
          height: 90px;
          background: #ffffff;
          border-radius: 10px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          .searchItems {
              width: calc(100% - 200px);
              display: flex;
              align-items: center;
              .searchItem {
                  margin-right: 30px;
                  .label {
                      margin-right: 10px;
                      color: #15104B;
                      font-size: 14px;
                  }


                  ::v-deep .el-input__inner {
                      width: 400px;
                      border-radius: 30px;
                  }
                  ::v-deep .el-range-separator {
                      width: 10%
                  }
              }

          }
      }
     .table {
      ::v-deep {
          .el-table {
              border-radius: 10px;
              thead {
                  tr th {
                      height: 70px;
                      .cell {
                          color:#15104B;
                          font-size: 16px;
                          font-weight: 700;

                      }
                  }
              }
          }
      }
     }

  }
  .pagebox {
    width: 100%;
    height: 70px;
    padding-top: 24px;
    margin-bottom: 24px;
  }
}
</style>
