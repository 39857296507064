/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
/*
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';

export const GetAgentById = (params) => request({// 获取问卷调查
    method: "get",
    url: '/api/LhAgent/GetAgentById',
    params
  });

export const message = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Member/GetMemberVo',
    data
  });
  export const ModifyMember = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/Member/ModifyMember',
    data
  });
  export const SubmitComment = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhMemberComment/SubmitComment',
    data
  });

export const GetPublishSettings = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/PublishSettings/GetPublishSettings',
    data
  });
export const SavePublishSettings = (data) => request({// 获取问卷调查
  method: "post",
  url: '/api/PublishSettings/SavePublishSettings',
  data
});
export const CancelSubscription = (data) => request({// 取消会员费订阅
  method: "post",
  url: '/api/Member/CancelSubscription',
  data
});

