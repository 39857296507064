<template>
  <div>
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="activeName">
        <div class="FAQTitle">
          <p>{{ $t("FAQ.FAQ") }}</p>
        </div>
        <div class="FAQTitleDetails">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="$t('FAQ.productItem')" name="1">
              <div class="withUSAdrop_words"></div>
              <div class="withUSAdrop_words">
                You may sign up to our platform https://www.usadrop.com.
                After you log in, you will see the best-selling products
                recently in our "Winners" category.
              </div>
              <div class="withUSAdrop_words">
                {{ $t("FAQ.TheyTrending") }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      activeNames: ['1']
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  created() {},
  mounted() {
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  },
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  position: relative;
}
.FAQTitle {
  text-align: center;
  margin-top: 40px;
}
.FAQTitle p {
  font-size: 30px;
  font-weight: 600;
  color: #21224e;
  font-family: "Regular";
}
.FAQTitleDetails {
  width: 1320px;
  height: auto;
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
}
.FAQTitleDetails >>> .el-collapse {
  width: 1280px;
  height: auto;
  margin-left: 20px;
}
.FAQTitleDetails >>> .el-collapse-item__header {
  font-size: 16px;
  font-family: "Regular";
  font-weight: 600;
}
.withUSAdrop_words {
  font-size: 14px;
  font-family: "Regular";
  line-height: 35px;
}
.withUSAdrop_words img {
  width: 1200px;
  height: 600px;
}
</style>
