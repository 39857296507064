var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName" }, [
        _c("div", { staticClass: "help_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("Help.Help")) + " "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "Help_something" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("Help.something")))]),
          ]),
          _c("div", { staticClass: "Help_ForGeneral" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("Help.ForGeneral")))]),
          ]),
          _c("div", { staticClass: "Help_WithinHours" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("Help.WithinHours")))]),
          ]),
          _c("div", { staticClass: "message_box" }, [
            _c("div", { staticClass: "message_Name" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("Help.Name")))]),
              _c(
                "p",
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.CenterMessage.Fillname,
                      callback: function ($$v) {
                        _vm.$set(_vm.CenterMessage, "Fillname", $$v)
                      },
                      expression: "CenterMessage.Fillname",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "message_Email" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("Help.Email")))]),
              _c(
                "p",
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.CenterMessage.Email,
                      callback: function ($$v) {
                        _vm.$set(_vm.CenterMessage, "Email", $$v)
                      },
                      expression: "CenterMessage.Email",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "InputContent" }, [
            _c("p", [_vm._v("*")]),
            _c("p", [_vm._v(_vm._s(_vm.$t("Help.Message")))]),
            _c(
              "p",
              [
                _c("el-input", {
                  attrs: { type: "textarea", rows: 2, placeholder: "" },
                  model: {
                    value: _vm.CenterMessage.Message,
                    callback: function ($$v) {
                      _vm.$set(_vm.CenterMessage, "Message", $$v)
                    },
                    expression: "CenterMessage.Message",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "btn" }, [
            _c("button", { on: { click: _vm.SendMessage } }, [
              _vm._v(_vm._s(_vm.$t("center.Submit"))),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }