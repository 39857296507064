var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formRef",
      attrs: {
        "label-position": "top",
        model: _vm.formModel,
        disabled: _vm.readonly,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("SupportTicket.VideoLink") } },
        [
          _c("el-input", {
            model: {
              value: _vm.formModel.VideoLink,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "VideoLink", $$v)
              },
              expression: "formModel.VideoLink",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("FeedImageUpload", {
            attrs: {
              "img-upload-path": _vm.imgUploadPath,
              "show-example": true,
              readonly: _vm.readonly,
            },
            model: {
              value: _vm.formModel.fileList,
              callback: function ($$v) {
                _vm.$set(_vm.formModel, "fileList", $$v)
              },
              expression: "formModel.fileList",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }