
<template>
  <div class="particulars" >
    <!-- 详情页面 -->
    <!-- 内容 -->
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      page: "Profiles",
      banner: "",
      itemname: ""
    };
  },
  components: {
  },
  mounted() {
  },
  methods: {},

};
</script>

<style scoped>
.particulars {
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
}
.particulars.dark {
  background: #1f1e2e !important;
}
</style>
