<template>
  <div class="container">
    <div class="headerBg_Img">
      <img src="../../assets/imgs/NewLogo/LogoOne.png" alt="">
    </div>
    <div class="loading_text">
      <p >
        <img src="../../assets/imgs/UpgradeToPro/failed.png" alt="">
      </p>
    </div>
    <div class="successful_box">
      <p>{{ $t("Airwallexfail.YourPayment") }}</p>
    </div>
    <div class="USAdrop_box">
      <p>{{ $t("Airwallexfail.PleasePersonal") }}</p>
    </div>
    <div class="Confirm_btn">
      <button @click="ConfirmBtn">{{ $t("Airwallexfail.Back") }}</button>
    </div>

  </div>
</template>
<script>
export default {
  data: () => {
    return {
    };
  },
  created() {
  },
  mounted() {
    // 自动加载进度
    // this.setTime();
  },
  methods: {
    ConfirmBtn() {
      window.location.href = `${window.location.origin}/Upgrade`
    }
  }
};
</script>
<style scoped>
.loading_text {
  text-align: center;
  margin-top: 60px;
}
.loading_text img {
  width: 350px;
  height: 250px;
}
.successful_box {
  text-align: center;
  margin-top: 25px;
}
.successful_box p{
  text-align: center;
  font-size: 26px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.USAdrop_box {
  text-align: center;
}
.USAdrop_box p{
  text-align: center;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
  color: #4d4d4d;
}
.Confirm_btn {
  text-align: center;
}
.Confirm_btn button {
  border: 0;
  margin-top: 50px;
  width: 150px;
  height: 40px;
  line-height: 40px;
  background: #15104B;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}
.Confirm_btn button:hover {
  background: #3C3772;
  color: #fff;
}
.headerBg_Img {
  margin-top: 50px;
  margin-left: 80px;
}
.headerBg_Img img{
  width: 130px;
  height: 130px;
}
</style>
