<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<template>
  <div class="content-left">
    <div class="login-info">
      <img
        src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580863760_logo.svg"
        alt=""
        class="logo1"
      />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2">Welcome to</div>
        <div class="ft-56 fw-800 color1 mb-40">USAdrop Shopify Sales</div>
        <div class="ft-24 fw-80s0 color2 mb-40">Partner with USAdrop for Unprecedented Growth.</div>
        <div class="ft-40 fw-800 color2 mb-48">Expand Your Shopify Saleswith USAdrop!</div>
        <div class="btnContent">
          <div class="btnInfo">
            <div class="title">Financial Backing</div>
            <div class="word">
              Enjoy robust startup funds from the very beginning, ensuring worry-free business
              initiation.
            </div>
          </div>
          <div class="btnInfo">
            <div class="title">Order Fulfillment</div>
            <div class="word">
              Our efficient order fulfillment system guarantees timely delivery of your products to
              customers
            </div>
          </div>
          <div class="btnInfo">
            <div class="title">Payment Gateway</div>
            <div class="word">
              Secure and reliable payment gateways covering a variety of payment methods, offering
              compre-hensive support for your business
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.login-info {
  // width: 60%;
  margin-right: 80px;
  text-align: left;
  .logo1 {
    width: 215px;
    height: 128px;
    margin-bottom: 40px;
  }
  .welcome-title {
  }
  .btnContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btnInfo {
      width: 276px;
      height: 208px;
      text-align: center;
      background: #e0e3f2;
      border-radius: 16px;
      margin-right: 24px;
      padding: 40px 28px;
      .title {
        color: $color2;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 14px;
      }
      .word {
        color: #5c5c90;
        font-size: 14px;
      }
    }
  }
}
.login-content {
  width: 503px;
  height: 708px;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 20px 40px;
  position: relative;
  .logo2 {
    width: 90px;
    height: 64px;
    position: absolute;
    left: 40px;
    margin-bottom: 30px;
  }
  .login-head {
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    margin-top: 104px;
    margin-bottom: 40px;
    text-align: center;
  }
  .forget {
    width: 100%;
    text-align: right;
    position: absolute;
    top: 44px;
  }
  .option {
    width: 420px;
    height: 100px;
    margin: 0 auto 10px;
    padding-left: 3px;
    ::v-deep .el-input__inner {
      // box-shadow: none;
      border-radius: 50px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      width: 100%;
      height: 42px;
      border: 1px solid #cccccc;
      // .el-input__inner {
      //   border-radius: 50px;
      //   font-family: 'Regular';
      //   -webkit-font-smoothing: antialiased;
      //   width: 100%;
      //   height: 48px;
      //   border: 1px solid #cccccc;
      // }
    }
  }
  .login-bottom {
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .el-button {
      width: 226px;
      height: 44px;
      background: $color2;
      font-size: 16px;
      margin-bottom: 8px;
      color: #ffffff;
      border-radius: 30px;
    }
    .small {
      font-size: 14px;
      color: #999999;
    }
  }

  .demo-ruleForm {
    ::v-deep .el-form-item {
      margin-bottom: 0 !important;
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
