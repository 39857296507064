<template>
  <div class="particulars">
    <div class="activeName">
      <div class="left">
        <div class="wrap">
          <div class="video">
            <DpVideo
              ref="dpVideo"
              :videoId="currentVideoData.Id"
              :key="currentVideoData.Id"
              :poster="currentVideoData.CoverUrl"
              :src="currentVideoData.VideoUrl"
              :canSeeTime="currentVideoData.CanSeeTime"
              :seekTime="currentVideoData.seekTime"
              @play="handlePlay(currentVideoData)"
              @canSeePause="handleCanSeePause(index)"
              @videoEnded="handleVideoEnded"
              :id="`dp-tutorial-detail`"
            />
          </div>
          <div class="info">
            <div class="title">
              {{ currentVideoData.Name }}
            </div>
            <div class="show_icon_content">
              <div class="content" :class="['content', { expanded: isExpanded }]">
                {{ currentVideoData.Introduce }}
              </div>
              <div class="show_icon" @click="toggleContent">
                <i class="el-icon-arrow-down" v-if="!isExpanded"></i>
                <i class="el-icon-arrow-up" v-else></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="upgradeVisible" class="big_video">
          <div class="members_video">This course is exclusive to members</div>
          <div class="become_video">Become a member to watch the full video</div>
          <div @click="jumpPage" class="subscribe_now_btn">
            <p>Subscribe now</p>
          </div>
          <div @click="ByVideo" class="subscribe_price_box" v-if="currentVideoData.Price != 0">
            <div class="lock_img">
              <img src="@/assets/imgs/Bank-Transfer/lock.svg" alt="" />
            </div>
            <div class="videp_price">Buy for ${{ currentVideoData.Price }}</div>
          </div>
        </div>
        <div class="big_video" v-if="show_next_video">
          <div class="per_next_toplay_box">
            <div class="previous_lesson" v-if="previousVideoData">
              <p>Previous Lesson</p>
              <div class="previous_lesson_video" @click="playNextVideo(previousVideoData)">
                <img :src="previousVideoData.CoverUrl" alt="" />
              </div>
            </div>
            <div class="function_buttons">
              <div class="pre_video_img" @click="preVideo">
                <img src="@/assets/imgs/video/pre_video.png" alt="" />
              </div>
              <div class="to_replay_img" @click="toReplayVideo">
                <img src="@/assets/imgs/video/to_replay.png" alt="" />
              </div>
              <div class="next_video_img" @click="nextVideo">
                <img src="@/assets/imgs/video/next_video.png" alt="" />
              </div>
            </div>
            <div class="next_lesson" v-if="nextVideoData">
              <p>Next Lesson</p>
              <div class="next_lesson_video" @click="playNextVideo(nextVideoData)">
                <img :src="nextVideoData.CoverUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div class="title" @click="MoreBtn">More courses</div>
          <div class="list">
            <div class="list-item" v-for="(item, index) in moreList" :key="index" @click="playBigVideo(item)">
              <div class="item-video">
                <div class="coverurl_video">
                  <img :src="item.CoverUrl" alt="" />
                </div>
                <!--  <DpVideo
                  :poster="item.CoverUrl"
                  :src="item.VideoUrl"
                  :id="`dp-tutorial-more${index}`"
                  :canSeeTime="item.CanSeeTime"
                  :seekTime="item.seekTime"
                  @canSeePause="handleCanSeePause(index)"
                /> -->
              </div>
              <div class="item-describe">{{ item.Name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="comments">Comments：</div>
        <div class="block">
          <div class="block-item" v-for="(item, index) in commentList" :key="index">
            <div class="lt">
              <el-image class="avatar" :src="item.Avatar || defaultAvatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="rt">
              <div class="header">
                <div class="user">
                  <div class="name">{{ item.MemberName }}</div>
                  <div class="time">{{ item.CreateTime.split(' ')[0] }}</div>
                </div>
                <div class="replay">{{ item.Comment }}</div>
              </div>
              <div class="footer">
                <span v-show="item.Reply">Reply: {{ item.Reply }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="add">
          <el-input
            v-model="comment"
            class="add-input"
            placeholder="Add a new comment..."
          ></el-input>
        </div>
        <el-button size="mini" @click="addComment">Comment</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UpgradeDialog from '@/components/UpgradeDialog.vue'
import DpVideo from '@/components/DpVideo/index.vue'
import { addComment, getCommentList, ListFromDetail, BuyVedioRights, getVideoDetail } from '@/api/proConnect'

export default {
  components: { UpgradeDialog, DpVideo },
  data() {
    return {
      dispatch: null,
      upgradeVisible: false,
      show_next_video: false,
      comment: '',
      moreList: [],
      commentList: [],
      paymentList: [],
      currentVideoData: {},
      previousVideoData: {},
      nextVideoData: {},
      defaultAvatar: require('@/assets/imgs/Bank-Transfer/headportrait.png'),
      currentIndex: 0,
      videoList: [],
      isExpanded: false,
      IsPay: []
    }
  },
  computed: mapState(['userInfo', 'theme']),
  created() {
    const storedCurrentVideoData = localStorage.getItem('currentVideoData')
    const storedVideoList = localStorage.getItem('videoList')
    const storedIndex = localStorage.getItem('currentIndex')
    if (storedCurrentVideoData && storedVideoList && storedIndex) {
      this.currentVideoData = JSON.parse(storedCurrentVideoData)
      this.videoList = JSON.parse(storedVideoList)
      this.currentIndex = parseInt(storedIndex)
      this.updateAdjacentVideos()

      if (this.currentVideoData && this.currentVideoData.Id) {
        console.log('Current Video ID:', this.currentVideoData.Id) // 日志确认
        this.videoId = this.currentVideoData.Id // 设置 videoId
      } else {
        console.error('Current video data is invalid')
      }

      this.$nextTick(() => {
        this.$refs.dpVideo.seekTo((this.currentVideoData.Process || 0) / 100)
      })
    }
  },
  mounted() {
    this.getCommentData()
    this.menterData()
  },
  methods: {
    getVideoDetailsList() {
      let videoId = this.currentVideoData.Id;  // 获取视频的 ID
      getVideoDetail({ VideoId: videoId })
        .then((res) => {
          if(res.IsPayed == true) {
            this.upgradeVisible = false
            this.currentVideoData.CanSeeTime = ''
          }
          console.log('Video details response:', res);
        })
        .catch((error) => {
          console.error('res.ErrMsg', error);
        });
    },
    toggleContent() {
      this.isExpanded = !this.isExpanded
    },
    seekToLastWatched() {
      const lastWatchedTime = localStorage.getItem(`videoTime_${this.currentVideoData.Id}`)
      if (lastWatchedTime) {
        this.currentVideoData.seekTime = parseFloat(lastWatchedTime)
      }
    },
    updateAdjacentVideos() {
      this.previousVideoData = this.currentIndex > 0 ? this.videoList[this.currentIndex - 1] : null
      this.nextVideoData =
        this.currentIndex < this.videoList.length - 1 ? this.videoList[this.currentIndex + 1] : null
    },
    preVideo() {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.switchVideo(this.currentIndex)
      }
    },
    nextVideo() {
      if (this.currentIndex < this.videoList.length - 1) {
        this.currentIndex++
        this.switchVideo(this.currentIndex)
      }
    },
    switchVideo(index) {
      this.currentVideoData = this.videoList[index]
      localStorage.setItem('currentVideoData', JSON.stringify(this.currentVideoData))
      localStorage.setItem('currentIndex', this.currentIndex)
      this.getCommentData()
      this.seekToLastWatched()
      this.updateAdjacentVideos()
    },
    playNextVideo(video) {
      this.currentVideoData = {
        ...video,
        VideoUrl: video.VideoUrl + '?time=' + new Date().getTime()
      }
      localStorage.setItem('currentVideoData', JSON.stringify(video))
      this.show_next_video = false // 关闭弹窗
      this.getCommentData() // 更新评论
    },
    toReplayVideo() {
      this.show_next_video = false // 关闭弹窗
      this.$refs.dpVideo.dp.seek(0) // 将视频重置到开头
      this.$refs.dpVideo.dp.play() // 播放视频
    },
    handleVideoEnded() {
      this.show_next_video = true // 显示弹窗
    },
    jumpPage() {
      this.$router.push('/Upgrade')
    },
    MoreBtn() {
      this.$router.push('/DropshippingTutorial')
    },
    ByVideo() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      if (this.paymentList.Balance == 0) {
        //this.$message.error('Sorry, your credit is running low')
        setTimeout(() => {
          this.$router.push("/wallet");
        }, 1000)
      }
      let postData = {
        VideoId: this.currentVideoData.Id
      }
      BuyVedioRights(postData)
        .then((res) => {
          loading.close();
          if (res.Success) {
            this.$message.success('Successfully purchased video')
            this.getVideoDetailsList()
            this.upgradeVisible = false
          } else {
            this.$message.error(res.Msg || '购买失败')
          }
        })
        .catch((error) => {
          console.error('res.ErrMsg', error)
        })
    },
    playBigVideo(item) {
      this.currentVideoData = { ...item }
      localStorage.setItem('currentVideoData', JSON.stringify(item)) // 保存到本地存储
      this.getCommentData()
      this.seekToLastWatched() // 自动跳到上次播放位置
      this.show_next_video = false
      this.upgradeVisible = false
    },
    addComment() {
      if (this.comment.trim() === '') {
        this.$message.warning('Please enter the comment content!')
      }
      let postData = {
        VideoId: this.currentVideoData.Id,
        Comment: this.comment
        // MemberId: 0
      }
      addComment(postData)
        .then((res) => {
          if (res.Success) {
            this.$message.success('Comment added successfully')
            this.getCommentData()
            this.comment = ''
          }
        })
        .catch((error) => {
          console.error('Error adding comment:', error)
        })
    },
    getCommentData() {
      let postData = {
        VideoId: this.currentVideoData.Id,
        PageIndex: 1,
        PageSize: 999
      }
      getCommentList(postData).then((res) => {
        this.commentList = res.Items
      })
    },
    getRecommendData() {
      let postData = {
        Catlog: '',
        PageIndex: 1,
        PageSize: 3
      }
      ListFromDetail(postData).then((res) => {
        if (res.Success) {
          this.moreList = res.Items.map((item) => {
            // 根据会员等级判断
            if (this.paymentList.GradeId == 2 || this.paymentList.GradeId == 3) {
              // 会员
              return {
                ...item,
                CanSeeTime: ''
              }
            } else {
              // 非会员
              if (item.IsPayed == true) {
                // 非会员且已购买，可以观看
                return {
                  ...item,
                  CanSeeTime: ''
                }
              } else {
                // 非会员且未购买，设置观看时间限制
                return {
                  ...item,
                  CanSeeTime: item.ExpenseType === 2 ? 60 : ''
                }
              }
            }
          })
        }
      })
    },
    showControls(index) {
      this.moreList[index].controls = true
    },
    hideControls(index) {
      this.moreList[index].controls = false
    },
    handlePlay() {
      const currentTime = this.$refs.dpVideo.dp.currentTime
      localStorage.setItem(`videoTime_${this.currentVideoData.seekTime.Id}`, currentTime)
    },
    handleCanSeePause() {
      this.upgradeVisible = true
    },
    menterData() {
      const { dispatch } = this.$store
      dispatch('login/getPaymentList').then((res) => {
        const { Result, Success } = res || {}
        if (Success && Result) {
          this.paymentList = res.Result
        }
        this.getRecommendData()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  font-family: 'Regular';
  .activeName {
    width: 1320px;
    // background-color: #ffffff;
    overflow: hidden;
    padding-bottom: 30px;
    display: flex;
  }
}

.left {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  .big_video {
    background: rgba(0, 0, 0, 0.74);
    backdrop-filter: blur(3px);
    width: 910px;
    height: 450px;
    position: absolute;
    top: 30px;
    left: 20px;
    border-radius: 21px;
    .members_video {
      font-size: 36px;
      color: #fff;
      text-align: center;
      margin-top: 90px;
    }
    .become_video {
      font-size: 24px;
      color: #fff;
      text-align: center;
    }
    .subscribe_now_btn {
      width: 275px;
      height: 56px;
      background: #fff;
      text-align: center;
      border-radius: 50px;
      margin-left: 300px;
      margin-top: 40px;
      cursor: pointer;
      transition: transform 0.3s;
      p {
        color: #15104b;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        line-height: 56px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
    .subscribe_price_box {
      display: flex;
      width: 215px;
      height: 40px;
      border: 1px solid #fff;
      margin-left: 330px;
      margin-top: 30px;
      border-radius: 50px;
      cursor: pointer;
      transition: transform 0.3s;
      .lock_img {
        img {
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-left: 30px;
        }
      }
      .videp_price {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        margin-top: 8px;
        margin-left: 10px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
    .per_next_toplay_box {
      display: flex;
      justify-content: center; /* Center the content horizontally */
      align-items: center;     /* Center the content vertically */
      flex-direction: row;  /* Stack the elements vertically */
      .previous_lesson,
      .next_lesson {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        margin-top: 80px;
        margin-left: 20px;
        p {
          margin-left: 70px;
        }
        .previous_lesson_video,
        .next_lesson_video {
          width: 300px;
          height: 179px;
          border-radius: 20px;
          margin-top: 30px;
          cursor: pointer;
          img {
            width: 300px;
            height: 179px;
            border-radius: 20px;
            transition: transform 0.3s ease-in-out;
          }
          &:hover img {
            transform: scale(1.06); /* 悬停时放大 */
          }
        }
      }
      .function_buttons {
        display: flex;
        margin-top: 150px;
        justify-content: center; /* Center the buttons horizontally */
        .pre_video_img,
        .to_replay_img,
        .next_video_img {
          width: 40px;
          height: 40px;
          margin-left: 20px;
          cursor: pointer;
          img {
            width: 40px;
            height: 40px;
            transition: transform 0.3s ease-in-out;
          }
          &:hover img {
            transform: scale(1.1); /* 悬停时放大 */
          }
        }
      }
    }
  }
  .wrap {
    height: auto;
    background-color: #fff;
    border-radius: 21px;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    .video {
      flex: 1;
      max-height: 450px;
      border-radius: 21px;
      height: 450px;
    }
    /*.info {
      //@debugheight: 90px;
      margin-top: 10px;
      .title {
        font-size: 24px;
        font-weight: 600;
      }
      .show_icon_content {
        display: flex;
      }
      .content {
        width: 880px;
        font-weight: 500;
        font-size: 16px;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        transition: all 0.3s ease;
      }
      .show_icon {
        margin-top: 30px;
        ::v-deep .el-icon-arrow-down {
          font-size: 20px;
        }
        ::v-deep .el-icon-arrow-up {
          font-size: 20px;
        }
      }
    }*/
    .info {
      .title {
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
      }
      .show_icon_content {
        display: flex;
      }
      .content {
        width: 880px;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 50px; // 初始高度
        transition: max-height 0.3s ease;
        &.expanded {
          max-height: 300px; // 展开后的最大高度，根据需要调整
          -webkit-line-clamp: unset; // 移除行数限制
        }
      }
      .show_icon {
        ::v-deep .el-icon-arrow-down {
          font-size: 20px;
        }
        ::v-deep .el-icon-arrow-up {
          font-size: 20px;
        }
      }
    }
  }
  .more {
    flex: 1;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 21px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 24px;
      font-weight: 600;
      align-content: center;
      height: 40px;
      margin-left: 20px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
    }
    &:hover .title {
      transform: scale(1.03); /* 悬停时放大 */
    }
    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      margin-top: 12px;
      .list-item {
        .item-video {
          height: 180px;
          border-radius: 21px;
        }
        .coverurl_video {
          width: 280px;
          height: 180px;
          cursor: pointer;
          img {
            width: 280px;
            height: 180px;
            border-radius: 20px;
            transition: transform 0.3s ease-in-out;
          }
          &:hover img {
            transform: scale(1.06); /* 悬停时放大 */
          }
        }
        .item-describe {
          align-content: center;
          font-weight: 600;
          font-size: 13px;
          text-overflow: ellipsis;
          margin-top: 15px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

.right {
  width: 350px;
  height: 100vh;
  min-width: 0;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 21px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 20px 30px 20px;

  > .comments {
    font-weight: 600;
    font-size: 24px;
    box-sizing: border-box;
  }
  > .block {
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 10px 0;
    .block-item {
      min-height: 110px;
      display: flex;
      .lt {
        width: 42px;
        margin-right: 10px;
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          // background-color: #f5f7fa;
          align-content: center;
          text-align: center;
          background-color: #f5f7fa;
        }
      }
      .rt {
        flex: 1;
        display: flex;
        flex-direction: column;
        .header {
          flex: 1;
          background-color: #e6e6e6;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 6px 12px;
          display: flex;
          flex-direction: column;
          .user {
            height: 24px;
            display: flex;
            .name {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 1px;
              width: 150px;
              -webkit-line-clamp: 1;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
            .time {
              font-size: 12px;
              color: #999;
              margin-left: 10px;
              // -webkit-line-clamp: 1;
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // text-overflow: ellipsis;
            }
          }
          .replay {
            flex: 1;
            width: 230px;
            margin-top: 10px;
            line-height: 20px;
            font-weight: 400;
            font-size: 16px;
            // overflow-wrap: break-word;
            letter-spacing: 0.3px;
          }
        }
        .footer {
          width: 260px;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          min-height: 26px;
          cursor: pointer;
          padding: 0 6px;
        }
      }
    }
  }
  > .add {
    height: 46px;
    .add-input {
      ::v-deep .el-input__inner {
        background-color: #e6e6e6;
      }
    }
  }
}
</style>
