<template>
  <div>
    <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
      <div class="activeName_big_box">
        <div class="Filter_box">
          <div class="search_ProductName">
            <el-input v-model="keywords" :placeholder="$t('Inventorylist.ProductName')" class="input-with-select"></el-input>
            <el-button @click="searchProductName">{{ $t("Inventorylist.search") }}</el-button>
          </div>
          <div class="search_Warehouse">
           <el-select
           v-model="selectWarehouse"
           filterable
           clearable
           :placeholder="$t('Inventorylist.Warehouse')"
           class="myselect">
           <el-option
             v-for="item in WarehouseList"
             :key="item.index"
             :label="item.NameEn"
             :value="item.WarehouseCode">
           </el-option>
         </el-select>
            <el-button class="" @click="searchWarehouseCode">{{ $t("Inventorylist.search") }}</el-button>
          </div>
        </div>
        <div class="activeName">
          <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('Inventorylist.Inventorylist')" class="first" name="first">
              <template v-if="StoreList && StoreList.length > 0">
                <el-table :data="StoreList" style="width: 100%">
                  <el-table-column
                    prop="ProductName"
                    align="center"
                    :label="$t('Inventorylist.ProductName')">
                  </el-table-column>
                  <el-table-column prop="SkuCode" align="center" :label="$t('Inventorylist.SKU')">
                  </el-table-column>
                  <el-table-column
                    prop="WarehouseNameEn"
                    align="center"
                    :label="$t('Inventorylist.Warehouse')">
                  </el-table-column>
                  <el-table-column
                    prop="AvailableStock"
                    align="center"
                    :label="$t('Inventorylist.AvailableStock')">
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <el-pagination
                    layout="prev, pager, next,slot"
                    :total="options[0].total"
                    :page-size="pageSize"
                    :current-page="options[0].page"
                    :page-sizes="[]"
                    @size-change="handleSizeChange"
                    @current-change="pageChange">
                  <span class="pageSizeInput">
                    <template>
                      <el-select v-model="pageSize" @change="selectChanged">
                        <el-option
                          v-for="item in optionsSize"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </span>
                  </el-pagination>
                  <div @keyup.enter="getPageList(0)" class="page_sign">
                    <span>{{ $t("orders.get") }}</span>
                    <input type="text" v-model="options[0].jumpPage" />
                  </div>
                </div>
              </template>
              <el-empty :description="$t('Inventorylist.Nodata')" v-else></el-empty>
            </el-tab-pane>
          </el-tabs>
          <el-button @click="ExportListBtn" v-if="paymentList.IsAdmin == true" class="Inventorylist">{{
            $t("Inventorylist.export")
          }}</el-button>
          <el-button @click="CloseListBtn" class="InventorylistClose">{{
            $t("Inventorylist.close")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { baseApiHost  } from '@/utils/env.js';
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      baseApiHost:baseApiHost,
      page: "first",
      activeName: "first",
      banner: "",
      itemname: "",
      StoreList: [], // 会员店铺列表
      keywords: "", //关键字搜索
      selectWarehouse: "",
      value: "",
      currentPage: 1,
      pageSize: 10,
      paymentList: [],
      options: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
      ],
      WarehouseList: "", //高级搜索 国家
      optionsSize:[
         {
           value: 10,
           label: "10",
         },
         {
           value: 20,
           label: "20",
         },
         {
           value: 50,
           label: "50",
         },
         {
           value: 100,
           label: "100",
         },
       ]
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  created() {},
  mounted() {
    this.dispatch = this.$store.dispatch
    this.banner = this.$t("store.bannername");
    this.itemname = this.$t("store.itemname");
    this.getData();
    this.GetWarehouseList();
    this.menterData();
  },
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
      .then((res) => {
        this.paymentList = res.Result;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    pageChange(page) {
      this.options[0].page = page;
      this.getData();
    },
    getPageList(page) { //分页器跳转页数
      this.options[0].page = this.options[0].jumpPage > 0 ? Number(this.options[0].jumpPage) : 1;
      this.getData();
    },
    handleSizeChange() {
      this.options[0].pageSize = pageSize;
      this.getData();
    },
    selectChanged(pageSize) {
      this.options[0].pageSize = pageSize;
      this.getData();
     },
    CloseListBtn() {
      this.$router.push("/Store");
    },
    searchProductName() {
      this.getData();
    },
    searchWarehouseCode() {
      this.getData();
    },
    ExportListBtn() {  // 导出备货库存列表
      this.dispatch('store/InventoryExportSt',{
          Keyword: this.keywords,
          WarehouseCode: this.selectWarehouse,
          CurrentPageIndex: this.options[0].page,
          PageSize: this.options[0].pageSize,
        })
        .then((res) => {
          if (res.Success) {
            window.open(this.baseApiHost() + res.Result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() { // 获取备货库存列表
       this.dispatch('store/InventoryGetListSt',{
          Keyword: this.keywords,
          WarehouseCode: this.selectWarehouse,
          CurrentPageIndex: this.options[0].page,
          PageSize: this.options[0].pageSize,
        })
        .then((res) => {
          this.StoreList = res.Items;
          this.options[0].total = res.TotalItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetWarehouseList() {  // 仓库列表
      this.dispatch('store/GetWarehouseListSt')
        .then((res) => {
          this.WarehouseList = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs {
  border-radius: 10px;
}
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  border-radius: 10px;
  font-family: "Regular";
}
.activeName {
  width: 1400px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  padding-bottom: 32px;
  margin-top: 30px;
  font-family: "Regular";
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 20px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: "Regular";
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: "Regular";
}
.activeName .Inventorylist {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  color: #3F6AFF;
  font-size: 16px;
  border: 2px solid #3F6AFF;
  z-index: 9999999;
}
.activeName .Inventorylist:hover {
  background: #3f6aff;
  color: #fff;
}
.activeName .InventorylistClose {
  position: absolute;
  top: 20px;
  right: 250px;
  display: inline-block;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  color: #3F6AFF;
  font-size: 16px;
  border: 2px solid #3F6AFF;
}
.activeName .InventorylistClose:hover {
  background: #3f6aff;
  color: #fff;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #6788ff;
  font-family: "Regular";
}
.activeName .first .paging {
  padding-top: 186px;
  padding-right: 35px;
  padding-left: 32px;
}
/* 标签页内容区的总长度 */
::v-deep .activeName .el-tabs__content {
  background-color: #fff;
  font-family: "Regular";
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pagebox {
  width: 100%;
  height: 88px;
  padding-bottom: 4px;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  margin-left: 15px;
}
.page_sign span {
  display: inline-block;
  cursor: pointer;
}
::v-deep .el-table th,
.el-table tr {
  height: 71px;
}
::v-deep .el-table__row {
  height: 86px !important;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner {
  border-radius: 20px;
  height: 35px;
  line-height: 35px;
}
::v-deep .el-table__expanded-cell[class*="cell"] {
  width: 1320px !important;
  padding-left: 294px !important;
  padding-top: 24px !important;
  padding-bottom: 12px !important;
}
.activeName .particulars1 {
  height: 92px;
  margin-bottom: 24px;
  width: 800px;
}
::v-deep .el-tabs__header {
  margin: 0 !important;
  background-color: #fff;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
::v-deep .status.el-button.el-button--primary {
  background: rgb(191, 204, 249, 0.15);
  color: #6788ff;
  border: none;
}
::v-deep .status.el-button.el-button--primary .seven:hover {
  background: #f9f9fa;
  color: #fff;
}
::v-deep .status.el-button--primary.el-button:hover {
  background: #e9efff;
  color: #6788ff;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}
.el-table::before {
  background: #dbdbe4;
}
::v-deep .el-table thead {
  color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
}
::v-deep .el-table {
  color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
}
/* 新增弹框 */
::v-deep .el-dialog__wrapper {
  height: 966px;
}
::v-deep .el-dialog {
  margin-top: 25vh !important;
  width: 810px !important;
  height: 282px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14) !important;
  border-radius: 21px !important;
  overflow: hidden;
  font-family: "Regular";
}
.Add-Store {
  background-color: #ffffff;
  width: 810px;
  height: 55px;
  line-height: 55px;
  color: #333333;
  padding-left: 42px;
  font-size: 16px;
  border-bottom: 1px solid #e4e6ef;
}
::v-deep .el-dialog__header {
  padding: 0 !important;
  font-family: "Regular";
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  font-family: "Regular";
}
::v-deep .el-dialog__headerbtn {
  position: absolute !important;
  top: 20px !important;
  right: 32px !important;
  padding: 0 !important;
  background: 0 0 !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-family: "Regular";
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
  font-family: "Regular";
}
::v-deep .el-input__inner:focus {
  border-color: #c0c4cc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.Filter_box {
  width: 1400px;
  height: 65px;
  background: #fff;
  border-radius: 50px;
  display: flex;
}
.search_ProductName {
  display: flex;
}
::v-deep .search_ProductName .el-input__inner {
  width: 400px;
  height: 40px;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: 100px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .search_ProductName .el-button {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  margin-top: 10px;
  background: #15104b;
  color: #fff;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .search_ProductName .el-button:hover {
  background: #3C3772 !important;
  color: #fff;
}
.search_Warehouse {
  display: flex;
}
::v-deep .search_Warehouse .el-input__inner {
  width: 400px;
  height: 40px;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: 100px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .search_Warehouse .el-button:nth-child(1) {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  margin-top: 10px;
  background: #15104b;
  color: #fff;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .search_Warehouse .el-button:nth-child(2) {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  margin-top: 10px;
  background: #15104b;
  color: #fff;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 25px;
}
::v-deep .search_Warehouse .el-button:nth-child(2):hover {
  background: #3C3772;
  color: #fff;
}
::v-deep .myselect .el-scrollbar {
  width: 250px;
}
::v-deep .search_Warehouse .el-select-dropdown {
  width: 250px;
}
</style>
