<template>
  <WinnerProducts :productType="productTypeMap.Top100" />
</template>

<script>
import { productTypeMap } from '../constants'
import WinnerProducts from './WinnerProducts.vue'

export default {
  components: {
    WinnerProducts
  },
  data() {
    return {
      productTypeMap
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
