var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-login" }, [
    _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: { src: _vm.logoImg[_vm.formLink], alt: "" },
      }),
      _vm._m(0),
    ]),
    _c(
      "div",
      { staticClass: "login-content" },
      [
        _c("img", {
          staticClass: "logo2",
          attrs: { src: _vm.logoImgOne[_vm.formLink], alt: "" },
        }),
        _c("p", { staticClass: "login-head" }, [
          _vm._v("Welcome to USAdrop Ads-agency"),
        ]),
        _c(
          "el-form",
          {
            ref: "formRef",
            attrs: {
              "label-position": "top",
              rules: _vm.rules,
              model: _vm.formData,
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "E-mail", prop: "username" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.formData.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "username", $$v)
                    },
                    expression: "formData.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "Password", prop: "password" },
              },
              [
                _c("el-input", {
                  attrs: { "show-password": true },
                  model: {
                    value: _vm.formData.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "password", $$v)
                    },
                    expression: "formData.password",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "login-bottom" }, [
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.signIn("formRef")
                        },
                      },
                    },
                    [_vm._v("Sign In")]
                  ),
                  _c("div", { staticClass: "small" }, [
                    _vm._v(" Don't have an account? "),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#3f6aff" },
                        on: { click: _vm.goRegister },
                      },
                      [_vm._v("Sign Up Here")]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "welcome-title" }, [
      _c("div", { staticClass: "ft-56 fw-800 color2" }, [_vm._v("Welcome to")]),
      _c("div", { staticClass: "ft-56 fw-800 color1 mb-24" }, [
        _vm._v("USAdrop Ads Agency"),
      ]),
      _c("div", { staticClass: "color2 fw-500 mb-24 ft-20" }, [
        _vm._v("USAdrop Facebook Ads Account"),
      ]),
      _c("div", { staticClass: "ft-56 fw-800 color2 mb-48" }, [
        _vm._v("USAdrop Ads-agency Service"),
      ]),
      _c("div", { staticClass: "btnContent" }, [
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009301_icon1.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("Up to "),
            _c("span", { staticClass: "color1" }, [_vm._v("5%")]),
            _vm._v(" Cash Back"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009336_icon2.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _c("span", { staticClass: "color1" }, [_vm._v("0%")]),
            _vm._v(" Charging Free"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009409_icon3.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("Stable Ads Account"),
          ]),
        ]),
        _c("div", { staticClass: "btnInfo" }, [
          _c("img", {
            attrs: {
              src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009445_icon4.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "tip-text" }, [
            _vm._v("No Spending Limits"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }