import request from '@/utils/request';
import axios from 'axios';
import {setToken} from '@/utils/token';
import {baseApiHost} from '@/utils/env';

export const authBack = (params) => request({
  method: "post",
  url: '/api/OAuth2/CallBack',
  data: params
});

export const authRegister = (params) => request({
  method: "post",
  url: '/api/OAuth2/OAuthRegister',
  data: params
});
export const login = (params) => request({
  method: "post",
  url: '/api/Login/GetJwtToken',
  data: params
});

export const getUser = (params) => request({
  method: "post",
  url: '/api/LhUser/GetUser',
  data: params
});

export const getMemberVo = (params) => request({
  method: "post",
  url: '/api/Member/GetMemberVo',
  data: params
});

export const authorization = (params) => request({
  method: "get",
  url: '/api/OAuth2/Authorization',
  params
});

export const checkMobileExist = (params) => request({
  method: "post",
  url: '/api/Login/CheckMoblieExist',
  data: params
});

export const getVerifyCode = (params) => request({
  method: "post",
  url: '/api/Login/GetVerifyCode',
  data: params
});
export const getCaptche = (params) => request({
  method: "get",
  url: '/api/Login/GetCaptche',
  params
});

export const getVetifyEmail = (params) => request({
  method: "get",
  url: '/api/Member/VetifyEmail',
  params
});
export const getVetifyMarketingCode = (params) => request({
  method: "get",
  url: '/api/LhFirstStepRecord/VetifyMarketingCode',
  params
});

export const LhFirstStepRecord = (params) => request({
  method: "post",
  url: '/api/LhFirstStepRecord/Add',
  data: params
});

export const register = (params) => request({
  method: "post",
  url: '/api/Login/Register',
  data: params
});

export const getRegisterCountryList = (params) => request({
  method: "get",
  url: '/api/Common/GetRegisterCountryList',
  params
});


export const getNewToken = () => {
  return axios({
    url: baseApiHost()+"/api/Login/RefreshToken",
    method: "post",
    data: { token: sessionStorage.getItem("Authorization") } ,
    headers: {
      "Content-Type": "application/json;",
      Authorization: "Bearer " + sessionStorage.getItem("Authorization")
    }
  })
    .then(res => {
      // 重置token成功
      if (res.data.Success) {
        setToken(res.data.Result);
        return res.data.Result.Token;
      } else {
        // 清除过期token
        sessionStorage.removeItem("USAdrop_user");
        sessionStorage.removeItem("Authorization");
        //   重置失败返回登录
        window.location.href = "/login";
        return Promise.reject();
      }
    })
    .catch(() => {
      // 清除过期token
      sessionStorage.removeItem("USAdrop_user");
      sessionStorage.removeItem("Authorization");
      //   重置失败返回登录
      window.location.href = "/login"
      return Promise.reject();
    });
}



