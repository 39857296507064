<script>
export default {
  name: 'FeedReturnInfo',
  props: {
    orderDetails: Array,
    returnInfos: {
      type: Object,
      default: () => ({
        ReturnedTrackingNumber: null,
        ReturnedSkuMap: null
      })
    },
    readonly: Boolean
  },
  data() {
    return {
      productSelectOptions: [],
      formModel: {
        ReturnedTrackingNumber: null,
        returnItems: []
      }
    }
  },
  watch: {
    orderDetails: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.productSelectOptions = newVal.map((item) => {
            return {
              value: item.ProductRealSku,
              label: item.ProductRealSku
            }
          })
          if (this.readonly) return
          this.formModel.returnItems = [
            {
              skuId: this.productSelectOptions[0].value,
              quantity: 0
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    returnInfos: {
      handler: function (newVal) {
        if (newVal) {
          this.formModel.ReturnedTrackingNumber = newVal.ReturnedTrackingNumber
          for (const key in newVal.ReturnedSkuMap) {
            let find = this.formModel.returnItems.find((item) => item.skuId == key)
            if (!find) {
              this.formModel.returnItems.push({
                skuId: key,
                quantity: newVal.ReturnedSkuMap[key]
              })
            } else {
              find.quantity = newVal.ReturnedSkuMap[key]
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onAddMissItem() {
      this.formModel.returnItems.push({
        skuId: null,
        quantity: 0
      })
    },
    onRemoveMissItem(index) {
      this.formModel.returnItems.splice(index, 1)
    },
    getReturnProducts(formName) {
      return new Promise((resolve, reject) => {
        this.$refs.validateFormRef.validate((valid) => {
          if (valid) {
            let ReturnedSkuMap = {}
            this.formModel.returnItems.forEach((item) => {
              ReturnedSkuMap[item.skuId] = item.quantity
            })
            resolve({
              ReturnedTrackingNumber: this.formModel.ReturnedTrackingNumber,
              ReturnedSkuMap: ReturnedSkuMap
            })
          } else {
            reject('error submit!!')
            return false
          }
        })
      })
    },
    handleInput(value, item) {
      item.quantity = value.replace(/[^\d]/g, '')
    }
  }
}
</script>

<template>
  <div class="feed-return-info">
    <div class="header">
      <div class="title-container">
        <span class="title">{{ $t('SupportTicket.ReturnedInfo') }}</span>
      </div>
      <el-button
        size="small"
        round
        type="primary"
        style="background: #3f6aff; border: none"
        @click="onAddMissItem"
      >
        {{ $t('SupportTicket.AddReturnedSku') }}
      </el-button>
    </div>
    <el-form
      ref="validateFormRef"
      :model="formModel"
      label-position="right"
      label-width="auto"
      :disabled="readonly"
    >
      <el-form-item
        :label="$t('SupportTicket.ReturnedTrackingNumber')"
        props="ReturnedTrackingNumber"
        :rules="{
          required: true,
          message: 'Please Input Return TrackingNumber',
          trigger: 'blur'
        }"
      >
        <el-input style="width: 70%" v-model="formModel.ReturnedTrackingNumber" />
      </el-form-item>
      <div v-for="(item, index) in formModel.returnItems" :key="index">
        <el-row :gutter="10">
          <el-col :span="9">
            <el-form-item
              :label="$t('SupportTicket.ReturnedItem')"
              :prop="'returnItems.' + index + '.skuId'"
              :rules="{
                required: true,
                message: 'Please select Return Item',
                trigger: 'blur'
              }"
            >
              <el-select v-model="item.skuId">
                <el-option
                  v-for="item in productSelectOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              :label="$t('SupportTicket.ReturnedQuantity')"
              :prop="'returnItems.' + index + '.quantity'"
              :rules="{
                required: true,
                message: 'Please Input Return Quantity',
                trigger: 'blur'
              }"
            >
              <el-input v-model="item.quantity" type="number" @input="handleInput($event, item)" />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button
              v-if="formModel.returnItems.length > 1"
              size="small"
              type="text"
              style="color: red"
              @click="onRemoveMissItem(index)"
              :disabled="readonly"
            >
              {{ $t('SupportTicket.Remove') }}
            </el-button>
            <div v-else></div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.feed-return-info {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #15104b;
      }

      .tips {
        color: #999999;
        line-height: 14px;
      }
    }
  }
}

:deep(.el-form-item__label) {
  padding-right: 10px !important;
}
</style>
