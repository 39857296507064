<template>
  <div><!-- <button @click="converBtn">{{ $t("member.payment") }}</button> --></div>
</template>
<script>
import request from "@/utils/request";
import { loadAirwallex } from "airwallex-payment-elements";
export default {
  data: () => {
    return {};
  },
  created() {
  },
  props: ["money", "totalmoney"],
  mounted() {
    loadAirwallex({
      origin: window.location.origin // Set up your event target to receive the browser events message
    });
  },
  methods: {
    converBtn() {
      let that = this;
      that.dispatch = that.$store.dispatch
      that.dispatch('wallet/CreateAirWallexOrderWa', {
          RechargeAmount: Number(that.totalmoney)
        })
      .then((res) => {
        if (res.Success) {
          Airwallex.redirectToCheckout({
            env: res.Result.AirWallexPayParam.Env, //服务端返回
            mode: res.Result.AirWallexPayParam.Mode,//服务端返回
            customer_id: res.Result.AirWallexPayParam.CustomerId, //服务端返回
            intent_id: res.Result.AirWallexPayParam.IntentId,//服务端返回
            client_secret: res.Result.AirWallexPayParam.ClientSecret,
            currency: 'USD',
            locale: 'en',
            successUrl: window.location.protocol+"//"+window.location.host+'/Airwallexsuccess',
            failUrl: window.location.protocol+"//"+window.location.host+'/Airwallexfail',
            logoUrl: window.location.protocol+"//"+window.location.host+'/img/LoginLogoTwo',
            recurringOptions: {
              card: {
                next_triggered_by: 'merchant',
                merchant_trigger_reason: 'scheduled',
                currency: 'USD',
              },
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
  }
};
</script>
<style scoped>
.Air_Convergence_box button {
  margin-top: 30px;
  margin-left: 65px;
  display: block;
  width: 272px;
  height: 52px;
  line-height: 52px;
  background: #ffffff;
  border-radius: 26px;
  border: 0;
  font-size: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #616ae8;
}
</style>
