var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "reset" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-col0" }, [
          _vm._v(_vm._s(_vm.$t("password.AnEmail"))),
        ]),
        _c("div", { staticClass: "params" }, [_vm._v(_vm._s(_vm.params))]),
        _c(
          "button",
          { staticClass: "comBtn btn-bg0", on: { click: _vm.confirm } },
          [_vm._v(_vm._s(_vm.$t("password.Confirm")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "registration" }, [
      _c("img", {
        attrs: { src: require("../../assets/imgs/HTSCIT.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }