var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showDialog,
        title: _vm.title,
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }