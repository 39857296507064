import en from '@/assets/languages/en.json'

const tips = en.tips

/**
 * 判断email是否为空,且格式是否正确
 * message:返回提示信息
 * val:是否符合要求
 * @param {*} email
 */
export const isEmail = (email) => {
  const obj = {
    val: false,
    message: tips.emailEmpty
  }
  if (!email) {
    return obj
  }
  const reg =
    /^([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/gi
  // const reg = /^\w+(?:\.\w+){0,1}@@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/gi;
  if (!reg.test(email)) {
    obj.message = tips.notEmail
    return obj
  } else {
    obj.val = true
    obj.message = ''
    return obj
  }
}

/**
 * 判断手机号是否为空,且格式是否正确
 * @param {*} number
 */
export const isMobileNumber = (number) => {
  const obj = {
    val: false,
    message: tips.phoneEmpty
  }
  if (!number) {
    return obj
  }
  const retNum = /^\d*$/
  if (!retNum.test(number)) {
    obj.message = tips.notPhone
    return obj
  } else {
    // console.log(retNum.test(number));
    obj.val = true
    obj.message = ''
    return obj
  }
}
/**
 * //判断是否从越南或西班牙过来的
 */
export const getFrom = () => {
  switch (document.referrer) {
    case 'https://vn.usadrop.com/':
      localStorage.setItem('fromLink', 'vn.usadrop.com');
      return 'vn.usadrop.com';
    case 'https://www.usadrop.com/':
    case 'https://usadrop.com/':
    case 'https://app.usadrop.com/':
      localStorage.setItem('fromLink', 'app.usadrop.com');
      return 'app.usadrop.com';
    case 'https://es.usadrop.com/': // New case for es.usadrop.com
      localStorage.setItem('fromLink', 'es.usadrop.com');
      return 'es.usadrop.com';
    default:
      var fromLink = localStorage.getItem('fromLink');
      switch (fromLink) {
        case 'vn.usadrop.com':
        case 'app.usadrop.com':
        case 'es.usadrop.com': // Handle stored value for es.usadrop.com
          return fromLink;
        default:
          return 'app.usadrop.com';
      }
  }
};

/* export const getFrom = () => {
  switch (document.referrer) {
    case 'https://vn.usadrop.com/':
      localStorage.setItem('fromLink', 'vn.usadrop.com')
      return 'vn.usadrop.com'
    case 'https://www.usadrop.com/':
    case 'https://usadrop.com/':
    case 'https://app.usadrop.com/':
      localStorage.setItem('fromLink', 'app.usadrop.com')
      return 'app.usadrop.com'
    default:
      var fromLink = localStorage.getItem('fromLink')
      switch (fromLink) {
        case 'vn.usadrop.com':
        case 'app.usadrop.com':
          return fromLink
        default:
          return 'app.usadrop.com'
      }
  }
} */

export const searchUrlParams = (search) => {
  let params = {}
  if (search) {
    const newSearch = search.substring(1).split('&')
    newSearch.forEach((item) => {
      const index = item.indexOf('=')
      const key = item.substring(0, index)
      params[key] = item.substring(index + 1).split('#')[0]
    })
  }
  return params
}

// 判断基本类型
export const judgePrototype = (value) => {
  const type = Object.prototype.toString.call(value)
  let judge = ''
  switch (type) {
    case '[object String]':
      judge = 'String'
      break
    case '[object Number]':
      judge = 'Number'
      break
    case '[object Boolean]':
      judge = 'Boolean'
      break
    case '[object Undefined]':
      judge = 'Undefined'
      break
    case '[object Null]':
      judge = 'Null'
      break
    case '[object Array]':
      judge = 'Array'
      break
    case '[object Function]':
      judge = 'Function'
      break
    case '[object Object]':
      judge = 'Object'
      break
    case '[object RegExp]':
      judge = 'RegExp'
      break
    case '[object Date]':
      judge = 'Date'
      break
  }
  return judge
}
export const useSearch = (key, json) => {
  const location = window.location
  const search = new URLSearchParams(location.search)
  const value = json ? JSON.parse(search.get(key) ?? '') : search.get(key)
  return value
}

/**
 * 删除指定参数并替换当前浏览器url
 * @param keys 参数key 列表
 */
export function deleteUrlParamsAndReplaceUrl(keys) {
  if (keys === undefined || keys.length === 0) return
  const searchParams = new URLSearchParams(window.location.search)
  keys.forEach((item) => {
    searchParams.delete(item)
  })
  const params = searchParams.toString()
  const newUrl =
    window.location.origin +
    window.location.pathname +
    (params ? '?' + searchParams.toString() : '')
  window.location.replace(newUrl)
}

// 处理 proConnect ud 商品名称显示
export function transProductName(item) {
  const findTrans = item.goodsTransList?.find((item) => item.country === 'en')
  if (findTrans) {
    return findTrans.subjectTrans
  }
  return item.subject
}

// 处理 商品子sku规格数据
export function transSpecData(itemData) {
  const specArr = []
  itemData.forEach((item) => {
    const value = JSON.parse(item.specValue)['en']
    specArr.push(value)
  })
  return specArr
}
//随机生成uuid
export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16)
  })
}

/**
 * 防抖： 管事件触发频率多高，一定在事件触发 n 秒后才执行，如果在一个事件执行的 n秒内又触发了这个事件，就以新的事件的时间为准
 */
export function debounce(fn, wait = 300, immediate = false) {
  let timer = null

  // 返回一个函数
  return function () {
    // 是否要立即执行一次
    if (!timer && immediate) fn.apply(this, arguments)

    // 每次触发事件时都取消之前的定时器
    clearTimeout(timer)

    // setTimeout 中使用箭头函数，就是让 this指向 返回的该闭包函数，而不是 debounce函数 的调用者
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, wait)
  }
}

/**
 * 节流： 不管事件触发频率有多高，只在单位时间内执行一次,第一次事件和最后一次事件都触发
 */
export function throttle(fn, wait = 300) {
  // 设置一个定时器
  let timer = null

  // 记录上一次执行的时间戳
  let previous = 0

  return function () {
    // 当前的时间戳，然后减去之前的时间戳，大于设置的时间间隔
    if (Date.now() - previous > wait) {
      clearTimeout(timer)
      timer = null

      // 更新上一次的时间戳为当前时间戳
      previous = Date.now()

      fn.apply(this, arguments)
    } else if (!timer) {
      // 设置下一个定时器
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, wait)
    }
  }
}
