import {
  checkGuide,
  OAuthGuide,
  ExistStore,
  CommitBusinessType
} from '@/api/guide'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    checkGuideGu({state, commit}, params) {
      return checkGuide(params).then(res => res)},
    OAuthGuideGu({state, commit}, params) {return OAuthGuide(params).then(res => res)},
    ExistStoreGu({state, commit}, params) {return ExistStore(params).then(res => res)},
    CommitBusinessTypeGu({state, commit}, params) {return CommitBusinessType(params).then(res => res)}
  }

}