<template>
    <div class="hierarchy">
        <div class="Overview">
          <p>{{ $t("dashboard.overviwe") }}</p>
          <p></p>
        </div>
        <div class="ConTent">
          <div class="ConTentOne">
            <p @click="Balance">{{ $t("dashboard.balance") }}</p>
            <p>{{ GetOverViewList.Balance }}</p>
          </div>
          <div class="ConTentTwo">
           <p @click="Orders">{{ $t("dashboard.ordersFulfill") }}</p>
            <p>{{ GetOverViewList.QuotedOrders }}</p>
          </div>
          <div class="ConTentThree">
            <p>{{ $t("dashboard.fulfilled") }}</p>
            <p>{{ GetOverViewList.FulFilledOrders }}</p>
          </div>
          <div class="ConTentFour">
            <p>{{ $t("dashboard.integrated") }}</p>
            <p>{{ GetOverViewList.IntegratedStores }}</p>
          </div>
        </div>
      </div>
</template>

<script>

export default {
//import引入的组件
components: {},
data() {
return {
    GetOverViewList: [],
};
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.dispatch = this.$store.dispatch
    this.GetOverView();
},
//方法集合
methods: {
    GetOverView() {
      this.dispatch('dashboard/GetOverViewDb')
        .then((res) => {
          this.GetOverViewList = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Balance() {
      this.$router.push("/wallet");
    },
    Orders() {
      this.$router.push("/Orders");
    },
},
}
</script>
<style scoped lang="scss">
.hierarchy {
    position: absolute;
    width: 300px;
    height: 400px;
    background: #ffffff;
    z-index: 99999px;
    right: 0px;
    top: 350px;
    border-radius: 10px;
    font-family: "Regular";
  }
  .Overview {
    p:nth-child(1) {
      font-size: 22px;
      color: #000000;
      margin-top: 25px;
      margin-left: 30px;
      font-weight: 700;
      font-family: 'Light';
    }
    p:nth-child(2) {
      width: 250px;
      height: 2px;
      background: #cccccc;
      margin-top: 30px;
      margin-left: 26px;
    }
  }
  .ConTentOne {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    p:nth-child(1) {
      font-size: 18px;
      color: #000000;
      font-weight: bold;
      cursor: pointer;
      margin-left: 30px;
      font-family: 'Light';
    }
    p:nth-child(2) {
      margin-right: 40px;
      font-size: 14px;
    }
  }
  .ConTentOne p:nth-child(1):hover{
    transform: translate(-5px, -5px);
    font-size: 20px;
  }
  .ConTentTwo {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    p:nth-child(1) {
      font-size: 18px;
      color: #000000;
      font-weight: bold;
      font-family: 'Light';
      cursor: pointer;
      margin-left: 30px;
    }
    p:nth-child(2) {
      margin-right: 40px;
      font-size: 14px;
    }
  }
  .ConTentTwo p:nth-child(1):hover{
    transform: translate(-5px, -5px);
    font-size: 20px;
  }
  .ConTentThree {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    font-size: 14px;
    p:nth-child(1) {
      margin-left: 30px;
    }
    p:nth-child(2) {
      margin-right: 40px;
    }
  }
  .ConTentFour {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    font-size: 14px;
    p:nth-child(1) {
      margin-left: 30px;
    }
    p:nth-child(2) {
      margin-right: 40px;
    }
  }
</style>