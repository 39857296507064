<template>
  <div class="particulars" :class="[theme === 'common' ? '' : 'dark']">
    <!-- 内容 -->
    <div class="main">
      <el-tabs class="source-tab">
        <!-- quoting -->
        <el-tab-pane class="first">
          <template v-if="quotedList[0].list.length > 0">
            <el-table :data="quotedList[0].list">
              <el-table-column align="center" :label="$t('Sourcing.SourcingImage')">
                <div slot-scope="scope" class="content">
                  <img :src="scope.row.ProductImage" alt="" />
                </div>
              </el-table-column>
              <el-table-column :label="$t('Sourcing.ProductLinks')">
                <div slot-scope="scope">
                  <div class="product_link_box">
                    <div class="product_link">{{ scope.row.ProductLink }}</div>
                    <div>
                      <i
                        :data-clipboard-text="scope.row.ProductLink"
                        class="el-icon-copy-document copy_btn"
                        id="quoting_copy"
                        @click="copyData('quoting_copy')"
                      ></i>
                    </div>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                width="140"
                show-overflow-tooltip=""
                align="center"
                :label="$t('Sourcing.Name')"
              >
                <div slot-scope="scope">
                  <div class="title_name">
                    {{ scope.row.ProductTitle }}
                  </div>
                </div>
              </el-table-column>
              <el-table-column align="center" :label="$t('Sourcing.state')">
                <div slot-scope="scope">
                  <div :class="switchState(scope.row.OfferStatus).className">
                    {{ switchState(scope.row.OfferStatus).title }}
                  </div>
                </div>
              </el-table-column>
              <el-table-column align="center" :label="$t('Sourcing.ProductPrice')">
                <div slot-scope="scope">
                  <div class="title" v-if="scope.row.MallPrice">
                    {{ scope.row.MallPrice }}
                  </div>
                  <div v-else>--</div>
                </div>
              </el-table-column>
              <el-table-column align="center" :label="$t('Sourcing.Description')">
                <div slot-scope="scope" class="product_describe">
                  <div v-if="scope.row.ProductDescribe">
                    {{ scope.row.ProductDescribe }}
                  </div>
                  <div v-else>--</div>
                </div>
              </el-table-column>
              <el-table-column align="center" width="220px" :label="$t('Sourcing.check')">
                <div slot-scope="scope" class="signBox">
                  <button
                    class="sign-box-btn comBtn col-col5"
                    @click="viewDetail(scope.row)"
                    v-if="switchState(scope.row.OfferStatus).title == 'Quoted'"
                  >
                    {{ $t('Sourcing.views') }}
                  </button>
                  <div v-if="switchState(scope.row.OfferStatus).title == 'To Be Quoted'">
                    Within 1 working day
                  </div>
                  <div
                    class="signContent"
                    v-if="scope.row.IsViewDetail === false && scope.row.QuoteStatus === 1"
                  ></div>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <el-empty :description="$t('Sourcing.NoData')" class="no-data" v-else></el-empty>
          <!-- 分页 -->
          <div class="pagebox">
            <PagePagination
              :total="quotedList[0].total"
              :page-size="quotedList[0].pageSize"
              :current-page="quotedList[0].page"
              @current-change="pageChange"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-button
        v-if="paymentList.IsAdmin == true"
        class="orders comBtn btn-bg5"
        @click.stop="sourcingBtn"
        >{{ $t('Sourcing.SourcingAliexpressProducts') }}
      </el-button>
      <div class="speed-progress" v-if="Fulfilled">
        <div class="progress-fulfilled">
          <span>{{ $t('Sourcing.ProductsSourced') }}</span>
          <span>{{ MembershipInterests.UsedTimes }}</span>
          <span>/</span>
          <span>{{ MembershipInterests.TotalTimes }}</span>
        </div>
        <el-progress
          :percentage="progressBar"
          style="width: 170px; margin-top: 8px"
          :show-text="false"
        ></el-progress>
        <div class="monthly-red" @click="monthlyRed()">
          <span v-if="paymentList.GradeId === 1">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576583_MemberJICHUSlice.png"
              alt=""
            />
          </span>
          <span v-if="paymentList.GradeId === 2">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576645_MemberVIPSlice16.png"
              alt=""
            />
          </span>
          <span v-if="paymentList.GradeId === 3">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715588576615_MemberSVIPSlice16.png"
              alt=""
            />
          </span>
        </div>
        <template v-if="MembershipInterests.DayLimit && MembershipInterests.DayRemainTimes === 0">
          <el-tooltip effect="dark" placement="right" :open-delay="delayTime">
            <div class="icon-question">
              <i class="el-icon-question"></i>
            </div>
            <div slot="content">
              <p>{{ $t('Sourcing.HaveToday') }}</p>
              <p>{{ $t('Sourcing.SalesmanAgent') }}</p>
            </div>
          </el-tooltip>
        </template>
      </div>
    </div>
    <!-- 会员费 -->
    <MemberPop :visible="memberPopupBox" />
    <!-- 联系业务员 -->
    <AgentPop :visible.sync="displayAgent" />
    <!-- 未绑定店铺7天和会员费过期弹窗 -->
    <MemberBindBox ref="child"></MemberBindBox>
  </div>
</template>
<script>
import MemberBindBox from '@/components/MemberBindBox.vue'
import PagePagination from '@/components/PagePagination.vue'
import Clipboard from 'clipboard'
import { mapState } from 'vuex'
import { NewLhSourcingList } from '@/api/quoted'
import { GetMemberVo } from '@/api/member'
import { GetRightsWithSourcing } from '@/api/memberRights'
import { checkGuide } from '@/api/guide'
import MemberPop from './components/memberPop.vue'
import AgentPop from './components/agentPop.vue'

export default {
  name: 'Sourcing',
  data: () => {
    return {
      delayTime: 300,
      paymentList: [],
      Fulfilled: true, // 进度条的显示与隐藏
      MembershipInterests: [],
      memberPopupBox: false, // 会员费
      displayAgent: false, // 业务员
      displayUSAdropInfo: false,
      progressBar: 0, // 进度条
      BindStoreList: [], // 是否绑定店铺
      // 数据列表
      quotedList: [
        {
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ]
    }
  },
  components: {
    MemberBindBox,
    PagePagination,
    MemberPop,
    AgentPop
  },
  computed: mapState(['userInfo', 'theme']),
  created() {},
  mounted() {
    console.clear()
    this.getData()
    this.getMemberShip()
    this.getMemBerData()
    this.checkGuideData()
  },
  methods: {
    getData() {
      let that = this
      let postData = {
        PageIndex: that.quotedList[0].page,
        PageSize: that.quotedList[0].pageSize
      }
      NewLhSourcingList(postData)
        .then((res) => {
          that.quotedList[0].list = res.Items
          that.quotedList[0].total = res.TotalItems
        })
        .catch((err) => {
          console.log(err)
        })
    },
    pageChange(page) {
      this.quotedList[0].page = page
      this.getData()
    },
    getMemberShip() {
      const that = this
      GetRightsWithSourcing().then((res) => {
        that.MembershipInterests = res.Result
        that.progressBar =
          (that.MembershipInterests.UsedTimes / that.MembershipInterests.TotalTimes) * 100
        if (this.MembershipInterests.TotalTimes === '-1') {
          this.Fulfilled = false
        }
      })
    },
    getMemBerData() {
      GetMemberVo().then((res) => {
        this.paymentList = res.Result
      })
    },
    /* 是否绑定店铺 */
    checkGuideData() {
      checkGuide().then((res) => {
        this.BindStoreList = res.Result
        if (res.Result.More7daysNoStoreOrNoGrade) {
          this.$refs.child.handelMember()
        } else {
          /* id && this.goDetail(); */
        }
      })
    },
    sourcingBtn() {
      const query = this.$route.query
      const { from = 1 } = query
      if (this.BindStoreList.More7daysNoStoreOrNoGrade) {
        this.checkGuideData()
      } else {
        this.getMemberShip()
        if (this.MembershipInterests.Enabled) {
          this.$router.push(`/SourcingURL?from=${from}`)
        } else {
          if (this.MembershipInterests.RemainTimes === 0) {
            this.memberPopupBox = true
          } else {
            this.displayAgent = true
          }
        }
      }
    },
    monthlyRed() {
      this.$router.push('/Upgrade')
    },
    switchState(val) {
      switch (val) {
        case 1:
          return {
            title: 'To Be Quoted',
            className: 'warning-text'
          }
        case 2:
          return {
            title: 'Quoted',
            className: 'success-text'
          }
        case 3:
          return {
            title: 'Unable to quote',
            className: 'error-text'
          }
        default:
          return ''
      }
    },
    viewDetail(rowData) {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.open(`${rowData.MallLink}?tk=${tk}`)
    },
    copyData(id) {
      var clipboard = new Clipboard(`#${id}`)
      clipboard.on('success', (e) => {
        this.$message.success(this.$t('Sourcing.CopySucceeded'))
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.warning($t('Sourcing.TheReplication'))
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 1320px;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  padding-bottom: 32px;
  box-sizing: border-box;
  font-family: 'Regular';
  .main {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.source-tab {
  width: 100%;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /* tab 标签 */
  ::v-deep .el-tabs__item {
    font-size: 16px;
  }
  ::v-deep .el-tabs__active-bar {
    height: 3px;
    background-color: #6788ff;
    border-radius: 10px;
  }

  ::v-deep .el-tabs__nav-scroll {
    height: 80px;
    padding: 15px 0;
    background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  }

  ::v-deep .el-tabs__header {
    margin: 0;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }

  ::v-deep .el-tabs__nav {
    margin-left: 32px;
  }

  ::v-deep .el-table th,
  ::v-deep .el-table tr {
    height: 70px;
    font-weight: 500;
  }
  .product_link_box {
    display: flex;
    font-size: 12px;
    color: #555555;
    .product_link {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    .copy_btn {
      cursor: pointer;
      &:hover {
        color: #3f6aff;
      }
    }
  }
  .product_describe {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .title_name {
    padding: 0 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-left: 24px;
    img {
      width: 100px;
      height: 100px;
    }
    .description {
      flex: 1;
      min-width: 0;
      padding-left: 8px;
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 14px;
      .title {
        // height: 36px;
        line-height: 18px;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-line-clamp: 2;
        word-break: keep-all;
      }
      .info {
        flex: 1;
        line-height: 18px;
        color: #999999;
        margin-top: 6px;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-line-clamp: 2;
        padding-right: 20px;
        position: relative;
        .copy_btn {
          position: absolute;
          top: 3px;
          right: 0px;
          width: 14px;
          height: 14px;
          cursor: pointer;
          &:hover {
            color: #3f6aff;
          }
        }
      }
      .remark {
        // height: 26px;
        margin-top: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        .remark-inner {
          height: 24px;
          padding: 0 10px;
          cursor: pointer;
          background-color: #ffffff;
          border: 1px solid #d2d2d2;
        }
      }
    }
    .result {
      justify-content: space-between;
    }
  }
}

.sign-box-btn {
  border: 2px solid #3f6aff;
  &:hover {
    opacity: 0.7;
  }
}

.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
}

.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
}

/* orders */
.main {
  .orders {
    position: absolute;
    top: 20px;
    right: 30px;
    font-family: 'Regular';
    font-weight: 500;
    color: #fff;
    border-radius: 50px;
    height: 40px;
  }
  .speed-progress {
    position: absolute;
    top: 26px;
    left: 55px;
  }
  .progress-fulfilled span:nth-child(1) {
    font-size: 14px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }

  .progress-fulfilled span:nth-child(2) {
    font-size: 14px;
    margin-left: 30px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }

  .progress-fulfilled span:nth-child(3) {
    font-size: 14px;
    margin-left: 5px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }

  .progress-fulfilled span:nth-child(4) {
    font-size: 14px;
    margin-left: 6px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
  .monthly-red {
    position: absolute;
    top: -5px;
    left: 230px;
    cursor: pointer;
  }

  .monthly-red span img {
    width: 26px;
    height: 27px;
  }

  .monthly-red span img:hover {
    transform: scale(1.05);
  }

  .icon-question {
    position: absolute;
    top: 2px;
    left: 260px;
    .el-icon-question {
      font-size: 20px;
      color: #3f6aff;
    }
  }
}

.no-data {
  background-color: #fff;
}

.success-text {
  color: #2ad131;
}

.warning-text {
  color: #ff6b00;
}

.error-text {
  color: #ff0000;
}
</style>
