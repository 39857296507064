<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <!-- 详情页面 -->
    <!-- 详情区域 -->
    <div class="activeName">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!--  -->
        <!-- 全部 -->
        <el-tab-pane :label="$t('notification.all')" name="all">
          <template>
            <el-table :data="NoticesList" style="width: 100%" @row-click="noticeDetail">
              <div slot="empty" v-if="NoticesList && NoticesList.length === 0">
                <el-empty :description="$t('notification.NoData')"></el-empty>
              </div>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <div class="dot" v-if="scope.row.IsReaded == false"></div>
                  <p class="Cancel" :class="scope.row.IsReaded == false ? 'unread' : ''">
                    {{ scope.row.Title }}
                  </p>
                </div>
              </el-table-column>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <span class="Cancel" :class="scope.row.IsReaded == false ? 'unread' : ''">
                    {{ scope.row.PublishTime }}</span
                  >
                </div>
              </el-table-column>
            </el-table>
          </template>
          <!-- <el-empty description="No data" v-else></el-empty> -->
        </el-tab-pane>
        <!-- 已读 -->
        <el-tab-pane :label="$t('notification.read')" name="read">
          <!--  v-if="Unread && Unread.length > 0" -->
          <template>
            <el-table :data="Unread" style="width: 100%" @row-click="noticeDetail">
              <div slot="empty" v-if="NoticesList && NoticesList.length === 0">
                <el-empty :description="$t('notification.NoData')"></el-empty>
              </div>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <p class="Cancel">{{ scope.row.Title }}</p>
                </div>
              </el-table-column>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <p class="Cancel">{{ scope.row.PublishTime }}</p>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <!-- <el-empty description="No data" v-else></el-empty> -->
        </el-tab-pane>
        <!-- 未读  v-if="HaverRead && HaverRead.length > 0"-->
        <el-tab-pane :label="$t('notification.unread')" name="unread">
          <template>
            <el-table :data="HaverRead" style="width: 100%" @row-click="noticeDetail">
              <div slot="empty" v-if="NoticesList && NoticesList.length === 0">
                <el-empty :description="$t('notification.NoData')"></el-empty>
              </div>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <div class="Dot"></div>
                  <p class="Cancel unread">{{ scope.row.Title }}</p>
                </div>
              </el-table-column>
              <el-table-column align="center">
                <div slot-scope="scope" class="content">
                  <p class="Cancel unread">{{ scope.row.PublishTime }}</p>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <!-- <el-empty description="No data" v-else></el-empty> -->
        </el-tab-pane>
      </el-tabs>
      <div class="pagebox">
        <PagePagination
          :total="options.total"
          :page-size="options.pageSize"
          :current-page="options.page"
          @current-change="pageChange"
        />
      </div>
    </div>
    <!-- 详情弹出框 -->
    <div class="paybox" v-if="Withdrawn" @click="hideDetail">
      <div class="main">
        <div class="bg"></div>
        <div class="payContent">
          <div class="close" @click="hideDetail">
            <img src="@/assets/imgs/close.svg" alt="" />
          </div>
          <div class="title">
            <span>{{ details.Title }}</span>
            <div class="subTitle">{{ details.ReleaseDate }}</div>
          </div>
          <div class="content">
            <p v-html="details.NewsContent" style="word-wrap: break-word"></p>
          </div>
        </div>
        <div class="copyright">
          <p>{{ $t('notification.OnTime') }}</p>
          <p style="margin-top: 8px">{{ $t('notification.Dropshipping') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagePagination from '@/components/PagePagination.vue'
import { mapState } from 'vuex'
export default {
  data: () => {
    return {
      dispatch: null,
      page: 'notification',
      input3: '',
      banner: '',
      itemname: '',
      activeName: 'all',
      Withdrawn: false, //是否查看详情
      NoticesList: null, // 所有公告列表
      unreadNotices: [],
      details: [], // 公告信息详情
      options:
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        },
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      RecordCountType: '-1'
    }
  },
  components: {
    PagePagination
  },
  computed: {
    ...mapState(['theme', 'userInfo']),
    HaverRead: function () {
      let arr = this.NoticesList?.filter((item) => {
        if (item.IsReaded != true) {
          return item
        }
      })
      // console.log(111,arr);
      return arr
    },
    Unread: function () {
      return this.NoticesList?.filter((item) => {
        if (item.IsReaded != false) {
          return item
        }
      })
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
    this.banner = this.$t('notification.bannername')
    this.itemname = this.$t('notification.itemname')
    this.getData()
  },
  mounted() {},

  methods: {
    pageChange(page) {
      //分页器页数改变
      this.options.page = page
      this.getData()
    },
    getPageList() {
      //分页器跳转页数
      this.options.page = this.options.jumpPage > 0 ? Number(this.options.jumpPage) : 1
      this.getData()
    },
    noticeDetail(row) {
      //查看消息详情
      this.Withdrawn = true

      this.dispatch('notification/LhNewsGetNo', {
        id: row.Id
      })
        .then((res) => {
          row.IsReaded = true
          this.details = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    hideDetail() {
      //关闭消息详情
      this.Withdrawn = false
    },
    // 获取所有公告列表
    getData() {
      let that = this
      that.NoticesList = null
      that
        .dispatch('notification/LhNewsGetAllNo', {
          CurrentPageIndex: that.options.page,
          PageSize: that.options.pageSize,
          RecordCount: that.options.list.length,
          ReadStatus: that.RecordCountType
        })
        .then((res) => {
          /* that.NoticesList = res.Result; */
          that.NoticesList = res?.Items || []
          that.options.total = res.TotalItems
          /* this.options[0].total = res.Result.length */
        })
        .catch((err) => {
          that.NoticesList = []
          console.log(err)
        })
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'all':
          this.RecordCountType = -1
          break
        case 'unread':
          this.RecordCountType = 0
          break
        case 'read':
          this.RecordCountType = 1
          break
      }
      this.options.page = 1
      this.getData()
    }
    //
  }
}
</script>
<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
/* 搜索框 */
.Filter {
  width: 1320px;
  height: 94px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 94px;
}
::v-deep .Filter .input-with-select {
  width: 563px;
  height: 40px;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 30px;
}
::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 46px;
}
::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 46px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .Filter [class*=' el-icon-'],
[class^='el-icon-'] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search {
  display: inline-block;
  width: 145px;
  height: 46px;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 50px;
  color: #6788ff;
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Filter .Advanced {
  display: inline-block;
  width: 145px;
  height: 46px;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  margin-left: 30px;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 详情区域 */
.activeName {
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}
::v-deep .el-tabs__nav {
  margin-left: 56px;
}
::v-deep .el-tabs__nav-scroll {
  text-align: center;
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__item {
  padding: 0 40px;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-table td.is-center:nth-child(1) {
  text-align: left;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-table_1_column_1 {
  padding-left: 10px;
}
/* 表头的高度 */
::v-deep .el-table__header-wrapper {
  height: 0 !important;
}
/* 表格每一行的高都 */
::v-deep .el-table__row {
  height: 64px !important;
}
::v-deep .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #f2f2f2;
}
/*.content {
  position: relative;
}*/
.dot {
  width: 8px;
  height: 8px;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 3px;
}
.Dot {
  width: 8px;
  height: 8px;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 3px;
}
.Cancel {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 500;
  /* color: rgb(0, 0, 0, 0.3); */
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.unread {
  margin-left: 16px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  color: rgb(0, 0, 0, 0.8);
}
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
}
.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
}
.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Seconds {
  margin-left: 30px;
}
</style>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* 详情弹出框 */
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  @extend .flex;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .main {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
  }
  .bg {
    width: 5.78125rem;
    height: 200px;
    background: #15104b;
    position: absolute;
    z-index: 100;
    bottom: 60px;
    border-radius: 20px;
  }
  .payContent {
    width: 5.40625rem;
    height: 800px;
    background: #ffffff;
    position: relative;
    border-radius: 20px;
    position: absolute;
    z-index: 102;
    bottom: 0;
    /*overflow: hidden;
    overflow-y: scroll;
    white-space: nowrap;*/

    .close img {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    .title {
      width: 100%;
      @extend .flex;
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      padding-top: 30px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
        opacity: 0.8;
      }
    }
    .content {
      width: 100%;
      height: 650px;
      padding: 30px 100px 150px;
      overflow-y: auto;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .content p {
      word-wrap: break-word;
    }
    .content::-webkit-scrollbar {
      display: none;
    }
  }
  .copyright {
    background: url('../../assets/imgs/guide/notice_YS.png') no-repeat;
    background-size: contain;
    width: 1110px;
    height: 220px;
    @extend .flex;
    padding-bottom: 20px;
    z-index: 103;
    color: #ffffff;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 103;
    bottom: 0;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
}
</style>
