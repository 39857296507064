<template>
  <div class="sync">
    <el-dialog
      :visible="visible"
      width="750px"
      title="Sync Data"
      custom-class="usadialog"
      :close-on-click-modal="false"
      label-suffix=":"
      @close="handleClose"
    >
      <div class="dialog-wrap">
        <el-form :model="formData" ref="formRef" :rules="rules" label-width="80px">
          <el-form-item label="Title" prop="title">
            <el-input v-model="formData.title" placeholder="please Enter Title"></el-input>
          </el-form-item>

          <el-form-item label="Date" prop="rangeTime">
            <el-date-picker
              style="width: 100%"
              v-model="formData.rangeTime"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn wid200" @click="handleClose">Cancel</el-button>
        <el-button class="comBtn btn-bg0 wid200" @click="handleConfirm" :loading="loading">
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'SyncDialog',
  props: {
    visible: {
      type: Boolean,
      required: false
    }
  },
  data: () => {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: 'Three Day',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [
                dayjs(start).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
              ])
            }
          },
          {
            text: 'One Week',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [
                dayjs(start).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
              ])
            }
          },
          {
            text: 'One Month',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [
                dayjs(start).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
              ])
            }
          },
          {
            text: 'Three Month',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [
                dayjs(start).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
              ])
            }
          }
        ]
      },
      formData: {
        title: '',
        rangeTime: [
          dayjs().format('YYYY-MM-DD') + ' 00:00:00',
          dayjs().format('YYYY-MM-DD') + ' 23:59:59'
        ]
      },
      rules: {
        title: [{ required: true, message: 'Please Enter Title', trigger: 'blur' }],
        rangeTime: [{ required: true, message: 'Please Select Date', trigger: 'change' }]
      },
      loading: false
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('confirm', this.formData)
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.formData = {
        rangeTime: [
          dayjs().format('YYYY-MM-DD') + ' 00:00:00',
          dayjs().format('YYYY-MM-DD') + ' 23:59:59'
        ],
        title: ''
      }
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrap {
  padding: 0 50px 0 30px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .wid200 {
    width: 200px;
  }
  .cancelBtn {
    background-color: #fff;
    border-radius: 25px;
    color: #15104b;
    height: 40px;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    margin-right: 40px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
