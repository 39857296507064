import request from '@/utils/request';

export const GetSynTasks = (params) => request({
    method: "get",
    url: '/api/Order/GetSynTasks',
    params
  });
  export const StartSyncTask = (data) => request({
    method: "post",
    url: '/api/Order/StartSyncTask',
    data
  });