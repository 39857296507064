var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-item-warp" },
    [
      _vm._l(_vm.route, function (child) {
        return [
          !child.meta?.hidden
            ? _c(
                "div",
                { key: child.path, class: child.children ? "menuItems" : "" },
                [
                  child.children
                    ? _c(
                        "el-submenu",
                        { key: child.name, attrs: { index: child.path } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("div", { staticClass: "silder-menu-item" }, [
                              child.meta?.icon
                                ? _c("img", {
                                    attrs: {
                                      src: child.meta?.icon,
                                      alt: "icon",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "silder-menu-item-title",
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(child?.meta?.title || child.name)
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          child.children?.length
                            ? _c("MenuItem", {
                                attrs: { route: child.children },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c(
                        "el-menu-item",
                        {
                          class:
                            _vm.$route.matched[0].path == child.path
                              ? "is-active"
                              : "",
                          attrs: { index: child.path, test: child.path },
                        },
                        [
                          _c("div", { staticClass: "silder-menu-item" }, [
                            child.meta?.icon
                              ? _c("img", {
                                  attrs: { src: child.meta?.icon, alt: "icon" },
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(child.meta?.title))]
                            ),
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: child.meta?.lock && _vm.showMemuLock,
                                    expression:
                                      "child.meta?.lock && showMemuLock",
                                  },
                                ],
                              },
                              [_c("i", { staticClass: "el-icon-lock" })]
                            ),
                          ]),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }