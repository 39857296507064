<template>
  <div class="image-viewer__wrapper" v-if="showPreviewDialog" @click="showPreviewDialog = false">
    <div class="image-viewer__canvas">
      <img
        @click.stop
        :src="imageList[currentIndex]"
        alt=""
        :style="{
          transform: 'rotate(' + rotation + 'deg) scale(' + zoomFactor + ')',
          transition: 'transform 0.3s ease',
          'max-height': '100%',
          'max-width': '100%'
        }"
      />
    </div>
    <div class="image-viewer__btn image-viewer__close" @click="showPreviewDialog = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="image-viewer__btn image-viewer__actions" @click.stop>
      <div class="image-viewer__actions__inner">
        <i class="el-icon-zoom-out" @click="zoomOut"></i>
        <i class="el-icon-zoom-in" @click="zoomIn"></i>
        <i class="el-icon-full-screen" @click="resetZoom"></i>
        <i class="el-icon-refresh-left" @click="rotateLeft"></i>
        <i class="el-icon-refresh-right" @click="rotateRight"></i>
      </div>
    </div>
    <div
      v-if="imageList.length > 1"
      class="image-viewer__btn image-viewer__prev"
      @click.stop="handleViewerPrev"
    >
      <i class="el-icon-arrow-left"></i>
    </div>
    <div
      v-if="imageList.length > 1"
      class="image-viewer__btn image-viewer__next"
      @click.stop="handleViewerNext"
    >
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    previewSrcList: {
      type: Array,
      default: () => []
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    zoomRate: {
      type: Number,
      default: 0.8
    },
    maxScale: {
      type: Number,
      default: 7
    },
    minScale: {
      type: Number,
      default: 0.2
    }
  },
  data() {
    return {
      showPreviewDialog: false,
      imageList: [],
      zoomFactor: this.zoomRate,
      rotation: 0,
      currentIndex: this.initialIndex
    }
  },
  watch: {
    value(newValue) {
      this.zoomFactor = this.zoomRate
      this.showPreviewDialog = newValue
      this.registerGestureEvent()
    },
    previewSrcList(newValue) {
      this.imageList = newValue || []
    },
    initialIndex(newVal) {
      this.currentIndex = newVal || 0
    },
    showPreviewDialog(newValue) {
      if (!newValue) {
        this.currentIndex = this.initialIndex
        this.rotation = 0
        this.unregisterGestureEvent()
      }
      this.$emit('input', newValue)
    }
  },
  methods: {
    zoomIn() {
      if (this.zoomFactor > this.maxScale) {
        return
      }
      this.zoomFactor += 0.1
    },
    zoomOut() {
      if (this.zoomFactor < this.minScale) {
        return
      }
      this.zoomFactor -= 0.1
    },
    resetZoom() {
      this.zoomFactor = this.zoomRate
    },
    rotateRight() {
      this.rotation += 90
    },
    rotateLeft() {
      this.rotation -= 90
    },
    handleViewerPrev() {
      this.resetAction()
      this.currentIndex =
        this.currentIndex === 0 ? this.imageList.length - 1 : this.currentIndex - 1
    },
    handleViewerNext() {
      this.resetAction()
      this.currentIndex =
        this.currentIndex === this.imageList.length - 1 ? 0 : this.currentIndex + 1
    },
    handleGesture(event) {
      const delta = Math.sign(event.deltaY)
      if (delta > 0) {
        this.zoomOut()
      } else if (delta < 0) {
        this.zoomIn()
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.showPreviewDialog = false
      }
    },
    resetAction() {
      this.zoomFactor = this.zoomRate
      this.rotation = 0
    },
    registerGestureEvent() {
      document.addEventListener('keydown', this.handleKeydown)
      document.addEventListener('wheel', this.handleGesture)
    },
    unregisterGestureEvent() {
      document.removeEventListener('wheel', this.handleGesture)
      document.removeEventListener('keydown', this.handleKeydown)
    }
  }
}
</script>
<style lang="scss" scoped>
.image-viewer__wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 设置背景颜色为半透明黑色 */
  z-index: 999; /* 设置层级，保证遮罩位于其他内容之上 */
  display: flex; /* 使用 Flex 布局使内容垂直水平居中 */
  justify-content: center;
  align-items: center;
  position: fixed;
  transition: opacity 0.3s ease; /* 添加 opacity 属性的过渡效果 */
  .image-viewer__close {
    right: 40px;
    top: 40px;
    width: 44px;
    height: 44px;
    color: #fff;
    font-size: 28px;
    background-color: #606266;
  }
  .image-viewer__canvas {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  .image-viewer__actions {
    left: 50%;
    bottom: 30px;
    transform: translate(-50%);
    width: 282px;
    height: 44px;
    padding: 0 23px;
    background-color: #606266;
    border-color: #fff;
    border-radius: 22px !important;
    .image-viewer__actions__inner {
      width: 100%;
      height: 100%;
      cursor: default;
      font-size: 23px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      i {
        cursor: pointer;
      }
    }
  }
  .image-viewer__btn {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.8;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
  }
  .image-viewer__prev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    width: 44px;
    height: 44px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    border-color: #fff;
  }
  .image-viewer__next {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    text-indent: 2px;
    width: 44px;
    height: 44px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    border-color: #fff;
  }
}
</style>
