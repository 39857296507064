var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shopifyContent" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("member.confirmation")))]),
    ]),
    _c("div", { staticClass: "diver" }),
    _c("div", { staticClass: "payInfo" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("User account:")]),
        _c("div", { staticClass: "msg" }, [
          _vm._v(_vm._s(_vm.userInfo.LoginName || "")),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Pricing plan:")]),
        _c("div", [_vm._v(_vm._s(_vm.radioLabel[_vm.CycleType] || ""))]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Billing option:")]),
        _c("div", [_vm._v(_vm._s(_vm.priceInfo?.TargetGradeName || ""))]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Subtotal:")]),
        _c("div", [
          _vm._v(
            " $" +
              _vm._s(
                Number(
                  _vm.priceInfo?.RegularAmount -
                    _vm.priceInfo?.Discount -
                    _vm.priceInfo?.RefereeDiscount || 0
                ).toFixed(2) || 0.0
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Expiration date:")]),
        _c("div", [_vm._v(_vm._s(_vm.priceInfo?.EndDate || ""))]),
      ]),
    ]),
    _c("div", { staticClass: "diver diver1" }),
    _c("div", { staticClass: "totalSum" }, [
      _vm._v(
        " $" +
          _vm._s(
            Number(
              _vm.priceInfo?.RegularAmount -
                _vm.priceInfo?.Discount -
                _vm.priceInfo?.RefereeDiscount || 0
            ).toFixed(2) || 0.0
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }