var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-group-box" }, [
    _c(
      "div",
      { staticClass: "scrollX left-button", on: { click: _vm.prevSlide } },
      [
        _c("img", {
          staticStyle: { width: "16px", height: "16px" },
          attrs: {
            src: require("@/assets/imgs/detail/ic_arr_left.png"),
            alt: "左箭头",
          },
        }),
      ]
    ),
    _c(
      "div",
      { ref: "groupBoxRef", staticClass: "index-group-boxIn" },
      _vm._l(_vm.productFiles, function (item, index) {
        return _c("img", {
          key: "thumb-" + index,
          staticClass: "group-card-n",
          class: { "group-card-s": _vm.currentIndex === index },
          attrs: { src: item, alt: "Thumbnail " + index },
          on: {
            click: function ($event) {
              return _vm.handleThumbnailClick(index)
            },
          },
        })
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "scrollX right-button", on: { click: _vm.nextSlide } },
      [
        _c("img", {
          staticStyle: { width: "16px", height: "16px" },
          attrs: {
            src: require("@/assets/imgs/detail/ic_arr_right.png"),
            alt: "右箭头",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }