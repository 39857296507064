import request from '@/utils/request';

export const GetCardList = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhCard/GetCardList',
    data
  });
  
export const GetCouponList = (data) => request({// 获取问卷调查
    method: "post",
    url: '/api/LhVideoCoupon/GetCouponList',
    data
  });