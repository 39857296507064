var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "loading",
      attrs: { src: require("../assets/imgs/loading.gif"), alt: "" },
    }),
    _c("p", { staticClass: "loading_text" }, [
      _vm._v("shopify Store is connecting... " + _vm._s(_vm.step) + "/2"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }