import {
  GetWelcomed,
  GetWelcomedAdd
} from '@/api/welcomed'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetWelcomedWc({state, commit}, params) {return GetWelcomed(params).then(res => res)},
    GetWelcomedAddWc({state, commit}, params) {return GetWelcomedAdd(params).then(res => res)}
  }

}