<template>
  <div class="particulars">
    <div class="activeName">
      <div class="winners_box">
        <div class="Filter">
          <el-input
            placeholder="Video name"
            v-model="searchType"
            class="input-with-select"
            @keyup.enter="searchBtn"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <button class="search" @click="searchBtn">{{ $t('MyProduct.search') }}</button>
        </div>
        <div class="bigbox">
          <div class="box" v-for="(item, index) in productList" :key="item.id">
            <div class="images" @mouseenter="enterProduct(index)" @mouseleave="leaveProduct()">
              <ud-video
                :poster="item.coverUrl"
                :src="item.videoUrl"
                :ref="'video' + index"
                :canSeeTime="30"
                @canSeePause="handleCanSeePause"
              />

              <div class="contents_exhibition" v-if="currentIndexImg === index">
                <div class="custom_controls">
                  <div @click="togglePlay(index)" class="video_player_icon">
                    <i :class="isPlaying[index] ? 'el-icon-video-pause' : 'el-icon-video-play'"></i>
                  </div>
                  <div @click="toggleFullscreen(index)" class="video_amplify_icon">
                    <i class="el-icon-full-screen"></i>
                  </div>
                </div>
                <div class="productExhibition_description">
                  <p>{{ item.videoDesc }}</p>
                </div>
              </div>
            </div>
            <div class="video_title">
              <p>{{ item.videoName }}</p>
            </div>
            <div class="video_time">
              <p>{{ formatDate(item.createTime) }}</p>
            </div>
          </div>
        </div>
        <div class="pagebox">
          <PagePagination
            :total="total"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>
    <!-- 会员提示弹窗 -->
    <UpgradeDialog :visible.sync="upgradeVisible" title="Get Pro Plan for more features" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PagePagination from '@/components/PagePagination.vue'
import { videoSearch } from '@/api/proConnect'
import UdVideo from '@/components/UdVideo/index.vue'
import UpgradeDialog from '@/components/UpgradeDialog.vue'

export default {
  data: () => ({
    total: 0,
    pageSize: 8,
    currentPage: 1,
    productList: [],
    loading: false,
    searchType: '',
    currentIndexImg: null,
    isPlaying: {},
    upgradeVisible: false
  }),
  components: {
    PagePagination,
    UdVideo,
    UpgradeDialog
  },
  computed: mapState(['userInfo', 'theme']),
  mounted() {
    this.getData()
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
        date.getDate()
      ).padStart(2, '0')}`
    },
    searchBtn() {
      this.getData(this.searchType)
    },
    async getData(videoName = '') {
      this.loading = true
      try {
        const type = this.$route.query.type || 0
        const postData = {
          pageNum: this.currentPage,
          size: this.pageSize,
          type,
          videoName
        }
        const res = await videoSearch(postData)
        if (res.success) {
          this.total = res.data.total
          this.productList = res.data.records || []
        } else {
          this.$message.error(res.msg || 'Error')
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    pageChange(val) {
      this.currentPage = val
      this.getData(this.searchType)
    },
    enterProduct(index) {
      this.currentIndexImg = index
    },
    leaveProduct() {
      this.currentIndexImg = null
    },
    togglePlay(index) {
      const video = this.$refs['video' + index][0].$refs.udvideo
      if (video.paused) {
        video.play()
        this.$set(this.isPlaying, index, true)
      } else {
        video.pause()
        this.$set(this.isPlaying, index, false)
      }
    },
    toggleFullscreen(index) {
      const video = this.$refs['video' + index][0].$refs.udvideo
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen()
      }
    },
    handleCanSeePause() {
      this.upgradeVisible = true
    },
    videoEnded(index) {
      this.$set(this.isPlaying, index, false)
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  font-family: 'Regular';
}
.activeName {
  width: 1320px;
}
.winners_box {
  position: relative;
}
.bigbox {
  display: flex;
  flex-wrap: wrap;
}
.box {
  margin: 22px 0 0 32px;
  border-radius: 10px;
  position: relative;
  width: 280px;
  height: 350px;
  background: #f6f6fa;
}
.images {
  width: 220px;
  height: 220px;
  margin: 20px 0 0 30px;
}
.contents_exhibition {
  position: absolute;
  width: 220px;
  height: 220px;
  background: rgba(0, 0, 0, 0.6);
  top: 20px;
  left: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  .custom_controls {
    .video_player_icon {
      position: absolute;
      top: 80px;
      left: 90px;
      border: none;
      ::v-deep .el-icon-video-play {
        font-size: 40px;
        color: #fff;
      }
      ::v-deep .el-icon-video-pause {
        font-size: 40px;
        color: #fff;
      }
    }
    ::v-deep .el-icon-full-screen {
      font-size: 20px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
.productExhibition_description p {
  color: #fff;
  font-size: 14px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.video_title p {
  font-size: 14px;
  margin: 10px 0 0 30px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 220px;
}
.video_time {
  text-align: right;
  font-size: 14px;
  color: #999999;
  margin-top: 15px;
  margin-right: 30px;
}
.Filter {
  width: 1220px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0 0 20px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  margin-left: 30px;
}
::v-deep .input-with-select {
  width: 950px;
  margin-left: 30px;
  border-radius: 50px;
  margin-left: 30px;
}
::v-deep .el-input__inner {
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #eff1f3;
}
::v-deep .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 40px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
::v-deep .Filter .el-input-group > .el-input__inner {
  border: 0;
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 100px;
  height: 40px;
}
.search {
  width: 145px;
  height: 40px;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  margin-left: 30px;
  border: 1px solid #f0f3fb;
  font-size: 16px;
}
.search:hover {
  background: #3c3772;
}
.pagebox {
  width: 1320px;
  margin-top: 30px;
}
</style>
