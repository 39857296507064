<script>
export default {
  name: 'FeedOrderDetail',
  props: {
    dataSource: {
      type: Object
    }
  },
  data() {
    return {}
  }
}
</script>

<template>
  <div>
    <div class="header">
      <span class="title">{{ $t('SupportTicket.OrderDetail') }}</span>
      <slot name="header_extra"></slot>
    </div>
    <div class="order-item-list">
      <div class="left-product">
        <div
          class="product-item"
          v-for="item in dataSource?.OrderDetails"
          :key="item.OrderDetailId"
        >
          <img :src="item.ProductPictureUrl" />
          <div class="product-info">
            <span class="product-name">{{
              item.IsExclude ? item.ProductName : item.ProductRealSku
            }}</span>
            <span class="product-desc" :class="{ exclude: item.IsExclude }">
              {{ item.Num }}*${{ item?.QuotedPrice || '0.00' }}
            </span>
          </div>
        </div>
      </div>
      <div class="right-view">
        <span>${{ dataSource?.QuotedPrice || '0.00' }}</span>
        <span style="text-align: center">
          {{ dataSource?.Contact || '-' }}
          <br />
          {{ dataSource?.CountryCode || '-' }}
        </span>
        <span style="text-align: center">
          {{ dataSource?.LogisticTransitNo || '-' }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  min-height: 56px;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 24px;

  .title {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #15104b;
  }
}

.order-item-list {
  display: flex;
  align-items: center;
  padding: 24px 16px 0;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  margin-top: 16px;

  .left-product {
    display: flex;
    flex-direction: column;
    width: 40%;

    .product-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      img {
        width: 72px;
        height: 72px;
      }

      .product-info {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        .product-name {
          font-size: 14px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-desc {
          font-size: 14px;
          font-weight: 400;
          color: #666;

          &.exclude {
            text-decoration: line-through;
            color: #c9c9ca;
          }
        }
      }
    }
  }

  .right-view {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;

    span {
      font-size: 14px;
    }
  }
}
</style>
