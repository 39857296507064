import {
  GetSourcingCountryList,
  CrawlProductPost,
  ConfirmQuote
} from '@/api/souring'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetSourcingCountryListSr({state, commit}, params) {return GetSourcingCountryList(params).then(res => res)},
    CrawlProductPostSr({state, commit}, params) {return CrawlProductPost(params).then(res => res)},
    ConfirmQuoteSr({state, commit}, params) {return ConfirmQuote(params).then(res => res)}
  }

}