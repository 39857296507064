import {
  GetCardList,
  GetCouponList
} from '@/api/card'
export default {
  namespaced: true,
  state: {
    token: '',
  },
  getters: {},
  mutations: {
    setAuthBack(state, status) {

    },
    setToken(state, status) {
      state.token = status;
    }
  },
  actions:{
    GetCardListCd({state, commit}, params) {
      return GetCardList(params).then(res => res)
    },
    GetCouponListCd({state, commit}, params) {
      return GetCouponList(params).then(res => res)
    },
  }

}