<script>
import { baseApiHost } from '@/utils/env'
import ImagePreviewDialog from '@/components/ImagePreview'

export default {
  name: 'FeedImageUpload',
  components: {
    ImagePreviewDialog
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showExample: {
      type: Boolean,
      default: false
    },
    uploadLimit: {
      type: Number,
      default: 20
    },
    defaultFileList: Array,
    imgUploadPath: String,
    readonly: Boolean
  },
  data() {
    return {
      uploadImages: [],
      uploadPercent: 0,
      uploading: false,
      showPreviewDialog: false,
      dialogImageUrl: null,
      showImageExampleDialog: false,
      exampleImgs: [
        'https://app.wiio.io/Content/img/example1.png',
        'https://app.wiio.io/Content/img/example2.png'
      ],
      action: baseApiHost() + '/api/File/UploadImgageWithPath',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      },
      currentPreviewIndex: 0
    }
  },
  watch: {
    value: {
      handler(newData) {
        if (newData) {
          this.uploadImages = newData
        }
      },
      deep: true,
      immediate: true
    },
    uploadImages: function (newData) {
      this.$emit('update:value', newData)
    }
  },
  methods: {
    handleRemove(item) {
      const index = this.uploadImages.findIndex((_item) => _item.Url === item.Url)
      this.uploadImages.splice(index, 1)
      item.showActions = false
    },
    handleUploadSuccess(res, file) {
      const result = res.Result || {}
      if (result.UploadStatus) {
        this.uploadImages.push({
          Id: 0, //新增默认0
          Url: result.FullFileName,
          FileName: result.FileName
        })
      }
    },
    handleUploadProgress(event, file, fileList) {
      this.uploadPercent = event.percent.toFixed(2) + '%'
      this.uploading = event.percent < 100
    },
    handlePreview(item) {
      if (item.Url.startsWith('/')) {
        this.dialogImageUrl = baseApiHost() + item.Url
      } else {
        this.dialogImageUrl = baseApiHost() + '/' + item.Url
      }
      item.showActions = false
      this.showPreviewDialog = true
    },
    getUrl(url) {
      if (url.startsWith('https://') || url.startsWith('http://')) {
        return url
      }
      if (url.startsWith('/')) {
        return baseApiHost() + url
      }
      return baseApiHost() + '/' + url
    },
    handleMouseOver(item) {
      item.showActions = true
    },
    handleMouseLeave(item) {
      item.showActions = false
    }
  }
}
</script>

<template>
  <div class="img-upload-container">
    <el-upload
      :action="action"
      :headers="headers"
      :file-list="defaultFileList"
      :data="{
        Action: imgUploadPath
      }"
      :limit="uploadLimit"
      :show-file-list="false"
      :on-remove="handleRemove"
      list-type="picture"
      :on-progress="handleUploadProgress"
      :on-success="
        (res, file) => {
          handleUploadSuccess(res, file)
        }
      "
    >
      <div class="upload-container">
        <div class="title-view" @click.stop.prevent>
          <span class="title">{{ $t('SupportTicket.ImageUrl') }}</span>
          <i
            v-if="showExample"
            class="el-icon-question info-icon"
            @click="showImageExampleDialog = true"
          ></i>
        </div>
        <el-button :loading="uploading" class="upload-button" size="small" round type="primary">{{
          uploading ? uploadPercent : $t('SupportTicket.UploadFile')
        }}</el-button>
      </div>
    </el-upload>

    <div class="image-card">
      <div
        v-for="item in uploadImages"
        :key="item.Url"
        class="image-container"
        @mouseover="handleMouseOver(item)"
        @mouseleave="handleMouseLeave(item)"
      >
        <el-image class="img" :src="getUrl(item.Url)" />
        <div class="actions" v-if="!item.showActions">
          <i class="el-icon-zoom-in icon-class" @click.stop="handlePreview(item)"></i>
          <i
            v-if="!readonly"
            class="el-icon-delete icon-class icon-space"
            @click.stop="handleRemove(item)"
          ></i>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('SupportTicket.Example')"
      :visible.sync="showImageExampleDialog"
      width="30%"
      show-close
    >
      <div class="example-image">
        <el-image
          v-for="(src, index) in exampleImgs"
          class="img"
          :key="index"
          :src="src"
          :preview-src-list="exampleImgs"
        />
      </div>
    </el-dialog>

    <!--    <el-dialog :visible.sync="showPreviewDialog">-->
    <!--      <img width="100%" :src="dialogImageUrl" alt="" />-->
    <!--    </el-dialog>-->
    <ImagePreviewDialog
      v-model="showPreviewDialog"
      :preview-src-list="[dialogImageUrl]"
      :initial-index="currentPreviewIndex"
    />
  </div>
</template>

<style lang="scss" scoped>
.img-upload-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .upload-container {
    display: flex;

    .title-view {
      padding-right: 20px;

      .title {
        padding-right: 5px;
        font-size: 16px;
        color: #606266;
      }

      .info-icon {
        color: red;
        font-size: 16px;
      }
    }

    .upload-button {
      width: 128px;
      height: 34px;
      background: #3f6aff;
      border: none;
    }
  }

  .image-upload {
    display: flex;
    align-items: center;

    .image-upload-tips {
      font-size: 13px;
      color: red;
      padding-right: 10px;
    }

    .button {
      color: red;
      font-size: 12px;
    }
  }

  .image-card {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;

    .image-container {
      position: relative;
    }

    .img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      border: 1px solid #ececec;
      cursor: pointer;
    }

    .actions {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
    }

    .icon-space {
      margin-left: 8px;
    }

    .icon-class {
      font-size: 20px;
      color: white;
      cursor: pointer;
    }

    .image-container:hover .actions {
      opacity: 1;
    }
  }
}

.example-image {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .img {
    width: 120px;
    height: 120px;
  }
}
</style>
