<template>
<!-- 提现弹出框 -->
<div class="WithdrawalDialog">
  <div class="Withdrawal_content">
    <!-- 头部标题 -->
    <div class="Withdrawal_title">
      <div class="Payouts_title">
        <p>{{ $t('wallet.Payouts') }}</p>
      </div>
      <div class="Payouts_icon_close" @click="closeDialog">
        <p><i class="el-icon-close"></i></p>
      </div>
    </div>
    <div class="line_line"></div>
    <div class="SelectTypeBox">
      <div class="walletAccountType">
        <span style="color: red">*</span>
        <span>{{ $t('wallet.AccountType') }}</span>
      </div>
      <div class="selectedOptionType">
        <!-- 内容 -->
        <el-select v-model="selectedOption" @change="handleSelectChange">
          <!-- 你的选项 -->
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- PayPal -->
    <template v-if="selectedOption == 0">
      <div>
        <!-- Account email： -->
        <div class="walletAccountEmail">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.AccountEmail') }}</span>
          </div>
          <div class="walletPleaseEnterStoreNameIpt">
            <el-input class="amount" v-model="AccountEmail"></el-input>
          </div>
        </div>
        <!-- Amount -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.Amount1') }}</span>
          </div>
          <div class="walletAmountIpt">
            <el-input class="amount" v-model="Amount"></el-input>
          </div>
        </div>
      </div>
    </template>
    <!-- CreditCard -->
    <template v-else-if="selectedOption == 1">
      <div>
        <!-- Account name: -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.AccountName') }}</span>
          </div>
          <div class="walletAccountName">
            <el-input v-model="AccountName"></el-input>
          </div>
        </div>
        <!-- Account: -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.Account') }}</span>
          </div>
          <div class="walletAccountIpt">
            <el-input v-model="Account"></el-input>
          </div>
        </div>
        <!-- SWIFT BIC: -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.SWIFTBIC') }}</span>
          </div>
          <div class="walletSwiftBicIpt">
            <el-input v-model="SwiftBic"></el-input>
          </div>
        </div>
        <!-- Country: -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.Country') }}</span>
          </div>
          <div class="walletCountryIpt">
            <el-input v-model="Country"></el-input>
          </div>
        </div>
        <!-- Personal/corporate accounts: -->
        <div class="walletAmount">
          <div class="walletCountryWord">
            <div>
              <p style="color: red">*</p>
            </div>
            <div class="walletCountryWordPersonalCorporate">
              <p>{{ $t('wallet.PersonalCorporate') }}</p>
              <p>{{ $t('wallet.PersonalAccounts') }}</p>
            </div>
          </div>
          <div class="walletPersonalSelect">
            <el-select v-model="PersonalCorporateType" placeholder="">
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- Amount: -->
        <div class="walletAmount">
          <div class="walletAccountEmailWord">
            <span style="color: red">*</span>
            <span>{{ $t('wallet.Amount2') }}</span>
          </div>
          <div class="walletAmountIpts">
            <el-input v-model="Amount"></el-input>
          </div>
        </div>
      </div>
    </template>
    <template>
      <!-- Confirm -->
      <div class="walletConfirm">
        <button @click="walletSubmitPaypal" class="paybtn btn-bg0">
          {{ $t('wallet.Confirm1') }}
        </button>
      </div>
    </template>
  </div>
</div>
</template>

<script>

export default {
//import引入的组件
components: {},
data() {
return {
  selectedOption: 0, // 默认支付类型
  options: [
    {
      value: 0,
      label: 'PayPal'
    },
    {
      value: 1,
      label: 'Bank Transfer'
    }
  ], //支付类型
  showSpecialContent: false,
  AccountEmail: '',
  Amount: '',
  AccountName: '', //
  Account: '',
  SwiftBic: '',
  Country: '',
  PersonalCorporateType: false, //默认账户类型
  optionsType: [
    {
      value: false,
      label: 'Personal'
    },
    {
      value: true,
      label: 'corporate'
    }
  ],
};
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
created() {
  this.dispatch = this.$store.dispatch
},
//方法集合
methods: {
  closeDialog() {
    this.$emit('close');
  },
  handleSelectChange(newVal) {
    this.selectedOption = newVal
  },
  walletSubmitPaypal() {
    if (this.Amount < 50) {
      return this.$message.error(this.$t('wallet.Cannot'))
    }
    this.dispatch('alliance/SubmitBalanceWithdrawalAi', {
      AccountType: this.selectedOption,
      AccountEmail: this.AccountEmail,
      ApplyAmount: this.Amount,
      BkAccountName: this.AccountName,
      BkAccount: this.Account,
      BkSwiftbic: this.SwiftBic,
      BkCountry: this.Country,
      BkIsCorporate: this.PersonalCorporateType
    })
      .then((res) => {
        if (res.Success == true) {
          this.$message.success(this.$t('Alliance.successful'))
          this.closeDialog();
        } else {
          this.$message.error(res.ErrMsg)
        }
      })
      .catch((err) => {
        console.log(err)
        this.$message.error(res.ErrMsg)
      })
  },
  /* 获取最后提现信息 */
  WithdrawalInformation() {
    this.dispatch('wallet/LastBalanceWithdrawalWa')
      .then((res) => {
        if (res?.Success && res?.Result) {
          this.selectedOption = res.Result.AccountType
          this.AccountEmail = res.Result.AccountEmail
          this.Amount = res.Result.ApplyAmount
          this.AccountName = res.Result.BkAccountName
          this.Account = res.Result.BkAccount
          this.SwiftBic = res.Result.BkSwiftbic
          this.Country = res.Result.BkCountry
          this.PersonalCorporateType = res.Result.BkIsCorporate
        } /* else {
          this.$message.error(res.ErrMsg)
        } */
      })
      .catch((err) => {
        console.log(err)
      })
  }
},
}
</script>
<style scoped lang="scss">
/* 提现弹出框 */
.WithdrawalDialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}

.WithdrawalDialog .Withdrawal_content {
  width: 600px;
  height: auto;
  background: #fff;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 330px);
  padding-bottom: 32px;
}
.Withdrawal_title {
  display: flex;
  justify-content: space-between;
}
.Payouts_title {
  margin-top: 25px;
  margin-left: 50px;
}
.Payouts_title p {
  font-size: 18px;
}
.Payouts_icon_close {
  margin-top: 25px;
  margin-right: 50px;
}
::v-deep .Payouts_icon_close .el-icon-close {
  font-size: 20px;
  cursor: pointer;
}
.line_line {
  width: 100%;
  height: 1.5px;
  background: #e5e5e5;
  margin-top: 15px;
}
.SelectTypeBox {
  display: flex;
  margin-left: 50px;
  margin-top: 30px;
}
.walletAccountType {
  margin-top: 10px;
}
.walletAccountType span {
  font-size: 16px;
}
.walletAccountType span:nth-child(2) {
  margin-left: 10px;
}
.selectedOptionType {
  margin-left: 55px;
  width: 300px;
}
::v-deep .selectedOptionType .el-input__inner {
  border-radius: 50px;
  width: 300px;
}
.walletAccountEmail {
  display: flex;
  margin-left: 50px;
  margin-top: 15px;
}
.walletAccountEmailWord {
  margin-top: 10px;
}
.walletAccountEmailWord span {
  font-size: 16px;
}
.walletAccountEmailWord span:nth-child(2) {
  margin-left: 10px;
}
::v-deep .walletPleaseEnterStoreNameIpt .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 40px;
}
.walletAmount {
  display: flex;
  margin-left: 50px;
  margin-top: 15px;
}
.walletAmountWord {
  margin-top: 10px;
}
.walletAmountWord p {
  font-size: 16px;
}
::v-deep .walletAmountIpt .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 100px;
}
.walletConfirm button {
  width: 200px;
  height: 45px;
  font-size: 18px;
  border-radius: 50px;
  margin-top: 40px;
  margin-left: 180px;
}
.walletConfirmTwo button {
  width: 200px;
  height: 45px;
  font-size: 18px;
  border-radius: 50px;
  margin-top: 40px;
  margin-left: 200px;
}
.walletAccountNameWord {
  margin-top: 10px;
}
.walletAccountNameWord p {
  font-size: 16px;
}
::v-deep .walletAccountName .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 50px;
}
::v-deep .walletAccountIpt .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 100px;
}
.walletSwiftBicWord {
  margin-top: 10px;
}
.walletSwiftBicWord p {
  font-size: 16px;
}
::v-deep .walletSwiftBicIpt .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 85px;
}
.walletCountryWord {
  margin-top: 10px;
  display: flex;
}
.walletCountryWord p {
  font-size: 16px;
}
.walletCountryWordPersonalCorporate {
  margin-left: 10px;
}
::v-deep .walletCountryIpt .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 105px;
}
.walletAmountWords {
  margin-top: 10px;
}
.walletAmountWords p {
  font-size: 16px;
}
::v-deep .walletAmountIpts .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 105px;
}
::v-deep .walletPersonalSelect .el-input__inner {
  width: 300px;
  border-radius: 50px;
  margin-left: 25px;
}
</style>
