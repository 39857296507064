<template>
  <div class="hello">
    <lazy-slide-captcha
      ref="captcha"
      :width="width"
      :height="height"
      :show-refresh="true"
      :fail-tip="failTip"
      :success-tip="successTip"
      @finish="handleFinish"
      @refresh="generate">
    </lazy-slide-captcha>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'GraphicValidation',
  props: {
    msg: String,
    phoneNum: String,
    phoneCode: String
  },
  data () {
    return {
      requestId: undefined,
      failTip: '',
      successTip: '',
      width: 340,
      height: 212
    }
  },
  created() {

  },
  mounted () {
    this.generate();
  },
  methods: {
    generate () {
      this.$refs.captcha.text ='Drag the slider'
      this.$refs.captcha.showRefresh = false
      // 改变内部状态，标识生成请求开始
      this.$refs.captcha.startRequestGenerate()
      setTimeout(function(){
        if(document.getElementsByClassName('captcha_message__text').length>0 && document.getElementsByClassName('captcha_message__text')[0].innerText=='加载中...'){
        document.getElementsByClassName('captcha_message__text')[0].innerText='loading...'
      }
      },50)
      axios.get('/api/Login/GetCaptche')
        .then((res) => {
          this.requestId = res.data.Result.Id
          // 改变内部状态，标识生成请求结束，同时设定background，slider图像
          this.$refs.captcha.endRequestGenerate(res.data.Result.BackgroundImage, res.data.Result.SliderImage)
          this.$refs.captcha.showRefresh = false
        })
        .catch((error) => {
          // 标识生成请求结束
          this.$refs.captcha.endRequestGenerate(null, null)
        });
    },
    handleFinish (data) {
      // 改变内部状态，标识验证请求开始
      this.$refs.captcha.startRequestVerify()
      axios.post('/api/Login/GetVerifyCode', {
        MobileNumber: this.phoneNum,
        phoneCode: this.phoneCode,
        Id: this.requestId,
        SmSlideTrack: data
      })
        .then((res) => {
          if(!res.data.Success){
            // 验证失败时显示信息
            this.failTip = res.data.ErrMsg
            setTimeout(function(){
              if(document.getElementsByClassName('captcha__action__text').length>0 && document.getElementsByClassName('captcha__action__text')[0].innerText=='刷新'){
              document.getElementsByClassName('captcha__action__text')[0].innerText='Refresh'
            }
            },50)
            this.$refs.captcha.endRequestVerify(false)
            setTimeout(() => {
              this.generate()
            }, 1000)
          }else{
            // 验证通过时显示信息
            this.successTip = 'The code has been sent successfully.';
            setTimeout(function(){
              if(document.getElementsByClassName('captcha__action__text').length>0 && document.getElementsByClassName('captcha__action__text')[0].innerText=='刷新'){
              document.getElementsByClassName('captcha__action__text')[0].innerText='Refresh'
            }
            },50)
            this.$refs.captcha.endRequestVerify(true);
          }
        })
        .catch((error) => {
          this.failTip = 'Err!'
          // 标识验证请求结束
          this.$refs.captcha.endRequestVerify(false)
          setTimeout(function(){
              if(document.getElementsByClassName('captcha__action__text').length>0 && document.getElementsByClassName('captcha__action__text')[0].innerText=='刷新'){
              document.getElementsByClassName('captcha__action__text')[0].innerText='Refresh'
            }
            },50)
        });
    }
  }
}
</script>
<style scoped>
.hello {
  width: 200px;
  height: 200px;
}
</style>
