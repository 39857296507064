var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "container",
      attrs: {
        visible: _vm.dialogVisible,
        width: "540px",
        title: "Your store",
        top: "50px",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "line" }),
        _c(
          "div",
          {
            staticClass: "scrollbar",
            staticStyle: { height: "360px", "overflow-y": "auto" },
          },
          [
            _c(
              "div",
              { staticClass: "shop-list" },
              _vm._l(_vm.shoplist, function (shop, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "shop-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectShop(shop)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "shop-content",
                        class: { selected: shop === _vm.selectedShop },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "shop-image",
                            style: { backgroundImage: "url(你的图片路径)" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(shop.ShopName.substring(0, 2))),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "shop-info" }, [
                          _c("div", { staticClass: "shop-title" }, [
                            _vm._v(_vm._s(shop.ShopName)),
                          ]),
                          _c("div", { staticClass: "shop-address" }, [
                            _vm._v(_vm._s(shop.ShopId)),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "center", "margin-top": "20px" } },
          [
            _c(
              "button",
              {
                staticClass: "confirm-button",
                attrs: { type: "button" },
                on: { click: _vm.confirmSelection },
              },
              [_vm._v("Confirm")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }