import Vue from 'vue'
import VueRouter from 'vue-router'
import SliceAddStore from '@/assets/imgs/SideBar/SliceAddStore@2x.png'
import SliceSourcing from '@/assets/imgs/SideBar/SliceSourcing@2x.png'
import SliceOrder from '@/assets/imgs/SideBar/SliceOrder@2x.png'
import SliceWinners from '@/assets/imgs/SideBar/SliceWinners@2x.png'
import SlicePod from '@/assets/imgs/SideBar/grid.png'
import SliceUpgrade from '@/assets/imgs/SideBar/SliceUpgrade@2x.png'
import SliceWallet from '@/assets/imgs/SideBar/SliceWallet@2x.png'
import SliceDashboard from '@/assets/imgs/SideBar/SliceDashboard@2x.png'
import SliceImportList from '@/assets/imgs/SideBar/SliceImportList@2x.png'
import SliceVideo from '@/assets/imgs/SideBar/SliceVideo@2x.png'
import SliceLianMengYinXiao from '@/assets/imgs/SideBar/SliceLianMengYinXiao.png'
import SliceConnect from '@/assets/imgs/connect.png'
import ExhibitionImg from '@/assets/imgs/SideBar/FrameMap.png'
import HelpCenterSlice from '@/assets/imgs/SideBar/HelpCenterSlice@2x.png'
import MenuSupports from '@/assets/imgs/SideBar/ic_menu_supports.png'
import SliceDropshipping from '@/assets/imgs/SideBar/dropshipping_icon.png'
import PaymentIcon from '@/assets/imgs/SideBar/PaymentIcon3.png'
import DLZIcon from '@/assets/imgs/SideBar/dlzFrame.png'
import Stockups from '@/views/setting/Stockup/Stockups.vue'
import Inventorylist from '@/views/setting/Stockup/Inventorylist.vue'
import Layout2 from '@/components/Layout/layout2.vue'
import TaxSLice from '@/assets/imgs/SideBar/menu_slider_tax.png'
import Shopify from '@/views/setting/member/shopify/index.vue'

import Dashboard from '@/views/dashboard/dashboard.vue'
import Login from '@/views/Login/index.vue'
import storeLogin from '@/views/Login/storeLogin.vue'
import Register from '@/views/Login/register/index.vue'
import Orders from '@/views/orders/Orders.vue'
import orerds from '@/views/orders/orerds.vue'
import OfflineOrder from '@/views/orders/OfflineOrder.vue'
import synchronize from '@/views/orders/synchronize.vue'
import notFound from '@/views/404.vue'
import Password from '@/views/Login/password'
import marketing from '@/views/Login/marketing'
import adsmarketing from '@/views/Login/adsmarketing'
import paymarketing from '@/views/Login/paymarketing'
import qfmarketing from '@/views/Login/qfmarketing'
import sourcingURL from '@/views/souring/sourcingURL.vue'
import SourcingURLOld from '@/views/souring/SourcingURLOld.vue'
import Store from '@/views/setting/Store.vue'
import NewStore from '@/views/setting/NewStore.vue'
import Exhibition from '@/views/setting/Exhibition.vue'
import Wallet from '@/views/setting/wallet/wallet.vue'
import CreditLoading from '@/views/setting/wallet/CreditLoading.vue'
import CreditBack from '@/views/setting/wallet/CreditBack.vue'
import walletuser from '@/views/setting/wallet/walletuser.vue'
import TopUpHistory from '@/views/setting/wallet/TopUpHistory.vue'
import Sourcing from '@/views/souring/sourcing.vue'
import SourcingHistory from '@/views/souring/sourcinghistory.vue'
import quoteder from '@/views/souring/quoteder.vue'
import videoer from '@/views/setting/videoer.vue'
import Profiles from '@/views/setting/Profiles.vue'
import ViewDetails from '@/views/souring/ViewDetails.vue'
import MyProduct from '@/views/setting/product/MyProduct.vue'
import Affiliate from '@/views/setting/Affiliate.vue'
import Upgrade from '@/views/setting/Upgrade.vue'
import ImportantNotices from '@/views/Login/ImportantNotices.vue'
import ProfileSub from '@/views/setting/ProfileSub.vue'
import Products from '@/views/Products/ProductList.vue'
import WinnerProducts from '@/views/Products/components/WinnerProducts.vue'
import Top100Products from '@/views/Products/components/Top100Products.vue'
import FindProductsV2 from '@/views/Products/components/FindProducts.vue'
import ProductDetail from '@/views/Products/ProductDetail.vue'
import ImportList from '@/views/setting/product/ImportList.vue'
import MyCard from '@/views/setting/myCard.vue'
import Voucher from '@/views/setting/Voucher.vue'
import ProductInfo from '@/views/setting/product/ProductInfo.vue'
import Video from '@/views/setting/Video.vue'
import Payment from '@/views/setting/member/Payment.vue'
import memberDetail from '@/views/setting/member/detail.vue'
import succeed from '@/views/setting/succeed.vue'
import cancel from '@/views/setting/cancel.vue'
import verificationCode from '@/views/Login/verificationCode.vue'
import loading from '@/views/loading.vue'
import ttloading from '@/views/ttloading.vue'
import guideSteps from '@/views/guide/guideSteps.vue'
import StepOne from '@/views/guide/StepOne.vue'
import StepTwo from '@/views/guide/StepTwo.vue'
import NewStepTwo from '@/views/guide/NewStepTwo.vue'
import AllVideos from '@/views/setting/AllVideos.vue'
import ProConnect from '@/views/ProConnect/index.vue'

import PaymentOrder from '@/views/PaymentOrders/PaymentOrder.vue'
import PaymentRefundRegistration from '@/views/PaymentOrders/RefundRegistration.vue'
import WithdrawalHistory from '@/views/PaymentOrders/WithdrawalHistory.vue'

import ProfitStatement from '@/views/Quickfund/ProfitStatement.vue'
import RefundRegistration from '@/views/Quickfund/RefundRegistration.vue'
import Captcha from '@/views/Login/captcha/index.vue'

import NYOrder from '@/views/NYBusiness/NYOrder.vue'
import NYRefundRegistration from '@/views/NYBusiness/NYRefundRegistration.vue'
import NYWithdrawalHistory from '@/views/NYBusiness/NYWithdrawalHistory.vue'

import Airwallexfail from '@/views/Airwallex/Airwallexfail.vue'
import Airwallexsuccess from '@/views/Airwallex/Airwallexsuccess.vue'
import PaypalSuccess from '@/views/Airwallex/PaypalSuccess.vue'
import Help from '@/views/setting/Help.vue'
import HelpSuccess from '@/views/setting/HelpSuccess.vue'
import CardFail from '@/views/Airwallex/CardFail.vue'
import CardSucceeded from '@/views/Airwallex/CardSucceeded.vue'
import HelpCenter from '@/views/HelpCenter/HelpCenter.vue'
import BasicFunction from '@/views/HelpCenter/BasicFunction.vue'
import StoreConnection from '@/views/HelpCenter/StoreConnection.vue'
import MessageTouch from '@/views/HelpCenter/MessageTouch.vue'
import PricingPlan from '@/views/HelpCenter/PricingPlan.vue'
import OrderRefund from '@/views/HelpCenter/OrderRefund.vue'
import ShippingTracking from '@/views/HelpCenter/ShippingTracking.vue'

import FAQ from '@/views/setting/FAQ/FAQ.vue'
import HowToStart from '@/views/setting/FAQ/HowToStart.vue'
import ProductItem from '@/views/setting/FAQ/ProductItem.vue'
import ProductNotFound from '@/views/setting/FAQ/ProductNotFound.vue'
import ShippingCost from '@/views/setting/FAQ/ShippingCost.vue'
import ShippingTime from '@/views/setting/FAQ/ShippingTime.vue'

import SupportTicket from '@/views/SupportTicket/index.vue'
import SubmittedByUd from '@/views/SupportTicket/SubmittedByUd.vue'
import FileQuestion from '@/views/SupportTicket/FileQuestion.vue'
import FileQuestionDetail from '@/views/SupportTicket/FileQuestionDetail.vue'
import OpenNewTicket from '@/views/SupportTicket/OpenNewTicket.vue'
import ViewOrderDetails from '@/views/SupportTicket/ViewOrderDetails.vue'
import Taxes from '@/views/Taxes/index.vue'

import podList from '@/views/PodPage/PodList/index.vue'
import podDetail from '@/views/PodPage/PodDetail/index.vue'
import podDetailMain from '@/views/PodPage/podMain.vue'
import ExhibitionTwo from '@/views/setting/ExhibitionTwo.vue'
import CreateShopifyStore from '@/views/DropshippingTutorial/CreateShopifyStore.vue'
import DropshippingTutorialDetail from '@/views/DropshippingTutorial/DropshippingTutorialDetail.vue'
import DropshippingTutorial from '@/views/DropshippingTutorial/DropshippingTutorial.vue'

Vue.use(VueRouter)
export default new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      alias: '/login',
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/storeLogin',
      name: 'storeLogin',
      component: storeLogin,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/captcha',
      name: 'captcha',
      component: Captcha,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '*',
      name: '404',
      component: notFound,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/Store',
      name: 'Store',
      component: Store,
      meta: {
        title: 'MyStore',
        icon: SliceAddStore
      }
    },
    {
      path: '/Store/:type',
      name: 'Store',
      component: Store,
      meta: {
        hidden: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: 'Dashboard',
        icon: SliceDashboard
      }
    },
    {
      path: '/NewStore',
      name: 'NewStore',
      component: NewStore,
      meta: {
        hidden: true
      }
    },
    {
      path: '/NewStore/:type',
      name: 'NewStore2',
      component: NewStore,
      meta: {
        hidden: true
      }
    },
    {
      path: '/Store',
      name: 'Store',
      component: Stockups,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/Inventorylist',
          name: 'Inventorylist',
          component: Inventorylist,
          meta: {
            title: 'Inventorylist'
          }
        }
      ]
    },
    {
      path: '/Sourcing',
      name: 'Sourcing',
      component: Sourcing,
      meta: {
        title: 'Sourcing',
        icon: SliceSourcing
      }
    },
    {
      path: '/sourcing-history',
      name: 'SourcingHistory',
      component: SourcingHistory,
      meta: {
        title: 'Sourcing-History',
        hidden: true,
        icon: SliceSourcing
      }
    },
    {
      path: '/Orders',
      name: 'Orders',
      component: Orders,
      meta: {
        lock: true,
        title: 'Orders',
        icon: SliceOrder
      }
    },
    {
      path: '/Products',
      name: 'products',
      component: Products,
      redirect: '/Products/FindProducts',
      meta: {
        lock: true,
        title: 'Products',
        icon: SliceWinners
      },
      children: [
        {
          path: '/Products/FindProducts',
          name: 'FindProducts',
          component: FindProductsV2,
          meta: {
            lock: true,
            title: 'Find Products'
            // hidden: true
          }
        },
        {
          path: '/Products/Winner',
          name: 'Winner',
          component: WinnerProducts,
          meta: {
            lock: true,
            title: 'Winner'
            // hidden: true
          }
        },
        {
          path: '/Products/Top100',
          name: 'Top100',
          component: Top100Products,
          meta: {
            lock: true,
            title: 'Top 100'
            // hidden: true
          }
        }
      ]
    },
    {
      path: '/ProductDetail/:goodsCode',
      name: 'ProductDetail',
      component: ProductDetail,
      meta: {
        hidden: true,
        title: 'Product Detail'
      }
    },
    {
      path: '/podList',
      name: 'podPage',
      component: podList,
      meta: {
        title: 'POD List',
        // hideBread: true,
        icon: SlicePod
      }
    },
    /* DLZ */
    {
      path: '/ProfitStatement',
      name: 'ProfitStatement',
      component: Layout2,
      meta: {
        hideBread: true,
        icon: DLZIcon,
        title: 'QuickFund'
      },
      children: [
        {
          path: '/ProfitStatement',
          name: 'ProfitStatement',
          component: ProfitStatement,
          meta: {
            title: 'Profit Statement'
          }
        },
        {
          path: '/RefundRegistration',
          name: 'RefundRegistration',
          component: RefundRegistration,
          meta: {
            title: 'Refund Registration'
          }
        }
      ]
    },
    /* PayMent */
    {
      path: '/PaymentOrders',
      name: 'PaymentOrders',
      component: Layout2,
      meta: {
        hideBread: true,
        icon: PaymentIcon,
        title: 'Payment'
      },
      children: [
        {
          path: '/PaymentOrder',
          name: 'PaymentOrder',
          component: PaymentOrder,
          meta: {
            title: 'Payment Order'
          }
        },
        {
          path: '/PaymentRefundRegistration',
          name: 'PaymentRefundRegistration',
          component: PaymentRefundRegistration,
          meta: {
            title: 'Refund Registration'
          }
        },
        {
          path: '/WithdrawalHistory',
          name: 'WithdrawalHistory',
          component: WithdrawalHistory,
          meta: {
            title: 'Withdrawal history'
          }
        }
        /*  {
          path: '/Available',
          name: 'Available',
          component: Available,
          meta: {
            title: 'Available'
          }
        },
        {
          path: '/Profit',
          name: 'Profit',
          component: Profit,
          meta: {
            title: 'Profit'
          }
        } */
      ]
    },
    /* NY */
    {
      path: '/NYOrder',
      name: 'NYOrder',
      component: Layout2,
      meta: {
        hideBread: true,
        icon: PaymentIcon,
        title: 'NY Business'
      },
      children: [
        {
          path: '/NYOrder',
          name: 'NYOrder',
          component: NYOrder,
          meta: {
            title: 'NY Order'
          }
        },
        {
          path: '/NYRefundRegistration',
          name: 'NYRefundRegistration',
          component: NYRefundRegistration,
          meta: {
            title: 'Refund Registration'
          }
        },
        {
          path: '/NYWithdrawalHistory',
          name: 'NYWithdrawalHistory',
          component: NYWithdrawalHistory,
          meta: {
            title: 'Withdrawal history'
          }
        }
      ]
    },
    /* Tax */
    {
      path: '/Taxes',
      name: 'Tax Solution',
      component: Taxes,
      meta: {
        title: 'Tax Solution',
        icon: TaxSLice
      }
    },
    {
      path: '/Upgrade',
      name: 'Upgrade',
      component: memberDetail,
      meta: {
        icon: SliceUpgrade,
        title: 'Upgrade'
      }
    },
    {
      path: '/wallet',
      name: 'Wallet',
      component: Wallet,
      meta: {
        title: 'Wallet',
        icon: SliceWallet,
        hidden: true
      }
    },
    {
      path: '/Supports',
      name: 'Supports',
      component: Layout2,
      meta: {
        hideBread: true,
        title: 'Support Ticket',
        icon: MenuSupports
      },
      children: [
        {
          path: '/SupportTicket',
          name: 'SupportTicket',
          component: SupportTicket,
          meta: {
            title: 'Support Ticket'
          }
        },
        {
          path: '/SubmittedByUD',
          name: 'Submitted by UD',
          component: SubmittedByUd,
          meta: {
            title: 'Submitted By USAdrop'
          }
        },
        {
          path: '/fileQuestion',
          name: 'FileQuestion',
          component: FileQuestion,
          meta: {
            lock: true,
            hidden: true,
            title: 'File A Question'
          }
        },
        {
          path: '/fileQuestionDetail',
          name: 'FileQuestionDetail',
          component: FileQuestionDetail,
          meta: {
            lock: true,
            hidden: true,
            title: 'View Details'
          }
        },
        {
          path: '/OpenNewTicket',
          name: 'OpenNewTicket',
          component: OpenNewTicket,
          meta: {
            lock: true,
            hidden: true,
            title: 'Open New Ticket'
          }
        },
        {
          path: '/ViewOrderDetails',
          name: 'ViewOrderDetails',
          component: ViewOrderDetails,
          meta: {
            lock: true,
            hidden: true,
            title: 'View Order Details'
          }
        }
      ]
    },
    {
      path: '/Affiliate',
      name: 'Affiliate',
      component: Affiliate,
      meta: {
        title: 'Affiliate',
        icon: SliceLianMengYinXiao,
        hidden: true
      }
    },
    {
      path: '/ImportList',
      name: 'ImportList',
      component: ImportList,
      meta: {
        //hidden: true,
        title: 'Import List',
        icon: SliceImportList
      }
    },
    /* {
      path: '/AliexpressProducts',
      name: 'AliexpressProducts',
      component: AliexpressProducts,
      meta: {
        title: 'Import List',
        icon: SliceImportList
      }
    }, */
    {
      path: '/Video',
      name: 'Video',
      // component: Video, // VideoDetail
      component: Video,
      meta: {
        title: 'Video',
        icon: SliceVideo,
        hidden: true
      }
    },
    {
      path: '/ProConnect',
      name: 'ProConnect',
      component: ProConnect,
      meta: {
        title: 'Product Connect',
        icon: SliceConnect
      }
    },
    {
      path: '/Exhibition',
      name: 'Exhibition',
      component: Exhibition,
      meta: {
        title: 'Exhibition',
        icon: ExhibitionImg,
        hidden: true
      }
    },
    {
      path: '/ExhibitionTwo',
      name: 'ExhibitionTwo',
      component: ExhibitionTwo,
      meta: {
        title: 'Exhibition',
        icon: ExhibitionImg,
        hidden: true
      }
    },
    {
      path: '/HelpCenter',
      name: 'HelpCenter',
      component: HelpCenter,
      meta: {
        title: 'Help Center',
        icon: HelpCenterSlice,
        hidden: true
      }
    },

    {
      path: '/Airwallexfail',
      name: 'Airwallexfail',
      component: Airwallexfail,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/Airwallexsuccess',
      name: 'Airwallexsuccess',
      component: Airwallexsuccess,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/PaypalSuccess',
      name: 'PaypalSuccess',
      component: PaypalSuccess,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/password',
      name: 'password',
      component: Password,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/verificationCode',
      name: 'verificationCode',
      component: verificationCode,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/Orders',
      name: 'Orders',
      component: orerds,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/synchronize',
          name: 'synchronize',
          component: synchronize,
          meta: {
            title: 'Synchronize',
            hidden: true
          }
        },
        {
          path: '/OfflineOrder',
          name: 'OfflineOrder',
          component: OfflineOrder,
          meta: {
            title: 'OfflineOrder',
            hidden: true
            /* keepAlive: false */
          }
        }
      ]
    },
    {
      path: '/CreditLoading',
      name: 'CreditLoading',
      component: CreditLoading,
      meta: {
        hidden: true
      }
    },
    {
      path: '/CreditBack',
      name: 'CreditBack',
      component: CreditBack,
      meta: {
        hidden: true
      }
    },
    {
      path: '/wallet',
      name: 'Wallet',
      component: walletuser,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/TopUpHistory',
          name: 'Top Up History',
          component: TopUpHistory,
          meta: {
            hidden: true
          }
        }
      ]
    },
    {
      path: '/Sourcing',
      name: 'Sourcing',
      component: quoteder,
      meta: {
        hidden: true,
        lock: true
      },
      children: [
        {
          path: '/ViewDetails',
          name: 'ViewDetails',
          component: ViewDetails,
          meta: {
            title: 'View Details',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/SourcingURL',
          name: 'Sourcing URL',
          component: sourcingURL,
          meta: {
            hidden: false
          }
        },
        {
          path: '/SourcingURLOld',
          name: 'Sourcing URL',
          component: SourcingURLOld,
          meta: {
            hidden: false
          }
        }
      ]
    },
    {
      path: '/MyProduct',
      name: 'MyProduct',
      component: MyProduct,
      meta: {
        title: 'My Product',
        hidden: true
      }
    },
    {
      path: '/MyProduct',
      name: 'MyProduct',
      component: quoteder,
      meta: {
        title: 'My Product',
        hidden: true
      },
      children: [
        {
          path: '/ProductInfo',
          name: 'ProductInfo',
          component: ProductInfo,
          meta: {
            title: 'View Details',
            keepAlive: false
          }
        }
      ]
    },
    {
      path: '/ImportList',
      name: 'ImportList',
      component: ImportList,
      meta: {
        hidden: true
      }
    },
    {
      path: '/Upgrade',
      name: 'Upgrade',
      component: Upgrade,
      meta: {
        hidden: true
      },
      children: [
        {
          path: 'member/pay/:id:radio:cycleType:priceId',
          name: 'Payment',
          component: Payment
        },
        {
          path: 'shopify/:id:priceType',
          name: 'Shopify',
          component: Shopify
        }
      ]
    },
    {
      path: '/ImportantNotices',
      name: 'Important Notices',
      component: ImportantNotices,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ProfileSub',
      name: 'ProfileSub',
      component: ProfileSub,
      meta: {
        title: 'Profile',
        hidden: true
      }
    },
    {
      path: '/ProfileSub',
      name: 'Profile',
      component: Profiles,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/MyCard',
          name: 'MyCard',
          component: MyCard,
          meta: {
            title: 'My Card'
          }
        },
        {
          path: '/Voucher',
          name: 'Voucher',
          component: Voucher,
          meta: {
            title: 'Voucher'
          }
        }
      ]
    },
    {
      path: '/myCard',
      name: 'myCard',
      component: MyCard,
      meta: {
        hidden: true
      }
    },
    {
      path: '/Video',
      name: 'Video',
      meta: {
        hidden: true
      },
      component: videoer,
      children: [
        {
          path: '/Voucher',
          name: 'Voucher',
          component: Voucher,
          meta: {
            title: 'Voucher'
          }
        },
        {
          path: '/AllVideos',
          name: 'AllVideos',
          component: AllVideos,
          meta: {
            title: 'All Videos'
          }
        }
      ]
    },
    {
      path: '/AllVideos',
      name: 'AllVideos',
      component: AllVideos,
      meta: {
        hidden: true
      }
    },
    {
      path: '/succeed',
      name: 'succeed',
      component: succeed,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: cancel,
      meta: {
        hidden: true
      }
    },
    {
      path: '/loading',
      name: 'loading',
      component: loading,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/ttloading',
      name: 'ttloading',
      component: ttloading,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/guideSteps',
      name: 'guideSteps',
      component: guideSteps,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/StepOne',
      name: 'StepOne',
      component: StepOne,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/StepTwo',
      name: 'StepTwo',
      component: StepTwo,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/StepTwo:type',
      name: 'StepTwo2',
      component: StepTwo,
      meta: {
        singlePage: true,
        hidden: true
      }
    },

    {
      path: '/NewStepTwo',
      name: 'NewStepTwo',
      component: NewStepTwo,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/NewStepTwo:type',
      name: 'NewStepTwo2',
      component: NewStepTwo,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/u',
      name: 'marketing',
      component: marketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/adsmarketing',
      name: 'adsmarketing',
      component: adsmarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/qfmarketing',
      name: 'qfmarketing',
      component: qfmarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/paymarketing',
      name: 'paymarketing',
      component: paymarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/v',
      name: 'marketing',
      component: marketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/es',
      name: 'marketing',
      component: marketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/r',
      name: 'marketing',
      component: marketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/adsmarketing',
      name: 'adsmarketing',
      component: adsmarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/qfmarketing',
      name: 'qfmarketing',
      component: qfmarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/paymarketing',
      name: 'paymarketing',
      component: paymarketing,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/Help',
      name: 'Help',
      component: Help,
      meta: {
        singlePage: true,
        hidden: true
      }
    },
    {
      path: '/HelpSuccess',
      name: 'HelpSuccess',
      component: HelpSuccess,
      meta: {
        hidden: true
      }
    },
    {
      path: '/CardFail',
      name: 'CardFail',
      component: CardFail,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/CardSucceeded',
      name: 'CardSucceeded',
      component: CardSucceeded,
      meta: {
        hidden: true,
        singlePage: true
      }
    },
    {
      path: '/HelpCenter',
      name: 'Help Center',
      component: quoteder,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/BasicFunction',
          name: 'BasicFunction',
          component: BasicFunction,
          meta: {
            title: 'Basic Function',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/StoreConnection',
          name: 'StoreConnection',
          component: StoreConnection,
          meta: {
            title: 'Store Connection',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/MessageTouch',
          name: 'MessageTouch',
          component: MessageTouch,
          meta: {
            title: 'Message and Get in Touch',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/PricingPlan',
          name: 'PricingPlan',
          component: PricingPlan,
          meta: {
            title: 'Pricing Plan',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/OrderRefund',
          name: 'OrderRefund',
          component: OrderRefund,
          meta: {
            title: 'Order and Refund',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/ShippingTracking',
          name: 'ShippingTracking',
          component: ShippingTracking,
          meta: {
            title: 'Shipping and Tracking',
            keepAlive: false,
            hidden: true
          }
        }
      ]
    },
    {
      path: '/BasicFunction',
      name: 'BasicFunction',
      component: BasicFunction,
      meta: {
        hidden: true
      }
    },
    {
      path: '/StoreConnection',
      name: 'StoreConnection',
      component: StoreConnection,
      meta: {
        hidden: true
      }
    },
    {
      path: '/MessageTouch',
      name: 'MessageTouch',
      component: MessageTouch,
      meta: {
        hidden: true
      }
    },
    {
      path: '/PricingPlan',
      name: 'PricingPlan',
      component: PricingPlan,
      meta: {
        hidden: true
      }
    },
    {
      path: '/OrderRefund',
      name: 'OrderRefund',
      component: OrderRefund,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ShippingTracking',
      name: 'ShippingTracking',
      component: ShippingTracking,
      meta: {
        hidden: true
      }
    },
    /* 常见问题 */
    {
      path: '/FAQ',
      name: 'FAQ',
      component: FAQ,
      meta: {
        hidden: true
      }
    },
    {
      path: '/FAQ',
      name: 'FAQ',
      component: quoteder,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/HowToStart',
          name: 'HowToStart',
          component: HowToStart,
          meta: {
            title: 'How to start',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/ProductItem',
          name: 'ProductItem',
          component: ProductItem,
          meta: {
            title: 'Product item',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/ProductNotFound',
          name: 'ProductNotFound',
          component: ProductNotFound,
          meta: {
            title: 'Product not found',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/ShippingCost',
          name: 'ShippingCost',
          component: ShippingCost,
          meta: {
            title: 'Shipping cost',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/ShippingTime',
          name: 'ShippingTime',
          component: ShippingTime,
          meta: {
            title: 'Shipping time',
            keepAlive: false,
            hidden: true
          }
        }
      ]
    },
    {
      path: '/HowToStart',
      name: 'HowToStart',
      component: HowToStart,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ProductItem',
      name: 'ProductItem',
      component: ProductItem,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ProductNotFound',
      name: 'ProductNotFound',
      component: ProductNotFound,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ShippingCost',
      name: 'ShippingCost',
      component: ShippingCost,
      meta: {
        hidden: true
      }
    },
    {
      path: '/ShippingTime',
      name: 'ShippingTime',
      component: ShippingTime,
      meta: {
        hidden: true
      }
    },

    {
      name: 'podDetailMain',
      path: '/PodList',
      component: podDetailMain,
      meta: {
        hidden: true,
        title: 'Product List'
      },
      children: [
        {
          name: 'podTemplateDetail',
          path: '/templates/detail',
          component: podDetail,
          meta: {
            title: 'Personalization Setting'
          }
        }
      ]
    },
    {
      path: '/DropshippingTutorial',
      name: 'DropshippingTutorial',
      component: DropshippingTutorial,
      meta: {
        title: 'USAdrop Academy',
        icon: SliceDropshipping
      }
    },
    {
      path: '/DropshippingTutorialDetail',
      name: 'DropshippingTutorialDetail',
      component: DropshippingTutorialDetail,
      meta: {
        title: 'USAdrop Academy',
        icon: SliceDropshipping,
        hidden: true
      }
    },
    {
      path: '/DropshippingTutorial',
      name: 'DropshippingTutorial',
      component: quoteder,
      meta: {
        hidden: true
      },
      children: [
        {
          path: '/CreateShopifyStore',
          name: 'CreateShopifyStore',
          component: CreateShopifyStore,
          meta: {
            title: 'Create a Shopify Store',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/NicheProductResearch',
          name: 'NicheProductResearch',
          component: CreateShopifyStore,
          meta: {
            title: 'Niche and product research',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/OrderFulfillmen',
          name: 'OrderFulfillmen',
          component: CreateShopifyStore,
          meta: {
            title: 'Order Fulfillment & Customer Service',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/AdvertisingStrategy',
          name: 'AdvertisingStrategy',
          component: CreateShopifyStore,
          meta: {
            title: 'Advertising Strategy',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/DiversifiedMarketing',
          name: 'DiversifiedMarketing',
          component: CreateShopifyStore,
          meta: {
            title: 'Diversified Internet Marketing',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/OperationGuide',
          name: 'OperationGuide',
          component: CreateShopifyStore,
          meta: {
            title: 'UD Operation Guide',
            keepAlive: false,
            hidden: true
          }
        },
        {
          path: '/IntroductionTo',
          name: 'IntroductionTo',
          component: CreateShopifyStore,
          meta: {
            title: 'Introduction to UD Value-added Services',
            keepAlive: false,
            hidden: true
          }
        }
      ]
    }
  ]
})
